import {
    SALARY_INCREMENT_UPDATE,
    SALARY_INCREMENT_UPDATE_SUCCESS,
    SALARY_INCREMENT_UPDATE_FAIL,
    SALARY_INCREMENT_GETINFO,SALARY_INCREMENT_GETINFO_SUCCESS,SALARY_INCREMENT_GETINFO_FAIL,
    GET_TRANSACTION,GET_TRANSACTION_SUCCESS,GET_TRANSACTIONL_FAIL,

    GET_AUDIT_TRAIL,GET_AUDIT_TRAIL_SUCCESS,GET_AUDIT_TRAIL_FAIL,
    RESET
} from "./actionTypes";


const IntitalValues = {
    salaryIncrement: {},
    getinfoSalary:{},
    audiitTrail:[],
    transactionStatus:{},
    loading: false,
    error: null
}

const SalaryIncrementReducer = (state = IntitalValues, action) => {

    if (!action) return state;
    switch (action.type) {
        case GET_TRANSACTION:
            state = {
                ...state,
               
                loading: true,
            };
            break;
        case GET_TRANSACTION_SUCCESS:
            state = {
                ...state,
                transactionStatus:action.payload,
                
                loading: false,
            };
            break;
        case GET_TRANSACTIONL_FAIL:
            state = {
                ...state,
                error:{
                    message:"Error"
                },
                transactionStatus: {},
                loading: false,
            };
            break;
        case GET_AUDIT_TRAIL:
            state = {
                ...state,
               
                loading: true,
            };
            break;
        case GET_AUDIT_TRAIL_SUCCESS:
            state = {
                ...state,
                audiitTrail:action.payload,
                
                loading: false,
            };
            break;
        case GET_AUDIT_TRAIL_FAIL:
            state = {
                ...state,
                error:{
                    message:"Error"
                },
                audiitTrail: {},
                loading: false,
            };
            break;
        case SALARY_INCREMENT_GETINFO:
            state = {
                ...state,
                getinfoSalary: {},
                loading: true,
            };
            break;
        case SALARY_INCREMENT_GETINFO_SUCCESS:
            state = {
                ...state,
                getinfoSalary:action.payload,
                
                loading: false,
            };
            break;
        case SALARY_INCREMENT_GETINFO_FAIL:
            state = {
                ...state,
                error:{
                    message:"Error"
                },
                getinfoSalary: {},
                loading: false,
            };
            break;
        case SALARY_INCREMENT_UPDATE:
            state = {
                ...state,
                salaryIncrement: {},
                loading: true,
            };
            break;
        case SALARY_INCREMENT_UPDATE_SUCCESS:
            state = {
                ...state,
                salaryIncrement:action.payload,
                loading: false,
            };
            break;
        case SALARY_INCREMENT_UPDATE_FAIL:
            state = {
                ...state,
                error:{
                    message:"Error"
                },
                salaryIncrement: {},
                loading: false,
            };
            break;
            case RESET:
                state = { ...state, salaryIncrement: {} };
                break;
            default:
                state = { ...state };
                break;

    }
    return state;
}
export default SalaryIncrementReducer;