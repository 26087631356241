import {
  GET_DETAILS_BY_ADMIN,
  GET_DETAILS_BY_ADMIN_SUCCESS,
  GET_DETAILS_BY_ADMIN_FAIL,
  GET_TABLENAMES,
  GET_TABLENAMES_SUCCESS,
  GET_TABLENAMES_FAIL,
  UPDATE_TABLENAMES,
  UPDATE_TABLENAMES_SUCCESS,
  UPDATE_TABLENAMES_FAIL,
} from "./actionTypes";

const initialState = {
  systemAdminList: [],
  tableList: [],
  process: {},
  processStatusList: [],
  postCreated: {},
  updateMessage: "",
  loading: false,
  loadingProcessDetails: false,
  processSetting: {},
  loadingProcessSetting: false,
  message: null,
  posting: false,
  error: {
    message: "",
  },
};

const PostReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_DETAILS_BY_ADMIN:
      state = { ...state, loading: true };
      break;
    case GET_DETAILS_BY_ADMIN_SUCCESS:
      state = {
        ...state,
        systemAdminList: action.payload,
        loading: false,
      };
      break;
    case GET_DETAILS_BY_ADMIN_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_TABLENAMES:
      state = { ...state };
      break;
    case GET_TABLENAMES_SUCCESS:
      state = {
        ...state,
        tableList: action.payload,
      };
      break;
    case GET_TABLENAMES_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
      };
      break;
    case UPDATE_TABLENAMES:
    
        state = { ...state, message: null };
        break;
     
    
    case UPDATE_TABLENAMES_SUCCESS:
      
      state = {
        ...state,
        message: action.payload,
      };
      break;
    case UPDATE_TABLENAMES_FAIL:
      state = {
        ...state,
        message: null,
        error: {
          message: "Error",
        },
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostReducer;
