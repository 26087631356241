import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "ChartSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const fetchEthanicity = createAsyncThunk(
  `${sliceName}/fetchEthanicityData`,
  async (actnNo) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV

      //get nature of transactions

      let EthanicityItemsArray = [];
      try {
        const fetchEthanicityItems = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "LK_Ethnicity",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: " ID desc",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (fetchEthanicityItems) {
          fetchEthanicityItems.data.results.map((item) => {
            EthanicityItemsArray.push({
              value: item.Id,
              label: item.Description,
            });
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      console.log("lookup eth final", EthanicityItemsArray);
      return {
        EthanicityItems: EthanicityItemsArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const fetchSankeyData = createAsyncThunk(
  `${sliceName}/fetchSankeyData`,
  async (actnNo) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV

      //get nature of transactions

      let lookupArray = [];
      try {
        const fetchLookupItem = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "vw_sankey_lookup",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: "type asc",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (fetchLookupItem) {
          lookupArray = fetchLookupItem.data.results.map((item) => {
            return {
              value: item.datavalue,
              label: item.datalabel,
              type: item.type,
            };
          });
        }
      } catch (error) {
        console.log("error", error);
      }
      return {
        snakeyLookup: lookupArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const ChartSlice = createSlice({
  name: sliceName,
  initialState: {
    EthanicityItems: [],
    snakeyLookup: [],
  },
  extraReducers: {
    [fetchEthanicity.fulfilled]: (state, action) => {
      state.EthanicityItems = action.payload.EthanicityItems;
      // ;
    },
    [fetchSankeyData.fulfilled]: (state, action) => {
      state.snakeyLookup = action.payload.snakeyLookup;
      // ;
    },
  },
});

export default ChartSlice.reducer;
