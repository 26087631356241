// import { put } from "redux-saga/effects";
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;
export const getProcessDetails = (id) =>
  get(`${baseURL}${url.PROCESS_DETAILS}/${Number(id)}`);
export const getProcessStatusList = (id) =>
  get(`${baseURL}${url.PROCESS_STATUS_LIST}=${Number(id)}`);
export const getSystemAdmin = (payload) => {
  return get(
    baseURL + url.DETAILS_BY_ADMIN + `?TableName=${payload?.tableName}`
  );
};
export const updateTableNames = (payload) =>
  post(baseURL + url.UPDATE_TABLES_NAME, payload);
export const getTableList = () => get(baseURL + url.GET_TABLE_NAMES_LIST);

export const updateProcessStatus = (ID, payload) =>
  put(baseURL + url.PROCESS_STATUS_UPDATE + "/" + Number(ID), payload);
export const updateProcessStatusList = (payload) =>
  put(baseURL + url.PROCESS_STATUSLIST_UPDATE, payload);
export const copyProcess = (ID, payload) =>
  post(baseURL + url.PROCESS_COPY, payload);
export const createProcess = (payload) =>
  post(baseURL + url.PROCESS_CREATE, payload);
export const createProcessStatus = (payload) =>
  post(baseURL + url.PROCESS_STATUS_CREATE, payload);
//Post
export const deleteProcess = (ID) =>
  remove(baseURL + url.PROCESS_DELETE + "/" + Number(ID));
export const deleteProcessStatus = (ID) =>
  remove(baseURL + url.PROCESS_STATUS_DELETE + "/" + Number(ID));
