import { combineReducers } from "redux";

import ThemeCustomizerReducer from "./themeCustomizer/reducer";
import PostReducer from "./posts/reducer";
import LeaveShareReducer from "./leave/reducer";
import outsideEmploymentReducer from "./outsideEmployment/reducer";
import onlineRecruitmentReducer from "./online-recruitment/reducer";
import performanceEvaluationReducer from "./performanceEvaluation/reducer";
import employeeReducer from "./employees/reducer";
import workflowReducer from "./workflows/reducer";
// import advanceSearchReducer from "./advanceSearch/reducer";
import advanceSearchv2Reducer from "./advanceSearchv2/reducer";
import insEnrollment from "./benifit/reducers";
import processReducer from "./process/reducer";
import systemAdminReducer from "./systemAdmin/reducer";
import querySlice from "../Components/AdvanceSearch/slices/querySlice";
import getDataBySSNSlice from "../Components/Create Transaction/slices/getDataBySSNSlice";
import configurationSlice from "../Components/AdvanceSearch/slices/configurationSlice";
import employeeSlice from "../Components/AdvanceSearch/slices/employeeSlice";
import ggTrackSlice from "../Components/Create Transaction/slices/ggTrackSlice";
import TransactionDataSlice from "../Components/Create Transaction/slices/TransactionDataSlice";
import PayrollStaging from "./PayrollStaging/reducer";
import PositionReducer from "./position/reducer";
import NewPositionReducer from "./addorupdate/reducer";
import DepartmentReducer from "./Department/reducer";
import EducationReducer from "./Education/reducer";
import EmployeeTypeReducer from "./EmployeeType/reducer";
import EthicGroupReducer from "./EthicGroup/reducer";
import NewEmployeeTypeReducer from "./EmployeeType-addorupdate/reducer";
import PaygradeReducer from "./Paygrade/reducer";
import PaystepReducer from "./Paystep/reducer";
import PmrcNatureReducer from "./PmrcNature/reducer";
import DeductionplanReducer from "./DeducionPlans/reducer";
import VendorReducer from "./Vendor/reducer";
import DeductiontypeReducer from "./DeductionTypes/reducer";
import PaytimecodeReducer from "./PaytimeCodes/reducer";
import PmrcsalariesReducer from "./PmrcSalaries/reducer";
import CertificationReducer from "./Certifications/reducer";
import TagmasterReducer from "./TagMaster/reducer";
import HistoryReducer from "./PrHistory/reducer";
import CommonReducer from "./Common/reducer";
import NewDepartmentReducer from "./Department-addorupdate/reducer";
import EmplFrPerformanceEvalSlice from "../Components/performanceEvaluation/slices/EmplFrPerformanceEvalSlice";
import SavePerfomanceEvalution from "../Components/performanceEvaluation/slices/SavePerfomanceEvalution";
import OnlineJobApplicationReducer from "./Online-job-application/reducer";
import BillingInvoice from "./DrugTesting/BillingInvoice/reducer";
import CarmensOwnDTLog from "./DrugTesting/CarmensOwnDTLog/reducer";
import DTPMDataLog from "./DrugTesting/DTPMDataLog/reducer";
import TestDesignatedPositionLog from "./DrugTesting/TestDesignatedPositionLog/reducer";
import DepartmentRandomDateLog from "./DrugTesting/DepartmentRandomDateLog/reducer";
import DepartmentJVControlNumber from "./DrugTesting/DepartmentJVControlNumber/reducer";
import RandomDTLog from "./DrugTesting/RandomDTLog/reducer";
import TDPEmployeeMasterListLog from "./DrugTesting/TDPEmployeeMasterListLog/reducer";
import DOAHRJVInternalLog from "./DrugTesting/DOA-HRJVInternalLog/reducer";
import MISDataLog from "./DrugTesting/MISDataLog/reducer";
import DFWPContactList from "./DrugTesting/DFWPContactList/reducer";
import VendorLog from "./DrugTesting/VendorLog/reducer";
import DT from "./DT/reducer";
import AdverseAction from "./EMR/AdverseAction/reducer";
import CBARLog from "./EMR/CBARLog/reducer";
import CBAStatus from "./EMR/Lookups/CBAStatus/reducer";
import MediaSource from "./EMR/Lookups/MediaSource/reducer";
import WaitingPeriod from "./EMR/Lookups/WaitingPeriod/reducer";
import TrainingLog from "./EMR/TrainingLog/reducer";
import TypeOfCharges from "./EMR/Lookups/TypeOfCharges/reducer";
import GrievanceAppealStatus from "./EMR/Lookups/GrievanceAppealStatus/reducer";
import NatureOfGrievance from "./EMR/Lookups/NatureOfGrievance/reducer";
import GrievanceRequest from "./EMR/GrievanceRequest/reducer";
import LTD from "./EMR/LTD/reducer";
import TypesOfWorkHours from "./EMR/Lookups/TypesOfWorkHours/reducer";
import RWHR from "./EMR/RWHR/reducer";
import TypeOfTraining from "./EMR/Lookups/TypeOfTraining/reducer";
import SpecMedicalEval from "./EMR/SpecMedicalEval/reducer";
import StatusOfAPPT from "./EMR/Lookups/StatusOfAPPT/reducer";
import TypesOfSME from "./EMR/Lookups/TypesOfSME/reducer";
import EvalAppeal from "./EMR/EvalAppeal/reducer";
import PERating from "./EMR/Lookups/PERating/reducer";
import EvalAppealReason from "./EMR/Lookups/EvalAppealReason/reducer";
import ChartSlice from "../Components/DashboardChart/Default/slice/ChartSlice";
import DocumentReducer from "./DocumentUpload/reducer";
import DocumentUploadReducer from "./DocumentUpload/reducer";
import ChartReducer from "./chart/reducer";
// import LeaveshareReducer from "./leaveshare/reduer";
import LeaveshareFormReducer from "./leaveshare/reduer";
import AuditLog from "./AuditLogs/reducer";
import MainMenuReducer from "./mainMenu/reducer";
import SideBarMenuReducer from "./sidebarmenu/reduer";
import GG1Reducer from "./GG1-Crud/reducer";
import ExitSurveyReducer from "./ExitSurvey/reducer";
import NpaPopupReducer from "./npa/reducer";
import TrainingReducer from "./TrainingRegistry/reducer";
import JobAnnouncementReducer from "./jobAnnouncement/reducer";
import PositionClassReducer from "./PositionClass/reducer";
import SalaryIncremntReducerUpdate  from "./salaryIncrement/reducer"
import EmployeeDeductionPlanReducer from "./EmployeeDeductionPlan/reducer"
import EmployeeSignatureReducer from "./EmployeeSignature/reducer"
import divisionReducer from "./UserDivisionAssignment/reducer"

const rootReducer = () => {
  return combineReducers({
    PostReducer,
    LeaveShareReducer,
    outsideEmploymentReducer,
    onlineRecruitmentReducer,
    performanceEvaluationReducer,
    employeeReducer,
    // advanceSearchReducer,
    advanceSearchv2Reducer,
    insEnrollment,
    querySlice,
    configurationSlice,
    employeeSlice,
    workflowReducer,
    ggTrackSlice,
    ChartSlice,
    processReducer,
    TransactionDataSlice,
    getDataBySSNSlice,
    PayrollStaging,
    PositionReducer,
    NewPositionReducer,
    DepartmentReducer,
    EducationReducer,
    EthicGroupReducer,
    EmployeeTypeReducer,
    NewEmployeeTypeReducer,
    PaygradeReducer,
    PaystepReducer,
    PmrcNatureReducer,
    DeductionplanReducer,
    VendorReducer,
    DeductiontypeReducer,
    PaytimecodeReducer,
    PmrcsalariesReducer,
    CertificationReducer,
    TagmasterReducer,
    HistoryReducer,
    CommonReducer,
    systemAdminReducer,
    NewDepartmentReducer,
    EmplFrPerformanceEvalSlice,
    SavePerfomanceEvalution,
    ThemeCustomizerReducer,
    OnlineJobApplicationReducer,
    DT: DT,
    BillingInvoice,
    CarmensOwnDTLog,
    DTPMDataLog,
    TestDesignatedPositionLog,
    DepartmentRandomDateLog,
    DepartmentJVControlNumber,
    RandomDTLog,
    TDPEmployeeMasterListLog,
    DOAHRJVInternalLog,
    MISDataLog,
    DFWPContactList,
    VendorLog,
    ExitSurveyReducer,
    AdverseAction,
    CBARLog,
    CBAStatus,
    MediaSource,
    WaitingPeriod,
    TrainingLog,
    TypeOfCharges,
    GrievanceAppealStatus,
    NatureOfGrievance,
    GrievanceRequest,
    LTD,
    TypesOfWorkHours,
    RWHR,
    TypeOfTraining,
    SpecMedicalEval,
    StatusOfAPPT,
    TypesOfSME,
    EvalAppeal,
    PERating,
    EvalAppealReason,
    DocumentReducer,
    ChartReducer,
    DocumentUploadReducer,
    LeaveshareFormReducer,
    AuditLog,
    MainMenuReducer,
    SideBarMenuReducer,
    GG1Reducer,
    NpaPopupReducer,
    TrainingReducer,
    JobAnnouncementReducer,
    PositionClassReducer,
    SalaryIncremntReducerUpdate,
    EmployeeDeductionPlanReducer,
    EmployeeSignatureReducer,
    divisionReducer,
  });
};
// const rootReducer = combineReducers({
//   PostReducer,
//   LeaveShareReducer,
//   outsideEmploymentReducer,
//   onlineRecruitmentReducer,
//   performanceEvaluationReducer,
//   employeeReducer,
//   advanceSearchReducer
// });

export default rootReducer;
