const HistoryColumns = [
    {
      title: "Change DateTime",
      field: "changeDateTime",
      locked: false,
      filter: "date",
      format: "MM/DD/YYYY hh:mm A",
      editor: "date",
      width: "auto",
      show: true,
    },
  
    {
      title: "Action Effective Date",
      field: "actionEffectiveDate",
      locked: false,
      filter: "text",
      width: "auto",
      show: true,
    },
    {
      title: "Job Number",
      field: "jobOrderNumber",
      locked: false,
      filter: "text",
      width: "auto",
      show: true,
    },
    {
      title: "Posting Date",
      field: "postingDate",
      locked: false,
      filter: "date",
      format: "MM/DD/YYYY hh:mm A",
      editor: "date",
      width: "auto",
      show: true,
    },
    {
      title: "Term Date",
      field: "termDate",
      locked: false,
      filter: "date",
      format: "MM/DD/YYYY",
      editor: "date",
      width: "auto",
      show: true,
    },
    {
      title: "Final Post",
      field: "finalPost",
      locked: false,
      filter: "text",
      width: "auto",
      show: true,
    },
    {
      title: "Budget Acct No",
      field: "budgetAcctNo",
      locked: false,
      filter: "text",
      width: "auto",
      show: true,
    },
  ];
  
  export default HistoryColumns;
  