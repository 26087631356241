import { Icon } from '@progress/kendo-react-common'
import React from 'react'
import { Row, Col, Card } from 'reactstrap'

const HSAAndPPO = [
  {
    value: "I",
    className: "Class I",
    label: "Subscriber Only",
    key:"HSAANDPPO"
  },
  {
    value: "II",
    className: "Class II",
    label: "Subscriber + Spouse/Domestic Partner",
    key:"HSAANDPPO"
  },
  {
    value: "III",
    className: "Class III",
    label: "Subscriber + Child(ren)",
    key:"HSAANDPPO"
  },
  {
    value: "IV",
    className: "Class IV",
    label: "Subscriber + Spouse/Dom. Partner & Child(ren)",
    key:"HSAANDPPO"
  }
]

const DeductionRSP = [
  {
    value: "I",
    className: "Class I",
    label: "RSP Subscriber Only",
    key:"DeductionRSP"
  },
  {
    value: "IIa",
    className: "Class IIa",
    label: "RSP Subscriber + RSP Spouse/Domestic Partner",
    key:"DeductionRSP"
  },
  {
    value: "IIb",
    className: "Class IIb",
    label: "RSP Subscriber + Non Medicare Spouse/Dom. Partner",
    key:"DeductionRSP"
  },
  {
    value: "III",
    className: "Class III",
    label: "RSP Subscriber + Non Medicare Child(ren)",
    key:"DeductionRSP"
    
  },
  {
    value: "IVa",
    className: "Class IVa",
    label: "RSP Subscriber + RSP Spouse/Dom. Partner + Non Medicare Child(ren)",
    key:"DeductionRSP"

  },
  {
    value: "IVb",
    className: "Class IVb",
    label: "RSP Subscriber + Non Medicare Spouse/Dom. Partner & Child(ren)",
    key:"DeductionRSP"
  },
]

const healthPlamArray = [
  {
    title: "HSA 2000",
    text: "Single Ded. is $2,000 / Family Ded. is $4,000.",
    key:"healthPlan"
  },
  {
    title: "PPO 1500",
    text: "Single Ded. is $1,500 / Family Ded. is $3,0000",
    key:"healthPlan"
  },
  {
    title: "Retiree Supplemental Plan(RSP)",
    text: "Must be enrolled in Medicare A and B and you must fill out Other Insurance below.",
    key:"healthPlan"
  }
]


 

const companyArray = [
  {
    "vendor_name": "Retiree Company A",
    "vendor_items": [
      {
        "vendor_code": "Retiree Supplemental Plan(RSP)",
        "vendor_desc": "Must be enrolled in Medicare A and B and you must fill out Other Insurance below.",
        "vendor_desc1": "Must be enrolled in Medicare A and B and you must fill out Other Insurance below.",
        'key':"retireePlan",
        "classInfo": [
          {
            value: "I",
            className: "Class I",
            label: "RSP Subscriber Only",
            key: "retireeClass",
            amount :"100"
          },
          {
            value: "IIa",
            className: "Class IIa",
            label: "RSP Subscriber + RSP Spouse/Domestic Partner",
            key: "retireeClass",
            amount :"100"
          },
          {
            value: "IIb",
            className: "Class IIb",
            label: "RSP Subscriber + Non Medicare Spouse/Dom. Partner",
            key: "retireeClass",
            amount :"100"
          },
          {
            value: "III",
            className: "Class III",
            label: "RSP Subscriber + Non Medicare Child(ren)",
            key: "retireeClass",
            amount :"100"
          },
          {
            value: "IVa",
            className: "Class IVa",
            label: "RSP Subscriber + RSP Spouse/Dom. Partner + Non Medicare Child(ren)",
            key: "retireeClass",
            amount :"100"
          }, {
            value: "IVb",
            className: "Class IVb",
            label: "RSP Subscriber + Non Medicare Spouse/Dom. Partner & Child(ren)",
            key: "retireeClass",
            amount :"100"
          }
        ]
      },
      
    ]
  },
  
  
]


const RetireePlan = (props) => {
  const { renderTextInput, renderCheckbox,renderGroupCheckbox, renderDropDown, renderDateInput,setFieldValue } = props
  return (
    <Card style={{ padding: "20px" }}>
      {/* <Row>
        <Col md={3}><b style={{ fontSize: "18px" }}>Health Plan Choice</b></Col>
        {healthPlamArray?.map((item) => {
          return (
            <>
              <Col md={3}>
                <div style={{ display: "flex" }}>
                  <div>{renderCheckbox("large",item.key , item.title)}</div> <div style={{ alignSelf: "center" }}> <b style={{ fontSize: "15px", alignItems: "center" }}>{item.title}</b>
                    <div>{item.text}</div></div>
                </div>
              </Col>
            </>
          )
        })}
      </Row> */}
   
      <Row>

      {companyArray.map((item, ind) => {
              return <Col md={ 12 } className='p-2' key={ind}>
                <Card className='rounded' style={{margin:'10px',marginBottom:'15px'}}>
                  
                <table className=' ' style={{ width: "100%" }}>
                    <tr style={{ margin: "10px" }}>
                      <th className='p-2 bg-primary text-white rounded-top'>{ item.vendor_name }</th>
                      
                    </tr>
                    {item?.vendor_items?.length ? item.vendor_items.map((i, index) => {
                      return <> <tr style={{ cursor: "pointer", backgroundColor: '#ffe4c4', padding: "5px" }} key={index}
                        onClick={(e) => { 
                          setFieldValue("retireeVendor",item?.vendor_name)
                          setFieldValue("retireePlan",item?.vendor_code)
                        }}
                      >
                        <td>
                          { renderGroupCheckbox("medium", 'retireePlan' ,   i.vendor_code,{matchkey:'retireeVendor',matchvalue:item?.vendor_name} ) } <b>{ i.vendor_code }</b>
                        </td>
                        
                      </tr>
                      {
                          i?.classInfo.map((k) => {
                            return <tr style={{ cursor: "pointer", backgroundColor: index % 2 ? "#e6e6e6" : "", padding: "5px" }}><div style={{ display: "flex" }}>
                            <div style={{width:'140px'}}>{renderGroupCheckbox("small",k.key,k.value,{changeTarget1:'retireeVendor',changeTargetValue1:item?.vendor_name,changeTarget2:'retireePlan',changeTargetValue2:i.vendor_code,matchkey:'retireeVendor',matchvalue:item?.vendor_name,matchkey1:'retireePlan',matchvalue1:i.vendor_code})}  <b style={{ alignSelf: "center" }}>{k.className}</b></div>
                            <div style={{ marginTop: "8px" }}>{k.label} {k.amount?`$${k.amount}`:''}</div>
                          </div></tr>
                          })
                        }
                        </>
                    }) : null}
                  </table>
                </Card>
              </Col>
            })}

      
        {/* <Col md={ 6 }>
          

          <div style={{ fontSize: "15px" }}>
            <div><b>Duduction Class for</b></div>
            <div><b>HSA2000 and PPO 1500 Plans</b></div>
          </div>
          {HSAAndPPO?.map((item) => {
            return (
              <div style={{ display: "flex" }}>
                <div style={{width:'110px'}}>{renderCheckbox("small",item.key,item.value)}  <b style={{ alignSelf: "center" }}>{item.className}</b></div>
                <div style={{ marginTop: "8px" }}>{item.label}</div>
              </div>
            )
          })}


        </Col> */}
        {/* <Col md={6}>
          <div style={{ fontSize: "15px" }}>
            <div><b>Duduction Class for RSP</b></div>
          </div>
          {DeductionRSP?.map((item) => {
            return (
              <div style={{ display: "flex" }}>
                <div style={{width:'155px'}}>{renderCheckbox("small", item.key, item.value)}  <b style={{ width: "130px" }}>{item.className}</b></div>
                <div style={{ marginTop: "8px",width:'-webkit-fill-available' }}>{item.label}</div>
              </div>
            )
          })}
        </Col> */}
      </Row>

    </Card>
  )
}

export default RetireePlan