import {
  GG1_ACTION_DELETE,
  GG1_ACTION_DELETE_FAIL,
  GG1_ACTION_DELETE_SUCCESS,
  GET_MASETR_DATA,
  GET_MASETR_DATA_SUCCESS,
  GET_MASETR_DATA_FAIL,
  GET_POSITION_SALARY_BY_GRADE,
  GET_POSITION_SALARY_BY_GRADE_SUCCESS,
  GET_POSITION_SALARY_BY_GRADE_FAIL,
  ADD_GG1_EMPLOYEE,
  ADD_GG1_EMPLOYEE_SUCCESS,
  ADD_GG1_EMPLOYEE_FAIL,
  GG1ANLYSTUPDATE_SUCCESS,
  GG1ANLYSTUPDATE_FAIL,
  RESET,
  GG1ANLYSTUPDATE,
} from "./actionTypes";

const initialState = {
  gg1List: {},
  gg1analyst:{},
  loading: false,
  gg1loading:false,
  error: {
    message: "",
  },
};

const GG1Reducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GG1_ACTION_DELETE:
      state = { ...state, loading: true };
      break;
    case GG1_ACTION_DELETE_SUCCESS:
      state = { ...state, loading: false };
      break;
    case GG1_ACTION_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    case GET_MASETR_DATA:
      state = { ...state, loading: true };
      break;
    case GET_MASETR_DATA_SUCCESS:
      state = { ...state, loading: false };
      break;
    case GET_MASETR_DATA_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;

    case GET_POSITION_SALARY_BY_GRADE:
      state = { ...state, loading: true };
      break;
    case GET_POSITION_SALARY_BY_GRADE_SUCCESS:
      state = { ...state, loading: false };
      break;
    case GET_POSITION_SALARY_BY_GRADE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
      case GG1ANLYSTUPDATE:
        state = { ...state, gg1analyst: {}, gg1loading: true };
        break;
      case GG1ANLYSTUPDATE_SUCCESS:
        state = {
          ...state,
          gg1analyst: action.payload,
          gg1loading: false,
        };
        break;
  
      case GG1ANLYSTUPDATE_FAIL:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          gg1analyst: {},
          gg1loading: false,
        };
        break;
    case ADD_GG1_EMPLOYEE:
      state = { ...state, loading: true };
      break;
    case ADD_GG1_EMPLOYEE_SUCCESS:
      state = { ...state, loading: false };
      break;
    case ADD_GG1_EMPLOYEE_FAIL:
      state = {
        ...state,
        error: {
          message: action.payload,
        },
        loading: false,
      };
      break;
    case RESET:
      state = { ...state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GG1Reducer;
