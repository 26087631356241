export const GET_TRAININGS = "GET_TRAININGS";
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS";
export const GET_TRAININGS_FAIL = "GET_TRAININGS_FAIL";

export const GET_TRAININGS_DETAILS = "GET_TRAININGS_DETAILS";
export const GET_TRAININGS_DETAILS_SUCCESS = "GET_TRAININGS_DETAILS_SUCCESS";
export const GET_TRAININGS_DETAILS_FAIL = "GET_TRAININGS_DETAILS_FAIL";

export const TRAINING_UPDATE= "TRAINING_UPDATE";
export const TRAINING_UPDATE_SUCCESS = "TRAINING_UPDATE_SUCCESS";
export const TRAINING_UPDATE_FAIL = "TRAINING_UPDATE_FAIL";

export const TRAINING_CREATE= "TRAINING_CREATE";
export const TRAINING_CREATE_SUCCESS = "TRAINING_CREATE_SUCCESS";
export const TRAINING_CREATE_FAIL = "TRAINING_CREATE_FAIL";

export const TRAINING_DELETE= "TRAINING_DELETE";
export const TRAINING_DELETE_SUCCESS = "TRAINING_DELETE_SUCCESS";
export const TRAINING_DELETE_FAIL = "TRAINING_DELETE_FAIL";
export const RESET = "RESET";

