import {
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_SUCCESS,
  ADVANCE_SEARCH_FAIL,
  ADVANCE_SEARCH_POSITION,
  ADVANCE_SEARCH_POSITION_SUCCESS,
  ADVANCE_SEARCH_POSITION_FAIL,
  ADVANCE_SEARCH_STATUS,
  ADVANCE_SEARCH_CALCULATE_SVC,
  ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS,
  ADVANCE_SEARCH_CALCULATE_SVC_FAIL,
  ADVANCE_SEARCH_TOTAL_SVC,
  ADVANCE_SEARCH_TOTAL_SVC_SUCCESS,
  ADVANCE_SEARCH_TOTAL_SVC_FAIL,
  ADVANCE_SEARCH_STATUS_SUCCESS,
  ADVANCE_SEARCH_STATUS_FAIL,
  ADVANCE_SEARCH_RETIREMENT,
  ADVANCE_SEARCH_RETIREMENT_SUCCESS,
  ADVANCE_SEARCH_RETIREMENT_FAIL,
  ADVANCE_SEARCH_APPROPRIATION,
  ADVANCE_SEARCH_APPROPRIATION_SUCCESS,
  ADVANCE_SEARCH_APPROPRIATION_FAIL,
  LOAD_COLUMNS,
  LOAD_COLUMNS_SUCCESS,
  LOAD_COLUMNS_FAIL,
  FINDASYOUTYPE,
  FINDASYOUTYPE_SUCCESS,
  FINDASYOUTYPE_FAIL,
  SAVE_ADVANCE_SEARCH,
  SAVE_ADVANCE_SEARCH_SUCCESS,
  SAVE_ADVANCE_SEARCH_FAIL,
  GET_ADVANCE_SEARCH_BYID,
  GET_ADVANCE_SEARCH_BYID_SUCCESS,
  GET_ADVANCE_SEARCH_BYID_FAIL,
  SHOWN_TOASTER,
  GET_ALL_ADVANCE_SEARCH,
  GET_ALL_ADVANCE_SEARCH_SUCCESS,
  GET_ALL_ADVANCE_SEARCH_FAIL,
  SAVE_QUERY,
  SAVE_LOCATION,
  GG1_EXCEL_REPORT,
  GG1_EXCEL_REPORT_SUCCESS,
  GG1_EXCEL_REPORT_FAIL,
  DELETE_SAVED_QUERY,
  DELETE_SAVED_QUERY_SUCCESS,
  DELETE_SAVED_QUERY_FAIL,
  ADVANCE_SEARCH_CITIZEN,
  ADVANCE_SEARCH_CITIZEN_SUCCESS,
  ADVANCE_SEARCH_CITIZEN_FAIL,
  ADVANCE_SEARCH_EDUCATION,
  ADVANCE_SEARCH_EDUCATION_SUCCESS,
  ADVANCE_SEARCH_EDUCATION_FAIL,
  ADVANCE_SEARCH_EMPL_TYPE,
  ADVANCE_SEARCH_EMPL_TYPE_SUCCESS,
  ADVANCE_SEARCH_EMPL_TYPE_FAIL,
  ADVANCE_SEARCH_ETHINIC,
  ADVANCE_SEARCH_ETHINIC_SUCCESS,
  ADVANCE_SEARCH_ETHINIC_FAIL,
  ADVANCE_SEARCH_MILITARY,
  ADVANCE_SEARCH_MILITARY_SUCCESS,
  ADVANCE_SEARCH_MILITARY_FAIL,
  ADVANCE_SEARCH_DEPARTMENT,
  ADVANCE_SEARCH_DEPARTMENT_SUCCESS,
  ADVANCE_SEARCH_DEPARTMENT_FAIL,
  SALARY_INCREMENT,
  SALARY_INCREMENT_SUCCESS,
  SALARY_INCREMENT_FAIL,
  PAYGRADE_CALCULATE,
  PAYGRADE_CALCULATE_SUCCESS,
  PAYGRADE_CALCULATE_FAIL,
} from "./actionTypes";

const initialState = {
  searchResults: {},
  searchResultsV2: {},
  salaryResults: {},
  paygreadeResults: {},
  education: [],
  ethinic: [],
  military: [],
  emplType: [],
  position: [],
  currentSVC: [],
  totalSVC: [],
  status: [],
  retirement: [],
  appropriation: [],
  savedQuery: [],
  savedLocation: "",
  departmentDivision: {},
  columns: [],
  empTypes: [],
  employee: {},
  findAsyouTypeResults: [],
  loadingFindAsYouType: false,
  loadingSearch: false,
  loadingColumns: false,
  loadingDeductionPlans: false,
  loadingEmployeeTypes: false,
  loadingEmployee: false,
  loadingPerformSubscription: false,
  lodingSaveType: null,
  getAdavanceSearch: [],
  getAdavanceSearchType: "",
  hasShownToaster: false,
  userSavedQueries: [],
  GG1ExcelReportResult: {},
  searchParameter: {},
  salaryParametr: {},
  gradeParamter: {},
  saveQueriesData: {},
  ColumnsSorted: {},
  deleteResults: {},
  activeResp: {},
  loadingExcelReport: false,
  fileExtension: "",
  loading: "",
  error: {
    message: "",
  },
};

const AdvanceSearchReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case ADVANCE_SEARCH:
      state = {
        ...state,
        searchParameter: action.payload,

        currentID: action.currentID,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_SUCCESS:
      state = {
        ...state,
        searchResults: action.payload,
        searchResultsV2: {
          ...state.searchResultsV2,
          [state.currentID]: action.payload,
        },
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        searchResults: {},
      };
      break;
    case PAYGRADE_CALCULATE:
      state = {
        ...state,
        gradeParamter: action.payload,
        currentID: action.currentID,
        loadingSearch: true,
      };
      break;
    case PAYGRADE_CALCULATE_SUCCESS:
      state = {
        ...state,
        paygreadeResults: action.payload,
        loadingSearch: false,
      };
      break;

    case PAYGRADE_CALCULATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        paygreadeResults: {},
      };
      break;
    case SALARY_INCREMENT:
      state = {
        ...state,
        salaryParametr: action.payload,
        currentID: action.currentID,
        loadingSearch: true,
      };
      break;
    case SALARY_INCREMENT_SUCCESS:
      state = { ...state, salaryResults: action.payload, loadingSearch: false };
      break;

    case SALARY_INCREMENT_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        salaryResults: {},
      };
      break;
    case ADVANCE_SEARCH_POSITION:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_POSITION_SUCCESS:
      state = {
        ...state,
        position: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_POSITION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        position: [],
      };
      break;
    case ADVANCE_SEARCH_STATUS:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_STATUS_SUCCESS:
      state = {
        ...state,
        status: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_STATUS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        status: [],
      };
      break;

    case ADVANCE_SEARCH_DEPARTMENT:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_DEPARTMENT_SUCCESS:
      state = {
        ...state,
        departmentDivision: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_DEPARTMENT_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        position: {},
      };
      break;
    case ADVANCE_SEARCH_CALCULATE_SVC:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS:
      state = {
        ...state,
        currentSVC: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_CALCULATE_SVC_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        position: {},
      };
      break;
    case ADVANCE_SEARCH_TOTAL_SVC:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_TOTAL_SVC_SUCCESS:
      state = {
        ...state,
        totalSVC: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_TOTAL_SVC_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        position: {},
      };
      break;
    case ADVANCE_SEARCH_RETIREMENT:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_RETIREMENT_SUCCESS:
      state = {
        ...state,
        retirement: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_RETIREMENT_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
      };
      break;
    case ADVANCE_SEARCH_APPROPRIATION:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_APPROPRIATION_SUCCESS:
      state = {
        ...state,
        appropriation: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_APPROPRIATION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_MILITARY:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_MILITARY_SUCCESS:
      state = {
        ...state,
        military: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_MILITARY_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        citizen: {},
      };
      break;
    case ADVANCE_SEARCH_CITIZEN:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_CITIZEN_SUCCESS:
      state = {
        ...state,
        citizen: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_CITIZEN_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        citizen: {},
      };
      break;

    case ADVANCE_SEARCH_EMPL_TYPE:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_EMPL_TYPE_SUCCESS:
      state = {
        ...state,
        emplType: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_EMPL_TYPE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        emplType: {},
      };
      break;
    case ADVANCE_SEARCH_ETHINIC:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_ETHINIC_SUCCESS:
      state = {
        ...state,
        ethinic: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_ETHINIC_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        ethinic: {},
      };
      break;
    case ADVANCE_SEARCH_EDUCATION:
      state = {
        ...state,
        loadingSearch: true,
      };
      break;
    case ADVANCE_SEARCH_EDUCATION_SUCCESS:
      state = {
        ...state,
        education: action.payload?.results,
        loadingSearch: false,
      };
      break;

    case ADVANCE_SEARCH_EDUCATION_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingSearch: false,
        education: {},
      };
      break;
    case "STORED_ACTIVE_DATA":
      state = {
        ...state,
        activeResp: action.payload,
      };
      break;
    case "STORED_COLUMNS":
      state = {
        ...state,
        ColumnsSorted: action.payload,
      };
      break;
    case GG1_EXCEL_REPORT:
      const { fileExtension } = action.payload;
      state = {
        ...state,
        fileExtension: fileExtension,
        loadingExcelReport: true,
      };
      break;
    case GG1_EXCEL_REPORT_SUCCESS:
      state = {
        ...state,
        GG1ExcelReport: action.payload,
        loadingExcelReport: false,
      };
      break;
    case GG1_EXCEL_REPORT_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingExcelReport: false,
      };
      break;
    case DELETE_SAVED_QUERY:
      state = {
        ...state,
        loading: true,
        deleteResults: {},
      };
      break;
    case DELETE_SAVED_QUERY_SUCCESS:
      state = {
        ...state,
        deleteResults: action.payload,
        loading: false,
      };
      break;
    case DELETE_SAVED_QUERY_FAIL:
      state = {
        ...state,
        deleteResults: {},
        error: {
          message: "Error",
          loading: false,
        },
      };
      break;
    case SAVE_QUERY:
      state = { ...state, savedQuery: [...action.payload] };
      break;
    case SAVE_LOCATION:
      state = { ...state, savedLocation: action.payload };
      break;

    case SAVE_ADVANCE_SEARCH:
      state = {
        ...state,
        lodingSaveType: action.type,
      };
      break;
    case SAVE_ADVANCE_SEARCH_SUCCESS:
      state = {
        ...state,
        saveQueriesData: { ...action.payload, id: action.currentID },
        hasShownToaster: false,
        lodingSaveType: action.type,
      };
      break;
    case SAVE_ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
          id: action.currentID,
        },
        hasShownToaster: false,
        lodingSaveType: action.type,
      };
      break;
    case SHOWN_TOASTER:
      state = {
        ...state,
        hasShownToaster: action.payload,
      };
      break;
    case GET_ADVANCE_SEARCH_BYID:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: action.type,
      };
      break;
    case GET_ADVANCE_SEARCH_BYID_SUCCESS:
      state = {
        ...state,
        getAdavanceSearch: action.payload,
        getAdavanceSearchType: action.type,
      };
      break;
    case GET_ADVANCE_SEARCH_BYID_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        getAdavanceSearch: [],
        getAdavanceSearchType: action.type,
      };
      break;

    case GET_ALL_ADVANCE_SEARCH:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        // userSavedQueries: [],
      };
      break;

    case GET_ALL_ADVANCE_SEARCH_SUCCESS:
      state = {
        ...state,
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        userSavedQueries: action.payload,
      };
      break;
    case GET_ALL_ADVANCE_SEARCH_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        getAdavanceSearch: [],
        getAdavanceSearchType: "",
        userSavedQueries: [],
      };
      break;

    case LOAD_COLUMNS:
      state = { ...state, loadingColumns: true };
      break;
    case LOAD_COLUMNS_SUCCESS: {
      state = { ...state, columns: [...action.payload], loadingColumns: false };
      break;
    }
    case LOAD_COLUMNS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingColumns: false,
      };
      break;
    case FINDASYOUTYPE:
      state = {
        ...state,
        findAsyouTypeResults: [],
        loadingFindAsYouType: true,
      };
      break;
    case FINDASYOUTYPE_SUCCESS: {
      state = {
        ...state,
        findAsyouTypeResults: action.payload,
        loadingFindAsYouType: false,
      };
      break;
    }
    case FINDASYOUTYPE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingFindAsYouType: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AdvanceSearchReducer;
