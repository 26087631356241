const visileColumns = [
  {
    title: "Prob End Date",
    field: "ProbationaryEndDate",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: "auto",
    show: true,
  },
  {
    title: "Employee Name",
    field: "EmployeeName",
    locked: true,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title:"Department",
    field:"Department_name",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
    remove:true
  },
  {
    title: "Title",
    field: "TITLE",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },

  ];
  
  export default visileColumns;
  