import {
  GET_POSITIONS,
  GET_POSITIONS_SUCCESS,
  GET_POSITIONS_FAIL,
  JOB_ANNOUCEMENT_DETAILS,
  JOB_ANNOUCEMENT_DETAILS_SUCCESS,
  JOB_ANNOUCEMENT_DETAILS_FAIL,
  JOB_ANNOUNCEMENT_CREATE,
  JOB_ANNOUNCEMENT_CREATE_FAIL,
  JOB_ANNOUNCEMENT_CREATE_SUCCESS,
  JOB_ANNOUCEMENT_DELETE,
  JOB_ANNOUCEMENT_DELETE_FAIL,
  JOB_ANNOUCEMENT_DELETE_SUCCESS,
  JOB_ANNOUNCEMENT_UPDATE,
  JOB_ANNOUNCEMENT_UPDATE_FAIL,
  JOB_ANNOUNCEMENT_UPDATE_SUCCESS,
  SHOWN_TOASTER,
} from "./actionTypes";

const initialState = {
  positionList: [],
  jobAnnouncementDetails: {},
  postingResult: {},
  loadingPositionList: false,
  loadingPositionDetails: false,
  positionSetting: {},
  loadingpositionSetting: false,
  posting: false,
  hasShownToaster: false,
  error: {
    message: "",
  },
};

const JobAnnouncementReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_POSITIONS:
      state = { ...state, loadingPositionList: false };
      break;
    case GET_POSITIONS_SUCCESS:
      state = {
        ...state,
        positionList: action.payload,
        loadingPositionList: false,
      };
      break;
    case GET_POSITIONS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPositionList: false,
      };
      break;
    case JOB_ANNOUCEMENT_DETAILS:
      state = { ...state, loadingPositionDetails: true };
      break;
    case JOB_ANNOUCEMENT_DETAILS_SUCCESS:
      state = {
        ...state,
        jobAnnouncementDetails: action.payload,
        loadingPositionDetails: false,
      };
      break;
    case JOB_ANNOUCEMENT_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPositionDetails: false,
      };
      break;
    case JOB_ANNOUNCEMENT_CREATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case JOB_ANNOUNCEMENT_CREATE_SUCCESS:
      state = {
        ...state,
        postingResult: action.payload,
        hasShownToaster: false,
        posting: false,
      };
      break;
    case JOB_ANNOUNCEMENT_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        posting: false,
      };
      break;
    case JOB_ANNOUNCEMENT_UPDATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case JOB_ANNOUNCEMENT_UPDATE_SUCCESS:
      state = {
        ...state,
        postingResult: action.payload,
        hasShownToaster: false,
        posting: false,
      };
      break;
    case JOB_ANNOUNCEMENT_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case "RESET":
      state = { ...state, jobAnnouncementDetails: {} };
    case SHOWN_TOASTER:
      state = {
        ...state,
        hasShownToaster: action.payload,
      };
      break;
    case JOB_ANNOUCEMENT_DELETE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case JOB_ANNOUCEMENT_DELETE_SUCCESS:
      state = {
        ...state,
        postingResult: action.payload?.data,
        hasShownToaster: false,
        posting: false,
      };
      break;
    case JOB_ANNOUCEMENT_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JobAnnouncementReducer;
