import React, { useEffect } from "react";

import {
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Popover,
  Checkbox,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { storeColumns } from "../../store/advanceSearchv2/actions";
import { useState } from "react";
import { H6 } from "../../AbstractElements";
import { gridDataTable } from "../../store/themeCustomizer/action";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { Icon } from "@progress/kendo-react-common";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/pro-duotone-svg-icons";
const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

export default function ColumnFilter(props) {
  const { ColumnsSorted } = useSelector(
    (state) => state.advanceSearchv2Reducer
  );
  const { GridData = {} } = useSelector(
    (state) => state?.ThemeCustomizerReducer || {}
  );
  const dispatch = useDispatch();
  const { columns, setResult, id, setSortedColumns, gridDataPayload } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlseSelect = (option) => {
    console.log("option 123", option);
  };

  const handleRemoveColumn = async (field, showField) => {
    // This function used  to ordering fields
    let copyArray = [...columns];

    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field == field;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["show"] = showField;
      copyArray[objectAtIndex] = copyObject;
      setResult({ data: [] });
      if (ColumnsSorted) {
        const obj = { ...ColumnsSorted };
        obj[id] = copyArray;
        dispatch(storeColumns(obj));
        dispatch(
          gridDataTable({
            ...gridDataPayload,
            ColumnsSorted: {
              ...GridData?.ColumnsSorted,
              [id]: copyArray,
            },
          })
        );
      }
      setSortedColumns(copyArray);
    }
  };
  const handleFilterChange = (event) => {
    setValue(event.target.value);
  };

  // const filteredColumns =
  //   columns &&
  //   columns.length > 0 &&
  //   columns.filter(
  //     (column) =>
  //       column.title.toLowerCase().includes(value.toLowerCase()) &&
  //       !column.locked
  //   );
  const filteredColumns =
    columns &&
    columns.length > 0 &&
    columns.filter(
      (column) =>
        column && // Check if column is defined
        column.title && // Check if column title is defined
        column.title.toLowerCase().includes(value.toLowerCase()) && // Proceed if column and its title are defined and title is a string
        !column.locked
    );

  return (
    <>
      <div>
        <Tooltip title="Filter Column" placement="bottom">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            {/* <FontAwesomeIcon
              icon={faBars}
              className="font-primary"
              rotation={90}
            /> */}
            {/* <i class="fa-duotone fa-bars fa-rotate-90 font-primary"></i> */}
            <Icon style={{ fontSize: "25px" }} name="filter-add-expression" />

            {/* <ViewColumnIcon /> */}
          </IconButton>
        </Tooltip>

        <Popover
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          style={{ zIndex: "10004" }}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,

              // width: "200px",
            },
          }}
        >
          <div className="customCheck">
            <TextField
              label="Filter Columns"
              value={value}
              onChange={handleFilterChange}
              // fullWidth
              size="small"
              style={{ margin: 10 }}
            />
          </div>
          {/* props.columns */}
          {filteredColumns && filteredColumns.length > 0 ? (
            filteredColumns.map((option, index) => {
              return (
                <MenuItem
                  style={{ backgroundColor: "white", width: "auto" }}
                  key={`1e+${index}`}
                  // onClick={() => handlseSelect(option.title)}
                  onClick={() => handleRemoveColumn(option.field, !option.show)}
                >
                  <Checkbox
                    checked={option.show}
                    style={{ padding: "0", margin: "0" }}
                  />
                  {option.title}
                </MenuItem>
              );
            })
          ) : (
            <H6
              attrH6={{
                style: {
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                },
              }}
            >
              Not Found !
            </H6>
          )}
        </Popover>
      </div>
      <div onClick={() => setAnchorEl(null)}></div>
    </>
  );
}
