import React from "react";
import { filterAllowedColumn } from "../../_helper/helper";

export const loadingPanel = () => {
  return (
    <div
      className="k-loading-mask"
      style={{
        position: `fixed`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 100005,
      }}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};

export const LoadingPanel = ({ position = "fixed" }) => {
  return (
    <div
      className="k-loading-mask"
      style={{
        position: position,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}>
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};
export function findMaxDigitsAmongFields(data, fields) {
  let maxDigits = 0;

  data &&
    data.length > 0 &&
    data.forEach((item) => {
      fields.forEach((field) => {
        const value = item[field];
        if (typeof value === "number") {
          const numDigits = value.toString().replace(".", "").length;
          if (numDigits > maxDigits) {
            maxDigits = numDigits;
          }
        }
      });
    });

  return maxDigits;
}
export const ConcatenedColumns = (
  columns,
  HistoryColumns,
  setSortedColumns,
  userData
) => {
  if (columns && HistoryColumns.length > 0 && columns.length > 0) {
    const clms = HistoryColumns.map((d) => d.field.toLowerCase());
    const data = columns
      .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
      .map((p) => {
        return {
          field:
            p.column_name.indexOf("_") > -1
              ? p.column_name
                .slice(0, p.column_name.indexOf("_") - 1)
                .toLowerCase() +
              p.column_name.slice(p.column_name.indexOf("_") - 1)
              : p.column_name.charAt(0).toLowerCase() + p.column_name.slice(1),
          show: false,
          locked: false,
          width: 200,
          title: p.column_title,
          filter: "text",
        };
      });

    const sortedData = filterAllowedColumn(
      userData,
      HistoryColumns.concat(data),
      "id"
    );

    setSortedColumns(sortedData);
  }
};
export const sortingFunc = (sortData, historyData, setHistoryData) => {
  const data = [...historyData].sort((a, b) => {
    return sortData
      .map((sortItem) => {
        const { field, dir } = sortItem;
        if (a[field] < b[field]) return dir === "desc" ? 1 : -1;
        if (a[field] > b[field]) return dir === "desc" ? -1 : 1;
        return 0;
      })
      .reduce((acc, result) => {
        return acc !== 0 ? acc : result;
      }, 0);
  });

  setHistoryData(data);
};
