import * as Actions from "./actions";
import * as ActionTypes from "./actionTypes";
// import { useLocation } from "react-router";
const initialState = {
  loading: false,
  postingResult: {},
  leaveApplicationList: {},
  FetchLeaveApplication: {},
  FetchLeaveData: {},
  deleteResponse: {},
  CalculateHours: 0,
  LeaveApplicationHistory: [],

  leaveShareDetail: {
    donor: [
      {
        id: 0,
        ssn: "",
        first_name: "",
        last_name: "",
        mi: "",
        department: "",
        position: "",
        grade: "",
        step: "",
        rate: "",
        donor_hours: "",
        leave_type: "",
        remarks: "",
      },
    ],
    ssn: "333-10-1001",
    first_name: "Robert",
    last_name: "Duenas",
    mi: "M",
    department: "DOA-Personnel Management121",
    position: "EQUAL EMPLOYMENT OPPORTUNITY",
    grade: "L",
    step: "09",
    rate: "38.67",
  },
  userList: [
    {
      ssn: "1",
      first_name: "fName1",
      last_name: "lName1",
      mi: "mi",
      department: "department",
      position: "position1",
      step: "1",
      grade: "A",
      rate: "12",
    },
    {
      ssn: "2",
      first_name: "fName2",
      last_name: "lName2",
      mi: "mi",
      department: "department",
      position: "position2",
      step: "2",
      grade: "A",
      rate: "22",
    },
    {
      ssn: "3",
      first_name: "fName3",
      last_name: "lName3",
      mi: "mi",
      department: "department",
      position: "position3",
      step: "3",
      grade: "A",
      rate: "32",
    },
    {
      ssn: "4",
      first_name: "fName4",
      last_name: "lName4",
      mi: "mi",
      department: "department",
      position: "position4",
      step: "4",
      grade: "A",
      rate: "42",
    },
  ],
  filterBy: {
    hrControl: "",
    ssn: "",
    name: "",
    date: null,
    status: "",
  },
  leaveshareList: [
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "335516523",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-123456",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Disapproved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "125644343",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-1234356",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "564566123",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-345345345",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "876565123",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-345345789",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "765656123",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-123456",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "756712883",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-123457",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "567126573",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-123452",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "415267673",
      first_name: "first name",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "LS-123453",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
  ],
};

const LeaveShare = (state = initialState, action) => {
  // const location = useLocation();
  // console.log("location.state.data ::: ", action, state);
  if (!action) return state;
  switch (action.type) {
    case Actions.CHANGE_HEADER_FILTER: {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          [action.payload.data &&
          action.payload.data.target &&
          action.payload.data &&
          action.payload.data.target.name]:
            action.payload.data &&
            action.payload.data.target &&
            action.payload.data &&
            action.payload.data.target.value,
        },
      };
    }
    case ActionTypes.SHOW_LOADER:
      return { ...state, isLoading: true };
    case ActionTypes.HIDE_LOADER:
      return { ...state, isLoading: false };

    case Actions.UPDATE_LEAVE_SHARE_DETAIL: {
      return {
        ...state,
        leaveShareDetail: action.payload,
      };
    }
    case Actions.RESET_FILTER: {
      return {
        ...state,
        filterBy: {
          hrControl: "",
          ssn: "",
          name: "",
          date: null,
          status: "",
        },
      };
    }
    case ActionTypes.REQ_LEAVE_APPLICATION: {
      return { ...state, postingResult: {}, loading: true };
    }
    case ActionTypes.REQ_LEAVE_APPLICATION_SUCCESS: {
      return { ...state, postingResult: action.response, loading: false };
    }
    case ActionTypes.FIND_LEAVE_APPLICATION:
      return { ...state };
    case ActionTypes.FIND_LEAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        FetchLeaveData: { ...action.response },
      };
    case ActionTypes.LEAVE_APPLICATION_HISTORY:
      return { ...state };
    case ActionTypes.LEAVE_APPLICATION_HISTORY_SUCCESS:
      return { ...state, LeaveApplicationHistory: [...action.response] };
    case ActionTypes.LEAVE_APPLICATION_DETAILS:
      return { ...state, loading: true };
    case ActionTypes.LEAVE_APPLICATION_DETAILS_SUCCESS:
      return {
        ...state,
        FetchLeaveApplication: { ...action.response },
        loading: false,
      };
    case ActionTypes.EDIT_LEAVE_APPLICATION:
      return { ...state, postingResult: {}, loading: true };
    case ActionTypes.EDIT_LEAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        postingResult: { ...action.response },
        loading: false,
      };
    case ActionTypes.DELETE_LEAVE_APPLICATION:
      return { ...state, loading: true };
    case ActionTypes.DELETE_LEAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        deleteResponse: { ...action.response },
        loading: false,
      };
    case ActionTypes.UPDATE_LEAVE_STATUS:
      return { ...state, loading: true, postingResult: {} };
    case ActionTypes.UPDATE_LEAVE_STATUS_SUCCESS:
      return {
        ...state,
        postingResult: { ...action.response },
        loading: false,
      };
    case ActionTypes.CALCULATE_HOURS:
      return { ...state };
    case ActionTypes.CALCULATE_HOURS_SUCCESS:
      return { ...state, CalculateHours: action.response };
    default: {
      return state;
    }
  }
};

export default LeaveShare;
