import React, { Fragment, useEffect, useState } from "react";
import List from "@mui/material/List";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Autocomplete, TextField, Button } from "@mui/material";
import { Box } from "react-feather";
import { Col, Row } from "reactstrap";
// import "../../../styles/custom.css";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  listHeight: {
    height: "calc(100vh - 100px - 317px)",
    maxHeight: "calc(100vh - 100px - 317px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 180px)",
      maxHeight: "calc(100vh - 100px - 180px)",
    },
  },
  listHeightError: {
    height: "calc(100vh - 100px - 256px)",
    maxHeight: "calc(100vh - 100px - 256px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 239px)",
      maxHeight: "calc(100vh - 100px - 239px)",
    },
  },
}));
const ChipsSelection = (props) => {
  const { setSelected, selected, selectUpdate } = props;
  const [filterText, setFilterText] = useState("");
  const [selectedPositions, setSelectedPositions] = useState(selected);
  const [positionList, setPositionList] = useState(props.options);

  return (
    <div className="  w-full flex-col">
      <Typography className="flex w-full justify-center py-8 bg-gray-200 text-gray-600 text-18">
        {`Search ${props.labelName} in which you are interested`}
      </Typography>

      <TextField
        margin="dense"
        className="flex w-full "
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        inputProps={{
          style: {
            fontSize: "14px",
            color: "black",
            paddingTop: "12px",
          },
        }}
        label={`Search ${props.labelName} `}
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />

      <div className="d-flex align-content-start flex-wrap">
        {positionList
          .filter((item) => {
            return selectedPositions.find((d) => d == item.value) != null;
          })
          .map((i, index) => {
            return (
              <div className="p-1">
                <input
                  type="checkbox"
                  class="btn-check font-primary"
                  value={i.value}
                  tabIndex={-1}
                  name="positionList"
                  id={i.value}
                  onClick={() => {
                    let selectedItems = [...selectedPositions];
                    if (selectedItems.find((d) => d === i.value) != null)
                      selectedItems = selectedItems.filter(
                        (d) => d !== i.value
                      );
                    else selectedItems.push(i.value);
                    setSelectedPositions([...selectedItems]);
                    setSelected(
                      [...selectedItems]
                    );

                  }}
                  checked={selectedPositions.find((d) => d === i.value) != null}
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary chipbtnchecked"
                  for={i.value}
                >
                  {i.label}
                </label>
              </div>
            );
          })}
        {positionList
          .filter((item) => {
            return (
              selectedPositions.find((d) => d === item.value) == null &&
              item.label.toLowerCase().indexOf(filterText.toLocaleLowerCase()) >
              -1
            );
          })
          .map((i, index) => {
            return (
              <div className="p-1">
                <input
                  type="checkbox"
                  class="btn-check font-primary"
                  value={i.value}
                  tabIndex={-1}
                  name="positionList"
                  id={i.value}
                  onClick={() => {
                    let selectedItems = [...selectedPositions];
                    if (selectedItems.find((d) => d === i.value) != null)
                      selectedItems = selectedItems.filter(
                        (d) => d !== i.value
                      );
                    else selectedItems.push(i.value);
                    setSelectedPositions([...selectedItems]);
                    setSelected(
                      [...selectedItems]
                    );

                  }}
                  checked={selectedPositions.find((d) => d === i.value) != null}
                  autocomplete="off"
                />
                <label
                  className="btn btn-outline-primary chipbtn"
                  for={i.value}
                >
                  {i.label}
                </label>
              </div>
            );
          })}
      </div>
      <div class="d-flex justify-content-end">
        {" "}
        <Button
          variant="outlined"
          className="px-4 py-2"
          style={{
            marginTop: "35px",
          }}
          onClick={() => props.setOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ChipsSelection;
