export const GET_SIDEBARMENU="GET_SIDEBARMENU";
export const GET_SIDEBARMENU_BY_ROLE="GET_SIDEBARMENU_BY_ROLE";
export const GET_SIDEBARMENU_SUCCESS="GET_SIDEBARMENU_SUCCESS";
export const GET_SIDEBAR_FAILURE = "GET_SIDEBAR_FAILURE"

export const GET_ROLES="GET_ROLES";
export const GET_ROLES_SUCCESS="GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const GET_SIDEBARLOOKUP="GET_SIDEBARLOOKUP";
export const GET_SIDEBAR_LIKKUP_SUCCESS="GET_SIDEBAR_LIKKUP_SUCCESS";
export const GET_SIDEBARLOOKUP_FAILURE = "GET_SIDEBARLOOKUP_FAILURE";


export const CREATE_SIDEBARMENU="CREATE_SIDEBARMENU";
export const CREATE_SIDEBAR_SUCCESS="CREATE_SIDEBAR_SUCCESS";
export const CREATE_SIDEBAR_FAILURE = "CREATE_SIDEBAR_FAILURE";

export const GETBY_ID_SIDEBARMENU="GETBY_ID_SIDEBARMENU";
export const GETBY_ID_SIDBARMENU_SUCCESS="GETBY_ID_SIDBARMENU_SUCCESS";
export const GETBY_ID_SIDEBARMENU_FAILURE="GETBY_ID_SIDEBARMENU_FAILURE";

export const DELETE_SIDEBARMENU="DELETE_SIDEBARMENU";
export const DELETE_SIDEBARMENU_SUCCESS="DELETE_SIDEBARMENU_SUCCESS";
export const DELETE_SIDEBARMENU_FAILURE="DELETE_SIDEBARMENU_FAILURE";

export const UPDATE_SIDEBARMENU = "UPDATE_SIDEBARMENU";
export const UPDATE_SIDEBARMENU_SUCCESS = "UPDATE_SIDEBARMENU_SUCCESS";
export const UPDATE_SIDEBARMENU_FAILURE="UPDATE_SIDEBARMENU_FAILURE";

export const GET_MENUITEMS = "GET_MENUITEMS";
export const GET_MENUITEMS_SUCCESS="GET_MENUITEMS_SUCCESS";
export const GET_MENUITEMS_FAILURE="GET_MENUITEMS_FAILURE";

export const UPDATE_SIDEBARMENU_SEQUENCE = "UPDATE_SIDEBARMENU_SEQUENCE";
export const UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS = "UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS";
export const UPDATE_SIDEBARMENU_SEQUENCE_FAILURE="UPDATE_SIDEBARMENU_SEQUENCE_FAILURE";
export const RESET = "RESET";
export const RESET_CREATE_MENU = "RESET_CREATE_MENU";
export const RESET_DELETE = "RESET_DELETE"



