import {
    EXIT_SURVEY_CREATE,
    EXIT_SURVEY_CREATE_SUCCESS,
    EXIT_SURVEY_CREATE_FAIL,
    GET_EXIT_SURVEY_ANSWER,
    GET_EXIT_SURVEY_ANSWER_SUCCESS,
    GET_EXIT_SURVEY_ANSWER_FAIL,
    GET_EXIT_SURVEY_MAIN_REASON,
    GET_EXIT_SURVEY_MAIN_REASON_SUCCESS,
    GET_EXIT_SURVEY_MAIN_REASON_FAIL,
    GET_EXIT_SURVEY_MARTIAL_STATUS,
    GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS,
    GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL,
    GET_EXIT_SURVEY_GENDER,
    GET_EXIT_SURVEY_GENDER_SUCCESS,
    GET_EXIT_SURVEY_GENDER_FAIL,
    GET_EXIT_SURVEY_DATA,
    GET_EXIT_SURVEY_DATA_SUCCESS,
    GET_EXIT_SURVEY_DATA_FAIL,
    RESET,
    GET_EXIT_SURVEY_TOKEN_DATA,
    GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS,
    GET_EXIT_SURVEY_TOKEN_DATA_FAIL,
    GET_EXIT_SURVEY_AGE,
    GET_EXIT_SURVEY_AGE_SUCCESS,
    GET_EXIT_SURVEY_AGE_FAIL,
    GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS,
    GET_EXIT_SURVEY_DEPARTMENTS_FAIL,
    GET_EXIT_SURVEY_DEPARTMENTS,
    GET_EXIT_SURVEY_POSITIONS,
    GET_EXIT_SURVEY_POSITIONS_SUCCESS,
    GET_EXIT_SURVEY_POSITIONS_FAIL,
    CREATE_FORM_TOKEN_EXIT_SURVEY,
    CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS,
    CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL,
} from "./actionType";

const initialState = {
    exitSurvey: [],
    answers: [],
    mainReason: [],
    martialStatus: [],
    gender: [],
    exitSurveyData: [],
    loading: false,
    error: {
        message: "",
    },
    exitSurveyTokenObj: {},
    tokenLoading: false,
    ageRange: [],
    ageLoading: false,
    departmentLoading: false,
    departments: [],
    positions: [],
    positionLoading: false,
    existSurveyToken: {},
    formTokenLoading: false
};

const ExitSurveyReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case EXIT_SURVEY_CREATE:
            state = { ...state, loading: true, exitSurvey: [] };
            break;

        case EXIT_SURVEY_CREATE_SUCCESS:
            state = { ...state, exitSurvey: action.payload, loading: false };
            break;

        case EXIT_SURVEY_CREATE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                exitSurvey: [],
                loading: false,
            };
            break;

        case CREATE_FORM_TOKEN_EXIT_SURVEY:
            state = { ...state, formTokenLoading: true, existSurveyToken: {} };
            break;

        case CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS:
            state = { ...state, existSurveyToken: action.payload, formTokenLoading: false };
            break;

        case CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                existSurveyToken: {},
                formTokenLoading: false,
            };
            break;

        case GET_EXIT_SURVEY_ANSWER:
            state = { ...state, loading: true, answers: [] };
            break;

        case GET_EXIT_SURVEY_ANSWER_SUCCESS:
            state = { ...state, answers: action.payload, loading: false };
            break;

        case GET_EXIT_SURVEY_ANSWER_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                answers: [],
                loading: false,
            };
            break;

        case GET_EXIT_SURVEY_DATA:
            state = { ...state, loading: true, exitSurveyData: [] };
            break;

        case GET_EXIT_SURVEY_DATA_SUCCESS:
            state = { ...state, exitSurveyData: action.payload, loading: false };
            break;

        case GET_EXIT_SURVEY_DATA_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                exitSurveyData: [],
                loading: false,
            };
            break;

        case GET_EXIT_SURVEY_MAIN_REASON:
            state = { ...state, loading: true, mainReason: [] };
            break;

        case GET_EXIT_SURVEY_MAIN_REASON_SUCCESS:
            state = { ...state, mainReason: action.payload, loading: false };
            break;

        case GET_EXIT_SURVEY_MAIN_REASON_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                mainReason: [],
                loading: false,
            };
            break;

        case GET_EXIT_SURVEY_MARTIAL_STATUS:
            state = { ...state, loading: true, martialStatus: [] };
            break;

        case GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS:
            state = { ...state, martialStatus: action.payload, loading: false };
            break;

        case GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                martialStatus: [],
                loading: false,
            };
            break;

        case GET_EXIT_SURVEY_GENDER:
            state = { ...state, loading: true, gender: [] };
            break;

        case GET_EXIT_SURVEY_GENDER_SUCCESS:
            state = { ...state, gender: action.payload, loading: false };
            break;

        case GET_EXIT_SURVEY_GENDER_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                gender: [],
                loading: false,
            };
            break;

        case GET_EXIT_SURVEY_AGE:
            state = { ...state, ageLoading: true, ageRange: [] };
            break;

        case GET_EXIT_SURVEY_AGE_SUCCESS:
            state = { ...state, ageRange: action.payload, ageLoading: false };
            break;

        case GET_EXIT_SURVEY_AGE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                ageRange: [],
                ageLoading: false,
            };
            break;

        case GET_EXIT_SURVEY_DEPARTMENTS:
            state = { ...state, departmentLoading: true, departments: [] };
            break;
        case GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS:
            state = { ...state, departments: action.payload, departmentLoading: false };
            break;
        case GET_EXIT_SURVEY_DEPARTMENTS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                departmentLoading: false,
            };
            break;

        case GET_EXIT_SURVEY_POSITIONS:
            state = { ...state, positionLoading: true, positions: [] };
            break;
        case GET_EXIT_SURVEY_POSITIONS_SUCCESS:
            state = { ...state, positions: action.payload, positionLoading: false };
            break;
        case GET_EXIT_SURVEY_POSITIONS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                positionLoading: false,
            };
            break;

        case GET_EXIT_SURVEY_TOKEN_DATA:
            state = { ...state, tokenLoading: true, exitSurveyTokenObj: {} };
            break;

        case GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS:
            state = { ...state, exitSurveyTokenObj: action.payload, tokenLoading: false };
            break;

        case GET_EXIT_SURVEY_TOKEN_DATA_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                exitSurveyTokenObj: {},
                tokenLoading: false,
            };
            break;

        case RESET:
            state = { ...initialState };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ExitSurveyReducer;
