// AddGG1EmployeeMaster
export const ADD_GG1_EMPLOYEE = "ADD_GG1_EMPLOYEE";
export const ADD_GG1_EMPLOYEE_SUCCESS = "ADD_GG1_EMPLOYEE_SUCCESS";
export const ADD_GG1_EMPLOYEE_FAIL = "ADD_GG1_EMPLOYEE_FAIL";

export const GG1_ACTION_DELETE = "GG1_ACTION_DELETE";
export const GG1_ACTION_DELETE_SUCCESS = "GG1_ACTION_DELETE_SUCCESS";
export const GG1_ACTION_DELETE_FAIL = "GG1_ACTION_DELETE_FAIL";

export const GG1ANLYSTUPDATE="GG1ANLYSTUPDATE";
export const GG1ANLYSTUPDATE_SUCCESS="GG1ANLYSTUPDATE_SUCCESS";
export const GG1ANLYSTUPDATE_FAIL="GG1ANLYSTUPDATE_FAIL";

export const GET_MASETR_DATA = "GET_MASETR_DATA";
export const GET_MASETR_DATA_SUCCESS = "GET_MASETR_DATA_SUCCESS";
export const GET_MASETR_DATA_FAIL = "GET_MASETR_DATA_FAIL";

// GetPositionSalaryByGrade
export const GET_POSITION_SALARY_BY_GRADE = "GET_POSITION_SALARY_BY_GRADE";
export const GET_POSITION_SALARY_BY_GRADE_SUCCESS =
  "GET_POSITION_SALARY_BY_GRADE_SUCCESS";
export const GET_POSITION_SALARY_BY_GRADE_FAIL =
  "GET_POSITION_SALARY_BY_GRADE_FAIL";

export const RESET = "RESET";
