const HistoryColumns = [
  {
    title: "ID",
    field: "id",
    locked: false,
    filter: "text",
    width: "150px",
    show: true,
  },
  {
    title: "Query Name",
    field: "queryName",
    locked: false,
    filter: "text",
    width: "250px",
    show: true,
  },
  {
    title: "Group Name",
    field: "permissionType",
    locked: false,
    filter: "text",
    width: "200px",
    show: true,
  },
  {
    title: "Status",
    field: "status",
    locked: false,
    filter: "text",
    width: "180px",
    show: true,
  },
  {
    title: "Created At",
    field: "createdOn",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY hh:mm A",
    editor: "date",
    width: "200px",
    show: true,
  },
];

export default HistoryColumns;
