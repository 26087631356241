import Loader from "./Loader";
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import React, { Fragment, useState, useEffect } from "react";
import ThemeCustomize from "../Layout/ThemeCustomizer";
import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { loadingPanel } from "../CommonComponents/CommonFunction";
import CustomizerContext from "../_helper/Customizer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AnimationThemeContext from "../_helper/AnimationTheme";
import { useSelector } from "react-redux";
import { useAuth, AuthContext } from "oidc-react";
import { setApiToken } from "./../_helper/api_helper";
import ErrorBoundary from "../ErrorBoundry";
// import { store } from "emoji-mart";
// import { parse } from "@fortawesome/fontawesome-svg-core";
const AppLayout = ({ children, classNames, ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useContext(AuthContext);

  const { userManager, userData } = useAuth();
  const { animation } = useContext(AnimationThemeContext);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { StoredUserConfiguration, storedTheme } = useSelector(
    (state) => state?.ThemeCustomizerReducer
  );

  const { sidebar_types } = useContext(CustomizerContext);
  const queryData = location?.search?.split("=")[1]?.toString();
  const settings1 =
    storedTheme?.settings?.sidebar_setting ||
    storedTheme?.settings?.sidebar.type ||
    "compact-wrapper" ||
    queryData;
  const sidebar_types1 =
    storedTheme?.settings?.sidebar.type || "default-sidebar" || sidebar_types;
  const animationTheme =
    animation ||
    StoredUserConfiguration?.router_animation ||
    storedTheme?.router_animation ||
    "fadeIn";
  userManager
    .signinRedirectCallback()
    .then((user) => {
      if (user) {
        // if (user.profile.role !== "user" && user.profile.Application !== "HR") {
        //   // window.location.replace("/unauthorized");
        // }
        //else {
        localStorage.setItem("jwt_access_token", user.access_token);
        sessionStorage.setItem("jwt_access_token", user?.access_token);

        localStorage.setItem("role", user.profile.role);
        localStorage.setItem("authStatus", "SignedIn");
        setApiToken(user.access_token);
        if (localStorage.getItem("redirect-to")) {
          const redirectTo = localStorage.getItem("redirect-to");
          localStorage.removeItem("redirect-to");
          navigate(redirectTo);
        }
        // }
      }
    })
    .catch((err) => {
      // history.push({ 'pathname': '/employeeManage' })
      // history.push({ pathname: window.location.pathname });
      // window.location.replace("/login");
    });
  useEffect(() => {
    if (isLoading === false && (userData == null || userData.expired)) {
      localStorage.setItem("redirect-to", location.pathname);
      userManager.signinRedirect();
    } else if (isLoading == false && userData) {
      if (localStorage.getItem("redirect-to")) {
        const redirectTo = localStorage.getItem("redirect-to");
        localStorage.removeItem("redirect-to");
        navigate(redirectTo);
      }
    }
  }, [userData, isLoading]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDataLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  if (!userData?.access_token) return null;
  return (
    <Fragment>
      <Loader />
      <Taptop />
      <div
        className={`page-wrapper ${sidebar_types1} ${settings1}`}
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper horizontal-menu">
          <Sidebar />
          <TransitionGroup>
            {/* {isLoading && loadingPanel()} */}
            {isDataLoaded && (
              <CSSTransition
                key={location.key}
                in={true}
                timeout={300}
                // classNames="my-transition"
                classNames={animationTheme}
                unmountOnExit
                // mountOnEnter
                appear
                mountOnEnter
              >
                <div className="page-body" style={{ overflow: "auto" }}>
                  <ErrorBoundary>
                    <Outlet />
                  </ErrorBoundary>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ThemeCustomize />
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
