import {
    SALARY_INCREMENT_UPDATE,
    SALARY_INCREMENT_UPDATE_SUCCESS,
    SALARY_INCREMENT_UPDATE_FAIL,
    SALARY_INCREMENT_GETINFO,SALARY_INCREMENT_GETINFO_SUCCESS,SALARY_INCREMENT_GETINFO_FAIL,
    GET_AUDIT_TRAIL,GET_AUDIT_TRAIL_SUCCESS,GET_AUDIT_TRAIL_FAIL,
    GET_TRANSACTION,GET_TRANSACTION_SUCCESS,GET_TRANSACTIONL_FAIL,
    RESET
} from "./actionTypes";


export const getAuditTrail = (employeeID) => {

    return {
        type: GET_AUDIT_TRAIL,
        payload: employeeID
    }
}
export const getAuditTrailSuccess = (response) => {
    return {
        type: GET_AUDIT_TRAIL_SUCCESS,
        payload: response
    }
}
export const getAuditTrailFail = (error) => {
    return {
        type: GET_AUDIT_TRAIL_FAIL,
        payload: error
    }
}

// export const getinfosalaryIncrement = (HrControl, employeeID) => {

//     return {
//         type: SALARY_INCREMENT_GETINFO,
//         payload: {HrControl, employeeID}
//     }
// }
export const getTransaction = (employeeID) => {

    return {
        type: GET_TRANSACTION,
        payload: employeeID
    }
}
export const getTransactionSuccess = (response) => {
    return {
        type: GET_TRANSACTION_SUCCESS,
        payload: response
    }
}
export const getTransactionFail = (error) => {
    return {
        type: GET_TRANSACTIONL_FAIL,
        payload: error
    }
}

export const getinfosalaryIncrement = (HrControl, employeeID) => {

    return {
        type: SALARY_INCREMENT_GETINFO,
        payload: {HrControl, employeeID}
    }
}
export const getinfosalaryIncrementSuccess = (response) => {
    return {
        type: SALARY_INCREMENT_GETINFO_SUCCESS,
        payload: response
    }
}
export const getinfosalaryIncrementFail = (error) => {
    return {
        type: SALARY_INCREMENT_GETINFO_FAIL,
        payload: error
    }
}
 
export const updatesalaryIncrement = (payload) => {

    return {
        type: SALARY_INCREMENT_UPDATE,
        payload: payload
    }
}
export const updatesalaryIncrementSuccess = (response) => {
    return {
        type: SALARY_INCREMENT_UPDATE_SUCCESS,
        payload: response
    }
}
export const updatesalaryIncrementFail = (error) => {
    return {
        type: SALARY_INCREMENT_UPDATE_FAIL,
        payload: error
    }
}
export const reset = (payload) => {
    return {
        type: RESET,
        payload: payload
    }
}