// import {
//     NPAPOPUP,
//     NPAPOPUP_SUCCESS,
//     NPAPOPUP_FAIL,
// } from "./actiontype"


// export const NpaPopup =({employeeID,HRControl})=>{
//     return {
//         type:NPAPOPUP,
//         payload: {employeeID,HRControl},
//     }
// };

// export const NpaPopupSuccess =(response)=>{
//     return{
//         type: NPAPOPUP_SUCCESS,
//         payload:response
//     }
// }

// export const NpaPopupFail =(error)=>{
//     return{
//         type:NPAPOPUP_FAIL,
//         payload:error,
//     }
// }
import {
    NPAPOPUP,
    NPAPOPUP_FAIL,
    NPAPOPUP_SUCCESS,
    NPA_ACTION_DELETE,
    NPA_ACTION_DELETE_FAIL,
    NPA_ACTION_DELETE_SUCCESS
} from "./actiontype"

export const NpaDetails =(employeeID,HrControl,NatureActionCode)=>{
       return{
        type:NPAPOPUP,
        payload:{employeeID,HrControl,NatureActionCode}
       }
    }
export const NpaDetailsSuccess=(response)=>{
    return {
        type:NPAPOPUP_SUCCESS,
        payload:response
    }
} 
export const NpaDetailsFail =(error)=>{
    return{
    type:NPAPOPUP_FAIL,
    payload:error
    }
}

export const deleteNPAAction = (payload) => {
    console.log("11111 payload in acton deleteNPAAction", payload);
    return {
      type: NPA_ACTION_DELETE,
      payload: payload,
    };
  };
  export const deleteNPAActionSucess = (response) => {
    return {
      type: NPA_ACTION_DELETE_SUCCESS,
      payload: response,
    };
  };
  export const deleteNPAActionFail = (error) => {
    console.log("11111 payload in acton fail", error);
    return {
      type: NPA_ACTION_DELETE_FAIL,
      payload: error,
    };
  };