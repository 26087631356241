import React from 'react'
import { Row,Col ,Card} from 'reactstrap'

export const EmpDepartMent = [
  {
    value:"0203",
    name:"OFFICE OF THE GOVERNOR",
  },
  {
    value:"0204",
    name:"GOV - EXECUTIVE DIRECTION",
  },
  {
    value:"0234",
    name:"GOV - MANAGEMENT SYSTEMS",
  },
  {
    value:"0205",
    name:"GOV - FACILITIES MANAGEMENT",
  },
  {
    value:"0231",
    name:"GOV - CHAMORRO HERITAGE INSTITUTE",
  },
]

const Gender = [
  {
    value:"male",
    name:"Male",
  },
  {
    value:"female",
    name:"Female",
  },
  {
    value:"other",
    name:"Other",
  },
]

const MaritalStatus = [
  {
    value:"M",
    name:"MARRIED",
  },
  {
    value:"D",
    name:"DIVORCED",
  },
  {
    value:"L",
    name:"LEGALLY SEPARATED",
  },
  {
    value:"C",
    name:"COMMON-LAW SPOUSE",
  },
  {
    value:"N",
    name:"NOT INDICATED",
  },
  {
    value:"S",
    name:"SINGLE",
  },
  {
    value:"W",
    name:"WIDOWED",
  },

]

const EmployeeDetails = (props) => {
  const {renderTextInput, renderCheckbox,renderDropDown,renderDateInput,values,params,oldData} = props
  return (
    <Card style={{padding:"20px"}}>
        <Row>
            <Col md={12}>
          <div style={ { display: 'flex' } }>
            <span style={{padding:"10px 0px"}}><b>Employment Status:</b></span>
            <span>{ renderCheckbox("medium", "empType", "actEmployee") } Active Employee</span>
            <span>
            {renderCheckbox("medium","empType","retiree")} Retiree
            </span>
            <span>
            {renderCheckbox("medium","empType","survivorRetiree")} Survivor Retiree
            </span>
            <span>
            {renderCheckbox("medium","empType","dbRetireeplan")} DB Retirement Plan
            </span>
            <span>
            {renderCheckbox("medium","empType","dcRetire")} DC Retirement Plan
            </span>
          </div>
                
            </Col>
            <Col md={3}>
            </Col>
            <Col md={3}>
            </Col>  
            <Col md={3}>
            </Col>
            <Col md={3}>
            </Col>
            {/* <Col md={4}>
              {renderTextInput("DCAGENCY", "DC Agency")}
            </Col> */}
        </Row>
        <Row>
          <Col md={5}>
          {renderTextInput("FirstName", "First Name", {maxLength:'40'})}
          </Col>
          <Col md={2}>
          {renderTextInput("MiddleName", "M.I.")}
          </Col>
          <Col md={5}>
          {renderTextInput("LastName", "Last Name")}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
          {renderDropDown("Department", "GovGuam Agency/Department",EmpDepartMent)}
          </Col>
          <Col md={4}>
          {renderDateInput("DateOfEmployment", "Date of Employeement")}
          </Col>
         

          <Col md={4}>
          {oldData?.ssn ?  "" :
          renderTextInput("SSN", "Social Security Number",{maxLength:9}) }
          </Col>
        </Row>
        <Row>
          <Col md={3}>
          {renderTextInput("MailingAddress", "Mailing Address")}
          </Col>
          <Col md={3}>
          {renderTextInput("City", "City")}
          </Col>
          <Col md={3}>
          {renderTextInput("State", "State")}
          </Col>
          <Col md={3}>
          {renderTextInput("Zip", "Zip")}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
          {renderTextInput("HomePhone", "Home Phone")}
          </Col>
          <Col md={3}>
          {renderTextInput("WorkPhone", "Work Phone & Ext.")}
          </Col>
          <Col md={3}>
          {renderTextInput("CellPhone", "Cell Phone / Other Phone")}
          </Col>
          <Col md={3}>
          {renderDateInput("DOB", "Date of Birth")}
          </Col>
        </Row>
        <Row>
          <Col md={4}>
          <div style={ { display: 'flex' } }>
            {/* <span style={{padding:"10px 0px"}}><b>Gender</b></span> */}
            <span>{ renderCheckbox("medium", "Gender", "Male") } Male</span>
            <span>
            {renderCheckbox("medium","Gender","Female")} Female
            </span>
            <span>
            {renderCheckbox("medium","Gender","Other")} Other
            </span>
            </div>
          </Col>
          <Col md={4}>
          {renderDropDown("MaritalStatus", "Marital Status",MaritalStatus)}
          </Col>
          <Col md={4}>
          {renderTextInput("Email", "E-mail Address")}
          </Col>
        </Row>
    </Card>
  )
}

export default EmployeeDetails