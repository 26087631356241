import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_POSITIONS,
  JOB_ANNOUCEMENT_DETAILS,
  JOB_ANNOUNCEMENT_CREATE,
  JOB_ANNOUCEMENT_DELETE,
  JOB_ANNOUNCEMENT_UPDATE,
} from "./actionTypes";

import {
  getJobAnnouncementDetailsSuccess,
  getJobAnnouncementDetailsFail,
  createJobAnnouncementSuccess,
  createJobAnnouncementFail,
  updateJobAnnouncementSuccess,
  updateJobAnnouncementFail,
  deleteJobAnnouncementSuccess,
  deleteJobAnnouncementFail,
} from "./action";

import {
  createJobAnnouncement,
  deleteJobAnnouncement,
  getJobAnnouncementDetails,
  updateJobAnnouncement,
} from "../../_helper/services/jobAnnouncement";

function* ongetJobAnnouncementDetails({ payload: id }) {
  try {
    const response = yield call(getJobAnnouncementDetails, id);
    yield put(getJobAnnouncementDetailsSuccess(response));
  } catch (error) {
    yield put(getJobAnnouncementDetailsFail(error.response));
  }
}
function* oncreateJobAnnouncement({ payload }) {
  try {
    const response = yield call(createJobAnnouncement, payload);
    yield put(createJobAnnouncementSuccess(response));
  } catch (error) {
    yield put(createJobAnnouncementFail(error.response));
  }
}
function* onupdateJobAnnouncement({ payload: { announcementId }, payload }) {
  try {
    const response = yield call(updateJobAnnouncement, announcementId, payload);
    yield put(updateJobAnnouncementSuccess(response));
  } catch (error) {
    yield put(updateJobAnnouncementFail(error.response));
  }
}
function* ondeleteJobAnnouncement({ payload: ID }) {
  try {
    const response = yield call(deleteJobAnnouncement, ID);
    yield put(deleteJobAnnouncementSuccess(response));
  } catch (error) {
    yield put(deleteJobAnnouncementFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(JOB_ANNOUCEMENT_DETAILS, ongetJobAnnouncementDetails);
  yield takeLatest(JOB_ANNOUNCEMENT_CREATE, oncreateJobAnnouncement);
  yield takeLatest(JOB_ANNOUNCEMENT_UPDATE, onupdateJobAnnouncement);
  yield takeLatest(JOB_ANNOUCEMENT_DELETE, ondeleteJobAnnouncement);
}

export default CartSaga;
