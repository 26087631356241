import { formatNumber } from "@telerik/kendo-intl";

export const renderNumberFormat = (category, value, padleft = 8) => {
  if (!padleft) padleft = 8;
  const limitedValue = value==0?0.00:value; //&& value?.toString().substring(0, 14);
  if (category === "Hours") return limitedValue;
  var d = formatNumber(limitedValue, "c3");
  let data = `$${d
    .substring(1, d.length - 1)
    .padStart(padleft < 5 ? padleft + 4 : padleft + 5, " ")}`;
  // return data;
  return (
    <div
      style={{
        whiteSpace: "pre",
        fontFamily: "monospace",
        letterSpacing: "-1px",
      }}
    >
      {data}
    </div>
    // <div className="row">
    //   <div className="col-6 text-end">
    //     <span>{d.substring(0, 1).replace("-", "$")}</span>
    //   </div>
    //   <div className="col-6">
    //     <span> {data.replace("$", "-")}</span>
    //   </div>
    // </div>
  );
};
