import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_SIDEBARMENU, GET_ROLES, CREATE_SIDEBARMENU, GET_SIDEBARLOOKUP,
  GETBY_ID_SIDEBARMENU, DELETE_SIDEBARMENU, UPDATE_SIDEBARMENU, 
  UPDATE_SIDEBARMENU_SEQUENCE,RESET,RESET_CREATE_MENU,RESET_DELETE, GET_SIDEBARMENU_BY_ROLE,
  GET_MENUITEMS
} from "./actionType";
import {
  getsidebarmenudatasuccess, getsidebarmenudatafailure,
  getrolessuccess, getrolesfailure,
  createsidebarmenuSuccess, createsidebarmenuFailure,
  getSidebarLookUpuccess, getSidebarLookUpfailure,
  getbyidsidebarmenuSuccess, getbyidsidebarmenuFailure,
  deletesidebarmenuSuccess, deletesidebarmenuFailure,
  updatesidebarmenusucccess, updatesidebarmenuFailure,
  
  updatesidebarmenuSeqSuccess,updatesidebarmenuSeqFailure,
  reset,resetCreateMenu,resetDeleteMenu, 
  getsidebarMenuItemsSuccess,getsidebarMenuItemsFailure

} from "./actions";
import {
  getsidebarmenuList, getuserGroup, createsidebarmenu, getsidebarmenulookup, getbyidsubmenusidebarmenu,
  deletesidebarmenu, updatesidebarmenu,updatesidebarmenusequnece, getsidebarmenuListByRole,getsideMenuItems

} from "../../_helper/services/sidebarmenumanage";
import { ShowSucess } from "../../_helper/helper";




function* OnGetSidebarMenu({ payload }) {
  try {
    const response = yield call(getsidebarmenuList, payload);
    yield put(getsidebarmenudatasuccess(response));
  } catch (error) {
    yield put(getsidebarmenudatafailure(error.response));
  }
}
function* OnGetSidebarMenuItems({ payload }) {
  try {
    const response = yield call(getsideMenuItems, payload);
    yield put(getsidebarMenuItemsSuccess(response));
  } catch (error) {
    yield put(getsidebarMenuItemsFailure(error.response));
  }
}

function* OnGetSidebarMenuByRole({ payload }) {
  try {
    const response = yield call(getsidebarmenuListByRole, payload?.role);
    yield put(getsidebarmenudatasuccess(response));
  } catch (error) {
    yield put(getsidebarmenudatafailure(error.response));
  }
}


function* OnGetRole({ payload }) {
  try {
    const response = yield call(getuserGroup, payload);
    yield put(getrolessuccess(response));
  } catch (error) {
    yield put(getrolesfailure(error.response));
  }
}
function* OnGetSideBarLookUp({ payload }) {
  try {
    const response = yield call(getsidebarmenulookup, payload);
    yield put(getSidebarLookUpuccess(response));
  } catch (error) {
    yield put(getSidebarLookUpfailure(error.response));
  }
}
function* OnCreateSidebar({ payload }) {
  //  debugger
  try {
    const response = yield call(createsidebarmenu, payload);
    yield put(createsidebarmenuSuccess(response));
    ShowSucess("Menu Created successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(createsidebarmenuFailure(error.response));
  }finally {
    yield put(resetCreateMenu()); // Dispatch RESET action after handling createDepartment
  }
}
function* OnGetByidSidebar({ payload: menuid }) {
  try {
    const response = yield call(getbyidsubmenusidebarmenu, menuid);
    yield put(getbyidsidebarmenuSuccess(response));

  } catch (error) {
    yield put(getbyidsidebarmenuFailure(error.response));
  }
}
function* OnUpdateSidebar({ payload: { id, payload } }) {


  try {
    const response = yield call(updatesidebarmenu, { id, payload });
    yield put(updatesidebarmenusucccess(response));
    // ShowSucess( response?.message, {
    //   position: 'top-right'
    // })
    // console.log("updated", response)
  } catch (error) {
    yield put(updatesidebarmenuFailure(error.response));
     
  }finally {
    yield put(reset()); // Dispatch RESET action after handling createDepartment
  }
}
function* OnUpdateSidebarSequnece({ payload }) {


  try {
    const response = yield call(updatesidebarmenusequnece,  payload );
    yield put(updatesidebarmenuSeqSuccess(response));

    console.log("updated", response)
  } catch (error) {
    yield put(updatesidebarmenuSeqFailure(error.response));
  }
}
function* OnDeleteSidebar({ payload: id }) {
  try {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const response = yield call(deletesidebarmenu, id);
      yield put(deletesidebarmenuSuccess(response));

      // ShowSucess('Deleted successfully', {
      //   position: 'top-right'
      // });
    }

  } catch (error) {
    yield put(deletesidebarmenuFailure(error.response));
  }finally {
    yield put(resetDeleteMenu()); // Dispatch RESET action after handling createDepartment
  }
}

function* SidearMenuSaga() {
  yield takeLatest(GET_ROLES, OnGetRole);
  yield takeLatest(GET_MENUITEMS, OnGetSidebarMenuItems);
  yield takeLatest(GET_SIDEBARMENU, OnGetSidebarMenu);
  yield takeLatest(GET_SIDEBARMENU_BY_ROLE, OnGetSidebarMenuByRole);
  yield takeLatest(GET_SIDEBARLOOKUP, OnGetSideBarLookUp);
  yield takeLatest(GETBY_ID_SIDEBARMENU, OnGetByidSidebar);
  yield takeLatest(CREATE_SIDEBARMENU, OnCreateSidebar);
  yield takeLatest(UPDATE_SIDEBARMENU, OnUpdateSidebar);
  yield takeLatest(UPDATE_SIDEBARMENU_SEQUENCE, OnUpdateSidebarSequnece);
  yield takeLatest(DELETE_SIDEBARMENU, OnDeleteSidebar);
  // yield takeLatest(GET_MENUITEMS, NewGetSidebarMenu);
  yield takeLatest(RESET,reset);
  yield takeLatest(RESET_CREATE_MENU,resetCreateMenu);
  yield takeLatest(RESET_DELETE,resetDeleteMenu);
}
export default SidearMenuSaga;