const visibleColumns = [
  {
    title: "Department Name",
    field: "DepartmentName",
    locked: true,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Department Number",
    field: "Dept_No",
    locked: false,
    filter: "text",
    width: 100,
    show: true,
  },
  {
    title: "Name2",
    field: "Name2",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "SSN",
    field: "SSN",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Title",
    field: "TITLE",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Position Number",
    field: "Nposition_No",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Budget Position Number",
    field: "Budge_Pos_No",
    locked: false,
    filter: "text",
    width: 100,
    show: true,
  },

  {
    title: "Range Pay",
    field: "Range_Pay",
    locked: false,
    filter: "numeric",
    width: 100,
    show: true,
  },
  {
    title: "Actual Step",
    field: "Step_Actual",
    locked: false,
    filter: "numeric",
    width: 100,
    show: true,
  },
  {
    title: "Annual Salary",
    field: "Annual_Salary",
    locked: false,
    filter: "numeric",
    width: 160,
    show: true,
  },
  {
    title: "Rate",
    field: "Rate",
    locked: false,
    filter: "numeric",
    width: 100,
    show: true,
  },
  {
    title: "Date of Increment",
    field: "Ndate_Increm",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "Date of Employment",
    field: "Date_Employment",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "Employee Status",
    field: "Emp_Status",
    locked: false,
    filter: "text",
    width: 100,
    show: true,
  },
  {
    title: "Term Date",
    field: "Date_Term",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
];

export default visibleColumns;
