import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { postReturnResponse } from "../../_helper/api_helper";
import { faEye, faList, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import DocumentViewer from "../DocumentViewer";
import ErrorBoundary from "../../ErrorBoundry";
import DataTable from "./DataTable";
import { Btn } from "../../AbstractElements";
import {
  deleteDocument,
  moveDocumentsToPR,
  reset,
  setReferenceId,
} from "../../store/DocumentUpload/actions";
import documentVisibleColumns from "./visibleColumns";
import { getAuditLogs } from "../../store/AuditLogs/action";
import HistoryDataTable from "../AuditLog/HistoryDataTable";
import {
  ConcatenedColumns,
  loadingPanel,
  sortingFunc,
} from "../CommonFunction";
import { useAuth } from "oidc-react";
import Columns from "./Columns";
import { ShowError } from "../../_helper/helper";

const visibleColumnsEmployee = [
  {
    title: "Employee ID",
    field: "EmployeeId",
    locked: true,
    filter: "numeric",
    customFilterType: "CustomNumericText",
    width: 170,
    show: true,
    className: "text-end",
    headerClassName: "number-align",
  },
  {
    title: "Employee Name",
    field: "EmployeeName",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Department",
    field: "DepartmentName",
    locked: false,
    filter: "text",
    show: false,
    width: "200",
  },
  {
    title: "Position",
    field: "PositionName",
    locked: false,
    filter: "text",
    show: false,
    width: "200",
  },
  {
    title: "File Type",
    field: "ContentType",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },
  {
    title: "File Name",
    field: "DocumentName",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Reference Id",
    field: "ReferenceID",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Application",
    field: "Application",
    locked: false,
    filter: "text",
    width: "180",
    show: true,
  },
  {
    title: "Module",
    field: "Module",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  // {
  //   title: "Performed By",
  //   field: "EmployeeName",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Uploaded At",
    field: "CreatedOn",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy hh:mm:ss A",
    editor: "date",
    width: "220",
    show: true,
  },
];

let visibleColumns = [
  {
    title: "File Type",
    field: "ContentType",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },
 
  {
    title: "File Name",
    field: "DocumentName",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "File Description",
    field: "ContentDescription",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Reference Id",
    field: "ReferenceID",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Reference Description",
    field: "Remarks",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Application",
    field: "Application",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Module",
    field: "Module",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Is Shared",
    field: "VisibleFor",
    locked: false,
    filter: "numeric",
    width: "220",
    show: true,
  },
  {
    title: "Is HR",
    field: "IsHr",
    locked: false,
    filter: "boolean",
    width: "220",
    show: false,
  },
  // {
  //   title: "Performed By",
  //   field: "EmployeeName",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Uploaded At",
    field: "CreatedOn",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy hh:mm:ss A",
    editor: "date",
    width: "220",
    show: true,
  },
];

const DocumentListing = (props) => {
  const {
    deleteDocument,
    deleteResult,
    addedResult,
    isUploading,
    isDeleteResultLoading,
    referenceId,
    sourceName,
    documentReferenceId,
    isDocumentManagement = false,
    isDashboard = true,
    isShowUplaodBtn = true,
    employeeId = null,
    isShowActionCheckBox = false,
    checkboxKey = "ID",
    isTransferToPRButtonVisible = false,
    docSelectionON=false,
    approvalBtn=false
  } = props;
  const dispatch = useDispatch();
  const baseURL = process.env.REACT_APP_API_DOTNET_URL;
  const { userData } = useAuth();
  const [isDocumentViewer, setIsDocumentViewer] = useState(false);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentTitle, setDocumentTitle] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [additionalFilter, setAdditionalFilter] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");
  const [openHistory, setOpenHistory] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [selectDocuments, setSelectDocuments] = useState([]);
  const [checkSelectedCheckbox, setCheckSelectedCheckbox] = useState(null);
  const [sortData, setSortData] = useState([
    // { field: "EmployeeName", dir: "asc" },
  ]);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const { auditLogs: Documents, auditLogsLoading: isDocumentsLoading } =
    useSelector((state) => state.AuditLog);
  const [checked, setChecked] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isEnableCheckbox, setIsEnableCheckbox] = useState(false);
  const [idRefId, setIDREFId] = useState({id : null, refId:null,status:null});
  const { moveToPRResponse, isLoading } = useSelector(
    (state) => state?.DocumentUploadReducer
  );

  const handleOpen = (documentFile, filename, ID,refID,status) => {
    setIsDocumentViewer(true);
    setDocumentFile(documentFile);
    setDocumentTitle(filename);
    setIDREFId({id : ID, refId:refID,status:status});
    setDocumentTitle(filename);
  };
  useEffect(() => {
    return () => {
      props.reset();
    };
  }, []);

  useEffect(() => {
   
    if(approvalBtn){
      let statusCheck = visibleColumns?.filter((item) => item.field == "Status")
      if(!statusCheck?.length){
      visibleColumns = [
       
        {
          title: "Document Status",
          field: "Status",
          editor : "Chips",
          locked: false,
          filter: "text",
          width: "200",
          show: true,
        }, ...visibleColumns]
      }
    }
  },[approvalBtn])

  async function downloadExcel() {
    try {
      setShowLoader(true);
      const response = await postReturnResponse(
        baseURL + "/DocumentAttachment/in-one",
        selectedDocuments,
        { responseType: "blob" }
      );

      const filename = `report_${new Date().getTime()}`;
      // Create a Blob from the response data
      const blob = await response.data;
      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.log("error", error);
    }
  }

  const moreGridTool = (enableCheckboxes) => {
    setIsEnableCheckbox(enableCheckboxes);

    if (enableCheckboxes && selectedDocuments && selectedDocuments.length > 1) {
      return (
        <Btn
          attrBtn={{
            color: "primary",
            onClick: () => {
              //  dispatch(combinedDocument(arr))
              downloadExcel();
            },
            style: {
              marginLeft: "8px",
            },
          }}
        >
          <i className="k-icon k-i-link"></i> Combine Documents
        </Btn>
      );
    } else {
      return <div></div>;
    }
  };

  useEffect(() => {
    if (!isEnableCheckbox) {
      setSelectedDocuments([]);
      setChecked({});
    }
  }, [isEnableCheckbox]);
  useEffect(() => {
    const refId = referenceId || documentReferenceId;

    if (!isDocumentManagement) {
      setSearchParams([
        {
          columnName: "ReferenceID",
          operator: "=",
          value: `${refId || ""}`,
          dataType: "varchar(max)",
          logicOperator: "AND",
        },
      ]);
    }
    setDeleteData({ dt: new Date() });
  }, [referenceId, sourceName, documentReferenceId]);

  useEffect(() => {
    if (
      deleteResult !== undefined &&
      !!Object.keys(deleteResult).length &&
      !isDeleteResultLoading
    ) {
      setDeleteData({ dt: new Date() });
    }
  }, [deleteResult, isDeleteResultLoading]);

  useEffect(() => {
    if (
      addedResult !== undefined &&
      !!Object.keys(addedResult).length &&
      !isUploading
    ) {
      setDeleteData({ dt: new Date() });
    }
  }, [addedResult, isUploading]);

  useEffect(() => {
    if (moveToPRResponse && !!Object.keys(moveToPRResponse).length) {
      clearSelectedCheckbox();
      setDeleteData({ dt: new Date() });
    }
  }, [moveToPRResponse, isLoading]);

  useEffect(() => {
    if (!!Object.keys(selectedDocument).length) {
      dispatch(
        getAuditLogs({
          tableName: "vw_DocumentAttachmentForEmployee",
          searchParams: "[]",
          additionalWhere: `sourceId = ${
            selectedDocument?.SourceId || selectedDocument?.sourceId
          }`,
          columns: "*",
          pageNumber: 1,
          pageSize: 100,
          location: "CommonSearch",
        })
      );
    }
  }, [selectedDocument]);

  useEffect(() => {
    setHistoryData(Documents);
  }, [Documents]);

  useEffect(() => {
    ConcatenedColumns(
      Columns,
      documentVisibleColumns,
      setSortedColumns,
      userData
    );
  }, [Columns, documentVisibleColumns, sortData]);

  useEffect(() => {
    if (historyData.length > 0) {
      sortingFunc(sortData, historyData, setHistoryData);
    }
  }, [sortData]);

  const handleSortChange = (sort) => {
    setSortData(sort);
  };

  const actions = useMemo(() => {
    return [
      {
        title: "View Document",
        action: (actionProps) => {
          console.log("ADsddsadada", actionProps);
          handleOpen(
            actionProps?.dataItem?.DocumentURL,
            actionProps?.dataItem?.DocumentName,
            actionProps?.dataItem?.ID,
            actionProps?.dataItem?.ReferenceID,
            actionProps?.dataItem?.Status,
          );
        },
        icon: { iconName: "k-icon  k-i-eye" },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
      {
        title: "Remove Document",
        action: (actionProps) => {
          if (window.confirm("Are you sure to delete this?")) {
            deleteDocument(parseInt(actionProps?.dataItem?.ID));
          }
        },
        icon: { iconName: "k-icon  k-i-delete" },
        className: "font-primary",
        style: { cursor: "pointer" },
        isVisible: (dataProps) => {
          return (
            userData?.profile?.role === "SUPER-USER" ||
            dataProps?.CreatedBy == userData?.profile?.EmployeeID ||
            dataProps?.createdBy == userData?.profile?.EmployeeID
          );
        },
      },
      {
        title: "List Documents",
        action: (selectedData) => {
          setSelectedDocument(selectedData?.dataItem);
          setOpenHistory(true);
        },
        icon: { iconName: "k-icon  k-i-list-ordered" },
        className: "font-primary",
        style: { cursor: "pointer" },

        isVisible: (dataItem) => {
          return props?.isDocumentManagement;
        },
      },
      {
        isCheckbox: true,
        isVisible: (dataProps) =>
          isShowActionCheckBox &&
          dataProps.IsDeleted !== true &&
          dataProps.VisibleFor !== 2,
      },
      {
        render: function (props) {
          // let arr=[];

          return (
            <input
              type="checkbox"
              id={props?.dataItem?.ID}
              name="selectTocombine"
              checked={checked && checked[props?.dataItem?.ID]}
              title="Select to Combine"
              onChange={(e) => {
                const newArr = [...selectedDocuments];
                if (e.target.checked) {
                  if (!newArr.some((obj) => obj.id === props.dataItem?.ID)) {
                    newArr.push({ id: props.dataItem?.ID });
                    setChecked({
                      ...checked,
                      [props?.dataItem?.ID]: e.target.checked,
                    });
                  }
                } else if (!e.target.checked) {
                  const index = newArr.findIndex(
                    (obj) => obj?.id === props.dataItem?.ID
                  );
                  if (index !== -1) {
                    newArr.splice(index, 1);
                    setChecked({
                      ...checked,
                      [props?.dataItem?.ID]: e.target.checked,
                    });
                  }
                } else {
                  const index = newArr.findIndex(
                    (obj) => obj?.id === props.dataItem?.ID
                  );
                  if (index !== -1) {
                    newArr.splice(index, 1);
                  }
                }
                setSelectedDocuments(newArr);
              }}
            />
          );
        },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
    ];
  });

  const clearSelectedCheckbox = () => {
    setCheckSelectedCheckbox({ checkbox: true });
    setSelectDocuments([]);
  };
  const handleTransferToPRButton = (event) => {
    event.stopPropagation();
    if (selectDocuments.length) {
      if (window.confirm("Are you sure want to move document(s) to PR?")) {
        const payload = {
          ids: selectDocuments,
        };
        props?.moveDocumentsToPR(payload);
      }
    } else {
      ShowError("Please select Document(s) to move to PR!", {
        position: "top-right",
      });
    }
  };

  return (
    <ErrorBoundary>
      {(isDeleteResultLoading || isUploading || showLoader) && loadingPanel()}
      {isDocumentViewer && (
        <DocumentViewer
          open={isDocumentViewer}
          setOpen={setIsDocumentViewer}
          url={documentFile}
          title={documentTitle}
          fullWidth
          maxWidth 
          approvalBtn={approvalBtn}
          idRefId={idRefId}
        />
      )}
      <DataTable
        location="global"
        tableName={
          isDocumentManagement
            ? "vw_DocumentAttachmentForEmployee"
            : "fn_getDocumentAttachmentByReference"
        }
        visileColumns={
          // isDocumentManagement ? visibleColumnsEmployee : visibleColumns
          isDocumentManagement
            ? visibleColumnsEmployee
            : sourceName == "GG1"
            ? visibleColumns.filter((item) => item?.title != "File Description")
            : visibleColumns
        }
        actions={actions}
        sort={[]}
        // { field: "EmployeeName", dir: "asc" }
        initialGridParams={{
          sort: [],
          filter: "",
        }}
        deleteData={deleteData}
        isLoading={false}
        additionalFilter={additionalFilter}
        title={props?.title}
        showDocumentUpload={isShowUplaodBtn}
        isDashboard={isDashboard}
        referenceId={referenceId}
        sourceName={sourceName}
        employeeId={employeeId}
        moreGridTool={moreGridTool}
        defaultSearchParams={searchParams}
        isShowActionCheckBox={isShowActionCheckBox}
        setSelectedCheckBoxed={setSelectDocuments}
        clearSelectedCheckbox={checkSelectedCheckbox}
        checkboxKey={checkboxKey}
        isTransferToPRButtonVisible={isTransferToPRButtonVisible}
        handleTransferToPRButton={handleTransferToPRButton}
        docSelectionON={docSelectionON}
        approvalBtn={approvalBtn}
        
      />

      {openHistory && (
        <HistoryDataTable
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
          data={historyData || []}
          sortData={[]}
          selectedColumns={sortedColumns}
          setSortedColumns={setSortedColumns}
          onSortChange={handleSortChange}
          actions={actions.filter((e) => e?.title == "View Document")}
          title={
            (selectedDocument?.module || selectedDocument?.Module) +
            " - Documents"
          }
          isLoading={isDocumentsLoading}
          tableName={
            isDocumentManagement
              ? "vw_DocumentAttachmentForEmployee"
              : "fn_getDocumentAttachmentByReference"
          }
          columnName={"sourceId"}
          value={selectedDocument?.SourceId || selectedDocument?.sourceId}
        />
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    deleteResult: state.DocumentUploadReducer.deletedDocument,
    isDeleteResultLoading: state.DocumentUploadReducer.loading,
    addedResult: state.DocumentUploadReducer?.uploadedDocumentAttechment,
    isUploading: state.DocumentUploadReducer?.loading,
    documentReferenceId:
      state.DocumentUploadReducer?.uploadedDocumentAttechment?.referenceId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteDocument,
      setReferenceId,
      reset,
      moveDocumentsToPR,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListing);
