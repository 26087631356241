const visibleColumns = [
  {
    title: "Job Order Number",
    field: "Job_Order_Number",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Employee No",
    field: "Employee_No",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
    className:"CustomTextField"
  },
  {
    title: "Pay Period End Date",
    field: "Pay_Period_End_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "DPDV",
    field: "Deptdiv",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Employee Name",
    field: "Employee_Name",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Employee Status",
    field: "Employee_Status",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Employee Ssn",
    field: "Employee_Ssn",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Employee Seq",
    field: "Employee_Seq",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },

  {
    title: "Pay Time Code",
    field: "Pay_Time_Code",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "PayType",
    field: "PayType",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Pay Category",
    field: "PayCategory",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Gross Pay",
    field: "GrossPay",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Dept Div",
    field: "Deptdiv",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },

  {
    title: "Retro Flag",
    field: "Retro_Flag",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Check Date",
    field: "Check_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Check Seq",
    field: "Check_Seq",
    locked: false,
    filter: "text",
    width: "160",
    show: true,
  },
  {
    title: "Job Order Number FY",
    field: "JobOrderNumberFY",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Job Order",
    field: "JobOrder",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Main Account Name",
    field: "Main_Account_Name",
    locked: false,
    filter: "text",
    width: "300",
    show: true,
  },
  {
    title: "Account No",
    field: "AccountNo",
    locked: false,
    filter: "text",
    width: "250",
    show: true,
  },
  {
    title: "Main Account",
    field: "Main_Account",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Main Account Description",
    field: "Main_Account_Description",
    locked: false,
    filter: "text",
    width: "300",
    show: true,
  },
  {
    title: "Fund Dimension",
    field: "Fund_Dimension",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Year Dimension",
    field: "Year_Dimension",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Job Number",
    field: "Job_Number",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Approp Type",
    field: "Approp_Type",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Account Type",
    field: "AccountType",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Lg Account Number",
    field: "Lg_Account_Number",
    locked: false,
    filter: "text",
    width: "250",
    show: true,
  },
  {
    title: "Total Pre-Encumbrances",
    field: "TotalPreEncumbrances",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Total Budget Funds Available",
    field: "TotalBudgetFundsAvailable",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Total Revised Budget",
    field: "TotalRevisedBudget",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Percent Used",
    field: "PercentUsed",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Budget Source Ledger Dimension Name",
    field: "BudgetSourceLedgerDimensionName",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Total Encumbrances",
    field: "TotalEncumbrances",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Total Apportionment",
    field: "TotalApportionment",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Total Actual Expenditures",
    field: "TotalActualExpenditures",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Fund ID",
    field: "FundID",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Code",
    field: "FundCode",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Name",
    field: "FundName",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Is Major",
    field: "FundIsMajor",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Class",
    field: "FundClass",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Year End Option",
    field: "FundYearEndOption",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Override Year End Option",
    field: "FundOverrideYearEndOption",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Type",
    field: "FundType",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund Is Non-Reporting Fund",
    field: "FundIsNonReportingFund",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Fund PSN Treasurer Fund Report",
    field: "FundPSNTreasurerFundReport",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
];

export default visibleColumns;
