export const GET_POSITIONS = "GET_POSITIONS";
export const GET_POSITIONS_SUCCESS = "GET_POSITIONS_SUCCESS";
export const GET_POSITIONS_FAIL = "GET_POSITIONS_FAIL";

export const JOB_ANNOUCEMENT_DETAILS = "JOB_ANNOUCEMENT_DETAILS";
export const JOB_ANNOUCEMENT_DETAILS_SUCCESS =
  "JOB_ANNOUCEMENT_DETAILS_SUCCESS";
export const JOB_ANNOUCEMENT_DETAILS_FAIL = "JOB_ANNOUCEMENT_DETAILS_FAIL";
export const SHOWN_TOASTER = "SHOWN_TOASTER";
export const JOB_ANNOUNCEMENT_UPDATE = "JOB_ANNOUNCEMENT_UPDATE";
export const JOB_ANNOUNCEMENT_UPDATE_SUCCESS =
  "JOB_ANNOUNCEMENT_UPDATE_SUCCESS";
export const JOB_ANNOUNCEMENT_UPDATE_FAIL = "JOB_ANNOUNCEMENT_UPDATE_FAIL";

export const JOB_ANNOUNCEMENT_CREATE = "JOB_ANNOUNCEMENT_CREATE";
export const JOB_ANNOUNCEMENT_CREATE_SUCCESS =
  "JOB_ANNOUNCEMENT_CREATE_SUCCESS";
export const JOB_ANNOUNCEMENT_CREATE_FAIL = "JOB_ANNOUNCEMENT_CREATE_FAIL";

export const JOB_ANNOUCEMENT_DELETE = "JOB_ANNOUCEMENT_DELETE";
export const JOB_ANNOUCEMENT_DELETE_SUCCESS = "JOB_ANNOUCEMENT_DELETE_SUCCESS";
export const JOB_ANNOUCEMENT_DELETE_FAIL = "JOB_ANNOUCEMENT_DELETE_FAIL";
