export const CREATE_UPDATE_EMPLOYEE_DEDUCTION = 'CREATE_UPDATE_EMPLOYEE_DEDUCTION';
export const CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS = 'CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS';
export const CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL = 'CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL';

export const GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID = 'GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID';
export const GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS = 'GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS';
export const GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL = 'GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL';

export const GET_DEDUCTION_PLAN_DOCUMENTS = 'GET_DEDUCTION_PLAN_DOCUMENTS';
export const GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS = 'GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS';
export const GET_DEDUCTION_PLAN_DOCUMENTS_FAIL = 'GET_DEDUCTION_PLAN_DOCUMENTS_FAIL';

export const GET_ALL_DEDUCTION_METHOD_LIST = 'GET_ALL_DEDUCTION_METHOD_LIST';
export const GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS = 'GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS';
export const GET_ALL_DEDUCTION_METHOD_LIST_FAIL = 'GET_ALL_DEDUCTION_METHOD_LIST_FAIL';

export const GET_ALL_DEDUCTION_PLAN_MASTER = 'GET_ALL_DEDUCTION_PLAN_MASTER';
export const GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS = 'GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS';
export const GET_ALL_DEDUCTION_PLAN_MASTER_FAIL = 'GET_ALL_DEDUCTION_PLAN_MASTER_FAIL';

export const GET_ALL_PAYMENT_TYPE = 'GET_ALL_PAYMENT_TYPE';
export const GET_ALL_PAYMENT_TYPE_SUCCESS = 'GET_ALL_PAYMENT_TYPE_SUCCESS';
export const GET_ALL_PAYMENT_TYPE_FAIL = 'GET_ALL_PAYMENT_TYPE_FAIL';

export const GET_DEDUCTION_TYPE = 'GET_DEDUCTION_TYPE';
export const GET_DEDUCTION_TYPE_SUCCESS = 'GET_DEDUCTION_TYPE_SUCCESS';
export const GET_DEDUCTION_TYPE_FAIL = 'GET_DEDUCTION_TYPE_FAIL';

export const RESET_DEDUCTION_CREATE = 'RESET_DEDUCTION_CREATE';
export const RESET_EMPLOYEE_DEDUCTION_PLAN = 'RESET_EMPLOYEE_DEDUCTION_PLAN';

export const RESET = "RESET";

