import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useSpring, animated } from "react-spring";
import DynamicTemplate from "./ViewTemplate/dynamicTemplate";
import ViewTemplate from "./ViewTemplate/viewTemplate";
import { faSave, faSend } from "@fortawesome/pro-duotone-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import axios from "axios";
import { Loader } from "@progress/kendo-react-indicators";
import { ShowError, ShowSucess } from "../../../src/_helper/helper";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCommentIcon from "@mui/icons-material/AddComment";
import FileViewer from "react-file-viewer";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ClearIcon from "@mui/icons-material/Clear";
import FileModal from "./ViewTemplate/FileModal";
import CustomButton from "../FormComponents/Button";
import { get, post } from "../../_helper/api_helper";
import PDFGenerator from "./PDFGenerator";

const baseURL = process.env.REACT_APP_API_URL_PR;

const NotificationPage = ({
  isModalOpen,
  setIsModalOpen,
  notificationId,
  hideButton,
  addParams,
  additinalStringToReplace,
  type,
}) => {
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("EMAIL");
  const [notificationContent, setNotificationContent] = useState("");
  const [showNewContent, setShowNewContent] = useState(false);
  const [isOldContentVisible, setIsOldContentVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [previewTemplateContent, setPreviewTemplateContent] = useState();
  // currentTemplate.templateBody
  const [notificationError, setNotificationError] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [submitNotificationLoader, setSubmitNotificationLoader] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [fileModal, setfileModal] = useState(false);
  const [addRemark, setAddRemark] = useState(false);
  const [remarkContent, setRemarkContent] = useState("");

  const template_ID = useMemo(
    () => notificationId?.Template_ID ?? "",
    [notificationId]
  );

  const convertNulltoZero = (obj) => {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = 0
      }
    }
    return obj;
  };

  const evaluateAndReplaceExpressions = (htmlString) => {
    let expressionRegex = /{([^}]+)}/g;

    let expressions = htmlString.match(expressionRegex);

    if (!expressions) {
      return htmlString;
    }

    let updatedHtmlString = htmlString.replace(
      expressionRegex,
      function (match, expression) {
        return eval(expression).toFixed(2);
      }
    );
    return updatedHtmlString;
  };

  const replaceParams = (htmlString, toReplaceObj) => {
    let tempReplcaeObj = convertNulltoZero({ ...toReplaceObj });
    let tempContent = htmlString.toString();
    for (const singleParam in tempReplcaeObj) {
      const escapedKey = singleParam.replace(
        /([.*+?^=!:${}()|\[\]\/\\])/g,
        "\\$1"
      );
      const regex = new RegExp(`\\[@${escapedKey}\\]`, "g");
      tempContent = tempContent.replace(
        regex,
        tempReplcaeObj[singleParam].toString()
      );
    }
    tempContent = evaluateAndReplaceExpressions(tempContent);
    return tempContent;
  };

  useEffect(() => {
    if (isModalOpen !== undefined) setOpen(isModalOpen);
  }, [isModalOpen]);

  const getNotificationById = async (id) => {
    if (!id) return;
    setNotificationLoader(true);
    try {
      const response = await get(`${baseURL}Notifications/${id}`);
      let currentHtmlString = response?.templateBody ?? "";
      setPreviewTemplateContent(response.templateBody);
      setCurrentTemplate(response);
      if (response?.params?.length === 0) {
        const tempNotificationContent = {
          notificationText: response.templateBody,
          data: { noparams: "noparams" },
        };
        setNotificationContent(tempNotificationContent);
      }
      if (Object.keys(addParams)?.length > 0) {
        if (additinalStringToReplace.htmlString) {
          const { htmlString, toReplace } = additinalStringToReplace;
          currentHtmlString = currentHtmlString.replace(toReplace, htmlString);
        }

        const tempNotificationContent = {
          notificationText: replaceParams(currentHtmlString, addParams),
          data: addParams,
        };
        setNotificationContent(tempNotificationContent);
        setCurrentTemplate({
          ...response,
          templateBody: tempNotificationContent.notificationText,
        });
        setPreviewTemplateContent(tempNotificationContent.notificationText);
      }
    } catch (error) {
      console.error("Error fetching notification:", error);
      ShowError("Could not fetched notification data")
    } finally {
      setNotificationLoader(false);
    }
  };

  useEffect(() => {
    if (template_ID) getNotificationById(template_ID);
  }, [template_ID]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
    setAddRemark(false);
    setRemarkContent("");
    handleRemoveFile();
    setNotificationContent({});
  };

  const handleDeliveryOptionChange = (event) => {
    setSelectedDeliveryOption(event.target.value);
  };

  const handleAddParams = () => {
    setShowNewContent(!showNewContent);
    setIsOldContentVisible(true);
  };

  const sendNotifictionApiCall = async (apiFormData) => {
    try {
      setSubmitNotificationLoader(true);
      // const authToken = localStorage.getItem("jwt_access_token");
      const authToken = sessionStorage.getItem("jwt_access_token");
      if (!authToken) {
        console.error("Authorization token not found in local storage");
        return null;
      }
      const response = fetch(`${baseURL}Notifications/smsvia_Type`, {
        method: "POST",
        body: apiFormData,
        headers: {
          Authorization: `Bearer ${authToken}`,
          // 'Content-Type':'multipart/form-data'
        },
      });
      ShowSucess("Notification sent successfully", {
        position: "top-right",
      });
      handleClose();
    } catch (error) {
      console.error("Error submitting notification:", error);
      ShowError("something went wrong", {
        position: "top-right",
      });
    } finally {
      setSubmitNotificationLoader(false);
    }
  };

  const prepareDataForApiCall = (
    file,
    fileType,
    notificationText,
    Template_ID,
    Employee_ID,
    DeliveryMethod,
    Remark = ""
  ) => {
    const formData = new FormData();

    formData.append("File", file);
    formData.append("fileType", fileType);
    formData.append("Template_ID", Template_ID);
    //10000001 Employee_ID
    formData.append("Employee_ID", Employee_ID);
    formData.append("notificationText", notificationText);
    formData.append("DeliveryMethod", DeliveryMethod);
    formData.append("DocumentIfAny", "");
    formData.append("Remark", Remark);

    return formData;
  };

  const handleSendNotification = () => {
    const { data, notificationText } = notificationContent;
    if (data) {
      const { Template_ID, Employee_ID } = notificationId;
      const apiFormData = prepareDataForApiCall(
        selectedFile,
        fileType,
        notificationText,
        Template_ID,
        Employee_ID,
        selectedDeliveryOption,
        ""
      );

      sendNotifictionApiCall(apiFormData);
      return;
    }
    setNotificationError(true);
    setTimeout(() => {
      setNotificationError(false);
    }, 4000);
  };

  const slideContentProps = useSpring({
    transform: showNewContent ? "translateX(100%)" : "translateX(0%)",
    onRest: () => {
      setIsOldContentVisible(!showNewContent);
    },
  });

  const handleSave = (data, htmlString) => {
    const tempNotificationContent = { notificationText: htmlString, data };
    setNotificationContent(tempNotificationContent);
    setPreviewTemplateContent(htmlString);
    handleAddParams();
  };

  const refreshStateHandler = () => {
    setShowNewContent(false);
    setIsOldContentVisible(true);
    handleClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = {
        "application/pdf": "pdf",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "xlsx",
      };

      if (allowedTypes[file.type]) {
        setFileType(allowedTypes[file.type]);
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        //need to show some alert notification
        alert("Invalid file type. Please select a PDF, Word, or Excel file.");
      }
    }
  };

  const handleRemarksClick = () => {
    setAddRemark(true);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileType(null);
  };

  const handleThumbnailHover = () => {
    setIsHovered(true);
  };

  const handleThumbnailLeave = () => {
    setIsHovered(false);
  };

  const handleModalClose = () => {
    setfileModal(false);
  };

  const handleReamarkChange = (event) => {
    setRemarkContent(event.target.value);
  };

  const loaderStyle = {
    border: "2px solid #f3f3f3",
    borderTop: "2px solid #3498db",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div>
      {!hideButton && <Button onClick={handleOpen}>Template Action</Button>}
      <Modal
        open={open}
        onClose={refreshStateHandler}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            width: 1200,
            height: '700px',
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-title" variant="h6" component="div">
              Notification Options
            </Typography>
            <IconButton
              onClick={refreshStateHandler}
              size="small"
              aria-label="preview"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {notificationLoader && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loader size="large" type={"infinite-spinner"} />
            </div>
          )}
          {Object.keys(currentTemplate).length > 0 && !notificationLoader && (
            <Box id="modal-description" sx={{ mt: 2 }}>
              <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
                <animated.div style={slideContentProps}>
                  {isOldContentVisible && (
                    <Grid container spacing={3}>
                      {/* Delivery Options */}
                      <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                          <Typography variant="subtitle1">
                            Choose Delivery Option:
                          </Typography>
                          <RadioGroup
                            value={selectedDeliveryOption}
                            onChange={handleDeliveryOptionChange}
                            row
                          >
                            <FormControlLabel
                              value="EMAIL"
                              control={<Radio />}
                              label="Email"
                            />
                            <FormControlLabel
                              value="DASHBOARD"
                              control={<Radio />}
                              label="Dashboard Notification"
                            />
                            <FormControlLabel
                              value="CREATE-PDF"
                              control={<Radio />}
                              label="Create PDF"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        alignItems="flex-end"
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleAddParams}
                        >
                          Add Params
                        </Button>
                      </Grid>
                      {notificationError && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            width: "100%",
                            marginTop: "4px",
                          }}
                        >
                          <Notification
                            type={{
                              style: "error",
                              icon: true,
                            }}
                            closable={true}
                            onClose={() => setNotificationError(false)}
                          >
                            <span>
                              Please ADD PARAMS to send notification!!
                            </span>
                          </Notification>
                        </div>
                      )}
                      <Grid item xs={12}>
                        {type === "payStub" ? (
                          <PDFGenerator>
                            <DynamicTemplate
                              templateString={previewTemplateContent}
                              pdf={true}
                            />
                          </PDFGenerator>
                        ) : (
                          <DynamicTemplate
                            templateString={previewTemplateContent}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="file"
                            accept=".pdf, .doc, .docx, .xls, .xlsx"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            id="fileInput"
                          />

                          <label htmlFor="fileInput">
                            <Button
                              // variant=""
                              // color="primary"
                              component="span"
                              startIcon={<AttachFileIcon />}
                              sx={{ padding: 0, minWidth: "fit-content" }}
                            />
                          </label>

                          {selectedFile && fileType && (
                            <div
                              style={{
                                marginTop: "10px",
                                width: "100px",
                                height: "100px",
                                overflow: "hidden",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                position: "relative",
                                // cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "5px",
                              }}
                              // onClick={handleThumbnailClick}
                              onMouseEnter={handleThumbnailHover}
                              onMouseLeave={handleThumbnailLeave}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                <ClearIcon onClick={handleRemoveFile} />
                              </div>

                              <InsertDriveFileIcon
                                style={{
                                  fontSize: "25px",
                                  marginBottom: "5px",
                                }}
                              />
                              {isHovered && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white",
                                    background: "rgba(0, 0, 0, 0.7)",
                                    padding: "2px 5px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setfileModal(true)}
                                >
                                  View
                                </div>
                              )}
                              <div style={{ textAlign: "center" }}>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {selectedFile.name}
                                </span>
                                <br />
                                <span style={{ fontSize: "10px" }}>
                                  {fileType.toUpperCase()}
                                </span>
                              </div>
                            </div>
                          )}

                          <Button
                            startIcon={<AddCommentIcon />}
                            onClick={handleRemarksClick}
                            sx={{
                              padding: 0,
                              minWidth: "fit-content",
                              marginLeft: "2px",
                            }}
                          />
                          {addRemark && (
                            <TextField
                              multiline
                              rows={2}
                              placeholder="Add Remarks"
                              onChange={handleReamarkChange}
                              margin="normal"
                              variant="outlined"
                              value={remarkContent}
                            />
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <CustomButton
                          text={
                            <div style={{ display: "flex", gap: "4px" }}>
                              {submitNotificationLoader && (
                                <div>
                                  <style>{keyframesStyle}</style>
                                  <div style={loaderStyle}></div>
                                </div>
                              )}
                              <b>Send Notification</b>
                            </div>
                          }
                          icon={!submitNotificationLoader ? faSend : ""}
                          onClick={handleSendNotification}
                          disabled={submitNotificationLoader}
                        />
                      </Grid>
                    </Grid>
                  )}
                </animated.div>
                {!isOldContentVisible && (
                  <ViewTemplate
                    currentTemplate={currentTemplate}
                    onSave={handleSave}
                    onCancel={handleAddParams}
                    addParams={addParams}
                  />
                )}
              </Paper>
            </Box>
          )}
        </Box>
      </Modal>
      <FileModal
        isOpen={fileModal}
        handleClose={handleModalClose}
        fileType={fileType}
        selectedFile={selectedFile}
      />
    </div>
  );
};

export default NotificationPage;
