import { uniqueId } from "react-bootstrap-typeahead/types/utils";
import {
  SET_HEADER_FILTER,
  CHANGE_HEADER_FILTER,
  TOGGLE_DIALOG,
  UPDATE_PERFORMANCE_EVALUATION_DETAIL,
  ADVANCE_SEARCH_PERFORMANCE_EVALUTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  filterBy: {
    hr_control: "",
    ssn: "",
    first_name: "",
    request_date: null,
    status: "",
  },
  performanceEvaluationDetail: {
    ssn: "",
    first_name: "",
    last_name: "",
    mi: "",
    department: "",
    position: "",
    nextIncrementDate: null,

    peDueDate: null,
    ratingFromDate: null,
    ratingToDate: null,
    supervisionFromDate: null,
    supervisionToDate: null,
    supervisorName: "",
    reviewerName: "",
    planAttached: false,
    employeeReviewed_1: true,
    employeeReviewDate_1: null,
    employeeRemark_1: "",
    employeeAttachment_1: { id: "", name: "", type: "" },
    supervisorReviewed_1: false,
    supervisorReviewDate_1: null,
    supervisorRemark_1: "",
    supervisorAttachment_1: { id: "", name: "", type: "" },

    employeeReviewed_2: true,
    employeeReviewDate_2: null,
    employeeRemark_2: "",
    employeeAttachment_2: { id: "", name: "", type: "" },
    supervisorReviewed_2: false,
    supervisorReviewDate_2: null,
    supervisorRemark_2: "",
    supervisorAttachment_2: { id: "", name: "", type: "" },

    performanceAdvisory: "",
    employeeReviewed_3: true,
    employeeReviewDate_3: null,
    employeeRemark_3: "",
    employeeAttachment_3: { id: "", name: "", type: "" },
    supervisorReviewed_3: false,
    supervisorReviewDate_3: null,
    supervisorRemark_3: "",
    supervisorAttachment_3: { id: "", name: "", type: "" },
    reviewerReviewed_3: false,
    reviewerReviewDate_3: null,
    reviewerRemark_3: "",
    reviewerAttachment_3: { id: "", name: "", type: "" },

    performanceRating: "",
    salaryIncrement: "",
    employeeReviewed_4: true,
    employeeReviewDate_4: null,
    employeeRemark_4: "",
    employeeAttachment_4: { id: "", name: "", type: "" },
    supervisorReviewed_4: false,
    supervisorReviewDate_4: null,
    supervisorRemark_4: "",
    supervisorAttachment_4: { id: "", name: "", type: "" },
    reviewerReviewed_4: false,
    reviewerReviewDate_4: null,
    reviewerRemark_4: "",
    reviewerAttachment_4: { id: "", name: "", type: "" },
    employeeAgreeDate: null,
    employeeAgree: "",
    supervisorAgreeDate: null,
    supervisorAgree: "",
    headAgreeDate: null,
    headAgree: "",
    performanceEvaluationJobTasks: [],

    hrControl: `PE-${uniqueId()}`,
    hrStatus: "",
    hrRemark: "",
  },
  performanceEvaluationList: [
    {
      donor: [
        {
          id: 0,
          ssn: "333-10-1002",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1002",
      first_name: "Randy Morphy",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-123456",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Disapproved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1003",
      first_name: "Robert ",
      last_name: "Duenas",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-1234356",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1026",
      first_name: "Cecilly",
      last_name: "Hollak",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-345345345",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1005",
      first_name: "Yash ",
      last_name: "Lewis",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-345345789",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1025",
      first_name: "Ilene ",
      last_name: "Eroman",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-123456",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Pending",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1007",
      first_name: "Mice Pompio",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-123457",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1012",
      first_name: "Larry Page",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-123452",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
    {
      donor: [
        {
          id: 0,
          ssn: "",
          first_name: "",
          last_name: "",
          mi: "",
          department: "",
          position: "",
          grade: "",
          step: "",
          rate: "",
          donor_hours: "",
          leave_type: "",
          remarks: "",
        },
      ],
      ssn: "333-10-1123",
      first_name: "Robart Vense",
      last_name: "last name",
      mi: "mi",
      department: "rtl",
      position: "agent",
      grade: "A",
      step: "2",
      rate: "12",
      request_date: new Date(),
      request_hours: "",
      from: new Date(),
      to: new Date(),
      reason: "",
      leave_type: "Sick",
      remarks: "",
      support_document: { id: "", name: "", type: "" },
      head_supervisor: {
        approve: false,
        approve_date: new Date(),
      },
      department_head: {
        approve: false,
        approve_date: new Date(),
      },
      doa: {
        approve: false,
        approve_date: new Date(),
      },
      hr_control: "PE-123453",
      hr_status: "",
      hr_remarks: "",
      selectedDonor: 0,
      status: "Approved",
    },
  ],
};

const performanceEvaluation = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case CHANGE_HEADER_FILTER: {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          [action.payload.data?.target.name]: action.payload.data?.target.value,
        },
      };
    }
    case ADVANCE_SEARCH_PERFORMANCE_EVALUTION_SUCCESS:
      state = { ...state, searchResults: action.payload, loadingSearch: false };
      break;
    case UPDATE_PERFORMANCE_EVALUATION_DETAIL: {
      return {
        ...state,
        performanceEvaluationDetail: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default performanceEvaluation;
