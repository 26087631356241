import { useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { usePDF } from "react-to-pdf";
import { Download } from "@mui/icons-material";
import { PDFExport } from "@progress/kendo-react-pdf";

const PDFGenerator = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const getCurrentDateTimeString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const seconds = currentDate.getSeconds().toString().padStart(2, "0");
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}_${milliseconds}`;
  };

  const { toPDF, targetRef } = usePDF({
    filename: `probation_notice${getCurrentDateTimeString()}.pdf`,
    orientation: "landscape",
    scale: 1,
    unit: "in",
    format: [8.5, 11],
  });

  const handleDownloadPDF = async () => {
    setLoading(true);
    await toPDF();
    setLoading(false);
  };

  const pdfExportComponent = useRef(null);
  const container = useRef(null);

  const exportPDF = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          padding: "0 15px",
        }}
      >
        <Button
          variant="outlined"
          style={{ backgroundColor: "transparent" }}
          onClick={handleDownloadPDF}
          // onClick={exportPDF}
          startIcon={loading ? <CircularProgress size={20} /> : <Download />}
        >
          Download PDF
        </Button>
      </div>
      {/* <PDFExport ref={pdfExportComponent}
      paperSize={["8.5in","11in"]}
      landscape={true}
      multiPage={false}
      scale={0.5}
      margin={0}
      fileName={`probationary_Report_${getCurrentDateTimeString()}.pdf`}> */}
        <div
        ref={targetRef}
          // style={{
          //   width: "11in", // Width of 8.5 inches in landscape
          //   height: "8.5in", // Height of 11 inches in landscape
          //   orientation: "landscape",
          // }}
        >
          {children}
        </div>
      {/* </PDFExport> */}
    </div>
  );
};

export default PDFGenerator;

//paystubTemplate

// `<div style="border: 1px solid rgb(204, 204, 204); padding: 10px; margin: auto;"><div id="header" style="text-align: center; font-size: 20px; margin-bottom: 20px;">

// <h4 style="margin: 0px; display: inline-block;">DEPT OF ADMINISTRATION</h4>

// <h5 style="margin: 0px;">Employee Earnings Statement [@processing_Date]</h5>

// </div><div style="margin-left: auto; margin-right: auto; max-width: 800px; overflow-x: auto;"><table style="border-collapse: collapse; min-width: 700px;"><tbody><tr><td style="border: none;">Employee number
// </td>
// <td style="border: none;">[@employee_Id]
// </td>
// <td style="border: none;">Dept
// </td>
// <td style="border: none;">[@Dept]
// </td>
// </tr>
// <tr><td style="border: none;">Employee name
// </td>
// <td colspan="3" style="border: none;">[@employee_name]
// </td>
// </tr>
// <tr><td style="border: none;">Pay period ending
// </td>
// <td style="border: none;">[@ppE_Date]
// </td>
// <td style="border: none;">Hourly rate
// </td>
// <td style="border: none;">$[@rate]
// </td>
// </tr>
// <tr><td style="border: none;">Check amount
// </td>
// <td style="border: none;">[@ppe_eft_net]
// </td>
// <td style="border: none;">Check number
// </td>
// <td style="border: none;">[@check_number]
// </td>
// </tr>
// </tbody></table>
// <br><br>
// <table style="border-collapse: collapse; min-width: 700px;"><tbody><tr><th style="border: none; text-decoration: underline; min-width: fit-content; white-space: nowrap;">
//     EARNING INFORMATION

// </th>
// <th colspan="2" style="border: none; text-align: center; min-width: fit-content; white-space: nowrap;">
//     -----Hours----

// </th>
// <th colspan="3" style="border: none; min-width: 80px;">
// </th>
// </tr>
// <tr><th colspan="2" style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//     Current

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//     Ytd

// </th>
// <th style="border: none; min-width: 80px;">
// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//     Current

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//     Ytd

// </th>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Work hours
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_regular_hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Regular_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_regular_earings]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Regular_Earnings]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Annual leave
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_Al_Hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Al_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_Al_Amount]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Al_Amount]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Sick leave
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_Sl_Hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Sl_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_Sl_Amount]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Sl_Amount]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Other leave
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_Other_Leave_Hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Other_Leave_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_Other_Leave_Amt]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//          <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Other_Leave_Amt]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Premium pay
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_premium_hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Premium_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_premium_earnings]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Premium_Amount]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">Overtime
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ppe_overtime_hours]
// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">[@ytd_Overtime_Hours]
// </td>
// <td style="border: none; min-width: 80px;">
// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_overtime_earings]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Overtime_Amount]</span>

// </td>
// </tr>
// <tr><th style="border: none; padding-left: 20px; white-space: nowrap;">
//        Gross pay

// </th>
// <th colspan="3" style="border: none; text-align: right; white-space: nowrap;">
// </th>
// <th style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//      <span style="float: left; margin-right: 5px;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);">[@total_Gross_Pay]</span>

// </th>
// <th style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//      <span style="float: left; margin-right: 5px;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);">[@ytd_Gross_Earnings]</span>

// </th>
// </tr>
// <tr><td style="border: none; min-width: fit-content; white-space: nowrap;">Tax deffered amount
// </td>
// <td colspan="3" style="border: none; text-align: right; white-space: nowrap;">
// </td>
// <td style="border: none; text-align: right; min-width: fit-content; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">{[@total_Gross_Pay]-[@ppe_adjusted_gross_earnings]}</span>

// </td>
// <td style="border: none; text-align: right; min-width: fit-content; white-space: nowrap; padding-left: 16px;">
//        <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Tax_Defered_Amount]</span>

// </td>
// </tr>
// <tr><th style="border: none; padding-left: 20px; min-width: fit-content; white-space: nowrap;">
//        Adjusted gross pay

// </th>
// <th colspan="3" style="border: none; text-align: right; white-space: nowrap;">
// </th>
// <th style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//      <span style="float: left; margin-right: 5px;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);">[@ppe_adjusted_gross_earnings]</span>

// </th>
// <th style="border: none; text-align: right; white-space: nowrap; overflow: hidden; padding-left: 16px;">
//      <span style="float: left; margin-right: 5px;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);">{[@ytd_Gross_Earnings]-[@ytd_Tax_Defered_Amount]}</span>

// </th>
// </tr>
// </tbody></table>
// <br><br>
// <table style="border-collapse: collapse; min-width: 500px;"><tbody><tr><th style="border: none; text-decoration: underline; white-space: nowrap;">
//     LEAVE INFORMATION

// </th>
// <th colspan="2" style="border: none; white-space: nowrap; text-align: center;">
//     -----Hours----

// </th>
// </tr>
// <tr><th style="border: none; white-space: nowrap;">
//  Anuual Leave

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//    Current

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//    YTD

// </th>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Accrued

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Al_Hours_Accrued]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Al_Hours_Accrued]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Received (donated)

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Transferred_Al_Received]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Transferred_Al_Received]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Used

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Transferred_Al_Used]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Transferred_Al_Used]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Balance

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@annual_Leave]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@annual_Leave]

// </td>
// </tr>
// <tr><th style="border: none; white-space: nowrap;">
//  Sick Leave

// </th>
// <th colspan="2" style="border: none; white-space: nowrap;">
// </th>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Accrued

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Sl_Hours_Accrued]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Sl_Hours_Accrued]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Received (donated)

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Transferred_Sl_Received]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Transferred_Sl_Received]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Used

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ppe_Transferred_Sl_Used]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@ytd_Transferred_Sl_Used]

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;">
//    Balance

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@sick_Leave]

// </td>
// <td style="border: none; text-align: right; white-space: nowrap;">
//    [@sick_Leave]

// </td>
// </tr>
// </tbody></table>
// <br><br>
// <table style="border-collapse: collapse; min-width: 700px;"><tbody><tr><th style="border: none; white-space: nowrap;">
//  WITHHOLDING &amp; DEDUCTION INFORMATION

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//  Current

// </th>
// <th style="border: none; text-decoration: underline; text-align: right; white-space: nowrap;">
//  YTD

// </th>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Guam income tax

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_add_guam_tax]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Guam_Withholding_Tax]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Fedral income tax

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_fed_tax]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Federal_Withholding_Tax]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    State income tax

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_state_tax]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_State_Withholding_Tax]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Social security tax

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_ss_deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Soc_Sec_Deduction]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Medicare tax

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_medicare_deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Medicare_Deduction]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Defined benefit, HP, GP plan

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_Retire_Deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Retire_Db_Deduction]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Defined contribution plan

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ppe_Retire_Deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Retire_Dc_Deduction]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Health insurance (Pretax)

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@health_Deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Health_Emp_Contrb]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Dental insurance (Pretax)

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@dental_Deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Dental_Emp_Contrb]</span>

// </td>
// </tr>
// <tr><td style="border: none; white-space: nowrap;">
//    Life insurance (Pretax)

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@life_Deduction]</span>

// </td>
// <td style="border: none; text-align: right; white-space: nowrap; padding-left: 16px;">
//   <span style="float: left; margin-right: 5px;font-weight: 400;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);font-weight: 400;">[@ytd_Life_Emp_Contrb]</span>

// </td>
// </tr>
// <tr><td style="border: none; padding-left: 20px; white-space: nowrap;font-weight: bold;">
//    Statutory withholding total

// </td>
// <th style="border: none; text-align: right; white-space: nowrap; overflow: hidden; padding-left: 16px;">
//  <span style="float: left; margin-right: 5px;">$</span>
//          <span style="overflow: hidden; max-width: calc(100% - 10px);">{[@ppe_add_guam_tax]+[@ppe_fed_tax]+[@ppe_state_tax]+[@ppe_ss_deduction]+[@ppe_medicare_deduction]+[@ppe_Retire_Deduction]+[@ppe_Retire_Deduction]+[@health_Deduction]+[@life_Deduction]+[@dental_Deduction]}</span>

// </th>
// <th style="border: none; text-align: right; white-space: nowrap; overflow: hidden; padding-left: 16px;">
//       <span style="float: left; margin-right: 5px;">$</span>
//       <span style="overflow: hidden; max-width: calc(100% - 10px);">{[@ytd_Guam_Withholding_Tax]+[@ytd_Federal_Withholding_Tax]+[@ytd_State_Withholding_Tax]+[@ytd_Soc_Sec_Deduction]+[@ytd_Medicare_Deduction]+[@ytd_Retire_Db_Deduction]+[@ytd_Retire_Dc_Deduction]+[@ytd_Life_Emp_Contrb]+[@ytd_Health_Emp_Contrb]+[@ytd_Dental_Emp_Contrb]}</span>
// </th>
// </tr>
// </tbody></table>
// <br>
// [@deductionByVendor]

// </div></div><br>
// `
