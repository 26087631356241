import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import CustomTextField from "../../../Layout/Components/TextField";
import {
  salaryincrement,
  advanceSearch,
  advanceSearchDepartment,
  advanceSearchPosition,
  paygradeCalc,
  findAsyouType,
} from "../../../store/advanceSearchv2/actions";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  TextareaAutosize,
  Typography,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  Autocomplete,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryColumns from "./HistoryColumns";
import { getAuditHistoryData } from "../../Records/NPAForms/slices/getDataBySSNSlice";
import HistoryDataTable from "../../../CommonComponents/HistoryDataTable";
import {
  ConcatenedColumns,

  sortingFunc,
} from "../../../CommonComponents/CommonFunction";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  faCopy,
  faEye,
  faArrowLeft,
  faTentArrowLeftRight,
  faFaceSmile,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import Modal from "@mui/material/Modal";
import {
  updatesalaryIncrement,
  getinfosalaryIncrement,
  getTransaction,
  // getAuditTrail,
} from "../../../store/salaryIncrement/action";
import { getProcessesStatusList } from '../../../store/process/actions';

import { ShowError, ShowSucess } from "../../../_helper/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import PEworkFlow from "./PEworkFlow";
import { useAuth } from "oidc-react";
import { loadingPanel } from "../../../CommonComponents/CommonFunction";
import { Icon } from "@progress/kendo-react-common";
import DifferencesModal from "../../OutsideEmployment/components/DifferencesModal";
import CustomLegand from "../../Records/NPAForms/CustomLegand";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import NPAVIEWPrint from "../../Records/NPAForms/NPAVIEWPrint";
import NPADirectPrint from "../../Records/NPAForms/NPADirectPrint";
import NPADirectPrintSalaryIncrement from "../../Records/NPAForms/NPADirectPrintSalaryIncrement";
import { setTime } from "@progress/kendo-react-dateinputs/dist/npm/utils";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};
const SalaryIncrement = () => {
  const { employeeID, HrControl } = useParams();
  const { userData: { profile: { OVERRIDE_NPA,READONLY_NPA } } = {} } = useAuth();
  const { userData } = useAuth();
  const location = useLocation();
  // const flag = GG1AccessLevel === "all-viewer";
  const overideflag = OVERRIDE_NPA == "YES";
  const isReadOnly = READONLY_NPA == "YES";
  const dispatch = useDispatch();
  const {
    departmentDivision,
    searchResults,
    salaryResults,
    findAsyouTypeResults,
    loadingFindAsYouType,

    position,

    loadingSearch,
    paygreadeResults,
    searchParameter = {},
    salaryResults: { results, rowCount } = {},
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const { salaryIncrement, getinfoSalary, loading, transactionStatus } = useSelector(
    (state) => state.SalaryIncremntReducerUpdate
  );
  const { auditHistory } = useSelector((state) => state.getDataBySSNSlice);
  console.log("auditHistory", auditHistory)
  const [openDiffModal, setOpenDiffModal] = useState(false);
  const [differenceData, setDifferenceData] = useState([]);

  const { processStatusList } = useSelector((state) => state.processReducer);
  const [openHistory, setOpenHistory] = useState(false);
  const columns = location.state;

  const [isOverrideClicked, setIsOverrideClicked] = useState(false);
  const editable = OVERRIDE_NPA === "YES";
  const history = useNavigate();
  const [dataValue, setDataValue] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openRemarks, setOpenRemarks] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseRemarks = () => setOpenRemarks(false)
  const [messgaeData, setMessageData] = useState({});
  const [department, setDepartment] = useState([]);
  const [departmentDiviosion, setDepartmentDivision] = useState([]);
  const [transactionStatusValue, setTransactionStatusVal] = useState([])
  const [positionTitle, setPositionTitle] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [transaction, setTransaction] = useState({});
  const TransactionsBySSNAndAction = transaction;
  const [transactionProcessStatuses, setAuditTrailTransaction] = useState([]);
  const [OverrideCondtion, setOverrideCondition] = useState("")
  console.log("OverrideCondtion", OverrideCondtion);
  const [sortData, setSortData] = useState([
    { field: "currentProcessStatus", dir: "desc" },
  ]);
  const [sortedColumns, setSortedColumns] = useState([]);

  const [optionSelectedItems, setOptionSelectedItems] = useState([]);

  const [printState, setPrintState] = useState({value:"", label: ""});
  const [mySignature, setMySignature]= useState();


  console.log("transactionStatusValue", transaction)

  console.log("optionSelectedItems", optionSelectedItems);




  const dataTitles = (findAsyouTypeResults || [])
    .map((title) => ({
      label: title?.keyField || "",
      value: title?.valueField || "",
    }))
    .filter((title) => title.label && title.value);





  const { control, setValue, handleSubmit, reset, register, getValues } =
    useForm({
      defaultValues: {
        Annual_Salary: "",
        Budget_Acct_No: "",
        CurrentProcessStatus: "",
        Department: "",
        Division: "",
        Emp_Status: "",
        Eval_Type: "",
        EmployeeID: "",
        FTENo: "",
        FirstName: "",
        HrControl: "",
        Job_Order_No: "",
        LastName: "",
        MiddleName: "",
        Ndate_Increm: "",
        Nposition_No: "",
        // OverallPerformanceRating: "",
        PayGrade: "",
        PayStep: "",
        Rate: "",
        SSN: "",
        NEXTINCR: "",
        Emp_Description: "",
        RequestedId: ""
      },
      resetOptions: {
        keepDirtyValues: false,
        keepErrors: true,
      },
    });

  const actions = useMemo(() => {
    return [
      {
        title: "Show Details",
        action: (props) => {
          const findIndex =
            auditHistory &&
            auditHistory.findIndex((item) => {
              return item?.changeDateTime === props?.dataItem?.changeDateTime;
            });

          if (findIndex !== -1 && findIndex + 1 < auditHistory.length) {
            const filterData = [
              auditHistory[findIndex],
              auditHistory[findIndex + 1],
            ];

            setOpenDiffModal(true);
            let newFilterData = filterData.map((item) => {
              const { updatedColumns, ...rest } = item;
              return rest;
            });
            // setDifferenceData(filterData);
            setDifferenceData(newFilterData);
          }
        },
        icon: { iconName: 'k-icon  k-i-eye' },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
    ];
  });



  console.log("processStatusList", processStatusList)
  const OpenHistoryComponent = () => {
    setOpenHistory(true);
  };

  useEffect(() => {
    // debugger;
    if (
      TransactionsBySSNAndAction &&
      TransactionsBySSNAndAction?.actnNo

    ) {
      dispatch(
        getAuditHistoryData({
          employeeId: TransactionsBySSNAndAction?.employeeId,
          actionNo: TransactionsBySSNAndAction?.actnNo,
        })
      );
    }
  }, [TransactionsBySSNAndAction]);

  const handleSortChange = (sort) => {
    setSortData(sort);
  };
  useEffect(() => {
    if (historyData.length > 0) {
      sortingFunc(sortData, historyData, setHistoryData);
    }
  }, [sortData]);
  useEffect(() => {
    ConcatenedColumns(columns, HistoryColumns, setSortedColumns, userData);
  }, [columns, sortData]);

  useEffect(() => {
    if (auditHistory && auditHistory.length !== 0) {
      setHistoryData(auditHistory);
    }
  }, [auditHistory]);
  useEffect(() => {
    console.log(salaryIncrement, 'salaryIncrement')
    if (salaryIncrement && salaryIncrement.actionNo) {
      setOpen(true);
      setMessageData(salaryIncrement);
      ShowSucess("Salary Increment Completed", {
        position: "top-right",
      });

      // setTimeout(() => {
      //   setOpen(false);

      //   history(-1)
      // }, 4000);

    }
  }, [salaryIncrement]);

  const handleModelClose = () => {
    setOpen(false);
    history(-1)
  }

  useEffect(() => {
    if (!employeeID) {
      if (results && Object.keys(results).length > 0) {
        setDataValue(results[0]);

      }
    }


  }, [results]);
  useEffect(() => {
    if (position && Object.keys(position).length > 0) {
      setPositionTitle(position);
    }
  }, [position]);

  useEffect(() => {
    if (
      searchResults.results &&
      Object.keys(searchResults.results).length > 0
    ) {
      setDepartment(searchResults.results);
    }
  }, [searchResults.results]);

  useEffect(() => {
    if (departmentDivision && departmentDivision.length > 0) {
      setDepartmentDivision(departmentDivision);
    }
  }, [departmentDivision]);


  useEffect(() => {
    // Check if employeeID exists before dispatching the action

    if (employeeID) {
      dispatch(getinfosalaryIncrement(HrControl, employeeID));

    }


  }, [HrControl, dataValue.EmployeeID]);





  const payload = {
    tableName: "vw_employeeMaster",
    searchParams: "[]",
    columns: "*",
    pageNumber: 1,
    pageSize: 9999,
    sortData: "",
    additionalWhere: "",
    qID: 0,
    location: "global",
  };
  const fetchDivisionByDepartment = (dept_Code) => {
    dispatch(
      advanceSearchDepartment({
        ...payload,
        tableName: "LK_DPDV",
        columns: "distinct Dept_key, name",
        additionalWhere: `dept_code='${dept_Code}'`,
      })
    );
  };

  // };

  useEffect(() => {
    if (!employeeID) {
      // Execute the rest of the useEffect only if employeeID is not present
      if (transactionStatus?.transactionProcessStatuses && transactionStatus?.transactionProcessStatuses?.length > 0) {
        setTransactionStatusVal(transactionStatus?.transactionProcessStatuses);
      }
    }
  }, [employeeID, transactionStatus.transactionProcessStatuses]);

  useEffect(() => {
    dispatch(getTransaction(HrControl));
  }, [dispatch, HrControl])

  useEffect(() => {
    dispatch(
      advanceSearchPosition(
        {
          ...payload,
          tableName: "LK_NPosition",
          // sortData: "Title ASC",
          // columns: "distinct NPosition_No, Title",
          // additionalWhere: "Title is not null",
        },
        "position"
      )
    );

    dispatch(
      advanceSearchDepartment({
        ...payload,
        tableName: "LK_DPDV",
        columns: "distinct Dept_key, name",
      })
    );

    dispatch(
      advanceSearch(
        {
          ...payload,
          tableName: "LK_DPDV",
          columns: "distinct Dept_Code, DepartmentName",
          additionalWhere: "DepartmentName is not null",
        },
        "division"
      )
    );
  }, []);

  useEffect(() => {
    if (
      getinfoSalary.transaction &&
      Object.keys(getinfoSalary.transaction).length > 0
    ) {
      setTransaction(getinfoSalary.transaction);
    }
  }, [getinfoSalary.transaction]);

  useEffect(() => {
    if (
      getinfoSalary?.transactionProcessStatuses &&
      Object.keys(getinfoSalary?.transactionProcessStatuses.length > 0)
    ) {
      setAuditTrailTransaction(getinfoSalary?.transactionProcessStatuses);
    }
  }, [getinfoSalary.transactionProcessStatuses]);

  useEffect(() => {
    if (paygreadeResults.results && paygreadeResults.results.length > 0) {
      const data = paygreadeResults.results[0];
      setValue("TORATE", data.NRATE);
      setValue("TOSALARY", data.NSALARY);
    }
  }, [paygreadeResults.results]);
  const OverAllPerofrmanceRating = [
    { value: "O", name: "Outstanding" },
    { value: "S", name: "Satisfactory" },
    { value: "U", name: "Unsatisfactory" },
    { value: "H", name: "High Satisfactory" },
    { value: "M", name: "Marginal" },
    { value: "X", name: "Unable to Rate" },
  ]
  useEffect(() => {

    const RatingLetters = {
      1: 'O',
      2: 'S',
      3: 'U',
      4: 'H',
      5: 'M',
      6: 'X',
    };
    // const RatingLetters = {
    //   'O': 'O',
    //   'S': 'S',
    //   'U': 'U',
    //   'H': 'H',
    //   'M': 'M',
    //   'X': 'X',
    // };
    const RatingLettersss = {
      1: "Outstanding",
      2: "Satisfactory",
      3: "Unsatisfactory",
      4: "High Satisfactory",
      5: "Marginal",
      6: "Unable to Rate",
    };


    if (
      (dataValue && Object.keys(dataValue).length > 0) ||
      (transaction && Object.keys(transaction).length > 0) || positionTitle
    ) {
      setOptionSelectedItems(

        {
          department: {
            code: dataValue?.Department || transaction?.toDeptKey,
            name: dataValue?.toDepartment || transaction?.toDepartment,
          },
          division: {
            code: dataValue?.Division || transaction?.toDivisionKey,
            name: dataValue?.toDivisionName || transaction?.toDivisionName,
          },
          selectedPosition: {
            // label: positionTitle.find((d) => d.NPOSITION_NO = dataValue?.Nposition_No)?.TITLE.trim("") || transaction?.toPositionTitle,
            label: dataValue?.toPositionTitle || transaction?.toPositionTitle,
            value: dataValue?.Nposition_No || transaction?.toPostTitleNo,

          },
          overAllItems: {
            value: RatingLetters[dataValue?.OverallPerformanceRating] || RatingLetters[transaction?.overallEvaluation],
            // name: OverAllPerofrmanceRating.find((D) => D.value = RatingLetters[dataValue.OverallPerformanceRating])?.name,
            // name:OverAllPerofrmanceRating.find((D)=>RatingLetterss[D.value] = dataValue?.OverallPerformanceRating)?.name;
            name: RatingLettersss[dataValue?.OverallPerformanceRating] || transaction?.evaluationTypeDescription
          }
        });





      reset({
        FROMDEPARTMENT: dataValue?.fromDepartment || transaction?.fromDepartment,

        FROMDIVISION: dataValue?.fromDivisionName || transaction?.fromDivisionName,
        FROMDIVISIONKEY: dataValue?.fromDivisionKey || transaction?.fromDivisionKey,
        FROMPOSITION: dataValue?.fromPositionTitle || transaction?.fromPositionTitle,
        FROMPOSITIONNO: dataValue?.fromPostTitleNo || transaction?.fromPostTitleNo,

        FROMLASTNAME: dataValue?.LastName || transaction?.fromLastName,
        FROMMIDDLENAME: dataValue?.MiddleName || transaction?.fromMiddleName,
        FROMFIRSTNAME: dataValue?.FirstName || transaction?.fromFirstName,
        FROMGRADE: dataValue?.PayGrade || transaction?.fromPayGrade,
        FROMSTEP: dataValue?.PayStep || transaction?.fromStep,
        FROMRATE: dataValue?.Rate || transaction?.fromRate,
        FROMSALARY: dataValue?.Annual_Salary?.toFixed(2) || transaction?.fromSalary?.toFixed(2),

        TODEPARTMENT: dataValue?.toDepartment || transaction?.toDepartment,
        TODEPARTMENTKEY: dataValue?.toDeptKey || transaction?.toDeptKey,
        TODIVISION: dataValue?.toDivisionName || transaction?.toDivisionName,
        TODIVISIONKEY: dataValue?.toDivisionKey || transaction?.toDivisionKey,
        TOLASTNAME: dataValue?.LastName || transaction?.toLastName,
        TOMIDDLENAME: dataValue?.MiddleName || transaction?.toMiddleName,
        TOFIRSTNAME: dataValue?.FirstName || transaction?.toFirstName,
        TOPOSITIONNo: dataValue?.toPostTitleNo || transaction?.toNpositionNo,
        TOPOSITION: dataValue?.toPositionTitle || transaction?.toPositionTitle,
        TOGRADE: dataValue?.toGrade || transaction?.toPayGrade,
        TOSTEP: dataValue?.toStep || transaction?.toStep,
        TORATE: dataValue?.toRate || transaction?.toRate,
        TOSALARY: dataValue?.toSalary?.toFixed(2) || transaction?.toSalary?.toFixed(2),

        Budget_Acct_No: dataValue?.Budget_Acct_No || transaction?.budgetAcctNo,
        EmployeeID: dataValue?.EmployeeID || transaction?.employeeId,
        CurrentProcessStatus:
          dataValue?.CurrentProcessStatus || transaction?.currentProcessStatus,
        Department: dataValue?.Department ? department.find((d) => d.Dept_Code = dataValue?.Department)?.DepartmentName : null || transaction?.fromDepartment,
        Division: dataValue?.Division ? departmentDiviosion.find((d) => d.Dept_key = dataValue?.Division)?.name : null || transaction?.fromDeptDivSec,
        Emp_Description: RatingLetters[dataValue?.OverallPerformanceRating] || RatingLetters[transaction?.overallEvaluation],
        Eval_Type: dataValue?.Eval_Type || transaction?.evalType,

        ActionDate: dataValue?.actionDate || transaction?.actionDate || new Date()?.toISOString() || "",
        EffectiveDate:
          dataValue?.EffectiveDate || transaction.actionEffectiveDate,
        EmployeeName: dataValue?.EmployeeName || transaction?.fullName,
        FTENo: dataValue?.FTENo || transaction?.fteNo,

        HrControl: dataValue?.HrControl || transaction?.hrControl,
        Job_Order_No: dataValue?.Job_Order_No || transaction?.jobOrderNumber,
        Ndate_Increm: dataValue?.Ndate_Increm,
        // OverallPerformanceRating: dataValue?.OverallPerformanceRating,
        SSN: dataValue?.SSN || transaction?.socialSecurityNumber,
        Nposition_No: dataValue?.Nposition_No || transaction?.toPostTitleNo,
        Position: dataValue?.Nposition_No ? positionTitle.find((d) => d.NPOSITION_NO = dataValue?.Nposition_No)?.TITLE : null || transaction?.toPositionTitle,
        PostingFlag: transaction?.postingFlag || "N",
        PostingDate: transaction?.postingDate,
        Remarks: dataValue?.Remarks || transaction?.remarks || "N/A",
        STATUSREMARKS: transaction?.statusRemarks,
        RequestedId: transaction?.requestId,
        NEXTINCR: dataValue?.toNextIncrementDate || transaction?.nextIncrementDate
        // Ndate_Increm:dataValue?.Ndate_Increm,
      });
    }
  }, [dataValue, transaction, positionTitle]);

  useEffect(() => {
    dispatch(
      salaryincrement({
        columns: "*",
        location: "global",
        pageSize: 100,
        pageNumber: 1,
        qID: 0,
        // logicOperator: "AND",
        searchParams: JSON.stringify([
          {
            columnName: "hrControl",
            operator: "=",
            value: HrControl,

            dataType: "VARCHAR(MAX)",
            logicOperator: "AND",
          },
        ]),
        additionalWhere: "",
        tableName: "fn_GetEmployeePerformance",
        sortData: "",
      })
    );
  }, []);
  const handleFormSubmit = (isOverride) => {

    setOverrideCondition(isOverride)


    setOpenRemarks(true)



    //  dispatch(postSlary())
  };

  const handleSubmitRemarks = (OverrideCondtion) => {
    const RatingLetters = {
      'O': 'O',
      'S': 'S',
      'U': 'U',
      'H': 'H',
      'M': 'M',
      'X': 'X',
    };
    if (!optionSelectedItems?.overAllItems?.value) {
      ShowError("Please select an overall performance rating", {
        position: "top-right",
      });

      setTimeout(() => {
        setOpenRemarks(false)

      }, 3000);


      return;
    }
    const RatingLetterss = {
      'O': 1,
      'S': 2,
      'U': 3,
      'H': 4,
      'M': 5,
      'X': 6,
    };

    const formDataValue = getValues();
    try {

      if (formDataValue && Object.keys(formDataValue).length > 0) {
        let newEffectiveDate = new Date(formDataValue.EffectiveDate);
        const payStep = parseInt(formDataValue.TOSTEP);
        // newEffectiveDate.setMonth(newEffectiveDate.getMonth() + (payStep <= 6 ? 12 : payStep < 9 ? 18 : 24));
        newEffectiveDate = new Date(newEffectiveDate.setMonth(newEffectiveDate.getMonth() + (payStep <= 6 ? 12 : payStep < 9 ? 18 : 24)));



        newEffectiveDate.setDate(newEffectiveDate.getDate() + 1);
        const payload = {
          PerformanceEvaluationControlNo: employeeID == null ? HrControl : transaction?.performanceEvaluationControlNo || transaction?.hrControl ,
          employeeId: formDataValue.EmployeeID || null,
          statusRemarks: formDataValue?.STATUSREMARKS,

          nextIncrementDate: newEffectiveDate.toISOString() || null,
          HrControl: formDataValue?.HrControl || null,
          actionDate: formDataValue?.ActionDate || null,
          actionEffectiveDate: formDataValue?.EffectiveDate,
          budgetAcctNo: formDataValue?.Budget_Acct_No || null,
          referenceID: "",
          employmentContractNo: "",
          finalPost: "F",
          fromDepartment: formDataValue.FROMDEPARTMENT || null,
          fromFirstName: formDataValue.FROMFIRSTNAME || null,
          fromFteNo: formDataValue?.FTENo || null,
          fromLastName: formDataValue.FROMLASTNAME || null,
          worK_SCH: "FULL-TIME",
          fromMiddleName: formDataValue.FROMMIDDLENAME || null,
          fromPayGrade: formDataValue.FROMGRADE || null,
          fromPositionTitle: formDataValue.FROMPOSITION || null,
          fromRate: formDataValue.FROMRATE || null,
          fromSalary: formDataValue?.FROMSALARY || null,
          fromStep: formDataValue?.FROMSTEP || null,
          jobOrderNumber: formDataValue?.Job_Order_No || null,
          legalAuthority: " ",
          natureOfActionCode: 30,

          overallEvaluation: RatingLetterss[optionSelectedItems.overAllItems?.value],  //first one
          payrollDate: "",
          postingDate: formDataValue?.formDataValue,
          postingFlag: formDataValue?.PostingFlag,
          probationEndDate: null,
          requestId: formDataValue?.RequestedId,
          retirementCoverage: null,
          enterByFirstName: "",
          enterByMiddleName: "",
          enterByLastName: "",
          enterByCellPhone: null,
          terminationDate: null,
          toDepartment: optionSelectedItems?.department?.name || null,
          toFirstName: formDataValue?.TOFIRSTNAME || null,
          toFteNo: formDataValue.FTENo || null,
          toFullName: dataValue?.EmployeeName || null,
          toLastName: formDataValue?.TOLASTNAME || null,
          toMiddleName: formDataValue?.TOMIDDLENAME || null,

          employmentDuration: 0,
          employmentDurationType: "Days",
          toPayGrade: formDataValue?.TOGRADE || null,
          toPositionTitle: optionSelectedItems?.selectedPosition?.label || null,
          toRate: formDataValue?.TORATE || null,
          toSalary: formDataValue?.TOSALARY || null,
          toStep: formDataValue?.TOSTEP || null,
          processId: 100,
          currentProcessStatus: 274,
          actnNo: "",
          fullName: formDataValue?.EmployeeName || null,
          fromDeptDivSec: formDataValue?.Division,
          toDeptDivSec: optionSelectedItems?.division?.code,
          fromBudgetNo: "",
          toBudgetNo: "",
          dp1: "",
          dp2: "",
          termDate: null,
          healthPlan: " ",
          lifePlan: " ",
          retSs: "",
          probationDate: null,
          evalType: RatingLetters[optionSelectedItems?.overAllItems?.value],     /////// ifrst two
          evaluationTypeDescription: optionSelectedItems?.overAllItems?.name,       /// first three
          cancelActn: "",
          employeeDaysCount: 0,
          retireSsCode: "",
          retireSsCodeMaster: "",
          retSsDesc: "",
          retSsDescMaster: "",
          fromDeptKey: dataValue.FROMDIVISIONKEY || null,
          toDeptKey: optionSelectedItems?.department?.code,
          fromNpositionNo: formDataValue?.FROMPOSITIONNO,
          toNpositionNo: optionSelectedItems?.selectedPosition.value,
          natureTitle: "SALARY INCREMENT",
          fromPositionTitlewithgrade: null,
          toPositionTitlewithgrade: null,
          fteNo: formDataValue?.FTENo || null,
          remarks: formDataValue?.Remarks,
          evaluationDescription: optionSelectedItems?.overAllItems?.name || dataValue?.Eval_Type, /////////////////////////
          toPostTitleNo: optionSelectedItems?.selectedPosition.value,
          fromPostTitleNo: formDataValue?.FROMPOSITIONNO, //foifjeofoiewjfoiweoijewwoifjewoi
          dateEntered: "",
          dateEdit: "",
          enterBy: "",
          editBy: "",
          employmentDate: "",
          expirationDate: null,
          transactionStatus: "",



          dateOfBirth: "",
          dateOfRequest: "",
          natureOfActionTitle: "SALARY INCREMENT",
          contactNumber: "",
          contactEmail: "",
          contactPosition: "",
          contactDepartment: "",
          contactFullName: "",
          // llll
          fromDivisionKey: formDataValue?.FROMDIVISIONKEY || "",
          fromDivisionName: formDataValue?.FROMDIVISION || null,
          toDivisionKey: optionSelectedItems?.division.code || null,
          toDivisionName: optionSelectedItems?.division.name || null,
          detailTermDate: null,
          typeOfRecruitment: null,
          areaOfConsidration: null,
          isOverride: OverrideCondtion,
        };

        // const confirmSubmission = window.confirm(isOverride ? "Are you sure to Override NPA ?" : "Are you sure to perform Salary Modification");
        // if (confirmSubmission) {

        // }
        dispatch(updatesalaryIncrement(payload));
        setOpenRemarks(false)

      } else {
        console.error(
          "Data not available. Please wait for results to be fetched."
        );
      }
    } catch (e) {
      console.log(e);
    }
  }


  console.log("salaryIncrement")
  const [error, setError] = useState({});

  const FormInputText = ({
    name,
    label,
    type = "text",
    options = [],
    className,
    handleChange,
    rows,
    multiline,
    disabled = false,
    readOnly = false,
  }) => {
    const errorMessage = error[name];


    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value }, field }) => {
          if (type === "date") {
            return (
              <FormControl
                error={
                  (!!errorMessage && onBlur && value === "") || value == null
                }
                fullWidth
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={label}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        inputVariant="outlined"
                        size="small"
                        {...params}
                      />
                    )}
                    disabled={disabled}
                    style={{ color: "black" }}
                    readOnly={readOnly}
                    value={dayjs(value) || null}
                    onChange={onChange}
                    format="MM/dd/yyyy"
                    onBlur={onBlur}
                  />
                </LocalizationProvider>
                {value === "" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
            );
          } else if (type === "dropdown") {
            return (
              <FormControl
                error={!!errorMessage && onBlur && value === ""}
                fullWidth
                size="small"
                margin="dense"
              >
                <InputLabel>{label}</InputLabel>
                <Select
                  value={value}
                  onChange={handleChange || onChange}
                  onBlur={onBlur}
                  disabled={disabled}
                  readOnly={readOnly}
                >
                  {options.map((option, index) => (
                    <MenuItem key={`${index}+1`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {value === "" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
            );
          } else {
            return (
              <TextField
                size="small"
                margin="dense"
                type={type}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                label={label}
                rows={rows}
                multiline={multiline}
                disabled={disabled}
                readOnly={readOnly}
                error={
                  !!errorMessage && onBlur && (value == "" || value == null)
                }
                helperText={value === "" ? errorMessage : ""}
              />
            );
          }
        }}
      />
    );
  };

  const handleCHeck = () => {

    dispatch(
      paygradeCalc({
        columns: "*",
        location: "global",
        pageSize: 2,
        pageNumber: 1,
        qID: 0,
        // logicOperator: "AND",
        searchParams: JSON.stringify([
          {
            columnName: "paygrade ",
            operator: "=",
            value: getValues().TOGRADE?.trim(),

            dataType: "VARCHAR(MAX)",
            logicOperator: "AND",
          },
          {
            columnName: "paystep",
            operator: "=",
            value: getValues().TOSTEP?.trim(),
            dataType: "VARCHAR(MAX)",
            logicOperator: "AND",
          },
        ]),
        additionalWhere: "",
        tableName: "fn_GetDataby_Paygrade_n_Step",
        sortData: "",
      })
    );
  };

  const renderProcessStatus = () => {
    if (!transactionProcessStatuses.length && !transactionStatusValue.length) return null;

    return (
      <TableContainer style={{ marginBottom: "15px" }} component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sr No</TableCell>
              <TableCell>Process</TableCell>
              <TableCell>Performed by</TableCell>
              <TableCell>Performed Date</TableCell>
              <TableCell>Remark</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>

          {transactionStatusValue.length
            ? transactionStatusValue.map((item, i) => (
              <TableBody key={`npaStatus${i + 1}`}>
                <TableRow>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    {item.transactionProcessStatus?.statusName || "N/A"}
                  </TableCell>
                  <TableCell>
                    {item?.transactionProcessStatus.peformedByName || "N/A"}
                  </TableCell>
                  <TableCell>
                    {item?.transactionProcessStatus.performedDate === null
                      ? "N/A"
                      : dayjs(item?.transactionProcessStatus.performedDate).format("MM/DD/YYYY hh:mm A")}
                  </TableCell>
                  <TableCell>{item?.transactionProcessStatus.remarks || "N/A"}</TableCell>
                  <TableCell>{item?.transactionProcessStatus.status || "Completed"}</TableCell>
                </TableRow>
              </TableBody>
            ))
            : transactionProcessStatuses.map((item, i) => (
              <TableBody key={`npaStatus${i + 1}`}>
                <TableRow>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>
                    {item.transactionProcessStatus?.statusName || "N/A"}
                  </TableCell>
                  <TableCell>
                    {item?.transactionProcessStatus.peformedByName || "N/A"}
                  </TableCell>
                  <TableCell>
                    {item?.transactionProcessStatus.performedDate === null
                      ? "N/A"
                      : dayjs(item?.transactionProcessStatus.performedDate).format("MM/DD/YYYY hh:mm A")}
                  </TableCell>
                  <TableCell>{item?.transactionProcessStatus.statusRemarks || "N/A"}</TableCell>
                  <TableCell>{item?.transactionProcessStatus.status || "Completed"}</TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      </TableContainer>
    );
  };

  /* View/Print NPA form  */
  const [approvedData, setApprovedData] = useState([]);

  useEffect(() => {
    let newArray = [];
    if (transactionProcessStatuses && transactionProcessStatuses.length) {
      transactionProcessStatuses.map((item, i) => {
        // console.log("123123 Items in Process ", item, i);
        if (item?.transactionProcessStatus.processStatusId == 7 && item?.transactionProcessStatus.status?.startsWith("Transaction Approved To")) {
          newArray.push({
            performedBy: item?.transactionProcessStatus.peformedByName,
            statusId: item?.transactionProcessStatus.processStatusId,
            performedOn: item?.transactionProcessStatus.performedDate
              ? dayjs(item?.transactionProcessStatus.performedDate).format("MM/DD/YYYY hh:mm A")
              : "",
          });
        }
        if (item?.transactionProcessStatus.processStatusId == 133 && item?.transactionProcessStatus.status?.startsWith("Transaction Approved To")) {
          newArray.push({
            performedBy: item?.transactionProcessStatus.peformedByName,
            statusId: item?.transactionProcessStatus.processStatusId,
            performedOn: item?.transactionProcessStatus.performedDate
              ? dayjs(item?.transactionProcessStatus.performedDate).format("MM/DD/YYYY hh:mm A")
              : "",
          });
        }
        if (item?.transactionProcessStatus.processStatusId == 8 && item?.transactionProcessStatus.status?.startsWith("Transaction Approved To")) {
          newArray.push({
            performedBy: item?.transactionProcessStatus.peformedByName,
            statusId: item?.transactionProcessStatus.processStatusId,
            performedOn: item?.transactionProcessStatus.performedDate
              ? dayjs(item?.transactionProcessStatus.performedDate).format("MM/DD/YYYY hh:mm A")
              : "",
          });
        }
      });
      // console.log("123123 ::: ", newArray);
      setApprovedData(newArray);
    }
  }, [transactionProcessStatuses]);
const [viewNpaForm, setViewNPAForm] = useState(false);
const [printNpaForm, setPrintNPAForm] = useState(false);
const DirectNpaRef = useRef()
const handleDirectNPAPrint = useReactToPrint({
  content: () => DirectNpaRef.current,
  onAfterPrint: () => {
    setPrintState({value:"", label:""});
    setPrintNPAForm(false);
  },
  documentTitle: `eHRP-report-${moment().format("MM-DD-YY-HH:mm:ss")}`,
})

  return (
    <>
      <Fragment>
        <Container fluid={true}>
          <Breadcrumbs
            mainTitle={
              <Typography variant="h5">
                <Icon style={{ fontSize: '25px', margin: "10px" }} name="list-latin-small" />
                Salary Increment
              </Typography>
            }
            parent={
              employeeID ? <Link
                to={`${process.env.PUBLIC_URL}/npa-records`}
                className="font-light"
              >
                NPA Record
              </Link> :
                <Link
                  to={`${process.env.PUBLIC_URL}/performance-evaluation`}
                  className="font-light"
                >
                  Performance Evaluation
                </Link>
            }
            title={dataValue?.FirstName || transaction?.fromFirstName || "Undefined"}
          />

          <div
            className="mt-2 mr-3   d-flex justify-content-end bd-highlight "
            style={{ paddingRight: "3rem", paddingBottom: "1rem" }}
          >
            <div className="mr-0">
              {overideflag &&
                <button
                //  onClick={() => handleSubmit(() => handleFormSubmit(true))}
                onClick={() => handleFormSubmit(true)}

                className="btn btn-primary me-3"
                type="submit"
              >
                Override NPA
              </button>
              }
              

            </div>
            {!employeeID && !isReadOnly ? (
              <div className="mr-0">
                <button
                  onClick={() => handleFormSubmit(false)}
                  // onClick={handleSubmit( handleFormSubmit)}
                  className="btn btn-primary"
                  type="submit"
                >
                  Post Salary Increment
                </button>
              </div>
            ) : null}

          </div>

          <Row>
            <Col sm="12">

              <Card className="py-4">
                {loading || (loadingSearch && loadingPanel())}
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "right",
                    marginBottom: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  {employeeID && HrControl 
                    // && (dataValue?.CurrentProcessStatus || transaction?.currentProcessStatus)
                      ? (
                    <>
                      <div className="d-flex gap-2">

                        {/* <Btn
                          attrBtn={{
                            className: "m-1",
                            color: "primary",
                            onClick: () => {
                              setPrintNPAForm(true);
                              setTimeout(() => {
                                handleDirectNPAPrint()
                              }, 100);
                            },
                          }}
                        >
                          Print NPA
                        </Btn> */}
                        <Autocomplete
                          size="small"
                          style={{width:"230px"}}
                          disablePortal
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="printNPA"
                          name="printNPA"
                          value={printState || ""}
                          options={[{value:"", label:""},{value:"Print NPA", label:"Print NPA"}, {value:"Print NPA Redacted", label:"Print NPA Redacted"}]}
                          getOptionLabel={(option) =>
                            option.value
                              ? option.value?.toString()
                              : ""
                          }
                          // disabled={disabledNature}
                          onChange={(e, v) => {
                            console.log("123123 onchange ::: ",v)
                            setPrintState(v);
                            setPrintNPAForm(true);
                            // handleDirectNPAPrint();
                            setTimeout(() => {
                              handleDirectNPAPrint();
                            }, 100);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Print NPA"
                              // error={
                              //   errors.natureCode ? true : false
                              // }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              // className={`flex w-full mx-10 ${checkSysGenHighlight("natureCode")
                              //   ? "isHL1"
                              //   : ""
                              //   } `}
                            />
                          )}
                        />
                        <Btn 
                          attrBtn={{
                            className: "m-1",
                            color: "primary",
                            onClick: () => {
                              setViewNPAForm(true);
                            },
                          }}
                        >
                          View NPA
                        </Btn> 
                        
                      </div>
                    </>
                  ) : null}
                  {/* Adding the new button */}

                </div>

                <Row className="mb-3 text-center">
                  <PEworkFlow
                    currentValue={dataValue?.CurrentProcessStatus || transaction?.currentProcessStatus}
                    ProcessID={100}
                    skip={"NPA Required"}
                  // ProcessListData={ProcessListData}
                  />
                </Row>

                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col className="m-3" xs={12} lg={4} md={4} sm={12}>
                    <Typography variant="h6">
                      <FontAwesomeIcon
                        className="m-r-10 font-primary"
                        icon={faFaceSmile}
                        size="lg"
                      />{" "}
                      Salary Increment Details
                    </Typography>
                  </Col>
                  <Col className="m-3" xs={12} lg={4} md={4} sm={12}>
                    <Typography>
                      HR Control No :- <span>{HrControl}</span>
                    </Typography>
                  </Col>

                </Row>

                <CardBody>
                  <Form className="needs-validation" noValidate="">
                    <Row className="g-3">
                      <Row>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            disabled={true}
                            name={"SSN"}
                            label={"SSN"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            disabled={true}
                            readOnly={true}
                            name={"EmployeeName"}
                            label={"Employee Name"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            // disabled={!isOverrideClicked}
                            // readOnly={!isOverrideClicked}
                            name={"Job_Order_No"}
                            label={"Job Order Number"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            // disabled={!isOverrideClicked}
                            // readOnly={!isOverrideClicked}
                            name={"Budget_Acct_No"}
                            label={"Budget Acc No"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="date"
                            // disabled={!isOverrideClicked}

                            // readOnly={!isOverrideClicked}

                            name={"ActionDate"}
                            label={"Action Date"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="date"
                            // disabled={!isOverrideClicked}
                            // readOnly={!isOverrideClicked}
                            name={"EffectiveDate"}
                            label={"Effective Date"}
                          />
                        </Col>
                        <Col md="3 ">
                          {/* <TextField size="small" fullWidth id="firstName" variant="outlined" label="Position" name="Position"
                                                /> */}
                          <FormInputText
                            type="text"
                            disabled={true}
                            readOnly={true}
                            name={"FROMPOSITION"}
                            label={"Position"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            disabled={true}
                            readOnly={true}
                            name={"FROMDEPARTMENT"}
                            label={"Department"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"
                            disabled={true}
                            readOnly={true}
                            name={"FROMDIVISION"}
                            label={"Division"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"

                            name={"FTENo"}
                            label={"FTE Number"}
                          />
                        </Col>
                        <Col md="3 ">
                          <FormInputText
                            type="text"

                            name={"RequestedId"}
                            label={"Request Id"}
                          />
                        </Col>
                      </Row>

                      {/* From Feilds */}
                      <Row className="mt-3">
                        <Col className="" md="6  ">
                          <CardHeader className="bg-primary">
                            <Typography className="text-center"> FROM</Typography>
                          </CardHeader>
                          {/* <Row className="mt-3">
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMLASTNAME"}
                                label={"Last Name"}
                              />
                            </Col>
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMFIRSTNAME"}
                                label={"First Name"}
                              />
                            </Col>
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMMIDDLENAME"}
                                label={"Middle Name"}
                              />
                            </Col>
                          </Row> */}

                          <Row>
                            <Col className="mt-3" md="12  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMPOSITION"}
                                label={"Postion"}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMDEPARTMENT"}
                                label={"Department"}
                              />
                            </Col>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMDIVISION"}
                                label={"Division"}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMGRADE"}
                                label={"Grade"}
                              />
                            </Col>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMSTEP"}
                                label={"Step"}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMRATE"}
                                label={"Rate"}
                              />
                            </Col>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                disabled={true}
                                readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"FROMSALARY"}
                                label={"Salary"}
                              />
                            </Col>
                          </Row>
                        </Col>

                        {/* To Fields */}
                        <Col md="6">
                          <CardHeader className="bg-primary">
                            <Typography className="text-center"> TO</Typography>
                          </CardHeader>

                          {/* <Row className="mt-3">
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"TOLASTNAME"}
                                label={"Last Name"}
                              />
                            </Col>
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                className="mt-3"
                                name={"TOFIRSTNAME"}
                                label={"First Name"}
                              />
                            </Col>
                            <Col className="" md="4  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"TOMIDDLENAME"}
                                label={"Middle Name"}
                              />
                            </Col>
                          </Row> */}
                          <Row className="mt-3">
                            <Col
                              style={{ marginTop: "-11px" }}
                              className=""
                              md="12  "
                            >
                              <AutoComplete
                                loading={loadingFindAsYouType}

                                label="Position Title"
                                textField="label"
                                valueField="value"
                                // disabled={!isOverrideClicked}
                                name="Nposition_No"
                                size="large"
                                value={
                                  optionSelectedItems.selectedPosition?.label
                                }
                                data={dataTitles}
                                style={{
                                  width: "100%",

                                  borderColor: "rgba(0, 0, 0, 0.38)",
                                }}
                                fillMode="outline"
                                onChange={(e) => {
                                  const selectedPosition = dataTitles.find(
                                    (item) => item.label === e.target.value
                                  );
                                  dispatch(
                                    findAsyouType({
                                      table: "LK_NPosition",
                                      keyField: "Title",
                                      valueField: "NPOSITION_NO",

                                      search: e.value,
                                    })
                                  );

                                  if (selectedPosition) {
                                    setOptionSelectedItems((prev) => ({
                                      ...prev,
                                      selectedPosition: selectedPosition,

                                    }));
                                  } else {

                                    setOptionSelectedItems((prev) => ({
                                      ...prev,
                                      selectedPosition: null,
                                    }));
                                  }



                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="d-flex align-items-center mt-3">
                            <Col className="" md="6  ">
                              <FormControl
                                style={{ color: "black" }}
                                // sx={{ width: 260 }}
                                fullWidth
                                size="small"
                              >
                                <InputLabel id="demo-select-small-label">
                                  Department
                                </InputLabel>
                                <Select
                                  style={{ color: "black" }}
                                  size="small"
                                  // disabled={!isOverrideClicked}
                                  value={
                                    optionSelectedItems.department?.code?.trim() || ""

                                  }
                                  name="DepartmentName"
                                  label="Department"
                                  onChange={(e) => {
                                    const selectedDept = department.find(
                                      (d) => d.Dept_Code === e.target.value
                                    );

                                    setOptionSelectedItems((prev) => ({
                                      ...prev,
                                      department: {
                                        name: selectedDept?.DepartmentName,
                                        code: selectedDept?.Dept_Code,
                                      },
                                    }));
                                    fetchDivisionByDepartment(
                                      selectedDept?.Dept_Code
                                    );
                                  }}
                                >
                                  {department.map((status, key) => (
                                    <MenuItem
                                      key={status.key}
                                      value={status.Dept_Code}
                                    >
                                      {status.DepartmentName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Col>
                            <Col className="" md="6  ">
                              <FormControl
                                style={{ color: "black" }}
                                fullWidth
                                // sx={{ width: 260 }}
                                size="small"
                              >
                                <InputLabel id="demo-select-small-label">
                                  Department Division
                                </InputLabel>
                                <Select
                                  style={{ color: "black" }}
                                  size="small"
                                  // disabled={!isOverrideClicked}
                                  value={
                                    optionSelectedItems?.division?.code?.trim() || ""

                                  }
                                  name="DivisionName"
                                  label="Department Division"
                                  onChange={(e) => {
                                    const selectedDivision = departmentDiviosion.find(
                                      (d) => d.Dept_key === e.target.value
                                    );

                                    setOptionSelectedItems((prev) => ({
                                      ...prev,
                                      division: {
                                        name: selectedDivision?.name,
                                        code: selectedDivision?.Dept_key
                                        ,
                                      },
                                    }));

                                  }}

                                >
                                  {departmentDiviosion.map((status, key) => (
                                    <MenuItem
                                      key={status.key}
                                      value={status.Dept_key}
                                    >
                                      {status.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"TOGRADE"}
                                label={"Grade"}
                              />
                            </Col>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name="TOSTEP"
                                label={"Step"}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col className="" md="6  ">
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"TORATE"}
                                label={"Rate"}
                              />
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                              }}
                              md="6  "
                            >
                              <FormInputText
                                type="text"
                                // disabled={!isOverrideClicked}
                                // readOnly={!isOverrideClicked}
                                className="mt-3"
                                name={"TOSALARY"}
                                label={"Salary"}
                              />
                              {dataValue?.OverallPerformanceRating < 3 ? <button

                                className="btn btn-sm btn-link flex-end text-end ms-5 p-0   "
                                type="button"
                                onClick={handleCHeck}
                              >
                                Calculate Salary
                              </button> : null}

                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {/* Other Details */}
                      <Row className="mt-3">
                        <Col ms="12">
                          <CardHeader className="bg-primary">
                            <Typography className="text-center">
                              {" "}
                              Other Details
                            </Typography>
                          </CardHeader>

                          <Col md="12">
                            <Row className="mt-2">



                              <Col className="" md="6  ">
                                <FormControl
                                  style={{ color: "black" }}
                                  // sx={{ width: 350 }}
                                  fullWidth
                                  size="small"
                                  className="mt-2"
                                >
                                  <InputLabel id="demo-select-small-label">
                                    Eval Type
                                  </InputLabel>
                                  <Select
                                    style={{ color: "black" }}
                                    size="small"
                                    fullWidth
                                    value={
                                      optionSelectedItems?.overAllItems?.value || ""

                                    }
                                    onChange={(e) => {
                                      const selectedDivision = OverAllPerofrmanceRating.find(
                                        (d) => d.value === e.target.value
                                      );
                                      setOptionSelectedItems((prev) => ({
                                        ...prev,
                                        overAllItems: {
                                          name: selectedDivision?.name,
                                          value: selectedDivision?.value
                                          ,
                                        },
                                      }));

                                    }}
                                    name="Eval_Type"
                                    label="eval type"

                                  >
                                    {OverAllPerofrmanceRating.map((status, key) => (
                                      <MenuItem
                                        key={status.value}
                                        value={status.value}
                                      >
                                        {status.value}
                                      </MenuItem>
                                    ))}


                                  </Select>
                                </FormControl>
                              </Col>

                              <Col className="" md="6  ">
                                <FormControl
                                  style={{ color: "black" }}
                                  // sx={{ width: 350 }}
                                  fullWidth
                                  size="small"
                                  className="mt-2"
                                >
                                  <InputLabel id="demo-select-small-label">
                                    Over all Perfomance Rating
                                  </InputLabel>
                                  <Select
                                    style={{ color: "black" }}
                                    size="small"
                                    fullWidth
                                    value={
                                      optionSelectedItems?.overAllItems?.value || ""

                                    }
                                    onChange={(e) => {
                                      const selectedDivision = OverAllPerofrmanceRating.find(
                                        (d) => d.value === e.target.value
                                      );
                                      setOptionSelectedItems((prev) => ({
                                        ...prev,
                                        overAllItems: {
                                          name: selectedDivision?.name,
                                          value: selectedDivision?.value
                                          ,
                                        },
                                      }));

                                    }}
                                    name="OverallPerformanceRating"
                                    label="OverallPerformanceRating"

                                  >
                                    {OverAllPerofrmanceRating.map((status, key) => (
                                      <MenuItem
                                        key={status.value}
                                        value={status.value}
                                      >
                                        {status.name}
                                      </MenuItem>
                                    ))}


                                  </Select>
                                </FormControl>
                              </Col>

                            </Row>
                            <Row>
                              <Col className="" md="6  ">
                                <FormInputText
                                  type="text"
                                  // disabled={true}
                                  // readOnly={!isOverrideClicked}
                                  className="mt-3"
                                  name={"PostingFlag"}
                                  label={"Posting Flag"}
                                />
                              </Col>
                              <Col className="" md="6  ">
                                {/* <TextField size="small" fullWidth id="firstName" variant="outlined" className='mt-3' label="Posting Date" name="Grade"
                                                            /> */}
                                <FormInputText
                                  type="date"
                                  // disabled={true}
                                  // readOnly={!isOverrideClicked}
                                  className="mt-3"
                                  name={"PostingDate"}
                                  label={"Posting Date"}
                                />
                              </Col>
                            </Row>

                            <Row className="mt-3">
                              <Col className="" md="6  ">
                                <FormInputText
                                  type="text"
                                  // disabled={true}
                                  // readOnly={!isOverrideClicked}
                                  className="mt-3"
                                  name={"Remarks"}
                                  multiline={true}
                                  label={"Remarks"}
                                  rows={4}
                                />
                                {/* <TextField
                                  multiline
                                  rows={5}
                                  label="Remarks"
                                  fullWidth
                                  name="remarks"
                                /> */}
                              </Col>
                              <Col className="" md="6  ">
                                {/* <TextField size="small" fullWidth id="firstName" variant="outlined" className='mt-3' label="Posting Date" name="Grade"
                                                            /> */}
                                <FormInputText
                                  type="date"
                                  disabled={true}
                                  // readOnly={!isOverrideClicked}
                                  className="mt-3"
                                  name={"NEXTINCR"}
                                  label={"Next INCR "}
                                />
                              </Col>
                            </Row>



                          </Col>
                        </Col>
                      </Row>
                    </Row>
                    {/* <button type='submit' className='btn btn-primary'>Save</button> */}
                  </Form>
                </CardBody>
              </Card>

              <Card className="p-3 my-3" elevation={1}>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <Typography variant="h6">
                      <FontAwesomeIcon
                        className="m-r-10 font-primary"
                        icon={faTentArrowLeftRight}
                        size="lg"
                      />
                      Audit Trail
                    </Typography>

                    {employeeID ? (
                      <Btn
                        attrBtn={{
                          color: "primary",
                          onClick: () => {
                            OpenHistoryComponent();
                          },
                          style: {
                            marginLeft: "6px",
                          },
                        }}
                      >
                        History
                      </Btn>
                    ) : (
                      null // or you can use an empty string: ""
                    )}

                  </div>
                  {renderProcessStatus()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Salary Increment Status
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Action Number : <span>{messgaeData.actionNo}</span>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Hr Control Number : <span>{messgaeData.hrControl}</span>
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <button className="btn btn-primary" onClick={handleModelClose}>
                  OK
                </button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={openRemarks}
            onClose={handleCloseRemarks}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className='' sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Remarks
                <FormInputText
                  type="text"
                  multiline={true}
                  size={'large'}

                  rows={4}
                  name={"STATUSREMARKS"}
                  label={"Remarks"}
                />
              </Typography>
              <Typography className="text-center" id="modal-modal-title" variant="h6" component="h2">
                <button onClick={() => handleSubmitRemarks(true)} className="btn btn-primary mt-3 text-center">Submit</button>
              </Typography>


            </Box>
          </Modal>
        </Container>
      </Fragment>
    
      {/* {printNpaForm &&  */}
      { printState && printState.value?
        <NPADirectPrintSalaryIncrement
          ref={DirectNpaRef}
          data={dataValue && Object.keys(dataValue).length > 0 ? dataValue : transaction && Object.keys(transaction).length > 0 ? transaction : {}}
          approvedData={approvedData?.length ? approvedData : []}
          showPrintForm = {true}
          printState={printState?.value}
          mySignature={mySignature}
          setMySignature={setMySignature}
        />
      :null}
      {/* }  */}
      
      <NPAVIEWPrint
        isOpen={viewNpaForm}
        data={dataValue && Object.keys(dataValue).length > 0 ? dataValue : transaction && Object.keys(transaction).length > 0 ? transaction : {}}
        handlePrint={handleDirectNPAPrint}
        handleClose={() => setViewNPAForm(false)}
        approvedData={approvedData?.length ? approvedData : []}
        from={"Salary Increment"}
        setPrintState={setPrintState}
        mySignature={mySignature}
        setMySignature={setMySignature}
      />
      <DifferencesModal
        visible={openDiffModal}
        setVisible={setOpenDiffModal}
        data={differenceData.length > 0 ? differenceData : []}
        loadingPanel={loadingPanel}
        loading={
          differenceData &&
            differenceData.length > 0 &&
            TransactionsBySSNAndAction?.actnNo
            ? false
            : true
        }
      />
      <HistoryDataTable
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
        data={auditHistory || []}
        sortData={sortData}
        selectedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
        onSortChange={handleSortChange}
        actions={actions}
        title={"History"}
      />
    </>
  );
};

export default SalaryIncrement;

