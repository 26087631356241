import React, { useRef, useState } from "react";
import CustomTextField from "../../CommonComponents/FormComponents/TextField";
import { Container, Row } from "reactstrap";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import CustomAutocomplete from "../../CommonComponents/FormComponents/Autocomplete";
import CustomButton from "../../CommonComponents/FormComponents/Button";
import {
  faAdd,
  faSave,
  faUndo,
  faArrowRotateBack,
} from "@fortawesome/pro-duotone-svg-icons";
import { Controller, useForm } from "react-hook-form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Delete } from "@material-ui/icons";
import * as Yup from "yup";
// import { Editor, EditorTools } from "@progress/kendo-react-editor";
import { useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Button as MuiButton } from "@mui/material";
import { questionCircleIcon } from "@progress/kendo-svg-icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { createNotification } from "../../app/store/Notification/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../_helper/api_helper";
// import CustomHeaderTool from "./customHeader/CustomHeaderTool ";
import { headerString } from "./header";
import { ShowError, ShowSucess } from "../../_helper/helper";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// const {
//   Bold,
//   Italic,
//   Underline,
//   Strikethrough,
//   Subscript,
//   Superscript,
//   AlignLeft,
//   AlignCenter,
//   AlignRight,
//   AlignJustify,
//   Indent,
//   Outdent,
//   OrderedList,
//   UnorderedList,
//   Undo,
//   Redo,
//   FontSize,
//   FontName,
//   FormatBlock,
//   Link,
//   Unlink,
//   InsertImage,
//   ViewHtml,
//   InsertTable,
//   AddRowBefore,
//   AddRowAfter,
//   AddColumnBefore,
//   AddColumnAfter,
//   DeleteRow,
//   DeleteColumn,
//   DeleteTable,
//   MergeCells,
//   SplitCell,
// } = EditorTools;

export const INITIAL_DATA = {
  templateName: "",
  body: "",
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  // templateName: Yup.string().required("Template Name is required"),
  body: Yup.string().required("Template Body is required"),
});

const EXAMPLE_TEMPLATE = `Hello @param1,
How are you?`;

const baseURL = process.env.REACT_APP_API_URL_PR;
const newBaseURL = process.env.REACT_APP_API_DOTNET_URL;

const NotificationEditor = ({ selectedDeliveryOption, notificationDetail }) => {
  const [paramsModal, setParamsModal] = useState(false);
  const [paramName, setParamName] = useState("");
  const [paramType, setParamType] = useState("text");
  const [templateParams, setTemplateParams] = useState([]);
  const [templateLoader, setTemplateLoader] = useState(false);
  const [content, setContent] = useState("");
  const [checked, setChecked] = useState(false);
  const [sentNotificationLoader, setSentNotificationLoader] = useState(false);
  const [selectedTemplate, setSelectedtemplate] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location?.search);
  const id = params.get("id");
  //   const { createNotificationLoader } = useSelector(
  //     (state) => state.NotificationReducer,
  //     shallowEqual
  //   );

  const { handleSubmit, control, reset, setValue, getValues } = useForm({
    defaultValues: { ...INITIAL_DATA },
  });
  const [error, setError] = useState({});

  const dateRegex =
    /\b(?:\d{1,2}\s*(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s*\d{4}|\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])|\d{1,2}\/(?:0[1-9]|1[0-2])\/\d{4})\b/;

  const createNotificationRef = useRef(false);

  const handleParamAddClick = () => {
    const isSameParamExists = templateParams.find(
      (e) => e?.name == `@${paramName}`
    );
    if (!!isSameParamExists) {
      alert("Template params already exists!");
    } else {
      setTemplateParams((prev) => [
        ...prev,
        { name: `@${paramName}`, type: paramType },
      ]);
      setParamName("");
      setParamType("");
    }
  };

  const handleRemoveTemplateParams = (selectParamName) => {
    if (
      window.confirm(`Are you sure you want to remove ${selectParamName} ?`)
    ) {
      setTemplateParams((prev) =>
        prev.filter((e) => e?.name != selectParamName)
      );
    }
  };
  const removerheaderString = (htmlString) => {
    let tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let headerTemplateElements =
      tempElement.querySelectorAll(".header-template");
    headerTemplateElements.forEach((element) => {
      element.remove();
    });
    let updatedHtmlString = tempElement.innerHTML;
    return updatedHtmlString;
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setContent(headerString + content);
    } else {
      const updatedHtmlString = removerheaderString(content);
      setContent(updatedHtmlString);
    }
  };

  const createNotificationHandler = async (payloadData) => {
    const params = templateParams.map((item) => {
      return {
        paramName: item.name,
        paramType: item.type,
      };
    });
    const payload = [
      {
        // id: id ?? 0,
        // templateName: payloadData["templateName"],
        notificationText: payloadData["body"],
        deliveryMethod: selectedDeliveryOption === "EMAIL" ? "EMAIL" : "",
        template_ID: 0,
        employee_ID: 10000001 || notificationDetail.EmployeeID,
        documentIfAny: "NA",
        remark: "",
        sourceId: notificationDetail.SourceId,
        dashBoard: selectedDeliveryOption === "DASHBOARD",
        iswhatsapp: selectedDeliveryOption === "Whatsapp_Notification",
        whatsapptext: payloadData["body"],
        // params,
      },
    ];
    createNotificationRef.current = true;
    // dispatch(createNotification(payload));
    console.log(notificationDetail, "notificationDetail1221");
    try {
      setSentNotificationLoader(true);
      const response = await post(
        `${newBaseURL}/Message/BulkNotificationwithoutAttchment`,
        payload
      );
      ShowSucess("Notification sent");
    } catch (error) {
      ShowError(error?.message ?? "Something went wrong");
    } finally {
      setSentNotificationLoader(false);
    }
  };

  const onSubmit = async (formData) => {
    formData["body"] = content;
    createNotificationHandler(formData);

    // try {
    //   await VALIDATION_SCHEMA.validate(formData, { abortEarly: false });
    //   createNotificationHandler(formData);
    // } catch (error) {
    //   console.log({ error });
    //   const validationErrors = {};
    //   error.inner.forEach((fieldError) => {
    //     validationErrors[fieldError.path] = fieldError.message;
    //   });
    //   setError(validationErrors);
    // }
  };

  const hanldeContentChange = (html) => {
    setContent(html);
  };

  const checkForParamsInBody = (htmlString) => {
    const placeholderRegex = /\[@([^\]]+)]/g;

    const extractedTexts = [];

    let match;
    while ((match = placeholderRegex.exec(htmlString)) !== null) {
      const text = match[1];
      extractedTexts.push(text);
    }
    return extractedTexts;
  };

  const createType = (argString) => {
    if (dateRegex.test(argString)) {
      return "DATE";
    } else return "TEXT";
  };

  const createTemplateParams = (argArr) => {
    return argArr.map((name) => {
      const type = createType(name);
      return {
        name,
        type,
      };
    });
  };

  const checkHeaderExistence = (htmlString) => {
    let tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    let headerTemplateElements = tempElement.querySelector(".header-template");
    if (headerTemplateElements) {
      return true;
    } else return false;
  };

  useEffect(() => {
    const getAllParams = checkForParamsInBody(content);
    const getTemplateParams = createTemplateParams(getAllParams);
    setTemplateParams([...getTemplateParams]);
    const checkboxFlag = checkHeaderExistence(content);
    if (checkboxFlag) {
      setChecked(true);
    } else setChecked(false);
  }, [content]);

  const getTemplateById = async (id) => {
    try {
      setTemplateLoader(true);
      const response = await get(`${baseURL}Notifications/${id}`);
      const { templateName, templateBody } = response;
      setValue("templateName", templateName);
      setContent(templateBody);
    } catch (error) {
      console.error("Error fetching template data:", error);
    } finally {
      setTemplateLoader(false);
    }
  };

  const handleReset = () => {
    setValue("templateName", "");
    setContent("");
    setChecked(false);
    reset();
  };

  //   useEffect(() => {
  //     if (createNotificationRef.current && !createNotificationLoader) {
  //       handleReset();
  //       createNotificationRef.current = false;
  //     }
  //   }, [createNotificationLoader]);

  useEffect(() => {
    if (id) {
      getTemplateById(id);
    }
  }, [id]);

  const getAllNotificationCall = async () => {
    const response = await get(`${baseURL}Notifications/GetNotifications`);
    if (response.length) {
      setTemplateList(
        response.map((item) => ({
          label: item.templateName,
          value: JSON.stringify(item),
        }))
      );
    }
    console.log(response, "GetNotifications");
  };

  useEffect(() => {
    getAllNotificationCall();
  }, []);

  useEffect(() => {
    if (notificationDetail.DefaultNotificationText) {
      setContent(notificationDetail.DefaultNotificationText);
    }
  }, [notificationDetail]);

  const loaderStyle = {
    border: "2px solid #f3f3f3",
    borderTop: "2px solid #3498db",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const convertNulltoZero = (obj) => {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = 0;
      }
    }
    return obj;
  };

  const evaluateAndReplaceExpressions = (htmlString) => {
    let expressionRegex = /{([^}]+)}/g;

    let expressions = htmlString.match(expressionRegex);

    if (!expressions) {
      return htmlString;
    }

    let updatedHtmlString = htmlString.replace(
      expressionRegex,
      function (match, expression) {
        return eval(expression).toFixed(2);
      }
    );
    return updatedHtmlString;
  };

  const replaceParams = (htmlString, toReplaceObj) => {
    let tempReplcaeObj = convertNulltoZero({ ...toReplaceObj });
    let tempContent = htmlString.toString();
    for (const singleParam in tempReplcaeObj) {
      const escapedKey = singleParam.replace(
        /([.*+?^=!:${}()|\[\]\/\\])/g,
        "\\$1"
      );
      const regex = new RegExp(`\\[@${escapedKey}\\]`, "g");
      tempContent = tempContent.replace(
        regex,
        tempReplcaeObj[singleParam].toString()
      );
    }
    tempContent = evaluateAndReplaceExpressions(tempContent);
    return tempContent;
  };

  const updateEditorContent = (currentTemplate) => {
    console.log(JSON.parse(currentTemplate), "currentTemplate");
    const data = JSON.parse(currentTemplate);
    const updatedContent = replaceParams(data.templateBody, notificationDetail);
    setContent(updatedContent);
  };

  const handleSelectChange = (event) => {
    setSelectedtemplate(event.target.value);
    updateEditorContent(event.target.value);
  };

  // Define formats and modules for the Quill editor
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
  ];

  return (
    <div>
      {templateLoader && <Skeleton height={100} width="80%" />}
      {!templateLoader && (
        <Container fluid={true}>
          {/* <Row className="bg-primary rounded py-3 mb-3">
            <Typography variant="h5">
              <i class="fa-duotone fa-brain m-r-10"></i>
              Template Builder
            </Typography>
          </Row> */}
          <br />
          <Grid container xs={12} spacing={2} alignItems="center">
            <Grid item>
              <InputLabel id="select-label">Select Template</InputLabel>
            </Grid>
            <Grid item>
              <Select
                labelId="select-label"
                id="select"
                value={selectedTemplate}
                onChange={handleSelectChange}
                style={{ minWidth: 120 }}
                size="small"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {templateList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={12} spacing={1}>
            <Grid
              item
              xs={12}
              sm={12}
              display={"flex"}
              justifyContent={"right"}
              gap={2}
            >
              {location?.state?.addTemplate && (
                <CustomButton
                  text={"Back"}
                  icon={faArrowRotateBack}
                  onClick={() => navigate("/template-builder/send")}
                  disabled={sentNotificationLoader}
                />
              )}
              <CustomButton
                text={"Reset"}
                icon={faUndo}
                disabled={sentNotificationLoader}
                onClick={handleReset}
              />
              <CustomButton
                text={
                  <div style={{ display: "flex", gap: "4px" }}>
                    {sentNotificationLoader && (
                      <div>
                        <style>{keyframesStyle}</style>
                        <div style={loaderStyle}></div>
                      </div>
                    )}
                    <b>Send</b>
                  </div>
                }
                icon={!sentNotificationLoader ? faSave : ""}
                disabled={sentNotificationLoader}
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={8}>
              <Controller
                name={"templateName"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomTextField
                      name={"templateName"}
                      label={"Template Name"}
                      onChange={onChange}
                      value={value}
                      errorMessage={error["templateName"]}
                    />
                  );
                }}
              />
            </Grid> */}
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={1}
            marginTop={1}
            marginBottom={10}
          >
            <Grid item xs={12} sm={12} md={12}>
              {/* <Typography variant="body1" className="fw-bold mb-1">
                Template Body
              </Typography> */}
              {error["body"] !== "" && (
                <span style={{ color: "red" }}>{error["body"]}</span>
              )}
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Add Header"
              />
              {/* <Editor
                tools={[
                  [Bold, Italic, Underline, Strikethrough],
                  [Subscript, Superscript],
                  [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                  [Indent, Outdent],
                  [OrderedList, UnorderedList],
                  FontSize,
                  FontName,
                  FormatBlock,
                  [Undo, Redo],
                  [Link, Unlink, InsertImage, ViewHtml],
                  [InsertTable],
                  [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                  [DeleteRow, DeleteColumn, DeleteTable],
                  [MergeCells, SplitCell],
                ]}
                contentStyle={{ height: 320 }}
                onChange={hanldeContentChange}
                value={content}
              /> */}
              {/* <ReactQuill theme="snow" value={content} onChange={hanldeContentChange} /> */}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  height: "500px",
                  maxHeight: "700px",
                  overflowY: "auto",
                }}
              >
                <ReactQuill
                  theme="snow"
                  value={content}
                  onChange={hanldeContentChange}
                  modules={modules}
                  formats={formats}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    minHeight: "500px",
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4}>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <Typography
                  variant="body1"
                  className="fw-bold mb-1"
                  style={{ margin: "-2px 0" }}
                >
                  Template Parameter's
                </Typography>

                <Button
                  svgIcon={questionCircleIcon}
                  color="primary"
                  themeColor={"primary"}
                  onClick={() => setParamsModal((prev) => !prev)}
                />
              </div>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                spacing={1}
                alignItems={"center"}
              ></Grid>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                spacing={1}
                marginLeft={0.5}
                marginTop={1}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!templateParams.length ? (
                        templateParams.map((row) => (
                          <TableRow key={row?.name}>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell>{row?.type}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>No Parameter's</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid> */}
          </Grid>
        </Container>
      )}

      {/* <Modal
        open={paramsModal}
        onClose={() => setParamsModal((prev) => !prev)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Do It Like This!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            To add params, write in the editor in this{" "}
            <Box
              component="span"
              sx={{ fontWeight: "bold" }}
            >{`[@textOrDate]`}</Box>{" "}
            format. Where 'textorDate' will be replaced by your param name.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <MuiButton
              onClick={() => setParamsModal((prev) => !prev)}
              sx={{ mt: 2 }}
            >
              Close
            </MuiButton>
          </Box>
        </Box>
      </Modal> */}
    </div>
  );
};

export default NotificationEditor;
