import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  InputAdornment,
  Box,
} from "@mui/material";
import CustomTextField from "../../FormComponents/TextField";
import moment from "moment";
import DatePicker from "../../FormComponents/DatePicker";
import CustomButton from "../../FormComponents/Button";
import { faCancel, faSave } from "@fortawesome/pro-duotone-svg-icons";
import * as yup from "yup";

const parameters = [
  {
    id: 12,
    templateID: 11,
    paramName: "User's Name",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 13,
    templateID: 11,
    paramName: "Your Platform",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 14,
    templateID: 11,
    paramName: "User's Username",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 15,
    templateID: 11,
    paramName: "User's Email",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 16,
    templateID: 11,
    paramName: "Activation Link",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 17,
    templateID: 11,
    paramName: "Activation URL",
    paramType: "TEXT",
    template: null,
  },
  {
    id: 18,
    templateID: 11,
    paramName: "Company Name",
    paramType: "TEXT",
    template: null,
  },
];

const ParamsForm = ({
  parameters,
  templateStringChangeHandler,
  onCancel,
  onSave,
  addParams,
}) => {
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        parameters.reduce((accumulator, param) => {
          const updatedParamName = param.paramName
            .replace(/'/g, "")
            .replace(" ", "_");
          accumulator[updatedParamName] = yup
            .string()
            .required(`This field is required for ${param.paramName}`);
          return accumulator;
        }, {})
      ),
    [parameters]
  );

  const customInputFields = {
    TEXT: CustomTextField,
    DATE: DatePicker,
  };

  const orignalKeys = useMemo(
    () => parameters.map((param) => param.paramName),
    [parameters]
  );

  const getOrignalKey = (replacedKey) => {
    for (const item of orignalKeys) {
      if (item.replace(/'/g, "").replace(" ", "_") === replacedKey) {
        return item;
      }
    }
  };

  const changeHandler = (evt) => {
    const newObj = {};
    const getAllValues = { ...getValues() };
    for (const item in getAllValues) {
      let checkKeyValue = getAllValues[item];
      if (checkKeyValue === undefined || checkKeyValue === "") continue;
      const newKey = getOrignalKey(item);
      newObj[newKey] = getAllValues[item];
    }
    templateStringChangeHandler(newObj);
  };

  const getCustomComponent = (param, onChange, value) => {
    const SelectedComp = customInputFields[param.paramType];
    const updatedParamName = param.paramName
      .replace(/'/g, "")
      .replace(" ", "_");

    return (
      <SelectedComp
        name={updatedParamName}
        label={param.paramType === "TEXT" ? `Enter ${param.paramName}` : ""}
        onChange={(evt) => {
          onChange(evt);
          changeHandler(evt);
        }}
        value={value}
        errorMessage={errors[updatedParamName]?.message}
        size="small"
      />
    );
  };

  const handleFormSubmit = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      onSave(data);
    } catch (validationErrors) {
      validationErrors.inner.forEach((error) => {
        setError(error.path, { type: "manual", message: error.message });
      });
    }
  };

  const formatSelectedDate = (date) => {
    return date ? moment(date).format("MM/DD/YYYY") : "";
  };

  const resetHandler = (evt) => {
    reset(evt);
    changeHandler();
  };

  useEffect(() => {
    if (addParams) {
      const availableParams = Object.keys({ ...getValues() });
      for (let singleParam in addParams) {
        if (availableParams.includes(singleParam)) {
          const val =
            addParams[singleParam] == null ? 0 : addParams[singleParam];
          setValue(singleParam, val);
        }
      }
    }
  }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              ParamName(ParamType)
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
              Enter Values
            </Typography>
          </Grid>
        </Grid>
        {parameters.map((param) => (
          <Grid
            container
            spacing={2}
            key={param.id}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="body1">{`@${param.paramName} (${param.paramType})`}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={param.paramName.replace(/'/g, "").replace(" ", "_")}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return getCustomComponent(param, onChange, value);
                }}
              />
            </Grid>
          </Grid>
        ))}
        {onSave && (
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <CustomButton text={"Cancel"} icon={faCancel} onClick={onCancel} />
            <CustomButton
              text={"Reset"}
              icon={faSave}
              onClick={(evt) => resetHandler(evt)}
              type="reset"
            />
            <CustomButton text={"Save"} icon={faSave} type="submit" />
          </div>
        )}
      </form>
    </Container>
  );
};

export default ParamsForm;
