export const GET_DIVISION = "GET_DIVISION";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const GET_DIVISION_FAIL = "GET_DIVISION_FAIL";

export const GET_DIVISION_DETAILS = "GET_DIVISION_DETAILS";
export const GET_DIVISION_DETAILS_SUCCESS = "GET_DIVISION_DETAILS_SUCCESS";
export const GET_DIVISION_DETAILS_FAIL = "GET_DIVISION_DETAILS_FAIL";

export const DIVISION_UPDATE = "DIVISION_UPDATE";
export const DIVISION_UPDATE_SUCCESS = "DIVISION_UPDATE_SUCCESS";
export const DIVISION_UPDATE_FAIL = "DIVISION_UPDATE_FAIL";

export const DIVISION_CREATE = "DIVISION_CREATE";
export const DIVISION_CREATE_SUCCESS = "DIVISION_CREATE_SUCCESS";
export const DIVISION_CREATE_FAIL = "DIVISION_CREATE_FAIL";

export const DIVISION_DELETE = "DIVISION_DELETE";
export const DIVISION_DELETE_SUCCESS = "DIVISION_DELETE_SUCCESS";
export const DIVISION_DELETE_FAIL = "DIVISION_DELETE_FAIL";

export const RESET = "RESET";
