const visibleColumns = [
  {
    title: "Department Name",
    field: "DepartmentName",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Employee Name",
    field: "Name2",
    locked: true,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Date of Birth",
    field: "Ndate_Of_Birth",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "SSN Last 4",
    field: "Last_SSN",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Position Title",
    field: "TITLE",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Employment Date",
    field: "Date_Employment",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "Annual Salary",
    field: "Annual_Salary",
    locked: false,
    filter: "numeric",
    width: 180,
    show: true,
    editor: "numeric",
    className: "text-end",
    headerClassName: "number-align",
  },
];

export default visibleColumns;
