import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { Typography } from "@mui/material";
import visileColumns from "./visileColumns";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../../ErrorBoundry";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable";
import { useNavigate } from "react-router-dom";
import { faEye, faList } from "@fortawesome/pro-duotone-svg-icons";
import { filterAllowedColumn } from "../../../_helper/helper";
import { useAuth } from "oidc-react";

import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Icon } from "@progress/kendo-react-common";
import useSortedColumns from "../../../utils/useSortedColumns";

function StaffingPattern({ report }) {
  const {
    columns,
    loadingSearch,
    searchResults,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const navigate = useNavigate();
  const { userData } = useAuth();
  // const [expandedSearchResult, setexpandedSearchResult] = useState(false);

  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [searchParams, setSearchParams] = useState([]);

  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([
    // {field:"Step_Actual",dir:"DESC"}
  ]);
  const [pageSize, setPageSize] = useState(100);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const id = "StaffingPattern";
  const history = useNavigate();

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);
  useEffect(() => {
    // console.log(searchResults);
  }, [searchResults]);
  const [sortedColumns,setSortedColumns] = useSortedColumns(
    id,
    ColumnsSorted,
    columns,
    visileColumns,
    userData
  );

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    // setPage(1);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"VW_STAFFING_PATTERN"}
                    searchParams={searchParams}
                    additionalWhere={additionalWhere}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                  />

                  <Accordion expanded={expandedSearchResult}>
                    <AccordionDetails>
                      {/* {loadingSearch && loadingPanel} */}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            key={report}
                            id={id}
                            pageSize={pageSize}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            sortData={sortData}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            // actions={actions}
                            searchParams={searchParams}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                          ></TelerikDataTable>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
}
export default StaffingPattern;
