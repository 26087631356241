export const EXIT_SURVEY_CREATE = "EXIT_SURVEY_CREATE";
export const EXIT_SURVEY_CREATE_SUCCESS = "EXIT_SURVEY_CREATE_SUCCESS";
export const EXIT_SURVEY_CREATE_FAIL = "EXIT_SURVEY_CREATE_FAIL";

export const CREATE_FORM_TOKEN_EXIT_SURVEY = "CREATE_FORM_TOKEN_EXIT_SURVEY";
export const CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS = "CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS";
export const CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL = "CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL";

export const GET_EXIT_SURVEY_ANSWER = "GET_EXIT_SURVEY_ANSWER";
export const GET_EXIT_SURVEY_ANSWER_SUCCESS = "GET_EXIT_SURVEY_ANSWER_SUCCESS";
export const GET_EXIT_SURVEY_ANSWER_FAIL = "GET_EXIT_SURVEY_ANSWER_FAIL";

export const GET_EXIT_SURVEY_MAIN_REASON = "GET_EXIT_SURVEY_MAIN_REASON";
export const GET_EXIT_SURVEY_MAIN_REASON_SUCCESS =
  "GET_EXIT_SURVEY_MAIN_REASON_SUCCESS";
export const GET_EXIT_SURVEY_MAIN_REASON_FAIL =
  "GET_EXIT_SURVEY_MAIN_REASON_FAIL";

export const GET_EXIT_SURVEY_MARTIAL_STATUS = "GET_EXIT_SURVEY_MARTIAL_STATUS";
export const GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS =
  "GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS";
export const GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL =
  "GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL";

export const GET_EXIT_SURVEY_GENDER = "GET_EXIT_SURVEY_GENDER";
export const GET_EXIT_SURVEY_GENDER_SUCCESS = "GET_EXIT_SURVEY_GENDER_SUCCESS";
export const GET_EXIT_SURVEY_GENDER_FAIL = "GET_EXIT_SURVEY_GENDER_FAIL";

export const GET_EXIT_SURVEY_AGE = "GET_EXIT_SURVEY_AGE";
export const GET_EXIT_SURVEY_AGE_SUCCESS = "GET_EXIT_SURVEY_AGE_SUCCESS";
export const GET_EXIT_SURVEY_AGE_FAIL = "GET_EXIT_SURVEY_AGE_FAIL";

export const GET_EXIT_SURVEY_DEPARTMENTS = "GET_EXIT_SURVEY_DEPARTMENTS";
export const GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS = "GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS";
export const GET_EXIT_SURVEY_DEPARTMENTS_FAIL = "GET_EXIT_SURVEY_DEPARTMENTS_FAIL";

export const GET_EXIT_SURVEY_POSITIONS = "GET_EXIT_SURVEY_POSITIONS";
export const GET_EXIT_SURVEY_POSITIONS_SUCCESS = "GET_EXIT_SURVEY_POSITIONS_SUCCESS";
export const GET_EXIT_SURVEY_POSITIONS_FAIL = "GET_EXIT_SURVEY_POSITIONS_FAIL";

export const GET_EXIT_SURVEY_DATA = "GET_EXIT_SURVEY_DATA";
export const GET_EXIT_SURVEY_DATA_SUCCESS = "GET_EXIT_SURVEY_DATA_SUCCESS";
export const GET_EXIT_SURVEY_DATA_FAIL = "GET_EXIT_SURVEY_DATA_FAIL";

export const GET_EXIT_SURVEY_TOKEN_DATA = "GET_EXIT_SURVEY_TOKEN_DATA";
export const GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS = "GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS";
export const GET_EXIT_SURVEY_TOKEN_DATA_FAIL = "GET_EXIT_SURVEY_TOKEN_DATA_FAIL";

export const RESET = "RESET";
