import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routes } from "../Route/Routes";
import * as Yup from "yup";
import { useAuth } from "oidc-react";

export const d2Time = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("HH:mm");
};
export const dateFormat = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
};

export const SELECTED_FORM_TIME = "09:00";

export const HourFormat = (hr) => {
  let size = 2;
  let s = "000000000" + hr;
  return s.substr(s.length - size) + ":00";
};
export const ShowSucess = (message) => {
  if (message === undefined) {
    return;
  }
  toast(`${message}`, {
    theme: "light",
    position: toast.POSITION.TOP_RIGHT,
  });
};
export const ShowError = (message) => {
  if (message === undefined) {
    return;
  }
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      width: "auto",
      height: "auto",
    },
  });
};

export const ShowWarning = (message) => {
  if (message === undefined) {
    return;
  }
  toast.warn(`${message}`, {
    position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // theme: "light",
  });
};

export const WeekColor = (startDate, EndDate, currentDate, paycode = "") => {
  let result = {};
  const week1StartDate = startDate;
  const week1EndDate = moment(startDate, "YYYY-MM-DD")
    .add(6, "d")
    .format("YYYY-MM-DD");
  const week2StartDate = moment(startDate, "YYYY-MM-DD")
    .add(7, "d")
    .format("YYYY-MM-DD");
  const week2EndDate = EndDate;
  if (["O/T1.5"].includes(paycode)) {
    return "#b1d4ff6b";
  }

  if (currentDate >= week1StartDate && currentDate <= week1EndDate) {
    return "#f6e5e5";
  } else if (currentDate >= week2StartDate && currentDate <= week2EndDate) {
    return "#d9ecd9";
  } else {
    return "#f6e5e5";
  }
};
export const HourMinFormat = (hrMin) => {
  let sec = hrMin.split(":");
  let size = 2;
  let s = "000000000" + sec[0];
  let s2 = "000000000" + sec[1];
  let hr = s.substr(s.length - size);
  let min = s2.substr(s2.length - size);
  return hr + ":" + min;
};

export const KeyGen = (length = 7) => {
  var result = "";
  var characters = `ABCDEFGHIJKLMNOPQRSTUV${moment(
    "YYMMDDHHmmss"
  )}WXYZabcdefghijklmnopqrstuvwxyz0123456789`;
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const KeyGenV2 = (length = 7) => {
  var result = "";
  var characters = `13121424432523532513121424432523532543232421312142443252353252334131214244325235325343242`;
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const RemoveSpaceKeyGen = (text) => {
  text = text.replace(" ", "");
  text = text.replace("/", "");
  text = text.replace(/[-]/g, "");
  text = text.replace(/[^\w-]+/g, "");
  return text;
};

export const timeSlotCheckInBeetween = (
  selectedDateFrom,
  elementFrom,
  elementTo
) => {
  const formate = "YYYY-MM-DD HH:mm";
  try {
    if (
      selectedDateFrom.length >= 16 &&
      elementFrom.length >= 16 &&
      elementTo.length >= 16
    ) {
      //from & to is proper date
      let startDate = moment(elementFrom, formate);

      // 12-07-2022 21:00
      let endDate = moment(elementTo, formate);
      let testDate = moment(selectedDateFrom, formate);

      let resultFrom = testDate.isBetween(startDate, endDate, "minute", false); // will return true
      if (resultFrom) {
        // _firstTimeSlotError = true;
        console.log(
          "SELECTED " +
            selectedDateFrom +
            " is between " +
            elementFrom +
            ", End : " +
            elementTo
        );
        return true;
      }
    }
    return false;
  } catch (err) {
    console.log("error SELECT " + err);
    return false;
  }
};

export const checkAddedTimeError1 = (listData, currentData1, callbackError) => {
  console.log("HEllo");
  let hasError = false;

  if (listData && listData.length > 0) {
    listData.forEach((currentData) => {
      let innerError = checkAddedTimeInner(listData, currentData);
      if (innerError) {
        hasError = true;
      }
    });
    callbackError(hasError);

    // const errorList = listData.filter((data) => {
    //   return data["ole1"] || data["ole2"];
    // });

    // console.log("errorList length" + errorList ? errorList.length : "0");

    return listData;
  }
};

export const checkAddedTimeInner = (listData, currentData) => {
  if (listData && listData.length > 0 && currentData) {
    let sf = currentData.date + " " + currentData.time_from;
    let st = currentData.date + " " + currentData.time_to;

    let sf1 = currentData.date + " " + currentData.time_from1;
    let st1 = currentData.date + " " + currentData.time_to1;

    let currentError1 = false;
    let currentError2 = false;

    listData.forEach((element) => {
      console.log("SELECTED ID " + element.id);

      //Check selected time slot's Form time is between any other row's range.

      if (
        element.jobtype === "REG" &&
        currentData.jobtype === "REG" &&
        element.date === currentData.date &&
        ((element.time_from && element.time_to) ||
          (element.time_from1 && element.time_to1))
      ) {
        //First time slot propery entered.
        let ef = element.date + " " + element.time_from;
        let et = `${element.date} ${element.time_to}`;

        let ef1 = element.date + " " + element.time_from1;
        let et1 = element.date + " " + element.time_to1;

        let elementError1 = false;
        let elementError2 = false;

        if (currentData.id !== element.id) {
          if (
            (sf.length > 11 && ef.length > 11 && sf === ef) ||
            (st.length > 11 && et.length > 11 && st === et) ||
            timeSlotCheckInBeetween(sf, ef, et) ||
            timeSlotCheckInBeetween(st, ef, et) ||
            timeSlotCheckInBeetween(ef, sf, st) ||
            timeSlotCheckInBeetween(et, sf, st)
          ) {
            elementError1 = true;
            currentError1 = true;
          }

          if (
            (sf.length > 11 && ef1.length > 11 && sf === ef1) ||
            (st.length > 11 && et1.length > 11 && st === et1) ||
            timeSlotCheckInBeetween(sf, ef1, et1) ||
            timeSlotCheckInBeetween(st, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf, st) ||
            timeSlotCheckInBeetween(et1, sf, st)
          ) {
            elementError2 = true;
            currentError1 = true;
          }

          if (
            (sf1.length > 11 && ef.length > 11 && sf1 === ef) ||
            (st1.length > 11 && et.length > 11 && st1 === et) ||
            timeSlotCheckInBeetween(sf1, ef, et) ||
            timeSlotCheckInBeetween(st1, ef, et) ||
            timeSlotCheckInBeetween(ef, sf1, st1) ||
            timeSlotCheckInBeetween(et, sf1, st1)
          ) {
            elementError1 = true;
            currentError2 = true;
          }
          if (
            (sf1.length > 11 && ef1.length > 11 && sf1 === ef1) ||
            (st1.length > 11 && st1.length > 11 && st1 === et1) ||
            timeSlotCheckInBeetween(sf1, ef1, et1) ||
            timeSlotCheckInBeetween(st1, ef1, et1) ||
            timeSlotCheckInBeetween(ef1, sf1, st1) ||
            timeSlotCheckInBeetween(et1, sf1, st1)
          ) {
            elementError2 = true;
            currentError2 = true;
          }
          element["ole1"] = elementError1;
          element["ole2"] = elementError2;
          currentData["ole2"] = currentError2;
        } else {
          console.log("CHECK IN OWN TIME SLOT ");
        }
      }
    });

    if (currentData.jobtype === "REG") {
      if (
        (sf.length > 11 && sf1.length > 11 && sf === sf1) ||
        (st.length > 11 && st1.length > 11 && st === st1) ||
        timeSlotCheckInBeetween(sf, sf1, st1) ||
        timeSlotCheckInBeetween(st, sf1, st1) ||
        timeSlotCheckInBeetween(sf1, sf, st) ||
        timeSlotCheckInBeetween(st1, sf, st)
      ) {
        currentError1 = true;
        currentError2 = true;
      }
    }

    currentData["ole1"] = currentError1;
    currentData["ole2"] = currentError2;

    console.log("HEllo", listData.length, "AAA##", JSON.stringify(currentData));

    if (currentError1 || currentError2) {
      return true;
    }
    return false;

    // return listData;
  }
};
export const formatMoney = (number) => {
  number = parseFloat(number);
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const format2Decimal = (number) => {
  number = parseFloat(number);
  var rounded = Math.round((number + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const getUserRole = (userData, check = false) => {
  if(!check && sessionStorage.getItem('currentRole')){
    return sessionStorage.getItem('currentRole')
  }
  if (!userData) {
    console.warn(
      "getUserRole : pass value from   const { userData } = useAuth()  "
    );
  }
  if (userData && userData.profile && userData.profile.role) {
    return userData.profile.role;
  } else {
    return "";
  }
};

export const getUserId = (userData) => {
  if (!userData) {
    console.warn(
      "getUserRole : pass value from   const { userData } = useAuth()"
    );
  }
  if (userData && userData.profile && userData.profile.EmployeeID) {
    return userData.profile.EmployeeID;
  } else {
    return 0; // for test admin dashboard
  }
};
export const isAllowedURL = (patternURL, userData) => {
  const role = getUserRole(userData);
  const route = routes.find((d) => d.path.indexOf(patternURL) > -1);
  return (
    route && (route?.roles?.indexOf(role) > -1 || route?.roles === undefined)
  ); // !!matchPath(checkUrl,patternURL)
};
export const filterAllowedColumn = (
  userData,
  columns = [],
  recordidfield = "id"
) => {
  const role = getUserRole(userData);
  const employeeColumn = [
    "ssn",
    "firstname",
    "lastname",
    "id",
    "employeeid",
    "employeename",
  ];

  if (role === "user" || role === undefined) {
    return columns.filter((d) => {
      const fieldName = d.field.toLowerCase();

      if (fieldName === "employeename_lfm") {
        return false; 
      }

      return employeeColumn.indexOf(fieldName) < 0 || fieldName === recordidfield.toLowerCase();
    });
  } else {
    return columns;
  }
};
// export const filterAllowedColumn = (
//   userData,
//   columns = [],
//   recordidfield = "id"
// ) => {
//   const role = getUserRole(userData);
//   const employeeColumn = [
//     "ssn",
//     "firstname",
//     "lastname",
//     "id",
//     "employeeid",
//     "employeename",
//   ];
//   // debugger;
//   if (role === "user" || role === undefined) {
//     return columns.filter(
//       (d) =>
//         employeeColumn.indexOf(d.field.toLowerCase()) < 0 ||
//         d.field.toLowerCase() === recordidfield.toLowerCase()
//     );
//   } else {
//     return columns;
//   }
// };

export const timeToISODate = (time) => {
  const currentDate = new Date();
  const [hours, minutes, seconds] = (time || "").split(":").map(Number);
  currentDate.setHours(hours, minutes, seconds);
  return currentDate.toISOString();
};

export const getHMFromTime = (time_from1, time_to1) => {
  const startTime1 = moment(time_from1, "HH:mm");
  const endTime1 = moment(time_to1, "HH:mm");
  let duration1 = moment.duration(endTime1.diff(startTime1));
  if (duration1.asHours() < 0) {
    const endTime3 = moment(time_to1, "HH:mm").add(1, "day");
    duration1 = moment.duration(endTime3.diff(startTime1));
  }

  const hours1 = duration1._isValid ? parseInt(duration1.asHours()) : 0;
  const totalMin1 = duration1._isValid ? parseInt(duration1.asMinutes()) : 0;

  let hrCalc = HourMinFormat(`${Math.floor(totalMin1 / 60)}:${totalMin1 % 60}`);

  return {
    min: totalMin1,
    hr: hours1,
    hhmm: hrCalc,
  };
};

/** validationObject example */
/** {
  email: [
      { type: 'required', rules: ['Email is required'] },
      { type: 'min', rules: [3, 'Minimum length is 3 characters'], dataType: 'string' },
      { type: 'min', rules: [11, 'Number must be greater than 10'], dataType: 'number' },
      { type: 'email', rules: ['Invalid email address'] }
  ],
};  */
export const generateYupValidationSchema = (validationObject) => {
  const schema = {};

  for (const key in validationObject) {
    let validator;
    validationObject[key].forEach((validation) => {
      const { rules, type, dataType } = validation;
      validator = Yup[dataType || "string"]();
      validator = validator[type](...rules);
    });
    schema[key] = validator;
  }

  return Yup.object().shape(schema);
};

export const sortData = (data, sortBy, orderBy = null) => {
  if (data.length) {
    let sortedData = [...data].sort((a, b) => {
      let currentData = a[sortBy] ? a[sortBy] : "";
      let nextData = b[sortBy] ? b[sortBy] : "";
      // Use localeCompare to perform a case-insensitive alphabetical sort
      if (orderBy === "desc") {
        return nextData.localeCompare(currentData, undefined, {
          sensitivity: "base",
        });
      } else {
        return currentData.localeCompare(nextData, undefined, {
          sensitivity: "base",
        });
      }
    });

    return sortedData;
  } else {
    return [];
  }
};

export const formatDateMMDDYYYY = (date = new Date()) => {
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const year = String(date.getFullYear());

  return `${month}${day}${year}`;
}

export function orderByVendorCode(companyArray) {
  // Sorting function
  function compare(a, b) {
    const codeA = a.vendor_items[0].vendor_code.toUpperCase();
    const codeB = b.vendor_items[0].vendor_code.toUpperCase();

    if (codeA < codeB) {
      return -1;
    }
    if (codeA > codeB) {
      return 1;
    }
    // If vendor_code is the same, maintain the original order
    return 0;
  }

  // Sort the companyArray
  const sortedArray = companyArray.slice().sort(compare);
  return sortedArray;
}