import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findOutGg1Employee } from "../../store/employees/actions";
import { useAuth } from "oidc-react";
import { useParams } from "react-router-dom";
import GG1EmployeeInformation from "./component/GG1EmployeeInformation";
import Gg1MasterHeader from "./component/Gg1Header";

const GG1MasterDetails = () => {
  const { userData } = useAuth();
  const { createObject, updateObject } = useSelector(
    (state) => state?.employeeReducer
  );
  const { employeeID } = useParams();
  const dispatch = useDispatch();
  console.log("userData", userData?.profile);
  useEffect(() => {
    if (employeeID || userData?.profile?.EmployeeID) {
      dispatch(
        findOutGg1Employee(Number(employeeID || userData?.profile?.EmployeeID))
      );
    }
  }, [employeeID, userData]);

  useEffect(() => {
    if (
      (createObject !== null && Object.keys(createObject).length > 0) ||
      (updateObject !== null && Object.keys(updateObject).length > 0)
    ) {
      dispatch(
        findOutGg1Employee(Number(employeeID || userData?.profile?.EmployeeID))
      );
    }
  }, [createObject, updateObject]);

  return (
    <>
      <Gg1MasterHeader />
      <GG1EmployeeInformation userData={userData} />
    </>
  );
};
export default GG1MasterDetails;
