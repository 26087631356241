import {
  GET_POSITIONCLASS,
  GET_POSITIONCLASS_SUCCESS,
  GET_POSITIONCLASS_FAIL,
  GET_POSITIONCLASS_DETAILS,
  GET_POSITIONCLASS_DETAILS_SUCCESS,
  GET_POSITIONCLASS_DETAILS_FAIL,
  POSITIONCLAS_CREATE,
  POSITIONCLAS_CREATE_FAIL,
  POSITIONCLAS_CREATE_SUCCESS,
  POSITIONCLAS_DELETE,
  POSITIONCLAS_DELETE_FAIL,
  POSITIONCLAS_DELETE_SUCCESS,
  POSITIONCLAS_UPDATE,
  POSITIONCLAS_UPDATE_FAIL,
  POSITIONCLAS_UPDATE_SUCCESS,
  RESET,
} from "./actionTypes";

const initialState = {
  positionclasList: [],
  positionclas: {},
  postingResult: {},
  loading: false,
  positionclasSetting: {},
  error: {
    message: "",
  },
};

const PositionClassReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_POSITIONCLASS:
      state = { ...state, loading: true };
      break;
    case GET_POSITIONCLASS_SUCCESS:
      state = { ...state, positionclasList: action.payload, loading: false };
      break;
    case GET_POSITIONCLASS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_POSITIONCLASS_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_POSITIONCLASS_DETAILS_SUCCESS:
      state = { ...state, positionclas: action.payload, loading: false };
      break;
    case GET_POSITIONCLASS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case POSITIONCLAS_CREATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case POSITIONCLAS_CREATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case POSITIONCLAS_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        loading: false,
      };
      break;
    case POSITIONCLAS_UPDATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case POSITIONCLAS_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case POSITIONCLAS_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case POSITIONCLAS_DELETE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case POSITIONCLAS_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case POSITIONCLAS_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case RESET:
      state = { ...state, positionclas: {} };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PositionClassReducer;
