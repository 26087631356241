import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useAuth } from "oidc-react";

import visileColumns from "./visibleColumns";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../ErrorBoundry";
import TelerikDataTable from "../../CommonComponents/TelerikDataTable";
import { useNavigate } from "react-router-dom";
import HistoryDataTable from "../../CommonComponents/HistoryDataTable";
import HistoryColumns from "./HistoryColumns";
import { auditTrail, verifyEmployee } from "../../store/employees/actions";
import { ShowError } from "../../_helper/helper";
import { loadingPanel } from "../../CommonComponents/CommonFunction";

import {
  faCalendar,
  faDisplayChartUp,
  faExternalLink,
  faEye,
  faList,
  faPlusCircle,
  faQrcode,
  faUsers,
  faBookOpen,
  faHousePersonLeave,
  faPoll,
  faPollH,
  faSquarePollHorizontal,
  faPollPeople,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { filterAllowedColumn } from "../../_helper/helper";
import { Icon } from "@progress/kendo-react-common";

function GG1EmployeeMaster() {
  const {
    columns,
    loadingSearch,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const dispatch = useDispatch();
  const { userData } = useAuth();

  const { employeeAuditHistory, isValidEmply, loadingEmployeeSearch } =
    useSelector((state) => state.employeeReducer);
  const { filter: storeFilter, pageSize: storePageSize } = useSelector(
    (state) => state.CommonReducer
  );
  const employeemaster = "gg1employeemaster";

  const history = useNavigate();
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [searchParams, setSearchParams] = useState([]);
  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([]);
  const [pageSize, setPageSize] = useState(
    storePageSize[employeemaster] || 100
  );
  const [sortedColumns, setSortedColumns] = useState([]);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);
  const [isActive, setIsActive] = useState("A%");

  const [empId, setEmpId] = useState(null);

  const actions = useMemo(() => {
    return [
      {
        title: "Employee Details",
        action: (props) => {
          const url = `/gg1employeemaster-detail/${props.dataItem.ID}`;
          window.open(url, "_blank");
        },
        icon: { iconName: "k-icon  k-i-eye" },
        className: "font-primary",
        style: { cursor: "pointer" },
        _role: ["Developer", "GG1-TRAINER", "EmployeeSuperUser", "SUPER-USER"],
      },
    ];
  });

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);
  useEffect(() => {
    if (isValidEmply === true && empId) {
      history(`/leaveShare/${empId}`);
    }
  }, [isValidEmply, empId]);

  useEffect(() => {
    if (ColumnsSorted[employeemaster] !== undefined) {
      setSortedColumns(ColumnsSorted[employeemaster]);
    } else {
      if (
        columns &&
        openHistory === true &&
        HistoryColumns.length > 0 &&
        columns.length > 0
      ) {
        const clms = HistoryColumns.map((d) => d.field.toLowerCase());
        const data = columns
          .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
          .map((p) => {
            return {
              field: p.column_name,

              show: false,
              locked: false,
              width: 200,
              title: p.column_title,
              filter: "text",
            };
          });

        const sortedData = filterAllowedColumn(
          userData,
          HistoryColumns.concat(data),
          "id"
        );

        setSortedColumns(sortedData);
      } else if (columns && visileColumns.length > 0 && columns.length > 0) {
        const clms = visileColumns.map((d) => d.field.toLowerCase());
        const data = columns
          .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
          .map((p) => {
            return {
              field: p.column_name,
              show: false,
              locked: false,
              width: 200,
              title: p.column_title,
              filter: "text",
            };
          });
        setSortedColumns(visileColumns.concat(data));
      } else {
        return;
      }
    }
  }, [columns]); //, openHistory === true, sortData

  useEffect(() => {
    const data = historyData.sort((a, b) => {
      return sortData
        .map((sortItem) => {
          const { field, dir } = sortItem;
          if (a[field] < b[field]) return dir === "desc" ? 1 : -1;
          if (a[field] > b[field]) return dir === "desc" ? -1 : 1;
          return 0;
        })
        .reduce((acc, result) => {
          return acc !== 0 ? acc : result;
        }, 0);
    });

    // console.log("useEffect", data);
    setHistoryData(data);
  }, [sortData]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    //setPageSize(10);
  };

  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    //setPageSize(10);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (searchParameter[employeemaster]?.searchParams?.length > 0) {
      setSearchParams(
        JSON.parse(searchParameter[employeemaster]?.searchParams)
      );
    }
  }, [searchParameter]);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row className="bg-primary rounded py-3 mb-3">
            <Typography variant="h5">
              {/* <FontAwesomeIcon icon={faUsers} className="m-r-10" /> */}
              <Icon style={{ fontSize: "25px", margin: "10px" }} name="user" />
              GG1 Employee Master
            </Typography>
          </Row>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={employeemaster}
                    gridId={employeemaster}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"vw_GG1employeeMaster"}
                    searchParams={searchParams}
                    additionalWhere={additionalWhere}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                    isActive={isActive}
                  />
                  <Accordion
                    style={{ margin: "0px" }}
                    expanded={expandedSearchResult}
                  >
                    <AccordionDetails style={{ padding: "0px" }}>
                      {loadingEmployeeSearch && loadingPanel()}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            id={employeemaster}
                            pageSize={pageSize}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            sortData={sortData}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            actions={actions}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                            // isEmployeeManagement={true}
                            setIsActive={setIsActive}
                            isActive={isActive}
                            isPmValidation={false}
                          ></TelerikDataTable>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <HistoryDataTable
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
          data={employeeAuditHistory || []}
          sortData={sortData}
          selectedColumns={sortedColumns}
          setSortedColumns={setSortedColumns}
          onSortChange={handleSortChange}
          onFilterChange={handleFilterChange}
        />
      </Fragment>
    </ErrorBoundary>
  );
}
export default GG1EmployeeMaster;
