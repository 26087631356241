import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  GET_LEAVES,
  GET_LEAVE_DETAILS,
  REQ_LEAVE_APPLICATION,
  REQ_LEAVE_APPLICATION_SUCCESS,
  REQ_LEAVE_APPLICATION_FAIL,
  FIND_LEAVE_APPLICATION,
  FIND_LEAVE_APPLICATION_SUCCESS,
  FIND_LEAVE_APPLICATION_FAIL,
  LEAVE_APPLICATION_DETAILS,
  LEAVE_APPLICATION_DETAILS_SUCCESS,
  LEAVE_APPLICATION_DETAILS_FAIL,
  EDIT_LEAVE_APPLICATION,
  EDIT_LEAVE_APPLICATION_SUCCESS,
  EDIT_LEAVE_APPLICATION_FAIL,
  DELETE_LEAVE_APPLICATION,
  DELETE_LEAVE_APPLICATION_SUCCESS,
  DELETE_LEAVE_APPLICATION_FAIL,
  CALCULATE_HOURS,
  CALCULATE_HOURS_SUCCESS,
  CALCULATE_HOURS_FAIL,
  SHOW_LOADER,
  HIDE_LOADER,
  LEAVE_APPLICATION_HISTORY,
  LEAVE_APPLICATION_HISTORY_SUCCESS,
  LEAVE_APPLICATION_HISTORY_FAIL,
  UPDATE_LEAVE_STATUS_FAIL,
  UPDATE_LEAVE_STATUS,
  UPDATE_LEAVE_STATUS_SUCCESS,
} from "./actionTypes";

import {
  getLeavesSuccess,
  getLeavesFail,
  getLeaveDetailsSuccess,
  getLeaveDetailsFail,
} from "./actions";

import {
  getLeaves,
  requestLeaveApplication,
  findLeaveApplic,
  fetchLeaveApplic,
  editLeaveApplic,
  deleteLeaveApplication,
  updateLeaveStatus,
  calculateHours,
  leaveApplicationHistory,
} from "../../_helper/backend_helper";
import { ShowError } from "../../_helper/helper";

function* onGetLeaves() {
  try {
    const response = yield call(getLeaves);
    yield put(getLeavesSuccess(response));
  } catch (error) {
    yield put(getLeavesFail(error.response));
  }
}
function* findLeaveApplication({ payload }) {
  try {
    const response = yield call(findLeaveApplic, payload);
    yield put({ type: FIND_LEAVE_APPLICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: FIND_LEAVE_APPLICATION_FAIL, message: error.message });
  }
}
function* getLeaveApplicationHistory({ payload }) {
  try {
    const response = yield call(leaveApplicationHistory, payload);
    yield put({ type: LEAVE_APPLICATION_HISTORY_SUCCESS, response });
  } catch (error) {
    yield put({
      type: LEAVE_APPLICATION_HISTORY_FAIL,
      message: error.message,
    });
  }
}
function* reqLeaveApplication({ payload }) {
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(requestLeaveApplication, payload);
    yield put({ type: REQ_LEAVE_APPLICATION_SUCCESS, response });
  } catch (error) {
    yield put({ type: REQ_LEAVE_APPLICATION_FAIL, message: error.message });
    ShowError(error?.response?.data?.message);
  } finally {
    yield put({ type: HIDE_LOADER });
  }
}
function* getLeaveApplicationDetails({ payload }) {
  try {
    const response = yield call(fetchLeaveApplic, payload);
    yield put({ type: LEAVE_APPLICATION_DETAILS_SUCCESS, response });
  } catch (error) {
    yield put({
      type: LEAVE_APPLICATION_DETAILS_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* editLeaveApplicationDetails({ payload, id }) {
  try {
    const response = yield call(editLeaveApplic, payload, id);
    yield put({ type: EDIT_LEAVE_APPLICATION_SUCCESS, response });
  } catch (error) {
    yield put({
      type: EDIT_LEAVE_APPLICATION_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* DeleteLeaveApplication({ payload }) {
  try {
    const response = yield call(deleteLeaveApplication, payload);
    yield put({ type: DELETE_LEAVE_APPLICATION_SUCCESS, response });
  } catch (error) {
    yield put({
      type: DELETE_LEAVE_APPLICATION_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* ChangeLeaveStatus({ payload: { statusPayload, leaveid } }) {
  try {
    const response = yield call(updateLeaveStatus, statusPayload, leaveid);
    yield put({ type: UPDATE_LEAVE_STATUS_SUCCESS, response });
  } catch (error) {
    yield put({
      type: UPDATE_LEAVE_STATUS_FAIL,
      message: error?.response?.data?.message,
    });
    ShowError(error?.response?.data?.message);
  }
}
function* CalculateHours({ startDate, endDate }) {
  try {
    const response = yield call(calculateHours, startDate, endDate);
    yield put({ type: CALCULATE_HOURS_SUCCESS, response });
  } catch (error) {
    yield put({
      type: CALCULATE_HOURS_FAIL,
      message: error?.response?.data.message,
    });
    ShowError(error?.response?.data?.message);
  }
}

function* CartSaga() {
  yield takeLatest(GET_LEAVES, onGetLeaves);
  yield takeLatest(REQ_LEAVE_APPLICATION, reqLeaveApplication);
  yield takeLatest(FIND_LEAVE_APPLICATION, findLeaveApplication);
  yield takeLatest(LEAVE_APPLICATION_DETAILS, getLeaveApplicationDetails);
  yield takeLatest(EDIT_LEAVE_APPLICATION, editLeaveApplicationDetails);
  yield takeLatest(DELETE_LEAVE_APPLICATION, DeleteLeaveApplication);
  yield takeLatest(CALCULATE_HOURS, CalculateHours);
  yield takeLatest(LEAVE_APPLICATION_HISTORY, getLeaveApplicationHistory);
  yield takeLatest(UPDATE_LEAVE_STATUS, ChangeLeaveStatus);
  //  yield takeLatest(GET_LEAVE_DETAILS, onGetLUPDATE_LEAVE_STATUSeaveDetails);
}

export default CartSaga;
