import {
  STORED_THEME,
  STORED_THEME_SUCCESS,
  STORED_THEME_FAIL,
  USER_CONFIGURATION,
  USER_CONFIGURATION_SUCCESS,
  USER_CONFIGURATION_FAIL,
} from "./actionTypes";
export const gridDataTable = (payload) => {
  return {
    type: "STORED_GRID_TABLE",
    payload: payload,
  };
};
export const getStoredTheme = (payload) => {
  return {
    type: STORED_THEME,
    payload: payload,
  };
};

export const getStoredThemeSuccess = (results) => {
  return {
    type: STORED_THEME_SUCCESS,
    payload: results,
  };
};

export const getStoredThemeFail = (error) => {
  return {
    type: STORED_THEME_FAIL,
    payload: error,
  };
};
export const userConfiguration = () => {
  return {
    type: USER_CONFIGURATION,
  };
};

export const userConfigurationSuccess = (results) => {
  return {
    type: USER_CONFIGURATION_SUCCESS,
    payload: results,
  };
};

export const userConfigurationFail = (error) => {
  return {
    type: USER_CONFIGURATION_FAIL,
    payload: error,
  };
};
