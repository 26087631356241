import {
	GET_PROCESSES, GET_PROCESSES_SUCCESS, GET_PROCESSES_FAIL,
	GET_PROCESSES_DETAILS, GET_PROCESSES_DETAILS_SUCCESS, GET_PROCESSES_DETAILS_FAIL,
	PROCESS_CREATE,PROCESS_CREATE_FAIL,PROCESS_CREATE_SUCCESS,
	PROCESS_DELETE,PROCESS_DELETE_FAIL,PROCESS_DELETE_SUCCESS,
	PROCESS_UPDATE,PROCESS_UPDATE_FAIL,PROCESS_UPDATE_SUCCESS, GET_PROCESSES_SETTING_FAIL, GET_PROCESSES_SETTING_SUCCESS, SAVE_PROCESSES_SETTING_FAIL, SAVE_PROCESSES_SETTING_SUCCESS, SAVE_PROCESSES_SETTING, GET_PROCESSES_SETTING, PROCESS_COPY, PROCESS_COPY_SUCCESS, PROCESS_COPY_FAIL, PROCESS_STATUS_CREATE, PROCESS_STATUS_CREATE_SUCCESS, PROCESS_STATUS_CREATE_FAIL, PROCESS_STATUS_UPDATE, PROCESS_STATUS_UPDATE_SUCCESS, PROCESS_STATUS_UPDATE_FAIL, PROCESS_STATUS_DELETE, PROCESS_STATUS_DELETE_SUCCESS, PROCESS_STATUS_DELETE_FAIL, GET_PROCESSES_STATUSLIST, GET_PROCESSES_STATUSLIST_SUCCESS, GET_PROCESSES_STATUSLIST_FAIL, GET_PROCESSES_STATUSLIST_BY_NOA, GET_PROCESSES_STATUSLIST_BY_NOA_SUCCESS, GET_PROCESSES_STATUSLIST_BY_NOA_FAIL,
	BOTTLENECK_UPDATE,BOTTLENECK_UPDATE_SUCCESS,BOTTLENECK_UPDATE_FAILURE,
	GET_NatureKeyValue,GET_NatureKeyValue_SUCCESS, GET_NatureKeyValue_FAIL
  } from "./actionTypes";

  export const getProcesses = (payload) => {
	return {
	  type: GET_PROCESSES,
	  payload: payload
	};
  };
  
  export const getProcessesSuccess = (posts) => {
	return {
	  type: GET_PROCESSES_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getProcessesFail = (error) => {
	return {
	  type: GET_PROCESSES_FAIL,
	  payload: error,
	};
  };
  
  export const getProcessesDetails = (id) => {
	return {
	  type: GET_PROCESSES_DETAILS,
	  payload: id,
	};
  };
  
  export const getProcessesDetailsSuccess = (post) => {
	return {
	  type: GET_PROCESSES_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getProcessesDetailsFail = (error) => {
	return {
	  type: GET_PROCESSES_DETAILS_FAIL,
	  payload: error,
	};
  };
  export const getNatureKeyValue = (payload) => {
	console.log("getNatureKeyValue:", payload); 
	return {
	  type: GET_NatureKeyValue,
	  payload: payload,
	};
  };
  
  export const getNatureKeyValueSuccess = (posts) => {
	console.log("getNatureKeyValueSuccess:", posts); 

	return {
	  type: GET_NatureKeyValue_SUCCESS,
	  payload: posts,
	  
	};
  };
  
  export const getNatureKeyValueFail = (error) => {
	return {
	  type: GET_NatureKeyValue_FAIL,
	  payload: error,
	};
  };
  export const createProcess =(payload)=>{
	return {
		type: PROCESS_CREATE,
		payload: payload
	}
  }
  export const createProcessSuccess =(response)=>{
	return {
		type: PROCESS_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createProcessFail =(error)=>{
	return {
		type: PROCESS_CREATE_FAIL,
		payload: error
	}
  }


  export const updateProcess =(payload)=>{
	
	return {
		type: PROCESS_UPDATE,
		payload: payload
	}
  }
  export const updateProcessSuccess =(response)=>{
	return {
		type: PROCESS_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateProcessFail =(error)=>{
	return {
		type: PROCESS_UPDATE_FAIL,
		payload: error
	}
  }


  export const copyProcess =(payload)=>{
	
	return {
		type: PROCESS_COPY,
		payload: payload
	}
  }
  export const copyProcessSuccess =(response)=>{
	return {
		type: PROCESS_COPY_SUCCESS,
		payload: response
	}
  }
  export const copyProcessFail =(error)=>{
	return {
		type: PROCESS_COPY_FAIL,
		payload: error
	}
  }


  export const deleteProcess =(payload)=>{
	return {
		type: PROCESS_DELETE,
		payload: payload
	}
  }
  export const deleteProcessSuccess =(response)=>{
	return {
		type: PROCESS_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteProcessFail =(error)=>{
	return {
		type: PROCESS_DELETE_FAIL,
		payload: error
	}
  }



  export const deleteProcessStatus =(payload)=>{
	return {
		type: PROCESS_STATUS_DELETE,
		payload: payload
	}
  }
  export const deleteProcessStatusSuccess =(response)=>{
	return {
		type: PROCESS_STATUS_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteProcessStatusFail =(error)=>{
	return {
		type: PROCESS_STATUS_DELETE_FAIL,
		payload: error
	}
  }

  export const updateProcessStatus =(payload)=>{
	
	return {
		type: PROCESS_STATUS_UPDATE,
		payload: payload
	}
  }
  export const updateProcessStatusSuccess =(response)=>{
	return {
		type: PROCESS_STATUS_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateProcessStatusFail =(error)=>{
	return {
		type: PROCESS_STATUS_UPDATE_FAIL,
		payload: error
	}
  }

  export const createProcessStatus =(payload)=>{
	return {
		type: PROCESS_STATUS_CREATE,
		payload: payload
	}
  }
  export const createProcessStatusSuccess =(response)=>{
	return {
		type: PROCESS_STATUS_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createProcessStatusFail =(error)=>{
	return {
		type: PROCESS_STATUS_CREATE_FAIL,
		payload: error
	}
  }


  export const getProcessesStatusList = (payload) => {
	return {
	  type: GET_PROCESSES_STATUSLIST,
	  payload: payload
	};
  };
  
  export const getProcessesStatusListSuccess = (posts) => {
	return {
	  type: GET_PROCESSES_STATUSLIST_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getProcessesStatusListFail = (error) => {
	return {
	  type: GET_PROCESSES_STATUSLIST_FAIL,
	  payload: error,
	};
  };

  export const getProcessesStatusListByNOA = (payload) => {
	console.log("121212 in action ::: ",payload)
	return {
	  type: GET_PROCESSES_STATUSLIST_BY_NOA,
	  payload: payload
	};
  };
  
  export const getProcessesStatusListByNOASuccess = (posts) => {
	return {
	  type: GET_PROCESSES_STATUSLIST_BY_NOA_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getProcessesStatusListByNOAFail = (error) => {
	return {
	  type: GET_PROCESSES_STATUSLIST_BY_NOA_FAIL,
	  payload: error,
	};
  };

  export const updatebottleneck=(data)=>{
	return{
		type:BOTTLENECK_UPDATE,
		payload:data
	}
  }
  export const updatebottleneckSuccess=(response)=>{
	return{
		type:BOTTLENECK_UPDATE_SUCCESS,
		payload:response
	}
  }
  export const updatebottleneckFailure=(error)=>{
	return{
		type:BOTTLENECK_UPDATE_FAILURE,
		payload:error
	}
  }

