import { get, post, put, remove } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getAllEmployeeSignaturesAPI = () =>
    get(baseURL + url.GET_EMPLOYEE_SIGNATURE);

export const deleteSignatureAPI = (id) =>
    remove(baseURL + url.DELETE_SIGNATURE + "?id=" + id);

export const addUpdateSignatureAPI = (payload) =>
    post(baseURL + url.ADD_UPDATE_SIGNATURE, payload)
        .then((res) => res)
        .catch((error) => error);

export const getSignatureDataAPI = ({ sourceName, refControlNumber }) =>
    get(`${baseURL}${url.GET_SIGNATURE_DATA}/${sourceName}/${refControlNumber}`);

export const getEmployeeSignatureByControlNumberAPI = (controlNumber) =>
    get(`${baseURL}${url.GET_SIGNATURE_DATA_BY_CONTROL_NUMBER}/${controlNumber}`);

export const unassignedSignatureAPI = (payload) =>
    put(`${baseURL}${url.UNASSIGN_SIGNATURE_API}`, payload);

export const generateSignatureDocumentAPI = (payload) =>
    post(`${baseURL}${url.GENERATE_SIGNATURE_DOCUMENT}`, payload);

export const updateSignatureStatusAPI = (payload) =>
    put(`${baseURL}${url.UPDATE_SIGNATURE_STATUS}`, payload);
// export const employeeservicerequest =(payload)=> put (`${baseURL}${url.EMPLOYEE_SERVICE}`, payload)
export const employeeservicerequest = (payload) => {
    // const payload = {
    //   hrControl: hrControl,
    //   currentProcessStatus: currentProcessStatus,
    //   processedReferenceId: processedReferenceId
    // };
    return put(`${baseURL}${url.EMPLOYEE_SERVICE}`, payload);
  }
  
export const approveRejectEmployeeSignatureAPI = (payload) => put(`${baseURL}${url.APPROVE_REJECT_EMPLOYEE_SIGNATURE_STATUS}`, payload);