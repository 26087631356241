import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "configurationSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const fetchAdvanceSearchData = createAsyncThunk(
  `${sliceName}/fetchAdvanceSearchData`,
  async () => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV
      const dataDPDV = await axios.get("https://api.ehrpweb.com//api/depdev", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("jwt_access_token"),

          // "x-access-token": localStorage.getItem("jwt_access_token"),
        },
      });

      let DPDVList = [];
      if (dataDPDV.data.length) {
        console.log("DPDV", dataDPDV.data);
        dataDPDV.data.map((item) => {
          DPDVList.push({
            value: item.Code,
            label: item.Description,
          });
          // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
        });
      }

      //get nature of transactions
      // const dataNature = await axios.post(
      //   "https://api.ehrpweb.com/api/advancesearch/v2",
      //   {
      //     tableName: "PMRC_NATURE",
      //     params: [
      //       {
      //         value: "0",
      //         operator: ">=",
      //         column: "NATURE_CODE",
      //         label: "",
      //         dataType: "bigint",
      //         columnName: "NATURE_CODE",
      //       },
      //     ],
      //     columns: "*",
      //     limit: 2000,
      //     orderBy: "NATURE_CODE",
      //     sortBy: "DESC",
      //   },
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-access-token":
      //         "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
      //     },
      //   }
      // );

      // let NatureList = [];
      // if (dataNature.data.length) {
      //   console.log("dg", dataNature.data);
      //   dataNature.data.map((item) => {
      //     NatureList.push({
      //       value: item.NATURE_CODE,
      //       label: item.NATURE_TITLE,
      //     });
      //     // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
      //   });
      // }

      //get Employee Types
      let EmpTypeList = [];
      try {
        const dataEmpType = await axios.get(
          `${process.env.REACT_APP_API_URL}/employeetypes`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": sessionStorage.getItem("jwt_access_token"),

              // "x-access-token": localStorage.getItem("jwt_access_token"),
            },
          }
        );

        if (dataEmpType.data.length) {
          console.log("DPDV", dataEmpType.data);
          dataEmpType.data.map((item) => {
            EmpTypeList.push({
              value: item.TypeCode,
              label: item.Description,
            });
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });
        }
      } catch (error) {}

      const allOptions = await axios.get(
        `${process.env.REACT_APP_API_URL}/optionsmaster`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": sessionStorage.getItem("jwt_access_token"),

            // "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      let Education = [];
      let EmpStatus = [];
      let EmpType = [];
      let Inactive_Status = [];
      let Ethnic_Group = [];
      let Nposition_No = [];
      let Pay_Grade = [];
      let Pay_Step = [];
      let Military_Status = [];
      if (allOptions?.data) {
        console.log("allOptions", allOptions.data);
        allOptions?.data?.Education?.map((item) => {
          Education.push({
            value: item.Education,
            label: item.Description,
          });
        });
        allOptions?.data?.Emp_Status?.map((item) => {
          EmpStatus.push({
            value: item.DESCRIPTION,
            label: item.DESCRIPTION,
          });
        });
        allOptions?.data?.Emp_Type?.map((item) => {
          EmpType.push({
            value: item.EMPL_TYPE,
            label: item.DESCRIPTION,
          });
        });
        allOptions?.data?.Inactive_Status?.map((item) => {
          Inactive_Status.push({
            value: item.DESCRIPTION,
            label: item.DESCRIPTION,
          });
        });
        allOptions?.data?.Ethnic_Group?.map((item) => {
          Ethnic_Group.push({
            value: item.ETHNIC_CODE,
            label: item.ETHNIC_TITLE,
          });
        });
        allOptions?.data?.Nposition_No?.map((item) => {
          Nposition_No.push({
            value: item.TITLE,
            label: item.TITLE,
          });
        });
        allOptions?.data?.Pay_Grade?.map((item) => {
          Pay_Grade.push({
            value: item.PAY_GRADE,
            label: item.PAY_GRADE,
          });
        });
        allOptions?.data?.Pay_Step?.map((item) => {
          Pay_Step.push({
            value: item.PAY_STEP,
            label: item.PAY_STEP,
            payGrade: item.PAY_GRADE,
          });
        });

        allOptions?.data?.Military_Status?.map((item) => {
          Military_Status.push({
            value: item.Military_Code,
            label: item.Military_Title,
          });
        });
      }

      let resultArray = {
        DPDV: DPDVList,
        EmpTypeList: EmpTypeList,
        Education: Education,
        EmpStatus: EmpStatus,
        Nposition_No: Nposition_No,
        EmpType: EmpType,
        Inactive_Status: Inactive_Status,
        Ethnic_Group: Ethnic_Group,
        Pay_Grade: Pay_Grade,
        Pay_Step: Pay_Step,
        Military_Status: Military_Status,

        doc_type: [
          { value: "COURT_RECORD", label: "COURT RECORD" },
          { value: "DLM_RECORD", label: "DLM RECORD" },
        ],
      };

      return {
        advanceSearchData: resultArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const configurationSlice = createSlice({
  name: sliceName,
  initialState: {
    advanceSearchData: [],
  },
  extraReducers: {
    [fetchAdvanceSearchData.fulfilled]: (state, action) => {
      console.log("action ::::: ==> ", action);
      state.advanceSearchData = action.payload.advanceSearchData;
    },
  },
});

export default configurationSlice.reducer;
