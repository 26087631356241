export const headerString = `<div style="padding: 5px; text-align: center; pointer-events: none;" class="header-template">
<div style="margin: 0 auto; max-width: 800px; display: flex; justify-content: space-between;">
    <div>
        <img src=${'process?.env?.REACT_APP_PUBLIC_URL'+'/GuamSeal.png'} alt="Left Image" style="width: 47px; height: auto; margin-bottom: 10px;">
        <p style="font-weight: 700; text-transform: uppercase; margin: 0;">edward m. birn</p>
        <p style="margin: 0; font-weight: 600;">Director (Direktot)</p>
        <p style="font-weight: 700; text-transform: uppercase; margin: 0;">Bernadine c. gines</p>
        <p style="margin: 0; font-weight: 600;">Deputy Director (Sigundo Direktot)</p>
    </div>
    
    <div style="text-align: center;">
        <p style="font-weight: 700; text-transform: uppercase; font-size: 1.5em; color: rgb(44, 163, 120); margin: 0;">Department of administration</p>
        <p style="font-weight: 600; text-transform: uppercase; font-size: 1.2em; color: rgb(99, 174, 144); margin: 0;">Dipattamenton atmenestrasion</p>
        <p style="font-weight: 700; text-transform: uppercase; color: rgb(28, 177, 74); margin: 0;"></p>
        <p style="font-weight: 700; text-transform: uppercase; color: rgb(28, 177, 74); margin: 0;">Human resource division</p>
        <p style="color: rgb(28, 177, 74); margin: 0; font-size: 16px; font-weight: 700;">(Dibision Inadilanto yan Guinaha Para Taotao)</p>
        <p style="color: rgb(28, 177, 74); margin: 0; font-size: 16px; font-weight: 700;">
            <span>Telephone (Telifon): (671) 475-1288/1103</span>
            <span>Fax (Faks): (671) 477-3671</span>
        </p>
    </div>
    
    <div>
        <img src=${'process?.env?.REACT_APP_PUBLIC_URL'+'/GuamSeal.png'} alt="Right Image" style="width: 47px; height: auto; margin-bottom: 10px;">
        <p style="font-weight: 700; text-transform: uppercase; margin: 0;">Lourdes a. leon guerrero</p>
        <p style="margin: 0; font-weight: 600;">Governor (Maga'haga)</p>
        <p style="font-weight: 700; text-transform: uppercase; margin: 0;">joshua f. tenorio</p>
        <p style="margin: 0; font-weight: 600;">Lt. Governor (Sigundo Maga'lahi)</p>
    </div>
</div>
</div>

<div>
<hr style="width: 100%; border: none; height: 5px; background-color: black;">
</div>`;
