// import { put } from "redux-saga/effects";
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getJobAnnouncementDetails = (id) => {
  return get(
    `${baseURL + url.JOB_ANNOUNCEMENT_DETAILS}/${encodeURIComponent(id)}`
  );
};
export const updateJobAnnouncement = (id, payload) => {
  return put(`${baseURL}${url.JOB_ANNOUNCEMENT_UPDATE}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const createJobAnnouncement = (payload) =>
  post(baseURL + url.JOB_ANNOUNCEMENT_CREATE, payload);
export const deleteJobAnnouncement = (ID) =>
  remove(baseURL + url.JOB_ANNOUNCEMENT_DELETE + "/" + ID);
