const documentVisibleColumns = [
  {
    title: "Employee ID",
    field: "employeeId",
    locked: true,
    filter: "numeric",
    customFilterType: "CustomNumericText",
    // className: "text-end",
    // headerClassName: "number-align",
    width: "160",
    show: true,
  },
  {
    title: "Employee Name",
    field: "employeeName",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Department",
    field: "departmentName",
    locked: false,
    filter: "text",
    show: false,
    width: "auto",
  },
  {
    title: "Position",
    field: "positionName",
    locked: false,
    filter: "text",
    show: false,
    width: "auto",
  },
  {
    title: "File Type",
    field: "contentType",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },
  {
    title: "File Name",
    field: "documentName",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Reference Id",
    field: "referenceId",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  // {
  //   title: "Application",
  //   field: "application",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  // {
  //   title: "Module",
  //   field: "module",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  // {
  //   title: "Performed By",
  //   field: "EmployeeName",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Uploaded At",
    field: "createdOn",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy hh:mm:ss A",
    editor: "date",
    width: "auto",
    show: true,
  },
];

export default documentVisibleColumns;
