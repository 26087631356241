import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Grid } from "@mui/material";
import ReactFlow, {
  getIncomers,
  getOutgoers,
  addEdge,
  Background,
  isNode,
  MiniMap,
  Panel,
  useEdgesState,
  useNodesState,
  isEdge,
  ReactFlowProvider,
} from "reactflow";
import "reactflow/dist/style.css";
import { colors } from "@mui/material";
import CustomNode from "./CustomNode";
import TooltipNode from "./TooltipNode";
import ConnectionLine from "./ConnectionLine";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorkflowsDetails,
  getWorkflowsSetting,
} from "../../store/workflows/actions";
import { Loader } from "react-feather";

const initialNodes = [
  //{"id":"2","type":"customNode","data":{"label":"fdsf","icon":"fd"},"position":{"x":-143,"y":47.5},"style":{"border":"1px solid #ff0071","background":"white","color":"#0041d0","fontSize":"12px","borderColor":"#0041d0","borderRadius":5},"width":51,"height":46,"selected":false,"positionAbsolute":{"x":-143,"y":47.5},"dragging":false},{"id":"3","type":"customNode","data":{"label":"d","icon":"d"},"position":{"x":-2.5,"y":10.5},"style":{"border":"1px solid #ff0071","background":"white","color":"#0041d0","fontSize":"12px","borderColor":"#0041d0","borderRadius":5},"width":43,"height":46,"selected":false,"positionAbsolute":{"x":-2.5,"y":10.5},"dragging":false},{"id":"4","type":"customNode","data":{"label":"ddd","icon":"d"},"position":{"x":103.5,"y":15},"style":{"border":"1px solid #ff0071","background":"white","color":"#0041d0","fontSize":"12px","borderColor":"#0041d0","borderRadius":5},"width":62,"height":46,"selected":true,"positionAbsolute":{"x":103.5,"y":15},"dragging":false}
];
const initialEdges = [
  //{"source":"2","sourceHandle":null,"target":"3",animated: true,"label":'hello',style:{stroke:'red'}, "targetHandle":null,"id":"reactflow__edge-2-3"},{"source":"3","sourceHandle":null,"target":"4","targetHandle":null,"id":"reactflow__edge-3-4"}
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const nodeTypes = {
  customNode: CustomNode,
  tooltip: TooltipNode,
};

export default function FullScreenWorkflowDialog({
  id,
  isOpened = false,
  onClose,
  employeeName,
  hrControlNo,
  currentNodeText,
}) {
  const [open, setOpen] = React.useState(isOpened);

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const dispatch = useDispatch();
  const {
    workflowDetails: workflow,
    workflowSetting,
    loadingWorkflowDetails,
    loadingWorkflowSetting,
  } = useSelector((state) => {
    return state.workflowReducer;
  });
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [elements, setElements] = useState();
  const [workflowData, setWorkflowData] = useState({});
  const [selectedNode, setSelectedNode] = useState();

  const findPreviousNodes = (Nodes, Edges, currentEdge) => {
    if (!currentEdge) return Nodes;
    const cNode = Nodes.find((d) => d.id === currentEdge.source);
    if (cNode) {
      cNode.style.backgroundColor = workflowSetting.PBackgroundColor;
      cNode.style.color = workflowSetting.PTextColor;
      cNode.style.borderColor = workflowSetting.PBorderColor;
      const nEdge = Edges.find((d) => d.target === cNode.id);
      if (nEdge) {
        Nodes = findPreviousNodes(Nodes, Edges, nEdge);
      } else {
        const nParentEdge = Edges.find(
          (d) => d.target === cNode.data.info.parentID
        );
        if (nParentEdge) Nodes = findPreviousNodes(Nodes, Edges, nParentEdge);
      }
    }
    return Nodes;
  };
  useEffect(() => {
    if (!workflowSetting || !workflowSetting.ID) {
      dispatch(getWorkflowsSetting(1));
    }
  }, []);
  useEffect(() => {
    if (workflowSetting && workflowSetting.ID > 0) {
      // debugger;
      if (!workflow || workflow.ID != id) {
        dispatch(getWorkflowsDetails(id));
      }
    }
  }, [workflowSetting, id]);

  useEffect(() => {
    setWorkflowData(workflow);
    let currentNode = null;
    let nds = null;
    if (workflow.nodes && workflow.nodes !== "") {
      nds = JSON.parse(workflow.nodes);
      nds = nds.map((item) => {
        item.style.fontSize = workflowSetting.FontSize + "px";
        item.style.borderRadius = workflowSetting.BorderRadius + "px";
        item.style.borderWidth = workflowSetting.BorderWidth + "px";
        item.style.fontWeight = "bold";
        item.style.padding = "10px";
        item.style.lineHeight = "1.2";
        item.style.textAlign = "center";
        item.data.resizable = false;
        if (currentNode === item) {
          item.style.backgroundColor = workflowSetting.CBackgroundColor;
          item.style.color = workflowSetting.CTextColor;
          item.style.borderColor = workflowSetting.CBorderColor;
        } else if (
          item.data.info.nodeType !== "group" &&
          item.data.info.nodeType !== "child"
        ) {
          item.style.backgroundColor = workflowSetting.BackgroundColor;
          item.style.borderColor = workflowSetting.BorderColor;
          item.style.color = workflowSetting.TextColor;
        }
        if (item.data.nodeType === "group") {
          item.data.title = null;
        }
        return item;
      });
      currentNode = nds.find((d) => d.data.info.title === currentNodeText);
    }

    if (workflow.edges && workflow.edges !== "") {
      let edg = JSON.parse(workflow.edges);
      if (currentNode != null) {
        const currentEdge = edg.find((d) => d.target === currentNode.id);
        if (currentEdge != null) {
          // currentEdge.style.stroke = 'red';
        }
        nds = findPreviousNodes(nds, edg, currentEdge);
      }
      edg = edg.map((item) => {
        item.style.strokeWidth = workflowSetting.EdgeWidth + "px";
        item.style.stroke = workflowSetting.EdgeColor;
        item.type = "smoothstep";
        item.pathOptions = { offset: 50, borderRadius: 15 };
        return item;
      });
      setEdges(edg);
    }
    if (nds) {
      if (currentNode != null) {
        currentNode.style.backgroundColor = workflowSetting.CBackgroundColor;
        currentNode.style.borderColor = workflowSetting.CBorderColor;
        currentNode.style.color = workflowSetting.CTextColor;
      }
      setNodes(nds);
    }
  }, [workflow]);

  const getAllIncomers = (node, elements) => {
    return getIncomers(node, elements).reduce(
      (memo, incomer) => [
        ...memo,
        incomer,
        ...getAllIncomers(incomer, elements),
      ],
      []
    );
  };

  const getAllOutgoers = (node, elements) => {
    return getOutgoers(node, elements).reduce(
      (memo, outgoer) => [
        ...memo,
        outgoer,
        ...getAllOutgoers(outgoer, elements),
      ],
      []
    );
  };
  const highlightPath = (node, elements, selection) => {
    if (node && elements) {
      const allIncomers = getAllIncomers(node, elements);
      const allOutgoers = getAllOutgoers(node, elements);

      setElements((prevElements) => {
        return prevElements?.map((elem) => {
          const incomerIds = allIncomers.map((i) => i.id);
          const outgoerIds = allOutgoers.map((o) => o.id);

          if (
            isNode(elem) &&
            (allOutgoers.length > 0 || allIncomers.length > 0)
          ) {
            const highlight =
              elem.id === node.id ||
              incomerIds.includes(elem.id) ||
              outgoerIds.includes(elem.id);

            elem.style = {
              ...elem.style,
              opacity: highlight ? 1 : 0.25,
            };
          }

          if (isEdge(elem)) {
            if (selection) {
              const animated =
                incomerIds.includes(elem.source) &&
                (incomerIds.includes(elem.target) || node.id === elem.target);
              elem.animated = animated;

              elem.style = {
                ...elem.style,
                stroke: animated ? colors.blue : "#b1b1b7",
                opacity: animated ? 1 : 0.25,
              };
            } else {
              elem.animated = false;
              elem.style = {
                ...elem.style,
                stroke: "#b1b1b7",
                opacity: 1,
              };
            }
          }

          return elem;
        });
      });
    }
  };
  const resetNodeStyles = () => {
    setElements((prevElements) => {
      return prevElements?.map((elem) => {
        if (isNode(elem)) {
          elem.style = {
            ...elem.style,
            opacity: 1,
          };
        } else {
          elem.animated = false;
          elem.style = {
            ...elem.style,
            stroke: "#b1b1b7",
            opacity: 1,
          };
        }

        return elem;
      });
    });
  };
  const onConnect = useCallback(
    (params) =>
      setEdges((els) => {
        //params.animated = true;
        //params.label="Helo";
        return addEdge(params, els);
      }),
    []
  );

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} className="bg-primary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Grid container fluid={true}>
                {workflowData?.WorkflowName ? (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <Typography variant="h6" className="bg-primary">
                      {workflowData?.WorkflowName}
                    </Typography>
                  </Grid>
                ) : null}
                {employeeName ? (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <Typography className="bg-primary">
                      <b> Employee Name:-</b> {employeeName}
                    </Typography>
                  </Grid>
                ) : null}
                {hrControlNo ? (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <Typography className="bg-primary">
                      <b> HR Control No:-</b> {hrControlNo}
                    </Typography>
                  </Grid>
                ) : null}

                {currentNodeText ? (
                  <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                    <Typography className="bg-primary">
                      <b> Current Status:-</b> {currentNodeText}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </div>
            {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {workflowData?.WorkflowName} 
            </Typography> */}
          </Toolbar>
        </AppBar>
        {(loadingWorkflowDetails || loadingWorkflowSetting) && (
          <Loader
            style={{ position: "absolute", top: "200", left: "50%" }}
            size={64}
          />
        )}
        <ReactFlow
          elements={elements || []}
          nodes={nodes}
          edges={edges}
          draggable={false}
          nodesDraggable={false}
          elementsSelectable={false}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
          fitViewOptions={{ padding: 2, includeHiddenNodes: true }}
          showInteractive={false}
          attributionPosition="bottom-left"
          connectionLineComponent={ConnectionLine}
          proOptions={{ hideAttribution: true }}
          onNodeMouseEnter={(_event, node) =>
            !selectedNode && highlightPath(node, elements)
          }
          onNodeMouseLeave={() => !selectedNode && resetNodeStyles()}
          onPaneClick={() => {
            resetNodeStyles();
            setSelectedNode(undefined);
          }}
        >
          <Panel></Panel>
          <MiniMap />
          <Background variant="none" />
          {/* <Controls>
								
							</Controls> */}
        </ReactFlow>
      </Dialog>
    </div>
  );
}
