import {
    GET_AUDIT_LOGS,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAIL,
    RESET,
    GET_AUDIT_LOG_EXPORTS,
    GET_AUDIT_LOG_EXPORTS_SUCCESS,
    GET_AUDIT_LOG_EXPORTS_FAIL,
    GET_AUDIT_LOG_DIFF,
    GET_AUDIT_LOG_DIFF_SUCCESS,
    GET_AUDIT_LOG_DIFF_FAIL,
    RESET_AUDIT_LOG_DIFF,
} from "./actionsTypes";

//Get Audit Logs
export const getAuditLogs = (payload) => {
    return {
        type: GET_AUDIT_LOGS,
        payload: payload,
    };
};
export const getAuditLogsSuccess = (data) => {
    return {
        type: GET_AUDIT_LOGS_SUCCESS,
        payload: data,
    };
};
export const getAuditLogsFail = (error) => {
    return {
        type: GET_AUDIT_LOGS_FAIL,
        payload: error,
    };
};

//Export Audit Logs
export const getAuditLogExports = (payload) => {
    return {
        type: GET_AUDIT_LOG_EXPORTS,
        payload: payload,
    };
};
export const getAuditLogExportsSuccess = (data) => {
    return {
        type: GET_AUDIT_LOG_EXPORTS_SUCCESS,
        payload: data,
    };
};
export const getAuditLogExportsFail = (error) => {
    return {
        type: GET_AUDIT_LOG_EXPORTS_FAIL,
        payload: error,
    };
};

//Get Audit Log Diff
export const getAuditLogDiff = (payload) => {
    return {
        type: GET_AUDIT_LOG_DIFF,
        payload: payload,
    };
};
export const getAuditLogDiffSuccess = (data) => {
    return {
        type: GET_AUDIT_LOG_DIFF_SUCCESS,
        payload: data,
    };
};
export const getAuditLogDiffFail = (error) => {
    return {
        type: GET_AUDIT_LOG_DIFF_FAIL,
        payload: error,
    };
};

//Reset Action
export const reset = () => {
    return {
        type: RESET,
    };
};

export const resetAuditLogDiff = () => {
    return {
        type: RESET_AUDIT_LOG_DIFF,
    };
};