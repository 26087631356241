import React, { useEffect, useState } from "react";
import tab from "../../Components/Leave/LeaveApplication/components/tab.css";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  gg1ExcelReport,
  storeActiveData,
  storeColumns,
} from "../../store/advanceSearchv2/actions";
import {
  getStoredTheme,
  userConfiguration,
  gridDataTable,
} from "../../store/themeCustomizer/action";
import { ShowSucess } from "../../_helper/helper";
import {
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // Chip,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  setGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFile, faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { CustomMenu } from "./customMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { filterBy } from "@progress/kendo-data-query";
import ColumnFilter from "./columnFilter";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { faDiagramProject } from "@fortawesome/pro-duotone-svg-icons";
import FullScreenWorkflowDialog from "../../Components/Workflow/WorkflowReadOnly";
import QRCodeModal from "../../Components/AdvanceSearch/QRCodeModal";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch/index";
import { Check, CropSharp, Watch } from "@material-ui/icons";
import { findMaxDigitsAmongFields } from "../CommonFunction/index";
import { renderNumberFormat } from "../../utils/rendernumber";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  SetGridDataState,
  SetGridFilter,
  SetGridSort,
  setAdditionalWhere,
  setGridPageSize,
  setGridSkip,
} from "../../store/Common/actions";

import {
  BOOLEAN_FIELDS,
  BUTTON_TYPE_FIELDS,
  CUSTOME_BUTTON_STATUS_FIELDS,
  STATUS_FIELDS,
} from "./Constants";
import { AlternateEmail } from "@mui/icons-material";

import { useAuth } from "oidc-react";
import { Btn, H6 } from "../../AbstractElements";
import { Icon, getter } from "@progress/kendo-react-common";
import { CustomTextFilter } from "../Customtextfilter";
import { CustomFilterCell } from "./customFilterCell";
import { getUserRole } from "../../_helper/helper";
import { isEqual } from "lodash";
import { makeStyles } from "@material-ui/core";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const noFooterCellFields = [
  "ID",
  "EmployeeId",
  "EMPLOYEE_ID",
  "Employee_No",
  "Check_Sequence",
  "Check_Seq",
  "FundID",
];
const useCheckboxStyles = makeStyles({
  root: {
    padding: "5px 0px !important",
  },
});

const initialDataState = {
  sort: [
    // {
    //   field: "code",
    //   dir: "asc",
    // },
  ],
  //   take: 10,
  //   skip: 0,
};
const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
const SQL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const SQL_DATE_FORMAT = "DD/MM/YYYY";
const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
const mappings = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  endswith: "{0} LIKE '%{1}'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
};
const formatFilter = (mapping, field, value) => {
  return mapping.replace("{0}", field).replace("{1}", value);
};
const formatDateFilter = (mapping, field, value) => {
  return mapping
    .replace("{0}", `CONVERT(DATE,${field})`)
    .replace("'{1}'", `CONVERT(DATE,'${value}')`);
};
const toSQLExpression = (filter) => {
  if (!filter) return;

  let filters = filter.filters,
    field,
    value,
    operator,
    mapping,
    type,
    logic = filter.logic || "AND",
    result = [];

  for (let i = 0; i < filters.length; i++) {
    filter = filters[i];
    field = filter.field;
    value = filter.value;
    operator = filter.operator;

    if (filter.filters) {
      filter = toSQLExpression(filter);
    } else {
      mapping = mappings[operator];
      type = typeof value; ///$.type

      // if (value instanceof Date) {
      //   value =moment(value).format(SQL_DATE_FORMAT) // toString(value.toISOString().slice(0, 19).replace('T', ' '));
      // }
      if (value instanceof Date) {
        filter = formatDateFilter(
          mapping,
          field,
          moment(value).format(SQL_DATE_FORMAT)
        );
      } else {
        filter = formatFilter(mapping, field, value);
      }
    }

    result.push(filter);
  }

  filter = result.join(" " + logic.toUpperCase() + " ");

  if (result.length > 1) {
    filter = "(" + filter + ")";
  }

  return filter;
};
let newData = [];
const TelerikDataTable = (props) => {
  let {
    id,
    setSortedColumns,
    selectedColumns,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
    onSortChange,
    totalCount,
    skip,
    loading,
    sortData,
    actions,
    handleRadioChange,
    chooseFilterMethod,
    setChooseFilterMethod,
    searchParams,
    isDashboard,
    expandField,
    moreGridTool,
    onExpandChange,
    handleClick,
    isEmployeeManagement,
    aggregates = [],
    // searchParameter,
    actionsWidth,
    setIsActive,
    isActive,
    isPmValidation,
    showCheckboxes,
    onSelectionChange,
    onHeaderSelectionChange,
    selectedState,
    groupDepartment,
    setDepartmentModalContent,
    departmentModalContent,
    groupDepartmentLoader,
    setGroupDepartmentLoader,
    checkboxAction = false,
    handleSelectCheckbox = () => {},
    checkboxKey = null,
    clearSelectedCheckbox = null,
    handleSelectAll = () => {},
    checkSelectedCheckbox = null,
    setSelectedCheckBoxed = () => {},
    showActionCheckBoxTooltipText,
    deleteState = false,
    setUnCheckEmpno = () => {},
    unCheckedEmpNo,
    defaultEmpID,
    isShowActionCheckBox = false,
    onGroupChange,
    data,
    enableFooterCell,
    initialGridParams,
    defaultAdditionalWhere,
    hideSaveIcon,
    hideAllGridToolBar,
  } = props;
  const {
    filter: storeFilter,
    dataState: storeDataState,
    sort: storeSort,
    skip: storeSkip,
    pageSize: storePageSize,
    additionalWhere: storeAdditionalWhere,
  } = useSelector((state) => state.CommonReducer);
  const {
    savedLocation,
    searchParameter,
    GG1ExcelReport,
    loadingExcelReport,
    activeResp,
    fileExtension,
    ColumnsSorted,
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const [enableCheckboxes, setEnableCheckboxes] = useState(false);
  const {
    gridSetting = {},
    GridData = {},
    loadingSearch,
    message,
  } = useSelector((state) => state?.ThemeCustomizerReducer || {});
  // const handleExcelExport = async () => {
  //   try {
  //     setIsExporting(true);

  //     // Simulate export delay (replace with your actual export logic)
  //     await new Promise((resolve) => setTimeout(resolve, 3000));

  //     // Perform your export logic here

  //   } finally {
  //     setIsExporting(false);
  //   }
  // };
  const dispatch = useDispatch();
  const URL = useLocation();
  const [group, setGroup] = React.useState(
    (GridData &&
    Object.keys(GridData).length > 0 &&
    GridData?.StoreDataState &&
    GridData?.StoreDataState[id] &&
    GridData?.StoreDataState[id]?.group
      ? GridData?.StoreDataState[id]?.group
      : props.group) || []
  );
  const minGridWidth = React.useRef(0);
  const grid = React.useRef(null);
  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  let gridPDFExport;
  const pdfExportComponent = React.useRef(null);
  const _export = React.useRef(null);
  const handleExcelExport = () => {
    const exportParams = {
      ...searchParameter[id],
      columns: selectedColumnsV2
        .filter((i) => i.show)
        .map((d) => d.field)
        .join(","),
      fileExtension: "xls",
      isOJA: id == "JA_Announcement",
    };

    dispatch(gg1ExcelReport(exportParams));
  };

  useEffect(() => {
    if (typeof GG1ExcelReport === "object" && startDownload === true) {
      downloadExcel();
    }
  }, [GG1ExcelReport]);
  async function downloadExcel() {
    // const contentDisposition = GG1ExcelReport?.headers?.get(
    //   "content-disposition"
    // );
    //const filenameMatch =
    // contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const filename = `report_${new Date().getTime()}`;
    // Create a Blob from the response data
    const blob = await GG1ExcelReport.data;
    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  const exportPDF = () => {
    try {
      setIsExportingPDF(true);

      const exportParams = {
        ...searchParameter[id],
        columns: selectedColumnsV2
          .filter((i) => i.show)
          .map((d) => d.field)
          .join(","),
        fileExtension: "pdf",
        isOJA: id == "JA_Announcement",
        ...(props?.header ? { headername: props.header } : {}),
        ...(props?.footerContent ? { footerContent: props.footerContent } : {}),
        ...(props?.headerContent ? { headerContent: props.headerContent } : {}),
        ...(props?.additionalExportPdfPayload
          ? props.additionalExportPdfPayload
          : {}),
      };

      dispatch(gg1ExcelReport(exportParams));
    } finally {
      setIsExportingPDF(false);
    }
  };

  const navigate = useNavigate();
  const { userData } = useAuth();
  const currentRole = getUserRole(userData);

  const [filter, setFilter] = React.useState({ logic: "and", filters: [] });
  const [startDownload, setStartDownload] = useState(false);
  const [gridParams, setGridParams] = useState({
    sort: [],
    skip: 0,
    pageSize: 100,
  });
  const [workflowInfo, setWorkflowInfo] = useState({
    workflowID: 0,
    CurrentStatus: "",
    employeeName: "",
    hrControlNo: "",
  });
  const [dataState, setDataState] = React.useState(initialDataState);

  const [openModal, setOpenModal] = useState(false);
  const [result, setResult] = React.useState({ data: [] });
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectAll, setSelectAll] = useState();
  const DATA_ITEM_KEY = "ID";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const checkboxProps = showCheckboxes
    ? {
        dataItemKey: DATA_ITEM_KEY,
        selectedField: SELECTED_FIELD,
        selectable: {
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        },
        onSelectionChange: onSelectionChange,
        onHeaderSelectionChange: onHeaderSelectionChange,
      }
    : {};
  const checkBoxClasses = useCheckboxStyles();
  const [selectedColumnsV2, setSelectedColumnsV2] = useState([]);
  const storedColumns = {};
  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;
    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    setResult({ data: newDataState.data });
    return newDataState;
  };
  useEffect(() => {
    if (
      searchParams &&
      searchParams !== null &&
      Object.keys(searchParams).length > 0
    ) {
      setChooseFilterMethod("advanceSearch");
    }
  }, [searchParams]);
  const [digitVars, setDigitVars] = useState(0);
  const [collapsedState, setCollapsedState] = React.useState([]);
  const expandChange = (event) => {
    const item = event.dataItem;
    if (item && item?.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };
  // useEffect(() => {
  //   if (savedLocation !== "") {
  //     setChooseFilterMethod(
  //       savedLocation === URL?.pathname ? "advanceSearch" : chooseFilterMethod
  //     );
  //   }
  // }, [savedLocation]);

  const handleSelectAllCheckbox = (event) => {
    if (event?.target?.checked) {
      let selectedData = props.data.map((obj) =>
        checkboxKey ? obj[checkboxKey] : JSON.stringify(obj)
      );
      setSelectedCheckbox(selectedData);
      setSelectAll(true);
      handleSelectAll(true);
      setSelectedCheckBoxed(selectedData);
    } else {
      setSelectedCheckbox([]);
      setSelectAll(false);
      handleSelectAll(false);
      setSelectedCheckBoxed([]);
    }
  };
  console.log("GridData", GridData);
  const handleCheck = (clickProps, e) => {
    const updatedSelectedEmployees = new Set(selectedCheckbox);

    if (e.target.checked) {
      !!checkboxKey &&
        updatedSelectedEmployees.add(clickProps?.dataItem[checkboxKey]);
      if (deleteState) {
        let removeEmp = unCheckedEmpNo.filter(
          (item) => item !== clickProps?.dataItem[checkboxKey]
        );
        setUnCheckEmpno(removeEmp);
      }
    } else {
      !!checkboxKey &&
        updatedSelectedEmployees.delete(clickProps?.dataItem[checkboxKey]);
      if (deleteState) {
        let isExistEmpID = defaultEmpID?.find(
          (i) => i == clickProps?.dataItem[checkboxKey]
        );
        if (isExistEmpID) {
          unCheckedEmpNo.push(isExistEmpID);
        }
      }
    }
    let data = JSON.parse(JSON.stringify(clickProps?.dataItem));
    delete data["expanded"];

    if (e.target.checked) {
      updatedSelectedEmployees.add(
        checkboxKey ? clickProps?.dataItem[checkboxKey] : JSON.stringify(data)
      );
    } else {
      updatedSelectedEmployees.delete(
        checkboxKey ? clickProps?.dataItem[checkboxKey] : JSON.stringify(data)
      );
    }
    if (updatedSelectedEmployees.size == props?.data?.length) {
      setSelectAll(true);
      handleSelectAll(true);
    } else {
      setSelectAll(false);
      handleSelectAll(false);
    }
    setSelectedCheckbox(Array.from(updatedSelectedEmployees));
    setSelectedCheckBoxed(Array.from(updatedSelectedEmployees));
  };

  useEffect(() => {
    if (selectAll && !!(props?.data || []).length) {
      setSelectedCheckbox(
        props?.data.map((obj) =>
          checkboxKey ? obj[checkboxKey] : JSON.stringify(obj)
        )
      );
    }
  }, [props?.data, checkboxKey]);

  useEffect(() => {
    handleSelectCheckbox(selectedCheckbox);
  }, [selectedCheckbox]);

  useEffect(() => {
    if (
      checkSelectedCheckbox &&
      !isEqual(selectedCheckbox, checkSelectedCheckbox)
    )
      setSelectedCheckbox(checkSelectedCheckbox);
  }, [checkSelectedCheckbox]);

  useEffect(() => {
    if (clearSelectedCheckbox != null || clearSelectedCheckbox != undefined) {
      setSelectedCheckbox([]);
    }
    setSelectAll(false);
    handleSelectAll(false);
  }, [clearSelectedCheckbox]);
  useEffect(() => {
    if (
      GridData &&
      Object.keys(GridData)?.length > 0 &&
      GridData?.storeAdditionalWhere &&
      GridData?.storeAdditionalWhere[id] &&
      GridData?.storeAdditionalWhere[id].includes("Employee_Status = 'I%'")
    ) {
      setIsActive("I%");
    } else if (
      GridData &&
      Object.keys(GridData)?.length > 0 &&
      GridData?.storeAdditionalWhere &&
      GridData?.storeAdditionalWhere[id] &&
      GridData?.storeAdditionalWhere[id].includes("Employee_Status = 'A%'")
    ) {
      setIsActive("A%");
    } else {
    }
  }, [GridData]);

  // useEffect(() => {
  //   if (
  //     GridData &&
  //     Object.keys(GridData)?.length > 0 &&
  //     GridData?.storeAdditionalWhere[id] &&
  //     GridData?.storeAdditionalWhere[id].includes("Employee_Status = 'I%'")
  //   ) {
  //     setIsActive("I%");
  //   } else if (
  //     GridData &&
  //     Object.keys(GridData)?.length > 0 &&
  //     GridData?.storeAdditionalWhere[id] &&
  //     GridData?.storeAdditionalWhere[id].includes("Employee_Status = 'A%'")
  //   ) {
  //     setIsActive("A%");
  //   } else {
  //   }
  // }, [GridData]);
  useEffect(() => {
    if (ColumnsSorted && ColumnsSorted[id] && ColumnsSorted[id].length > 0) {
      setSelectedColumnsV2(ColumnsSorted[id]);
    } else if (
      GridData &&
      Object.keys(GridData)?.length > 0 &&
      GridData?.ColumnsSorted &&
      GridData?.ColumnsSorted[id] &&
      GridData?.ColumnsSorted[id]?.length > 0
    ) {
      setSelectedColumnsV2(GridData?.ColumnsSorted[id]);
    } else if (
      GridData &&
      Object.keys(GridData)?.length > 0 &&
      GridData?.storedColumns &&
      GridData?.storedColumns[id] &&
      GridData?.storedColumns[id]?.length > 0
    ) {
      setSelectedColumnsV2(GridData?.storedColumns[id]);
    } else if (selectedColumns && selectedColumns.length > 0) {
      setSelectedColumnsV2(selectedColumns);
    } else {
      setSelectedColumnsV2([]);
    }
  }, [GridData, ColumnsSorted, props?.selectedColumns]);
  React.useEffect(() => {
    setGridParams({ pageSize: pageSize, skip: skip, sort: sortData });
  }, [pageSize, skip, sortData]);
  React.useEffect(() => {
    let gridState = storeDataState[id];
    if (gridState) {
      setDataState(gridState);
    }
  }, [id]);
  React.useEffect(() => {
    let gridState = storeDataState[id] || { sort: sortData, group: group };
    if (gridState) {
      setDataState(gridState);
    }
  }, [storeDataState, id]);

  useEffect(() => {
    let pageSize = storePageSize[id];
    let skip = storeSkip[id];
    let Filter = storeFilter[id];
    let sort = storeSort[id];
    let storedSort;
    let storedFilter;
    let storedPageSize;
    let storedSkip;
    if (GridData && Object.keys(GridData).length > 0) {
      const {
        storeFilter: StoredFilter,
        storePageSize: StorePageSize,
        storeSkip: StoreSkip,
        StoredSort: storeSort,
        storeDataState: StoreDataState,
      } = GridData ?? {};
      storedSort =
        storeSort && storeSort[id] && storeSort[id]?.length > 0
          ? storeSort[id]
          : StoreDataState && StoreDataState[id] && StoreDataState[id]?.sort
          ? StoreDataState[id]?.sort
          : null;

      storedFilter = StoredFilter ? StoredFilter[id] : null;
      storedPageSize = StorePageSize ? StorePageSize[id] : null;
      storedSkip = StoreSkip ? StoreSkip[id] : null;
    }
    if (storedFilter || Filter) {
      const formattedDateVal = storedFilter?.filters?.map((d) =>
        d?.field.toLowerCase().includes("date")
          ? { ...d, value: new Date(d?.value) }
          : d
      );

      storedFilter = { filters: formattedDateVal ?? [], logic: "and" };
      setFilter(storedFilter ?? Filter);
    } else {
      setFilter(filter);
    }
    if (
      storedPageSize ||
      storedSkip ||
      storedSort ||
      pageSize ||
      skip ||
      sort
    ) {
      setGridParams({
        pageSize: (storedPageSize ?? pageSize) || 100,
        skip: storedSkip ?? skip,
        sort: (storedSort ?? sort) || sortData,
      });
    }
  }, [
    storeSort,
    storePageSize,
    storeSkip,
    storeFilter,
    storeAdditionalWhere,
    id,
    GridData,
  ]);
  useEffect(() => {
    if (
      id &&
      GridData &&
      Object.keys(GridData).length > 0 &&
      GridData?.storeDataState &&
      GridData?.storeDataState[id] &&
      GridData?.storeDataState[id]?.sort &&
      GridData?.storeDataState[id]?.group
    ) {
      setDataState(GridData?.storeDataState[id]);
    } else if (id && storeDataState && storeDataState[id]) {
      setDataState(storeDataState[id]);
    } else {
      setDataState({ sort: gridParams?.sort, group: group });
    }
  }, [id, storeDataState, GridData]);

  useEffect(() => {
    const initialGroupFiled = Array.isArray(storeDataState[id]?.group)
      ? []
      : []; // { field: sortData[0]?.field }
    const allGroupFiled = initialGroupFiled.concat(
      Array.isArray(storeDataState[id]?.group)
        ? storeDataState[id]?.group
        : group
    );
    setGroup(allGroupFiled);
  }, [storeDataState[id]?.group]);

  useEffect(() => {
    let data = {};
    data[id] = dataState;
    dispatch(SetGridDataState(data));
  }, [dataState]);
  const [employeeInfo, setEmployeeInfo] = useState({
    ID: null,
    NAME: "",
  });
  React.useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    selectedColumnsV2.map((item) =>
      item.minWidth !== undefined
        ? (minGridWidth.current += item.minWidth)
        : minGridWidth.current
    );
    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, []);
  const handleResize = () => {
    if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };
  console.log("storeAdditionalWhere", storeAdditionalWhere);
  const customTextFilterSearch = (props) => (
    <CustomTextFilter {...props} data={props.field} />
  );
  // const setWidth = (minWidth) => {
  //   minWidth = minWidth === undefined ? (minWidth = 0) : minWidth;
  //   let width = applyMinWidth
  //     ? minWidth
  //     : minWidth +
  //       (gridCurrent - minGridWidth.current) / selectedColumnsV2.length;
  //   width = width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
  //   return width;
  // };
  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };
  handleRadioChange = (e) => {
    setChooseFilterMethod(e.target.value);
  };
  const handleActiveChange = (e) => {
    const val = e.target.value;
    setIsActive(val);
    const obj = { ...activeResp };
    obj[id] = {
      ...obj[id],
      active: val,
      ID: id,
    };
    dispatch(storeActiveData(obj));
    let d = {};
    if (
      GridData &&
      GridData?.storeAdditionalWhere &&
      GridData?.storeAdditionalWhere[id]
    ) {
      const params = GridData?.storeAdditionalWhere[id].split("AND");
      const flag1 =
        params && params.filter((d) => !d.includes("Employee_Status"));
      const flag2 =
        params && params.filter((d) => d.includes("Employee_Status"));
      if (flag1 && flag2) {
        const mainParams = flag2[0].split("=");
        const params2 = flag1.join("AND");
        d[id] = mainParams[0] + "= '" + `${val}'` + "AND" + params2;
      } else {
        d[id] = "Employee_Status" + "= '" + `${val}'`;
      }
    }
    dispatch(
      gridDataTable({
        ...gridDataPayload,
        storeAdditionalWhere: {
          ...GridData?.storeAdditionalWhere,
          [id]: d[id],
        },
        activeResp: obj,
      })
    );
  };

  const updateLockColumn = async (col, status) => {
    let copyArray = [...selectedColumnsV2];
    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field === col;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    // debugger;
    if (copyObject) {
      copyObject["locked"] = status;
      copyArray[objectAtIndex] = copyObject;
      setSortedColumns(copyArray);
      dispatch(storeColumns({ ...ColumnsSorted, [id]: copyArray }));
      dispatch(
        gridDataTable({
          ...gridDataPayload,
          ColumnsSorted: {
            ...GridData?.storedColumns,
            [id]: copyArray,
          },
          storeDataState: {
            ...GridData?.storeDataState,
            [id]: {
              ...storeDataState[id],
              group: group,
              sort: gridParams?.sort,
            },
          },
        })
      );
    }
  };
  useEffect(() => {
    if (
      GridData &&
      Object.keys(GridData).length > 0 &&
      GridData?.storeDataState &&
      GridData?.storeDataState[id] &&
      GridData?.storeDataState[id]?.sort &&
      GridData?.storeDataState[id]?.group
    ) {
      processWithGroups(
        props.data,
        {
          sort: GridData?.storeDataState[id]?.sort,
          group: GridData?.storeDataState[id]?.group || [],
        } //|| storeDataState[id]
      );
    } else if (id && storeDataState && storeDataState[id]) {
      processWithGroups(props.data, storeDataState[id]);
    } else {
      processWithGroups(
        props.data,
        { sort: gridParams.sort, group: props.group || [] } //|| storeDataState[id]
      );
    }
  }, [id, props.data, GridData]);

  const dataStateChange = (event) => {
    const newDataState = processWithGroups(props.data, event.dataState);
    setResult({ data: newDataState.data });
    setDataState(event.dataState);
  };
  newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState,
  });
  useEffect(() => {
    if (selectedColumnsV2 && selectedColumnsV2.length) {
      const newDigitVars = {};
      selectedColumnsV2
        .filter((i) => i.show)
        .forEach((i) => {
          const field = i.field;
          if (field !== null && field !== undefined) {
            newDigitVars[field] = findMaxDigitsAmongFields(props.data, [field]);
          }
        });
      setDigitVars(newDigitVars);
    }
  }, [selectedColumnsV2, props.data]);

  const loaderStyle = {
    border: "2px solid #f3f3f3",
    borderTop: "2px solid #3498db",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupHeader") {
      if (cellProps.dataItem.field === "Department_name" && groupDepartment) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "100%",
              marginLeft: "2rem",
            }}
          >
            <h6 style={{ whiteSpace: "nowrap", margin: "0", padding: "0" }}>
              {cellProps.dataItem.value}
            </h6>
            {groupDepartmentLoader &&
            departmentModalContent?.[0]?.["Department_name"] ==
              cellProps?.dataItem?.value ? (
              <div>
                <style>{keyframesStyle}</style>
                <div style={loaderStyle}></div>
              </div>
            ) : (
              <Icon
                style={{ cursor: "pointer", fontSize: "20px" }}
                name="file-report"
                onClick={() => {
                  console.log(cellProps, "cellPropsGropu");
                  //this is group data(icon) trigger function
                  setDepartmentModalContent(cellProps?.dataItem?.items ?? []);
                }}
              />
            )}
          </div>
        );
      }
    }
    return tdElement;
  };

  const removeExpandedKey = (props) => {
    let tempData = JSON.parse(JSON.stringify(props?.dataItem));
    delete tempData["expanded"];
    return tempData;
  };
  const StoredSort = { ...storeSort };
  const gridDataPayload = { ...GridData };

  for (const obj in GridData) {
    if (GridData[obj].hasOwnProperty(id)) {
      if (gridSetting !== null && Object.keys(gridSetting).length > 0) {
        gridDataPayload[obj] = { ...gridSetting[obj], [id]: GridData[obj][id] };
      } else {
        gridDataPayload[obj] = { [id]: GridData[obj][id] };
      }
      gridDataPayload[obj] = GridData[obj];
    } else {
      gridDataPayload[obj] = GridData[obj];
    }
  }
  let GridComponent = (
    <>
      {workflowInfo?.workflowID > 0 && (
        <FullScreenWorkflowDialog
          id={workflowInfo?.workflowID}
          currentNodeText={workflowInfo?.CurrentStatus}
          employeeName={workflowInfo?.employeeName}
          hrControlNo={workflowInfo?.hrControlNo}
          onClose={() => {
            setWorkflowInfo({ workflowID: 0 });
          }}
          isOpened={workflowInfo?.workflowID > 0 ? true : false}
        />
      )}
      {loading && loadingPanel}
      <Grid
        // style={{
        //   maxHeight: "calc(100vh - 420px)",
        //   minHeight: "450px",
        // }}
        key={id}
        style={{ height: `calc(100vh - 240px)` }}
        data={newData}
        {...dataState}
        {...checkboxProps}
        reorderable={true}
        onColumnReorder={(e) => {
          setResult({ data: [] });
          let arr = [];
          e.target.columnResize?.columns.forEach((column) => {
            arr.push(column?.title);
          });
          const rearrangedArray = selectedColumnsV2.sort((a, b) => {
            const indexA = arr.indexOf(a.title);
            const indexB = arr.indexOf(b?.title);
            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            }
            if (indexA !== -1) {
              return -1;
            } else if (indexB !== -1) {
              return 1;
            }
            return 0;
          });
          setSelectedColumnsV2(rearrangedArray);
          // storedColumns[id] = rearrangedArray;
          dispatch(storeColumns({ ...ColumnsSorted, [id]: rearrangedArray }));
          dispatch(
            gridDataTable({
              ...gridDataPayload,
              ColumnsSorted: {
                ...GridData?.storedColumns,
                [id]: rearrangedArray,
              },
              storeDataState: {
                ...GridData?.storeDataState,
                [id]: {
                  ...storeDataState[id],
                  group: group,
                  sort: gridParams?.sort,
                },
              },
            })
          );
        }}
        group={group}
        onGroupChange={(evt) => {
          setResult({ data: [] });
          const formattedGroup =
            Object.values(
              evt?.group.reduce((a, b) => {
                if (!a[b.field]) a[b.field] = b;
                return a;
              }, {})
            ).map((obj) => {
              obj.aggregates = [];
              return obj;
            }) || [];
          let ungroupData = group.filter(
            (d) => !evt.group || !evt.group.find((c) => c?.field === d?.field)
          );

          if (typeof onGroupChange === "function") {
            onGroupChange(formattedGroup);
          }

          const GroupForSort =
            formattedGroup.map((obj) => {
              obj["dir"] = "asc";
              delete obj?.aggregates;
              return obj;
            }) || [];
          let storedGridPaSort = gridParams?.sort || [];
          storedGridPaSort = storedGridPaSort
            .filter(
              (d) =>
                !ungroupData || !ungroupData.find((c) => c?.field === d?.field)
            )
            .filter((d) => evt?.group.map((c) => c.field).indexOf(d.field) < 0);
          const concateGroupSort = GroupForSort.concat(storedGridPaSort);
          // setGridParams({ ...gridParams, sort: concateGroupSort });

          if (id) {
            dispatch(
              gridDataTable({
                ...gridDataPayload,
                StoredSort: { ...GridData?.StoredSort, [id]: concateGroupSort },
                storeDataState: {
                  ...GridData?.storeDataState,
                  [id]: {
                    ...storeDataState[id],
                    group: formattedGroup,
                    sort: concateGroupSort,
                  },
                },
              })
            );
            onSortChange(concateGroupSort);
          }
        }}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 1500],
          pageSizeValue: gridParams.pageSize,
          // style: { width: '200px' }
        }}
        expandField={expandField || "expanded"}
        onExpandChange={onExpandChange || expandChange}
        sortable={{
          allowUnsort: true,
          mode: "multiple",
        }}
        groupable={{
          footer: "visible",
        }}
        pageSize={gridParams.pageSize}
        total={totalCount}
        skip={gridParams.skip}
        take={gridParams.pageSize}
        filter={filter}
        lockGroups={true}
        onFilterChange={(props) => {
          setResult({ data: [] });
          const filterArr = props?.filter?.filters?.find((d) => d?.filters);
          const nonFiltersKeyObj = props?.filter?.filters?.find(
            (d) => !d?.filters
          );
          if (nonFiltersKeyObj && filterArr && filterArr?.filters) {
            filterArr?.filters.unshift(nonFiltersKeyObj);
          }
          const updatedFilter = filterArr ?? props?.filter;
          const formattedFilter = updatedFilter?.filters?.map((d) =>
            id === "employeemaster" && d?.field === "Emp_Status_Title"
              ? {
                  ...d,
                  operator: "eq",
                }
              : d
          );

          const updatedFilterArr = {
            filters: formattedFilter || [],
            logic: "and",
          };
          setFilter(updatedFilterArr);
          let additionalWhere = "";
          const params = toSQLExpression(updatedFilterArr);
          if (
            defaultAdditionalWhere &&
            defaultAdditionalWhere !== "" &&
            params &&
            params !== ""
          ) {
            additionalWhere = `${params}  AND  ${defaultAdditionalWhere}`;
          } else {
            additionalWhere = params;
          }
          onFilterChange(additionalWhere);

          if (id) {
            const where = {};
            where[id] = additionalWhere;
            dispatch(setAdditionalWhere(where));
            where[id] = updatedFilterArr;
            dispatch(SetGridFilter(where));

            const skip = {};
            skip[id] = 0;
            dispatch(setGridSkip(skip));
            dispatch(
              gridDataTable({
                ...gridDataPayload,
                storeAdditionalWhere: {
                  ...GridData?.storeAdditionalWhere,
                  [id]: additionalWhere,
                },
                storeDataState: {
                  ...GridData?.storeDataState,
                  [id]: {
                    ...storeDataState[id],
                    group: group,
                    sort: gridParams?.sort,
                  },
                },
                storeFilter: {
                  ...GridData?.storeFilter,
                  [id]: updatedFilterArr,
                },
              })
            );
          }
          return false;
        }}
        onSortChange={(props) => {
          setResult({ data: [] });
          if (id) {
            const d = {};
            d[id] = props.sort;
            dispatch(SetGridSort(d));
            dispatch(
              gridDataTable({
                ...gridDataPayload,
                StoredSort: { ...GridData?.StoredSort, [id]: props.sort },
                storeDataState: {
                  ...GridData?.storeDataState,
                  [id]: {
                    ...storeDataState[id],
                    group: group.map((d) => ({
                      ...d,
                      ["dir"]: props?.sort[0]?.dir,
                    })),
                    sort: d[id],
                  },
                },
              })
            );
          }
          setTimeout(() => {
            onSortChange(props.sort);
          }, 500);
        }}
        sort={gridParams.sort}
        onPageChange={(evt) => {
          setResult({ data: [] });
          if (id) {
            const skip = {};
            skip[id] = evt.page.skip;
            dispatch(setGridSkip(skip));
            const d = {};
            d[id] = evt.page.take;
            dispatch(
              gridDataTable({
                ...gridDataPayload,
                storeSkip: { ...GridData?.storeSkip, [id]: evt.page.skip },
                storePageNumber: {
                  ...GridData?.storePageNumber,
                  [id]: evt.page.skip / evt.page.take + 1,
                },
                storeDataState: {
                  ...GridData?.storeDataState,
                  [id]: {
                    ...storeDataState[id],
                    group: group,
                    sort: gridParams.sort,
                  },
                },
                storePageSize: {
                  ...GridData?.storePageSize,
                  [id]: evt.page.take,
                },
              })
            );

            let outerContainer = document.querySelector(".k-grid-content");
            // Scroll the outer container to the top
            if (outerContainer) {
              outerContainer.scrollTop = 0;
            }
          }
          onPageChange(evt);

          // window.scrollY(0, 0);
        }}
        // onPageSizeChange={(evt) => {
        //   debugger;

        //   if (id) {
        //     const d = {};
        //     d[id] = evt.target.value;
        //     dispatch(setGridPageSize(d));
        //     dispatch(
        //       gridDataTable({
        //         ...gridDataPayload,
        //         storeDataState: {
        //           ...GridData?.storeDataState,
        //           [id]: {
        //             ...storeDataState[id],
        //             group: group,
        //             sort: gridParams.sort,
        //           },
        //         },
        //         storePageSize: {
        //           ...GridData?.storePageSize,
        //           [id]: evt.target.value,
        //         },
        //       })
        //     );
        //   }
        //   onPageSizeChange(evt);

        //   let outerContainer = document.querySelector(".k-grid-content");
        //   // Scroll the outer container to the top
        //   if (outerContainer) {
        //     outerContainer.scrollTop = 0;
        //   }
        // }}
        filterable={
          // isDashboard === true || chooseFilterMethod === "normalSearch"
          //   ? true
          //   : false
          true
        }
        resizable={true}
        onDataStateChange={dataStateChange}
        cellRender={cellRender}

        // onDataStateChange={(e) => {
        //   setDataState(e.dataState);
        // }}
      >
        {filter && filter?.filters?.length > 0 && (
          <GridToolbar>
            <label>Filter By</label>

            {filter &&
              filter?.filters?.map((d, i) => {
                if (d?.field) {
                  let title = "";
                  const matchingColumn = JSON.parse(
                    JSON.stringify(selectedColumnsV2)
                  ).find((c) => c.field === d?.field);
                  if (matchingColumn && matchingColumn?.title) {
                    title = matchingColumn?.title;
                  }
                  return (
                    <Chip
                      key={`${i + 1}xy`}
                      style={{ margin: "8px" }}
                      text={` ${title || d?.field}   ${d?.operator}   ${
                        typeof d?.value === "object"
                          ? dayjs(d?.value).format("MM/DD/YYYY")
                          : d?.value
                      }`}
                      onRemove={(e) => {
                        const selectFilter = e.target?.props?.value;
                        const filteredFilterArr = filter?.filters.filter(
                          (d) => d?.field !== selectFilter
                        );
                        let additionalWhere = "";
                        const payloadFilterArr = {
                          logic: "and",
                          filters: filteredFilterArr,
                        };
                        const params = toSQLExpression(payloadFilterArr);
                        if (
                          defaultAdditionalWhere &&
                          defaultAdditionalWhere !== "" &&
                          params &&
                          params !== ""
                        ) {
                          additionalWhere = `${params}  AND  ${defaultAdditionalWhere}`;
                        } else {
                          additionalWhere = params;
                        }
                        onFilterChange(additionalWhere);
                        if (id) {
                          const where = {};
                          where[id] = additionalWhere;
                          dispatch(setAdditionalWhere(where));
                          where[id] = payloadFilterArr;
                          dispatch(SetGridFilter(where));

                          const skip = {};
                          skip[id] = 0;
                          dispatch(setGridSkip(skip));
                          dispatch(
                            gridDataTable({
                              ...gridDataPayload,
                              storeAdditionalWhere: {
                                ...GridData?.storeAdditionalWhere,
                                [id]: additionalWhere,
                              },
                              storeDataState: {
                                ...GridData?.storeDataState,
                                [id]: {
                                  ...storeDataState[id],
                                  group: group,
                                  sort: gridParams?.sort,
                                },
                              },
                              storeFilter: {
                                ...GridData?.storeFilter,
                                [id]: payloadFilterArr,
                              },
                            })
                          );
                        }
                        setFilter(payloadFilterArr);
                      }}
                      removable={true}
                      value={d?.field}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </GridToolbar>
        )}
        {gridParams && gridParams?.sort && gridParams?.sort.length > 0 && (
          <GridToolbar>
            <label>Sort By</label>
            {gridParams &&
              gridParams?.sort &&
              gridParams?.sort.map((d, i) => {
                if (d?.field) {
                  let title = "";
                  const matchingColumn = JSON.parse(
                    JSON.stringify(selectedColumnsV2)
                  ).find((c) => c.field === d?.field);
                  if (matchingColumn && matchingColumn?.title) {
                    title = matchingColumn?.title;
                  }
                  return (
                    <Chip
                      key={`${i + 1}chip`}
                      style={{ margin: "8px" }}
                      text={` ${title || d?.field}     ${d?.dir}`}
                      onRemove={(e) => {
                        const selectTag = e.target?.props?.value;
                        const filteredSort =
                          gridParams &&
                          gridParams?.sort &&
                          gridParams?.sort?.filter(
                            (d) => d?.field !== selectTag
                          );
                        const filteredGroup =
                          GridData &&
                          Object.keys(GridData)?.length > 0 &&
                          GridData?.storeDataState &&
                          GridData?.storeDataState[id] &&
                          GridData?.storeDataState[id]?.group
                            ? GridData?.storeDataState[id]?.group?.filter(
                                (d) => d?.field !== selectTag
                              )
                            : group && group?.length > 0
                            ? group?.filter((d) => d?.field !== selectTag)
                            : [];
                        setGroup(filteredGroup);

                        dataState.group = filteredGroup;

                        if (id) {
                          const d = {};
                          d[id] = filteredSort;
                          dispatch(SetGridSort(d));
                          dispatch(
                            gridDataTable({
                              ...gridDataPayload,
                              StoredSort: {
                                ...GridData?.StoreSort,
                                [id]: filteredSort,
                              },
                              storeDataState: {
                                ...GridData?.storeDataState,
                                [id]: {
                                  ...storeDataState[id],
                                  group: filteredGroup,
                                  sort: filteredSort,
                                },
                              },
                            })
                          );
                        }
                        onSortChange(filteredSort);
                      }}
                      value={d?.field}
                      removable={true}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </GridToolbar>
        )}
        {hideAllGridToolBar ? (
          ""
        ) : (
          <GridToolbar>
            <div style={{ float: "left", width: "50%" }}>
              <Tooltip title="Export Excel" placement="bottom">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  // onClick={excelExport}
                  onClick={() => {
                    setStartDownload(true);
                    handleExcelExport();
                  }}
                >
                  {loadingExcelReport && fileExtension === "xls" ? (
                    <Spinner size={24} />
                  ) : (
                    // <i className="fa-duotone fa-file font-primary"></i>
                    <Icon style={{ fontSize: "25px" }} name="file-excel" />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Export PDF" placement="bottom">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={() => {
                    setStartDownload(true);
                    exportPDF();
                  }}
                >
                  {loadingExcelReport && fileExtension === "pdf" ? (
                    <Spinner size={24} />
                  ) : (
                    // <i class="fa-duotone fa-file-pdf font-primary"></i>
                    <Icon style={{ fontSize: "25px" }} name="file-pdf" />
                  )}
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Export PDF" placement="bottom">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setStartDownload(true);
                  exportPDF();
                }}
              >
                <i class="fa-duotone fa-file-pdf font-primary"></i>

                
              </IconButton>
            </Tooltip> */}

              <ColumnFilter
                id={id}
                columns={selectedColumnsV2 || []}
                setSortedColumns={setSelectedColumnsV2}
                setResult={setResult}
                gridDataPayload={gridDataPayload}
              />
              {typeof moreGridTool === "function" &&
                moreGridTool(enableCheckboxes)}
            </div>
            {isDashboard === true ? (
              ""
            ) : (
              <div style={{ display: "flex" }}>
                {isPmValidation && (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="radio"
                      checked={
                        (activeResp && activeResp[id]?.active) || isActive
                      }
                      value={(activeResp && activeResp[id]?.active) || isActive}
                      onChange={handleActiveChange}
                    >
                      <FormControlLabel
                        value="A%"
                        control={<Radio />}
                        label={
                          <Typography variant="body1" fontWeight="bold">
                            Active
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="I%"
                        control={<Radio />}
                        label={
                          <Typography variant="body1" fontWeight="bold">
                            In Active
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {id != "PMRC_REMNEW" &&
                id !== "employeemaster" &&
                id != "gg1employeemaster" ? (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="radio"
                      checked={chooseFilterMethod ?? ""}
                      value={chooseFilterMethod ?? ""}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="advanceSearch"
                        control={<Radio />}
                        label={
                          <Typography variant="body1" fontWeight="bold">
                            Advance Search
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="normalSearch"
                        control={<Radio />}
                        label={
                          <Typography variant="body1" fontWeight="bold">
                            Normal Search
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                ) : null}
                {id === "vw_DocumentAttachmentForEmployee" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        value={enableCheckboxes}
                        onChange={(e) => {
                          setEnableCheckboxes(e.target.checked);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    }
                    label={
                      <Typography variant="body1" fontWeight="bold">
                        Enable Checkboxes
                      </Typography>
                    }
                  />
                )}
              </div>
            )}
            {hideSaveIcon ? null : (
              <div
                style={{ marginLeft: "auto", marginRight: "0" }}
                className="d-flex align-items-center w-full"
              >
                <i
                  style={{
                    fontSize: "30px",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  className={`k-icon k-i-reset font-primary`}
                  onClick={() => {
                    const localConfirmed = window.confirm(
                      "Are you sure you want to reset Locally?"
                    );

                    if (localConfirmed) {
                      const { sort, filter } = initialGridParams || {};
                      dispatch(
                        storeColumns({
                          ...ColumnsSorted,
                          [id]: [],
                        })
                      );
                      dispatch(
                        setGridPageSize({ ...storePageSize, [id]: 100 })
                      );
                      dispatch(setGridSkip({ ...storeSkip, [id]: 0 }));
                      dispatch(SetGridSort({ ...storeSort, [id]: sort ?? [] }));
                      dispatch(
                        SetGridFilter({
                          ...storeFilter,
                          [id]: { filters: [], logic: "and" },
                        })
                      );
                      setFilter({ filters: [], logic: "and" });
                      dispatch(
                        setAdditionalWhere({
                          ...storeAdditionalWhere,
                          [id]: filter ?? "",
                        })
                      );
                      // setGroup(props?.group ?? [])
                      onFilterChange(filter ?? "");
                      onSortChange(sort ?? []);
                      setGroup(props.group);
                      const gridDataPayload = { ...GridData };

                      for (const obj in GridData) {
                        if (GridData[obj].hasOwnProperty(id)) {
                          if (
                            gridSetting !== null &&
                            Object.keys(gridSetting).length > 0
                          ) {
                            delete GridData[obj][id];
                          } else {
                            delete GridData[obj][id];
                          }
                          gridDataPayload[obj] = GridData[obj];
                        } else {
                          gridDataPayload[obj] = GridData[obj];
                          gridDataPayload["storeDataState"] = {
                            ...GridData?.storeDataState,
                            [id]: {
                              group: props?.group ?? [],
                              sort: sort ?? [],
                            },
                          };
                        }
                      }

                      setGridParams({
                        skip: 0,
                        pageSize: 100,
                        sort: sortData,
                      });
                      dispatch(gridDataTable(gridDataPayload));

                      // setGridParams({
                      //   skip: 0,
                      //   pageSize: 100,
                      //   sort: sortData,
                      // });
                      ShowSucess("Reset grid state locally Successfully", {
                        position: "top-right",
                      });
                    }

                    const confirmed =
                      localConfirmed &&
                      window.confirm(
                        "Are you sure you want to reset Permanently?"
                      );
                    if (localConfirmed && confirmed) {
                      dispatch(
                        getStoredTheme({
                          gridSetting: JSON.stringify(gridDataPayload),
                        })
                      );
                      if (message) {
                        ShowSucess("Reset Successfully!", {
                          position: "top-right",
                        });
                      }
                    }
                  }}
                >
                  {" "}
                </i>
                <i
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  className={`k-icon k-i-save font-primary`}
                  onClick={() => {
                    if (storeAdditionalWhere && storeAdditionalWhere[id]) {
                      gridDataPayload["storeAdditionalWhere"] = {
                        ...GridData?.storeAdditionalWhere,
                        [id]: storeAdditionalWhere[id],
                      };
                    }
                    dispatch(
                      getStoredTheme({
                        gridSetting: JSON.stringify(gridDataPayload),
                      })
                    );
                    if (message) {
                      ShowSucess(message, {
                        position: "top-right",
                      });
                    }
                  }}
                >
                  {" "}
                </i>
              </div>
            )}
          </GridToolbar>
        )}
        {showCheckboxes && (
          <GridColumn
            filterable={false}
            field={"selected"}
            width={70}
            headerSelectionValue={
              newData.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
        )}
        {actions && !!actions.length && (
          <GridColumn
            // field="ID"
            title="Actions"
            sortable={false}
            width={
              actionsWidth
                ? actionsWidth
                : isEmployeeManagement
                ? "200px"
                : actions?.length <= 3
                ? `${actions?.length * 30 + 70}px`
                : `${actions?.length * 30}px`
            }
            filterCell={() => {
              return (
                !!isShowActionCheckBox && (
                  <Typography className="text-center">
                    <Tooltip
                      style={{ cursor: "pointer" }}
                      title={showActionCheckBoxTooltipText ?? "Select All"}
                      placement="bottom"
                    >
                      <Checkbox
                        size="small"
                        checked={
                          props?.data?.length > 0 &&
                          selectedCheckbox?.length == props?.data?.length
                        }
                        onChange={handleSelectAllCheckbox}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                )
              );
            }}
            footerCell={() => {
              if (enableFooterCell) {
                return (
                  <td>
                    <H6>Total:</H6>
                  </td>
                );
              } else {
                return null;
              }
            }}
            // filterable={false}
            // locked={true}
            cell={(props) => {
              if (props.rowType === "groupHeader") {
                return null;
              }
              if (props.rowType === "groupFooter") {
                return null;
              }
              return (
                <td
                  {...props}
                  style={{
                    ...props.style,
                    backgroundColor:
                      props?.dataItem?.IS_DELETED ||
                      props?.dataItem?.IsDeleted === true ||
                      props?.dataItem?.isDeleted ||
                      props?.dataItem?.isDeleted === true
                        ? "#ff8080"
                        : null, // Used for showing red color in Action Column when delete flag true
                  }}
                >
                  {isEmployeeManagement ? (
                    <div>
                      <FormControl sx={{ minWidth: 200 }}>
                        <Select
                          sx={{
                            width: "150px",
                            height: "30px",
                            marginTop: "7px",
                          }}
                          displayEmpty
                          value=""
                        >
                          <MenuItem disabled value="">
                            Select Action
                          </MenuItem>
                          {actions && actions.length
                            ? actions
                                .filter(
                                  (d) => {
                                    return (
                                      (d.onlyVisible === undefined ||
                                        (d.onlyVisible &&
                                          props.dataItem[d.onlyVisible] > 0)) &&
                                      (d.role === undefined ||
                                        d.role.includes(currentRole))
                                    );
                                  }
                                  // d.role.includes(userData?.profile?.role))
                                )
                                .map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      value={item.title}
                                      onClick={() => {
                                        if (!item.action) {
                                          switch (item.actionType) {
                                            case "workflow":
                                              setWorkflowInfo({
                                                workflowID:
                                                  props.dataItem.WorkflowID,
                                                CurrentStatus:
                                                  props.dataItem
                                                    .CurrentWorkflowStatus,
                                                employeeName:
                                                  props.dataItem.EmployeeID,
                                                hrControlNo:
                                                  props.dataItem.HRControlNo,
                                              });
                                              break;
                                            case "qrcode": {
                                              // debugger;
                                              handleModal(
                                                props.dataItem.id,
                                                props.dataItem.employeeName_FML
                                              );
                                              break;
                                            }
                                            default:
                                              break;
                                          }
                                        } else {
                                          item.action(props);
                                        }
                                      }}
                                    >
                                      {/* <FontAwesomeIcon
                                    style={{ marginRight: "7px" }}
                                    className={item.className}
                                    icon={item?.icon}
                                  /> */}
                                      <span
                                        style={{ marginRight: "7px" }}
                                        className={`${item?.icon?.iconName} font-primary`}
                                      ></span>

                                      {item?.title}
                                    </MenuItem>
                                  );
                                })
                            : ""}
                        </Select>
                      </FormControl>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        columnGap: "6px",
                        alignItems: "center",
                      }}
                    >
                      {actions &&
                        actions
                          .filter(
                            (d) =>
                              d.isVisible ? d.isVisible(props.dataItem) : true
                            // (d) =>
                            //   d.onlyVisible === undefined ||
                            //   (d.onlyVisible &&
                            //     props.dataItem[d.onlyVisible] > 0)
                          )
                          .map((item) =>
                            item?.render && enableCheckboxes ? (
                              item?.render(props, enableCheckboxes)
                            ) : item?.showAsButton ? (
                              <Btn
                                attrBtn={{
                                  color: "primary",
                                  onClick: () => {
                                    item?.action(props);
                                  },
                                  disabled: item?.disabled
                                    ? item?.disabled(props)
                                    : false,
                                }}
                              >
                                {item?.title}
                                <span
                                  className={`${
                                    ["delete", "Show Details"].includes(
                                      item?.title
                                    ) && props?.dataItem?.IS_DELETED === true
                                      ? null
                                      : item.icon?.iconName
                                  } font-primary`}
                                ></span>
                              </Btn>
                            ) : item?.isCheckbox ? (
                              <Checkbox
                                size="small"
                                checked={selectedCheckbox?.includes(
                                  checkboxKey
                                    ? props?.dataItem[checkboxKey]
                                    : JSON.stringify(removeExpandedKey(props))
                                )}
                                onChange={(e) => handleCheck(props, e)}
                                className={checkBoxClasses.root}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <Tooltip
                                style={{ cursor: "pointer" }}
                                title={item.title}
                                placement="bottom"
                              >
                                {item?.visible == undefined ||
                                (item?.arrayVisible &&
                                  item?.arrayVisible.includes(
                                    props.dataItem[item?.visible]
                                  )) ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!item.action) {
                                        switch (item.actionType) {
                                          case "workflow":
                                            setWorkflowInfo({
                                              workflowID:
                                                props.dataItem.WorkflowID,
                                              CurrentStatus:
                                                props.dataItem
                                                  .CurrentWorkflowStatus,
                                              employeeName:
                                                props.dataItem.EmployeeID,
                                              hrControlNo:
                                                props.dataItem.HrControlNo,
                                            });
                                            break;
                                          case "qrcode": {
                                            // debugger;
                                            handleModal(
                                              props.dataItem.id,
                                              props.dataItem.employeeName_FML
                                            );
                                            break;
                                          }
                                          default:
                                            break;
                                        }
                                      } else {
                                        item.action(props);
                                      }
                                    }}
                                    className={`${
                                      (["delete", "Show Details"].includes(
                                        item?.title
                                      ) &&
                                        props?.dataItem?.IS_DELETED) ||
                                      props?.dataItem?.IsDeleted === true
                                        ? null // used to hide Action columns Actions when isdeleted is true
                                        : item.icon?.iconName
                                    } 
                                      font-primary
                                      // ${
                                        props.dataItem?.donated_Status_Cde ==
                                        "A"
                                          ? "text-success"
                                          : "font-primary"
                                      }
                                        `}
                                    // className={item.className}
                                    // icon={

                                    // }
                                  ></span>
                                ) : (
                                  <span></span>
                                )}
                              </Tooltip>
                            )
                          )}
                    </div>
                  )}
                </td>
              );
            }}
          />
        )}

        {selectedColumnsV2 && selectedColumnsV2.length
          ? selectedColumnsV2
              .filter((i) => i.show)
              .map((i, index) => {
                const isShowColumnMenu = i.isShowColumnMenu ?? true;

                return (
                  <GridColumn
                    key={`col${index + 1}`}
                    headerClassName={i.headerClassName}
                    className={i.className}
                    field={i.field}
                    filter={i.filter}
                    locked={i.locked || false}
                    title={i.title}
                    width={i.minWidth || i.width}
                    minResizableWidth={100}
                    format={i.format}
                    editor={i.editor}
                    filterable={i?.filterable ?? true}
                    filterCell={
                      i?.customFilterType
                        ? (filterProps) =>
                            CustomFilterCell({ ...filterProps, ...i })
                        : undefined
                    }
                    footerCell={(props) => {
                      const { editor, format, className, title, field } = i;
                      if (enableFooterCell) {
                        if (
                          noFooterCellFields.find(
                            (d) =>
                              d.toLowerCase() === field.toLowerCase() ||
                              d.toLowerCase() === title.toLowerCase()
                          )
                        ) {
                          return <td></td>;
                        }
                        const sum = data.reduce((acc, current) => {
                          const fieldValue = current[field];
                          return typeof fieldValue === "number"
                            ? acc + fieldValue
                            : acc;
                        }, 0);

                        return (
                          <td
                            colSpan={props.colSpan}
                            style={{ paddingInline: "20px" }}
                            className={className || ""}
                          >
                            {!actions && props?.ariaColumnIndex === 1 ? (
                              <H6>Total:{sum === 0 ? null : sum}</H6>
                            ) : sum === 0 ? null : (
                              <H6
                                attrH6={{ style: { display: "inline-flex" } }}
                              >
                                {editor === "numeric" && format !== null
                                  ? renderNumberFormat(" ", sum)
                                  : sum % 1 !== 0
                                  ? sum.toFixed(2)
                                  : sum}
                              </H6>
                            )}
                          </td>
                        );
                      } else {
                        return null;
                      }
                    }}
                    // filterCell={customTextFilterSearch}
                    // filterCell={
                    // i.field === "ID" ||
                    // i.field === "EmployeeID" ||
                    // i.field === "employeeId" ||
                    // i.field === "EmpId" ||
                    // i.field === "empId" ||
                    // i.field === "emplId" ||
                    // i.field === "SSN" || i.field === "ssn" ? customTextFilterSearch : undefined}

                    cell={(props) => {
                      const field = props.field || "";
                      const editor = props.editor || "";
                      const format = props.format || "";
                      const value = props.dataItem[field] || "";
                      if (props.rowType === "groupHeader") {
                        return null;
                      }
                      if (props.rowType === "groupFooter") {
                        const obj = aggregates.find(
                          (agg) => agg.field === props.field
                        );
                        if (!!Object.keys(obj || {}).length) {
                          return (
                            <td
                              key={i.title}
                              style={{
                                ...props.style,
                                whiteSpace: "nowrap",
                              }}
                              className={props.className}
                            >
                              Total {obj?.field}: {obj?.prefixIcon}{" "}
                              {props.dataItem.aggregates[obj?.field][
                                obj?.aggregate
                              ].toFixed(obj?.precision || 0)}
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      }
                      return (
                        <td
                          key={i.title}
                          onClick={
                            isDashboard === true
                              ? () => handleClick(props)
                              : null
                          }
                          style={{
                            ...props.style,
                            whiteSpace: "nowrap",
                            minWidth: i.minWidth || "100px !important",
                            backgroundColor:
                              props?.dataItem?.IS_DELETED ||
                              props?.dataItem?.IsDeleted === true || // Used to show red Visile column if isdeleted is true
                              props?.dataItem?.isDeleted ||
                              props?.dataItem?.isDeleted === true
                                ? " #ff8080"
                                : null,
                          }}
                          className={props?.className || ""}
                        >
                          {editor === "numeric" &&
                          format !== null &&
                          value !== null &&
                          value !== undefined ? (
                            renderNumberFormat(" ", value, digitVars[field])
                          ) : editor === "date" ? (
                            value ? (
                              moment(value).format(format)
                            ) : (
                              "N/A"
                            )
                          ) : // editor === "date" && value !== null ? (
                          //   moment(value).format(format)
                          editor === "img" ? (
                            <img
                              alt="signature"
                              className={"sigImage m-auto"}
                              src={value || ""}
                            />
                          ) : field === "IS_DELETED" ||
                            BOOLEAN_FIELDS.includes(field) ? (
                            value === true ? (
                              "Yes"
                            ) : (
                              "No"
                            )
                          ) : field === "requestType" ? (
                            value?.toUpperCase()
                          ) : (field === "status" ||
                              field === "CurrentWorkflowStatus" ||
                              field === "processStatus" ||
                              BUTTON_TYPE_FIELDS.includes(field)) &&
                            value !== null ? (
                            <Button
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                                height: "35px",
                              }}
                              color="primary"
                              variant="contained"
                              className="bg-primary text-truncate w-100"
                            >
                              {value ? value : "N/A"}
                            </Button>
                          ) : (field === "Status" && editor == "Chips")  ? (
                            <Chip style={{color:["Verified","Rejected"].includes(value) ? "white" : "",background : value == "Verified" ? "green" : value == "Rejected" ? "red" : "" }} text={value ? value : "Pending"} size="small" colo /> 
                         
                      )  : ["IsHold"].includes(field) ? (
                            <Button
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                                height: "35px",
                              }}
                              color={value ? "error" : "primary"}
                              variant="contained"
                              className="text-truncate w-100"
                            >
                              {value ? "HOLD" : "HELD"}
                            </Button>
                          ) : CUSTOME_BUTTON_STATUS_FIELDS.includes(field) ? (
                            <Button
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "left",
                                height: "35px",
                              }}
                              color="primary"
                              variant="contained"
                              className="bg-primary"
                            >
                              {value == 1
                                ? "SUBMITTED"
                                : value == 2
                                ? "ACTIVE"
                                : "INACTIVE"}
                            </Button>
                          ) : STATUS_FIELDS.includes(field) ? (
                            value == true || value == "A" || value == 1 ? (
                              "Active".toUpperCase()
                            ) : value == false || value == "I" || value == 0 ? (
                              "Inactive".toUpperCase()
                            ) : (
                              "N/A"
                            )
                          ) : field === "VisibleFor" ? (
                            value === 2 ? (
                              "Shared"
                            ) : (
                              "Not Shared"
                            )
                          ) : value !== null && value !== "" ? (
                            value
                          ) : (
                            "N/A"
                          )}
                        </td>
                      );
                    }}
                    columnMenu={
                      isShowColumnMenu
                        ? (props) => (
                            <CustomMenu
                              locked={i.locked}
                              {...props}
                              onColumnLockToggle={(col, status) => {
                                updateLockColumn(col, status);
                              }}
                            />
                          )
                        : false
                    }
                  />
                );
              })
          : null}
      </Grid>
    </>
  );

  return (
    <>
      <ExcelExport data={props.data} ref={_export}>
        {selectedColumnsV2 && selectedColumnsV2.length
          ? selectedColumnsV2
              .filter((i) => i.show)
              .map((i) => (
                <ExcelExportColumn
                  key={i.field}
                  field={i.field}
                  title={i.title}
                />
              ))
          : null}
      </ExcelExport>
      <div>
        {/* {GridComponent} */}
        <PDFExport ref={pdfExportComponent}> {GridComponent} </PDFExport>
      </div>

      {openModal && (
        <div>
          <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0",
                }}
              >
                <h6 className="mt-2">
                  {employeeInfo?.NAME ? employeeInfo.NAME + " 'S QR Code" : ""}
                </h6>
                <div>
                  <IconButton onClick={handleModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <QRCodeModal employeeInfo={employeeInfo} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TelerikDataTable;
