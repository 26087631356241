import {get,post, remove,put} from "../api_helper"
import * as url from "../url_helper"

const baseURL = process.env.REACT_APP_API_DOTNET_URL;
export const getuserGroup=()=>get(`${baseURL}${url.GETAPI_UserGroup}`);
export const getsidebarmenuList=()=>get(`${baseURL}${url.GETAPI_SIDEBARMENU}`);
export const getsideMenuItems=()=>get(`${baseURL}${url.GET_MENUITEMS_API}`);
export const getsidebarmenuListByRole=(role)=>get(`${baseURL}${url.GETAPI_SIDEBARMENU}/${role}`);
export const getsidebarmenulookup=()=>get(`${baseURL}${url.SIDEBAR_LOOPUP}`);
export const getbyidsubmenusidebarmenu=(menuid)=>get(`${baseURL}${url.ONEALL_SIDEBAR_MENU}/${menuid}`);
export const createsidebarmenu=(payload)=>post(`${baseURL}${url.ONEALL_SIDEBAR_MENU}`,payload);
export const deletesidebarmenu=(id)=>remove(`${baseURL}${url.ONEALL_SIDEBAR_MENU}/${id}`);
export const updatesidebarmenu=({id,payload})=>put(`${baseURL}${url.ONEALL_SIDEBAR_MENU}/${id}`,payload);
export const updatesidebarmenusequnece=(payload)=>put(`${baseURL}${url.UPDATE_SIDEBARMENU_SEQUENCE_API}`,payload);