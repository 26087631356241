export const GET_PROCESSES = "GET_PROCESSES";
export const GET_PROCESSES_SUCCESS = "GET_PROCESSES_SUCCESS";
export const GET_PROCESSES_FAIL = "GET_PROCESSES_FAIL";

export const GET_NatureKeyValue="GET_NatureKeyValue";
export const GET_NatureKeyValue_SUCCESS = "GET_NatureKeyValue_SUCCESS";
export const GET_NatureKeyValue_FAIL = "GET_NatureKeyValue_FAIL";


export const GET_PROCESSES_STATUSLIST = "GET_PROCESSES_STATUSLIST";
export const GET_PROCESSES_STATUSLIST_SUCCESS = "GET_PROCESSES_STATUSLIST_SUCCESS";
export const GET_PROCESSES_STATUSLIST_FAIL = "GET_PROCESSES_STATUSLIST_FAIL";

export const GET_PROCESSES_STATUSLIST_BY_NOA = "GET_PROCESSES_STATUSLIST_BY_NOA";
export const GET_PROCESSES_STATUSLIST_BY_NOA_SUCCESS = "GET_PROCESSES_STATUSLIST_BY_NOA_SUCCESS";
export const GET_PROCESSES_STATUSLIST_BY_NOA_FAIL = "GET_PROCESSES_STATUSLIST_BY_NOA_FAIL";

export const GET_PROCESSES_SETTING = "GET_PROCESSES_SETTING";
export const GET_PROCESSES_SETTING_SUCCESS = "GET_PROCESSES_SETTING_SUCCESS";
export const GET_PROCESSES_SETTING_FAIL = "GET_PROCESSES_SETTING_FAIL";

export const SAVE_PROCESSES_SETTING = "SAVE_PROCESSES_SETTING";
export const SAVE_PROCESSES_SETTING_SUCCESS = "SAVE_PROCESSES_SETTING_SUCCESS";
export const SAVE_PROCESSES_SETTING_FAIL = "SAVE_PROCESSES_SETTING_FAIL";

export const GET_PROCESSES_DETAILS = "GET_PROCESSES_DETAILS";
export const GET_PROCESSES_DETAILS_SUCCESS = "GET_PROCESSES_DETAILS_SUCCESS";
export const GET_PROCESSES_DETAILS_FAIL = "GET_PROCESSES_DETAILS_FAIL";

export const PROCESS_UPDATE= "PROCESS_UPDATE";
export const PROCESS_UPDATE_SUCCESS = "PROCESS_UPDATE_SUCCESS";
export const PROCESS_UPDATE_FAIL = "PROCESS_UPDATE_FAIL";

export const PROCESS_COPY= "PROCESS_COPY";
export const PROCESS_COPY_SUCCESS = "PROCESS_COPY_SUCCESS";
export const PROCESS_COPY_FAIL = "PROCESS_COPY_FAIL";

export const PROCESS_CREATE= "PROCESS_CREATE";
export const PROCESS_CREATE_SUCCESS = "PROCESS_CREATE_SUCCESS";
export const PROCESS_CREATE_FAIL = "PROCESS_CREATE_FAIL";

export const PROCESS_DELETE= "PROCESS_DELETE";
export const PROCESS_DELETE_SUCCESS = "PROCESS_DELETE_SUCCESS";
export const PROCESS_DELETE_FAIL = "PROCESS_DELETE_FAIL";


export const PROCESS_STATUS_CREATE= "PROCESS_STATUS_CREATE";
export const PROCESS_STATUS_CREATE_SUCCESS = "PROCESS_STATUS_CREATE_SUCCESS";
export const PROCESS_STATUS_CREATE_FAIL = "PROCESS_STATUS_CREATE_FAIL";

export const PROCESS_STATUS_UPDATE= "PROCESS_STATUS_UPDATE";
export const PROCESS_STATUS_UPDATE_SUCCESS = "PROCESS_STATUS_UPDATE_SUCCESS";
export const PROCESS_STATUS_UPDATE_FAIL = "PROCESS_STATUS_UPDATE_FAIL";

export const PROCESS_STATUS_DELETE= "PROCESS_STATUS_DELETE";
export const PROCESS_STATUS_DELETE_SUCCESS = "PROCESS_STATUS_DELETE_SUCCESS";
export const PROCESS_STATUS_DELETE_FAIL = "PROCESS_STATUS_DELETE_FAIL";



export const BOTTLENECK_UPDATE = "BOTTLENECK_UPDATE";
export const BOTTLENECK_UPDATE_SUCCESS="BOTTLENECK_UPDATE_SUCCESS";
export const BOTTLENECK_UPDATE_FAILURE = "BOTTLENECK_UPDATE_FAILURE"
 