import React from "react";

const Header = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      className="header-template"
    >
      <div
        style={{
          // margin: "0 auto",
          maxWidth: "900px",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
          gap: "14px",
          alignItems: "end",
        }}
      >
        <div>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}DOA.png`}
            alt="DOA"
            style={{ width: "65px", height: "65px", marginBottom: "5px" }}
          />
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              margin: 0,
              lineHeight: 1.2,
              fontSize: "14px",
            }}
          >
            edward m. birn
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 600,
              lineHeight: 1.2,
              fontSize: "12px",
            }}
          >
            Director (Direktot)
          </p>
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              margin: 0,
              lineHeight: 1.4,
              fontSize: "12px",
            }}
          >
            Elizabeth T.Fisher
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 600,
              textWrap: "nowrap",
              lineHeight: 1.2,
              fontSize: "12px",
            }}
          >
            Deputy Director (Sigundo Direktot)
          </p>
        </div>

        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              fontSize: "1.7em",
              color: "rgb(44, 163, 120)",
              margin: 0,
              lineHeight: 1,
              // textWrap:"nowrap"
            }}
          >
            Department of administration
          </p>
          <p
            style={{
              fontWeight: 600,
              textTransform: "uppercase",
              fontSize: "1.2em",
              color: "rgb(99, 174, 144)",
              margin: 0,
              textWrap: "nowrap",
            }}
          >
            Dipattamenton atmenestrasion
          </p>
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: "rgb(28, 177, 74)",
              margin: 0,
            }}
          ></p>
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: "rgb(28, 177, 74)",
              margin: 0,
              textWrap: "nowrap",
              fontSize: "14px",
            }}
          >
            Human resources division
          </p>
          <p
            style={{
              color: "rgb(28, 177, 74)",
              margin: 0,
              fontSize: "14px",
              fontWeight: 700,
              textWrap: "nowrap",
              lineHeight: 1.4,
            }}
          >
            (Dibision Inadilanto yan Guinaha Para Taotao)
          </p>
          <p
            style={{
              color: "rgb(28, 177, 74)",
              margin: 0,
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: 1.4,
            }}
          >
            <span style={{ textWrap: "nowrap", lineHeight: 1 }}>
              Telephone (Telifon): (671) 475-1288/1103
            </span>
            <span style={{ textWrap: "nowrap", lineHeight: 1 }}>
              Fax (Faks): (671) 477-3671
            </span>
          </p>
        </div>

        <div>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}GuamSeal.png`}
            alt="GuamSeal"
            style={{ width: "47px", height: "auto", marginBottom: "5px" }}
          />
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              textWrap: "nowrap",
              margin: 0,
              lineHeight: 1.2,
              fontSize: "14px",
            }}
          >
            Lourdes a. leon guerrero
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 600,
              lineHeight: 1.2,
              fontSize: "12px",
            }}
          >
            Governor (Maga'haga)
          </p>
          <p
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              margin: 0,
              lineHeight: 1.4,
              fontSize: "12px",
            }}
          >
            joshua f. tenorio
          </p>
          <p
            style={{
              margin: 0,
              fontWeight: 600,
              textWrap: "nowrap",
              lineHeight: 1.2,
              fontSize: "12px",
            }}
          >
            Lt. Governor (Sigundo Maga'lahi)
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
