import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CLOSE_JOB_APPLICATION } from "../../../../store/Online-job-application/actionTypes";

const sliceName = "getDataBySSNSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const getDataBySSN = createAsyncThunk(
  `${sliceName}/getDataBySSN`,
  async (payload) => {
    let dataLoading = true;
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      console.log("data", payload);
      const getTransactionDataBySSN = await axios.get(
        `${process.env.REACT_APP_API_DOTNET_URL}/GG1/GetRransactionDataBySSN?ssn=${payload}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let transactionData;

      if (
        getTransactionDataBySSN.data &&
        getTransactionDataBySSN.status === 200
      ) {
        console.log("fghfd--", getTransactionDataBySSN);

        transactionData = getTransactionDataBySSN.data;
      }
      return {
        transactionData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const GetAutoPopulate = createAsyncThunk(
  `${sliceName}/GetAutoPopulate`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      const GetAutoPopulateData = await axios.get(
        `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/GetAutoPopulate/${payload}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let transactionData;

      if (GetAutoPopulateData.data && GetAutoPopulateData.status === 200) {
        console.log("GetAutoPopulateData--", GetAutoPopulateData);

        transactionData = GetAutoPopulateData.data;
      }
      return {
        transactionData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const getDataBySSNAndAction = createAsyncThunk(
  `${sliceName}/getDataBySSNAndAction`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      console.log("data", payload);
      const getTransactionDataBySSN = await axios.get(
        // `${process.env.REACT_APP_API_DOTNET_URL}/GG1/GetRransactionDataBySSNAndActnNo?employeeID=${payload.employeeID}&actnNo=${payload.action}`,
        // `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/GetTransactionData/${payload.employeeID}/${payload.action}`,
        `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/GetTransactionData/${payload.action}?employeeID=${payload.employeeID ? payload.employeeID : ""}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let transactionData;
      if (
        getTransactionDataBySSN.data &&
        getTransactionDataBySSN.status === 200
      ) {
        transactionData = getTransactionDataBySSN.data;
      }
      console.log("fghfd--", transactionData);
      return {
        transactionData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
 
export const getAuditHistoryData = createAsyncThunk(
  `${sliceName}/getAuditHistoryData`,
  async (data) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      const getAuditHistoryByActionNo = await axios.get(
        // `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/AuditTrail/${data.employeeId}/${data.actionNo}`,
        `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/AuditTrail/${data.actionNo}?employeeId=${data.employeeId ? data.employeeId : ""}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let HistoryData;
      if (getAuditHistoryByActionNo.data) {
        HistoryData = getAuditHistoryByActionNo.data;
      }
      console.log("fghfd--", HistoryData);
      return {
        HistoryData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
const getDataBySSNSlice = createSlice({
  name: sliceName,
  initialState: {
    TransactionsBySSN: {},
    TransactionsBySSNAndAction: {},
    
    loding: false,
    auditHistory: [],
    AutoPopulateData: {},
    error : null,
  },
  extraReducers: {
    [getDataBySSN.fulfilled]: (state, action) => {
      console.log("action ::::: ==> ", action);
      state.TransactionsBySSN = action.payload?.transactionData;
      state.loding = false;
    },
    [getDataBySSN.pending]: (state, action) => {
      state.TransactionsBySSN = {};
      state.loding = true;
    },
    [getDataBySSNAndAction.rejected]: (state, action) => {
      state.TransactionsBySSN = action.payload?.error;
      state.loding = false;
    },
    [getDataBySSNAndAction.fulfilled]: (state, action) => {
      
      console.log("action Tansactiongg1 ", action);
      if(action.payload?.error){
        state.error = 'reloaddataSSN&Action';
        state.loding = false;
      }else{
        state.TransactionsBySSNAndAction = action.payload.transactionData;
        state.loding = false;
        state.error = null
      }
    },
    [getDataBySSNAndAction.pending]: (state, action) => {
      state.TransactionsBySSNAndAction = {};
      state.loding = true;
    },
    [getDataBySSNAndAction.rejected]: (state, action) => {
      state.TransactionsBySSNAndAction = {};
      state.loding = false;
    },
    
    [getAuditHistoryData.fulfilled]: (state, action) => {
      state.auditHistory = action.payload.HistoryData;
      state.loding = false;
    },
    [getAuditHistoryData.pending]: (state, action) => {
      state.auditHistory = {};
      state.loding = true;
    },
    [getAuditHistoryData.rejected]: (state, action) => {
      state.auditHistory = {};
      state.loding = false;
    },
    [GetAutoPopulate.fulfilled]: (state, action) => {
      state.AutoPopulateData = action.payload.transactionData;
      state.loding = false;
    },
    [GetAutoPopulate.pending]: (state, action) => {
      state.AutoPopulateData = {};
      state.loding = true;
    },
    [GetAutoPopulate.rejected]: (state, action) => {
      state.AutoPopulateData = {};
      state.loding = false;
    },
  },
});

export default getDataBySSNSlice.reducer;
