import {
  GET_LEAVESSHAREFORM_DETAILS,
  GET_LEAVESSHAREFORM_SUCCESS,
  GET_LEAVESHAREFORM_FAILURE,
  LEAVESHAREFORM_DELETE,
  LEAVESHAREFORM_DELETE_SUCCESS,
  LEAVESHREFORM_DELETE_FAIL,
  LEAVESHAREFORM_UPDATE,
  LEAVESHAREFORM_UPDATE_SUCCESS,
  LEAVESHAREFORM_UPDATE_FAIL,
  LEAVESHAREFORM_CREATE,
  LEAVESHAREFORM_CREATE_SUCCESS,
  LEAVESHAREFORM_CREATE_FAIL,
  LEAVESHAREFORM_GETINFO,
  LEAVESHAREFORM_GETINFO_SUCCESS,
  LEAVESHAREFORM_GETINFO_FAIL,
} from "./actiontype";

const initialState = {
  detailsofLeave: null,
  leavedoanr: [],
  getleaveshareformData: {},
  employeeResponseData: [],
  loading: false,
  postingResult: {},

  error: null,
};

const LeaveshareFormReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_LEAVESSHAREFORM_DETAILS:
      state = { ...state, loading: true, error: null };
      break;
    case GET_LEAVESSHAREFORM_SUCCESS:
      return {
        ...state,
        detailsofLeave: action.payload,
        leavedoanr: action.payload,
        // processStatuses: action.payload.processStatuses,
        loading: false,
        error: null,
      };

    case GET_LEAVESHAREFORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LEAVESHAREFORM_GETINFO:
      state = { ...state, employeeResponseData: [], loading: true };
      break;
    case LEAVESHAREFORM_GETINFO_SUCCESS:
      state = {
        ...state,
        employeeResponseData: action.payload.map((d) => ({
          ...d,
          rowIdx: action.index,
        })),
        loading: false,
      };
      break;
    case LEAVESHAREFORM_GETINFO_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        employeeResponseData: [],
        loading: false,
      };
      break;
    case LEAVESHAREFORM_CREATE:
      state = {
        ...state,
        postingResult: {},
        loading: true,
        getleaveshareformData: {},
      };
      break;
    case LEAVESHAREFORM_CREATE_SUCCESS:
      state = {
        ...state,
        postingResult: action.payload,

        loading: false,
      };
      break;
    case LEAVESHAREFORM_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        loading: false,
      };
      break;
    case LEAVESHAREFORM_UPDATE:
      state = { ...state, loading: true };
      break;
    case LEAVESHAREFORM_UPDATE_SUCCESS:
      state = {
        ...state,
        postingResult: action.payload,
        loading: false,
      };
      break;
    case LEAVESHAREFORM_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    case LEAVESHAREFORM_DELETE:
      const leaveShareIdToDelete = action.payload;
      const updatedLeavedoanr = state.leavedoanr.filter(
        (donor) => donor.id !== leaveShareIdToDelete
      );
      return {
        ...state,
        leavedoanr: updatedLeavedoanr,
      };

    case LEAVESHAREFORM_DELETE_SUCCESS:
      return state;

    case LEAVESHREFORM_DELETE_FAIL:
      return state;

    default:
      state = { ...state };
      break;
  }
  return state;
};
export default LeaveshareFormReducer;
