import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Activity,
  Bell,
  CheckCircle,
  FileText,
  UserCheck,
} from "react-feather";
import { Link } from "react-router-dom";
import { LI, P, UL } from "../../../AbstractElements";

 let intervalId

const Notifications = (results) => {
  const[notification,setNotification]=useState([])

  const resultDependency = useMemo(()=>results.results.length?JSON.stringify(results):"",[results])

  // useEffect(() => {
    
  // }, [resultDependency]);

  useEffect(() => {
    if (results && results.results.length > 0) {
      setNotification(results.results);
    }
    //  intervalId = setInterval(() => {
       
    //   setNotification(results.results);
    // }, 5 * 60 * 1000);  

    // return () => clearInterval(intervalId);  
  }, [resultDependency]);
const timeAgo = (date) => {
  const currentDate = new Date();
  const notifiedDate = new Date(date);
  const timeDifference = currentDate - notifiedDate;
  const minutes = Math.floor(timeDifference / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else {
    return `${notifiedDate.toLocaleDateString()} ${notifiedDate.toLocaleTimeString()}`;
  }
};

  return (
    <Fragment>
    <LI attrLI={{ className: "onhover-dropdown" }}>
      <div className="notification-box">
        <Bell />
        <span className="dot-animated"></span>
      </div>
      <UL attrUL={{ className: "notification-dropdown onhover-show-div" }}>
        <LI>
          <P attrPara={{ className: "f-w-700 m-0" }}>
            You have {notification?.length} Notifications
            <span className="pull-right badge badge-primary badge-pill">
            {notification?.length}
            </span>
          </P>
        </LI>
        {notification?.map((item, index) => (
          <LI key={index} attrLI={{ className: "noti-primary" }}>
            <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
              <div className="media">
              
                <div className="media-body">
                  <P>{item?.HR_CONTROL}</P>
                  <span>{item.NotificationText}</span>
                  
                  <div style={{fontSize:"13px",marginTop:"2px"}}> {timeAgo(item.NotifiedOn)}</div>
                 
                </div>
              </div>
            </Link>
          </LI>
        ))}
      </UL>
    </LI>
  </Fragment>
    
  );
};

export default Notifications;
// <Fragment>
    //   <LI attrLI={{ className: "onhover-dropdown" }}>
    //     <div className="notification-box">
    //       <Bell />
    //       <span className="dot-animated"></span>
    //     </div>
    //     <UL attrUL={{ className: "notification-dropdown onhover-show-div" }}>
    //       <LI>
    //         <P attrPara={{ className: "f-w-700 m-0" }}>
    //           You have 3 Notifications
    //           <span className="pull-right badge badge-primary badge-pill">
    //             4
    //           </span>
    //         </P>
    //       </LI>
    //       <LI attrLI={{ className: "noti-primary" }}>
    //         <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
    //           <div className="media">
    //             <span className="notification-bg bg-light-primary">
    //               <Activity />
    //             </span>
    //             <div className="media-body">
    //               <P>Department submit report</P>
    //               <span>10 minutes ago</span>
    //             </div>
    //           </div>
    //         </Link>
    //       </LI>
    //       <LI attrLI={{ className: "noti-secondary" }}>
    //         <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
    //           <div className="media">
    //             <span className="notification-bg bg-light-secondary">
    //               <CheckCircle />
    //             </span>
    //             <div className="media-body">
    //               <P>Report Complete</P>
    //               <span>1 hour ago</span>
    //             </div>
    //           </div>
    //         </Link>
    //       </LI>
    //       <LI attrLI={{ className: "noti-success" }}>
    //         <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
    //           <div className="media">
    //             <span className="notification-bg bg-light-success">
    //               <FileText />
    //             </span>
    //             <div className="media-body">
    //               <P>Hours Generated</P>
    //               <span>3 hour ago</span>
    //             </div>
    //           </div>
    //         </Link>
    //       </LI>
    //       <LI attrLI={{ className: "noti-danger" }}>
    //         <Link to={`${process.env.REACT_APP_PREFIX_URL}app/email/mailbox`}>
    //           <div className="media">
    //             <span className="notification-bg bg-light-danger">
    //               <UserCheck />
    //             </span>
    //             <div className="media-body">
    //               <P>New Employee added</P>
    //               <span>6 hour ago</span>
    //             </div>
    //           </div>
    //         </Link>
    //       </LI>
    //     </UL>
    //   </LI>
    // </Fragment>