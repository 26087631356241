import React, { useEffect } from "react";
import store from "./store/index";
import axios from "axios";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import ChatProvider from "./_helper/Chat/ChatProvider";
import { AuthProvider, UserManager, useAuth } from "oidc-react";
import { WebStorageStateStore } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "./utils/authConst";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/styles";
import { IntlProvider,load } from "@progress/kendo-react-intl";
import { createTheme } from "@mui/material";
import "@progress/kendo-theme-material/dist/all.css";
import { ToastContainer } from "react-toastify";
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/en-AU/numbers.json';
import currencies from 'cldr-numbers-full/main/en-AU/currencies.json';
import caGregorian from 'cldr-dates-full/main/en-AU/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/en-AU/dateFields.json';

// import { setApiToken } from "./_helper/api_helper";
// import { useNavigate } from "react-router";

dateFields.main['en-AU'].dates.fields.day.displayName = 'dd';
dateFields.main['en-AU'].dates.fields.month.displayName = 'mm';
dateFields.main['en-AU'].dates.fields.year.displayName = 'yyyy';

caGregorian.main['en-AU'].dates.calendars.gregorian.dateFormats.short =
	'MM/dd/yyyy';

load(
	likelySubtags,
	currencyData,
	weekData,
	currencies,
	numbers,
	caGregorian,
	dateFields
);
const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  completeAuthentication: (user) => {
    return user;
  },
  metadata: {
    ...METADATA_OIDC,
  },
});

// userManager
//   .signinRedirectCallback()
//   .then((user) => {
//     if (user) {
//       if (user.profile.role !== "user" && user.profile.Application !== "HR") {
//         // window.location.replace("/unauthorized");
//         // alert("You are not an authorized user.");
//       } else {
//         localStorage.setItem("jwt_access_token", user.access_token);
//         localStorage.setItem("role", user.profile.role);
//         localStorage.setItem("authStatus", "SignedIn");
//         setApiToken(user.access_token);
//       }
//       localStorage.setItem("jwt_access_token", user.access_token);
//       localStorage.setItem("role", user.profile.role);
//       localStorage.setItem("authStatus", "SignedIn");
//       setApiToken(user.access_token);
//     }
//   })
//   .catch((err) => {
//     // history.push({ 'pathname': '/employeeManage' })
//     // history.push({ pathname: window.location.pathname });
//     // window.location.replace("/login");
//   });

// // userManager
// //   .signinRedirectCallback()
// //   .then((user) => {
// //     if (user) {
// //       localStorage.setItem("jwt_access_token", user.access_token);
// //       localStorage.setItem("role", user.profile.role);
// //       localStorage.setItem("authStatus", "SignedIn");
// //       setApiToken(user.access_token);

// //     }
// //   })
// //   .catch((err) => {
// //     // history.push({ 'pathname': '/employeeManage' })
// //     // history.push({ pathname: window.location.pathname });
// //     // window.location.replace("/login");
// //   });

const oidcConfig = {
  onSignIn: async (user) => {
    // alert("dd");
    localStorage.setItem("role", user?.profile?.role);
    localStorage.setItem("authStatus", "SignedIn");
    localStorage.setItem("jwt_access_token", user?.access_token);
    sessionStorage.setItem("jwt_access_token", user?.access_token);

  },
  loadUserInfo: true,
  filterProtocolClaims: true,
  userManager: userManager,
  automaticSilentRenew: true,
};

const App = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#E33E7F",
      },
      action: {
        selected: "#E33E7F",
      },
    },
    overrides: {
      MuiButtonBase: {
        root: {
          borderRadius: "2px",
          fontFamily: "Open Sans",
          fontSize: "0.875rem",
          fontWeight: 600,
          padding: "8px 24px", // I expect this padding to apply to all variants
          textTransform: "none",
          transition: "none",
        },
      },
    },
  });

  useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const role = urlParams.get('role');

		if (role) {
			localStorage.setItem("currentRole", role);
			sessionStorage.setItem("currentRole", role);
		}
	}, []);

  return (
    <div className="App">
      <Provider store={store}>
        <AnimationThemeProvider>
          <AuthProvider {...oidcConfig}  autoSignIn={false}>
            <CustomizerProvider>
              {/* {userData?.access_token && */}
              <IntlProvider locale="en-AU">
                {/* <ChatProvider> */}
                  <ThemeProvider theme={theme}>
                    <Routers />
                  </ThemeProvider>
                {/* </ChatProvider> */}
              </IntlProvider>
              {/* } */}
            </CustomizerProvider>
          </AuthProvider>
        </AnimationThemeProvider>
      </Provider>
      <ToastContainer />
    </div>
  );
};
export default App;
