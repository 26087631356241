import React, { Fragment, useState } from 'react';
import { Card } from 'reactstrap';
import { Btn, LI } from '../../../AbstractElements';
import { firebase_app } from '../../../Config/Config';
import { LogOut } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth  } from 'oidc-react';
import { LoadingPanel } from '../../../CommonComponents/CommonFunction';

const LogoutClass = () => {
    const history = useNavigate();
	const {signOutRedirect, userManager} = useAuth();
    const [loading, setLoading] = useState(false);

    const Logout = () => {
        // debugger;
        setLoading(true);
        localStorage.removeItem('profileURL');
        localStorage.removeItem('token');
		localStorage.removeItem('jwt_access_token');
        
        firebase_app.auth().signOut();

        localStorage.removeItem('auth0_profile');
        localStorage.removeItem('Name');
        localStorage.setItem('authenticated', false);
        sessionStorage.removeItem('currentRole');
        
        userManager.signoutRedirect().then(p=>{
            // debugger;
            console.log("logout",p);
        }).catch(err=>{
            console.log(err);
        }).finally(d=>{
            userManager.signinRedirect();
            setLoading(false)
        });

    };

    return (
        <Fragment>
            <LI attrLI={{ className: 'onhover-dropdown p-0', onClick: Logout }} >
                <Btn attrBtn={{ as: Card.Header, className: 'btn btn-primary-light', color: 'default' }} >
                   
                        <LogOut />
                        Log out
                    
                </Btn>
                {loading && <LoadingPanel position="fixed" />}
            </LI>
        </Fragment>
    );
};

export default LogoutClass;