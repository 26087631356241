import {
  GET_PROCESSES,
  GET_PROCESSES_SUCCESS,
  GET_PROCESSES_FAIL,
  GET_PROCESSES_DETAILS,
  GET_PROCESSES_DETAILS_SUCCESS,
  GET_PROCESSES_DETAILS_FAIL,
  PROCESS_CREATE,
  PROCESS_CREATE_FAIL,
  PROCESS_CREATE_SUCCESS,
  PROCESS_DELETE,
  PROCESS_DELETE_FAIL,
  PROCESS_DELETE_SUCCESS,
  PROCESS_UPDATE,
  PROCESS_UPDATE_FAIL,
  PROCESS_UPDATE_SUCCESS,
  GET_PROCESSES_SETTING,
  GET_PROCESSES_SETTING_SUCCESS,
  GET_PROCESSES_SETTING_FAIL,
  SAVE_PROCESSES_SETTING,
  SAVE_PROCESSES_SETTING_SUCCESS,
  SAVE_PROCESSES_SETTING_FAIL,
  PROCESS_COPY,
  PROCESS_COPY_SUCCESS,
  PROCESS_COPY_FAIL,
  PROCESS_STATUS_CREATE,
  PROCESS_STATUS_CREATE_SUCCESS,
  PROCESS_STATUS_CREATE_FAIL,
  PROCESS_STATUS_UPDATE,
  PROCESS_STATUS_UPDATE_SUCCESS,
  PROCESS_STATUS_UPDATE_FAIL,
  PROCESS_STATUS_DELETE,
  PROCESS_STATUS_DELETE_SUCCESS,
  PROCESS_STATUS_DELETE_FAIL,
  GET_PROCESSES_STATUSLIST,
  GET_PROCESSES_STATUSLIST_SUCCESS,
  GET_PROCESSES_STATUSLIST_FAIL,
  BOTTLENECK_UPDATE,
  BOTTLENECK_UPDATE_SUCCESS,
  BOTTLENECK_UPDATE_FAILURE, GET_NatureKeyValue, GET_NatureKeyValue_SUCCESS, GET_NatureKeyValue_FAIL
} from "./actionTypes";

const initialState = {
  processList: [],
  process: {},
  naturekeyvalue: [],
  processStatusList: [],
  postCreated: {},
  postingResult: {},
  loadingProcessList: false,
  loadingProcessDetails: false,
  processSetting: {},
  loadingProcessSetting: false,
  posting: false,
  bottleneck: {},
  loading: false,
  error: {
    message: "",
  },
};

const PostReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case BOTTLENECK_UPDATE:
      state = { ...state, bottleneck: {}, loading: true }
      break;
    case BOTTLENECK_UPDATE_SUCCESS:

      state = {
        ...state,
        bottleneck: action.payload,
        loading: false,

      }
      break;
    case BOTTLENECK_UPDATE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_PROCESSES:
      state = { ...state, loadingProcessList: true };
      break;
    case GET_PROCESSES_SUCCESS:
      state = {
        ...state,
        processList: action.payload,
        loadingProcessList: false,
      };
      break;
    case GET_PROCESSES_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingProcessList: false,
      };
      break;
    case GET_PROCESSES_STATUSLIST:
      state = { ...state, loadingProcessList: true };
      break;
    case GET_PROCESSES_STATUSLIST_SUCCESS:
      console.log("action", action);
      // debugger;
      state = {
        ...state,
        processStatusList: action.payload,
        loadingProcessList: false,
      };
      break;
    case GET_PROCESSES_STATUSLIST_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingProcessList: false,
      };
      break;
    case GET_PROCESSES_DETAILS:
      state = { ...state, loadingProcessDetails: true };
      break;
    case GET_PROCESSES_DETAILS_SUCCESS:
      state = {
        ...state,
        process: action.payload,
        loadingProcessDetails: false,
      };

    case GET_PROCESSES_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingProcessDetails: false,
      };
      break;

    case GET_NatureKeyValue:
      // state ={...state, loadingProcessDetails: true};
      state = { ...state, loading: true };
      break;
    case GET_NatureKeyValue_SUCCESS:
      console.log("action2", action);
      state = { ...state, naturekeyvalue: action.payload, loading: false };
      // state ={
      //   ...state,
      //   naturekeyvalue:action.payload,
      //   loadingProcessDetails:false,

      // }

      break;
    case GET_NatureKeyValue_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case PROCESS_CREATE:
      state = { ...state, postCreated: {}, posting: true };
      break;
    case PROCESS_CREATE_SUCCESS:
      state = { ...state, postCreated: action.payload, posting: false };
      break;
    case PROCESS_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postCreated: {},
        posting: false,
      };
      break;

    case PROCESS_STATUS_CREATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case PROCESS_STATUS_CREATE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case PROCESS_STATUS_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        posting: false,
      };
      break;
    case PROCESS_UPDATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case PROCESS_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case PROCESS_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case PROCESS_STATUS_UPDATE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case PROCESS_STATUS_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case PROCESS_STATUS_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case PROCESS_COPY:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case PROCESS_COPY_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case PROCESS_COPY_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case PROCESS_DELETE:
    case PROCESS_STATUS_DELETE:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case PROCESS_DELETE_SUCCESS:
    case PROCESS_STATUS_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case PROCESS_DELETE_FAIL:
    case PROCESS_STATUS_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    case GET_PROCESSES_SETTING:
      state = { ...state, loadingProcessSetting: true };
      break;
    case GET_PROCESSES_SETTING_SUCCESS:
      state = {
        ...state,
        processSetting: action.payload,
        loadingProcessSetting: false,
      };
      break;
    case GET_PROCESSES_SETTING_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingProcessSetting: false,
      };
      break;
    case SAVE_PROCESSES_SETTING:
      state = { ...state, postingResult: {}, posting: true };
      break;
    case SAVE_PROCESSES_SETTING_SUCCESS:
      state = { ...state, postingResult: action.payload, posting: false };
      break;
    case SAVE_PROCESSES_SETTING_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        posting: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostReducer;
