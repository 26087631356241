import { CREATE_UPDATE_EMPLOYEE_DEDUCTION, CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL, CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS, GET_ALL_DEDUCTION_METHOD_LIST, GET_ALL_DEDUCTION_METHOD_LIST_FAIL, GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS, GET_ALL_DEDUCTION_PLAN_MASTER, GET_ALL_DEDUCTION_PLAN_MASTER_FAIL, GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS, GET_ALL_PAYMENT_TYPE, GET_ALL_PAYMENT_TYPE_FAIL, GET_ALL_PAYMENT_TYPE_SUCCESS, GET_DEDUCTION_PLAN_DOCUMENTS, GET_DEDUCTION_PLAN_DOCUMENTS_FAIL, GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS, GET_DEDUCTION_TYPE, GET_DEDUCTION_TYPE_FAIL, GET_DEDUCTION_TYPE_SUCCESS, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS, RESET_DEDUCTION_CREATE, RESET_EMPLOYEE_DEDUCTION_PLAN } from "./actionTypes";

const initialState = {
	createResult: {},
	isCreating: false,
	createError: {},
	isFetching: false,
	employeeDeductionPlan: {},
	error: {},
	actualDeductionPlanList: [],
	isDeductionLoading: false,
	searchResults: [],
	loadingSearch: false,
	payPeriodList: [],
	isPayPeriodLoading: false,
	allEmployeeDeductionPlanMasterList: [],
	isEmployeeDeductionLoading: false,
	deductionDocuments: [],
	isDocumentLoading: false,
	isLoading: false,
	allDeductionMethod: [],
	allDeductionPlanMaster: [],
	allPaymentType: [],
	deductionType: [],
};

const EmployeeDeductionPlanReducer = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case CREATE_UPDATE_EMPLOYEE_DEDUCTION:
			state = { ...state, isCreating: true };
			break;
		case CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS:
			state = { ...state, createResult: action.payload, isCreating: false };
			break;
		case CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL:
			state = {
				...state,
				createResult: {},
				createError: action.payload?.error,
				isCreating: false,
			};
			break;

		case GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID:
			state = { ...state, isEmployeeDeductionLoading: true };
			break;
		case GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS:
			state = { ...state, employeeDeductionPlan: action.payload, isEmployeeDeductionLoading: false };
			break;
		case GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL:
			state = {
				...state,
				employeeDeductionPlan: {},
				error: action.payload?.error,
				isEmployeeDeductionLoading: false,
			};
			break;

		case GET_DEDUCTION_PLAN_DOCUMENTS:
			state = { ...state, isDocumentLoading: true };
			break;
		case GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS:
			state = { ...state, deductionDocuments: action.payload, isDocumentLoading: false };
			break;
		case GET_DEDUCTION_PLAN_DOCUMENTS_FAIL:
			state = {
				...state,
				deductionDocuments: {},
				createError: action.payload?.error,
				isDocumentLoading: false,
			};
			break;

		case GET_ALL_DEDUCTION_METHOD_LIST:
			state = { ...state, isLoading: true };
			break;
		case GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS:
			state = { ...state, allDeductionMethod: action.payload, isLoading: false };
			break;
		case GET_ALL_DEDUCTION_METHOD_LIST_FAIL:
			state = {
				...state,
				allDeductionMethod: {},
				error: action.payload?.error,
				isLoading: false,
			};
			break;

		case GET_ALL_DEDUCTION_PLAN_MASTER:
			state = { ...state, isLoading: true };
			break;
		case GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS:
			state = { ...state, allDeductionPlanMaster: action.payload, isLoading: false };
			break;
		case GET_ALL_DEDUCTION_PLAN_MASTER_FAIL:
			state = {
				...state,
				allDeductionPlanMaster: {},
				error: action.payload?.error,
				isLoading: false,
			};
			break;

		case GET_ALL_PAYMENT_TYPE:
			state = { ...state, isLoading: true };
			break;
		case GET_ALL_PAYMENT_TYPE_SUCCESS:
			state = { ...state, allPaymentType: action.payload, isLoading: false };
			break;
		case GET_ALL_PAYMENT_TYPE_FAIL:
			state = {
				...state,
				allPaymentType: {},
				error: action.payload?.error,
				isLoading: false,
			};
			break;

		case GET_DEDUCTION_TYPE:
			state = { ...state, isLoading: true };
			break;
		case GET_DEDUCTION_TYPE_SUCCESS:
			state = { ...state, deductionType: action.payload, isLoading: false };
			break;
		case GET_DEDUCTION_TYPE_FAIL:
			state = {
				...state,
				deductionType: {},
				error: action.payload?.error,
				isLoading: false,
			};
			break;

		case RESET_DEDUCTION_CREATE:
			state = {
				...state,
				createResult: {},
				createError: {},
				isCreating: false
			};
			break;

		case RESET_EMPLOYEE_DEDUCTION_PLAN:
			state = {
				...state,
				employeeDeductionPlan: {},
				isFetching: false,
				createResult: {},
				createError: {},
				isCreating: false
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default EmployeeDeductionPlanReducer;