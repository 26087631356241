export const visileColumns = [
  {
    title: "Control No",
    field: "HrControl",
    locked: false, //true,
    filter: "text",
    minWidth: 200,
    show: true,
  },
  {
    title: "SSN",
    field: "SSN",
    locked: false,
    filter: "text",
    minWidth: 200,
    show: true,
  },
  {
    title: "BBMR Analyst",
    field: "AnalystName",
    locked: false,
    filter: "text",
    minWidth: 200,
    show: true,
  },
  {
    title: "Analyst Days",
    field: "Last_Updated_BBMR_ANalystCreateOn",
    locked: false,
    filter: "text",
    minWidth: 200,
    show: true,
  },
  
  {
    title: "Employee ID",
    field: "EmployeeID",
    locked: false,
    filter: "numeric",
    customFilterType: "CustomNumericText",
    className: "text-end",
    // headerClassName: "number-align",
    minWidth: 190,
    show: true,
  },
  {
    title: "Employee Name",
    field: "EmployeeName_LFM",
    locked: false,
    filter: "text",
    minWidth: 250,
    show: true,
  },

  {
    title: "Nature Of Action",
    field: "NATURE_TITLE",
    locked: false,
    filter: "text",
    minWidth: 250,
    Width: "auto",
    show: true,
  },
  {
    title: "BBMR Analyst",
    field: "BBMR_Analyist",
    locked: false,
    filter: "text",
    minWidth: 250,
    Width: "auto",
    show: true,
  },
  {
    title: "Effective Date",
    field: "ActnEffDate",
    locked: false,
    filter: "date",
    minWidth: 200,
    show: true,
    format: "MM/DD/yyyy",
    editor: "date",
  },
  {
    title: "Action Date",
    field: "ActionDate",
    locked: false,
    filter: "date",
    minWidth: 200,
    show: true,
    format: "MM/DD/yyyy",
    editor: "date",
  },
  // {
  //   title: "Action No",
  //   field: "ActnNo", //"ActionNo",
  //   locked: false,
  //   filter: "test",
  //   className: "text-end",
  //   // headerClassName: "number-align",
  //   minWidth: 190,
  //   show: true,
  // },
  {
    title: "Status",
    field: "CurrentWorkflowStatus",
    locked: false,
    filter: "text",
    minWidth: 200,
    show: true,
  },
  {
    title: "Department",
    field: "DepartmentDivisionTitle",
    locked: false,
    filter: "text",
    minWidth: 250,
    show: true,
  },

  // {
  //   title: "Emp Type",
  //   field: "EmployeeType",
  //   locked: false,
  //   filter: "text",
  //   minWidth: 180,
  //   width: "auto",
  //   show: true,
  // },
];
