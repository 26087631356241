export const GET_POSITIONCLASS = "GET_POSITIONCLASS";
export const GET_POSITIONCLASS_SUCCESS = "GET_POSITIONCLASS_SUCCESS";
export const GET_POSITIONCLASS_FAIL = "GET_POSITIONCLASS_FAIL";

export const GET_POSITIONCLASS_DETAILS = "GET_POSITIONCLASS_DETAILS";
export const GET_POSITIONCLASS_DETAILS_SUCCESS = "GET_POSITIONCLASS_DETAILS_SUCCESS";
export const GET_POSITIONCLASS_DETAILS_FAIL = "GET_POSITIONCLASS_DETAILS_FAIL";

export const POSITIONCLAS_UPDATE= "POSITIONCLAS_UPDATE";
export const POSITIONCLAS_UPDATE_SUCCESS = "POSITIONCLAS_UPDATE_SUCCESS";
export const POSITIONCLAS_UPDATE_FAIL = "POSITIONCLAS_UPDATE_FAIL";

export const POSITIONCLAS_CREATE= "POSITIONCLAS_CREATE";
export const POSITIONCLAS_CREATE_SUCCESS = "POSITIONCLAS_CREATE_SUCCESS";
export const POSITIONCLAS_CREATE_FAIL = "POSITIONCLAS_CREATE_FAIL";

export const POSITIONCLAS_DELETE= "POSITIONCLAS_DELETE";
export const POSITIONCLAS_DELETE_SUCCESS = "POSITIONCLAS_DELETE_SUCCESS";
export const POSITIONCLAS_DELETE_FAIL = "POSITIONCLAS_DELETE_FAIL";
export const RESET = "RESET";

