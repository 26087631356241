const visileColumns = [
  // {
  //   title: "Social Security Number",
  //   field: "ssn",
  //   locked: false,
  //   filter: "text",
  //   width: 150,
  //   show: true,
  // },
  {
    title: "Emp ID",
    field: "ID",
    locked: false,
    filter: "numeric",
    className: "text-end",
    headerClassName: "number-align",
    customFilterType: "CustomNumericText",
    width: 160,
    show: true,
    // editor: "numeric",
  },

  {
    title: "Employee NAME",
    field: "EmployeeName_LFM",
    locked: false,
    filter: "text",
    width: 250,
    show: true,
  },
  {
    title: "Position Title",
    field: "NPosition_Title",
    locked: false,
    filter: "text",
    width: 300,
    show: true,
  },
  {
    title: "Department Div.",
    field: "Department_Title",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "Education",
    field: "Education_Title",
    locked: false,
    filter: "text",
    width: 180,
    show: true,
  },
  {
    title: "Status",
    field: "Emp_Status_Title",
    locked: false,
    filter: "text",
    width: 170,
    show: true,
  },
];

export default visileColumns;
