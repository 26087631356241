import React, { lazy } from "react";
import { SilentRenewComponent } from "../SilentRenewComponent";
import CreateEmployeeGG1 from "../Components/CGITrackingNew/CreateEmployeeGG1";
import LoadingDashboard from "../Components/Dashboard/Default/LoadingDashboard";
import SalaryIncrement from "../Components/performanceEvaluation/salaryIncrementNPA/Index";
import AgReport from "../Components/Reports/AgReports";
import LeaveShareTable from "../Components/EMR Reports/LeaveShareRequested";
import OverdueProbationary from "../Components/Reports/OverdueProbationaryReport";
import StaffingPattern from "../Components/Reports/StaffingPattern";
import AgNewReport from "../Components/Reports/AgNewAppoint";
import RetirementReport from "../Components/Reports/Retirement";
import Joborderdistribution from "../Components/LookupMaster/JobOrderDistribution/Index.js"
import AttritionReport from "../Components/Attrition/AttritionReport";
import NPADirectPrintFromList from "../Components/Records/NPAForms/NPADirectPrintFromList";
import NpaPdf from "../Components/Records/NPA/NpaPdf";
import GG1EmployeeMaster from "../Components/GG1EmployeeMaster";
import GG1MasterDetails from "../Components/payroll/GG1EmployeeMasterIndex";
import Notification from "../Components/Notifications/Notification.jsx";
import LaborCost from "../Components/LookupMaster/LaborCost/Index.js";
import BbmrMaster from "../Components/LookupMaster/BBMRMaster/Index.js"
import BbmrReport200 from "../Components/LookupMaster/BbmrReport200/Index.js"
import BbmrReport210 from "../Components/LookupMaster/BbmrReport210/Index.js"

import BBMRGG1List from "../Components/CGITrackingNew/BBMRGG1List.jsx";
import IndexNew from "../Components/StaffingPatternNew/IndexNew.js";
import BBMRStaffingPattern from "../Components/StaffingPatternNew/BBMRStaffingPattern.jsx";
import EnrollMentBenifitsMain from "../EnrollEmployee/EnrollEmployeeMain.js";
import BenefitsMaster from "../benefitsMaster/index.js";
import EmpBenefitMaster from "../EmployeeBenefit/index.js";
import BenefitDashboard from "../Components/Dashboard/BenefitDashboard/index.jsx";
import BenefitsDashboardMain from "../Components/BenefitDashboard/BenefitsDashboardMain.js";
// import EmployeeDeductions from "../Components/EmployeeDeductions";
const EmployeeExpiration = lazy(() =>
  import("../Components/Reports/EmployeeExpiration")
);
const PerformanceEvaluationNewListing = lazy(() =>
  import(
    "../Components/performanceEvaluation/PerformanceEvaluationNewListing.jsx"
  )
);
const DocumentPermission = lazy(() =>
  import("../Components/DocumentPermission/index.jsx")
);
const ProbationNotice = lazy(() =>
  import("../Components/Reports/ProbationNotice/index")
);
const NoticeReport = lazy(() =>
  import("../Components/Reports/OverdueProbationaryReport")
);
const PhysicalExaminationReport = lazy(() =>
  import("../Components/Reports/PhysicalExaminationReport")
);
const AdverseActionReport = lazy(() =>
  import("../Components/Reports/AdverseActionReport")
);
const EmployeeDeductions = lazy(() =>
  import("../Components/EmployeeDeductions/index")
);
const CreateUpdateEmployeeDeductionsMaster = lazy(() =>
  import("../Components/EmployeeDeductions/components/CreateUpdateEDM")
);
// const AddPerformanceEvaluation = lazy(() => import("../Components/AddPE"));

const Position = lazy(() => import("../Components/LookupMaster/Position"));
const CreateUpdateDocPermission = lazy(() =>
  import("../Components/DocumentPermission/CreateUpdateDocPermission")
);

const PositionAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Position/components/AddOrUpdate")
);
const BottleNeckAnalysis = lazy(() =>
  import("../Components/Charts/BottleNeckAnalysis")
);
const SideBar = lazy(() => import("../Components/Sidebar/Index"));
const CreateMenu = lazy(() => import("../Components/Sidebar/CreateMenu"));
const Education = lazy(() => import("../Components/LookupMaster/Education"));
const EducationAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Education/components/EducationAddOrUpdate")
);
const TrainingEditor = lazy(() =>
  import("../Components/TrainingDev/TrainingRegistry/components/TrainingEditor")
);
const EmployeeVerificationNew = lazy(()=>
  import ("../Components/LookupMaster/EmployeeVerification/Index.js")
)
const PositionClass = lazy(() =>
  import("../Components/Class&Comp/PositionClass")
);
const PositionClassEditor = lazy(() =>
  import(
    "../Components/Class&Comp/PositionClass/components/PositionClassEditor"
  )
);
const EthicGroup = lazy(() => import("../Components/LookupMaster/EthicGroup"));
const EthicGroupAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/EthicGroup/components/EthicGroupAddOrUpdate"
  )
);
const Department = lazy(() => import("../Components/LookupMaster/Department"));
const DepartmentAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/Department/components/DepartmentAddOrUpdate"
  )
);
const SystemAdmin = lazy(() => import("../Components/SystemAdmin"));
const EmployeeType = lazy(() =>
  import("../Components/LookupMaster/EmployeeType")
);
const TrainingRegistry = lazy(() =>
  import("../Components/TrainingDev/TrainingRegistry")
);
const JobAnnouncement = lazy(() =>
  import("../Components/LookupMaster/JobAnnouncement")
);
const JobApplications = lazy(() =>
  import("../Components/LookupMaster/JobApplications")
);
const JobAnnouncementAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/JobAnnouncement/components/JobAnnouncementAddOrUpdate"
  )
);
const EmployeeTypeAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/EmployeeType/components/EmployeeTypeAddOrUpdate"
  )
);
const Paygrade = lazy(() => import("../Components/LookupMaster/Paygrade"));
const PaygradeAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Paygrade/components/PaygradeAddOrUpdate")
);
const Paystep = lazy(() => import("../Components/LookupMaster/Paystep"));
const PaystepAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/Paystep/components/PaystepAddOrUpdate")
);
const PmrcNature = lazy(() => import("../Components/LookupMaster/PmrcNature"));
const PmrcAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PmrcNature/components/PmrcNatureAddOrUpdate"
  )
);
const IndividualDevelopmentPlan = lazy(() =>
  import("../Components/performanceEvaluation/IndividualDevelopmentPlan")
);
const WorkPlanning = lazy(() =>
  import("../Components/performanceEvaluation/WorkPlanning")
);
const DeductionPlans = lazy(() =>
  import("../Components/LookupMaster/DeductionPlans")
);
const DeductionPlansAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/DeductionPlans/components/DeductionPlansAddOrUpdate"
  )
);
const Vendor = lazy(() => import("../Components/LookupMaster/Vendor"));
const VendorAddorUpdate = lazy(() =>
  import("../Components/LookupMaster/Vendor/components/VendorAddOrUpdate")
);
const DeductionTypes = lazy(() =>
  import("../Components/LookupMaster/DeductionTypes")
);
const DeductiontypsAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/DeductionTypes/components/DeductionTypesAddOrUpdate"
  )
);

const Paytimecodes = lazy(() =>
  import("../Components/LookupMaster/PaytimeCodes")
);
const PaytimecodesAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PaytimeCodes/components/PaytimecodesAddOrUpdate"
  )
);
const PmrcSalaries = lazy(() =>
  import("../Components/LookupMaster/PmrcSalaries")
);
const PmrcSalariesAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/PmrcSalaries/components/PmrcSalariesAddOrUpdate"
  )
);
const Certifications = lazy(() =>
  import("../Components/LookupMaster/Certifications")
);
const CertificationsAddOrUpdate = lazy(() =>
  import(
    "../Components/LookupMaster/Certifications/components/CertificationsAddOrUpdate"
  )
);
const TagMaster = lazy(() => import("../Components/LookupMaster/TagMaster"));
const TagMasterAddOrUpdate = lazy(() =>
  import("../Components/LookupMaster/TagMaster/components/TagMasterAddOrUpdate")
);
const PrHistory = lazy(() => import("../Components/PrHistory"));
const PrHistoryAddOrUpdate = lazy(() =>
  import("../Components/PrHistory/components/PrHistoryAddOrUpdate")
);
const UnAuthorized = lazy(() => import("../Components/UnAuthorized"));
const WithoutLogin = lazy(() => import("../Components/WithoutLogin"));
const Logout = lazy(() => import("../Components/Logout/index"));
const MainMenu = lazy(() => import("../Components/MainMenu/index"));
// dashbaord
const EmployeeDepartment = lazy(() =>
  import("../Components/EmployeeDepartment/index.js")
);
const Ecommerce = lazy(() => import("../Components/Dashboard/Ecommerce"));
const Employee = lazy(() => import("../Components/Dashboard/Employee"));
const TransactionLogs = lazy(() => import("../Components/TransactionLog"));
const SignatureManagement = lazy(() =>
  import("../Components/SignatureManagement")
);
const EmployeeSignatureManagement = lazy(() =>
  import("../Components/SignatureManagement/Admin")
);
const DashBoardLog = lazy(() => import("../Components/viewDashboard"));
const TransactionLogDetails = lazy(() =>
  import("../Components/TransactionLog/details")
);
const Announcement = lazy(() => import("../Components/announcement"));
const ChatAppContain = lazy(() => import("../Components/Chat/ChatApp"));
const LeaveShare = lazy(() =>
  import("../Components/Leave/leaveShare/leaveShare")
);
const UpdateLeaveShare = lazy(() =>
  import("../Components/Leave/leaveShare/UpdateDonation")
);
const PayrollStagingList = lazy(() =>
  import("../Components/PayrollStaging/components/PayrollStagingList")
);
const LeaveApplicationWithFormik = lazy(() =>
  import("../Components/Leave/LeaveApplication")
);
const OutsideEmploymentWithFormik = lazy(() =>
  import("../Components/OutsideEmployment")
);
const EmployeeVerification = lazy(() =>
  import("../Components/EmployeeVarificationV2/component/EmployeeVerification")
);
const Reports = lazy(() => import("../Components/Reports/Reports"));
const PerformanceEvaluation = lazy(() =>
  import("../Components/performanceEvaluation/performanceEvaluation")
);
const OnlineRecruitment = lazy(() =>
  import("../Components/online-recruitment/online-recruitment")
);
const RecruitmentFormData = lazy(() =>
  import("../Components/online-recruitment/component/recruitment-form")
);
const NPAFORM = lazy(() =>
  import("../Components/Records/NPAForms/NPAPayroll/Index")
);
const JobApplicationFormData = lazy(() =>
  import("../Components/online-recruitment/component/jobApplicationForm")
);
const MyApplications = lazy(() =>
  import("../Components/online-recruitment/component/myapplications")
);
const PayrollMasterDetails = lazy(() =>
  import("../Components/payroll/payrollMasterDetails")
);
const MasterDetails = lazy(() => import("../Components/payroll/index"));

const DocumentManagement = lazy(() =>
  import("../Components/DocumentManagement")
);
const BenefitMasterDetail = lazy(() =>
  import("../Components/ins-enrollment/benefitMasterDetail")
);
const LifeEnrollDetail = lazy(() =>
  import("../Components/ins-enrollment/lifeEnrollDetail")
);
const HealthEnrollDetail = lazy(() =>
  import("../Components/ins-enrollment/healthEnrollDetail")
);
const DeathClaims = lazy(() => import("../Components/deathClaims/DeathClaims"));
const EmpDetails = lazy(() => import("../Components/AdvanceSearch"));
const PayrollWorkTime = lazy(() =>
  import("../Components/payroll/payrollWorkTime")
);
const GG1Details = lazy(() => import("../Components/CGITrackingNew/details"));
const Workflow = lazy(() => import("../Components/Workflow"));
const ManageWorkflow = lazy(() =>
  import("../Components/Workflow/ManageWorkflow")
);
const WorkflowConfiguration = lazy(() =>
  import("../Components/Workflow/WorkflowConfiguration")
);
const Process = lazy(() => import("../Components/Process/Index"));
const ProcessStatus = lazy(() =>
  import("../Components/Process/components/ProcessStatus")
);
const CreateTransaction = lazy(() =>
  import("../Components/Create Transaction/TransactionList")
);
const CreateTransactions = lazy(() =>
  import("../Components/Create Transaction/CreateTransactions")
);
const DashboardChart = lazy(() =>
  import("../Components/DashboardChart/Default")
); // import Default from "";
const Default = lazy(() => import("../Components/Dashboard/Default")); // import Default from "";
const GG1List = lazy(() => import("../Components/CGITrackingNew/GG1List"));
const GG1Request = lazy(() =>
  import("../Components/CGITrackingNew/GG1Request")
);

//Drug Testing
const BillingInvoiceLogLists = lazy(() =>
  import("../Components/DrugTesting/BillingInvoiceLog")
);
const CreateBillingInvoice = lazy(() =>
  import("../Components/DrugTesting/BillingInvoiceLog/CreateBillingInvoice")
);

const CarmensOwnDTLog = lazy(() =>
  import("../Components/DrugTesting/CarmensOwnDTLog")
);
const CreateCarmesOwnDTLog = lazy(() =>
  import("../Components/DrugTesting/CarmensOwnDTLog/CreateCarmesOwnDTLog")
);

const DTPMDataLog = lazy(() => import("../Components/DrugTesting/DTPMDataLog"));
const CreateDTPMDataLog = lazy(() =>
  import("../Components/DrugTesting/DTPMDataLog/CreateDTPMDataLog")
);

const TestDesignatedPositionLog = lazy(() =>
  import("../Components/DrugTesting/TestDesignatedPositionLog")
);
const CreateTestDesignatedPositionLog = lazy(() =>
  import(
    "../Components/DrugTesting/TestDesignatedPositionLog/CreateTestDesignatedPositionLog"
  )
);

const DepartmentRandomDateLog = lazy(() =>
  import("../Components/DrugTesting/DepartmentRandomDateLog")
);
const CreateDepartmentRandomDateLog = lazy(() =>
  import(
    "../Components/DrugTesting/DepartmentRandomDateLog/CreateDepartmentRandomDateLog"
  )
);

const TDPEmployeeMasterListLog = lazy(() =>
  import("../Components/DrugTesting/TDPEmployeeMasterListLog")
);
const CreateTDPEmployeeMasterListLog = lazy(() =>
  import(
    "../Components/DrugTesting/TDPEmployeeMasterListLog/CreateTDPEmployeeMasterListLog"
  )
);

const RandomDTLog = lazy(() => import("../Components/DrugTesting/RandomDTLog"));
const CreateRandomDTLog = lazy(() =>
  import("../Components/DrugTesting/RandomDTLog/CreateRandomDTLog")
);

const DepartmentsJVControlNumber = lazy(() =>
  import("../Components/DrugTesting/DepartmentsJVControlNumber")
);
const CreateDepartmentsJVControlNumber = lazy(() =>
  import(
    "../Components/DrugTesting/DepartmentsJVControlNumber/CreateDepartmentsJVControlNumber"
  )
);

const DFWPContactList = lazy(() =>
  import("../Components/DrugTesting/DFWPContactList")
);
const CreateDFWPContactList = lazy(() =>
  import("../Components/DrugTesting/DFWPContactList/CreateDFWPContactList")
);

const VenderLog = lazy(() => import("../Components/DrugTesting/VenderLog"));
const CreateVenderLog = lazy(() =>
  import("../Components/DrugTesting/VenderLog/CreateVenderLog")
);

const DOA_HRJVInternalLog = lazy(() =>
  import("../Components/DrugTesting/DOA-HRJVInternalLog")
);
const CreateHRJVInternalLog = lazy(() =>
  import("../Components/DrugTesting/DOA-HRJVInternalLog/CreateHRJVInternalLog")
);

const MISDataLog = lazy(() => import("../Components/DrugTesting/MISDataLog"));
const CreateMISDataLog = lazy(() =>
  import("../Components/DrugTesting/MISDataLog/CreateMISDataLog")
);

const EmployeeLeaveShareForm = lazy(() =>
  import("../Components/EMR/EmployeeLeaveShare/EmployeeLeaveShareForm")
);
const CreateEmployeeLeaveShare = lazy(() =>
  import("../Components/EMR/EmployeeLeaveShare/CreateEmployeeLeaveShare")
);

const PerformanceEvalution = lazy(() =>
  import("../Components/EMR/PerformanceEvaluation")
);
const CreatePerformanceEvalution = lazy(() =>
  import("../Components/EMR/PerformanceEvaluation/CreatePerformanceEvaluation")
);

const PerformanceEvalutionAppeal = lazy(() =>
  import("../Components/EMR/PerformanceEvaluationAppeal")
);
const CreatePerformanceEvalutionAppeal = lazy(() =>
  import(
    "../Components/EMR/PerformanceEvaluationAppeal/CreatePerformanceEvaluationAppeal"
  )
);

const OutsideEmploymentRecord = lazy(() =>
  import("../Components/EMR/OutsideEmploymentRecord")
);
const CreateOutsideEmploymentRecord = lazy(() =>
  import(
    "../Components/EMR/OutsideEmploymentRecord/CreateOutsideEmploymentRecord"
  )
);

const EMRTrainingLog = lazy(() => import("../Components/EMR/EMRTrainingLog"));
const CreateEMRTrainingLog = lazy(() =>
  import("../Components/EMR/EMRTrainingLog/CreateEMRTrainingLog")
);

const AdverseAction = lazy(() => import("../Components/EMR/AdverseAction"));
const CreateAdverseAction = lazy(() =>
  import("../Components/EMR/AdverseAction/CreateAdverseAction")
);

const GrievanceRequest = lazy(() =>
  import("../Components/EMR/GrievanceRequest")
);
const CreateGrievanceRequest = lazy(() =>
  import("../Components/EMR/GrievanceRequest/CreateGrievanceRequest")
);

const SpecialMedicalEvaluation = lazy(() =>
  import("../Components/EMR/SpecialMedicalEvaluation")
);
const CreateSpecialMedicalEvaluation = lazy(() =>
  import(
    "../Components/EMR/SpecialMedicalEvaluation/CreateSpecialMedicalEvaluation"
  )
);

const CollectiveBargainingAgreementRequest = lazy(() =>
  import("../Components/EMR/CollectiveBargainingAgreementRequest")
);
const CreateCollectiveBargainingAgreementRequest = lazy(() =>
  import(
    "../Components/EMR/CollectiveBargainingAgreementRequest/CreateCollectiveBargainingAgreementRequest"
  )
);

const LongTermDisability = lazy(() =>
  import("../Components/EMR/LongTermDisability")
);
const CreateLongTermDisability = lazy(() =>
  import("../Components/EMR/LongTermDisability/CreateLongTermDisability")
);

const ReduceWorkHourRecord = lazy(() =>
  import("../Components/EMR/ReduceWorkHourRecord")
);
const CreateReduceWorkHourRecord = lazy(() =>
  import("../Components/EMR/ReduceWorkHourRecord/CreateReduceWorkHourRecord")
);
const NPAList = lazy(() => import("../Components/Records/NPA"));
const CreateNPATransactions = lazy(() =>
  import("../Components/Records/NPAForms/NewCreateTransactions")
);
const Inprogress = lazy(() => import("../Components/Inprogress/index"));

const Charts = lazy(() => import("../Components/Charts"));

const AuditMasterLog = lazy(() => import("../Components/AuditMasterLog/index"));

const ExitSurvey = lazy(() => import("../Components/ExitSurvey/index"));
// const StaffingPatternNew =lazy(()=> import ("../Components/StaffingPatternNew/index"))
const ExitSurveyEmail = lazy(() =>
  import("../Components/ExitSurvey/ExitSurveyEmail")
);

const GeneratePaystub = lazy(() => import("../Components/GeneratePaystub"));

export const routes = [
  {
    path: `${process.env.REACT_APP_PREFIX_URL}silentrenew`,
    auth: ["user", "HRAdmin", "DepartmentAdmin"],
    Component: <SilentRenewComponent />,
  },
  //exit survey
  {
    path: `/exit-survey`,
    Component: <ExitSurvey />,
    exact: true,
    group: ["exit-survey"],
    layout: "none",
  },

  {
    path: `/exit-survey-email`,
    Component: <ExitSurveyEmail />,
    exact: true,
    group: ["exit-survey-email"],
    layout: "none",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/sanky`,
    group: ["dashboard/sanky`"],
    Component: <DashboardChart />,
    roles: ["Analyst"],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    group: ["dashboard/ecommerce"],
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/employee`,
    group: ["dashboard/employee"],
    Component: <Employee />,
  },
  // master details
  {
    path: `${process.env.PUBLIC_URL}/individual-development-plan`,
    group: ["individual-development-plan"],
    Component: <IndividualDevelopmentPlan />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/individual-development-plan/:employeeid/:id`,
    group: ["individual-development-plan"],

    Component: <IndividualDevelopmentPlan />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/work-planning/:employeeid/:id`,
    Component: <WorkPlanning />,
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail`,
    group: ["employee-master"],
    // Component: <PayrollMasterDetails />,
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail/new`,
    group: ["employee-master"],
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-detail/:employeeID`,
    group: ["employee-master"],
    Component: <MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1employeemaster-detail`,
    group: ["gg1employeemaster-detail"],
    // Component: <PayrollMasterDetails />,
    Component: <GG1MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1employeemaster-detail/new`,
    group: ["gg1employeemaster-detail"],
    Component: <GG1MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1employeemaster-detail/:employeeID`,
    group: ["gg1employeemaster-detail"],
    Component: <GG1MasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/announcement`,
    group: ["announcement"],
    Component: <Announcement />,
  },
  //   { path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatAppContain /> },
  {
    path: `${process.env.PUBLIC_URL}/share`,
    group: ["share"],
    Component: <LeaveShare />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication`,
    group: ["leaveapplication", "share"],
    Component: <LeaveApplicationWithFormik />,
  },
  // { path: `${process.env.PUBLIC_URL}/leave/:id`, Component: <LeaveShare /> },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment`,
    group: ["outside-employment"],
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id`,
    group: ["outside-employment"],
    Component: <OutsideEmploymentWithFormik />,
  },

  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id/:RecordId`,
    group: ["outside-employment"],
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payroll-work/:id`,
    Component: <PayrollWorkTime />,
  },

  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation`,
    group: ["performance-evaluation"],
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid`,
    Component: <PerformanceEvaluation />,
    group: ["performance-evaluation"],
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid/:id`,
    Component: <PerformanceEvaluation />,
    group: ["performance-evaluation"],
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "reviewer",
      "user",
      "appointingAuthority",
    ],
    // exact: true,
  },

  {
    path: "/online-recruitment",
    group: ["online-recruitment"],
    exact: true,
    Component: <OnlineRecruitment />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply-job/:id",
    group: ["online-recruitment"],
    Component: <JobApplicationFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply/:id/applied",
    group: ["online-recruitment"],
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  // {
  //   path: "/online-recruitment/apply/:id",
  //   Component: <RecruitmentFormData />,
  //   exact: true,
  // },
  {
    path: "/online-recruitment/edit/:id",
    group: ["online-recruitment"],
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/my-application",
    group: ["online-recruitment"],
    exact: true,
    Component: <MyApplications />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/employee-master",
    group: ["employee-master", "gg1"],
    exact: true,
    Component: <EmpDetails />,
    // _roles: ["Developer"],
  },
  {
    path:"/sendnotification",
    group:["sendnotification"],
    exact:true,
    Component : <Notification />

  },
  {
    path: "/gg1employee-master",
    group: ["employee-master", "gg1"],
    exact: true,
    Component: <GG1EmployeeMaster />,
    // _roles: ["Developer"],
  },
  {
    path: "/performance-evaluation-create",
    group: ["performance-evaluation-create"],
    exact: true,
    Component: <PerformanceEvaluationNewListing />,
    // _roles: ["Developer"],
  },
  {
    path: "/document-management",
    exact: true,
    group: ["document-management"],
    Component: <DocumentManagement />,
    // _roles: ["Developer"],
  },
  {
    path: `/transactionlist`,
    group: ["transactionlist"],
    _roles: ["HRAdmin", "DepartmentAdmin"],
    Component: <CreateTransaction />,
  },
  {
    path: "/ins-enrollment/death-claims/:id",
    Component: <DeathClaims />,
  },
  {
    path: "/ins-enrollment/benefit-master-detail",
    group: ["ins-enrollment/benefit-master-detail"],
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll",
    group: ["ins-enrollment/health-enroll"],
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll",
    group: ["ins-enrollment/life-enroll"],
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/benefit-master-detail/:id",
    group: ["ins-enrollment/benefit-master-detail"],
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment",
    Component: <BenefitMasterDetail />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll/:id",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll/:id",
    group: ["ins-enrollment/health-enroll"],
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/advance-search",
    exact: true,
    Component: <EmpDetails />,
  },
  {
    path: `${process.env.REACT_APP_PREFIX_URL}silentrenew`,
    roles: ["user", "HRAdmin", "DepartmentAdmin"],
    Component: <SilentRenewComponent />,
  },
  //dashboard
  {
    path: `${process.env.PUBLIC_URL}`,
    Component: <LoadingDashboard />,
  },
  {
    // path: `${process.env.PUBLIC_URL}/dashboard/default`,
    path: `/dashboard/default`,
    group: ["dashboard/default"],
    _roles: [
      "Developer",
      "user",
      "HRAdmin",
      "DepartmentAdmin",
      "reviewer",
      "appointingAuthority",
      "SuperUser",
      "BenefitsDepartmentManagement"
    ],
    Component: <Default />,
  },
  {
    // path: `${process.env.PUBLIC_URL}/dashboard/default`,
    path: `/dashboard/default`,
    roles: ["Analyst"],
    Component: <DashboardChart />,
  },
  {
    // path: `${process.env.PUBLIC_URL}/dashboard/default`,
    path: `/dashboard/default`,
    roles: ["BenefitsDepartmentManagement"],
    Component: <DashboardChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/employee`,
    Component: <Employee />,
  },
  // master details
  {
    path: `${process.env.PUBLIC_URL}/master-detail`,
    Component: <PayrollMasterDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking`,
    group: ["gg1-tracking"],
    Component: <GG1List />,
    _roles: ["Developer", "HRAdmin", "user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/bbmr-gg1-tracking`,
    group: ["bbmr-gg1-tracking"],
    Component: <BBMRGG1List />,
    _roles: ["Developer", "HRAdmin","BBMR-ANALYST", "BBMR-ADMIN","BBMR-SUPERVISOR","BBMR-DIRECTOR", "SUPER-USER"],
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/new-request`,
    Component: <GG1Request />,
    group: ["gg1-tracking"],
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/new-request/:ssn`,
    group: ["gg1-tracking1"],
    Component: <GG1Request />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/employeecreate-gg1/:ssn`,
    group: ["gg1-tracking"],
    Component: <CreateEmployeeGG1 />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/employeecreate-gg1`,
    group: ["gg1-tracking"],
    Component: <CreateEmployeeGG1 />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/payrollstaging`,
    Component: <PayrollStagingList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gg1-tracking/:employeeID/:actn_no`,
    group: ["gg1-tracking"],
    Component: <GG1Details />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction`,
    Component: <CreateTransactions />,
    group: ["gg1-tracking"],
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction/:employeeID`,
    group: ["gg1-tracking"],
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    // path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:actionNo`,
    path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:hrControl`,
    group: ["gg1-tracking"],
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transactionwithnoa/:employeeID/:noa`,
    group: ["gg1-tracking"],
    Component: <CreateTransactions />,
    _roles: ["Developer", "HRAdmin"],
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/transaction/:employeeID/:noa`,
  //   Component: <CreateTransactions />,
  //   _roles: ["Developer"],
  // },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log`,
    group: ["transaction-log"],
    Component: <TransactionLogs />,
    _roles: ["user"],
  },
  {
    path: `${process.env.PUBLIC_URL}/signature-management`,
    Component: <SignatureManagement />,
    _roles: [
      "SuperUser",
      "user",
      "HRAdmin",
      "Developer",
      "supervisor",
      "reviewer",
      "appointingAuthority",
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log/view-transaction/:employeeID/:actn_no`,
    Component: <TransactionLogDetails />,
    group: ["transaction-log"],
    _roles: ["Developer", "user", "HRAdmin"],
  },

  {
    path: `${process.env.PUBLIC_URL}/transaction-log/:parentPage/:employeeID`,
    Component: <TransactionLogs />,
    group: ["transaction-log"],
    _roles: ["Developer", "user", "HRAdmin"],
  },
  {
    path: `${process.env.PUBLIC_URL}/transaction-log/:employeeID`,
    Component: <TransactionLogs />,
    group: ["transaction-log"],
    _roles: ["Developer", "HRAdmin"],
  },

  {
    path: `${process.env.PUBLIC_URL}/view-dashboard/:employeeID`,
    Component: <DashBoardLog />,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/announcement`,
    Component: <Announcement />,
  },
  { path: `${process.env.PUBLIC_URL}/chat`, Component: <ChatAppContain /> },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication/:employeeID/:leaveid`,
    group: ["share"],
    Component: <LeaveApplicationWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leaveapplication/:employeeID`,
    group: ["share"],
    Component: <LeaveApplicationWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leave/leaveShare`,
    group: ["share"],
    Component: <LeaveShare />,
  },
  {
    path: `${process.env.PUBLIC_URL}/npa/payroll/:employeeId`,
    Component: <NPAFORM />,
  },
  // leave share by id
  {
    path: `${process.env.PUBLIC_URL}/leaveShare/:employeeId`,
    group: ["share"],
    Component: <LeaveShare />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leaveDetails/:leave`,
    group: ["share"],
    Component: <LeaveShare />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: `${process.env.PUBLIC_URL}/outside-employment/:id`,
    Component: <OutsideEmploymentWithFormik />,
  },
  {
    path: "/enrollment-benefits",
    exact: true,
    Component: <EnrollMentBenifitsMain />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/enrollment-benefits/:id",
    exact: true,
    Component: <EnrollMentBenifitsMain />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/enrollment-benefits/:id/:type",
    exact: true,
    Component: <EnrollMentBenifitsMain />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/enrollment-benefits/:id/:type/:empType",
    exact: true,
    Component: <EnrollMentBenifitsMain />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/benefits-list`,
    Component: <BenefitsMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/benefits-master`,
    Component: <EmpBenefitMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation`,
    group: ["performance-evaluation"],
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "user",
      "Developer",
      "supervisor",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/Salary-Increment/:HrControl`,
    group: ["performance-evaluation", "npa-records"],
    Component: <SalaryIncrement />,
    _roles: [
      "HRAdmin",
      "user",
      "Developer",
      "supervisor",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/npa/Salary-Increment/:employeeID/:HrControl`,
    group: ["npa-records"],

    Component: <SalaryIncrement />,
    _roles: [
      "HRAdmin",
      "user",
      "Developer",
      "supervisor",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/performance-evaluation/:employeeid`,
    group: ["performance-evaluation-create"],
    Component: <PerformanceEvaluation />,
    _roles: [
      "HRAdmin",
      "Developer",
      "supervisor",
      "user",
      "reviewer",
      "appointingAuthority",
    ],
    // exact: true,
  },
  {
    path: "/online-recruitment",
    exact: true,
    Component: <OnlineRecruitment />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/apply/:id",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/edit/:id",
    Component: <RecruitmentFormData />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/online-recruitment/my-application",
    exact: true,
    Component: <MyApplications />,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/ins-enrollment/death-claims/:id",
    Component: <DeathClaims />,
  },
  {
    path: "/ins-enrollment/benefit-master-detail",
    group: ["ins-enrollment/benefit-master-detail"],
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll",
    group: ["ins-enrollment/health-enroll"],
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/benefit-master-detail/:id",
    Component: <BenefitMasterDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment",
    Component: <BenefitMasterDetail />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
  },
  {
    path: "/ins-enrollment/life-enroll/:id",
    Component: <LifeEnrollDetail />,
    exact: true,
  },
  {
    path: "/ins-enrollment/health-enroll/:id",
    group: ["ins-enrollment/health-enroll"],
    Component: <HealthEnrollDetail />,
    exact: true,
  },
  {
    path: "/workflow",
    group: ["column-lookup"],
    Component: <Workflow />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path: "/system",
    path: `${process.env.PUBLIC_URL}/column-lookup`,
    group: ["column-lookup"],
    Component: <SystemAdmin />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path: "/system",
    path: `${process.env.PUBLIC_URL}/document-permission`,
    group: ["column-lookup"],
    Component: <DocumentPermission />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["SUPER-USER", "Developer"],
  },
  {
    path: "/document-permission/:id",
    group: ["column-lookup"],
    Component: <CreateUpdateDocPermission />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/document-permission/create",
    group: ["column-lookup"],
    Component: <CreateUpdateDocPermission />,
    exact: true,
    _roles: ["SUPER-USER", "Developer"],
  },
  {
    path: "/document-permission/:id/update",
    group: ["column-lookup"],
    Component: <CreateUpdateDocPermission />,
    exact: true,
    _roles: ["SUPER-USER", "Developer"],
  },
  {
    path: "/sidebar",
    group: ["sidebar"],
    Component: <SideBar />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/sidebar/create",
    group: ["sidebar"],
    Component: <CreateMenu />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/sidebar/update/:id",
    group: ["sidebar"],
    Component: <CreateMenu />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/sidebar/submenu/:menuid",
    group: ["sidebar"],
    Component: <CreateMenu />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/workflow/:id",
    group: ["column-lookup"],
    Component: <ManageWorkflow />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/workflow/configuration",
    group: ["column-lookup"],
    Component: <WorkflowConfiguration />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/process/:id",
    group: ["process"],
    Component: <ProcessStatus />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/process",
    group: ["process"],
    Component: <Process />, //React.lazy(() => import("../Components/ins-enrollment/benefitMasterDetail")),
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/position",
    group: ["column-lookup"],
    Component: <Position />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/inprogress",
    Component: <Inprogress />,
    group: ["inprogress"],
    exact: true,
    // _roles: ["Developer"],
  },
  {
    path: "/position/:id",
    group: ["column-lookup"],
    Component: <PositionAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/position/new",
    group: ["column-lookup"],
    Component: <PositionAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/jobannouncement/new",
    Component: <JobAnnouncementAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/jobannouncement/:ANNOUNCEMENT_ID",
    Component: <JobAnnouncementAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/jobapplications/:ANNOUNCEMENT_ID/applications",
    Component: <JobApplications/>,
    exact: true,
    _roles: ["Developer", "HRAdmin", "SUPER-USER"],
  },
  {
    path: "/jobapplications/",
    Component: <JobApplications/>,
    exact: true,
    _roles: ["Developer", "HRAdmin", "SUPER-USER"],
  },
  {
    path: "/education",
    group: ["column-lookup"],
    Component: <Education />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/educations/:id",
    group: ["column-lookup"],
    Component: <EducationAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/educations/new",
    group: ["column-lookup"],
    Component: <EducationAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup",
    group: ["column-lookup"],
    Component: <EthicGroup />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup/:id",
    group: ["column-lookup"],
    Component: <EthicGroupAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/ethicgroup/new",
    group: ["column-lookup"],
    Component: <EthicGroupAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision",
    group: ["column-lookup"],
    Component: <Department />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision/:id",
    group: ["column-lookup"],
    Component: <DepartmentAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/departmentdivision/new",
    group: ["column-lookup"],
    Component: <DepartmentAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employeetypes",
    path: "/employee-type",
    group: ["column-lookup"],
    Component: <EmployeeType />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employee-type/:id",
    path: "/employeetypes/:id",
    group: ["column-lookup"],
    Component: <EmployeeTypeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/employee-type/new",
    path: "/employeetypes/new",
    group: ["column-lookup"],
    Component: <EmployeeTypeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paygrade",
    Component: <Paygrade />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/lkpaygrades/:id",
    Component: <PaygradeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/lkpaygrades/new",
    Component: <PaygradeAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/paystep",
    group: ["column-lookup"],
    Component: <Paystep />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkPaySteps/:paY_GRADE/:paY_STEP",
    group: ["column-lookup"],
    Component: <PaystepAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkPaySteps/new",
    group: ["column-lookup"],
    Component: <PaystepAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrc-nature",
    group: ["column-lookup"],
    Component: <PmrcNature />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/pmrc-nature/:id",
    path: "/pmrcnatures/:id",
    group: ["column-lookup"],
    Component: <PmrcAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    // path:"/pmrc-nature/new",
    path: "/pmrcnatures/new",
    group: ["column-lookup"],
    Component: <PmrcAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans",
    Component: <DeductionPlans />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans/:code",
    Component: <DeductionPlansAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/deductionplans/new",
    Component: <DeductionPlansAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  // {
  //   path: "/vendors",
  //   Component: <Vendor />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/vendors/:id",
  //   Component: <VendorAddorUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/vendors/new",
  //   Component: <VendorAddorUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/deductiontypes",
  //   Component: <DeductionTypes />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/deductiontypes/:code",
  //   Component: <DeductiontypsAddOrUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/deductiontypes/new",
  //   Component: <DeductiontypsAddOrUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "paytimecodes",
  //   Component: <Paytimecodes />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/paytimecodes/:code",
  //   Component: <PaytimecodesAddOrUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  // {
  //   path: "/paytimecodes/new",
  //   Component: <PaytimecodesAddOrUpdate />,
  //   exact: true,
  //   _roles: ["Developer"],
  // },
  {
    path: "/pmrc-salaries",
    group: ["column-lookup"],
    Component: <PmrcSalaries />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrcsalaries/:grade/:step",
    group: ["column-lookup"],
    Component: <PmrcSalariesAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/pmrcsalaries/new",
    group: ["column-lookup"],
    Component: <PmrcSalariesAddOrUpdate />,

    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/certifications",
    group: ["column-lookup"],
    Component: <Certifications />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkCertifications/:code",
    group: ["column-lookup"],
    Component: <CertificationsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/LkCertifications/new",
    group: ["column-lookup"],
    Component: <CertificationsAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  // {
  // 	path: "/advance-search",
  // 	exact: true,
  // 	Component: <EmpDetails />,
  // }

  {
    path: "/tagmaster",
    Component: <TagMaster />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/Lktags/:code",
    Component: <TagMasterAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/Lktags/new",
    Component: <TagMasterAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },

  {
    path: "/legacy-payroll",
    Component: <PrHistory />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/legacy-payroll/:id",
    Component: <PrHistoryAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/legacy-payroll/new",
    Component: <PrHistoryAddOrUpdate />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/unauthorized",
    Component: <UnAuthorized />,
    exact: true,
    layout: "none",
  },
  {
    path: "/withoutlogin",
    Component: <WithoutLogin />,
    exact: true,
    layout: "none",
  },
  {
    path: "/logout",
    Component: <Logout />,
    exact: true,
    layout: "none",
  },
  {
    path: "/billing-invoice-log",
    group: ["billing-invoice-log"],
    Component: <BillingInvoiceLogLists />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/billing-invoice-log/new",
    group: ["billing-invoice-log"],

    Component: <CreateBillingInvoice />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/billing-invoice-log/:id",
    group: ["billing-invoice-log"],

    Component: <CreateBillingInvoice />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log",
    group: ["archival-dt-log"],
    Component: <CarmensOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log/new",
    group: ["archival-dt-log"],
    Component: <CreateCarmesOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/archival-dt-log/:id",
    group: ["archival-dt-log"],
    Component: <CreateCarmesOwnDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log",
    group: ["dtpm-data-log"],
    Component: <DTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log/new",
    group: ["dtpm-data-log"],
    Component: <CreateDTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dtpm-data-log/:id",
    group: ["dtpm-data-log"],
    Component: <CreateDTPMDataLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log",
    group: ["test-designated-position-log"],
    Component: <TestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log/new",
    group: ["test-designated-position-log"],
    Component: <CreateTestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/test-designated-position-log/:id",
    group: ["test-designated-position-log"],
    Component: <CreateTestDesignatedPositionLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log",
    group: ["department-random-date-log"],
    Component: <DepartmentRandomDateLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log/new",
    group: ["department-random-date-log"],
    Component: <CreateDepartmentRandomDateLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-random-date-log/:id",
    Component: <CreateDepartmentRandomDateLog />,
    group: ["department-random-date-log"],
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log",
    group: ["tdp-employee-master-list-log"],
    Component: <TDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log/new",
    group: ["tdp-employee-master-list-log"],
    Component: <CreateTDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/tdp-employee-master-list-log/:id",
    group: ["tdp-employee-master-list-log"],
    Component: <CreateTDPEmployeeMasterListLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log",
    group: ["random-dt-log"],
    Component: <RandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log/new",
    group: ["random-dt-log"],
    Component: <CreateRandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/random-dt-log/:id",
    group: ["random-dt-log"],
    Component: <CreateRandomDTLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number",
    group: ["department-jv-control-number"],
    Component: <DepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number/new",
    group: ["department-jv-control-number"],
    Component: <CreateDepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/department-jv-control-number/:id",
    group: ["department-jv-control-number"],
    Component: <CreateDepartmentsJVControlNumber />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list",
    group: ["dfwp-contact-list"],
    Component: <DFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list/new",
    group: ["dfwp-contact-list"],
    Component: <CreateDFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/dfwp-contact-list/:id",
    group: ["dfwp-contact-list"],
    Component: <CreateDFWPContactList />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/vendor-log",
    group: ["vendor-log"],
    Component: <VenderLog />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/vendor-log/new",
    group: ["vendor-log"],
    Component: <CreateVenderLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/vendor-log/:id",
    group: ["vendor-log"],
    Component: <CreateVenderLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log",
    group: ["doa-hr-jv-internal-log"],
    Component: <DOA_HRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log/new",
    group: ["doa-hr-jv-internal-log"],
    Component: <CreateHRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/doa-hr-jv-internal-log/:id",
    group: ["doa-hr-jv-internal-log"],
    Component: <CreateHRJVInternalLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log",
    group: ["mis-data-log"],
    Component: <MISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log/new",
    group: ["mis-data-log"],
    Component: <CreateMISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/mis-data-log/:id",
    group: ["mis-data-log"],
    Component: <CreateMISDataLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/sharess",
    Component: <EmployeeLeaveShareForm />,
    group: ["share"],
    exact: true,
    _roles: ["Developer"],
  },
  // {
  //   path: "/employee-leave-share",
  //   Component: <CreateEmployeeLeaveShare />,
  //   exact: true,
  //   roles: ["HRAdmin"],
  // },
  {
    path: "/employee-leave-share/new",
    Component: <CreateEmployeeLeaveShare />,
    group: ["share"],
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/employee-performance-evaluation",
    group: ["performance-evaluation"],
    Component: <CreatePerformanceEvalution />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/employee-performance-evaluation/new",
    group: ["performance-evaluation"],
    Component: <CreatePerformanceEvalution />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal",
    group: ["performance-evaluation"],
    Component: <PerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal/new",
    Component: <CreatePerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/performance-evaluation-appeal/:id",
    group: ["performance-evaluation"],
    Component: <CreatePerformanceEvalutionAppeal />,
    exact: true,
    _roles: ["HRAdmin", "Developer"],
  },
  {
    path: "/outside-employment-record",
    group: ["outside-employment-record"],
    Component: <CreateOutsideEmploymentRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/outside-employment-record/new",
    group: ["outside-employment-record"],
    Component: <CreateOutsideEmploymentRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log",
    group: ["training-log"],
    Component: <EMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log/new",
    group: ["training-log"],
    Component: <CreateEMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-log/:id",
    group: ["training-log"],
    Component: <CreateEMRTrainingLog />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action",
    group: ["adverse-action"],
    Component: <AdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action/new",
    group: ["adverse-action"],
    Component: <CreateAdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/adverse-action/:id",
    group: ["adverse-action"],
    Component: <CreateAdverseAction />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request",
    group: ["grievance-request"],
    Component: <GrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request/new",
    group: ["grievance-request"],
    Component: <CreateGrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/grievance-request/:id",
    group: ["grievance-request"],
    Component: <CreateGrievanceRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log",
    group: ["special-medical-evaluation-log"],
    Component: <SpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log/new",
    group: ["special-medical-evaluation-log"],
    Component: <CreateSpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/special-medical-evaluation-log/:id",
    group: ["special-medical-evaluation-log"],
    Component: <CreateSpecialMedicalEvaluation />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request",
    group: ["collective-bargaining-agreement-request"],
    Component: <CollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request/new",
    group: ["collective-bargaining-agreement-request"],
    Component: <CreateCollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/collective-bargaining-agreement-request/:id",
    group: ["collective-bargaining-agreement-request"],
    Component: <CreateCollectiveBargainingAgreementRequest />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/long-term-disability",
    group: ["long-term-disability"],
    Component: <LongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/long-term-disability/new",
    group: ["long-term-disability"],
    Component: <CreateLongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/staffing-pattern-2320133022",
    group: ["long-term-disability"],
    Component: <IndexNew />,
    exact: true,
    // _roles: ["Developer"],
    layout:"none"
  },
  {
    path: "/bbmr-staffing-pattern-2320133022",
    group: ["long-term-disability"],
    Component: <BBMRStaffingPattern />,
    exact: true,
    // _roles: ["Developer"],
    layout:"none"
  },
  {
    path: "/long-term-disability/:id",
    group: ["long-term-disability"],
    Component: <CreateLongTermDisability />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record",
    group: ["reduce-work-hour-record"],
    Component: <ReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record/new",
    Component: <CreateReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/reduce-work-hour-record/:id",
    group: ["reduce-work-hour-record"],
    Component: <CreateReduceWorkHourRecord />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/npa-records`,
    group: ["npa-records"],
    Component: <NPAList />,
    exact: true,
    _roles: [
      "Developer",
      "CERTIFYING-OFFICER",
      "SUPERVISOR-ADMIN",
      "AGENCY-DIRECTOR",
      "DEPT-ADMIN",
      "SUPER-USER",
      "SUPER-USER",
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/npa/transaction/:employeeID/:HrControl`,
    group: ["npa-records"],

    Component: <CreateNPATransactions />,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/chart`,
    Component: <Charts />,
    group: ["dashboard/chart"],
    roles: ["Analyst"],
  },
  {
    path: "/dashboard/rapid-g-track",
    Component: <BottleNeckAnalysis />,
    group: ["dashboard/chart"],
    exact: true,
    _roles: ["Analyst"],
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/employeeVerification/:employeeID`,
  //   Component: <EmployeeVerification />,
  //   exact: true,
  //   // layout: "none",
  // },
  {
    path: "/training-registry",
    group: ["training-registry"],
    Component: <TrainingRegistry />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/PdfDownload/:hrControl/:employeeID`,
    Component: <NpaPdf />,
    exact: true,

    layout: "none",
  },
  {
    path: "/poerequest",
    group: ["training-registry"],
    Component: <EmployeeVerificationNew />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/training-registry/:id",
    group: ["training-registry"],
    Component: <TrainingEditor />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/positionclass",
    group: ["PositionClass"],
    Component: <PositionClass />,
    exact: true,
    _roles: ["Developer"],
  },

  {
    path: "/positionclass/:id",
    group: ["PositionClass"],
    Component: <PositionClassEditor />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: "/JobAnnouncement",
    group: ["JobAnnouncement"],
    Component: <JobAnnouncement />,
    exact: true,
    _roles: ["Developer"],
  },
  {
    path: `${process.env.PUBLIC_URL}/audit-master-log`,
    group: ["audit-master-log"],
    Component: <AuditMasterLog />,
    _roles: ["HRAdmin", "Developer"],
  },
  { path: `mainmenu`, Component: <MainMenu /> },
  {
		auth: ["BBMR", "BBMR-ADMIN", "SUPER-USER"],
		path: `${process.env.PUBLIC_URL}/laborcost`,
		Component: <LaborCost />,
	},
  // {
	// 	auth: ["BenefitsDepartmentManagement"],
	// 	path: `${process.env.PUBLIC_URL}/dashboard/benefit`,
	// 	Component: <BenefitDashboard />,
	// },
  {
		auth: ["BBMR", "BBMR-ADMIN", "SUPER-USER"],
		path: `${process.env.PUBLIC_URL}/BbmrMaster`,
		Component: <BbmrMaster />,
	},
  {
		auth: ["BBMR", "BBMR-ADMIN", "SUPER-USER"],
		path: `${process.env.PUBLIC_URL}/BbmrReport200`,
		Component: <BbmrReport200 />,
	},
  {
		auth: ["BBMR", "BBMR-ADMIN", "SUPER-USER"],
		path: `${process.env.PUBLIC_URL}/BbmrReport210`,
		Component: <BbmrReport210 />,
	},
    {
		auth: ["BenefitsDepartmentManagement","BenefitsGovernmentWideManagement"],
		path: `${process.env.PUBLIC_URL}/dashboard/benefit`,
		Component: <BenefitsDashboardMain />,
	},
  {
    path: "/employee-deduction-plan",
    group: ["employee-deduction-plan"],
    Component: <EmployeeDeductions />,
    auth: ["user"],
  },
  {
    path: "/employee-deduction-plan/new",
    group: ["employee-deduction-plan"],
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user"],
  },
  {
    path: "/employee-deduction-plan/:id",
    group: ["employee-deduction-plan"],
    Component: <CreateUpdateEmployeeDeductionsMaster />,
    auth: ["user"],
  },
  {
    path: "/reports",
    group: ["reports"],
    Component: <Reports />,
    exact: true,
  },
  {
    path: "/reports/:category/:report",
    group: ["reports"],
    Component: <Reports />,
    exact: true,
  },
  {
    path: "/Expiration",
    group: ["Expiration"],
    Component: <EmployeeExpiration />,
    exact: true,
  },
  {
    path: "/probationNotice",
    group: ["probationNotice"],
    Component: <ProbationNotice />,
    exact: true,
  },
  {
    path: "/reportNotice",
    group: ["reportNotice"],
    Component: <OverdueProbationary />,
    exact: true,
  },
  {
    path: "/examinationReport",
    group: ["examinationReport"],
    Component: <PhysicalExaminationReport />,
    exact: true,
  },
  {
    path: "/agreport",
    group: ["agreport"],
    Component: <AgReport />,
    exact: true,
  },
  {
    path: "/adverseAction",
    group: ["adverseAction"],
    Component: <AdverseActionReport />,
    exact: true,
  },
  {
    path: "/leaveShareRequested",
    group: ["share"],
    Component: <LeaveShareTable />,
    exact: true,
  },
  {
    path: "/staffingPattern",
    group: ["staffingPattern"],
    Component: <StaffingPattern />,
    exact: true,
  },
  {
    path: "/AgNewReport",
    group: ["AgNewReport"],
    Component: <AgNewReport />,
    exact: true,
  },
  {
    path: "/RetirementReport",
    group: ["RetirementReport"],
    Component: <RetirementReport />,
    exact: true,
  },
  {
    path: "/joborderdistribution",
    group: ["RetirementReport"],
    Component: <Joborderdistribution />,
    exact: true,
  },
  {
    auth: ["user", "Developer"],
    path: `/pay-stub`,
    group: ["pay-stub"],
    Component: <GeneratePaystub />,
  },
  {
    path: "/UserDivisionAssignment",
    Component: <EmployeeDepartment />,
    auth: ["PR-CPO", "Developer"],
  },
  {
    // auth: ["user", "Developer"],
    path: `/reportV2/AttritionTable`,
    Component: <AttritionReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employee-signature-management`,
    Component: <EmployeeSignatureManagement />,
    _roles: ["SuperUser", "HRAdmin", "Developer"],
  },
];
