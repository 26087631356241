import { Button, ButtonGroup, Fab, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { memo, useEffect, useState } from 'react';
import { Handle, Position, NodeToolbar, useNodeId, useNodes, useNodesState, useUpdateNodeInternals, } from 'reactflow';
import { NodeResizer } from '@reactflow/node-resizer';
import Popover from '@mui/material/Popover';
const dragHandleStyle = {
	display: 'inline-block',
	width: 25,
	height: 25,
	backgroundColor: 'teal',
	marginLeft: 5,
	borderRadius: '50%',
};
const CustomNode = ({ data,selected, dragging, sourcePosition, targetPosition }) => {
	const info = data.info;
	const nodeID = useNodeId();
	const updateNodeInternals = useUpdateNodeInternals();
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
		if(info.description == '' || !info.description)
			return;
	  setAnchorEl(event.currentTarget);
	};
  
	const handlePopoverClose = () => {
		if(info.description == null || !info.description)
			return;
	  setAnchorEl(null);
	};
  
	const open = Boolean(anchorEl);
	const [size, setSize] = useState({width:150,height:50});
	useEffect(()=>{
		setSize({width:info.width,height: info.height});
		
	},[info])
	useEffect(()=>{
		updateNodeInternals(nodeID);
	},[size])
	return (
		<Stack justifyContent={'center'} sx={{ height: '100%' }} aria-owns={open ? `pop${nodeID}` : undefined}
		aria-haspopup="true"
		onMouseEnter={handlePopoverOpen}
		onMouseLeave={handlePopoverClose}>
			<NodeResizer 
			onResizeEnd={(e,param)=>{
					setSize({width:param.width,height:param.width})
			}}
			isVisible={(data.resizable === false) ? false : (selected === true ) ? true: false} />
				
			<Stack>
				<Handle type="source" className='nodrag' position={sourcePosition} />
				{/* <span className="custom-drag-handle" onClick={(e)=>{
					alert('d');
					e.target.style.backgroundColor = 'red';
				}} style={dragHandleStyle} /> */}
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					sx={{ height: '100%' }}
					//style={{justifyContent: 'center',width:'100%' }}
					style={{ justifyContent: 'center', width: '100%' }}
					
				//style={{ height: info.height + 'px', color: info.color, padding: '0 1px', justifyContent: 'center', width: info.width + 'px', background: info.background }} //, border: `1px solid ${info.borderColor}`
				>

					<Grid item xs={3}>
						<Stack direction={'row'} alignItems="center"
							justifyContent="center">
							{info.image &&
								<img src={info.image} width={64} height={64} alt="sample text" />
							}
							{info.icon &&
								<i className={`mx-2 fa-duotone fa-4x fa-${info.icon}`} />
							}
							{info.nodeType !=='group' &&
								<div >
								{info.title}
								</div>
							}
							
						</Stack>
					</Grid>

				</Grid>
				<Handle type="target" className='nodrag' position={targetPosition} />
			</Stack>
			{info.description !== null &&
			<Popover
			id={`pop${nodeID}`}
			sx={{
			  pointerEvents: 'none',
			}}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			  }}
			  transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			  }}
			onClose={handlePopoverClose}
			disableRestoreFocus
		  ><div style={{padding:'5px'}} dangerouslySetInnerHTML={{ __html: info.description }} />
		  </Popover>
			}
		</Stack>
	);
};

export default memo(CustomNode);
