import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Grid,
  GridColumn,
  GridColumnMenuWrapper,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { useForm } from "react-hook-form";
// import { useNavigate } from 'react-router-dom';
import { Btn } from "../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@progress/kendo-react-common";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import visileColumns from "./visibleColumns";
import { orderBy } from "@progress/kendo-data-query";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../../ErrorBoundry";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";
import { filterAllowedColumn } from "../../../_helper/helper";
import {
  faDiagramProject,
  faEye,
  faList,
  faPlusLarge,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";

import useSortedColumns from "../../../utils/useSortedColumns";

function LaborCost() {
  const dispatch = useDispatch();
  const {
    columns,
    loadingSearch,
    searchResults,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);

  const history = useNavigate();
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const id = "laborcost";
  const { pageSize: storePageSize } = useSelector(
    (state) => state.CommonReducer
  );
  const [searchParams, setSearchParams] = useState([]);
  const [page, setPage] = useState(1);
  const { userData } = useAuth();
  const [additionalWhere, setAdditionalWhere] = useState("");
  const { sort } = useSelector((state) => state?.CommonReducer) || {};
  const [sortData, setSortData] = useState([]);

  const [pageSize, setPageSize] = useState(storePageSize[id] || 1000);

  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);

  useEffect(() => {
    // console.log(searchResults);
  }, [searchResults]);
  const [sortedColumns, setSortedColumns] = useSortedColumns(
    id,
    ColumnsSorted,
    columns,
    visileColumns,
    userData
  );

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    setPageSize(100);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    setPageSize(100);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row className="bg-primary rounded py-3 mb-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
  <Typography variant="h5" style={{ textAlign: "left" }}>
  <Icon
                style={{ fontSize: "25px", margin: "10px" }}
                name="file-ascx"
              />
               Employee Labor Cost
  </Typography>
  <Typography variant="h6" style={{ textAlign: "right", marginLeft: "50px"  }}>
    
    Summary Cost by  DeptDiv, Employee, PPE
  </Typography>
</div>
            {/* <Typography variant="h5">
              <Icon
                style={{ fontSize: "25px", margin: "10px" }}
                name="file-ascx"
              />
               Labor Cost Distribution
            </Typography> */}
          </Row>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"BBMR_Labor_Cost_Distribution_Emp_Detail_D365"}
                    searchParams={searchParams}
                    additionalWhere={additionalWhere}
                    expanded={true}
                    sortData={sortData}
                    //  sortBy={sortData[0].dir}
                    //  orderBy={sortData[0].field}
                    pageNumber={page}
                    pageSize={pageSize}
                    deleteData={deleteData}
                    chooseFilterMethod={chooseFilterMethod}
                  />
                  <Accordion expanded={expandedSearchResult}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      className="bg-light"
                      onClick={() =>
                        setexpandedSearchResult(!expandedSearchResult)
                      }
                    >
                      <Typography variant="h6">Search Results</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* {loadingSearch && loadingPanel} */}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            id={id}
                            pageSize={pageSize}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            sortData={sortData}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            // actions={actions}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                            enableFooterCell={true}
                            aggregates={[
                              {
                                field: "RATE",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "GROSS",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "Ppe_Total_Deductions",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "HEALTH_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "DENTAL_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "GOVT_LIFE_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "DC_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "HP_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "GOVT_NON_BASE_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "GOC_DC_AND_UNF",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "MEDICARE_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "HEALTH_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "DENT_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "LIFE_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "HP_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "DC_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "DB_GOVT_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                              {
                                field: "MEDICARE_EMP_CONTR",
                                aggregate: "sum",
                                format: "c2",
                                prefixIcon: "$",
                                precision: 2,
                              },
                            ]}
                          ></TelerikDataTable>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
}
export default LaborCost;
