import React, { useState } from "react";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import { Btn } from "../../../AbstractElements";
// import ComponentToView from "./ComponentToView";
import ComponentToPrint from "../../Create Transaction/ComponentToPrint";
import NPAComponentPrint from "./NPAComponentPrint";
import NPADirectPrint from "./NPADirectPrint";
import NPADirectPrintSalaryIncrement from "./NPADirectPrintSalaryIncrement";
import EmployeeSignature from "../../../CommonComponents/EmployeeSignature";

const NPAVIEWPrint = (props) => {
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);
  return (
    <div>
      {/* <Dialog open={props.isOpen} onClose={props.handleClose}>
        <DialogActions></DialogActions>
        <DialogTitle>GG1</DialogTitle>
        <ComponentToView />
      </Dialog> */}
      <Dialog
        maxWidth="md"
        open={props.isOpen}
        onClose={props.handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>NPA</div>
            <div className="d-flex justify-content-end ">
              <Btn
                attrBtn={{
                  className: "m-1",
                  color: "primary",
                  onClick: () => {
                      props.setPrintState({value: "Print NPA",label: "Print NPA"}) 
                      props.handleClose();
                       
                        props.handlePrint();
                       
                  },
                }}
              >
                Print
              </Btn>
              <Btn
                attrBtn={{
                  className: "m-1",
                  color: "primary",
                  onClick: () => {
                      props.setPrintState({value: "Print NPA Redacted",label: "Print NPA Redacted"}) 
                      props.handleClose();
                       
                        props.handlePrint();
                       
                  },
                }}
              >
                Print Redacted
              </Btn>

              <Btn
                attrBtn={{
                  className: "m-1",
                  color: "primary",
                  onClick: ()=>{
                    console.log("123123 ::: signatureData ",props?.signData)
                    props.handleClose()
                  },
                }}
              >
                Close
              </Btn>
            </div>{" "}
          </div>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
            {console.log("123123 ::: Salary Increment ",props?.from, props)}
            {props?.from && props?.from == "Salary Increment" ? 
              <NPADirectPrintSalaryIncrement
                data={props?.data}
                approvedData={props?.approvedData}
                showPrintForm = {true}
                // printState={"Print NPA Redacted"}
                ref={props?.ref}
                mySignature={props?.mySignature}
                setMySignature={props?.setMySignature}
              />
            :
            <>
              <NPADirectPrint
                data={props.data}
                approvedData={props?.approvedData}
                 
                ref={props?.ref}
                mySignature={props?.mySignature}
                setMySignature={props?.setMySignature}
              />
            </>
          }
          {/* <ComponentToPrint
            data={props.data}
            approvedData={props?.approvedData}
          /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};


export default NPAVIEWPrint