// // import { put } from "redux-saga/effects";
// import { get, post, remove,put } from "../api_helper";
// import * as url from "../url_helper";

// const baseURL = process.env.REACT_APP_API_DOTNET_URL;

// export const getPaystepDetails =({paY_GRADE ,paY_STEP})=> get(`${baseURL+url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`);
// export const getPaysteps =({page,pageSize})=> get(baseURL+url.PAYSTEP_BASE+`?pageNumber=${page}&pageSize=${pageSize}`);
// // export const updatePaystep = (ID,payload) => put(url.PAYSTEP_BASE+'/'+ID,payload);

// // export const updatePaystep = ({paY_GRADE},{paY_STEP}, payload) => put(`${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`, payload, {
// //     headers: {
// //       'Content-Type': 'application/json',
// //     },
// //   });
// export const updatePaystep = ({ paY_GRADE, paY_STEP }, payload) => put(
//   `${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`,
//   payload,
//   {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   }
// );

// // export const updatePositioStatus = (ID,payload) => put(url.PAYSTEP_BASE_UPDATE+'/'+ID, payload);
// // export const updatePaystepStatusList = (payload) => put(url.PAYSTEP_BASE_UPDATE,payload);
// export const createPaystep =(payload) => post(baseURL+url.PAYSTEP_BASE,payload);
// //Post
// export const deletePaystep = (ID) => remove(baseURL+url.PAYSTEP_BASE+'/'+ID);
// // export const deletePaystep = ({ paY_GRADE, paY_STEP }) => remove(
// //   `${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`
// // );



import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPaystepDetails = ({ paY_GRADE, paY_STEP }) =>
  get(`${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`);

export const getPaysteps = ({ page, pageSize }) =>
  get(`${baseURL}${url.PAYSTEP_BASE}?pageNumber=${page}&pageSize=${pageSize}`);

export const updatePaystep = ({ paY_GRADE,  paY_STEP }, payload) =>
  put(
    `${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const createPaystep = (payload) =>
  post(`${baseURL}${url.PAYSTEP_BASE}`, payload);

// export const deletePaystep = ({ id: paY_GRADE, id: paY_STEP }) =>
//   remove(`${baseURL}${url.PAYSTEP_BASE}/${paY_GRADE}/${paY_STEP}`);
export const deletePaystep = ({ PAY_GRADE, PAY_STEP }) => remove(
  `${baseURL}${url.PAYSTEP_BASE}/${PAY_GRADE}/${PAY_STEP}`
);
