// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getPositionClasDetails =(id)=> get(`${baseURL+url.POSITIONCLASS_BASE}/getPositionClassList/${id}`);
export const getPositionClass =({page,pageSize})=> get(`${baseURL+url.POSITIONCLASS_BASE}/getallPositionClassList+?pageNumber=${page}&pageSize=${pageSize}`);
export const updatePositionClas = (id,payload) => put(`${baseURL+url.POSITIONCLASS_BASE}/UpdatePositionClassList`,payload);

export const createPositionClas =(payload) => post(`${baseURL+url.POSITIONCLASS_BASE}/createPositionClassList`,payload);
//Post
export const deletePositionClas = (ID) => remove(`${baseURL+url.POSITIONCLASS_BASE}/deletePositionClassList/${ID}`);