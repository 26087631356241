import React from "react";
import { Box, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalCustom = (props) => {
  const {
    open,
    handleClose,
    fullWidth = false,
    maxWidth = "md",
    closeButton = false,
    title = ""
  } = props;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {closeButton && (
        <DialogTitle id="id" style={{ padding: "0 !important" }} className="pb-0">
          <Box display="flex" alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Typography variant="h5" fontWeight={500}>
                {title}
              </Typography>
            </Box>
            <Box justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
      )}
      {props.children}
    </Dialog>
  );
};

export default ModalCustom;
