import {
    GET_ANNOUNCEMENTS,
    GET_ANNOUNCEMENTS_SUCCESS,
    GET_ANNOUNCEMENTS_FAIL,
    GET_JOB_APPLICATION_LEAD,
    GET_JOB_APPLICATION_LEAD_SUCCESS,
    GET_JOB_APPLICATION_LEAD_FAIL,
    GET_ETHNICITIES,
    GET_ETHNICITIES_SUCCESS,
    GET_ETHNICITIES_FAIL,
    GET_PERSON_RACES_SUCCESS,
    GET_PERSON_RACES_FAIL,
    GET_JOB_TYPES,
    GET_JOB_TYPES_SUCCESS,
    GET_JOB_TYPES_FAIL,
    GET_POSITION_TYPES,
    GET_POSITION_TYPES_SUCCESS,
    GET_POSITION_TYPES_FAIL,
    GET_PERSON_RACES,
    GET_QUESTION_POSITIONS,
    GET_QUESTION_POSITIONS_SUCCESS,
    GET_QUESTION_POSITIONS_FAIL,
    GET_TERMINATION_REASONS,
    GET_TERMINATION_REASONS_SUCCESS,
    GET_TERMINATION_REASONS_FAIL,
    CREATE_JOB_APPLICATION,
    CREATE_JOB_APPLICATION_SUCCESS,
    CREATE_JOB_APPLICATION_FAIL,
    GET_CITIZENSHIP,
    GET_CITIZENSHIP_SUCCESS,
    GET_CITIZENSHIP_FAIL,
    GET_ANNOUNCEMENT,
    GET_APPLICATION_EMP_AND_ANN,
    GET_APPLICATION_EMP_AND_ANN_SUCCESS,
    GET_APPLICATION_EMP_AND_ANN_FAIL,
    GET_AGE_RANGES,
    GET_AGE_RANGES_SUCCESS,
    GET_AGE_RANGES_FAIL,
    GET_ANNOUNCEMENT_SUCCESS,
    GET_ANNOUNCEMENT_FAIL,
    RESET,
    SET_ERROR_STATE,
    SAVE_JOB_APPLICATION,
    SAVE_JOB_APPLICATION_SUCCESS,
    SAVE_JOB_APPLICATION_FAIL,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAIL,
    DELETE_UPLOAD_FILE,
    DELETE_UPLOAD_FILE_SUCCESS,
    DELETE_UPLOAD_FILE_FAIL,
    GET_FILES_SUCCESS,
    GET_FILES_FAIL,
    GET_FILES,
    CLOSE_JOB_APPLICATION,
    CLOSE_JOB_APPLICATION_SUCCESS,
    CLOSE_JOB_APPLICATION_FAIL
} from "./actionTypes";

const initialState = {
    announcementList: [],
    loading: false,
    offset: 9,
    currentCount: 9,
    total: 0,
    filterBy: {
        announcement: "",
        position: "",
        due_date: null,
        type: "",
        department: "",
    },
    jobApplicationLeads: [],
    ethnicities: [],
    personRaces: [],
    positionTypes: [],
    jobTypes: [],
    loadingQuestions: false,
    positionWiseQuestions: [],
    terminationReasons: [],
    citizenship: [],
    selectedAnnouncement: {},
    currentJobApplication: {},
    ageRanges: [],
    jobApplicationLoading: false,
    isCreatingJobloading: false,
    isSelectedAnnouncementLoading: false,
    errorState: {},
    isJobCreated: false,
    isJobSaved: false,
    uploadFile: {},
    isUploadFile: false,
    deleteFile: {},
    isDeleteFile: false,
    uploadedFiles: [{}],
    isFetchingUploadedFiles: false,
    closeJobObject: {},
    isClosingJob: false
};

const OnlineJobApplicationReducer = (state = initialState, action) => {
    if (!action) return state;
    switch (action.type) {
        case GET_ANNOUNCEMENTS:
            state = { ...state, loading: true };
            break;
        case GET_ANNOUNCEMENTS_SUCCESS:
            state = {
                ...state,
                announcementList: action.payload.results,
                currentCount: action.payload.rowCount,
                loading: false,
            };
            break;
        case GET_ANNOUNCEMENTS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case GET_JOB_APPLICATION_LEAD:
            state = { ...state, loading: true };
            break;
        case GET_JOB_APPLICATION_LEAD_SUCCESS:
            state = { ...state, jobApplicationLeads: action.payload, loading: false };
            break;
        case GET_JOB_APPLICATION_LEAD_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case GET_ETHNICITIES:
            state = { ...state, loading: true };
            break;
        case GET_ETHNICITIES_SUCCESS:
            state = { ...state, ethnicities: action.payload, loading: false };
            break;
        case GET_ETHNICITIES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_PERSON_RACES:
            state = { ...state, loading: true };
            break;
        case GET_PERSON_RACES_SUCCESS:
            state = { ...state, personRaces: action.payload, loading: false };
            break;
        case GET_PERSON_RACES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_JOB_TYPES:
            state = { ...state, loading: true };
            break;
        case GET_JOB_TYPES_SUCCESS:
            state = { ...state, jobTypes: action.payload, loading: false };
            break;
        case GET_JOB_TYPES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_POSITION_TYPES:
            state = { ...state, loading: true };
            break;
        case GET_POSITION_TYPES_SUCCESS:
            state = { ...state, positionTypes: action.payload, loading: false };
            break;
        case GET_POSITION_TYPES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_QUESTION_POSITIONS:
            state = { ...state, loadingQuestions: true };
            break;
        case GET_QUESTION_POSITIONS_SUCCESS:
            state = {
                ...state,
                positionWiseQuestions: action.payload,
                loadingQuestions: false,
            };
            break;
        case GET_QUESTION_POSITIONS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loadingQuestions: false,
            };
            break;

        case GET_TERMINATION_REASONS:
            state = { ...state, loading: true };
            break;
        case GET_TERMINATION_REASONS_SUCCESS:
            state = { ...state, terminationReasons: action.payload, loading: false };
            break;
        case GET_TERMINATION_REASONS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_CITIZENSHIP:
            state = { ...state, loading: true };
            break;
        case GET_CITIZENSHIP_SUCCESS:
            state = { ...state, citizenship: action.payload, loading: false };
            break;
        case GET_CITIZENSHIP_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_AGE_RANGES:
            state = { ...state, loading: true };
            break;
        case GET_AGE_RANGES_SUCCESS:
            state = { ...state, ageRanges: action.payload, loading: false };
            break;
        case GET_AGE_RANGES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;

        case GET_APPLICATION_EMP_AND_ANN:
            state = { ...state, jobApplicationLoading: true };
            break;
        case GET_APPLICATION_EMP_AND_ANN_SUCCESS:
            state = {
                ...state,
                currentJobApplication: action.payload,
                jobApplicationLoading: false,
            };
            break;
        case GET_APPLICATION_EMP_AND_ANN_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                jobApplicationLoading: false,
            };
            break;

        case CREATE_JOB_APPLICATION:
            state = { ...state, postingResult: {}, isCreatingJobloading: true };
            break;
        case CREATE_JOB_APPLICATION_SUCCESS:
            state = {
                ...state,
                postingResult: action.payload,
                currentJobApplication: action.payload.result,
                isCreatingJobloading: false,
                isJobCreated: true,
            };
            break;
        case CREATE_JOB_APPLICATION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                postingResult: {},
                isCreatingJobloading: false,
            };
            break;

        case SAVE_JOB_APPLICATION:
            state = {
                ...state,
                postingResult: {},
                isCreatingJobloading: true,
                isJobSaved: false,
            };
            break;
        case SAVE_JOB_APPLICATION_SUCCESS:
            state = {
                ...state,
                postingResult: action.payload,
                currentJobApplication: action.payload.result,
                isCreatingJobloading: false,
                isJobSaved: true,
            };
            break;
        case SAVE_JOB_APPLICATION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                postingResult: {},
                isCreatingJobloading: false,
                isJobSaved: false,
            };
            break;

        case GET_ANNOUNCEMENT:
            state = { ...state, isSelectedAnnouncementLoading: true };
            break;
        case GET_ANNOUNCEMENT_SUCCESS:
            state = {
                ...state,
                selectedAnnouncement: action.payload,
                isSelectedAnnouncementLoading: false,
            };
            break;
        case GET_ANNOUNCEMENT_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isSelectedAnnouncementLoading: false,
            };
            break;

        case UPLOAD_FILE:
            state = { ...state, uploadFile: {}, isUploadFile: true };
            break;
        case UPLOAD_FILE_SUCCESS:
            state = {
                ...state,
                uploadFile: action.payload,
                isUploadFile: false,
            };
            break;
        case UPLOAD_FILE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                uploadFile: {},
                isUploadFile: false,
            };
            break;

        case DELETE_UPLOAD_FILE:
            state = { ...state, deleteFile: {}, isDeleteFile: true };
            break;
        case DELETE_UPLOAD_FILE_SUCCESS:
            state = {
                ...state,
                deleteFile: action.payload,
                isDeleteFile: false,
            };
            break;
        case DELETE_UPLOAD_FILE_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                deleteFile: {},
                isDeleteFile: false,
            };
            break;

        case GET_FILES:
            state = { ...state, isFetchingUploadedFiles: true };
            break;
        case GET_FILES_SUCCESS:
            state = {
                ...state,
                uploadedFiles: action.payload,
                isFetchingUploadedFiles: false,
            };
            break;
        case GET_FILES_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                isFetchingUploadedFiles: false,
            };
            break;

        case CLOSE_JOB_APPLICATION:
            state = {
                ...state,
                closeJobObject: {},
                isClosingJob: true
            };
            break;
        case CLOSE_JOB_APPLICATION_SUCCESS:
            state = {
                ...state,
                closeJobObject: action.payload,
                isClosingJob: false
            };
            break;
        case CLOSE_JOB_APPLICATION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                closeJobObject: {},
                isClosingJob: false
            };
            break;

        case RESET:
            state = {
                ...state,
                currentJobApplication: {},
                isJobCreated: false,
            };
            break;

        case SET_ERROR_STATE:
            state = {
                ...state,
                errorState: { ...state.errorState, ...action.payload },
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default OnlineJobApplicationReducer;
