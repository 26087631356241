// import Typography from "@mui/material/Typography";
import React from "react";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import { isAllowedURL } from "../../../_helper/helper";
import { Breadcrumbs } from "../../../AbstractElements";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckDollar,
  faFileExport,
  faAddressCard,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";

import { connect } from "react-redux";
import { CardHeader } from "reactstrap";

function Gg1MasterHeader(props) {
  const headers = [
    { header: "Employee Name", key: "employee_name" },
    { header: "Employee Number", key: "employee_number" },
    { header: "Department", key: "department" },
    { header: "Division", key: "division" },
    { header: "Date Of Birth", key: "dob" },
    { header: "Rate", key: "rate" },
  ];
  return (
    <CardHeader>
      <div
        className={`flex flex-1 w-full bg-primary`}
        style={{
          display: "flex",
          flexDirection: "row",
          // backgroundColor: "#1975D2",
          height: "4.2rem",
          borderRadius: "5px",
          alignItems: "center",
        }}
      >
        <Breadcrumbs
          mainTitle={
            <Typography className="fs-5">
              <FontAwesomeIcon
                icon={faAddressCard}
                className="mx-10  px-3 fs-4"
              />
              GG1 Employer Master
            </Typography>
          }
          parent={
            isAllowedURL("/employee-master") ? (
              <Link
                to={`${process.env.PUBLIC_URL}/employee-master`}
                className="font-light"
              >
                GG1 Employer Master
              </Link>
            ) : undefined
          }
          title="Details"
        />
        {/* <FontAwesomeIcon icon={faAddressCard} className="mx-10  px-3 fs-4" />
        <Typography className="fs-5">Employer Master</Typography> */}
      </div>
    </CardHeader>
  );
}

export default Gg1MasterHeader;
