export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS";
export const GET_AUDIT_LOGS_SUCCESS = "GET_AUDIT_LOGS_SUCCESS";
export const GET_AUDIT_LOGS_FAIL = "GET_TRAINING__AUDIT_LOGS_FAIL";

export const GET_AUDIT_LOG_EXPORTS = "GET_AUDIT_LOG_EXPORTS";
export const GET_AUDIT_LOG_EXPORTS_SUCCESS = "GET_AUDIT_LOG_EXPORTS_SUCCESS";
export const GET_AUDIT_LOG_EXPORTS_FAIL = "GET_TRAINING__AUDIT_LOG_EXPORTS_FAIL";

export const GET_AUDIT_LOG_DIFF = "GET_AUDIT_LOG_DIFF";
export const GET_AUDIT_LOG_DIFF_SUCCESS = "GET_AUDIT_LOG_DIFF_SUCCESS";
export const GET_AUDIT_LOG_DIFF_FAIL = "GET_TRAINING__AUDIT_LOG_DIFF_FAIL";

export const RESET = "RESET";
export const RESET_AUDIT_LOG_DIFF = "RESET_AUDIT_LOG_DIFF";

