import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Row,
} from "reactstrap";
import * as Yup from "yup";

import { Breadcrumbs, Btn } from "../../AbstractElements";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import Alert from "@mui/material/Alert";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBroom,
  faCopy,
  faFaceSmile,
  faFilePen,
} from "@fortawesome/pro-duotone-svg-icons";
import { ShowError, ShowSucess, isAllowedURL } from "../../_helper/helper";
import { Link, useNavigate } from "react-router-dom";
import CustomLegand from "../Create Transaction/CustomLegand";
import { ExpandMore, Schedule } from "@mui/icons-material";
import CustomTextField from "../../Layout/Components/TextField";
import { useFormik } from "formik";
import ConfirmModal from "./ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGGTrackData } from "../Create Transaction/slices/ggTrackSlice";
import axios from "axios";
import { AddGG1EmployeeMasterData } from "../../store/GG1-Crud/actions";
import { Icon } from "@progress/kendo-react-common";
import { saveAdvanceSearch } from "../../store/advanceSearchv2/actions";

const genderArray = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
];
const maritalArray = [
  {
    name: "Single",
    value: "single",
  },
  {
    name: "Married",
    value: "married",
  },
];
const genderList = [
  { value: "M", label: "Male", name: "Male" },
  { value: "F", label: "Female", name: "Female" },
   { value: "O", label: "Other", name: "Other" },
];

const CreateEmployeeGG1 = () => {
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [DPDVCode, setDPDVCode] = useState([]);
  const [DPDVName, setDPDVName] = useState([]);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  const [step, setStep] = useState("");
  const [copyAddress, setCopyAddress] = useState(false);

  const [userWarning, setUserWarning] = useState(false);

  const lookupItems = useSelector((state) => state.ggTrackSlice.GGTrackData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    
    // if(!lookupItems){
      dispatch(fetchGGTrackData(""));
    // }
  }, []);

  
  const initialValues = {
    id: 0,
    employeeNameFml: null,
    employeeNameLfm: null,
    employeeName: null,
    dpdv: null,
    ssn: params?.ssn || null,
    firstName: null,
    middleName: null,
    lastName: null,
    budgePosNo: null,
    npositionNo: null,
    physicalExam: null,
    inactiveStatus: null,
    ndateOfBirth: null,
    maritalStatus: null,
    emplType: null,
    empStatus: null,
    natureOfAction: null,
    ethnicGroup: null,
    sex: null,
    militaryStatus: null,
    veteran: null,
    education: null,
    handicapped: null,
    handiCond: null,
    preferencePts: null,
    i9: null,
    citizen: null,
    hmAddLn1: null,
    hmAddCity: null,
    hmAddState: null,
    hmAddZip: null,
    hmPhone: null,
    mlAddLn1: null,
    mlAddCity: null,
    mlAddState: null,
    mlAddZip: null,
    mlWkPhone: null,
    probation: null,
    deptNo: null,
    collar: null,
    rangeActual: null,
    rangePay: null,
    stepActual: "",
    annualSalary: null,
    rate: null,
    performancePts: null,
    reemployDate: null,
    servPrior: null,
    dateTerm: null,
    ndateIncrem: null,
    dateEmployment: null,
    courtClrDate: null,
    policeClrDate: null,
    retireSsCode: null,
    driversLicenseNo: null,
    ninsHealthType: null,
    ninsDentType: null,
    ninsLifeType: null,
    name2: null,
    jacketNo: null,
    jktPrintedFlag: null,
    postCurrentDate: null,
    dtlAddtlRate: null,
    dtlTermDate: null,
    newEmployee: null,
    processedDate: null,
    workSch: null,
    lastActnDate: null,
    requestId: null,
    appropType: null,
    email: null,
    npositionTitle: null,
    educationTitle: null,
    inactiveStatusTitle: null,
    empStatusTitle: null,
    emplTypeTitle: null,
    ethnicGroupTitle: null,
    departmentTitle: null,
    employeeType: null,
    fteno: null,
    jacketNo: null,
  };
  const onSubmit = async (e) => {
    console.log("dsadadadad", e);
    setOpen(true);
  };
  
  const [schema, setPerformanceSchema] = useState({
    // ssn: Yup.string()
    //   .required("SSN is Required")
    //   .test("len", "SSN must have exactly 9 digits", (value) => {
    //     return value ? value.length === 9 : false;
    //   }),
    ssn: Yup.string()
      .min(9, "SSN must have exactly 9 digits")
      .max(9, "SSN must have exactly 9 digits")
      .required("SSN is Required"),
    email: Yup.string().email().required("Email is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    firstName: Yup.string().required("First Name is Required"),
    hmAddLn1: Yup.string()
      // .min(5, "must be 5 character")
      .required("Home address is Required"),
    mlAddLn1: Yup.string()
      // .min(5, "must be 5 character")
      .required("Mailing address is Required"),
    hmAddState: Yup.string()
      .max(2, "Maximum 2 character")
      .required("Home State Code is Required"),
    mlAddState: Yup.string()
      .max(2, "Maximum 2 character")
      .required("Mailing State Code is Required"),
    ndateOfBirth: Yup.date()
      .max(moment().subtract(18, "years").format("MM-DD-YYYY"),
        `Employee must be 18 Yrs old.`)
      .typeError("Date of Birth must be in MM/DD/YYYY format")
      // .nullable(),
      .required("Date of Birth is Required"),
    policeClrDate: Yup.date()
      .min(
        moment().subtract(30, "days").format("MM-DD-YYYY"),
        "Police Clearance Date must not be 1 month older."
      )
      .max(
        moment().add(1, "day").format("MM-DD-YYYY"),
        "Police Clearance Date can not be a future Date."
      )
      .typeError("Police Clearance must be in MM/DD/YYYY format")
      .nullable(),
    // .required(""),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    validateForm,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape(schema),
    onSubmit,
  });
  // console.log(
  //   "121212 lookupItems ::: ",
  //   lookupItems,
  //   values.dpdv,
  //   values.departmentTitle
  // );

  useEffect(()=>{
    // if(values?.npositionTitle && values?.npositionTitle?.includes("AMERICORP")){
      if(values?.npositionTitle && (['americorp','youth summer','summer trainee','youth summer trainee'].includes(values?.npositionTitle?.toLowerCase()?.trim()) || values?.npositionTitle?.includes("AMERICORP"))){
      setPerformanceSchema({...schema,
        ndateOfBirth: Yup.date()
        .max(moment().subtract(12, "years").format("MM-DD-YYYY"),
          `Minimun Age for Employee is 12 Yrs.`)
        .typeError("Date of Birth must be in MM/DD/YYYY format")
        // .nullable(),
        .required("Date of Birth is Required"),
      })
    }
    else{
      setPerformanceSchema({...schema,
        ndateOfBirth: Yup.date()
        .max(moment().subtract(18, "years").format("MM-DD-YYYY"),
          `Employee must be 18 Yrs old.`)
        .typeError("Date of Birth must be in MM/DD/YYYY format")
        // .nullable(),
        .required("Date of Birth is Required"),
      })
    }
  },[values?.npositionTitle])

  function renderTextInput(
    stateName,
    label,
    disabled = false,
    multiline = false,
    required = false,
    shrink = true
  ) {
    return (
      <TextField
        fullWidth
        size="small"
        inputProps={{
          style: {
            fontSize: "14px",
            paddingTop: "12px",
            width: "100%",
          },
          readOnly: disabled,
        }}
        margin="dense"
        variant="outlined"
        label={label}
        disabled={disabled}
        name={stateName}
        id={stateName}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        value={values[stateName]}
        InputLabelProps={{ shrink: shrink ? shrink : false }}
        shrink={shrink ? shrink : false}
        // onChange={handleChange}
        onChange={(e) => {
          console.log("hanfke CHale dasd", e.target.value, stateName);
          // handleChange(value.value);
          if(stateName == "stepActual"){
            // if(parseInt(e.target.value) < 10){
              // let step = parseInt(e.target.value) < 10
              // ? `0${e.target.value}`
              // : e.target.value;
              // setFieldValue(stateName, step);
              // if (e.target.value > 0) {
              //   if (parseInt(e.target.value) < 10) {
              //     setFieldValue(stateName,`0${parseInt(e.target.value)}`);
              //   } else {
              //     let b = parseInt(e.target.value,10).toString();
              //     if (b?.length <= 2) {setFieldValue(stateName, b);
              //     }
              //   }
              // } else {
              //   setFieldValue(stateName, e.target.value);
              // }
              setFieldValue(stateName, e.target.value?.padStart(2,"0").slice(-2));
            // }
          }else{
            setFieldValue(stateName, e.target.value);
          }

          
        }}
        // onBlur={handleBlur}
        onBlur={(e) => {
          if(stateName == "firstName" || stateName == "lastName"){
            if(values?.ndateOfBirth && values?.firstName && values?.lastName){
              // ShowError("values Entered...")
              CheckUserExist();
            }
          }
        }}
        touched={touched.stateName}
        error={touched[stateName] && errors[stateName]}
        helperText={touched[stateName] && errors[stateName]}
        required={required}
      />
    );
  }

  function renderDropDown(stateName, label, value, disabled) {
    return (
      <div>
        {/* <TextField
          margin="dense"
          className="flex w-full  mt-2"
          variant="outlined"
          select
          SelectProps={{ native: true }}
          style={{ width: "100%" }}
          size="small"
          disabled={disabled}
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label={label}
          name={stateName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          error={errors[stateName]}
          helperText={errors[stateName]}
          value={values[stateName]}
        >
          <option value={""}>Select {label}</option>

          {value.length &&
            value.map((option, index) => (
              <option key={`${index}-${option.value}`} value={option.value}>
                {option.name}
              </option>
            ))}
        </TextField> */}
        {/* <option value={""} disabled> */}
        {/* value={values[stateName]}> */}

        <Autocomplete
          size="small"
          name={stateName}
          disablePortal
          InputLabelProps={{
            shrink: true,
          }}
          id={stateName}
          value={value?.length ? value?.find(i=>i?.value == values[stateName]) : ""}
          options={value}
          getOptionLabel={(option) =>
            option.name ? option.name?.toString() : ""
          }
          onChange={(e, value) => {
            // console.log("hanfke CHale dasd", value);
            // handleChange(value.value);
            setFieldValue(stateName, value?.value);
          }}
          renderOption={(props, option, index) => {
            const key = `listItem-${index}-${option["value"]}`;
            return (
              <li {...props} key={key}>
                {stateName == "npositionTitle" || stateName == "npositionNo" ? option.titleWithGrade ? option["titleWithGrade"] : "" : option["name"]}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              margin="dense"
              name={stateName}
              label={label}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </div>
    );
  }

  function renderDateInput(
    stateName,
    label,
    minDate = "",
    maxDate = "",
    disabled = false
  ) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputVariant={"outlined"}
          margin="dense"
          format="MM/dd/yyyy"
          label={label}
          minDate={minDate}
          maxDate={maxDate}
          value={values[stateName]}
          KeyboardButtonProps={{ "aria-label": label }}
          disabled={disabled}
          onChange={(value) => {
            const formatDate = value?.$d;
            const date = formatDate
              ? moment(formatDate).format("YYYY-MM-DD hh:mm:ss")
              : null;
            setFieldValue(stateName, date);
          }}
          // onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin="dense"
              size="small"
              InputLabelProps={{ shrink: true }}
              {...params}
              error={!disabled && errors[stateName]}
              helperText={!disabled && errors[stateName]}
              onBlur={(e) => {
                // debugger
                if(stateName == "ndateOfBirth"){
                  if(values?.ndateOfBirth && values?.firstName && values?.lastName){
                    // ShowError("values Entered...")
                    CheckUserExist();
                  }
                }
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  }
  function renderCheckbox(stateName, label, changehandler) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            name={stateName}
            id={stateName}
            // checked={checked}
            value={values[stateName]}
            onChange={changehandler}
            // disabled={disabled}
            // inputProps={{
            //   style: {
            //     fontSize: "14px",
            //     paddingTop: "12px",
            //     width: "100%",
            //   },
            //   // readOnly: disabled,
            // }}
          />
        }
        label={label}
      />
    );
  }
  const [transactionView, setTransactionView] = useState(true);
  const [transactionView1, setTransactionView1] = useState(true);
  const [transactionView2, setTransactionView2] = useState(true);

  // useEffect(() => {
  //   if (values?.departmentTitle) {
  //     let dpdvData = lookupItems?.dpdvItems?.find(
  //       (i) => i.label == values?.departmentTitle
  //     );
  //     setFieldValue("dpdv", dpdvData?.value);
  //     setFieldValue("departmentTitle", dpdvData?.label);
  //     setFieldValue("deptNo", dpdvData?.deptNo);
  //   }
  // }, [values?.departmentTitle]);
  // useEffect(() => {
  //   if (values?.dpdv) {
  //     let dpdvData = lookupItems?.dpdvItems?.filter(
  //       (i) => i.value == values?.departmentTitle
  //     );
  //     setFieldValue("dpdv", dpdvData?.value);
  //     setFieldValue("departmentTitle", dpdvData?.label);
  //     setFieldValue("deptNo", dpdvData?.deptNo);
  //   }
  // }, [values?.dpdv]);

  useEffect(() => {
    if (lookupItems?.dpdvItems) {
      let dpdvName = lookupItems.dpdvItems.filter((item) => item.deptDiv == "");
      let dpdvCode = lookupItems.dpdvItems.filter((item) => item.deptDiv != "");
      dpdvName = dpdvName?.map((i) => {
        return { name: i.label, value: i.label };
      });
      console.log("121212 ::: ",dpdvCode, dpdvName)
      setDPDVCode(dpdvCode);
      setDPDVName(dpdvName);
    }
  }, [lookupItems.dpdvItems]);
  useEffect(() => {
    setFieldValue("dpdv",null);
    let dpdvData = {};
    if (values?.departmentTitle) {
      dpdvData = lookupItems?.dpdvItems?.find(
        (i) => i.label == values?.departmentTitle
      );
    }
    if (DPDVCode.length) {
      // setFieldValue("TO_Division", { value: "", label: "" });
      let newDivisions = DPDVCode.filter(
        (item) => item?.deptNo == dpdvData?.deptNo
        );
        console.log("123123 ::: ",newDivisions);
        let newDivisions1 = newDivisions?.map((i) => {
        return { name: i.label, value: i.value };
      });
      setFieldValue("deptNo", dpdvData?.deptNo);
      setFilteredDivisions(newDivisions1);
      
    }
  }, [values?.departmentTitle]);

  useEffect(() => {
    let dpdvData = {};
    if (values?.departmentTitle) {
      dpdvData = lookupItems?.dpdvItems?.find(
        (i) => i.label == values?.departmentTitle
      );
    }
    if (DPDVCode.length) {
      // setFieldValue("TO_Division", { value: "", label: "" });

      let newDivisions = DPDVCode.filter(
        (item) => item?.deptNo == dpdvData?.deptNo
      );
      let newDivisions1 = newDivisions?.map((i) => {
        return { name: i.label, value: i.value };
      });
      setFieldValue("deptNo", dpdvData?.deptNo);
      setFilteredDivisions(newDivisions1);
    }
  }, [lookupItems?.positionTitle]);

  useEffect(async () => {
    if (values?.npositionNo) {
      const nPositionOBJ = lookupItems?.positionTitle.find(
        (i) => i.value == values?.npositionNo
      );
      setFieldValue("npositionTitle", nPositionOBJ?.label);
      //API for getting salary and rate
      // try {
      //   let data = await axios.get(
      //     `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/GetPositionSalaryByGrade/${nPositionOBJ?.range_next}/${nPositionOBJ?.label}`, //543015044
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${localStorage.getItem(
      //           "jwt_access_token"
      //         )}`,
      //       },
      //     }
      //   );

      //   if (data?.data?.length) {
      //     const firstStep = data?.data?.find((i) => i.step == "01");
      //     console.log("1212 Data :::fsdfs ", firstStep);
      //     setFieldValue("stepActual", firstStep?.step);
      //     setFieldValue("annualSalary", firstStep?.salary);
      //     setFieldValue("rangePay", firstStep?.grade);
      //     setFieldValue("rate", firstStep?.rate);
      //   }
      //   // if(console.error();)
      // } catch (err) {
      //   console.log("1212 Err ::: ", err.response);
      // }
      const step = values?.stepActual?.padStart(2,"0").slice(-2);
        // parseInt(values?.stepActual) < 10
        //   ? `0${values?.stepActual}`
        //   : values?.stepActual; //"01";

      // setFieldValue("stepActual", step);
      let grade = nPositionOBJ?.range_next.trim();
      if(grade != ""){
        setFieldValue("rangePay", grade);
      }else{
        grade = values?.rangePay;
      }
      // if(step != values?.stepActual){
        // setFieldValue("stepActual",  values?.stepActual.padStart(2,"0"));
      // }
      let getfromStep;
      if (grade !== null || grade !== undefined || grade !== "") {
        getfromStep = lookupItems?.salaryItemsList.filter((item) => {
          return (
            item?.step?.trim() == step.trim() &&
            item?.grade?.trim() == grade?.trim()
          );
        });
        console.log("demo 123123", getfromStep);

        if (getfromStep) {
          setFieldValue("annualSalary", getfromStep[0]?.nSalary);
          setFieldValue("rate", getfromStep[0]?.nrate);
        }
      }
      console.log("nPositionOBJ 123123", nPositionOBJ, values?.rangePay);
    }
  }, [values?.npositionNo, values?.stepActual, values?.rangePay]);

  const saveData = async () => {
    // console.log("121212 values:::: ", values);
    // return false;
    // try {
    //   let data = await axios.post(
    //     `${process.env.REACT_APP_API_DOTNET_URL}/Employee/AddGG1EmployeeMaster`,
    //     values,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
    //       },
    //     }
    //   );

    //   // if(console.error();)
    //   console.log("finaL DATA SAVED", data);
    //   if (data?.data) {
    //     navigate(`/gg1-tracking/new-request/${data?.data?.result?.id}`);
    //   }
    // } catch (err) {
    //   console.log("finaL DATA SAVED err", err);
    // }
    const payload = values;
    // console.log("123123 ::: ",payload)
    const cbSuccess = (response) => {
      if (response) {
        console.log("NAVIAGTED 111 : ", response);
        ShowSucess(response?.message);
        setOpen(false);
        navigate(`/gg1-tracking/new-request/${response?.result?.id}`);
      }
    };
    const cbFailure = (errMsg) => {
      console.log("finaL DATA SAVED err", errMsg);
      ShowError(errMsg);
      setOpen(false);
    };
    dispatch(AddGG1EmployeeMasterData(payload, cbSuccess, cbFailure));
  };

  useEffect(() => {
    if (values?.dpdv) {
      const dpdvNo = lookupItems?.dpdvItems?.find(
        (i) => i.value == values?.dpdv
      );
      console.log("dpdvNore", dpdvNo);
    }
  }, [values?.dpdv]);

  const CopyHomeaddress = (props) => {
    if (props?.target?.checked) {
      setCopyAddress(props?.target?.checked);
      setFieldValue("mlAddLn1", values["hmAddLn1"]);
      setFieldValue("mlAddCity", values["hmAddCity"]);
      setFieldValue("mlAddState", values["hmAddState"]);
      setFieldValue("mlAddZip", values["hmAddZip"]);
    } else {
      setCopyAddress(props?.target?.checked);
      // setFieldValue("mlAddLn1", values["hmAddLn1"]);
      // setFieldValue("mlAddCity", values["hmAddCity"]);
      // setFieldValue("mlAddState", values["hmAddState"]);
      // setFieldValue("mlAddZip", values["hmAddZip"]);
    }
  };

  let warningMsg = "Warning: An employee with the same date of birth, first and last name exist in the system with a different SSN.  Please verify the SSN entered before creating this employee.";
  const checkUser = useSelector((state) => state?.advanceSearchv2Reducer?.saveQueriesData?.results);
  useEffect(()=>{
    // debugger
    console.log("121212 data outside ::: ",checkUser)
    if(checkUser?.length > 0){
      setUserWarning(true);
      // window.alert(warningMsg);
    }else{
      setUserWarning(false);
    }
  },[checkUser])

  const CheckUserExist = () => {
    dispatch(
      saveAdvanceSearch(
        {
          "tableName": "vw_employeeMaster",
          "searchParams": "[]",
          "columns": "*",
          "pageNumber": 1,
          "pageSize": 10,
          "sortData": "",
          "additionalWhere": `Ndate_Of_Birth = '${moment(values?.ndateOfBirth,"YYYY-MM-DD 00:00:00").format("YYYY-MM-DDT00:00:00")}' AND FirstName = '${values?.firstName}' AND LastName = '${values?.lastName}' AND SSN not like '${values?.ssn}'`,
          "qID": 0,
          "location": "global",
          "id": "employeemaster"
        }
      )
    );
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs
          mainTitle={
            <Typography variant="h5">
              <Icon style={{ fontSize: '25px', margin:"10px"}} name="list-latin-small" />
              Create New Employee Master
            </Typography>
          }
          parent={
            isAllowedURL("/employee-master") ? (
              <Link
                to={`${process.env.PUBLIC_URL}/employee-master`}
                className="font-light"
              >
                Employee Master
              </Link>
            ) : isAllowedURL("/transactionlist") ? (
              <Link
                to={`${process.env.PUBLIC_URL}/gg1-tracking`}
                className="font-light"
              >
                GG1 Tracking
              </Link>
            ) : undefined
          }
          title={"Employee Create"}
        />
        <CardHeader>
          <div
            className={` w-100`}
            style={{
              height: "3.2rem",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <div
              className="mt-2 mr-3   d-flex justify-content-end bd-highlight"
              style={{ paddingRight: "3rem", paddingBottom: "1rem"}}
            >
              {userWarning && 
                <Stack style={{marginRight:"2rem"}} >
                  <Alert severity="warning">{warningMsg}</Alert>
                </Stack>
              } 
              <div className="mr-2">
                <Button
                  color="primary"
                  onClick={(e) => {
                    // e.preventDefault();
                    handleSubmit();
                  }}
                  // type: "submit",
                  style={{
                    marginRight: "4px",
                    marginBottom: "5px",
                  }}
                >
                  <>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFilePen}
                      style={{
                        color: "white",
                        marginRight: "5px",
                      }}
                    />
                    Save
                  </>
                </Button>
              </div>
            </div>{" "}
          </div>
        </CardHeader>
        <Row>
          <Col
            sm="12"
            style={{ maxHeight: "calc(100vh - 300px)", overflow: "auto" }}
          >
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end mb-2 ">
                  {/* <CustomLegand /> */}
                </div>
                <Accordion expanded={transactionView}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView(!transactionView)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Employee Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("ssn", "Social Security No.")}
                      </Col>
                      <Col md={6} lg={6} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput(
                          "ndateOfBirth",
                          "Date of Birth",
                          null,
                          moment().subtract(18, "years").format("MM-DD-YYYY")
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("lastName", "Last Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("firstName", "First Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("middleName", "Middle Name")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {/* {renderDropDown("sex", "Sex", lookupItems?.genderItems)} */}
                        {renderDropDown("sex", "Gender", genderList)}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("email", "Email Address")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmPhone", "Home Phone Number")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlWkPhone", "Work Phone Number")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "maritalStatus",
                          "Marital Status",
                          lookupItems?.maritalStatusItems
                        )}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={transactionView1}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView1(!transactionView1)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Address Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddLn1", "Home Address Street")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddCity", "City")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddState", "State")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("hmAddZip", "Zipcode")}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} lg={6} xs={12}>
                        {renderCheckbox(
                          "copyAddress",
                          "Same as Home Address",
                          CopyHomeaddress
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddLn1", "Mailing Address Street")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddCity", "City")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddState", "State")}
                      </Col>
                      <Col md={3} lg={3} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("mlAddZip", "Zipcode")}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={transactionView2}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    onClick={() => setTransactionView2(!transactionView2)}
                  >
                    <div className="w-100">
                      <Row>
                        {" "}
                        <Col xs={12} lg={4} md={4} sm={12}>
                          <Typography variant="h6">
                            <FontAwesomeIcon
                              className="m-r-10 font-primary"
                              icon={faFaceSmile}
                              size="lg"
                            />{" "}
                            Other Details
                          </Typography>
                        </Col>
                      </Row>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput(
                          "driversLicenseNo",
                          "Driver's License No."
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput(
                          "policeClrDate",
                          "Police Clearance Date",
                          moment().subtract(30, "days").format("MM-DD-YYYY"),
                          moment().format("MM-DD-YYYY"),
                          false
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDateInput(
                          "courtClrDate",
                          "Court Clearance Date"
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "npositionNo",
                          "Position",
                          lookupItems?.positionTitle
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "departmentTitle",
                          "Department",
                          DPDVName
                        )}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderDropDown(
                          "dpdv",
                          "Section/Division",
                          filteredDivisions
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("rangePay", "Pay Grade")}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("stepActual", "Pay Step")}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("annualSalary", "Salary Per Annum")}
                      </Col>
                      {/* <Col md={5} lg={5} xs={12} style={{ padding: "10px" }}>
                          {renderTextInput("aa", "Section/Division")}
                        </Col> */}
                    </Row>
                    <Row>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("rate", "Rate Per Hour")}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("fteno", "FTE No.")}
                      </Col>
                      <Col md={4} lg={4} xs={12} style={{ padding: "10px" }}>
                        {renderTextInput("jacketNo", "Jacket No.")}
                      </Col>
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfirmModal
        open={open}
        setOpen={setOpen}
        confimClick={saveData}
        message={"Please review before continuing."}
        // modalHeader="Alert Message"
      />
    </Fragment>
  );
};

export default CreateEmployeeGG1;
