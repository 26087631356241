import { useEffect, useState } from 'react';
import { Stepper } from "@progress/kendo-react-layout";
import { getProcessesStatusList } from '../../../store/process/actions';
import { useDispatch, useSelector } from 'react-redux';

const PEworkFlow = ({ currentValue, skip }) => {
	const dispatch = useDispatch();
	const { processStatusList } = useSelector((state) => state.processReducer);
	console.log("processStatusList", processStatusList);
	console.log("currentValue",currentValue)

	const [value, setValue] = useState(-1);
	const [data, setData] = useState([]);
	console.log("DataStepper", data)

	const handleChange = (e) => setValue(e.value);

	useEffect(() => {

		dispatch(getProcessesStatusList(100));

	}, [dispatch, 100]);

	useEffect(() => {
		 
		if (processStatusList && processStatusList.length > 0) {
			const statusLists = JSON.parse(JSON.stringify(processStatusList))
			let d = statusLists.find(d => d.statusName === skip);
			const afterRecords = statusLists.splice(statusLists.indexOf(d));
			console.log("afterRecords",afterRecords)
			const finalDAta = afterRecords.map(d => {
				return { label: d.statusName }
			});

			setData(finalDAta);
			setValue(afterRecords.indexOf(afterRecords.find(d => d.id == currentValue)));
			
		}
	}, [processStatusList,currentValue]);

	if ((!data || data.length === 0)  )
		return null;
	return <Stepper disabled value={value} onChange={handleChange}
			items={data}

	/>
}

export default PEworkFlow