import { get, post, postReturnResponse, remove } from "../api_helper";
import * as url from "../url_helper";

const URL_AdvanceSearch = "/AdvanceSearch/[LOCATION]";
const GG1_EXCEL_ADVANCESEARCH = "/AdvanceSearch";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;
//Post
export const gg1ReportDownload = (payload) => {
  const { fileExtension,headername,footerContent,headerContent, ...rest } = payload;
  if(headername){
    // rest['headername'] = headername?.replace(/\s+/g, ' ')
  }
  if(footerContent){
    rest['footerContent'] = footerContent?.replace(/\s+/g, ' ')
  }
  if(headerContent){
    rest['headerContent'] = headerContent?.replace(/\s+/g, ' ')
  }
  return postReturnResponse(
    baseURL +
    GG1_EXCEL_ADVANCESEARCH +
    `/${payload.isOJA ? 'OJAExport' : 'CommonSearchExport'}?fileExtension=${fileExtension}${headername?`&headername=headername`:""}`,
    rest,
    { responseType: "blob" }
  );
};

export const advanceSearch = (payload) => {
  if (payload.location === "npa") {
    return post(
      baseURL +
        URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
        `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&transType=NPA`,
      payload
    );
  } else {
    return post(
      baseURL +
        URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
        `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`,
      payload
    );
  }
};

export const loadColumns = (tableName) =>
  get(baseURL + url.ADVANCE_SEARCH_LOAD_COLUMNS + "=" + tableName);
export const findAsyouType = (table, keyField, valueField, search) =>
  get(
    url.ADVANCE_SEARCH_LOAD_FINDASTYPE +
      "/" +
      `${table}/${keyField}/${valueField}/${search?search:''}`
  );

export const saveAdvanceSearch = (payload) =>
  post(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    payload
  );
export const deleteSavedQuery = (payload) => {
  console.log("payload", payload);
  return remove(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", "DeleteSearch"),
    payload
    //   `/${Number(qID)}`
    // payload
  );
};

export const getAdvanceSearchById = (payload, params) =>
  get(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    params
  );
export const getAllAdvanceSearchDAta = (payload) =>
  post(
    baseURL + URL_AdvanceSearch.replace("[LOCATION]", payload.location),
    payload
  );

export const findByAdavanceSearch = async(payload) =>{
  const {table,key,value,order} = payload
   return await get(baseURL + url.ADVANCE_SEARCH_LOAD_FINDASTYPE +`/${table}/${key}/${value}?order=${order}`)
}

export const advanceSearchDashboard = (payload, tableName) => {
  if (payload.location === "npa") {
    return post(
      baseURL +
      URL_AdvanceSearch.replace("[LOCATION]", payload.location) +
      `/${tableName}`,
      payload
    );
  } else {
    return post(`${process.env.REACT_APP_API_DOTNET_URL + "/AdvanceSearch/global"}`, payload);
  }
};
