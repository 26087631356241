// import { put } from "redux-saga/effects";
import { get, post, remove,put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getTrainingDetails =(id)=> get(`${baseURL+url.TRAINING_BASE}/GetTrainingRegistory/${id}`);
export const getTrainings =({page,pageSize})=> get(`${baseURL+url.TRAINING_BASE}/getallTrainingRegistry+?pageNumber=${page}&pageSize=${pageSize}`);
export const updateTraining = (id,payload) => put(`${baseURL+url.TRAINING_BASE}/UpdateTrainingRegistry`,payload);

export const createTraining =(payload) => post(`${baseURL+url.TRAINING_BASE}/AddTrainingRegistry`,payload);
//Post
export const deleteTraining = (ID) => remove(`${baseURL+url.TRAINING_BASE}/DeleteTrainingRegistry/${ID}`);