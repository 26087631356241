import React, { Fragment, useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import md5 from "md5-hash";

import { H6, Image, LI, UL, P } from "../../AbstractElements";
import man from "../../assets/images/dashboard/1.png";
import { useSelector } from "react-redux";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faQrcode } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Profile = () => {
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const { userData } = useAuth();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { role: selectedRole } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name"));
  }, [setProfile, setName]);
  const imgURL = "https://signin.ehrpweb.com/images/profiles/";
  const url = process.env.REACT_APP_AUTH_URL;

  useEffect(() => {
    if (userData) {
      if (userData.profile.email.indexOf("gmail") > -1) {
        setProfile(
          `https://secure.gravatar.com/avatar/${md5(userData.profile.email)}`
        );
      } else {
        setProfile(
          `https://api.ehrpweb.com/profiles/${md5(userData.profile.email)}.png`
        );
      }
      setName(userData.profile.name);
    }
  }, [userData]);

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        {/* <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}> */}
        {open ? (
          <Fragment>
            <QRCodeCanvas
              value={userData?.profile?.EmployeeID}
              id={"profile-qr"}
            />
          </Fragment>
        ) : (
          <Image
            attrImage={{
              className: "img-90 rounded-circle",
              src:
                userData && userData?.profile?.ProfilePhoto
                  ? `${imgURL}${userData?.profile?.ProfilePhoto}`
                  : authenticated
                  ? auth0_profile.picture
                  : profile,
              alt: "",
            }}
          />
        )}
        <H6 attrH6={{ className: "mt-3 f-14 f-w-600" }}>
          <a href={url} target="_blank">
            {authenticated
              ? auth0_profile.name
              : name == null
              ? "Profile"
              : name}
          </a>
          {open && (
            <FontAwesomeIcon
              className="m-l-10 font-primary"
              icon={faDownload}
              onClick={() => {
                const canvas = document.getElementById("profile-qr");
                const pngUrl = canvas
                  .toDataURL("image/png")
                  .replace("image/png", "image/octet-stream");
                let downloadLink = document.createElement("a");
                downloadLink.href = pngUrl;
                downloadLink.download = `${userData?.profile?.EmployeeID}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }}
            />
          )}
        </H6>
        <P attrPara={{ className: "mb-0 font-roboto" }}>
          <a href="/master-detail">
            {(userData &&
              userData?.profile &&
              userData?.profile?.name &&
              userData.profile.name) ||
              selectedRole}{" "}
          </a>
        </P>
        {userData?.profile?.EmployeeID && (
          <Fragment>
            {open === false ? (
              <FontAwesomeIcon
                style={{ position: "absolute", top: "10", right: "10" }}
                className={"fa-2x"}
                icon={faQrcode}
                onClick={() => {
                  setOpen(!open);
                }}
              />
            ) : (
              <Image
                attrImage={{
                  style: { position: "absolute", top: "10px", right: "10px" },
                  className: "img-30 rounded-circle",
                  src:
                    userData && userData?.profile?.profilePhoto
                      ? `${imgURL}${userData?.profile?.profilePhoto}`
                      : authenticated
                      ? auth0_profile.picture
                      : profile,
                  alt: "",
                  onClick: () => {
                    setOpen(!open);
                  },
                }}
              />
            )}
            {/* </Link> */}

            {/* <Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Box sx={style}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										margin: "0",
									}}
								>
									<h6 className="mt-2">
										{authenticated ? auth0_profile.name : name}
									</h6>
									<div>
										<IconButton onClick={() => {
											setOpen(!open);
										}}>
											<CloseIcon />
										</IconButton>
									</div>
								</div>
								<QRCodeModal employeeInfo={{ ID: userData?.profile?.EmployeeID }} />
							</Box>
						</Modal> */}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Profile;
