import {
  STORED_THEME,
  STORED_THEME_SUCCESS,
  STORED_THEME_FAIL,
  USER_CONFIGURATION,
  USER_CONFIGURATION_SUCCESS,
  USER_CONFIGURATION_FAIL,
} from "./actionTypes";
import ConfigDB from "../../Config/ThemeConfig";
const initialState = {
  storedTheme: { ...ConfigDB.data },
  StoredUserConfiguration: {},
  GridData: {},
  gridSetting: {},
  message: null,
};

const ThemeCustomizerReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case "STORED_GRID_TABLE":
      state = {
        ...state,
        GridData: { ...action.payload, id: "common" },
      };
      break;
    case STORED_THEME:
      state = { ...state };
      break;
    case STORED_THEME_SUCCESS:
      state = {
        ...state,
        storedTheme: JSON.parse(
          action.payload?.userConfiguration?.layoutSetting || "{}"
        ),
        GridData: JSON.parse(action.payload?.userConfiguration?.gridSetting),
        gridSetting: JSON.parse(action.payload?.userConfiguration?.gridSetting),
        message: action.payload?.message,
      };
      break;
    case USER_CONFIGURATION:
      state = { ...state };
      break;
    case USER_CONFIGURATION_SUCCESS:
      state = {
        ...state,
        StoredUserConfiguration: JSON.parse(
          action.payload?.layoutSetting || "{}"
        ),
        GridData: JSON.parse(action.payload?.gridSetting),
        gridSetting: JSON.parse(action.payload?.gridSetting),
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ThemeCustomizerReducer;
