import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@mui/styles";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";

import {
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileSpreadsheet,
  faFileWord,
  faPlugCirclePlus,
  faPlusCircle,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { Col, Row } from "reactstrap";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  getDocumentFields,
  getDocumentSources,
  postDocuments,
} from "../../store/DocumentUpload/actions";
import { Add, Remove, Visibility } from "@mui/icons-material";
import { loadingPanel } from "../CommonFunction";
import _, { isEmpty } from "lodash";
import CustomAutocomplete from "../FormComponents/Autocomplete";
import CustomTextField from "../FormComponents/TextField";
import * as Yup from "yup";
import { Controller, set, useForm } from "react-hook-form";
import CustomButton from "../FormComponents/Button";
import DocumentViewer from "../DocumentViewer";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fileItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    backgroundColor: "#f0f0f0",
  },
  fileName: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
}));

const fileIcons = {
  pdf: faFilePdf,
  doc: faFileWord,
  docx: faFileWord,
  xls: faFileExcel,
  xlsx: faFileSpreadsheet,
  csv: faFileCsv,
  txt: faFileLines,
  png: faFileImage,
  jpg: faFileImage,
  jpeg: faFileImage,
  gif: faFileImage,
  ppt: faFilePowerpoint,
};

const CustomDocumentUpload = (props) => {
  const {
    documentFields,
    sourceName,
    documentSources,
    uploadedDocumentAttechment,
    open,
    fullWidth,
    modalTitle = "",
    maxWidth = "md",
    handleClose,
    referenceId,
    loading,
    documentReferenceId,
    attachmentId = null,
    isShowClosed = false,
    employeeId = null,
    docSelectionON = false,
    approvalBtn = false,
  } = props;
  const classes = useStyles();
  const fileInputs = useRef([]);
  const [options, setOptionsList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isDocumentViewer, setIsDocumentViewer] = useState(false);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentTitle, setDocumentTitle] = useState(null);
  const [docSelectedID, setDocSelectedID] = useState(null);

  console.log("dasdaddada", docSelectionON, approvalBtn);
  const handleOpen = (documentFile, filename) => {
    setIsDocumentViewer(true);
    setDocumentFile(documentFile);
    setDocumentTitle(filename);
  };
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    register,
    formState: { errors },
  } = useForm({});

  const urlValue = useParams();
  console.log("dsfjsdfhjdsjfhjdhf", errors);

  useEffect(() => {
    props?.getDocumentSources();
  }, []);

  useEffect(() => {
    if (!isEmpty(attachmentId)) {
      setOptionsList(
        documentFields?.lkAttachments?.filter((obj) => obj.id == attachmentId)
      );
    } else {
      setOptionsList(documentFields?.lkAttachments);
    }
    if (!docSelectedID) {
      setDocSelectedID(
        documentFields?.lkAttachments?.length
          ? documentFields?.lkAttachments[0]["id"]
          : null
      );
    }
  }, [documentFields]);

  const documentsouceDependency = useMemo(() => {
    if (documentSources.length) {
      return JSON.stringify(documentSources);
    } else {
      return "";
    }
  }, [documentSources]);

  useEffect(() => {
    if (!!documentSources.length && sourceName) {
      handleOptionChange(
        documentSources?.find((item) => item?.sourceName === sourceName).id
      );
      props?.getDocumentFields({
        sourceID: documentSources.find(
          (item) => item?.sourceName === sourceName
        ).id,
      });
    }
  }, [documentsouceDependency]);

  useEffect(() => {
    handleReset();
    if (!sourceName) {
      handleOptionChange(null);
      setOptionsList([]);
    }
  }, [open]);

  useEffect(() => {
    if (
      !!documentSources.length &&
      !!uploadedDocumentAttechment?.uploadedDocuments?.length
    ) {
      const obj = {
        [documentSources?.find((item) => item?.sourceName === sourceName)
          ?.sourceName + "documentattachment"]: documentSources?.find(
          (item) => item?.sourceName === sourceName
        )?.sourceName,
        ["uploadedDocument"]: uploadedDocumentAttechment,
      };

      localStorage.setItem(
        "uploadedUpdateDocumentAttechment",
        JSON.stringify(obj)
      );

      handleClose();
    }
  }, [uploadedDocumentAttechment]);

  const handleOptionChange = (option) => {
    handleReset();
    !!option ? setSelectedOptions(option) : setSelectedOptions(null);
    if (option) {
      props?.getDocumentFields({ sourceID: option });
    } else {
      props?.getDocumentFields({ sourceID: -1 });
    }
    setValue("selectSource", option);
  };

  const handleOptionDocumentChange = (option) => {
    console.log(option);
    setDocSelectedID(option);
    // handleReset();
    // !!option ? setSelectedOptions(option) : setSelectedOptions(null);
    // if (option) {
    //     props?.getDocumentFields({ sourceID: option });
    // } else {
    //     props?.getDocumentFields({ sourceID: -1 });
    // }
    // setValue("selectSource", option);
  };

  const handleUploadChange = (e, index, attachmentId) => {
    console.log("DocSelectedID", attachmentId);
    const file = e;
    if (!file || (file && file?.url)) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const updatedUploadFiles = [...uploadFiles];
      const payload = {
        DocumentFile: reader.result.toString(),
        FileName: `${
          documentSources?.find((item) => item.id == selectedOptions)
            ?.sourceName
        }-${options[index]?.contentType}-${addTrailingZeros(
          attachmentId
        )}-${new Date()
          .toLocaleString("en-GB")
          .replace(/\s/g, "")
          .split(",")
          .join("-")}.${file?.name.split(".")[1]}`,
        attachmentId: attachmentId,
      };
      updatedUploadFiles[index] = payload;
      setUploadFiles(updatedUploadFiles);
      setValue(
        `documentAttachmentReqDTOs[${index}].documentUrl`,
        payload?.DocumentFile
      );
      setValue(
        `documentAttachmentReqDTOs[${index}].documentName`,
        payload?.FileName
      );
      setValue(
        `documentAttachmentReqDTOs[${index}].sourceId`,
        documentSources?.find((item) => item.id == selectedOptions)?.id
      );
      setValue(
        `documentAttachmentReqDTOs[${index}].attachmentId`,
        payload?.attachmentId
      );
      setValue(`documentAttachmentReqDTOs[${index}].employeeId`, employeeId);
      updatedUploadFiles[index].DocumentFileAsBinary =
        URL.createObjectURL(file);
      if (referenceId || documentReferenceId) {
        setValue(
          `documentAttachmentReqDTOs[${index}].referenceId`,
          referenceId || documentReferenceId
        );
      }
    };
    reader.onerror = () => {
      console.log("error on load document");
    };
  };

  const getIconForExtension = (extension) => {
    return fileIcons[extension] || faFileLines;
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop().toLowerCase();
  };

  const handleDelete = (index) => {
    const newArray = [...uploadFiles];
    const indexToRemove = newArray[index];
    if (indexToRemove) {
      newArray[index] = null;
      setUploadFiles(newArray);
      setValue(`documentAttachmentReqDTOs`, newArray);
    }
  };

  const handleReset = () => {
    setUploadFiles([]);
    reset();
  };

  const onSubmit = async (data) => {
    console.log("dasdsdsadas", data);

    let documentAttachmentReqDTOs = data?.documentAttachmentReqDTOs.filter(
      (obj) => obj.hasOwnProperty("attachmentId")
    );

    let { HrControl } = urlValue;

    if (HrControl && documentAttachmentReqDTOs?.[0]?.referenceId) {
      documentAttachmentReqDTOs[0].referenceId = HrControl;
    }

    try {
      const payload = {
        sourceName: documentSources?.find((item) => item.id === selectedOptions)
          .sourceName,
        documentAttachmentReqDTOs,
      };

      props?.postDocuments(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const addTrailingZeros = (inputString) => {
    const length = Number(inputString).toString().length;
    if (length >= 11) {
      return inputString;
    }
    const numberOfZerosToAdd = 11 - length;
    const zeros = "0".repeat(numberOfZerosToAdd);
    return zeros + inputString;
  };

  return (
    <>
      {isDocumentViewer && (
        <DocumentViewer
          open={isDocumentViewer}
          setOpen={setIsDocumentViewer}
          url={documentFile}
          title={documentTitle}
          fullWidth
          maxWidth
          approvalBtn={false}
        />
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClick={(e) => e.stopPropagation()}
        onClose={(event, reason) => {
          if (
            reason &&
            (reason == "backdropClick" || reason == "escapeKeyDown")
          )
            return;
          handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="id"
          style={{ padding: "0 !important" }}
          className="pb-0"
        >
          <Box display="flex" alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h5" fontWeight={500}>
              {modalTitle}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className="mt-3">
          <form id="documentUpload" onSubmit={handleSubmit(onSubmit)}>
            {loading && loadingPanel()}

            {sourceName ? null : (
              <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4} className="mb-3 gap-2">
                  <CustomAutocomplete
                    name={"selectSource"}
                    onChange={(event) => {
                      handleOptionChange(event);
                    }}
                    value={sourceName && selectedOptions}
                    label="Select Document"
                    placeholder="Select Document"
                    options={documentSources}
                    keyName={"id"}
                    labelName={"sourceName"}
                    disabled={!!sourceName}
                    inputProps={{
                      ...register(`selectSource`, {
                        required: "Required",
                      }),
                    }}
                    errorMessage={errors?.selectSource?.message}
                  />
                </Grid>
              </Grid>
            )}
            {!!options &&
              !docSelectionON &&
              options.map((obj, index) => (
                <Grid
                  className="mt-20"
                  container
                  padding={2}
                  style={{ border: "1px solid #000" }}
                >
                  <Grid
                    className="d-flex justify-content-center align-items-center"
                    item
                    xs={12}
                    md={3}
                  >
                    <Typography>{obj?.contentType}</Typography>
                  </Grid>
                  <Grid
                    className="d-flex justify-content-center align-items-center px-3"
                    item
                    xs={12}
                    md={4}
                  >
                    <Controller
                      name={`documentAttachmentReqDTOs[${index}].remarks`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomTextField
                            onChange={onChange}
                            value={!!value ? value : ""}
                            type="text"
                            label="Remarks"
                            placeholder="Remarks"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    className="d-flex justify-content-center align-items-center px-3"
                    container
                    xs={12}
                    md={4}
                  >
                    {!uploadFiles[index] || !uploadFiles[index].FileName ? (
                      <>
                        <Grid
                          className="d-flex justify-content-center align-items-center mt-2"
                          item
                          xs={12}
                          md={12}
                        >
                          <Button
                            className={`gap-1 bg-primary`}
                            style={{
                              textTransform: "none",
                              color: "white",
                            }}
                            variant="contained"
                            fullWidth
                            size="large"
                            color="primary"
                            onClick={() => fileInputs.current[index].click()}
                          >
                            Select Document
                          </Button>

                          <input
                            {...register(
                              `documentAttachmentReqDTOs[${index}].documentUrl`,
                              {
                                required: obj?.isRequired
                                  ? "File is required"
                                  : false,
                                validate: {
                                  isPDF: (value) => {
                                    if (value) {
                                      return (
                                        value?.match(
                                          /[^:]\w+\/[\w-+\d.]+(?=;|,)/
                                        )[0] === "application/pdf" ||
                                        "Only PDF files are allowed"
                                      );
                                    } else {
                                      return true;
                                    }
                                  },
                                },
                              }
                            )}
                            ref={(el) => (fileInputs.current[index] = el)}
                            accept="application/pdf"
                            className="hidden"
                            name={`documentAttachmentReqDTOs[${index}].documentUrl`}
                            onChange={(event) => {
                              handleUploadChange(
                                event.target.files[0],
                                index,
                                obj.id
                              );
                            }}
                            type="file"
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        className="d-flex justify-content-center align-items-center mt-2"
                        item
                        xs={12}
                        md={12}
                      >
                        {uploadFiles[index] && uploadFiles[index].FileName && (
                          <Paper
                            elevation={4}
                            className={`${classes.fileItem} px-4`}
                          >
                            <FontAwesomeIcon
                              className="fa-duotone font-primary"
                              icon={getIconForExtension(
                                getFileExtension(uploadFiles[index]?.FileName)
                              )}
                              size="2x"
                            />
                            <Typography
                              variant="body1"
                              className={`${classes.fileName} mx-2`}
                            >
                              {uploadFiles[index].FileName}
                            </Typography>
                            <Tooltip title="Remove" arrow>
                              <IconButton
                                edge="end"
                                aria-label="remove"
                                onClick={() => handleDelete(index)}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </Tooltip>
                          </Paper>
                        )}
                      </Grid>
                    )}
                    <Grid
                      className="d-flex justify-content-center align-items-center px-3"
                      item
                      xs={12}
                      md={12}
                    >
                      {errors?.documentAttachmentReqDTOs && (
                        <span style={{ color: "red" }}>
                          {
                            errors.documentAttachmentReqDTOs[index]?.documentUrl
                              ?.message
                          }
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    className="d-flex justify-content-center align-items-center"
                    item
                    xs={12}
                    md={1}
                  >
                    {uploadFiles[index] && uploadFiles[index].FileName && (
                      <Tooltip title="View Document" arrow>
                        <IconButton
                          edge="end"
                          aria-label="remove"
                          onClick={() =>
                            handleOpen(
                              uploadFiles[index].DocumentFileAsBinary,
                              uploadFiles[index].FileName
                            )
                          }
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              ))}
            {!!options && docSelectionON && (
              <Grid
                className="mt-20"
                container
                padding={2}
                style={{ border: "1px solid #000" }}
              >
                <Grid
                  className="d-flex justify-content-center align-items-center"
                  item
                  xs={12}
                  md={3}
                >
                  {/* <CustomAutocomplete
							key={'0'}
							size="medium"
							className={"w-75"}
							// value={selectedOptions[0]}
							onChange={(event, newValue) => handleOptionChange(newValue, 0)}
							id={`controllable-states-demo-${0}`}
							options={options}
							// disabled={uploadFiles[0] && !!uploadFiles[0].FileName}
							renderInput={(params) => (
								<TextField {...params} label="Select Document" />
							)}
						/> */}

                  {console.log("dsfdsfdsf", options, docSelectedID)}
                  <CustomAutocomplete
                    // name={"selectSource"}
                    onChange={(event) => {
                      handleOptionDocumentChange(event);
                    }}
                    // value={sourceName && selectedContentType}
                    label="Select Document"
                    placeholder="Select Document"
                    options={options}
                    value={docSelectedID}
                    keyName={"id"}
                    labelName={"contentType"}
                    // disabled={!!options}
                    // inputProps={{
                    //     ...register(
                    //         `selectSource`,
                    //         {
                    //             required: true,
                    //         }
                    //     )
                    // }}
                    // errorMessage={errors?.selectSource?.message}
                  />
                </Grid>
                <Grid
                  className="d-flex justify-content-center align-items-center px-3"
                  item
                  xs={12}
                  md={4}
                >
                  <Controller
                    name={`documentAttachmentReqDTOs.remarks`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomTextField
                          onChange={onChange}
                          value={!!value ? value : ""}
                          type="text"
                          label="Remarks"
                          placeholder="Remarks"
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid
                  className="d-flex justify-content-center align-items-center px-3"
                  container
                  xs={12}
                  md={4}
                >
                  {!uploadFiles[0] || !uploadFiles[0].FileName ? (
                    <>
                      <Grid
                        className="d-flex justify-content-center align-items-center mt-2"
                        item
                        xs={12}
                        md={12}
                      >
                        <Button
                          className={`gap-1 bg-primary`}
                          style={{
                            textTransform: "none",
                            color: "white",
                          }}
                          variant="contained"
                          fullWidth
                          size="large"
                          color="primary"
                          onClick={() => fileInputs.current[0].click()}
                        >
                          Select Document
                        </Button>

                        <input
                          {...register(
                            `documentAttachmentReqDTOs[${0}].documentUrl`,
                            {
                              required: true,
                              validate: {
                                isPDF: (value) => {
                                  if (value) {
                                    return (
                                      value?.match(
                                        /[^:]\w+\/[\w-+\d.]+(?=;|,)/
                                      )[0] === "application/pdf" ||
                                      "Only PDF files are allowed"
                                    );
                                  } else {
                                    return true;
                                  }
                                },
                              },
                            }
                          )}
                          ref={(el) => (fileInputs.current[0] = el)}
                          accept="application/pdf"
                          className="hidden"
                          name={`documentAttachmentReqDTOs[${0}].documentUrl`}
                          onChange={(event) => {
                            handleUploadChange(
                              event.target.files[0],
                              0,
                              docSelectedID
                            );
                          }}
                          type="file"
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid
                      className="d-flex justify-content-center align-items-center mt-2"
                      item
                      xs={12}
                      md={12}
                    >
                      {uploadFiles[0] && uploadFiles[0].FileName && (
                        <Paper
                          elevation={4}
                          className={`${classes.fileItem} px-4`}
                        >
                          <FontAwesomeIcon
                            className="fa-duotone font-primary"
                            icon={getIconForExtension(
                              getFileExtension(uploadFiles[0]?.FileName)
                            )}
                            size="2x"
                          />
                          <Typography
                            variant="body1"
                            className={`${classes.fileName} mx-2`}
                          >
                            {uploadFiles[0].FileName}
                          </Typography>
                          <Tooltip title="Remove" arrow>
                            <IconButton
                              edge="end"
                              aria-label="remove"
                              onClick={() => handleDelete(0)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </Tooltip>
                        </Paper>
                      )}
                    </Grid>
                  )}
                  <Grid
                    className="d-flex justify-content-center align-items-center px-3"
                    item
                    xs={12}
                    md={12}
                  >
                    {errors?.documentAttachmentReqDTOs && (
                      <span style={{ color: "red" }}>
                        {
                          errors.documentAttachmentReqDTOs[0]?.documentUrl
                            ?.message
                        }
                      </span>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className="d-flex justify-content-center align-items-center"
                  item
                  xs={12}
                  md={1}
                >
                  {uploadFiles[0] && uploadFiles[0].FileName && (
                    <Tooltip title="View Document" arrow>
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() =>
                          handleOpen(
                            uploadFiles[0].DocumentFileAsBinary,
                            uploadFiles[0].FileName
                          )
                        }
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            )}
            {/* end of fileselection ON */}
            <Grid
              item
              xs={12}
              sm={12}
              display={"flex"}
              justifyContent={"center"}
              marginTop={5}
              gap={2}
            >
              <CustomButton text="Submit" icon={faSave} type="submit" />
              <CustomButton
                onClick={() => handleReset()}
                text={"Reset"}
                icon={faUndo}
              />
              {!isShowClosed && (
                <CustomButton
                  onClick={(evt) => {
                    evt.stopPropagation();
                    handleClose();
                  }}
                  text={"Close"}
                />
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documentState: state,
    documentFields: state.DocumentUploadReducer.documentFields,
    documentSources: state.DocumentUploadReducer.documentSources,
    uploadedDocumentAttechment:
      state.DocumentUploadReducer.uploadedDocumentAttechment,
    loading: state.DocumentUploadReducer.loading,
    documentReferenceId:
      state.DocumentUploadReducer?.uploadedDocumentAttechment?.referenceId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDocumentFields,
      getDocumentSources,
      postDocuments,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDocumentUpload);
