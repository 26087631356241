// import { takeEvery,put,call } from "redux-saga/effects";
// import{
//     NPAPOPUP
// } from "./actiontype"
// import{
//     NpaPopupSuccess,
//     NpaPopupFail,
// } from "./action"
// import{
//     NpaPopup,
// } from "../../_helper/services/npaa"

// function * getNpa({payload:{employeeID,HrControl}}){
//     try{
//         const response= yield call(NpaPopup,{employeeID,HrControl});
//         yield put (NpaPopupSuccess(response));
//         console.log("responce",response)
//     } catch (error){
//     yield put ( NpaPopupFail(error.message))
// }
// }
// function * CartSaga(){
//     yield takeEvery(NPAPOPUP,getNpa)
// }
// export default CartSaga
import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import { NPAPOPUP, NPA_ACTION_DELETE } from "./actiontype";
import {NpaDetailsSuccess,NpaDetailsFail, deleteNPAActionSucess, deleteNPAActionFail} from "./action"
import { NpaPop, deleteActionNPA } from "../../_helper/services/npaa";
import { ShowError, ShowSucess } from "../../_helper/helper";

function * getNpa({payload:{employeeID, HrControl,NatureActionCode}}){
    try{
        const responce = yield call (NpaPop,{employeeID,HrControl,NatureActionCode});
        yield put (NpaDetailsSuccess(responce));
         
    } catch (error){
        yield put (NpaDetailsFail(error.message))
    }
}
function* onDeleteActionNPA({ payload }) {
    try {
      const response = yield call(deleteActionNPA, payload);
    //   console.log("paylaod in saga", payload, response);
      if (response?.status == 200 || 204) {
        yield put(deleteNPAActionSucess(response));
        ShowSucess(
            // `${payload?.isCancel ? "Cancelled " : "Deleted "}Successfully`,
            "Deleted Successfully",
            {
                position: "top-right",
            }
        );
      }
    } catch (error) {
      yield put(deleteNPAActionFail(error.response));
    //   console.log("11111 in saga fail ::: ",error)
      ShowError(error?.response?.message || error?.response?.data?.message || error?.response?.statusText);
    }
}
function * CartSaga(){
    yield takeEvery(NPAPOPUP,getNpa)
    yield takeLatest(NPA_ACTION_DELETE, onDeleteActionNPA);

}
export default CartSaga