import {
  JOB_ANNOUCEMENT_DETAILS,
  JOB_ANNOUCEMENT_DETAILS_SUCCESS,
  JOB_ANNOUCEMENT_DETAILS_FAIL,
  JOB_ANNOUNCEMENT_CREATE,
  JOB_ANNOUNCEMENT_CREATE_FAIL,
  JOB_ANNOUNCEMENT_CREATE_SUCCESS,
  JOB_ANNOUCEMENT_DELETE,
  JOB_ANNOUCEMENT_DELETE_FAIL,
  JOB_ANNOUCEMENT_DELETE_SUCCESS,
  JOB_ANNOUNCEMENT_UPDATE,
  JOB_ANNOUNCEMENT_UPDATE_FAIL,
  JOB_ANNOUNCEMENT_UPDATE_SUCCESS,
  SHOWN_TOASTER,
} from "./actionTypes";

export const getJobAnnouncementDetails = (id) => {
  return {
    type: JOB_ANNOUCEMENT_DETAILS,
    payload: id,
  };
};

export const getJobAnnouncementDetailsSuccess = (post) => {
  return {
    type: JOB_ANNOUCEMENT_DETAILS_SUCCESS,
    payload: post,
  };
};

export const getJobAnnouncementDetailsFail = (error) => {
  return {
    type: JOB_ANNOUCEMENT_DETAILS_FAIL,
    payload: error,
  };
};

export const createJobAnnouncement = (payload) => {
  return {
    type: JOB_ANNOUNCEMENT_CREATE,
    payload: payload,
  };
};
export const createJobAnnouncementSuccess = (response) => {
  return {
    type: JOB_ANNOUNCEMENT_CREATE_SUCCESS,
    payload: response,
  };
};
export const createJobAnnouncementFail = (error) => {
  return {
    type: JOB_ANNOUNCEMENT_CREATE_FAIL,
    payload: error,
  };
};

export const updateJobAnnouncement = (payload) => {
  return {
    type: JOB_ANNOUNCEMENT_UPDATE,
    payload: payload,
  };
};
export const updateJobAnnouncementSuccess = (response) => {
  return {
    type: JOB_ANNOUNCEMENT_UPDATE_SUCCESS,
    payload: response,
  };
};
export const updateJobAnnouncementFail = (error) => {
  return {
    type: JOB_ANNOUNCEMENT_UPDATE_FAIL,
    payload: error,
  };
};

export const deleteJobAnnouncement = (payload) => {
  return {
    type: JOB_ANNOUCEMENT_DELETE,
    payload: payload,
  };
};
export const deleteJobAnnouncementSuccess = (response) => {
  return {
    type: JOB_ANNOUCEMENT_DELETE_SUCCESS,
    payload: response,
  };
};
export const deleteJobAnnouncementFail = (error) => {
  return {
    type: JOB_ANNOUCEMENT_DELETE_FAIL,
    payload: error,
  };
};
export const reset = () => {
  return {
    type: "RESET",
  };
};
export const savedIsToasterShown = (payload) => {
  return {
    type: SHOWN_TOASTER,
    payload: payload,
  };
};
