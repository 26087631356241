/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterAllowedColumn } from "../../_helper/helper";
import { ShowError, ShowSucess } from "../../_helper/helper";
import {
  Add,
  DeleteForeverRounded,
  ExpandMore,
  RemoveCircleOutline,
  Save,
  SearchOffTwoTone,
} from "@mui/icons-material";
import axios from "axios";
import { Typography } from "@mui/material";
import { Card, CardBody, Button } from "reactstrap";
import { FloatingLabel } from "@progress/kendo-react-labels";
import { TextBox } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  advanceSearch,
  savedIsToasterShown,
  findAsyouType,
  getAllAdvanceSearch,
  getAdvanceSearchById,
  loadColumns,
  saveAdvanceSearch,
  SaveQuery,
  SaveLocation,
  deleteSearchSaveQuery,
} from "../../store/advanceSearchv2/actions";
import ModalCustom from "../../Components/AdvanceSearch/component/Modal";
import SaveNewQuery from "./SaveNewQuery";
import { userGroupStore } from "../../Components/AdvanceSearch/slices/querySlice";
import { toast } from "react-toastify";
import { useAuth } from "oidc-react";
import HistoryDataTable from "../HistoryDataTable";
import { sortingFunc } from "../CommonFunction";
import {
  faEye,
  faTrash,
  faSearch,
  faGrinTears,
} from "@fortawesome/pro-duotone-svg-icons";
import HistoryColumns from "./HistoryColumns";
import { Btn } from "../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTimeline } from "@fortawesome/pro-duotone-svg-icons";
import { faSave } from "@fortawesome/pro-duotone-svg-icons";

function AdvanceSearch({
  gridId = "",
  expanded,
  columnList,
  searchParams = [],
  tableName,
  columnRefernces = [],
  pageNumber,
  pageSize,
  additionalWhere,
  sortData,
  location,
  forceParams,
  deleteData,
  chooseFilterMethod,
  postingResult,
  getParamData,
  setChooseFilterMethod,
  isActive,
  role = "",
}) {
  let counterAdvanceSearch = null;
  const dispatch = useDispatch();
  const {
    filter: storeFilter,
    dataState: storeDataState,
    sort: storeSort,
    skip: storeSkip,
    pageSize: storePageSize,
    additionalWhere: storeAdditionalWhere,
  } = useSelector((state) => {
    return state.CommonReducer;
  });
  const { GridData = {} } = useSelector(
    (state) => state?.ThemeCustomizerReducer || {}
  );
  const {
    columns,
    lodingSaveType,
    findAsyouTypeResults,
    loadingFindAsYouType,
    searchResults,
    error,
    savedQuery,
    savedLocation,
    userSavedQueries,
    getAdavanceSearchType,
    getAdavanceSearch,
    deleteResults,
    saveQueriesData,
    hasShownToaster,
    searchParameter: storeSearchParameter,
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const URL = useLocation();
  const [expandedSearchTools, setExpandedSearchTools] = useState(expanded);
  const [isSearched, setIsSearched] = useState(false);
  const [existingColumnFilter, setExistingColumnFilter] = useState([
    { id: "original", value: "" },
  ]);
  const [isDefaultQuery, setIsDefaultQuery] = React.useState(false);

  const handleChange = (event) => {
    setIsDefaultQuery(event.target.checked);
  };
  const [dropdownFilter, setDropdownFilter] = useState({});
  const [columnLastId, setColumnLastId] = useState("");
  const [newColumnFilter, setNewColumnFilter] = useState("");
  const [columnsData, setColumnsData] = React.useState([
    { id: "original", columns },
  ]);
  const { userData } = useAuth();

  const [SortData, setSortData] = React.useState([
    // { field: " queryName", dir: "asc" },
  ]);
  const [historyData, setHistoryData] = React.useState([]);
  const [currentFindAsType, setCurrentFindAsType] = useState("");
  const [parameterChange, setParameterChange] = useState({ dt: "Initial" });
  const [sortedColumns, setSortedColumns] = useState([]);
  const [firstTimeToaster, setFirstTimeToaster] = useState(false);
  const [open, setOpen] = useState({
    saveOpen: false,
    selectOpen: false,
  });

  useEffect(() => {
    if (
      open?.saveOpen === true &&
      getAdavanceSearch !== null &&
      getAdavanceSearch?.advanceSearchQuery !== null
    ) {
      const { isDefault, permissionValue, queryName, id } =
        getAdavanceSearch?.advanceSearchQuery || {};
      setSaveParameter({
        queryName: queryName,
        role: permissionValue,
        queryId: id,
      });
      setIsDefaultQuery(isDefault);
    }
  }, [open?.saveOpen]);
  const actions = useMemo(() => {
    return [
      {
        title: "Show Details",
        action: async (props) => {
          return await dispatch(
            getAdvanceSearchById(
              {
                location: "getAdvanceSearchDetails",
              },
              {
                params: {
                  qID: props?.dataItem?.id,
                },
              }
            )
          );
        },
        icon: { iconName: "k-icon  k-i-eye" },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
      {
        title: "delete",
        icon: { iconName: "k-icon  k-i-delete" },
        className: "font-primary",
        style: { cursor: "pointer" },
        action: async (props) => {
          await deleteQuery(props?.dataItem);
        },
      },
    ];
  });

  useEffect(() => {
    if (getAdavanceSearchType === "GET_ADVANCE_SEARCH_BYID_SUCCESS") {
      let para = [];
      let localData = [...columnsData];

      getAdavanceSearch?.advanceSearchParamters?.forEach((item, index) => {
        const elmID =
          new Date().getTime() * (Math.random() * (9999 - 1000) + 1000);
        para.push({
          columnName: item.columnName,
          value: item.paramValue,
          operator: item.operatorName,
          id: elmID,
          dataType: item.paramType,
        });
        const clm = localData.find((p) => p.id === elmID);

        if (clm) {
          localData.push({ id: elmID, columns: [...columns] });
        } else {
          localData.push({ id: elmID, columns: [...columns] });
        }
      });
      setColumnsData(localData);
      setSearchParameter({
        ...searchParameter,
        searchParams: para,
        qID: getAdavanceSearch?.advanceSearchQuery.id,
      });
      notifyParameterChange();
      setOpen({ ...open, selectOpen: !open });
    }
    if (getAdavanceSearchType === "GET_ADVANCE_SEARCH_BYID_FAIL") {
      setOpen({ ...open, selectOpen: !open });
      ShowError(error.message, {
        position: "top-right",
      });
      // setState(false)
    }
  }, [getAdavanceSearchType]);

  useEffect(() => {
    if (forceParams) {
      //debugger;

      setSearchParameter({ ...searchParameter, searchParams: forceParams });
      notifyParameterChange();
      // handleSearch(true,{...searchParameter,searchParams:forceParams});
    }
  }, [forceParams]);

  const handleSearch = (isTrue, newSearchfiled) => {
    // let searchParams = searchParameter.searchParams;
    if (isTrue && checkObjectProperties(newParamater)) {
      setIsSearched(isTrue);
      // searchParams = [...searchParams, newParamater];
      handleNewParameter();
    }
    const newsearchParameter = newSearchfiled
      ? newSearchfiled
      : searchParameter;
    const {
      storeAdditionalWhere: StoreAdditionalWhere,
      storeDataState: StoreDataState,
      storePageNumber,
      storePageSize,
    } = (GridData && Object.keys(GridData)?.length > 0 && GridData) || {};

    let obj = { ...storeSearchParameter };
    obj[gridId] = {
      ...newsearchParameter,
      searchParams: JSON.stringify(
        searchParameter.searchParams.filter((d) => d.columnName !== "")
      ),
      location: location,
      id: gridId,
      role,
      pageNumber:
        storePageNumber && storePageNumber[gridId]
          ? storePageNumber[gridId]
          : pageNumber,
      pageSize:
        storePageSize && storePageSize[gridId]
          ? storePageSize[gridId]
          : pageSize,
      additionalWhere:
        StoreAdditionalWhere && StoreAdditionalWhere[gridId]
          ? StoreAdditionalWhere[gridId]
          : additionalWhere,
      sortData:
        StoreDataState && StoreDataState[gridId] && StoreDataState[gridId]?.sort
          ? StoreDataState[gridId]?.sort.reduce(
              (accumulator, item) =>
                (accumulator === "" ? " " : accumulator + ", ") +
                item.field +
                " " +
                item.dir,
              ""
            )
          : sortData && sortData?.length > 0
          ? sortData.reduce(
              (accumulator, item) =>
                (accumulator === "" ? " " : accumulator + ", ") +
                item.field +
                " " +
                item.dir,
              ""
            )
          : "",
    };

    dispatch(advanceSearch(obj, gridId));

    if (typeof getParamData === "function") {
      getParamData({
        ...searchParameter,
        searchParams: JSON.stringify(searchParams),
      });
    }
  };
  const debounce = (func, delay = 500) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  const value = {
    dt: new Date().toISOString(),
    [`"${new Date().toISOString()}"`]: new Date().toISOString(),
  };
  const optimizedVersion = useCallback(
    debounce(() => setParameterChange(value), 900),
    [parameterChange]
  );

  const notifyParameterChange = () => {
    optimizedVersion();

    // if (counterAdvanceSearch != null) {
    //   clearTimeout(counterAdvanceSearch);
    //   counterAdvanceSearch = null;
    // }
    // counterAdvanceSearch = setTimeout(() => {
    //   const value = {
    //     dt: new Date().toISOString(),
    //     [`"${new Date().toISOString()}"`]: new Date().toISOString(),
    //   };
    //   setParameterChange(value);
    //   clearTimeout(counterAdvanceSearch);
    //   counterAdvanceSearch = null;
    // }, 1000);
  };
  const deleteQuery = async (item) => {
    const queryId = item.id;
    dispatch(
      deleteSearchSaveQuery({
        qID: parseInt(queryId),
      })
    );
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
  };
  useEffect(() => {
    dispatch(loadColumns(tableName));
  }, []);
  useEffect(() => {
    if (chooseFilterMethod === "normalSearch") {
    } else {
      const callAPI = () => {
        dispatch(
          getAllAdvanceSearch({
            // tableName: "",
            location: "findSavedSearch",
            permissionType: "",
            permissionValue: "",
            queryName: "",
            tableName: tableName,
          })
        );
      };

      callAPI();
    }

    // if (deleteResults && deleteResults?.data?.message !== null) {
    //   ShowSucess(deleteResults?.data?.message, { position: "top-right" });
    //   callAPI();
    // }
  }, [chooseFilterMethod, tableName]);

  useEffect(() => {
    if (userSavedQueries.length !== 0) {
      setHistoryData(userSavedQueries);
    }
  }, [userSavedQueries]);
  useEffect(() => {
    if (isActive) {
      setSearchParameter({
        ...searchParameter,
        additionalWhere: `Emp_Status Like '${isActive}'`,
      });
      notifyParameterChange();
    }
  }, [isActive]);

  useEffect(() => {
    if (userSavedQueries.length !== 0) {
      const defaultQueryArr = userSavedQueries.find(
        (d) => d.isDefault === true
      );
      if (defaultQueryArr && tableName === defaultQueryArr.viewName) {
        dispatch(
          getAdvanceSearchById(
            {
              location: "getAdvanceSearchDetails",
            },
            {
              params: {
                qID: defaultQueryArr.id,
              },
            }
          )
        );
      }
    }
  }, [userSavedQueries, dispatch]);

  useEffect(() => {
    if (historyData.length > 0) {
      sortingFunc(SortData, historyData, setHistoryData);
    }
  }, [SortData]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      var data = [];
      data.push({ id: "original", columns: columns?.slice() });
      searchParams.forEach((item) => {
        if (
          item.columnName !== null &&
          item.columnName !== undefined &&
          item.columnName !== ""
        ) {
          data.push({ id: item.id, columns: columns?.slice() });
        }
      });
      setColumnsData(data);
    } else {
      setColumnsData([{ id: "original", columns: columns?.slice() }]);
    }
  }, [columns, searchParams]);
  useEffect(() => {
    setSearchParameter({
      ...searchParameter,
      tableName,
      // searchParams:searchParams,
      pageNumber: pageNumber,
      pageSize: pageSize,
      additionalWhere: additionalWhere,
      sortData:
        sortData && sortData?.length > 0
          ? sortData.reduce(
              (accumulator, item) =>
                (accumulator === "" ? " " : accumulator + ", ") +
                item.field +
                " " +
                item.dir,
              ""
            )
          : "",
    });
    notifyParameterChange();
  }, [pageSize, pageNumber, additionalWhere, sortData, role, tableName]);

  useEffect(() => {
    const sortedData = filterAllowedColumn(userData, HistoryColumns, "id");
    setSortedColumns(sortedData);
  }, [SortData]);
  useEffect(() => {
    if (gridId) {
      let StoredPageSize;
      let StoredSkip;
      let StoredAdditionalWhere;
      let StoredSort;
      let StoreDataState;
      if (GridData && Object.keys(GridData).length > 0) {
        StoreDataState = GridData?.storeDataState
          ? GridData?.storeDataState[gridId]
          : null;
        StoredPageSize = GridData?.storePageSize
          ? GridData?.storePageSize[gridId]
          : null;
        StoredSkip = GridData?.storeSkip ? GridData?.storeSkip[gridId] : null;
        StoredAdditionalWhere = GridData?.storeAdditionalWhere
          ? GridData?.storeAdditionalWhere[gridId]
          : null;
        StoredSort =
          GridData?.StoredSort &&
          GridData?.StoredSort[gridId] &&
          GridData?.StoredSort[gridId].length > 0
            ? GridData?.StoredSort[gridId]
            : StoreDataState?.sort;
      }
      let pageSize = StoredPageSize ?? storePageSize[gridId];
      let skip = StoredSkip ?? storeSkip[gridId];
      let additionalWhere =
        storeAdditionalWhere[gridId] ?? StoredAdditionalWhere;
      let sort =
        StoredSort && StoredSort?.length > 0 ? StoredSort : storeSort[gridId];
      const storeParams = {};
      if (pageSize > 0) {
        storeParams["pageNumber"] = skip / pageSize + 1;
        storeParams["pageSize"] = pageSize;
      }
      if (additionalWhere) {
        storeParams["additionalWhere"] = additionalWhere;
      }
      if (sort) {
        storeParams["sortData"] = sort.reduce(
          (accumulator, item) =>
            (accumulator === "" ? " " : accumulator + ", ") +
            item.field +
            " " +
            item.dir,
          ""
        );
      }
      //if (Object.keys(storeParams).length > 0) {
      setSearchParameter({
        ...searchParameter,
        ...storeParams,
        role,
      });
      // !GridData?.id &&

      notifyParameterChange();
      // }
    }
  }, [
    GridData,
    gridId,
    storePageSize,
    storeSkip,
    // storeAdditionalWhere,
    storeSort,
    JSON.stringify(storeAdditionalWhere),
    pageSize,
    pageNumber,
    role,
  ]);

  // useEffect(() => {
  //   if (gridId) {
  //     let pageSize = storePageSize[gridId];
  //     let skip = storeSkip[gridId];
  //     let additionalWhere = storeAdditionalWhere[gridId];
  //     let sort = storeSort[gridId];
  //     const storeParams = {};
  //     if (pageSize > 0) {
  //       storeParams["pageNumber"] = skip / pageSize + 1;
  //       storeParams["pageSize"] = pageSize;
  //     }
  //     // if (additionalWhere || additionalWhere === "") {
  //     storeParams["additionalWhere"] = additionalWhere;
  //     // }
  //     if (sort) {
  //       storeParams["sortData"] = sort.reduce(
  //         (accumulator, item) =>
  //           (accumulator === "" ? " " : accumulator + ", ") +
  //           item.field +
  //           " " +
  //           item.dir,
  //         ""
  //       );
  //     }
  //     //if (Object.keys(storeParams).length > 0) {
  //     setSearchParameter({
  //       ...searchParameter,
  //       ...storeParams,
  //       role,
  //     });
  //     notifyParameterChange();
  //     // }
  //   }
  // }, [
  //   gridId,
  //   storePageSize,
  //   storeSkip,
  //   // storeAdditionalWhere,
  //   JSON.stringify(storeAdditionalWhere),
  //   storeSort,
  //   role,
  // ]);

  useEffect(() => {
    if (parameterChange !== null && parameterChange.dt !== "Initial") {
      handleSearch();
    }
  }, [parameterChange]);
  useEffect(() => {
    if (deleteData != null || postingResult !== undefined) {
      notifyParameterChange();
    }
  }, [deleteData, postingResult]);
  useEffect(() => {
    //if(existingColumnFilter.length === 0) {
    let localData = columnsData.map((item) => {
      const blank = existingColumnFilter.find(
        (d) => d.id == item.id && d.value == ""
      );
      return { id: item.id, columns: blank ? columns.slice() : item.columns };
    });
    setColumnsData(localData);
    //}
  }, [existingColumnFilter]);

  const filterData = (filter) => {
    const data = columns.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    const elmID = event.target.element.id;
    setDropdownFilter(event.filter);
    const localData = [...columnsData];
    const existsFilters = existingColumnFilter.filter((d) => d.id !== elmID);
    setExistingColumnFilter(
      existsFilters.concat({ id: elmID, value: event.filter.value })
    );
    setColumnLastId(elmID);
    const clm = columnsData.find((p) => p.id == elmID);
    if (clm) {
      localData[localData.indexOf(clm)].columns = filterData(event.filter);
    } else {
      localData.push({ id: elmID, columns: filterData(event.filter) });
    }
    setColumnsData(localData);
  };

  const [newParamater, setNewParamer] = useState({
    columnName: "",
    value: "",
    operator: "",
    dataType: "",
    objectValue: null,
  });

  const [searchParameter, setSearchParameter] = useState({
    tableName: tableName,
    searchParams: searchParams,
    columns: columnList,
    pageNumber: pageNumber,
    pageSize: pageSize,
    // "orderBy": orderBy,
    // "sortBy": sortBy,
    sortData:
      sortData && sortData?.length > 0
        ? sortData.reduce(
            (accumulator, item) =>
              (accumulator === "" ? " " : accumulator + ", ") +
              item.field +
              " " +
              item.dir,
            ""
          )
        : "",
    additionalWhere: additionalWhere,
    qID: 0,
    role,
  });
  useEffect(() => {
    // Update search parameters when pageSize prop changes
    setSearchParameter((prevState) => ({
      ...prevState,
      pageSize: pageSize,
    }));
  }, [pageSize]);
  const [saveParameter, setSaveParameter] = useState({
    role: "",
    queryName: "",
    queryId: null,
  });
  useEffect(() => {
    if (
      lodingSaveType === "SAVE_ADVANCE_SEARCH_SUCCESS" &&
      hasShownToaster === false
    ) {
      setOpen({ ...open, saveOpen: false });
      if (
        saveQueriesData !== null &&
        saveQueriesData?.message !== null &&
        saveQueriesData?.message !== "null" &&
        saveQueriesData?.id === gridId
      ) {
        ShowSucess(saveQueriesData?.message, {
          position: "top-right",
        });
        dispatch(savedIsToasterShown(true));
      }
      return;
    } else if (
      lodingSaveType === "SAVE_ADVANCE_SEARCH_FAIL" &&
      hasShownToaster === false
    ) {
      setOpen({ ...open, saveOpen: false });
      if (error?.id === gridId) {
        ShowError(error?.message, {
          position: "top-right",
        });
        dispatch(savedIsToasterShown(true));
      }
    } else {
      return;
    }
  }, [lodingSaveType, hasShownToaster]);

  const userGroup = useSelector((state) => state.querySlice.groups);

  const clearNewParamter = () => {
    setNewParamer({
      columnName: "",
      value: "",
      operator: "",
      dataType: "",
    });
  };
  const findDataType = (columnName) => {
    const c = columns.find((p) => p.column_name === columnName);
    if (c) {
      return c.data_type === "char" ||
        c.data_type === "varchar" ||
        c.data_type === "nvarchar"
        ? "VARCHAR(MAX)"
        : c.data_type;
    } else {
      return "";
    }
  };
  const getOperatorList = (dataType) => {
    if (dataType === "VARCHAR(MAX)" || dataType === "datetime") {
      return ["=", "LIKE"];
    } else {
      return ["=", ">", "<", ">=", "<=", "LIKE"];
    }
  };
  const handleNewParameter = (event) => {
    let p = { ...searchParameter };

    const elmID = new Date().getTime();
    if (newParamater.columnName !== "") {
      p.searchParams.push({
        ...newParamater,
        dataType: findDataType(newParamater.columnName),
        id: elmID,
      });
    }

    const localData = [...columnsData];
    const clm = localData.find((p) => p.id === elmID);
    if (clm) {
      localData[localData.indexOf(clm)].columns = [...columns];
    } else {
      localData.push({ id: elmID, columns: [...columns] });
    }

    clearNewParamter();
    setColumnsData(localData);
    setTimeout(() => {
      setSearchParameter(p);
    }, 500);
  };

  const handleExistingParameter = (param, key, value) => {
    let p = [...searchParameter.searchParams];
    p[p.indexOf(param)][key] = value;
    p[p.indexOf(param)].dataType = findDataType(p[p.indexOf(param)].columnName);
    setSearchParameter({ ...searchParameter, searchParams: p });
  };
  function checkObjectProperties(obj) {
    return (
      obj.hasOwnProperty("columnName") &&
      obj.hasOwnProperty("value") &&
      obj.hasOwnProperty("operator") &&
      obj.columnName !== "" &&
      obj.value !== "" &&
      obj.operator !== ""
    );
  }

  useEffect(() => {
    if (searchParameter.searchParams.length > 0 && isSearched === true) {
      dispatch(SaveQuery(searchParameter.searchParams));
      dispatch(SaveLocation(URL.pathname));
    }
  }, [searchParameter.searchParams.length > 0]);
  useEffect(() => {
    const groupingFiledd =
      sortData && sortData?.length > 0 ? sortData.map((i) => i.field) : [];
    const groupingFiled = storeDataState.undefined?.group
      ?.map((i) => {
        if (!groupingFiledd.includes(i.field)) {
          return i.field;
        }
      })
      .filter((is) => is);
    if (Array.isArray(groupingFiled)) {
      const sortedfiled =
        sortData && sortData?.length > 0
          ? sortData.reduce(
              (accumulator, item) =>
                (accumulator === "" ? " " : accumulator + ", ") +
                item.field +
                " " +
                item.dir,
              ""
            )
          : "";
      const newSearchfiled = {
        ...searchParameter,
        orderByMultiple:
          groupingFiled?.length > 0
            ? groupingFiled.toString().concat(",", sortedfiled)
            : sortedfiled,
      };
      setSearchParameter(newSearchfiled);
      notifyParameterChange();
    }
  }, [storeDataState.undefined?.group]);
  useEffect(() => {
    if (savedLocation === URL.pathname && savedQuery !== null) {
      setSearchParameter({ ...searchParameter, searchParams: savedQuery });
      setExpandedSearchTools(true);
    } else if (
      searchParameter.searchParams !== null &&
      Object.keys(searchParameter.searchParams).length > 0
    ) {
      setExpandedSearchTools(true);
    }
  }, [savedLocation, savedQuery]);
  const renderAutoComplete = (param, identifier, changeCallback) => {
    return findDataType(param.columnName) === "VARCHAR(MAX)" &&
      param.operator !== "LIKE" &&
      param.operator !== "" ? (
      <AutoComplete
        loading={loadingFindAsYouType && currentFindAsType === identifier}
        data={findAsyouTypeResults}
        textField="keyField"
        ref={param.autocomplete}
        value={param.value}
        label="Value"
        size={"medium"}
        fillMode={"outline"}
        className="bg-white"
        style={{
          width: "400px",
          height: "35px",
        }}
        onChange={(e) => {
          setCurrentFindAsType(identifier);
          if (columnRefernces && columnRefernces.length > 0) {
            const item = columnRefernces.find(
              (d) => d.columnName === param.columnName
            );
            if (item)
              dispatch(
                findAsyouType({
                  table: item.table,
                  keyField: item.keyField,
                  valueField: item.valueField,
                  search: e.target.value,
                })
              );
            else
              dispatch(
                findAsyouType({
                  table: tableName,
                  keyField: param.columnName,
                  valueField: param.columnName,
                  search: e.target.value,
                })
              );
          } else {
            dispatch(
              findAsyouType({
                table: tableName,
                keyField: param.columnName,
                valueField: param.columnName,
                search: e.target.value,
              })
            );
          }
          changeCallback(
            { ...newParamater, value: e.target.value.trim() },
            e.target.value
          );
        }}
      />
    ) : (
      <FloatingLabel
        className="mx-2"
        editorId={"textbox-id"}
        label={"value"}
        editorValue={param.value}
      >
        <TextBox
          placeholder="Enter your value"
          style={{
            width: 400,
            marginTop: "0px",
            height: "35px",
          }}
          fillMode={"outline"}
          className="bg-white"
          value={param.value}
          onChange={(e) => {
            changeCallback(
              { ...newParamater, value: e.target.value.trim() },
              e.target.value
            );
          }}
        />
      </FloatingLabel>
    );
  };

  useEffect(() => {
    if (chooseFilterMethod === "normalSearch") {
    } else {
      dispatch(userGroupStore());
    }
  }, [chooseFilterMethod]);

  const onSubmit = (values, isUpdate) => {
    const { update } = isUpdate;
    const { queryName, selected } = values;
    // const saveQueryApi = (data) => {
    // const payload = {
    //   queryName: saveParameter.queryName,
    //   permissionType: saveParameter.role,
    //   permissionValue: saveParameter.role,
    //   // state.userGroupID.length === 0 ? null : state.userGroupID,
    //   userId: 10000000,
    //   tableName: "EmployeeMaster",
    //   params: searchParameter.searchParams,
    //   columns: "*",
    //   limit: 10,
    //   page: 1,
    //   orderBy: "EmployeeName_FML",
    //   sortBy: "DESC",
    // };

    let searchParams = searchParameter.searchParams;
    // if (checkObjectProperties(newParamater)) {
    //   searchParams = [...searchParams, newParamater];
    //   // handleNewParameter();
    // }
    dispatch(
      saveAdvanceSearch(
        {
          ...searchParameter,
          qID: update === true ? getAdavanceSearch?.advanceSearchQuery.id : 0,
          searchParams: JSON.stringify(searchParams),
          queryName: queryName,
          permissionType: selected,
          permissionValue: selected,
          isDefault: isDefaultQuery,
          location: "saveSearch",
          sortBy: "DESC",
          orderBy: "ID",
        },
        gridId
      )
    );
  };

  return (
    <Fragment>
      <Accordion
        style={{ marginBottom: "9px" }}
        expanded={expandedSearchTools}
        className={
          chooseFilterMethod === "normalSearch" // Removed isDashboard === true || to show advance filter in Dashboard
            ? // isDashboard === true || chooseFilterMethod === "normalSearch"
              "hideFilter"
            : ""
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className="bg-light"
          onClick={() => setExpandedSearchTools(!expandedSearchTools)}
        >
          <Typography variant="h6">
            Search Tools
            {expandedSearchTools && (
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: (e) => {
                    e.stopPropagation();
                    setOpen({ ...open, selectOpen: !open.selectOpen });
                  },
                  style: { marginLeft: "20px" },
                }}
              >
                <FontAwesomeIcon icon={faListTimeline} /> Load Saved Query
              </Btn>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              <Stack
                direction={"column"}
                alignSelf="center"
                justifyContent={"center"}
              >
                {searchParameter.searchParams
                  .filter((d) => d.columnName !== "")
                  .map((param) => {
                    return (
                      <Stack
                        direction={"row"}
                        style={{ width: "100%", justifyContent: "left" }}
                        marginX={2}
                        spacing={2}
                      >
                        <DropDownList
                          id={param.id}
                          fillMode={"outline"}
                          className="bg-white m-x-2"
                          value={
                            columnsData.length > 0 &&
                            columnsData
                              .find((d) => d.id === param.id)
                              ?.columns?.find(
                                (d) =>
                                  d.column_name
                                    .replace(/_/g, "")
                                    .toLowerCase() ===
                                  param.columnName
                                    .replace(/_/g, "")
                                    .toLowerCase()
                              )
                          }
                          label="Field"
                          style={{ width: 300, height: "35px" }}
                          dataItemKey="column_name"
                          filterable={true}
                          textField="column_title"
                          data={
                            columnsData.length > 0
                              ? columnsData.find((d) => d.id === param.id)
                                  ?.columns
                              : []
                          }
                          onFilterChange={filterChange}
                          filter={
                            existingColumnFilter.find((d) => d.id === param.id)
                              ?.value
                          }
                          onChange={(e) => {
                            handleExistingParameter(
                              param,
                              "columnName",
                              e.target.value.column_name
                            );
                            setExistingColumnFilter([
                              { id: param.id, value: "" },
                            ]);
                          }}
                        ></DropDownList>

                        <DropDownList
                          className="bg-white m-x-2"
                          value={param.operator}
                          label="Operator"
                          style={{ width: 120, height: "35px" }}
                          fillMode={"outline"}
                          data={getOperatorList(findDataType(param.columnName))}
                          onChange={(e) => {
                            handleExistingParameter(
                              param,
                              "operator",
                              e.target.value
                            );
                          }}
                        ></DropDownList>
                        {renderAutoComplete(
                          param,
                          param.columnName,
                          (p, value) => {
                            handleExistingParameter(param, "value", value);
                          }
                        )}

                        <Btn
                          attrBtn={{
                            color: "primary",
                            size: "sm",
                            style: {
                              marginTop: "20px",
                              marginLeft: "3px",
                              height: "30px",
                              width: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                            onClick: () => {
                              const searchParams = searchParameter.searchParams;
                              /// const idx = params.indexOf(param);
                              setSearchParameter({
                                ...searchParameter,
                                searchParams: searchParams.filter(
                                  (d) => d !== param
                                ),
                              });
                            },
                          }}
                        >
                          <DeleteForeverRounded style={{ fontSize: "16px" }} />
                        </Btn>
                      </Stack>
                    );
                  })}
              </Stack>

              <Stack
                direction={"row"}
                style={{ width: "100%", justifyContent: "left" }}
                marginX={2}
                spacing={2}
              >
                <DropDownList
                  id={`original`}
                  fillMode={"outline"}
                  className="bg-white m-x-2"
                  value={
                    columnsData.length > 0 && newParamater.columnName !== ""
                      ? columnsData
                          .find((d) => d.id === `original`)
                          ?.columns?.find(
                            (d) => d.column_name === newParamater.columnName
                          )
                      : null
                  }
                  label="Field"
                  style={{ width: 300, height: "35px" }}
                  dataItemKey="column_name"
                  textField="column_title"
                  data={
                    columnsData.length > 0
                      ? columnsData.find((d) => d.id === `original`)?.columns
                      : []
                  }
                  filterable={true}
                  filter={
                    existingColumnFilter.find((d) => d.id === "original")?.value
                  }
                  onFilterChange={filterChange}
                  onChange={(e) => {
                    setNewParamer({
                      ...newParamater,
                      columnName: e.target.value.column_name,
                    });
                    setExistingColumnFilter([{ id: "original", value: "" }]);
                    //setExistingColumnFilter([]);//existingColumnFilter.filter(d=>d.id !== "original")
                  }}
                ></DropDownList>

                <DropDownList
                  className="bg-white m-x-2"
                  fillMode={"outline"}
                  value={newParamater.operator}
                  label="Operator"
                  style={{ width: 120, height: "35px" }}
                  data={getOperatorList(findDataType(newParamater.columnName))}
                  onChange={(e) => {
                    setNewParamer({
                      ...newParamater,
                      operator: e.target.value,
                    });
                  }}
                ></DropDownList>
                {renderAutoComplete(newParamater, "new", (p) => {
                  setNewParamer(p);
                })}
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      handleNewParameter();
                    },
                    size: "sm",
                    style: {
                      marginTop: "20px",
                      marginLeft: "3px",
                      height: "30px",
                      width: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <Add style={{ fontSize: "16px" }} />
                </Btn>
              </Stack>
            </CardBody>
            <CardActionArea>
              <Stack
                className="my-2"
                direction={"row"}
                alignItems={"flex-end"}
                // justifyContent="center"
                justifyContent="left"
                spacing={2}
                marginX={2}
              >
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      setSearchParameter({
                        ...searchParameter,
                        searchParams: [],
                      });
                      clearNewParamter();
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> Clear All
                </Btn>
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      handleSearch(true);
                      //TODO - SEARCH CALL
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faSearch} /> Search Now
                </Btn>

                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      setOpen({ ...open, saveOpen: !open.saveOpen });
                    },
                    size: "sm",
                    class: "me-2",
                  }}
                >
                  <FontAwesomeIcon icon={faSave} /> Save Search
                </Btn>
              </Stack>
            </CardActionArea>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/* save query			 */}

      {open.saveOpen && (
        <ModalCustom open={open.saveOpen} fullWidth>
          <SaveNewQuery
            onSelectUserGroup={(e) => {
              setSaveParameter({ ...saveParameter, role: e });
            }}
            userGroup={userGroup}
            //   onSelectUserGroup={onSelectUserGroup}
            //   onRemoveUserGroup={onRemoveUserGroup}
            //   onSubmit={onFinished}
            //   values={values}
            onTextChange={(e) => {
              setSaveParameter({
                ...saveParameter,
                queryName: e.target.value,
              });
            }}
            queryName={saveParameter?.queryName}
            isDefaultQuery={isDefaultQuery}
            handleChange={handleChange}
            selected={saveParameter.role}
            queryId={saveParameter?.queryId}
            handleClose={() => {
              setOpen({ ...open, saveOpen: !open.saveOpen });
            }}
            onSubmit={onSubmit}
          />
        </ModalCustom>
      )}

      <HistoryDataTable
        openHistory={open.selectOpen}
        setOpenHistory={setOpen}
        data={userSavedQueries || []}
        sortData={SortData}
        selectedColumns={sortedColumns}
        setSortedColumns={setSortedColumns}
        onSortChange={handleSortChange}
        actions={actions}
        title={"My Queries"}
        group={[{ field: "permissionType" }]}
      />
    </Fragment>
  );
}
export default memo(AdvanceSearch);
