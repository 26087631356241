import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../_helper/helper';

import {
  CREATE_UPDATE_EMPLOYEE_DEDUCTION,
  GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID,
  GET_DEDUCTION_PLAN_DOCUMENTS,
  GET_DEDUCTION_TYPE,
  GET_ALL_PAYMENT_TYPE,
  GET_ALL_DEDUCTION_PLAN_MASTER,
  GET_ALL_DEDUCTION_METHOD_LIST
} from "./actionTypes";

import {
  getDeductionPlanDocumentsSuccess,
  getDeductionPlanDocumentsFail,
  getEmployeeDeductionPlanByIdSuccess,
  getEmployeeDeductionPlanByIdFail,
  createOrUpdateEmployeeDeductionPlanMasterSuccess,
  createOrUpdateEmployeeDeductionPlanMasterFail,
  getAllDeductionMethodListSuccess,
  getAllDeductionMethodListFail,
  getAllDeductionPlanMasterSuccess,
  getAllDeductionPlanMasterFail,
  getAllPaymentTypeSuccess,
  getAllPaymentTypeFail,
  getDeductionTypeSuccess,
  getDeductionTypeFail,
  getAllPaymentType,
  getDeductionType,
} from "./actions";

import { CreateOrUpdateDeduction, getAllPaymentTypeAPI, getDeductionDocuments, getDeductionMethodList, getDeductionPlanById, getDeductionPlanMaster, getDeductionTypeAPI } from '../../_helper/services/employeeDeduction';

function* onGetEmployeeDeductionPlanById({ payload: id }) {
  try {
    const response = yield call(getDeductionPlanById, id);
    yield put(getEmployeeDeductionPlanByIdSuccess(response?.data));
  } catch (error) {
    yield put(getEmployeeDeductionPlanByIdFail(error.response));
  }
}

function* onGetDeductionPlanDocuments({ payload }) {
  try {
    const response = yield call(getDeductionDocuments, payload);
    yield put(getDeductionPlanDocumentsSuccess(response));
  } catch (error) {
    yield put(getDeductionPlanDocumentsFail(error.response));
  }
}

function* onCreateDeductionPlan({ payload }) {
  try {
    const response = yield call(CreateOrUpdateDeduction, payload);
    yield put(createOrUpdateEmployeeDeductionPlanMasterSuccess(response?.data));
    if (response?.status === 208) {
      ShowSucess(response?.message, { position: 'top-right' });
    } else {
      ShowSucess(`Deduction ${(payload?.id || payload?.Id) ? 'updated' : 'created'} successfully`, { position: 'top-right' });
    }

  } catch (error) {
    yield put(createOrUpdateEmployeeDeductionPlanMasterFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* onGetAllDeductionMethodList() {
  try {
    const response = yield call(getDeductionMethodList);
    yield put(getAllDeductionMethodListSuccess(response));
  } catch (error) {
    yield put(getAllDeductionMethodListFail(error.response));
  }
}

function* onGetAllDeductionPlanMaster() {
  try {
    const response = yield call(getDeductionPlanMaster);
    yield put(getAllDeductionPlanMasterSuccess(response));
  } catch (error) {
    yield put(getAllDeductionPlanMasterFail(error.response));
  }
}

function* onGetAllPaymentType() {
  try {
    const response = yield call(getAllPaymentTypeAPI);
    yield put(getAllPaymentTypeSuccess(response));
  } catch (error) {
    yield put(getAllPaymentTypeFail(error.response));
  }
}

function* onGetDeductionType() {
  try {
    const response = yield call(getDeductionTypeAPI);
    yield put(getDeductionTypeSuccess(response));
  } catch (error) {
    yield put(getDeductionTypeFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID, onGetEmployeeDeductionPlanById);
  yield takeLatest(GET_DEDUCTION_PLAN_DOCUMENTS, onGetDeductionPlanDocuments);
  yield takeLatest(CREATE_UPDATE_EMPLOYEE_DEDUCTION, onCreateDeductionPlan);
  yield takeLatest(GET_ALL_DEDUCTION_METHOD_LIST, onGetAllDeductionMethodList);
  yield takeLatest(GET_ALL_DEDUCTION_PLAN_MASTER, onGetAllDeductionPlanMaster);
  yield takeLatest(GET_ALL_PAYMENT_TYPE, onGetAllPaymentType);
  yield takeLatest(GET_DEDUCTION_TYPE, onGetDeductionType);
}

export default CartSaga;