import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_POSITIONCLASS, GET_POSITIONCLASS_DETAILS,  POSITIONCLAS_CREATE, POSITIONCLAS_DELETE,
   POSITIONCLAS_UPDATE,RESET} from "./actionTypes";

import {
  getPositionClassSuccess,
  getPositionClassFail,
  getPositionClassDetailsSuccess,
  getPositionClassDetailsFail,
  createPositionClasSuccess,
  createPositionClasFail,
  updatePositionClasSuccess,
  updatePositionClasFail,
  deletePositionClasSuccess,
  deletePositionClasFail,
} from "./actions";

import {  createPositionClas,deletePositionClas,
	getPositionClasDetails,getPositionClass
	,updatePositionClas,
 } from "../../_helper/services/positionclass";
function* onGetPositionClass({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPositionClass,{page,pageSize});
    yield put(getPositionClassSuccess(response));
  } catch (error) {
    yield put(getPositionClassFail(error.response));
  }
}

function* onGetPositionClasDetails({ payload: id }) {
  try {
    const response = yield call(getPositionClasDetails, id);
    yield put(getPositionClassDetailsSuccess(response));
  } catch (error) {
    yield put(getPositionClassDetailsFail(error.response));
  }
}
function* onCreatePositionClas({ payload }) {
	try {
	  const response = yield call(createPositionClas, payload);
	  yield put(createPositionClasSuccess(response));
    ShowSucess('PositionClas Created successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(createPositionClasFail(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error)
    // const { status, statusText } = error.response;
    // const errorMessage = `${status} - ${statusText}`;
    //     toast.error(errorMessage, {
    //       position: 'top-right'
    //     });
	}
  }
  function* onUpdatePositionClas({ payload: { id},payload }) {
	try {
	  const response = yield call(updatePositionClas, id, payload);
	  yield put(updatePositionClasSuccess(response));
    ShowSucess('PositionClas updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updatePositionClasFail(error.response));
    console.log(error?.response?.data?.message);
    ShowError(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
	}
  }
  function* onDeletePositionClas({ payload: ID }) {
	try {
	  const response = yield call(deletePositionClas, ID);
	  yield put(deletePositionClasSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deletePositionClasFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_POSITIONCLASS, onGetPositionClass);
  yield takeLatest(GET_POSITIONCLASS_DETAILS, onGetPositionClasDetails);
  yield takeLatest(POSITIONCLAS_CREATE, onCreatePositionClas);
  yield takeLatest(POSITIONCLAS_UPDATE, onUpdatePositionClas);
  yield takeLatest(POSITIONCLAS_DELETE, onDeletePositionClas);
}

export default CartSaga;