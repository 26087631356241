import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faList } from "@fortawesome/pro-duotone-svg-icons";
import ModalCustom from "../AdvanceSearch/component/Modal";
import ChipsSelection from "./ChipsSelection";
import { Box, Button } from "@mui/material";
import { faXmark } from "@fortawesome/pro-duotone-svg-icons";
const style = {
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const classes = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "500",
    },
  },
};
function SelectMultipleCheckBox(props) {
  let {
    options,
    selected,
    handleChange,
    setSelected,
    selectUpdate,
    labelName,
    name,
  } = props;

  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const [open, setOpen] = useState(false);
  return (
    <div>
      <ModalCustom open={open} fullWidth>
        <Box sx={style}>
          <Button
            variant="outlined"
            className=" mb-3"
            style={{
              float: "right",
            }}
            onClick={() => setOpen(false)}
          >
            <FontAwesomeIcon icon={faXmark} size="2xl" />
          </Button>
          <ChipsSelection
            options={options}
            setOpen={setOpen}
            selected={selected}
            setSelected={setSelected}
            selectUpdate={selectUpdate}
            labelName={labelName}
            name={name}
          />
        </Box>
      </ModalCustom>
      {/* {JSON.stringify(options.filter(i => selected.includes(i.value)).map(i=>i.label).join(", "))} */}
      <div class="d-flex justify-content-between">
        {" "}
        <InputLabel id="mutiple-select-label">
          {`${labelName}  `}
          {` ${selected.length ? `-  ${selected.length} Items` : ""}`}
        </InputLabel>
        <FontAwesomeIcon
          icon={faList}
          style={{ marginRight: "10px" }}
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>{" "}
      <FormControl className={classes.formControl} style={{ width: "100%" }}>
        <Select
          labelId="mutiple-select-label"
          size="small"
          multiple
          name={name}
          value={selected}
          onChange={handleChange}
          renderValue={() => {
            return options
              .filter((i) => selected.includes(i.value))
              .map((i) => i.label)
              .toString();
          }}
          MenuProps={MenuProps}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemIcon>
                <Checkbox checked={selected.includes(option.value)} />
              </ListItemIcon>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectMultipleCheckBox;
