import React, {useEffect, useState} from 'react';
import EmployeeSignatureModal from "./EmployeeSignatureModal";
import {
    clearAddUpdateSignatureResponse,
    employeeservicerequest,
    generateDocumentForSignature,
    getAllEmployeeSignatures,
    getEmployeeSignatureByControlNumber,
    getSignatureData
} from "../../store/EmployeeSignature/actions";
import {isEmpty} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {LoadingPanel} from "../CommonFunction";
import {getDocumentSources} from "../../store/DocumentUpload/actions";
import {drawDOM, exportPDF} from "@progress/kendo-drawing";
import {formatDateMMDDYYYY, ShowError} from "../../_helper/helper";

export default function EmployeeSignature({
    label = "",
    sourceName,
    refControlNumber,
    setSignatureData,
    printRef,
    employeeId,
    npaSignature,
    signControlNumber = null,
    isReadOnlySignature = false,
    onDocumentSignComplete
}) {
    const [isCanvasOpen, setOpenCanvas] = useState(false)
    const [signatureDetails, setSignatureDetails] = useState({});
    const dispatch = useDispatch();
    const { documentResponse, isEmployeeSignatureLoading,isSignatureFetching, signatureData, signatures, getSignatureByControlNumberResponse } = useSelector(
        (state) => state?.EmployeeSignatureReducer
    );
    const { documentSources } = useSelector(
        (state) => state?.DocumentUploadReducer
    );

    useEffect(() => {
        if(sourceName && refControlNumber) {
            dispatch(getSignatureData(sourceName, refControlNumber))
        }
    }, [sourceName, refControlNumber])

    useEffect(() => {
        if (signControlNumber) {
            !Object.keys(signatureDetails).length && dispatch(getEmployeeSignatureByControlNumber(signControlNumber));
        }
    }, [signControlNumber]);

    useEffect(() => {

        setSignatureDetails(!isEmpty(signatureData) ? { ...signatureData } : !isEmpty(npaSignature) ? {...npaSignature} : {})
        // setSignatureData(!isEmpty(signatureData) ? { ...signatureData } : {})
    }, [signatureData,npaSignature]);
    
    // useEffect(() => {
    //     console.log("123123dsadada ::: ",npaSignature,signatureData)
    //     setSignatureDetails(!isEmpty(npaSignature) ? { ...npaSignature } : {})
    //     // setSignatureDetails(!isEmpty(signatureData) ? { ...signatureData } : {})
    // }, [npaSignature]);
    console.log("123123dsadada ....",npaSignature,signatureDetails)

    // useEffect(() => {
    //     if(!isEmpty(documentResponse) && documentResponse.status === 200) {
    //         if (printRef && printRef.current) {
    //             printRef.current.save();
    //         }
    //     }
    // }, [documentResponse]);
    useEffect(() => { debugger
        console.log("documentResponse",documentResponse)
        if (documentResponse && Object.keys(documentResponse).length > 0 && onDocumentSignComplete) {
            // onDocumentSignComplete(documentResponse.documentReferenceID || "LR-00000007496523");
            onDocumentSignComplete(documentResponse.data || "LR-00000007496523");
            // onDocumentSignComplete(documentResponse);
        }
    }, [documentResponse]);
    
    useEffect(() => {
        if(!isEmpty(getSignatureByControlNumberResponse)) {
            setSignatureDetails(getSignatureByControlNumberResponse)
            setSignatureData(!isEmpty(getSignatureByControlNumberResponse) ? { ...getSignatureByControlNumberResponse } : {})
            if(printRef) {
                if(!employeeId) {
                    ShowError("Employee data missing!", {
                        position: 'top-right'
                    })
                    return;
                }
                if(!getSignatureByControlNumberResponse.signUrl) {
                    ShowError("Signature is required for the verification!", {
                        position: 'top-right'
                    })
                    return;
                }
                setTimeout(() => {
                    let gridElement = document.querySelector(".export-PDF-class");
                    drawDOM(gridElement, {
                        paperSize: ["8.5in", "11in"],
                        scale: 0.6
                    })
                        .then((group) => {
                            return exportPDF(group);
                        })
                        .then((dataUri) => {
                            if(dataUri && getSignatureByControlNumberResponse.signUrl) {
                                const payload = {
                                    documentUrl: dataUri,
                                    signReferenceId: getSignatureByControlNumberResponse.controlNumber,
                                    sourceName: sourceName,
                                    employeeId: employeeId
                                }
                                dispatch(generateDocumentForSignature(payload))
                                // if(onDocumentSignComplete){
                                //     onDocumentSignComplete("LR-00000007496523")
                                // }
                            }
                        });
                }, 1000)
            }
        }
    }, [getSignatureByControlNumberResponse])

    useEffect(() => {
        dispatch(getAllEmployeeSignatures());
        return () => {
            dispatch(clearAddUpdateSignatureResponse());
        }
    }, []);

    useEffect(() => {
        !documentSources.length && dispatch(getDocumentSources());
    }, [documentSources]);

    return (
        <>
            {" "}
            <div
                style={{
                    background: "white",
                    // border: "1px solid lightgray",
                    width: "100%",
                    marginTop: "6px",
                    borderRadius: "5px",
                    padding: "0 6px",
                    // borderBottom: "3px solid black",
                    minHeight: 72
                }}
            >
                <small
                    style={{
                        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                        fontSize: "15px",
                        top: "2px",
                        color: "rgba(0, 0, 0, 0.6)",
                        position: "sticky",
                        display: "block",
                        padding: "3px 6px",
                    }}
                >
                    {label}
                </small>
                {isSignatureFetching
                    ? <LoadingPanel />
                    : (
                        <>
                            <div className={`d-flex ${signatureDetails?.signUrl ? 'justify-content-between' : 'justify-content-end'} align-items-center`}>
                                {signatureDetails?.signUrl || signatureDetails?.SignUrl ? (
                                    <span className="w-100 text-center" style={{ cursor: 'pointer' }} onClick={() => !isReadOnlySignature && setOpenCanvas(!isCanvasOpen)}>
                                        <img alt="signatureDetails" className={"sigImage"}
                                        style={{width:"100px",height:"100px"}}
                                             src={signatureDetails?.signUrl ? signatureDetails?.signUrl?.trim?.() : signatureDetails?.SignUrl}/>
                                    </span>
                                ) : null}
                                {signatures.length && !signatureDetails.signUrl ? (
                                    <button
                                        className="icon m-2 "
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            border: 'none',
                                            backgroundColor: 'transparent'
                                        }}
                                        type="button"
                                        onClick={() => setOpenCanvas(!isCanvasOpen)}
                                    >
                                        <i className="fa-duotone fa-upload  fa-lg"></i>
                                    </button>
                                ): null}
                            </div>
                            {signatureDetails.description && <p className="small text-center text-wrap">{signatureDetails.description || ''}</p> }
                            {signatureDetails?.controlNumber && <p className="small text-center">{signatureDetails.controlNumber}-D{formatDateMMDDYYYY()}</p>}
                        </>
                    )
                }
            </div>
            {isCanvasOpen && (
                <EmployeeSignatureModal
                    openCanvas={isCanvasOpen}
                    setOpenCanvas={setOpenCanvas}
                    signatureData={signatureDetails || {}}
                    sourceName={sourceName}
                    refControlNumber={refControlNumber}
                    handleSetSignature={(data) => setSignatureDetails({...data})}
                    printRef={printRef}
                    employeeId={employeeId}
                />
            )}
        </>
    );
}