const visileColumns = [
  
  {
    title: "Employee ID",
    field: "EmployeeID",
    locked: false,
    filter: "text",
    width: 160,
    show: true,
  },
  // {
  //   title: "Subscriber Id",
  //   field: "SubscriberID",
  //   locked: false,
  //   filter: "text",
  //   width: 200,
  //   show: true,
  // },
  {
    title: "FirstName",
    field: "FirstName",
    locked: false,
    filter: "text",
    width: 350,
    show: true,
  },
  {
    title: "MiddleName",
    field: "MiddleName",
    locked: false,
    filter: "text",
    width: 160,
    show: true,
  },
  {
    title: "LastName",
    field: "LastName",
    locked: false,
    filter: "text",
    width: 160,
    show: true,
  },
  {
    title: "DOB",
    field: "DOB",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 140,
    show: true,
  },
  {
    title: "Gender",
    field: "Gender",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "Department",
    field: "Department",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "DateOfEmployment",
    field: "DateOfEmployment",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 140,
    show: true,
  },
  {
    title: "City",
    field: "City",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "State",
    field: "State",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "Zip",
    field: "Zip",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
  {
    title: "HomePhone",
    field: "HomePhone",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },

  
  {
    title: "MaritalStatus",
    field: "MaritalStatus",
    locked: false,
    filter: "text",
    width: 200,
    show: true,
  },
 
];

export default visileColumns;
