import {
    GET_SIDEBARMENU, GET_SIDEBARMENU_SUCCESS, GET_SIDEBAR_FAILURE,
    GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILURE,
    CREATE_SIDEBARMENU, CREATE_SIDEBAR_SUCCESS, CREATE_SIDEBAR_FAILURE,
    GET_SIDEBARLOOKUP, GET_SIDEBAR_LIKKUP_SUCCESS, GET_SIDEBARLOOKUP_FAILURE,
    GETBY_ID_SIDEBARMENU, GETBY_ID_SIDBARMENU_SUCCESS, GETBY_ID_SIDEBARMENU_FAILURE,
    DELETE_SIDEBARMENU, DELETE_SIDEBARMENU_SUCCESS, DELETE_SIDEBARMENU_FAILURE,
    UPDATE_SIDEBARMENU, UPDATE_SIDEBARMENU_SUCCESS, UPDATE_SIDEBARMENU_FAILURE,
    GET_MENUITEMS,GET_MENUITEMS_SUCCESS,GET_MENUITEMS_FAILURE,
    UPDATE_SIDEBARMENU_SEQUENCE,UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS,UPDATE_SIDEBARMENU_SEQUENCE_FAILURE,
    RESET,RESET_CREATE_MENU,RESET_DELETE, GET_SIDEBARMENU_BY_ROLE

} from "./actionType";


export const getsidebarmenudata = (data) => ({
    type: GET_SIDEBARMENU,
    payload: data,
});

export const getSidebarMenuByRole = (data) => ({
    type: GET_SIDEBARMENU_BY_ROLE,
    payload: data,
});
export const getsidebarmenudatasuccess = (sidebarmenu) => ({
    type: GET_SIDEBARMENU_SUCCESS,
    payload: sidebarmenu,
});
export const getsidebarmenudatafailure = (error) => ({
    type: GET_SIDEBAR_FAILURE,
    payload: error,
});
export const getsidebarMenuItems = (data) => ({
    type: GET_MENUITEMS,
    payload: data,
});
export const getsidebarMenuItemsSuccess = (menuItems) => ({
    type: GET_MENUITEMS_SUCCESS,
    payload: menuItems,
});
export const getsidebarMenuItemsFailure = (error) => ({
    type: GET_MENUITEMS_FAILURE,
    payload: error,
});


export const getrolesdata = (data) => ({
    type: GET_ROLES,
    payload: data,
});
export const getrolessuccess = (role) => ({
    type: GET_ROLES_SUCCESS,
    payload: role,
});
export const getrolesfailure = (error) => ({
    type: GET_ROLES_FAILURE,
    payload: error,
});
export const getSidebarLookUp = (data) => ({
    type: GET_SIDEBARLOOKUP,
    payload: data,
});
export const getSidebarLookUpuccess = (lookup) => ({
    type: GET_SIDEBAR_LIKKUP_SUCCESS,
    payload: lookup,
});
export const getSidebarLookUpfailure = (error) => ({
    type: GET_SIDEBARLOOKUP_FAILURE,
    payload: error,
});

export const createsidebarmenu = (payload) => ({
    type: CREATE_SIDEBARMENU,
    payload: payload
})


export const createsidebarmenuSuccess = (response) => ({
    type: CREATE_SIDEBAR_SUCCESS,
    payload: response
})


export const createsidebarmenuFailure = (error) => ({
    type: CREATE_SIDEBAR_FAILURE,
    payload: error
})


export const getbyidsidebarmenu = (menuid) => ({
    type: GETBY_ID_SIDEBARMENU,
    payload: menuid
})
export const getbyidsidebarmenuSuccess = (data) => ({
    type: GETBY_ID_SIDBARMENU_SUCCESS,
    payload: data
})
export const getbyidsidebarmenuFailure = (error) => ({
    type: GETBY_ID_SIDEBARMENU_FAILURE,
    payload: error
})

export const deletesidebarmenu = (payload) => {
    
    return {
        type: DELETE_SIDEBARMENU,
        payload: payload
    }

}
export const deletesidebarmenuSuccess = (response) => {
    return {
        type: DELETE_SIDEBARMENU_SUCCESS,
        payload: response
    }

}
export const deletesidebarmenuFailure = (error) => {
    return {
        type: DELETE_SIDEBARMENU_FAILURE,
        payload: error
    }

};

export const updatesidebarmenu = (payload) => {
    return {
        type: UPDATE_SIDEBARMENU,
        payload: payload
    }
}
export const updatesidebarmenusucccess = (response) => {
    return {
        type: UPDATE_SIDEBARMENU_SUCCESS,
        payload: response
    }
}
export const updatesidebarmenuFailure = (error) => {
    return {
        type: UPDATE_SIDEBARMENU_FAILURE,
        payload: error
    }
}

export const updatesidebarmenuSeq=(payload)=>{
    return{
        type:UPDATE_SIDEBARMENU_SEQUENCE,
        payload:payload
    }
}
export const updatesidebarmenuSeqSuccess=(response)=>{
    return{
        type:UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS,
        payload:response
    }
}
export const updatesidebarmenuSeqFailure=(error)=>{
    return{
        type:UPDATE_SIDEBARMENU_SEQUENCE_FAILURE,
        payload:error
    }
}

 
export const reset = (payload) => {
	return {
	  type: RESET,
	  payload: payload
	};
  };
export const resetCreateMenu = (payload) => {
	return {
	  type: RESET_CREATE_MENU,
	  payload: payload
	};
  };
export const resetDeleteMenu = (payload) => {
	return {
	  type: RESET_DELETE,
	  payload: payload
	};
  };







