import {
  EXIT_SURVEY_CREATE,
  EXIT_SURVEY_CREATE_SUCCESS,
  EXIT_SURVEY_CREATE_FAIL,
  GET_EXIT_SURVEY_ANSWER,
  GET_EXIT_SURVEY_ANSWER_SUCCESS,
  GET_EXIT_SURVEY_ANSWER_FAIL,
  GET_EXIT_SURVEY_MAIN_REASON,
  GET_EXIT_SURVEY_MAIN_REASON_SUCCESS,
  GET_EXIT_SURVEY_MAIN_REASON_FAIL,
  GET_EXIT_SURVEY_MARTIAL_STATUS,
  GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS,
  GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL,
  GET_EXIT_SURVEY_GENDER,
  GET_EXIT_SURVEY_GENDER_SUCCESS,
  GET_EXIT_SURVEY_GENDER_FAIL,
  GET_EXIT_SURVEY_DATA,
  GET_EXIT_SURVEY_DATA_SUCCESS,
  GET_EXIT_SURVEY_DATA_FAIL,
  GET_EXIT_SURVEY_TOKEN_DATA,
  GET_EXIT_SURVEY_TOKEN_DATA_FAIL,
  GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS,
  RESET,
  GET_EXIT_SURVEY_AGE,
  GET_EXIT_SURVEY_AGE_SUCCESS,
  GET_EXIT_SURVEY_AGE_FAIL,
  GET_EXIT_SURVEY_DEPARTMENTS,
  GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS,
  GET_EXIT_SURVEY_DEPARTMENTS_FAIL,
  GET_EXIT_SURVEY_POSITIONS,
  GET_EXIT_SURVEY_POSITIONS_SUCCESS,
  GET_EXIT_SURVEY_POSITIONS_FAIL,
  CREATE_FORM_TOKEN_EXIT_SURVEY,
  CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS,
  CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL,
} from "./actionType";

export const createExitSurvey = (payload) => {
  return {
    type: EXIT_SURVEY_CREATE,
    payload: payload,
  };
};
export const createExitSurveySuccess = (response) => {
  return {
    type: EXIT_SURVEY_CREATE_SUCCESS,
    payload: response,
  };
};
export const createExitSurveyFail = (error) => {
  return {
    type: EXIT_SURVEY_CREATE_FAIL,
    payload: error,
  };
};

export const createExitFormTokenSurvey = (payload) => {
  return {
    type: CREATE_FORM_TOKEN_EXIT_SURVEY,
    payload: payload,
  };
};
export const createExitFormTokenSuccess = (response) => {
  return {
    type: CREATE_FORM_TOKEN_EXIT_SURVEY_SUCCESS,
    payload: response,
  };
};
export const createExitFormTokenFail = (error) => {
  return {
    type: CREATE_FORM_TOKEN_EXIT_SURVEY_FAIL,
    payload: error,
  };
};

export const getAnswers = () => {
  return {
    type: GET_EXIT_SURVEY_ANSWER,
  };
};

export const getAnswersSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_ANSWER_SUCCESS,
    payload: results,
  };
};

export const getAnswersFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_ANSWER_FAIL,
    payload: error,
  };
};

export const getMainReason = () => {
  return {
    type: GET_EXIT_SURVEY_MAIN_REASON,
  };
};

export const getMainReasonSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_MAIN_REASON_SUCCESS,
    payload: results,
  };
};

export const getMainReasonFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_MAIN_REASON_FAIL,
    payload: error,
  };
};

export const getMartialStatus = () => {
  return {
    type: GET_EXIT_SURVEY_MARTIAL_STATUS,
  };
};

export const getMartialStatusSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_MARTIAL_STATUS_SUCCESS,
    payload: results,
  };
};

export const getMartialStatusFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_MARTIAL_STATUS_FAIL,
    payload: error,
  };
};

export const getGender = () => {
  return {
    type: GET_EXIT_SURVEY_GENDER,
  };
};

export const getGenderSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_GENDER_SUCCESS,
    payload: results,
  };
};

export const getGenderFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_GENDER_FAIL,
    payload: error,
  };
};

export const getAge = () => {
  return {
    type: GET_EXIT_SURVEY_AGE,
  };
};

export const getAgeSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_AGE_SUCCESS,
    payload: results,
  };
};

export const getAgeFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_AGE_FAIL,
    payload: error,
  };
};

export const getExitSurveyDepartments = (payload) => {
  return {
    type: GET_EXIT_SURVEY_DEPARTMENTS,
    payload: payload
  };
};

export const getExitSurveyDepartmentsSuccess = (posts) => {
  return {
    type: GET_EXIT_SURVEY_DEPARTMENTS_SUCCESS,
    payload: posts,
  };
};

export const getExitSurveyDepartmentsFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_DEPARTMENTS_FAIL,
    payload: error,
  };
};

export const getExitSurveyPositions = () => {
  return {
    type: GET_EXIT_SURVEY_POSITIONS
  };
};

export const getExitSurveyPositionsSuccess = (posts) => {
  return {
    type: GET_EXIT_SURVEY_POSITIONS_SUCCESS,
    payload: posts,
  };
};

export const getExitSurveyPositionsFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_POSITIONS_FAIL,
    payload: error,
  };
};

export const getExitSurveyData = () => {
  return {
    type: GET_EXIT_SURVEY_DATA,
  };
};

export const getExitSurveyDataSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_DATA_SUCCESS,
    payload: results,
  };
};

export const getExitSurveyDataFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_DATA_FAIL,
    payload: error,
  };
};

export const getExitSurveyTokenData = (payload) => {
  return {
    type: GET_EXIT_SURVEY_TOKEN_DATA,
    payload: payload
  };
};

export const getExitSurveyTokenDataSuccess = (results) => {
  return {
    type: GET_EXIT_SURVEY_TOKEN_DATA_SUCCESS,
    payload: results,
  };
};

export const getExitSurveyTokenDataFail = (error) => {
  return {
    type: GET_EXIT_SURVEY_TOKEN_DATA_FAIL,
    payload: error,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};
