import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_DETAILS,
  GET_WORKFLOWS_SETTING,
  SAVE_WORKFLOWS_SETTING,
  WORKFLOW_COPY,
  WORKFLOW_CREATE,
  WORKFLOW_DELETE,
  WORKFLOW_UPDATE,
} from "./actionTypes";

import {
  getWorkflowsSuccess,
  getWorkflowsFail,
  getWorkflowsDetailsSuccess,
  getWorkflowsDetailsFail,
  createWorkflowSuccess,
  createWorkflowFail,
  updateWorkflowSuccess,
  updateWorkflowFail,
  deleteWorkflowSuccess,
  deleteWorkflowFail,
  getWorkflowsSettingSuccess,
  getWorkflowsSettingFail,
  updateWorkflowSettingSuccess,
  updateWorkflowSettingFail,
  copyWorkflowSuccess,
  copyWorkflowFail,
} from "./actions";

import {
  copyWorkflow,
  createWorkflow,
  deleteWorkflow,
  getWorkflowDetails,
  getWorkflows,
  getWorkflowSetting,
  updateWorkflow,
  updateWorkflowSetting,
} from "../../_helper/services/workflow";

function* onGetWorkflows({ payload: { page, pageSize } }) {
  try {
    // debugger;
    const response = yield call(getWorkflows, { page, pageSize });
    yield put(getWorkflowsSuccess(response));
  } catch (error) {
    yield put(getWorkflowsFail(error.response));
  }
}

function* onGetWorkflowDetails({ payload: id }) {
  try {
    const response = yield call(getWorkflowDetails, id);
    yield put(getWorkflowsDetailsSuccess(response));
  } catch (error) {
    yield put(getWorkflowsDetailsFail(error.response));
  }
}
function* onGetWorkflowSetting({ payload: id }) {
  try {
    const response = yield call(getWorkflowSetting, id);
    yield put(getWorkflowsSettingSuccess(response));
  } catch (error) {
    yield put(getWorkflowsSettingFail(error.response));
  }
}
function* onCreateWorkflow({ payload }) {
  try {
    const response = yield call(createWorkflow, payload);
    yield put(createWorkflowSuccess(response));
    ShowSucess("Created Successfully",{
      position:"top-right"
    })
  } catch (error) {
    yield put(createWorkflowFail(error.response));
  }
}
function* onUpdateWorkflow({ payload: { ID }, payload }) {
  try {
    const response = yield call(updateWorkflow, ID, payload);
    yield put(updateWorkflowSuccess(response));
    ShowSucess("Updated Successfully",{
      position:"top-right"
    })
  } catch (error) {
    yield put(updateWorkflowFail(error.response));
  }
}
function* onCopyWorkflow({ payload: { ID }, payload }) {
  try {
    const response = yield call(copyWorkflow, ID, payload);
    yield put(copyWorkflowSuccess(response));
  } catch (error) {
    yield put(copyWorkflowFail(error.response));
  }
}
function* onUpdateWorkflowSetting({ payload: { ID }, payload }) {
  try {
    const response = yield call(updateWorkflowSetting, ID, payload);
    yield put(updateWorkflowSettingSuccess(response));
    ShowSucess("Updated Successfully",{
      position:"top-right"
    })
  } catch (error) {
    yield put(updateWorkflowSettingFail(error.response));
  }
}
function* onDeleteWorkflow({ payload: ID }) {
  try {
    const response = yield call(deleteWorkflow, ID);
    yield put(deleteWorkflowSuccess(response));
    ShowSucess("Deleted Successfully",{
      position:"top-right"
    })
  } catch (error) {
    yield put(deleteWorkflowFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(GET_WORKFLOWS, onGetWorkflows);
  yield takeLatest(GET_WORKFLOWS_DETAILS, onGetWorkflowDetails);
  yield takeLatest(GET_WORKFLOWS_SETTING, onGetWorkflowSetting);
  yield takeLatest(WORKFLOW_CREATE, onCreateWorkflow);
  yield takeLatest(WORKFLOW_UPDATE, onUpdateWorkflow);
  yield takeLatest(WORKFLOW_COPY, onCopyWorkflow);
  yield takeLatest(SAVE_WORKFLOWS_SETTING, onUpdateWorkflowSetting);
  yield takeLatest(WORKFLOW_DELETE, onDeleteWorkflow);
}

export default CartSaga;
