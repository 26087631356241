import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_PMRCSALARIESS, GET_PMRCSALARIESS_DETAILS,  PMRCSALARIES_CREATE, PMRCSALARIES_DELETE,
   PMRCSALARIES_UPDATE} from "./actionTypes";

import {
  getPmrcsalariessSuccess,
  getPmrcsalariessFail,
  getPmrcsalariessDetailsSuccess,
  getPmrcsalariessDetailsFail,
  createPmrcsalariesSuccess,
  createPmrcsalariesFail,
  updatePmrcsalariesSuccess,
  updatePmrcsalariesFail,
  deletePmrcsalariesSuccess,
  deletePmrcsalariesFail,
} from "./actions";

import {  createPmrcsalaries,deletePmrcsalaries,
	getPmrcsalariesDetails,getPmrcsalariess
	,updatePmrcsalaries,
 } from "../../_helper/services/pmrcsalaries";
function* onGetPmrcsalariess({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPmrcsalariess,{page,pageSize});
    yield put(getPmrcsalariessSuccess(response));
  } catch (error) {
    yield put(getPmrcsalariessFail(error.response));
  }
}

function* onGetPmrcsalariesDetails({ payload: {grade ,step} }) {
  try {
    const response = yield call(getPmrcsalariesDetails, {grade, step});
    yield put(getPmrcsalariessDetailsSuccess(response));
  } catch (error) {
    yield put(getPmrcsalariessDetailsFail(error.response));
  }
}
function* onCreatePmrcsalaries({ payload }) {
	try {
	  const response = yield call(createPmrcsalaries, payload);
	  yield put(createPmrcsalariesSuccess(response));
    ShowSucess("Pmrcsalaries Created Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(createPmrcsalariesFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdatePmrcsalaries({ payload: { grade, step },payload }) {
	try {
	  const response = yield call(updatePmrcsalaries, { grade, step }, payload);
	  yield put(updatePmrcsalariesSuccess(response));
    ShowSucess("Pmrcsalaries Details Updated Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(updatePmrcsalariesFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  
  
  function* onDeletePmrcsalaries({payload: GRADE,STEP} ) {
    console.log("Pay_grade:", GRADE);
    console.log("Pay_step:", STEP);
    
    try {
      const response = yield call(deletePmrcsalaries, GRADE, STEP );
      yield put(deletePmrcsalariesSuccess(response));
      ShowSucess('Deleted successfully', {
        position: 'top-right'
      });
    } catch (error) {
      yield put(deletePmrcsalariesFail(error.response));
      ShowError(error?.response?.data?.message);
    }
  }
  

function* CartSaga() {
  yield takeLatest(GET_PMRCSALARIESS, onGetPmrcsalariess);
  yield takeLatest(GET_PMRCSALARIESS_DETAILS, onGetPmrcsalariesDetails);
  yield takeLatest(PMRCSALARIES_CREATE, onCreatePmrcsalaries);
  yield takeLatest(PMRCSALARIES_UPDATE, onUpdatePmrcsalaries);
  yield takeLatest(PMRCSALARIES_DELETE, onDeletePmrcsalaries);
}

export default CartSaga;