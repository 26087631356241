import React, { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { Btn } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { updateGg1Analyst } from "../../store/GG1-Crud/actions";
import { filterBy } from "@progress/kendo-data-query";
import { loadingPanel } from "../../CommonComponents/CommonFunction";
import { Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusLarge } from "@fortawesome/pro-duotone-svg-icons";
import { advanceSearch } from "../../store/advanceSearchv2/actions";
import { getBBMRAnalyst } from "../Create Transaction/slices/ggTrackSlice";

const columns = [
  {
    field: "EmployeeID",
    header: "Emp ID",
    width: "130px",
  },
  {
    field: "EmployeeName_LFM",
    header: "Name",
    width: "200px",
  },
  {
    field: "DivisionName",
    header: "Department",
    width: "300px",
  },
  {
    field: "TotalAssigned",
    header: "Total Assigned",
    width: "150px",
  },
];
function ConfirmationDialog({ open, onClose, onAssign, HrControl }) {
  const { searchResultsV2 } = useSelector(
    (state) => state.advanceSearchv2Reducer
  );
  const { gg1loading } = useSelector(
    (state) => state.GG1Reducer
  );
  const [BBMRList, setBBMRList] = useState([]);

  const { BBMRList: BBMRAnalyst, isBBMRLoading } = useSelector(
    (state) => state.ggTrackSlice
  );
  useEffect(() => {
    dispatch(getBBMRAnalyst());
  }, []);

  useEffect(() => {
    if (BBMRAnalyst.length) {
      setBBMRList(
        BBMRAnalyst
        // .map((item) => {
        //   return {item
        //     // label: item.EmployeeName_LFM,
        //     // value: item.EmployeeID,
        //   };
        // })
      );
    }
  }, [BBMRAnalyst]);

  // const { results =[] } =
  //   searchResultsV2["bbmr_analyst_lookup"] || {results:[]}

  const [selectedItem, setSelectedItem] = useState(null);
  const [res, setRes] = useState([]);
  const dispatch = useDispatch();

  

  // useEffect(() => {
  //   // Call advanceSearch action with payload
  //   const payload = {
  //     id: "bbmr_analyst_lookup",
  //     gridId: "bbmr_analyst_lookup",
  //     location: "global",
  //     columnLocation: "global",
  //     tableName: "vw_bbmr_analyst_lookup",
  //     searchParams: "[]",
  //     columns: "*",
  //     columnRefernces: "",
  //     pageNumber: 1,
  //     pageSize: 1000,
  //     sortData: "",
  //     // additionalWhere: additionalWhere,
  //     additionalWhere: "",
  //   };
  //   dispatch(
  //     advanceSearch({ bbmr_analyst_lookup: payload }, "bbmr_analyst_lookup")
  //   );
  // }, []);
  
  // using to show loader on base of flag
  // useEffect(() => {
  //   console.log("gg1loading", gg1loading);
  // }, [gg1loading]);

  // useEffect(() => {
  //   console.log("results 1123465", results)
  //   if (results && results?.length) {
  //     setRes(results)
  //   }
  // }, [results]);
  
  const handleAssign = () => {
    if (selectedItem) {
      const payload = {
        bbmrAnalyst: selectedItem.EmployeeID,
        hrControl: HrControl,
      };
      dispatch(updateGg1Analyst(payload));
      setTimeout(() => {
        window.location.href=""
      }, 1500);

    } else {
      console.error("No item selected");
    }
  };
  // const payload ={
  //   bbmrAnalyst:"1234",
  //   hrControl:"1234567"
  // }
  //   const handleAssign = () => {
  //     dispatch(updateGg1Analyst(payload))

  //   };
  const [filter, setFilter] = React.useState();
  // const handleFilterChange = (event) => {
  //   if (event) {
  //     setFilter(event.filter);
  //   }
  // };
  const handleFilterChange = (event) => {
    if (event) {
      const value = event.filter.value.toLowerCase();
      setFilter({
        logic: "or",
        filters: columns.map((column) => ({
          field: column.field,
          operator: "contains",
          value,
        })),
      });
    }
  };

  return (
    <Dialog title="Assign Analyst" onClose={onClose} width={400} visible={open}>
      <MultiColumnComboBox
        // data={results}
        // data={filter ? filterBy(results, filter) : results}
        data={filter ? filterBy(BBMRList, filter) : BBMRList}
        filterable={true}
        onFilterChange={handleFilterChange}
        columns={columns}
        textField={"EmployeeName_LFM"}
        style={{
          width: "300px",
        }}
        className="mt-3 mb-3"
        placeholder="Please select ..."
        onChange={(e) => setSelectedItem(e.target.value)}
      />
 
      <DialogActionsBar>
        
      <Btn
  attrBtn={{
    color: "primary",
    onClick: handleAssign,
  }}
>
  {gg1loading ? (
    <>
      <Spinner style={{ width: "14px", height: "14px", marginRight: "3px" }} />
      Assigning...
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faPlusLarge} style={{ marginRight: "5px" }} />
      Assign
    </>
  )}
</Btn>

      </DialogActionsBar>
    </Dialog>
  );
}

export default ConfirmationDialog;
