import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-duotone-svg-icons";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { verifyDocument } from "../../store/DocumentUpload/actions";

const DocumentViewer = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    url,
    title = "",
    fullWidth,
    maxWidth = "md",
    fullScreen,
    approvalBtn = false,
    idRefId = null,
  } = props;
  const handleClose = () => setOpen(false);

  const handleVerifyDocumnet = (type) => {
    if (!idRefId?.id) {
      return false;
    }
    let payload = {
      id: idRefId?.id,
      referenceId: idRefId?.refId,
      status: type == "verify" ? "Verified" : "Rejected",
    }
    
    dispatch(
      verifyDocument(payload)
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
      fullScreen={fullScreen}
      style={{ zIndex: "99999" }}
    >
      <Grid className="d-flex justify-content-end align-items-center">
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogTitle id="id" style={{ padding: "0 !important" }} className="pb-0">
        <Box
          display="flex"
          // alignItems="center"
          // justifyContent="center"
          className="bg-primary rounded py-3 mb-3"
        >
          <Typography
            variant="h5"
            fontWeight={500}
            style={{ width: "100%", padding: "10px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <FontAwesomeIcon icon={faFile} className="m-r-10" />
                {title}
              </div>
              <div style={{gap:"15px"}}>
                {approvalBtn ? (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleVerifyDocumnet("verify")}
                    style={{marginRight:"10px"}}
                    disabled={idRefId?.status == "Verified"}
                  >
                    {idRefId?.status == "Verified" ? "Verified" :"Verify"}
                  </Button>
                ) : null}
                {approvalBtn ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleVerifyDocumnet("reject")}
                    disabled={idRefId?.status == "Rejected"}
                  >
                    {idRefId?.status == "Rejected" ? "Rejected" : "Reject"}
                  </Button>
                ) : null}
              </div>
            </div>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        className="h-100"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <iframe
          id="documentViewer"
          src={url}
          width="100%"
          height="100%"
          title={title || ""}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DocumentViewer;
