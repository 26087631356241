// import { put } from "redux-saga/effects";BOTTLENECKUPDATE
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getProcessDetails = (id) =>
  get(`${baseURL}${url.PROCESS_DETAILS}/${Number(id)}`);
  export const getNatureKeyValue  =()=> get(baseURL+url.NATURE_KEY_VALUE);
  export const getProcessStatusList = (id) =>
  get(`${baseURL}${url.PROCESS_STATUS_LIST}=${Number(id)}`);
  
  export const getProcessStatusListByNOA = ({ id, noa }) =>
  get(`${baseURL}${url.PROCESS_STATUS_LIST_BY_NOA}?ProcessID=${id}&IgnoreNOA=${noa}`);

  export const getProcesses = ({ page, pageSize }) =>
  get(url.PROCESS_LIST + `?page=${page}&pageSize=${pageSize}`);
//Post
export const updateProcess = (ID, payload) =>
  put(baseURL + url.PROCESS_UPDATE + "/" + Number(ID), payload);
export const updateProcessStatus = (ID, payload) =>
  put(baseURL + url.PROCESS_STATUS_UPDATE + "/" + Number(ID), payload);
export const updateProcessStatusList = (payload) =>
  put(baseURL + url.PROCESS_STATUSLIST_UPDATE, payload);
export const updateBottleneckData = (payload) =>
  post(baseURL + url.BOTTLENECKUPDATE, payload);
export const copyProcess = ( payload) =>
  post(baseURL + url.PROCESS_COPY , payload);
export const createProcess = (payload) =>
  post(baseURL + url.PROCESS_CREATE, payload);
export const createProcessStatus = (payload) =>
  post(baseURL + url.PROCESS_STATUS_CREATE, payload);
//Post
export const deleteProcess = (ID) =>
  remove(baseURL + url.PROCESS_DELETE + "/" + Number(ID));
export const deleteProcessStatus = (ID) =>
  remove(baseURL + url.PROCESS_STATUS_DELETE + "/" + Number(ID));
