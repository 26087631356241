import { get, post } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getExitSurveyAnswers = () =>
  get(`${baseURL + url.EXIT_SURVEY_DATA + url.EXIT_SURVEY_ANSWERS}`);
export const getExitSurveyMainReason = () =>
  get(`${baseURL + url.EXIT_SURVEY_DATA + url.EXIT_SURVEY_MAIN_REASON}`);
export const getExitSurveyMartialStatus = () =>
  get(`${baseURL + url.EXIT_SURVEY_DATA + url.EXIT_SURVEY_MARTIAL_STATUS}`);
export const getExitSurveyGender = () =>
  get(`${baseURL + url.EXIT_SURVEY_DATA + url.EXIT_SURVEY_GENDER}`);
export const getExitSurveyAge = () =>
  get(`${baseURL + url.EXIT_SURVEY_DATA + url.EXIT_SURVEY_AGE}`);
export const getExitSurveyData = () => get(`${baseURL + url.EXIT_SURVEY_DATA}`);
export const getExitSurveyDataByToken = (token) => get(`${baseURL + url.EXIT_SURVEY_DATA + '/GetSurveyDetailByToken?surveyToken=' + encodeURIComponent(token)}`);
export const creteExitSurveyApi = (payload) => post(`${baseURL + url.EXIT_SURVEY_DATA + '/SaveExitSurveyData'}`, payload);
export const creteExitSurveyFormTokenApi = (payload) => post(`${baseURL + url.EXIT_SURVEY_DATA + '/GetSurveyTokenByEmail'}`, payload);