import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ShowSucess, ShowError } from "../../../_helper/helper";
import { retry } from "redux-saga/effects";
import { useAuth } from "oidc-react";
const sliceName = "Transaction";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const transactionData = createAsyncThunk(
  `${sliceName}/TransactionData`,
  async (payload, status) => {
    console.log("create123456", payload.payload);
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    let ggTrackArray = {};
    let data = {};
    let message = "";
// console.log("useAuth",useAuth)
    try {
      if (payload.payload !== undefined) {
        try {
          const submitTransactionData = await axios.post(
            `${
              process.env.REACT_APP_API_DOTNET_URL
              // }/GG1/saveTransactions?action=${
            }/GG1Common/saveTransactions?action=${
              payload.staus ? "create" : "update"
            }`,
            payload.payload,
            {
              headers: {
                "Content-Type": "application/json",
                // Authorization: `Bearer ${useAuth?.oidc?.user?.access_token}`, 
                Authorization: `Bearer ${sessionStorage.getItem(
                  "jwt_access_token"
                )}`,
                // Authorization: `Bearer ${localStorage.getItem(
                //   "jwt_access_token"
                // )}`,
              },
            }
          );

          if (submitTransactionData.data) {
            console.log("dg", submitTransactionData.data);
            if (
              submitTransactionData.status === 200 ||
              submitTransactionData.status === 201
            ) {
              if (payload.staus == true) {
                data = submitTransactionData.data;
                ggTrackArray = submitTransactionData.data;
              } // message = submitTransactionData.data.message;
              if (payload.staus == false) {
                const msg = submitTransactionData.data.message;
                data = submitTransactionData.data;
                const withoutbr = msg.replace("<br/>", "\n");
                ShowSucess(withoutbr);
              }
            }
            return {
              data: data,
              GGTrackData: ggTrackArray,
              staus: payload.staus,
              message: message,
            };
          }
        } catch (error) {
          if (error?.response?.data?.title) {
            ShowError(error?.response?.data?.title);
          }
        }
      }

      console.log("dg", ggTrackArray);
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getBBMRJobOrder = createAsyncThunk(
  `${sliceName}/getBBMRJobOrder`,
  async (payload, setObj = null) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole"); 
   
// console.log("useAuth",useAuth)
    try {
      
        try {
          const BBMRJobOrderData = await axios.post(
            `${
              process.env.REACT_APP_API_DOTNET_URL
              // }/GG1/saveTransactions?action=${
            }/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
            {
              "tableName": "vw_joborder_detailed_lookup",
              "searchParams": "[]",
              "columns": "*",
              "pageNumber": 1,
              "pageSize": 100000,
              "sortData": "",
              "additionalWhere" : `Job_Number = '${payload}'`,
              "qID": 0,
              "role": "",
              "location": "global",
              "id": "jobOrderSelect"
          },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionStorage.getItem(
                  "jwt_access_token"
                )}`,
              },
            }
          );
          if (BBMRJobOrderData.data) {
            return {
              BBMRjobOrderNo: BBMRJobOrderData?.data?.results?.length ? BBMRJobOrderData?.data?.results[0] : {},
            };
          }else{
            return {
              BBMRjobOrderNo : {}
            }
          }
        } catch (error) {
          if (error?.response?.data?.title) {
            ShowError(error?.response?.data?.title);
          }
        }
      

     
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
const TransactionSlice = createSlice({
  name: sliceName,
  initialState: {
    data: {},
    GGTrackData: {},
    status: "",
    BBMRJobOrdersNo: {}
    // message: "",
  },
  extraReducers: {
    [transactionData.fulfilled]: (state, action) => {
      console.log("action 1212121 ::::: ==> ", action);
      if (action.payload?.staus === true) {
        state.GGTrackData = action.payload?.GGTrackData;
        state.status = "create";
      } else {
        state.status = "update";
        state.GGTrackData = {};
        // state.message = action.payload.message;
      }
    },
    [transactionData.pending]: (state, action) => {
      // console.log("action 1212121 ::::: ==> ", action, create);
      state.GGTrackData = {};
      state.status = "";
    },
    [getBBMRJobOrder.fulfilled]: (state, action) => {
        state.BBMRJobOrdersNo = action.payload.BBMRjobOrderNo;
    },
    [getBBMRJobOrder.pending]: (state, action) => {
      state.BBMRJobOrdersNo = [];
    },
  },
});

export default TransactionSlice.reducer;
