import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useContext } from "react";
import { Maximize } from "react-feather";
import { LI, UL } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import Bookmarks from "./Bookmark";
import LanguageClass from "./Language";
import LogoutClass from "./Logout";
import MessageDrop from "./MessageDrop";
import MoonLight from "./MoonLight";
import Notifications from "./Notifiations";
import { ShowError, getRole, getUserRole } from "../../../_helper/helper";
import { useAuth } from "oidc-react";
import { isArray } from "lodash";
import { MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRoles } from "../../../store/Common/actions";
import { getSidebarMenuByRole } from "../../../store/sidebarmenu/actions";
import { LoadingPanel } from "../../../CommonComponents/CommonFunction";
import { useNavigate } from "react-router-dom";
import { advanceSearch } from "../../../_helper/services/advanceSearchv2";
// import { advanceSearch } from "../../../store/advanceSearchv2/actions";

const Rightbar = () => {
  
  const { searchResultsV2 } = useSelector((state) => state.advanceSearchv2Reducer);
  const IdNotification = "vw_dashboard_notifications"
  const { results, rowCount } = searchResultsV2["vw_dashboard_notifications"] == undefined ? { results: [] } : searchResultsV2["vw_dashboard_notifications"];

  const { sidebarResponsive } = useContext(CustomizerContext);
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const navigate = useNavigate();
  const userRole = getUserRole(userData, true);
  const { loading, sidebarmenu } = useSelector((state) => state.SideBarMenuReducer);
  const [selectedRole, setSelectedRole] = useState('');
  const [notify, setNotify] = useState([])
  useEffect(() => {
    const currentRole = sessionStorage.getItem('currentRole');
    if ((isArray(userRole) ? userRole?.includes(currentRole) : userRole == currentRole) && !!currentRole) {
      setSelectedRole(currentRole);
    } else {
      setSelectedRole(isArray(userRole) ? userRole[0] : userRole)
    }
  }, [userRole])

  useEffect(() => {
    if (selectedRole) {
      sessionStorage.setItem('currentRole', selectedRole);
      dispatch(setSelectedRoles(selectedRole));
      dispatch(getSidebarMenuByRole({ role: selectedRole }));
    }
  }, [selectedRole]);

  // useEffect(() => {
  //   navigate('/dashboard/default');
  // }, [sidebarmenu])

  const handleRoleChange = (evt) => {
    if (process.env.REACT_APP_REDIRECTION_URL) {
      if ((evt.target.value || '').startsWith("PR")) {
        const otherSiteUrl = process.env.REACT_APP_REDIRECTION_URL || "";
        const redirectUrl = new URL(otherSiteUrl);
        // redirectUrl.searchParams.append('access_token', sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`));
        redirectUrl.searchParams.append('role', evt.target.value);
        window.location.href = redirectUrl.toString();
        return
      }
    }

    setSelectedRole(evt.target.value);
    if(evt.target.value == "BBMR-ADMIN" || evt.target.value == "BBMR-ANALYST" || evt.target.value == "BBMR-SUPERVISOR" || evt.target.value == "BBMR-DIRECTOR"){
      navigate('/bbmr-gg1-tracking');
    }else{
      navigate('/dashboard/default');
    }
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  // useEffect(() => {
  //   dispatch(
  //     advanceSearch({
  //       id:'vw_dashboard_notifications',

  //       searchParams: "[]",
  //       columns: "*",
  //       pageNumber: 1,
  //       pageSize: 999,
  //       sortData: "",
  //       additionalWhere: "",
  //       qID: 0,
  //       location: "global", tableName: "vw_dashboard_notifications",
  //     })
  //   )
  // }, [dispatch]);
  const payload = {
    id: "vw_dashboard_notificationsv2",
    // gridId: "vw_dashboard_notifications",
    location: "global",
    // columnLocation: "global",
    tableName: "vw_dashboard_notificationsv2",
    searchParams: "[]",
    columns: "*",
    // columnRefernces: "",
    pageNumber: 1,
    pageSize: 999,
    sortData: "",
    additionalWhere: "",
    qID: 0,
    // role: "SUPER-USER"
  };

  const firstCallNotificationRef = useRef(true)

  const getAllNotifications = async()=>{
    try{
    const response = await advanceSearch(payload)
    const {results} = response
    setNotify(results)
    console.log(response,'getAllNotifications')
    }catch(error){
      // ShowError(error?.message??"No Notifications found")
    }finally{
      firstCallNotificationRef.current = false
    }
  }


  useEffect(() => {
    // Call advanceSearch action with payload
    if(firstCallNotificationRef.current){
      getAllNotifications()
    }
    const intervalId = setInterval(() => {
      // dispatch(advanceSearch({ "vw_dashboard_notifications": payload }, 'vw_dashboard_notifications'));
      getAllNotifications()
      }, 2 * 60 * 1000);
    
    return () => clearInterval(intervalId);  
  }, []);

   const resultDependency = useMemo(()=>JSON.stringify(results),[results])

  useEffect(() => {
    if (results) {
      // setNotify(results)
    }

  }, [resultDependency])

  return (
    <Fragment>
      {loading && <LoadingPanel />}
      <div className="nav-right col pull-right right-menu p-0">
        <UL
          attrUL={{
            className: `simple-list d-flex flex-row nav-menus ${sidebarResponsive ? "open" : ""
              }`,
          }}
        >
          <LI>
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Maximize />
            </a>
          </LI>
          {/* <LanguageClass /> */}
          {/* <Bookmarks /> */}
          <Notifications results={notify} />
          <MoonLight />
          {/* <MessageDrop /> */}

          {(isArray(userRole) && userRole.length > 1) && <Select
            size="small"
            placeholder="Role"
            className="m-r-10"
            style={{ width: "200px" }}
            variant="outlined"
            value={selectedRole}
            // onChange={(evt) => setSelectedRole(evt.target.value)}
            onChange={handleRoleChange}
          >
            {userRole.map((option) => (
              <MenuItem className='p-2' key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>}

          <LogoutClass />
        </UL>
      </div>
    </Fragment>
  );
};

export default Rightbar;
