import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const sliceName = "employeeSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const fetchEmployeeSearch = createAsyncThunk(
  `${sliceName}/fetchEmployeeSearch`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/advancesearch/v2`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": sessionStorage.getItem("jwt_access_token"),

            // "x-access-token": localStorage.getItem("jwt_access_token"),
          },
        }
      );
      return {
        employeeSearched: data.data,
      };
    } catch (e) {
      return {
        error: e.message,
        employeeSearched: [],
      };
    }
  }
);

const chartSlice = createSlice({
  name: sliceName,
  initialState: {
    employeeSearched: [],
  },
  extraReducers: {
    [fetchEmployeeSearch.fulfilled]: (state, action) => {
      state.employeeSearched = action.payload.employeeSearched;
    },
  },
});

export default chartSlice.reducer;
