import { get, post, remove, put } from "../../api_helper";
import * as url from "../../url_helper";
import {
  LEAVE_SHARE_FORM,
  LEAVE_DELETE,
  Leave_Create,
  Leave_Update,
  Leave_GetInfo,
  LEAVE_SHARE_BY_EMPLOYEE_ID,
} from "../../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

// export const getLeaveShareForm = (leaveAppID, leaveShareID) =>get(`${baseURL}${LEAVE_SHARE_FORM}?LeaveAppID=${15}&LeaveShareID=${20001}`);
export const getLeaveShareForm = (leaveAppID) => {
  //
  // console.log("urlid", leaveAppID, leaveshareId);
  // const newLeaveShareID = leaveshareId === undefined ? 0 : leaveshareId;

  return get(`${baseURL}${LEAVE_SHARE_BY_EMPLOYEE_ID}=${leaveAppID}`);
};
export const deleteleaveshareform = (dID) =>
  remove(`${baseURL}${LEAVE_DELETE}?PrDonatedLeaveRequestId=${dID}`);
export const createleaveshareform = (payload) =>
  post(`${baseURL}${Leave_Create}`, payload);
export const updateleaveshareform = (payload) =>
  put(`${baseURL}${Leave_Update}`, payload);
export const getleaveshareInfo = (EmpID) =>
  get(`${baseURL}${Leave_GetInfo}?EmpID=${EmpID}`);
