const visibleColumns = [
  {
    title: "Department Name",
    field: "DepartmentName",
    locked: true,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Employee Name",
    field: "Name2",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Social Security Number",
    field: "SSN",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Effective Date",
    field: "Action_Effective_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "Definition",
    field: "NATURE_TITLE",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Position Title",
    field: "TITLE",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Annual Salary",
    field: "Annual_Salary",
    locked: false,
    filter: "numeric",
    width: 150,
    show: true,
  },
  {
    title: "Date of Birth",
    field: "Ndate_Of_Birth",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "EIN",
    field: "EIN",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Mailing Address",
    field: "Mailing_Add",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Home Phone",
    field: "Hm_Phone",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Work Phone",
    field: "Ml_Wk_Phone",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
  {
    title: "Address",
    field: "ADDRESS",
    locked: false,
    filter: "text",
    width: 150,
    show: true,
  },
];

export default visibleColumns;
