
import { takeLatest, put, call } from "redux-saga/effects";

import {	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_Salary_Group,
  GET_CGI_BOTTLENET,
  GET_SOLIDGAUGE,
  GET_BBMRGG1ANALYST,
  GET_BBMRGG1ASSIGNANALYST
 } from "./actionTypes";

import {
  getChartDataYearOfServiceSuccess,
  getChartDataYearOfServiceFail,
  getChartDataAgeGroupSuccess,
  getChartDataAgeGroupFail,
  getChartDataSalaryGroupSuccess,
  getChartDataSalaryGroupFail,
  getcglBottleNetSuccess,
  getcglBottleNetFailure,
  getsolidGaugeSuccess,getsolidGaugeFailure, BBMRGG1AnalystSuccess, BBMRGG1AnalystFail, BBMRGG1AssignAnalystFail, BBMRGG1AssignAnalystSuccess
} from "./actions";

import { getChartDataForYearOfService ,
   getChartDataForAgeGroup , 
   getChartDataForSalaryGroup ,getSolidGaugeApi,
   getBottlenetChartApi, 
   getBBMRGG1}  from "../../_helper/services/chart"


function* onGetCGLBOTTLENET({payload}  ) {
  
  console.log("checckingPayload",payload)
  try {
    const response = yield call(getBottlenetChartApi,payload);
    yield put(getcglBottleNetSuccess(response));
    console.log("cgl",response)
  } catch (error) {
    yield put(getcglBottleNetFailure(error.response));
  }
}
function* onGetChartDataYearofService({ payload: queryName }) {
 
  try {
    const response = yield call(getChartDataForYearOfService,queryName);
    yield put(getChartDataYearOfServiceSuccess(response));
  } catch (error) {
    yield put(getChartDataYearOfServiceFail(error.response));
  }
}


function* onGetChartDataAgeGroup({ payload: queryName }) {
  try {
    const response = yield call(getChartDataForAgeGroup , queryName);
    yield put(getChartDataAgeGroupSuccess(response));
  } catch (error) {
    yield put(getChartDataAgeGroupFail(error.response));
  }
}

function* onGetBBMRGG1Analyst({ payload: queryName }) {
  try {
    const response = yield call(getBBMRGG1 , queryName);
    yield put(BBMRGG1AnalystSuccess(response));
  } catch (error) {
    yield put(BBMRGG1AnalystFail(error.response));
  }
}

function* onGetBBMRGG1AssignAnalyst({ payload: queryName }) {
  try {
    const response = yield call(getBBMRGG1 , queryName);
    yield put(BBMRGG1AssignAnalystSuccess(response));
  } catch (error) {
    yield put(BBMRGG1AssignAnalystFail(error.response));
  }
}

function* onGetChartDataSalaryGroup({ payload: queryName }) {
  try {
    const response = yield call(getChartDataForSalaryGroup , queryName);
    yield put(getChartDataSalaryGroupSuccess(response));
  } catch (error) {
    yield put(getChartDataSalaryGroupFail(error.response));
  }
}
function* onGetGaugeSolidApi({ payload:PPE  }) {
  try {
    const response = yield call(getSolidGaugeApi , PPE);
    yield put(getsolidGaugeSuccess(response));
    console.log("repsoneSuuccess",response)
  } catch (error) {
    yield put(getsolidGaugeFailure(error.response));
    console.log("repsoneFail",error.response)
  }
}
function* ChartSaga() {
  yield takeLatest(GET_CGI_BOTTLENET, onGetCGLBOTTLENET);
  yield takeLatest(GET_CHART_DATA_YEAR_SERVICE, onGetChartDataYearofService);
  yield takeLatest(GET_CHART_DATA_AGE_GROUP, onGetChartDataAgeGroup);
  yield takeLatest(GET_CHART_DATA_AGE_Salary_Group, onGetChartDataSalaryGroup);
  yield takeLatest(GET_SOLIDGAUGE, onGetGaugeSolidApi);
  yield takeLatest(GET_BBMRGG1ANALYST, onGetBBMRGG1Analyst);
  yield takeLatest(GET_BBMRGG1ASSIGNANALYST, onGetBBMRGG1AssignAnalyst);
}

export default ChartSaga;