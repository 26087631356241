import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import ErrorBoundary from '../../../ErrorBoundry';

const data = [
  {
    dateReceived: '12/30/2023',
    dateEmployeeSignedForm: '12/28/2023',
    recipientName: 'Rex Nguyen',
    recipientPositionTitle: 'COMMODITY INSPECTOR III',
    hoursRequested: 160,
    numberOfDonors: 22,
    hrdMemo: '22-0614A',
    leaveShareStatus: 'APPROVED',
    numberOfDaysToComplete: -359
  },
  {
    dateReceived: '3/8/2023',
    dateEmployeeSignedForm: '3/3/2023',
    recipientName: 'Isabela Livingston',
    recipientPositionTitle: 'COMMODITY INSPECTOR III',
    hoursRequested: 40,
    numberOfDonors: 22,
    hrdMemo: '22-0614E',
    leaveShareStatus: 'APPROVED',
    numberOfDaysToComplete: 9
  },
  {
    dateReceived: '3/1/2023',
    dateEmployeeSignedForm: '3/1/2023',
    recipientName: 'Joanna Mosley',
    recipientPositionTitle: 'COMMODITY INSPECTOR III',
    hoursRequested: 80,
    numberOfDonors: 22,
    hrdMemo: '22-0614D',
    leaveShareStatus: 'APPROVED',
    numberOfDaysToComplete: 6
  },
  {
    dateReceived: '2/24/2023',
    dateEmployeeSignedForm: '2/20/2023',
    recipientName: 'Kaila Chavez',
    recipientPositionTitle: 'COMMODITY INSPECTOR III',
    hoursRequested: 25,
    numberOfDonors: 22,
    hrdMemo: '22-0614C',
    leaveShareStatus: 'APPROVED',
    numberOfDaysToComplete: 6
  },
  {
    dateReceived: '2/7/2023',
    dateEmployeeSignedForm: '2/6/2023',
    recipientName: 'Miles Wheeler',
    recipientPositionTitle: 'COMMODITY INSPECTOR III',
    hoursRequested: 40,
    numberOfDonors: 22,
    hrdMemo: '22-0614B',
    leaveShareStatus: 'APPROVED',
    numberOfDaysToComplete: 8
  }
];

const columns = [
  'DATE RECEIVED',
  'DATE EMPLOYEE SIGNED FORM',
  'RECIPIENT NAME',
  'RECIPIENT POSITION TITLE',
  'HOURS REQUESTED',
  'NUMBER OF DONORS',
  'HRD MEMO #',
  'LEAVE SHARE STATUS',
  'NO. OF DAYS TO COMPLETE'
];

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  sectionHeader: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  footer: {
    textAlign: 'center',
    marginTop: '20px',
  },
});

const LeaveShareTable = () => {
  const classes = useStyles();

  const sections = groupByDepartment(data);

  return (
    <ErrorBoundary>
      <Container maxWidth="md">
        <div>
          {sections.map((section, index) => (
            <Fragment key={index}>
              <h4 className={classes.sectionHeader}>NUMBER OF LEAVE SHARE REQUEST RECEIVED REPORT:</h4>
              <Divider />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="leave-share-table">
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell key={column}>{column}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.data.map((rowData, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>{rowData.dateReceived}</TableCell>
                        <TableCell>{rowData.dateEmployeeSignedForm}</TableCell>
                        <TableCell>{rowData.recipientName}</TableCell>
                        <TableCell>{rowData.recipientPositionTitle}</TableCell>
                        <TableCell>{rowData.hoursRequested}</TableCell>
                        <TableCell>{rowData.numberOfDonors}</TableCell>
                        <TableCell>{rowData.hrdMemo}</TableCell>
                        <TableCell>{rowData.leaveShareStatus}</TableCell>
                        <TableCell>{rowData.numberOfDaysToComplete}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider />
              </TableContainer>
              <div className={classes.footer}>
                <p>Leave Request by Department: {section.data.length}</p>
              </div>
            </Fragment>
          ))}
        </div>
      </Container>
    </ErrorBoundary>
  );
};

// Helper function to group data by department
const groupByDepartment = data => {
  const grouped = {};
  data.forEach(entry => {
    const department = entry.recipientPositionTitle;
    if (!grouped[department]) {
      grouped[department] = [];
    }
    grouped[department].push(entry);
  });
  return Object.keys(grouped).map(department => ({
    department,
    data: grouped[department],
  }));
};

export default LeaveShareTable;
