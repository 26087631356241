import React, { useState, useEffect, useMemo } from "react";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  FormControl,
  TableCell,
  TextareaAutosize,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  Checkbox,
} from "@mui/material";
import HistoryDataTable from "../../../CommonComponents/HistoryDataTable";
import { useForm, Controller } from "react-hook-form";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import InputAdornment from "@mui/material/InputAdornment";
import index from "./index.css";
import {
  advanceSearch,
  advanceSearchStatus,
  advanceSearchEmplType,
  advanceSearchEducation,
  advanceSearchEthinic,
  advanceSearchCitizen,
  advanceSearchMilitary,
  advanceSearchDepartment,
  advanceSearchCalculateSVC,
  advanceSearchTotalSVC,
  advanceSearchRetirement,
  advanceSearchAppropriation,
  findAsyouType,
} from "../../../store/advanceSearchv2/actions";
import PositionForm from "../../../CommonComponents/PositionForm/index";
import { padStart } from "lodash";
// import { ShowError, ShowSucess } from "../../_helper/helper";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import HistoryColumns from "./HistoryColumns";
import { filterBy } from "@progress/kendo-data-query";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAllowedURL, getUserRole } from "../../../_helper/helper";
import { Breadcrumbs } from "../../../AbstractElements";
import { Btn } from "../../../AbstractElements";
import { faSave, faUndo, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { updateGg1Master } from "../../../store/employees/actions";
import {
  loadingPanel,
  ConcatenedColumns,
  sortingFunc,
} from "../../../CommonComponents/CommonFunction";
import { useDispatch } from "react-redux";

const validationSchema = Yup.object().shape({
  employeeNameLfm: Yup.string().required("Employee Name is required"),
  email: Yup.string().required("Email is required"),
  sex: Yup.string().required("Gender is required"),
  maritalStatus: Yup.string().required("Marital Status is required"),
  citizen: Yup.string().required("Citizen is required"),
  education: Yup.string().required("Education is required"),
  hmPhone: Yup.string().required("Phone No is required"),
  workSch: Yup.string().required("Work Schedule is required"),
});
const GG1EmployeeInformation = ({ userData }) => {
  const {
    register,
    formState: { touchedFields },
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      employeeNameLfm: "",
      email: "",
      sex: "",
      maritalStatus: "",
      citizen: "",
      education: "",
      hmPhone: "",
      workSch: "",
      budgetAcctNo: "",
      govtServYear: null,
      psYy: null,
      psMm: null,
      psDd: null,
      npositionTitle: "",
      exempt_Position: "",
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });
  const userRole = getUserRole(userData);
  const [is1stRender, setIs1stRender] = useState(false);
  const isUserRoleXYZ = userRole === "EmployeeSuperUser" || "Developer";
  console.log("userRole", userRole);
  console.log("userData", userData);

  const isRole = userData?.profile?.MODIFY_EMPLOYEE !== "YES";

  // const isRole = isUserRoleXYZ ? false : true;
  const {
    searchResults: { results },
    ethinic,
    appropriation,
    position,
    retirement,
    emplType,
    education,
    citizen,
    military,
    status,
    currentSVC,
    totalSVC,
    findAsyouTypeResults,
    loadingFindAsYouType,
    departmentDivision,
    loadingSearch,
  } = useSelector((state) => state.advanceSearchv2Reducer);
  // const isEditableUserRole = ["HRAdmin", "Developer", "supervisor", "user", "reviewer", "appointingAuthority"].includes(userRole);
  const [sortData, setSortData] = useState([
    { field: "deductionName", dir: "desc" },
  ]);
  const [sortedColumns, setSortedColumns] = useState([]);
  const genderList = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];
  const [exemptVal, setExemptVal] = useState(null);
  const [data, setData] = useState({
    genderList: genderList,
    positionList: position,
    emplTypeList: emplType,
    // dpdvList:
  });
  const [valData, setValData] = useState({
    gender: { value: null, label: null },
  });

  const [customData, setCustomData] = useState({
    yy: null,
    mm: null,
    dd: null,
  });
  const findgg1 =
    // useSelector((state) => state?.employeeReducer?.findEmployee) || {};
    useSelector((state) => state?.employeeReducer?.findGg1Employee) || {};
  console.log("findgg1", findgg1);
  const { error: responseErr, loadingEmployee } =
    useSelector((state) => state.employeeReducer) || {};
  const { priorServiceYears, priorServiceMonths, priorServiceDays } =
    findgg1 || {};
  const { yy, mm, dd } = currentSVC[0] || {};
  const { yy: YY, mm: MM, dd: DD } = totalSVC[0] || {};

  const loading = loadingSearch || loadingEmployee;
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      function compareObjects() {
        // Initialize changes object
        const changes = {};
        for (const key in touchedFields) {
          if (data[key] !== "Invalid Date") {
            changes[key] = data[key];
          }
        }
        const invalidDateKeys = Object.keys(touchedFields).filter(
          (key) => data[key] === null
        );
        if (invalidDateKeys.length > 0) {
          changes["empty"] = invalidDateKeys.join(",");
        }

        return changes;
      }

      const changes = compareObjects();
      if (changes && Object.keys(changes).length > 0) {
        dispatch(updateGg1Master({ ...changes, id: data["id"] }));
      } else {
        return;
      }
    } catch (error) {
      // Validation failed, display error messages
      const validationErrors = {};
      error.inner.forEach((fieldError) => {
        validationErrors[fieldError.path] = fieldError.message;
      });
      setError(validationErrors);
    }
  };
  console.log("touchedFields", touchedFields);
  const filterData = (filter) => {
    const data = genderList?.slice();
    return filterBy(data, filter);
  };
  const filterChange = (event) => {
    setData({ ...data, genderList: filterData(event.filter) });
  };
  const action = "toggleSwitch";
  const [positionTitle, setPositionTitle] = useState(null);
  const columns = [
    {
      column_name: "CreatedDate",
      column_title: "Createddate",
      data_type: "datetime",
      status: 1,
    },
    {
      column_name: "CurrentProcessID",
      column_title: "Currentprocessid",
      data_type: "int",
      status: 1,
    },
  ];

  function calculateSVCFun() {
    if (findgg1 && Object.keys(findgg1).length > 0) {
      const dstart = new Date(getValues("dateEmployment"));

      const yy = getValues("psYy");
      const mm = getValues("psMm");
      const dd = getValues("psDd");

      dstart.setFullYear(dstart.getFullYear() - yy);
      dstart.setMonth(dstart.getMonth() - mm);
      dstart.setDate(dstart.getDate() - dd);
      // const formattedDate = dstart && dstart?.toISOString().split("T")[0];
      console.log("dstart", getValues("dateEmployment"));

      dispatch(
        advanceSearchCalculateSVC({
          ...payload,
          id: "CurrentSVC",
          tableName: "fn_getdate_year_month_day",
          searchParams: JSON.stringify([
            {
              columnName: "dstart",
              operator: "=",
              value: getValues("dateEmployment"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
            {
              columnName: "dend",
              operator: "=",
              value:
                getValues("empStatus") === "I"
                  ? getValues("dateTerm")
                  : dayjs(new Date()).format("MM/DD/YYYY"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
          ]),
        })
      );
      dispatch(
        advanceSearchTotalSVC({
          ...payload,
          id: "TotalSVC",
          tableName: "fn_getdate_year_month_day",
          searchParams: JSON.stringify([
            {
              columnName: "dstart",
              operator: "=",
              value: dayjs(dstart).format("MM/DD/YYYY"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
            {
              columnName: "dend",
              operator: "=",
              value:
                getValues("empStatus") === "I"
                  ? getValues("dateTerm")
                  : dayjs(new Date()).format("MM/DD/YYYY"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
          ]),
        })
      );
    } else {
      return;
    }
  }
  useEffect(() => {
    if (findgg1 && Object.keys(findgg1)?.length > 0) {
      const posiFilter =
        findAsyouTypeResults &&
        findAsyouTypeResults.length > 0 &&
        findAsyouTypeResults?.filter(
          (d) => d?.valueField?.trim() == findgg1?.npositionNo?.trim()
        );
      setPositionTitle(
        posiFilter[0]?.keyField || findgg1?.npositionTitle?.trim()
      );

      setCustomData({
        yy: findgg1?.totalSerYr,
        mm: findgg1?.totalSerMm,
        dd: findgg1?.totalSerDd,
      });
      setExemptVal(findgg1?.exempt_Position);
      reset({
        ...findgg1,
        maritalStatus: findgg1?.maritalStatus?.trim() || null,
        rate: findgg1?.rate,
        jobOrderNo: findgg1?.jobOrderNo?.trim() || "N/A",
        education: findgg1?.education?.trim() || "N/A",
        dateEmployment: findgg1?.dateEmployment,
        driversLicenseNo: findgg1?.driversLicenseNo?.trim() || "N/A",
        firstName: findgg1.firstName,
        deptNo: findgg1?.dpdv?.slice(0, 2),
        dpdv: findgg1?.dpdv?.trim(),
        // i9:findgg1?.i9,
      });

      calculateSVCFun();
    }
  }, [findgg1]);

  const payload = {
    tableName: "vw_employeeMaster",
    searchParams: "[]",
    columns: "*",
    pageNumber: 1,
    pageSize: 9999,
    sortData: "",
    additionalWhere: "",
    qID: 0,
    location: "global",
  };
  const handleCustomChange = (e) => {
    setCustomData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setIs1stRender(true);
  };

  useEffect(() => {
    if (is1stRender && customData && Object.values(customData).length > 0) {
      const { yy, mm, dd } = customData;
      const total = Number(yy) * 365 + Number(mm) * 30 + Number(dd);
      setValue("govtServYear", total, {
        shouldTouch: true,
        shouldDirty: true,
      });
    }
  }, [customData, is1stRender]);

  useEffect(() => {
    dispatch(
      advanceSearchDepartment({
        ...payload,
        tableName: "LK_DPDV",
        columns: "distinct Dept_key, name",
      })
    );
    dispatch(
      advanceSearchRetirement({ ...payload, tableName: "PMRC_RETIRESS" })
    );
    dispatch(
      advanceSearchAppropriation({
        ...payload,
        tableName: "LK_Appropriation_Type",
      })
    );
    dispatch(
      advanceSearch(
        {
          ...payload,
          tableName: "LK_DPDV",
          columns: "distinct Dept_Code, DepartmentName",
          additionalWhere: "DepartmentName is not null",
        },
        "division"
      )
    );

    dispatch(
      advanceSearchEmplType(
        {
          ...payload,
          tableName: "LK_Empl_Type",
          additionalWhere: "DESCRIPTION is not null",
        },
        "empType"
      )
    );
    dispatch(
      advanceSearchEducation(
        { ...payload, tableName: "LK_Education" },
        "education"
      )
    );
    dispatch(
      advanceSearchCitizen({ ...payload, tableName: "LK_Citizen" }, "citizen")
    );
    dispatch(
      advanceSearchStatus({ ...payload, tableName: "INACTIVE_ST" }, "status")
    );
    dispatch(
      advanceSearchEthinic(
        { ...payload, tableName: "LK_Ethic_Group" },
        "ethinic"
      )
    );
    dispatch(
      advanceSearchMilitary(
        {
          ...payload,
          tableName: "LK_Military_Status",
          // additionalWhere: "Military_Title not null",
        },
        "military"
      )
    );
  }, []);
  const buttonStyle = {
    margin: "5px",
  };

  // If the user has permission, enable mouse interaction
  if (!isRole) {
    buttonStyle.pointerEvents = "auto";
  } else {
    // If the user doesn't have permission, disable mouse interaction
    buttonStyle.pointerEvents = "none";
  }
  const [error, setError] = useState({});
  const FormInputText = ({
    name,
    label,
    type = "text",
    options = [],
    className,
    disabled = false,
    readOnly = false,
    handleChange,
    customVal,
    placeholder,
    onFilterChange,
    textField,
    InputProps,
  }) => {
    const errorMessage = error[name];
    const displayValue =
      getValues(name) === null || getValues(name) === ""
        ? "N/A"
        : getValues(name);

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value }, field }) => {
          if (type === "date") {
            return (
              <FormControl
                error={
                  !!errorMessage && onBlur && (value === "" || value === null)
                }
                fullWidth
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={label}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        inputVariant="outlined"
                        size="small"
                        {...params}
                        error={false}
                      />
                    )}
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                    value={dayjs(value) || null}
                    onChange={(value) => {
                      const formatDate = value?.$d;
                      const date = formatDate
                        ? dayjs(formatDate).format("YYYY-MM-DD hh:mm:ss")
                        : null;
                      setValue(name, date, {
                        shouldTouch: true,
                        shouldDirty: true,
                      });
                    }}
                    format="MM/dd/yyyy"
                    onBlur={onBlur}
                  />
                </LocalizationProvider>
                {value === "" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
            );
          } else if (type === "radio") {
            return (
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <FormControl
                    error={
                      !!errorMessage &&
                      onBlur &&
                      (value === "" || value === null)
                    }
                    component="fieldset"
                    fullWidth
                    size="small"
                    margin="dense"
                    style={{ marginTop: "-1px" }}
                  >
                    <FormLabel
                      style={{ marginBottom: "-5px" }}
                      component="legend"
                    >
                      {label}
                    </FormLabel>
                    <RadioGroup
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      row
                    >
                      {options.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                          disabled={disabled}
                          readOnly={readOnly}
                        />
                      ))}
                    </RadioGroup>
                    {value === "" && errorMessage && (
                      <FormHelperText style={{ marginTop: "-13px" }}>
                        {errorMessage}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            );
          } else if (type === "dropdown") {
            return (
              <FormControl
                error={
                  !!errorMessage && onBlur && (value === "" || value === null)
                }
                fullWidth
                size="small"
                margin="dense"
              >
                <InputLabel>{label}</InputLabel>
                <Select
                  value={value}
                  onChange={handleChange || onChange}
                  onBlur={onBlur}
                  disabled={disabled}
                  readOnly={readOnly}
                >
                  {options.map((option, index) => (
                    <MenuItem key={`${index}+1`} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          } else if (type === "textarea") {
            return (
              <FormControl
                error={
                  !!errorMessage && onBlur && (value === "" || value === null)
                }
                fullWidth
              >
                <TextareaAutosize
                  rows={4}
                  minRows={2}
                  placeholder={label}
                  className={className}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  style={{
                    width: "100%",
                    resize: "vertical",
                    padding: "6px 8px",
                    fontFamily: "inherit",
                    fontSize: "inherit",
                    lineHeight: "inherit",
                    borderRadius: 4,
                    borderColor: "black",
                  }}
                />
                {value === "" && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>
            );
          } else {
            const isPayGradeField = name === "payStep";

            // Apply padding logic if it's the "Pay Grade" field
            const paddedValue = isPayGradeField
              ? padStart(displayValue, 2, "0")
              : displayValue;
            return (
              <TextField
                size="small"
                type={type}
                fullWidth
                margin="dense"
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                // value={value === null ? "N/A" : value}
                value={
                  value == null
                    ? "N/A"
                    : name === "stepActual" && !isNaN(value) && value < 10
                    ? `0${value}`
                    : value
                }
                label={label}
                disabled={disabled}
                readOnly={readOnly}
                InputProps={InputProps}
                error={
                  !!errorMessage && onBlur && (value === "" || value === null)
                }
                helperText={value === "" || value === "N/A" ? errorMessage : ""}
              />
            );
          }
        }}
      />
    );
  };

  const generateHeader = (label) => (
    <Grid item xs={12} sm={12} key={`header-${label}`}>
      <h3>{label}</h3>
    </Grid>
  );
  return (
    <>
      {(loading || loadingFindAsYouType) && loadingPanel()}
      <br></br>
      {responseErr?.errMsg && (
        <Grid container justifyContent={"center"}>
          <H5 attrH5={{ style: { color: "red" } }}>
            This EmployeeID does not exists in our record !
          </H5>
        </Grid>
      )}

      {isRole == true ? (
        ""
      ) : (
        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Btn
            attrBtn={{
              color: "primary",
              onClick: handleSubmit(onSubmit),
              style: { marginRight: "10px" },
            }}
          >
            <FontAwesomeIcon icon={faSave} /> &nbsp;Save
          </Btn>
        </Grid>
      )}
      {ethinic &&
        retirement &&
        appropriation &&
        emplType &&
        education &&
        citizen &&
        military &&
        findAsyouTypeResults &&
        // loadingFindAsYouType &&
        departmentDivision && (
          <div>
            <Card className="mt-4">
              {/* {isUserRoleXYZ?false:true && ( */}
              <CardBody>
                <Grid container spacing={1}>
                  {/* {generateHeader("Employee Status")} */}

                  <Grid item xs={12} sm={12}>
                    <H5>Employee #{findgg1?.id}</H5>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={true}
                        readOnly={true}
                        name={"ssn"}
                        label={"SSN"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={true}
                        readOnly={true}
                        name={"id"}
                        label={"Employee ID"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"email"}
                        label={"Email"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"jacketNo"}
                        label={" Jacket"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2.5}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"firstName"}
                        label={"First Name"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"middleName"}
                        label={"Middle Name"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"lastName"}
                        label={"Last Name"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2.5}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"employeeNameLfm"}
                        label={"Full Name"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"ndateOfBirth"}
                        label={"DOB"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        name={"rate"}
                        label={"Hourly Rate($)"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"annualSalary"}
                        label={"Annual Salary"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"rangePay"}
                        label={"Pay Grade"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        // name={"payStep"} //
                        name={"stepActual"}
                        label={"Pay Step"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"dateEmployment"}
                        label={"Employment Begin Date"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"dateTerm"}
                        label={"Termination Date"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2} style={{ marginTop: "6px" }}>
                      <Btn
                        attrBtn={{
                          color: "primary",
                          onClick: () => {
                            if (Object.keys(touchedFields).length > 0) {
                              calculateSVCFun();
                            }
                          },
                          style: buttonStyle,
                        }}
                      >
                        Calculate SVC
                      </Btn>{" "}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{ fontSize: "0.8rem", marginTop: "-22px" }}
                        >
                          Total SVC
                        </InputLabel>
                        <div style={{ display: "flex" }}>
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            name="yy"
                            placeholder="YY"
                            value={YY ? YY : customData?.yy}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="text-field-first"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  y
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            name="mm"
                            placeholder="MM"
                            value={MM ? MM : customData?.mm}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="border-field"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            placeholder="DD"
                            name="dd"
                            value={DD ? DD : customData?.dd}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="text-field-last"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  d
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </FormControl>

                      {/* </FormInputText> */}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{ fontSize: "0.8rem", marginTop: "-22px" }}
                        >
                          Current SVC
                        </InputLabel>
                        <div style={{ display: "flex" }}>
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            name="yy"
                            placeholder="YY"
                            value={yy}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="text-field-first"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  y
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            name="mm"
                            placeholder="MM"
                            value={mm}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="border-field"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            disabled={true}
                            readOnly={true}
                            placeholder="DD"
                            name="dd"
                            value={dd}
                            onChange={(e) => handleCustomChange(e)}
                            size="small"
                            margin="dense"
                            className="text-field-last"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  d
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{ fontSize: "0.8rem", marginTop: "-24px" }}
                        >
                          Prior Service
                        </InputLabel>
                        <div style={{ display: "flex" }}>
                          <FormInputText
                            type="number"
                            placeholder="YY"
                            name="psYy"
                            disabled={isRole}
                            readOnly={isRole}
                            className="text-field-first"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  y
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormInputText
                            type="number"
                            disabled={isRole}
                            readOnly={isRole}
                            placeholder="MM"
                            name="psMm"
                            className="border-field"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  m
                                </InputAdornment>
                              ),
                            }}
                          />
                          <FormInputText
                            type="number"
                            disabled={isRole}
                            readOnly={isRole}
                            placeholder="DD"
                            name="psDd"
                            className="text-field-last"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  d
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"reemployDate"}
                        label={"Reemployment Date"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"lastActnDate"}
                        label={"Last Actn Date"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"actionDetailBeginDate"}
                        label={"Action Detail Begin Date"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"actionDetailEndDate"}
                        label={"Action Detail End Date"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"probationaryEndDate"}
                        label={"Probationary End Date "}
                      />
                    </Grid>
                    {emplType == "L" && (
                      <Grid item xs={12} sm={3}>
                        <FormInputText
                          type="date"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"dateTerm"}
                          label={"Limited Term Expiration"}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"ndateIncrem"}
                        label={"NXT INCREMENT Date"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"policeClrDate"}
                        label={"Police Clerance Date"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="date"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"courtClrDate"}
                        label={"Court Clerance Date"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel
                        sx={{ fontSize: "0.9rem", marginBottom: "-10px" }}
                      >
                        Exempt Position{" "}
                      </InputLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            color="primary"
                            checked={exemptVal === true}
                            onChange={(e) => {
                              setExemptVal(true);
                              setValue("exempt_Position", true, {
                                shouldTouch: true,
                                shouldDirty: true,
                              });
                            }}
                            id="exempt_Position_yes"
                            name="exempt_Position"
                          />
                        }
                        label="Yes"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            color="primary"
                            id="exempt_Position_no"
                            checked={exemptVal === false}
                            onChange={(e) => {
                              setExemptVal(false);
                              setValue("exempt_Position", false, {
                                shouldTouch: true,
                                shouldDirty: true,
                              });
                            }}
                            name="exempt_Position"
                          />
                        }
                        label="No"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormInputText
                        type="text"
                        disabled={isRole}
                        readOnly={isRole}
                        name={"workSch"}
                        label={"Work Schedule"}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormInputText
                      type="text"
                      disabled={true}
                      readOnly={true}
                      name={"npositionNo"}
                      label={"Position No"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: "-10px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.7rem", marginBottom: "2px" }}
                    >
                      Position Title
                    </InputLabel>
                    <AutoComplete
                      data={findAsyouTypeResults}
                      textField="keyField"
                      valueField="valueField"
                      name="npositionTitle"
                      disabled={isRole}
                      readOnly={isRole}
                      value={positionTitle}
                      size={"large"}
                      fillMode={"outline"}
                      className="bg-white"
                      style={{
                        width: "100%",
                        maxWidth: "510px",
                        margin: "0 auto",
                        borderColor: "rgba(0, 0, 0, 0.38)",
                      }}
                      onChange={(e) => {
                        if (e.value !== "") {
                          dispatch(
                            findAsyouType({
                              table: "vw_positions",
                              keyField: "TitleWithGrade",
                              valueField: "NPositionNo",
                              search: e.value,
                              order: "asc",
                            })
                          );
                          const filterData = findAsyouTypeResults?.filter(
                            (d) => d?.keyField === e.value
                          );
                          const value =
                            filterData[0] === null ||
                            filterData[0] === undefined
                              ? "N/A"
                              : filterData[0]?.valueField;
                          console.log("value", e.value);
                          setValue("npositionNo", value, {
                            shouldTouch: true,
                            shouldDirty: true,
                          });
                          setPositionTitle(e?.value);
                        } else {
                          setValue("npositionNo", null, {
                            shouldTouch: true,
                            shouldDirty: true,
                          });
                          setPositionTitle(null);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "10px" }}
                    >
                      Status
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-27px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"inactiveStatus"}
                          // label={"Active"}
                          options={
                            status && status?.length > 0
                              ? status?.map((item) => ({
                                  value: item?.INACTIVE_STATUS,
                                  label: item?.INACTIVE_STATUS,
                                }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"inactiveStatus"}
                          // label={"Active"}
                          options={
                            status && status?.length > 0
                              ? status
                                  .sort((a, b) =>
                                    a.DESCRIPTION.localeCompare(b.DESCRIPTION)
                                  )
                                  ?.map((item) => ({
                                    value: item?.INACTIVE_STATUS,
                                    label: item?.DESCRIPTION,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "10px" }}
                    >
                      Active/InActive
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-27px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"empStatus"}
                          // label={"Active"}
                          options={[
                            {
                              value: "A",
                              label: "A",
                            },
                            {
                              value: "I",
                              label: "I",
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"empStatus"}
                          // label={"Active"}
                          options={[
                            {
                              value: "A",
                              label: "Active",
                            },
                            {
                              value: "I",
                              label: "InActive",
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Employee Type
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"emplType"}
                          // label={"Employee Type"}
                          options={
                            emplType && emplType?.length > 0
                              ? emplType.map((item) => ({
                                  value: item?.EMPL_TYPE,
                                  label: item?.EMPL_TYPE,
                                }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"emplType"}
                          // label={"Employee Type"}
                          options={
                            emplType && emplType?.length > 0
                              ? emplType
                                  .sort((a, b) =>
                                    a.DESCRIPTION.localeCompare(b.DESCRIPTION)
                                  )
                                  .map((item) => ({
                                    value: item?.EMPL_TYPE,
                                    label: item?.DESCRIPTION,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>{" "}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "1px" }}
                    >
                      Citizenship
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-20px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"citizen"}
                          // label={"Citizenship"}
                          options={
                            citizen && citizen?.length > 0
                              ? citizen
                                  ?.filter(
                                    (d) =>
                                      d.Description !== null && d?.Id !== null
                                  )
                                  ?.map((item) => ({
                                    value: item.Id,
                                    label: item.Id,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"citizen"}
                          // label={"Citizenship"}
                          options={
                            citizen && citizen?.length > 0
                              ? citizen
                                  .sort((a, b) =>
                                    a.Description.localeCompare(b.Description)
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.Description !== null && d?.Id !== null
                                  )
                                  ?.map((item) => ({
                                    value: item.Id,
                                    label: item.Description,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} sm={6} style={{ marginTop: "-8px" }}>
                    {/* <Box component="fieldset"> */}
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Gender
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"sex"}
                          // textField={"value"}
                          // label={"Gender"}
                          // customVal={valData?.gender}
                          options={[
                            { value: "M", label: "M" },
                            { value: "F", label: "F" },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          textField="label"
                          name={"sex"}
                          // label={"Gender"}
                          // customVal={valData?.gender}
                          options={[
                            { value: "M", label: "Male" },
                            { value: "F", label: "Female" },
                          ]}
                        />
                      </Grid>
                    </Grid>{" "}
                    {/* </Box> */}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Marital Status
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"maritalStatus"}
                          // label={"Marital Status"}
                          options={[
                            { value: "S", label: "S" },
                            { value: "M", label: "M" },
                            { value: "D", label: "D" },
                            { value: "W", label: "W" },
                          ].sort((a, b) => a.value.localeCompare(b.value))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"maritalStatus"}
                          // label={"Marital Status"}
                          options={[
                            { value: "S", label: "Single" },
                            {
                              value: "M",
                              label: "Married(including Common Law)",
                            },
                            { value: "D", label: "Divorced" },
                            { value: "W", label: "Widowed" },
                          ].sort((a, b) => a.value.localeCompare(b.value))}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "8px" }}
                    >
                      Department
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"deptNo"}
                          // label={"Department"}
                          handleChange={(e) => {
                            setValue("deptNo", e.target.value, {
                              shouldTouch: true,
                              shouldDirty: true,
                            });
                            dispatch(
                              advanceSearchDepartment({
                                ...payload,
                                tableName: "LK_DPDV",
                                columns: "distinct Dept_key, name",
                                additionalWhere: `dept_code='${e.target.value}'`,
                              })
                            );
                          }}
                          options={
                            results && results.length > 0
                              ? results
                                  ?.filter(
                                    (d) =>
                                      d.DepartmentName !== null &&
                                      d?.Dept_Code !== null
                                  )
                                  ?.map((item) => ({
                                    value: item.Dept_Code,
                                    label: item.Dept_Code,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"deptNo"}
                          // label={"Department"}
                          handleChange={(e) => {
                            setValue("deptNo", e.target.value, {
                              shouldTouch: true,
                              shouldDirty: true,
                            });
                            dispatch(
                              advanceSearchDepartment({
                                ...payload,
                                tableName: "LK_DPDV",
                                columns: "distinct Dept_key, name",
                                additionalWhere: `dept_code='${e.target.value}'`,
                              })
                            );
                          }}
                          options={
                            results && results.length > 0
                              ? results
                                  .sort((a, b) =>
                                    a.DepartmentName.localeCompare(
                                      b.DepartmentName
                                    )
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.DepartmentName !== null &&
                                      d?.Dept_Code !== null
                                  )
                                  ?.map((item) => ({
                                    value: item.Dept_Code,
                                    label: item.DepartmentName,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "7px" }}
                    >
                      Department Division
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"dpdv"}
                          // label={"Department Division"}
                          options={
                            departmentDivision && departmentDivision?.length > 0
                              ? departmentDivision
                                  ?.filter(
                                    (d) =>
                                      d.name !== null && d?.Dept_key !== null
                                  )
                                  .map((item) => ({
                                    value: item.Dept_key,
                                    label: item.Dept_key,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"dpdv"}
                          // label={"Department Division"}
                          options={
                            departmentDivision && departmentDivision?.length > 0
                              ? departmentDivision
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  ?.filter(
                                    (d) =>
                                      d.name !== null && d?.Dept_key !== null
                                  )
                                  .map((item) => ({
                                    value: item.Dept_key,
                                    label: item.name,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={1}>
           
          </Grid> */}

                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "11px" }}
                    >
                      Military Status
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"militaryStatus"}
                          // label={"Military Status"}
                          options={
                            military && military?.length > 0
                              ? military?.map((item) => ({
                                  value: item.Military_Code,
                                  label: item.Military_Code,
                                }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"militaryStatus"}
                          // label={"Military Status"}
                          options={
                            military && military?.length > 0
                              ? military
                                  ?.sort((a, b) =>
                                    a.Military_Title.localeCompare(
                                      b.Military_Title
                                    )
                                  )
                                  ?.map((item) => ({
                                    value: item.Military_Code,
                                    label: item.Military_Title,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Ethnic
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-29px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"ethnicGroup"}
                          // label={"Ethnic"}
                          options={
                            ethinic && ethinic.length > 0
                              ? ethinic
                                  ?.filter(
                                    (d) =>
                                      d.ETHNIC_TITLE !== null &&
                                      d?.ETHNIC_CODE !== null
                                  )
                                  .map((vendor) => ({
                                    value: vendor.ETHNIC_CODE,
                                    label: vendor.ETHNIC_CODE,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"ethnicGroup"}
                          // label={"Ethnic"}
                          options={
                            ethinic && ethinic.length > 0
                              ? ethinic
                                  ?.sort((a, b) =>
                                    a.ETHNIC_TITLE.localeCompare(b.ETHNIC_TITLE)
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.ETHNIC_TITLE !== null &&
                                      d?.ETHNIC_CODE !== null
                                  )
                                  .map((vendor) => ({
                                    value: vendor.ETHNIC_CODE,
                                    label: vendor.ETHNIC_TITLE,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Education
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"education"}
                          // label={"Education"}
                          options={
                            education && education.length > 0
                              ? education
                                  ?.filter(
                                    (d) =>
                                      d.Description !== null &&
                                      d?.Education !== null
                                  )
                                  .map((item) => ({
                                    value: item?.Education,
                                    label: item?.Education,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"education"}
                          // label={"Education"}
                          options={
                            education && education.length > 0
                              ? education
                                  ?.sort((a, b) =>
                                    a.Description.localeCompare(b.Description)
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.Description !== null &&
                                      d?.Education !== null
                                  )
                                  .map((item) => ({
                                    value: item?.Education,
                                    label: item?.Description,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Retirement
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"retireSsCode"}
                          // label={"Education"}
                          options={
                            retirement && retirement.length > 0
                              ? retirement
                                  ?.filter(
                                    (d) =>
                                      d.DESCRIPTION !== null &&
                                      d?.RETIRE_SS_CODE !== null
                                  )
                                  .map((item) => ({
                                    value: item?.RETIRE_SS_CODE,
                                    label: item?.RETIRE_SS_CODE,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"retireSsCode"}
                          // label={"Education"}
                          options={
                            retirement && retirement.length > 0
                              ? retirement
                                  ?.sort((a, b) =>
                                    a.DESCRIPTION.localeCompare(b.DESCRIPTION)
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.DESCRIPTION !== null &&
                                      d?.RETIRE_SS_CODE !== null
                                  )
                                  .map((item) => ({
                                    value: item?.RETIRE_SS_CODE,
                                    label: item?.DESCRIPTION,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ marginTop: "-8px" }}>
                    <InputLabel
                      sx={{ fontSize: "0.8rem", marginBottom: "12px" }}
                    >
                      Appropriation
                    </InputLabel>
                    <Grid container spacing={1} style={{ marginTop: "-28px" }}>
                      <Grid item xs={6} sm={3}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"appropType"}
                          // label={"Education"}
                          options={
                            appropriation && appropriation.length > 0
                              ? appropriation
                                  ?.filter(
                                    (d) =>
                                      d.APPROP_DESCR !== null &&
                                      d?.APPROP_TYPE !== null
                                  )
                                  .map((item) => ({
                                    value: item?.APPROP_TYPE,
                                    label: item?.APPROP_TYPE,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <FormInputText
                          type="dropdown"
                          disabled={isRole}
                          readOnly={isRole}
                          name={"appropType"}
                          // label={"Education"}
                          options={
                            appropriation && appropriation.length > 0
                              ? appropriation
                                  .sort((a, b) =>
                                    a.APPROP_DESCR.localeCompare(b.APPROP_DESCR)
                                  )
                                  ?.filter(
                                    (d) =>
                                      d.APPROP_DESCR !== null &&
                                      d?.APPROP_TYPE !== null
                                  )
                                  .map((item) => ({
                                    value: item?.APPROP_TYPE,
                                    label: item?.APPROP_DESCR,
                                  }))
                              : []
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"handicapped"}
                      label={"Handicapped"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"handiCond"}
                      label={"Handicap Condition"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="dropdown"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"veteran"}
                      label={"Veteran"}
                      options={[
                        { value: "Y", label: "Yes" },
                        { value: "N", label: "No" },
                      ]}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"veteran"}
                      label={"Veteran"}
                    />
                  </Grid> */}

                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="dropdown"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"i9"}
                      label={"I9"}
                      options={[
                        { value: "Y", label: "Yes" },
                        { value: "N", label: "No" },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"budgetAcctNo"}
                      label={"Budget Acct No"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"jobOrderNo"}
                      label={"Job Order Number"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"fteno"}
                      label={"Fte No."}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"driversLicenseNo"}
                      label={"Driver License No."}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"requestId"}
                      label={"Request Id"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"emplContractNo"}
                      label={"Employee Contract No:"}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"physicalExam"}
                      label={"Physical Exam"}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={3}>
                    <FormInputText
                      type="dropdown"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"physicalExam"}
                      label={"Physical Exam"}
                      options={[
                        { value: "Y", label: "Yes" },
                        { value: "N", label: "No" },
                      ]}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"hmPhone"}
                      label={"PH:Home"}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"hmAddLn1"}
                      label={"Home"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"hmAddCity"}
                      label={"City"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"hmAddState"}
                      label={"State"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"hmAddZip"}
                      label={"Zip"}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"mlWkPhone"}
                      label={"PH:Work"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"mlAddLn1"}
                      label={"Mail"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"mlAddCity"}
                      label={"City"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2.5}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"mlAddState"}
                      label={"State"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormInputText
                      type="text"
                      disabled={isRole}
                      readOnly={isRole}
                      name={"mlAddZip"}
                      label={"Zip"}
                    />
                  </Grid>
                </Grid>

                {/* </Grid> */}
              </CardBody>
              {/* )} */}
            </Card>
          </div>
        )}
    </>
  );
};
export default GG1EmployeeInformation;
