import {
  GET_DETAILS_BY_ADMIN,
  GET_DETAILS_BY_ADMIN_SUCCESS,
  GET_DETAILS_BY_ADMIN_FAIL,
  PROCESS_CREATE,
  PROCESS_CREATE_FAIL,
  PROCESS_CREATE_SUCCESS,
  GET_TABLENAMES,
  GET_TABLENAMES_SUCCESS,
  GET_TABLENAMES_FAIL,
  UPDATE_TABLENAMES,
  UPDATE_TABLENAMES_SUCCESS,
  UPDATE_TABLENAMES_FAIL,
} from "./actionTypes";

export const getDetailsByAdmin = (payload) => {
  return {
    type: GET_DETAILS_BY_ADMIN,
    payload: payload,
  };
};

export const getDetailsByAdminSuccess = (tableName) => {
  return {
    type: GET_DETAILS_BY_ADMIN_SUCCESS,
    payload: tableName,
  };
};

export const getDetailsByAdminFail = (error) => {
  return {
    type: GET_DETAILS_BY_ADMIN_FAIL,
    payload: error,
  };
};
export const getTableNames = () => {
  return {
    type: GET_TABLENAMES,
  };
};

export const getTableNamesSuccess = (posts) => {
  return {
    type: GET_TABLENAMES_SUCCESS,
    payload: posts,
  };
};

export const getTableNamesFail = (error) => {
  return {
    type: GET_TABLENAMES_FAIL,
    payload: error,
  };
};
export const updateTableNames = (payload) => {
  return {
    type: UPDATE_TABLENAMES,
    payload: payload,
  };
};

export const updateTableNamesSuccess = (posts) => {
  return {
    type: UPDATE_TABLENAMES_SUCCESS,
    payload: posts,
  };
};

export const updateTableNamesFail = (error) => {
  return {
    type: UPDATE_TABLENAMES_FAIL,
    payload: error,
  };
};
export const createDetailsByAdmin = (payload) => {
  return {
    type: PROCESS_CREATE,
    payload: payload,
  };
};
export const createDetailsByAdminSuccess = (response) => {
  return {
    type: PROCESS_CREATE_SUCCESS,
    payload: response,
  };
};
export const createDetailsByAdminFail = (error) => {
  return {
    type: PROCESS_CREATE_FAIL,
    payload: error,
  };
};
