import React, { useEffect, useState, memo } from "react";
import "./history.css";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useParams } from "react-router-dom";
import { Tooltip, Switch, IconButton } from "@mui/material";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { SetGridDataState } from "../../store/Common/actions";
import { perfomDeductionSubscription, updateDeduction } from "../../store/employees/actions";
import ColumnFilter from "../TelerikDataTable/columnFilter";
import FullScreenWorkflowDialog from "../../Components/Workflow/WorkflowReadOnly";
import { CustomMenu } from "../TelerikDataTable/customMenu";
import { loadingPanel } from "../CommonFunction";
import { Spinner } from "reactstrap";
import { getAuditLogExports } from "../../store/AuditLogs/action";
import { Icon } from "@progress/kendo-react-common";

const initialDataState = {
  sort: [
    {
      field: "queryName",
      dir: "asc",
    },
  ],
};

const toSQLExpression = (filter) => {
  if (!filter) return;

  let filters = filter.filters,
    field,
    value,
    operator,
    mapping,
    type,
    logic = filter.logic || "AND",
    result = [];

  for (let i = 0; i < filters.length; i++) {
    filter = filters[i];
    field = filter.field;
    value = filter.value;
    operator = filter.operator;

    if (filter.filters) {
      filter = toSQLExpression(filter);
    } else {
      mapping = mappings[operator];
      type = typeof value; ///$.type

      // if (value instanceof Date) {
      //   value =moment(value).format(SQL_DATE_FORMAT) // toString(value.toISOString().slice(0, 19).replace('T', ' '));
      // }
      if (value instanceof Date) {
        filter = formatDateFilter(
          mapping,
          field,
          moment(value).format(SQL_DATE_FORMAT)
        );
      } else {
        filter = formatFilter(mapping, field, value);
      }
    }

    result.push(filter);
  }

  filter = result.join(" " + logic.toUpperCase() + " ");

  if (result.length > 1) {
    filter = "(" + filter + ")";
  }

  return filter;
};
const SQL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const SQL_DATE_FORMAT = "DD/MM/YYYY";
const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
const mappings = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
};
const formatFilter = (mapping, field, value) => {
  return mapping.replace("{0}", field).replace("{1}", value);
};
const formatDateFilter = (mapping, field, value) => {
  return mapping
    .replace("{0}", `CONVERT(DATE,${field})`)
    .replace("'{1}'", `CONVERT(DATE,'${value}')`);
};

const HistoryDataTable = (props) => {
  const dispatch = useDispatch();
  let gridPDFExport;
  const _export = React.useRef(null);

  const [dataState, setDataState] = React.useState({ initialDataState });
  let {
    id,
    setSortedColumns,
    selectedColumns,
    onSortChange,
    sortData,
    actions,
    setOpenHistory,
    openHistory,
    isMasterDetails,
    userData,
    isLoading
  } = props;
  const { employeeID } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [loadingExcelReport, setLoadingExcelReport] = useState(false);
  const [fileExtension, setFileExtension] = useState("");
  const [dataResult, setDataResult] = React.useState(
    process(props.data, dataState)
  );
  const { exportAuditLogs } = useSelector((state) => state.AuditLog);

  useEffect(() => {
    setDataState(dataState);
    setDataResult(process(props.data, dataState));
  }, [props?.data]);

  const fnSubscribe = (subscribe, DeductionCode) => {
    if (subscribe === "add" && userData !== null) {
      dispatch(
        perfomDeductionSubscription({
          // subscribe,
          employeeID: employeeID || userData?.profile?.EmployeeID,
          deductionCode: DeductionCode,
          deductionDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss.SSSZ"),
          isActive: true,
        })
      );
    } else {
      dispatch(
        updateDeduction({
          deductionCode: DeductionCode,
          employeeID: employeeID || userData?.profile?.EmployeeID,
          isActive: subscribe,
        })
      );
    }
  };

  const [workflowInfo, setWorkflowInfo] = useState({
    workflowID: 0,
    CurrentStatus: "",
  });

  useEffect(() => {
    let data = {};
    data[id] = dataState;
    dispatch(SetGridDataState(data));
  }, [dataState]);

  useEffect(() => {
    if (typeof exportAuditLogs === "object" && loadingExcelReport) {
      downloadExcel();
    }
  }, [exportAuditLogs]);

  async function downloadExcel() {
    const filename = `report_${new Date().getTime()}`;
    const blob = await exportAuditLogs.data;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    setLoadingExcelReport(false);
    setFileExtension("");
  }

  const [employeeInfo, setEmployeeInfo] = useState({ ID: null, NAME: "", });

  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };

  const toggleDialog = () => {
    setOpenHistory(!openHistory);
  };

  const handleExcelExport = (fileExt) => {
    setFileExtension(fileExt);
    setLoadingExcelReport(true);
    const exportParams = {
      fileExtension: fileExt,
      tableName: props.tableName,
      searchParams: "[]",
      additionalWhere: `${props.columnName} = ${props.value}`,
      columns: "*",
      pageNumber: 1,
      pageSize: 100,
    };

    dispatch(getAuditLogExports(exportParams));
  };

  const updateLockColumn = async (col, status) => {
    let copyArray = [...selectedColumns];
    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field === col;
    });

    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    if (copyObject) {
      copyObject["locked"] = status;
      copyArray[objectAtIndex] = copyObject;
      setSortedColumns(copyArray);
    }
  };

  let GridComponent = (
    <>
      <Grid
        style={{
          height: "460px",
          position: isMasterDetails ? "relative" : "absolute",
          zIndex: "4",
          marginTop: "10px",
        }}
        data={dataResult}
        filterable={true}
        {...dataState}
        onDataStateChange={(e) => {
          setDataState(e.dataState);
          setDataResult(process(props.data, e.dataState));
        }}
        sortable={true}
        resizable={true}
      >
        <GridToolbar>
          <div style={{ float: "left", width: "50%" }}>
            <Tooltip title="Export Excel" placement="bottom">
              <IconButton
                onClick={() => {
                  !loadingExcelReport && handleExcelExport("xls");
                }}
              >
                {loadingExcelReport && fileExtension == "xls"
                  ? <Spinner size={24} />
                  : <Icon style={{ fontSize: '25px' }} name="file-excel" />
                }
              </IconButton>
            </Tooltip>
            <Tooltip title="Export PDF" placement="bottom">
              <IconButton
                onClick={() => {
                  !loadingExcelReport && handleExcelExport("pdf");
                }}
              >
                {loadingExcelReport && fileExtension == "pdf"
                  ? <Spinner size={24} />
                  : <Icon style={{ fontSize: '25px' }} name="file-pdf" />
                }
              </IconButton>
            </Tooltip>
            <ColumnFilter
              columns={selectedColumns}
              setSortedColumns={setSortedColumns}
            />
          </div>
        </GridToolbar>
        {actions?.length > 0 ? (
          <GridColumn
            field="ID"
            title="Actions"
            width={
              actions === "toggleSwitch"
                ? "100px"
                : actions?.length <= 2
                  ? `${actions?.length * 30 + 70}px`
                  : `${actions?.length * 30}px`
            }
            filterable={false}
            cell={(props) => (
              <td {...props}>
                <div style={{ display: "flex", columnGap: "6px" }}>
                  {actions === "toggleSwitch" ? (
                    <Switch
                      defaultChecked={
                        props?.dataItem?.subscribed ? true : false
                      }
                      onChange={(event) => {
                        const value = event.target.checked ? "Y" : "N";
                        if (!props?.dataItem?.subscribedOn) {
                          fnSubscribe("add", props?.dataItem?.deductionCode);
                        } else {
                          if (props?.dataItem?.subscribed == 1) {
                            fnSubscribe(false, props?.dataItem?.deductionCode);
                          } else {
                            fnSubscribe(true, props?.dataItem?.deductionCode);
                          }
                        }
                      }}
                      color="primary"
                      className="align-top"
                      title="Subscribe"
                    />
                  ) : null}
                  {Array.isArray(actions) &&
                    actions.length &&
                    actions
                      .filter(
                        (d) =>
                          d.onlyVisible === undefined ||
                          (d.onlyVisible && props.dataItem[d.onlyVisible] > 0)
                      )
                      .map((item) => (
                        <Tooltip
                          style={{ cursor: "pointer" }}
                          title={item.title}
                          placement="bottom"
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (!item.action) {
                                switch (item.actionType) {
                                  case "workflow":
                                    setWorkflowInfo({
                                      workflowID: props.dataItem.workflowID,
                                      CurrentStatus:
                                        props.dataItem.currentWorkflowStatus,
                                    });
                                    break;
                                  case "qrcode": {
                                    handleModal(
                                      props.dataItem.id,
                                      props.dataItem.employeeName_FML
                                    );
                                    break;
                                  }
                                  default:
                                    break;
                                }
                              } else {
                                item.action(props);
                              }
                            }}
                            className={`${item.className} ${item.icon?.iconName} font-primary`}
                          />
                        </Tooltip>
                      ))}
                </div>
              </td>
            )}
          />
        ) : (
          " "
        )}

        {props.selectedColumns && props.selectedColumns.length
          ? props.selectedColumns
            .filter((i) => i.show)
            .map((i) => {
              return (
                <GridColumn
                  field={i?.field}
                  filter={i?.filter}
                  locked={i.locked}
                  title={i.title}
                  width={i.minWidth || i.width}
                  format={i.format}
                  editor={i.editor}
                  cell={(props) => {
                    const field = props.field || "";
                    const editor = props.editor || "";
                    const format = props.format || "";
                    const value = props.dataItem[field];
                    const updatedColumns = props?.dataItem?.updatedColumns;
                    let highlightField = [];
                    if (updatedColumns) {
                      const arrUpdatedColumns =
                        updatedColumns && updatedColumns.split(",");

                      arrUpdatedColumns.forEach((item) => {
                        if (
                          item.toLowerCase().includes(field.toLowerCase())
                        ) {
                          highlightField.push(field.toLowerCase());
                        }
                      });
                    }

                    return (
                      <td
                        style={{
                          ...props.style,
                          whiteSpace: "nowrap",
                          backgroundColor: highlightField.includes(
                            field.toLocaleLowerCase()
                          )
                            ? "#59bd59" //"#4cd964"
                            : "",
                        }}
                        className={props.className}
                      >
                        {editor === "date" && value !== null
                          ? moment(value).format(format)
                          : value
                            ? value
                            : "N/A"}
                      </td>
                    );
                  }}
                  columnMenu={(props) => (
                    <CustomMenu
                      locked={i.locked}
                      loadQuery={true}
                      {...props}
                      onColumnLockToggle={(col, status) => {
                        updateLockColumn(col, status);
                      }}
                    />
                  )}
                />
              );
            })
          : null}
      </Grid>
    </>
  );

  return (
    <>
      {openHistory && isMasterDetails ? (
        <ExcelExport data={props.data} ref={_export}>
          {GridComponent}
        </ExcelExport>
      ) : (
        openHistory && (
          <div className="customDialog">
            <Dialog
              height={"550px"}
              minWidth={"50%"}
              title={<h4>{props?.title}</h4>}
              onClose={toggleDialog}
              className={props.data?.length ? "customDialogue" : "noDataClass"}
            >
              {isLoading
                ? loadingPanel()
                : props.data?.length ? (
                  <ExcelExport data={props.data} ref={_export}>
                    {openHistory && GridComponent}
                  </ExcelExport>
                ) : (
                  <div
                    style={{ display: "flex", alignItems: 'center', justifyContent: 'center', fontSize: "20px", height: "100%" }}
                    className="dark-mode"
                  >
                    Data not available!
                  </div>
                )}
            </Dialog>
          </div>
        )
      )}
    </>
  );
};

export default memo(HistoryDataTable);
