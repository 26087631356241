import {
  GET_ALL_EMPLOYEE_SIGNATURES,
  GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS,
  GET_ALL_EMPLOYEE_SIGNATURES_FAIL,
  ADD_UPDATE_EMPLOYEE_SIGNATURES,
  ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS,
  ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL,
  CLEAR_ADD_UPDATE_SIGNATURE,
  DELETE_SIGNATURE,
  DELETE_SIGNATURE_SUCCESS,
  DELETE_SIGNATURE_FAIL,
  GET_SIGNATURE_DATA,
  GET_SIGNATURE_DATA_SUCCESS,
  GET_SIGNATURE_DATA_FAIL,
  UNASSIGN_SIGNATURE,
  UNASSIGN_SIGNATURE_SUCCESS,
  UNASSIGN_SIGNATURE_FAIL,
  GET_SIGNATURE_BY_CONTROL_NUMBER,
  GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS,
  GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL,
  GENERATE_SIGNATURE_DOCUMENT,
  GENERATE_SIGNATURE_DOCUMENT_SUCCESS,
  GENERATE_SIGNATURE_DOCUMENT_FAIL,
  RESET_DOCUMENT_DATA,
  UPDATE_SIGNATURE_STATUS,
  UPDATE_SIGNATURE_STATUS_SUCCESS,
  UPDATE_SIGNATURE_STATUS_FAIL,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS,
  EMPLOYEESERVICE_REQUEST,
  EMPLOYEESERVICE_REQUEST_SUCCESS,
  EMPLOYEESERVICE_REQUEST_FAIL,
} from "./actionTypes";

const initialState = {
  isEmployeeSignatureLoading: false,
  employeeserviceLoading: false,
  signatures: [],
  signatureData: {},
  isSignatureFetching: false,
  addUpdateSignatureResponse: {},
  unAssignSignatureResponse: {},
  getSignatureByControlNumberResponse: {},
  updateSignatureStatusResponse: {},
  deleteResponse: {},
  documentResponse: {},
  employeeservice:{},
  isDeleteLoading: false,
  error: {
    message: "",
  }
};

const EmployeeSignatureReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_ALL_EMPLOYEE_SIGNATURES:
      state = { ...state, signatureData: {}, isEmployeeSignatureLoading: true };
      break;
    case GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS:
      state = {
        ...state,
        signatures: action.payload.data,
        isEmployeeSignatureLoading: false,
      };
      break;
    case GET_ALL_EMPLOYEE_SIGNATURES_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        isEmployeeSignatureLoading: false,
      };
      break;
    case ADD_UPDATE_EMPLOYEE_SIGNATURES:
      state = { ...state, addUpdateSignatureResponse: {}, isEmployeeSignatureLoading: true };
      break;
    case ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS:
      state = {
        ...state,
        signatureData: action.payload?.data || {},
        addUpdateSignatureResponse: action.payload?.data,
        isEmployeeSignatureLoading: false,
      };
      break;
    case ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL:
      state = {
        ...state,
        addUpdateSignatureResponse: {},
        error: {
          message: "Error",
        },
        isEmployeeSignatureLoading: false,
      };
      break;
    case CLEAR_ADD_UPDATE_SIGNATURE:
      state = {
        ...state,
        addUpdateSignatureResponse: {},
        unAssignSignatureResponse: {},
        getSignatureByControlNumberResponse: {},
      };
      break;
    case DELETE_SIGNATURE:
      state = { ...state, isDeleteLoading: true };
      break;
    case DELETE_SIGNATURE_SUCCESS:
      state = {
        ...state,
        deleteResponse: action.payload,
        isDeleteLoading: false,
      };
      break;
    case DELETE_SIGNATURE_FAIL:
      state = {
        ...state,
        deleteResponse: {},
        error: {
          message: "Error",
        },
        isDeleteLoading: false,
      };
      break;
    case GET_SIGNATURE_DATA:
      state = { ...state, isSignatureFetching: true };
      break;
    case GET_SIGNATURE_DATA_SUCCESS:
      state = {
        ...state,
        signatureData: action.payload?.data,
        isSignatureFetching: false,
      };
      break;
    case GET_SIGNATURE_DATA_FAIL:
      state = {
        ...state,
        signatureData: {},
        error: {
          message: "Error",
        },
        isSignatureFetching: false,
      };
      break;
    case UNASSIGN_SIGNATURE:
      state = { ...state, isEmployeeSignatureLoading: true };
      break;
    case UNASSIGN_SIGNATURE_SUCCESS:
      state = {
        ...state,
        unAssignSignatureResponse: action.payload,
        signatureData: {},
        isEmployeeSignatureLoading: false,
      };
      break;
    case UNASSIGN_SIGNATURE_FAIL:
      state = {
        ...state,
        unAssignSignatureResponse: {},
        error: {
          message: "Error",
        },
        isEmployeeSignatureLoading: false,
      };
      break;
    case GET_SIGNATURE_BY_CONTROL_NUMBER:
      state = { ...state, isSignatureFetching: true };
      break;
    case GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS:
      state = {
        ...state,
        // signatureData: action.payload?.data,
        getSignatureByControlNumberResponse: action.payload?.data,
        // addUpdateSignatureResponse: action.payload?.data,
        isSignatureFetching: false,
      };
      break;
    case GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL:
      state = {
        ...state,
        getSignatureByControlNumberResponse: {},
        error: {
          message: "Error",
        },
        isSignatureFetching: false,
      };
      break;
    case GENERATE_SIGNATURE_DOCUMENT:
      state = { ...state, documentResponse: {}, isEmployeeSignatureLoading: true };
      break;
    case GENERATE_SIGNATURE_DOCUMENT_SUCCESS:
      state = {
        ...state,
        documentResponse: action.payload,
        isEmployeeSignatureLoading: false,
      };
      break;
    case GENERATE_SIGNATURE_DOCUMENT_FAIL:
      state = {
        ...state,
        isEmployeeSignatureLoading: false,
        documentResponse: {},
        error: {
          message: "Error",
        },
      };
      break;
      case EMPLOYEESERVICE_REQUEST:
        state = { ...state, employeeservice: {}, employeeserviceLoading: true };
        break;
      case EMPLOYEESERVICE_REQUEST_SUCCESS:
        state = {
          ...state,
          employeeservice: action.payload,
          employeeserviceLoading: false,
        };
        break;
      case EMPLOYEESERVICE_REQUEST_FAIL:
        state = {
          ...state,
          employeeserviceLoading: false,
          employeeservice: {},
          error: {
            message: "Error",
          },
        };
        break;
    case RESET_DOCUMENT_DATA:
      state = { ...state, documentResponse: {} };
      break;
    case UPDATE_SIGNATURE_STATUS:
    case APPROVE_REJECT_EMPLOYEE_SIGNATURE:
      state = { ...state, isEmployeeSignatureLoading: true };
      break;
    case UPDATE_SIGNATURE_STATUS_SUCCESS:
    case APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS:
      state = {
        ...state,
        updateSignatureStatusResponse: action.payload,
        isEmployeeSignatureLoading: false,
      };
      break;
    case UPDATE_SIGNATURE_STATUS_FAIL:
    case APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL:
      state = {
        ...state,
        updateSignatureStatusResponse: {},
        error: {
          message: "Error",
        },
        isEmployeeSignatureLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EmployeeSignatureReducer;
