import { Autocomplete, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CustomTextField from "../../Layout/Components/TextField";
import { fontWeight } from "@mui/system";
import moment from "moment";
import { TextArea } from "@progress/kendo-react-inputs";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTentArrowLeftRight } from "@fortawesome/pro-duotone-svg-icons";
import dayjs from "dayjs";
import { useSelector } from "react-redux";


const ComponentToPrint = React.forwardRef((props, ref) => {
  const { data } = props;
  const { processStatusList } = useSelector((state) => state.processReducer);
  // console.log("123123 userdata", data)
  const CheckValue = (temp) => {
    if (temp !== null && temp !== undefined && temp !== "") {
      return true;
    }
    return false;
  }

  const BBMRStatuses = [8,339,272,342,338]

  const [createdFullName, setCreatedFullName] = useState(data?.fullName || "");
  const makeFullName = () => {
    if(!CheckValue(data?.fullName?.trim())){
      if(CheckValue(data?.lastName?.trim()) || CheckValue(data?.firstName?.trim()) || CheckValue(data?.middleName?.trim())){
        let name = `${CheckValue(data?.lastName?.trim()) ? data?.lastName : ""}, ${CheckValue(data?.firstName?.trim()) ? data?.firstName : ""} ${CheckValue(data?.middleName?.trim()) ? data?.middleName : ""}`;
        setCreatedFullName(name);
      }
      else if(CheckValue(data?.toLastName?.trim()) || CheckValue(data?.toFirstName?.trim()) || CheckValue(data?.toMiddleName?.trim())){
        let name = `${CheckValue(data?.toLastName?.trim()) ? data?.toLastName : ""}, ${CheckValue(data?.toFirstName?.trim()) ? data?.toFirstName : ""} ${CheckValue(data?.toMiddleName?.trim()) ? data?.toMiddleName : ""}`;
        setCreatedFullName(name);
      }
      else if(CheckValue(data?.fromLastName?.trim()) || CheckValue(data?.fromFirstName?.trim()) || CheckValue(data?.fromMiddleName?.trim())){
        let name = `${CheckValue(data?.fromLastName?.trim()) ? data?.fromLastName : ""}, ${CheckValue(data?.fromFirstName?.trim()) ? data?.fromFirstName : ""} ${CheckValue(data?.fromMiddleName?.trim()) ? data?.fromMiddleName : ""}`;
        setCreatedFullName(name);
      }
    }else{
      setCreatedFullName(data?.fullName)
    }
  }

  useEffect(()=>{
    makeFullName();
  },[data])
 

  let UserName = `${CheckValue(data?.enterByLastName) ? data?.enterByLastName : ""}, ${CheckValue(data?.enterByFirstName) ? data?.enterByFirstName : ""} ${CheckValue(data?.enterByMiddleName) ? data?.enterByMiddleName : ""}`
  // let UserContactNo = CheckValue(data?.enterByCellPhone) ? data?.enterByCellPhone : "";
  // let FullName = CheckValue(data?.fullName?.trim()) ? data?.fullName : `${CheckValue(data?.lastName?.trim()) ? data?.lastName : ""}, ${CheckValue(data?.firstName?.trim()) ? data?.firstName : ""} ${CheckValue(data?.middleName?.trim()) ? data?.middleName : ""}`
  let FullName = CheckValue(data?.fullName?.trim()) ? data?.fullName : CheckValue(createdFullName?.trim()) ? createdFullName : `${CheckValue(data?.lastName?.trim()) ? data?.lastName : ""}, ${CheckValue(data?.firstName?.trim()) ? data?.firstName : ""} ${CheckValue(data?.middleName?.trim()) ? data?.middleName : ""}`

  return (
    <div ref={ref}>
      <style type="text/css" media="print">
        {" @page {size : portrait}"}
      </style>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#ccfff9",
          padding: "20px",
          fontSize: "12px",
          fontWeight: "bold",
          lineHeight: "1.3",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 !important",
            fontWeight: "bold",
            lineHeight: "1.3",
          }}
        >
          <Col
            sm={2}
            className="p-1"
            style={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
            }}
          >
            <Row>
              <Col sm={12}>
                FCN 2-0-7 <br />
                Revised 7/62 <br />
                (old GG-1)
              </Col>
            </Row>
          </Col>
          <Col sm={8} className="text-center" style={{ fontSize: "14px" }}>
            GOVERNMENT OF GUAM <br />
            AGANA, GUAM <br />
            REQUEST FOR PERSONNEL ACTION
          </Col>
          <Col sm={2}></Col>
        </Row>
        <Row
          style={{ borderTop: "1px solid black", marginBottom: "5px" }}
        ></Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={6} className="p-1 border-dark border-end">
            1. NAME (Mr.,Miss,Mrs.,First,Middle Initial,Last)
            <br />
            {/* <span className="p-4">{data?.fullName}</span> */}
            <span className="p-4">{FullName || data?.fullName}</span>
          </Col>

          <Col sm={2} className="p-1 border-dark border-end">
            2. Date of Birth <br />
            <span className="p-4">
              {data?.dateOfBirth && moment(data.dateOfBirth).format("DD MMM YYYY") || data?.DOB && moment(data.DOB).format("DD MMM YYYY")}
            </span>
          </Col>
          <Col sm={2} className="p-1 border-dark border-end">
            3.Employee ID
            <br />
            <span className="p-4">{data?.employeeId ?? data?.employeeID}</span>
            {/* <span className="p-4">{data && data.employeeId ? data.employeeId : employeeID}</span> */}
          </Col>

          <Col sm={2} className="p-1 border-dark">
            4.Date Requested <br />
            <span className="p-4">
              {" "}
              {data?.dateOfRequest && moment(data.dateOfRequest).format("DD MMM YYYY")}
            </span>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={3} className="p-1 border-dark border-end">
            5.Request Number <br />
            <span className="p-4">{data?.requestId ?? data?.DOR}</span>
          </Col>
          <Col sm={3} className="p-1 border-dark border-end">
            6.Effective Date <br />
            <span className="p-4">
              {" "}
              {data?.actionEffectiveDate && moment(data.actionEffectiveDate).format("DD MMM YYYY") || data?.ActionDate && moment(data.ActionDate).format("DD MMM YYYY")}
            </span>
          </Col>
          <Col sm={6} className="p-1">
            7.Nature of Action (use standard terminology) <br />
            <span className="p-4">{data?.natureTitle ?? data?.NatureOfActionTitle} </span>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={5} className="p-1 border-dark border-end text-center">
            FROM
          </Col>
          <Col sm={2} className="p-1 border-dark border-end text-center">
            Description
          </Col>
          <Col sm={5} className="p-1 text-center">
            TO
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={5} className="p-1 border-dark border-end text-center p-3">
            <Row>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  // style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="FR_Title"
                  label="Position"
                  value={data?.fromPositionTitle}
                  shrink={true}
                />
              </Col>
              <Col sm={4} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromPayGrade"
                  label="Pay Grade"
                  value={data?.fromPayGrade}
                  shrink={true}
                />
              </Col>
              <Col sm={8} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromSalary"
                  label="Salary Per Annum"
                  value={data?.fromSalary}
                  shrink={true}
                />
              </Col>
              <Col sm={4} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromStep"
                  label="Step"
                  value={data?.fromStep}
                  shrink={true}
                />
              </Col>
              <Col sm={8} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="fromRate"
                  label="Rate per Hour"
                  value={data?.fromRate}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="department"
                  label="Department"
                  value={data?.fromDepartment}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="section_divison"
                  label="Section/Division"
                  value={data?.fromDivisionName}
                  shrink={true}
                />
              </Col>
              <Col sm={12} className="bg-white">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  style={{ backgroundColor: "#cdd3d6" }}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="budget"
                  label="FTE"
                  value={data?.fromFteNo}
                  shrink={true}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={2} className="p-1 border-dark border-end pt-3">
            {/* <h6 className="p-1 m-2"> */}
            <Row>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                8. Position Title
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                9. Pay Range.Step Salary or Rate
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                10. Dept. or Agency
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                11. Division
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                12. Section
              </Col>
              <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                13.Position Number
              </Col>
            </Row>
            {/* 8. Position Title */}
            {/* <br />
            <br /> */}
            {/* </h6> */}
            {/* <h6 className="p-1 m-2"> */}
            {/* 9. Pay Range.Step Salary or Rate */}
            {/* <br />
            <br /> */}
            {/* </h6>{" "} */}
            {/* <h6 className="p-1 m-2"> */}
            {/* 10. Dept. or Agency
            <br />
            <br /> */}
            {/* </h6>{" "} */}
            {/* <h6 className="p-1 m-2"> */}
            {/* 11. Division */}
            {/* <br />
            <br /> */}
            {/* </h6> */}
            {/* <h6 className="p-1 m-2"> */}
            {/* 12. Section
            <br />
            <br /> */}
            {/* </h6> */}
            {/* <h6 className="p-1 m-2"> */}
            {/* 13.Position Number */}
            {/* </h6> */}
          </Col>
          <Col sm={5} className="p-1  text-center p-3">
            <Row>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_title"
                  label="Position"
                  value={data?.toPositionTitle}
                  shrink={true}
                />
              </Col>
              <Col sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_pay_grade"
                  label="Pay Grade"
                  value={data?.toPayGrade}
                  shrink={true}
                />
              </Col>
              <Col sm={8}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toSalary"
                  label="Salary"
                  value={data?.toSalary}
                  shrink={true}
                />
              </Col>
              <Col sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_strp"
                  label="Step"
                  value={data?.toStep}
                  shrink={true}
                />
              </Col>
              <Col sm={8}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toRate"
                  label="Rate"
                  value={data?.toRate}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="toDepartment"
                  label="Department"
                  value={data?.toDepartment}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_section_divison"
                  label="Section/Division"
                  value={data?.toDivisionName}
                  shrink={true}
                />
              </Col>
              <Col sm={12}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  size="small"
                  margin="dense"
                  fullWidth
                  disabled
                  name="to_budget"
                  label="FTE"
                  value={data?.toFteNo}
                  shrink={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            borderTop: "1px solid black",
            minHeight: "16vh",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-between",
          }}
        >
          <Col sm={12} className="p-1">
            14.REMARKS (continue in item 21,if necessary) <br />
            <span className="p-4">{data?.remarks}</span>
          </Col>

          <Col
            md={12}
            lg={12}
            sm={12}
            className="p-1"
          //  style={{ display: "flex", justifyContent: "end", width: "50%" }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "end",
                width: "50%",
                float: "right",
              }}
            >
              {/* {console.log("123123 props... ",props?.approvedData)} */}
              <Col sm={12} md={12} lg={12}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  fullWidth
                  minRows={4}
                  rows={4}
                  margin="dense"
                  disabled
                  name="Approved"
                  label="Approved by Certifying Officer"
                  value={
                    props?.approvedData?.find((i) => i.statusId == 7)
                      ? `Digitally approved by ${props?.approvedData.length
                        ? props?.approvedData?.find((i) => i.statusId == 7)
                          ?.performedBy !== undefined
                          ? props?.approvedData?.find(
                            (i) => i.statusId == 7
                          )?.performedBy
                          : ""
                        : ""
                      } on ${props?.approvedData.length
                        ? props?.approvedData?.find((i) => i.statusId == 7)
                          ?.performedOn !== undefined
                          ? props?.approvedData?.find(
                            (i) => i.statusId == 7
                          )?.performedOn
                          : ""
                        : ""
                      }`
                      : ""
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={6} className="p-1" >
            <TextField
              className=" bg-white"
              InputLabelProps={{ shrink: true }}
              // style={{ backgroundColor: "#cdd3d6" }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                  fontSize: "12px",
                },
                "& .MuiFormLabel-root.Mui-disabled": {
                  WebkitTextFillColor: "#13346b",
                  bgcolor: "white",
                  fontSize: "14px",
                  fontWeight: "bolder",
                },
              }}
              size="small"
              margin="dense"
              fullWidth
              disabled
              name="jobordernumber"
              label="Job Order Number"
              value={data?.jobOrderNumber}
              shrink={true}
            />
          </Col>
          <Col sm={6} className="p-1" >
            <TextField
              InputLabelProps={{ shrink: true }}
              className="bg-white"

              // style={{ backgroundColor: "#cdd3d6" }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                  fontSize: "12px",
                },
                "& .MuiFormLabel-root.Mui-disabled": {
                  WebkitTextFillColor: "#13346b",
                  bgcolor: "white",
                  fontSize: "14px",
                  fontWeight: "bolder",
                },
              }}
              size="small"
              margin="dense"
              fullWidth
              disabled
              name="budgetaccountnumber"
              label="Budget Account Number"
              value={data?.budgetAcctNo}
              shrink={true}
            />
          </Col>

        </Row>

        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={6} className="p-1 border-dark border-end">
            15.Requested By (Signature and title) <br />
            {/* <span className="p-4"> {data?.UserName} </span>  */}
            {/* <span className="p-4"> {UserName} </span> */}
            <span className="p-4"> {CheckValue(data?.requestedByName) && CheckValue(data?.requestedByPosition) ? `${data?.requestedByName}, ${data?.requestedByPosition}` : UserName || ""} </span>
            
            <br />
            <br />
            {/* <span className="p-4"> Date </span> */}
          </Col>
          <Col sm={6} className="p-1 border-dark ">
            16.For Additional Information Call (name and tel.No)
            {/* <span className="p-4"> {UserContactNo} </span> */}
            {/* <span className="p-4"> {data?.contactNumber} </span> */}
            <span className="p-4"> {`${data?.contactFullName}, ${data?.contactPosition}  ${data?.contactNumber}`} </span>
            <br />
            <br />
            {/* <span className="p-4"> 12545</span> */}
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={6} className="p-1 border-dark border-end">
            17. Approved By (Signature, title and department)
            <br />
            {props?.approvedData?.find((i) => i.statusId == 133) ? (
              <span className="p-4 ">
                Digitally approved by{" "}
                {props?.approvedData.length
                  ? props?.approvedData?.find((i) => i.statusId == 133)
                    ?.performedBy !== undefined
                    ? props?.approvedData?.find((i) => i.statusId == 133)
                      ?.performedBy
                    : ""
                  : ""}
                {" "}on{" "}
                {props?.approvedData.length
                  ? props?.approvedData?.find((i) => i.statusId == 133)
                    ?.performedOn !== undefined
                    ? props?.approvedData?.find((i) => i.statusId == 133)
                      ?.performedOn
                    : ""
                  : ""}
              </span>
            ) : null}
            {/* <span className="p-4"> signature </span> */}
            <br />
            {/* <span className="p-4"> Date </span> */}
          </Col>
          <Col sm={6} className="p-1">
            18.Approved By (releasing Dept. Head - For transfers only)
            <br />
            {data?.natureCode?.value == "8" ||
              data?.natureCode?.value == "32" ||
              data?.natureCode?.value == "33" ||
              data?.natureCode?.value == "46" ||
              data?.natureCode?.value == "74" ?
              <>
                {props?.approvedData?.find((i) => i.statusId == 133) ? (
                  <span className="p-4 ">
                    Digitally approved by{" "}
                    {props?.approvedData.length
                      ? props?.approvedData?.find((i) => i.statusId == 133)
                        ?.performedBy !== undefined
                        ? props?.approvedData?.find((i) => i.statusId == 133)
                          ?.performedBy
                        : ""
                      : ""}
                    on{" "}
                    {props?.approvedData.length
                      ? props?.approvedData?.find((i) => i.statusId == 133)
                        ?.performedOn !== undefined
                        ? props?.approvedData?.find((i) => i.statusId == 133)
                          ?.performedOn
                        : ""
                      : ""}
                  </span>
                ) : null}
              </>
              : null}
            <br />
            {/* <span className="p-4">Supervision</span> */}
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col sm={12} className="p-1  ">
            19.CLEARANCES - ITEMS BELOW TO BE COMPLETED BY OFFICES CONCERNED
          </Col>
        </Row>
        <Row
          style={{ borderTop: "1px solid black", marginBottom: "5px" }}
        ></Row>
        <Row style={{ borderTop: "1px solid black" }}>
          <Col
            sm={6}
            className="border-dark border-end"
            style={{ paddingTop: "0 !important" }}
          >
            <Row className="text-center">
              <Col sm={6} className="p-1 border-dark border-end ">
                TO
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                ACTION
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                BY
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                DATE
              </Col>
            </Row>
            <Row className="  border-dark border-top">
              <Col sm={6} className="p-1 border-dark border-end ">
                CLASSIFICATON
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                {/* ADD */}
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                {/* 1 */}
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                {/* 2023/10/10 */}
              </Col>
            </Row>
            <Row className=" border-dark border-top">
              <Col sm={6} className="p-1 border-dark border-end ">
                EMPLOYMENT
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                {/* ADD */}
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                {/* 1 */}
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                {/* 2023/10/10 */}
              </Col>
            </Row>
            <Row className="  border-dark border-top">
              <Col sm={6} className="p-1 border-dark border-end ">
                OVERSEAS
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                {/* ADD */}
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                {/* 1 */}
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                {/* 2023/10/10 */}
              </Col>
            </Row>
            <Row className="  border-dark border-top">
              <Col sm={6} className="p-1 border-dark border-end ">
                RETIREMENT
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                {/* ADD */}
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                {/* 1 */}
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                {/* 2023/10/10 */}
              </Col>
            </Row>
            <Row className="  border-dark border-top">
              <Col sm={6} className="p-1 border-dark border-end ">
                RECORDS
              </Col>
              <Col sm={2} className="p-1 border-dark border-end ">
                {/* ADD */}
              </Col>{" "}
              <Col sm={1} className="p-1 border-dark border-end ">
                {/* 1 */}
              </Col>{" "}
              <Col sm={3} className="p-1  ">
                {/* 2023/10/10 */}
              </Col>
            </Row>
          </Col>
          <Col sm={3} className="text-center border-dark border-end">
            {/* <Row className="text-center p-1"> */}
            BUDGET SECTION
            {/* </Row> */}
            <br />
            <br />
            <br />
            {/* {props?.approvedData?.find((i) => i.statusId == 8 || i.statusId == 339 || i.statusId == 272 || i.statusId == 342 || i.statusId == 338) || props?.approvedData?.find((i) =>i?.bbmr)? ( */}
            {props?.approvedData?.find((i)=>BBMRStatuses?.includes(i?.statusId)) || props?.approvedData?.find((i) =>i?.bbmr)? (
              <>
              {props?.approvedData?.find((i)=>i.statusId == 8) && 
                <span className="pt-4">
                  Digitally approved by{" "}
                  {props?.approvedData.length
                    ? props?.approvedData?.find((i) => i.statusId == 8)
                      ?.performedBy !== undefined
                      ? props?.approvedData?.find((i) => i.statusId == 8)
                        ?.performedBy
                      : ""
                    : ""}
                  on{" "}
                  {props?.approvedData.length
                    ? props?.approvedData?.find((i) => i.statusId == 8)
                      ?.performedOn !== undefined
                      ? props?.approvedData?.find((i) => i.statusId == 8)
                        ?.performedOn
                      : ""
                    : ""}
                </span> 
              }
              <span className="pt-2">
                <br/>
                <hr/>
                Lester L. Carlson, Jr., BBMR Director
              </span>    
              </>
            ) : null}
            <br />
          </Col>
          <Col sm={3} className="text-center ">
            MANAGEMENT SECTION
          </Col>
        </Row>
        <Row
          style={{ borderTop: "1px solid black", marginBottom: "5px" }}
        ></Row>
        <Row
          style={{ borderTop: "1px solid black", marginBottom: "5px" }}
        ></Row>
        {props?.transactionProcessStatuses && props?.transactionProcessStatuses?.length ?
          <Card className="p-3 my-3" elevation={1}>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="h6">
                  <FontAwesomeIcon
                    className="m-r-10 font-primary"
                    icon={faTentArrowLeftRight}
                    size="lg"
                  />
                  Audit Trail
                </Typography>
              </div>
              <TableContainer
                style={{ marginBottom: "15px" }}
                component={Paper}
              >
                <Table
                  sx={{ minWidth: 700 }}
                  aria-label="simple table"
                >
                  {props?.transactionProcessStatuses &&
                    props?.transactionProcessStatuses?.length ? (
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr No</TableCell>
                        <TableCell>Performed Date</TableCell>
                        <TableCell>Process</TableCell>
                        <TableCell>Performed by</TableCell>
                        <TableCell>Remark</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                  ) : null}
                  {props?.transactionProcessStatuses &&
                    props?.transactionProcessStatuses?.length ? (
                    props?.transactionProcessStatuses.map((item, i) => {
                      return (
                        <TableBody>
                          <TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>
                              {item?.transactionProcessStatus?.performedDate === null
                                ? "N/A"
                                : dayjs(item?.transactionProcessStatus?.performedDate).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                            </TableCell>
                            <TableCell>
                              {processStatusList?.length &&
                                processStatusList.find((itm) => {
                                  return (
                                    itm.id === item?.transactionProcessStatus?.processStatusId
                                  );
                                })?.statusName}
                            </TableCell>
                            <TableCell>
                              {item?.transactionProcessStatus?.peformedByName
                                ? item?.transactionProcessStatus?.peformedByName
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {item?.transactionProcessStatus?.statusRemarks || "N/A"}
                            </TableCell>

                            <TableCell>
                              {item?.transactionProcessStatus?.status || "Completed"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <div className="text-center p-2">
                      No Data Found
                    </div>
                  )}
                </Table>
              </TableContainer>
            </CardBody>
          </Card>
          : null}
      </div>
    </div>
  );
});

export default ComponentToPrint;
