// Custom hook for handling sorted columns
import { useEffect, useState } from "react";
import { filterAllowedColumn } from "../_helper/helper";

const useSortedColumns = (
  id,
  ColumnsSorted,
  columns,
  visileColumns,
  userData
) => {
  const [sortedColumns, setSortedColumns] = useState([]);

  useEffect(() => {
    if (
      ColumnsSorted &&
      ColumnsSorted[id] !== undefined &&
      ColumnsSorted[id]?.length > 0
    ) {
      setSortedColumns(ColumnsSorted[id]);
    } else {
      if (columns && visileColumns.length > 0 && columns.length > 0) {
        const clms = visileColumns?.map((d) => d.field?.toLowerCase());
        const data = columns
          .filter(
            (p) =>
              p.column_name && clms.indexOf(p.column_name?.toLowerCase()) < 0
          )
          .map((p) => {
            const baseColumn = {
              field: p.column_name,
              show: false,
              locked: false,
              width: 200,
              title: p.column_title,
            };

            if (
              p.data_type === "int" ||
              p.data_type == "bigint" ||
              p.data_type === "float" ||
              p.data_type === "numeric"
            ) {
              return {
                ...baseColumn,
                filter: "numeric",
                className: "text-end",
                headerClassName: "number-align",
              };
            } else if (p.data_type == "datetime") {
              return {
                ...baseColumn,
                filter: "date",
                format: "MM/DD/yyyy ",
                editor: "date",
              };
            } else if (p.data_type == "money") {
              return {
                ...baseColumn,
                filter: "numeric",
                className: "text-end",
                headerClassName: "number-align",
                editor: "numeric",
              };
            } else {
              return {
                ...baseColumn,
                filter: "text",
              };
            }
          });

        setSortedColumns(
          filterAllowedColumn(userData, visileColumns.concat(data)),
          id
        );
      }
    }
  }, [id, ColumnsSorted, columns, visileColumns, userData]);
  return [sortedColumns, setSortedColumns];
};

export default useSortedColumns;
