import {
  GET_DIVISION,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAIL,
  GET_DIVISION_DETAILS,
  GET_DIVISION_DETAILS_SUCCESS,
  GET_DIVISION_DETAILS_FAIL,
  DIVISION_UPDATE,
  DIVISION_UPDATE_SUCCESS,
  DIVISION_UPDATE_FAIL,
  DIVISION_CREATE,
  DIVISION_CREATE_SUCCESS,
  DIVISION_CREATE_FAIL,
  DIVISION_DELETE,
  DIVISION_DELETE_SUCCESS,
  DIVISION_DELETE_FAIL,
  RESET,
} from "./actionTypes";

const initialState = {
  divisionList: [],
  division: {},
  postingResult: {},
  updating: false,
  loading: false,
  error: null,
};

const divisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIVISION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DIVISION_SUCCESS:
      return {
        ...state,
        divisionList: action.payload,
        loading: false,
        error: null,
      };
    case GET_DIVISION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_DIVISION_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_DIVISION_DETAILS_SUCCESS:
      return {
        ...state,
        division: action.payload,
        loading: false,
        error: null,
      };
    case GET_DIVISION_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DIVISION_UPDATE:
      return {
        ...state,
        postingResult: {},
        updating: true,
        
      };
    case DIVISION_UPDATE_SUCCESS:
      return {
        ...state,
        
        postingResult: action.payload,
        updating: false,
       
      };
    case DIVISION_UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        error: action.payload,
      };
    case DIVISION_CREATE:
      return {
        ...state,
        postingResult: {},
        loading: true,
       
      };
    case DIVISION_CREATE_SUCCESS:
      return {
        ...state,
        postingResult: action.payload,
        loading: false,
        
      };
    case DIVISION_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DIVISION_DELETE:
      return {
        ...state,
        postingResult: {},
        loading: true,
        
      };
    case DIVISION_DELETE_SUCCESS:
      return {
        ...state,
        postingResult: action.payload,
        loading: false,
       
      };
    case DIVISION_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET:
      return {
        ...state,
        division: {},
        error: null,
      };
    default:
      return state;
  }
};

export default divisionReducer;
