import React, { useEffect, useState } from "react";
import AnimationThemeContext from "../../_helper/AnimationTheme";
import Context from "./index";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoredTheme,
  userConfiguration,
} from "../../store/themeCustomizer/action";
import { useAuth } from "oidc-react";
const CustomizerProvider = (props) => {
  const [sidebar_types, setSidebarTypes] = useState("");
  const [settings, setSettings] = useState("");
  const [layout, setLayout] = useState("");
  const [mix_background_layout, setMixBackgroundLayout] = useState("");
  const [toggleIcon, setToggleIcon] = useState(false);
  const [mixLayout, setMixLayout] = useState(false);
  const [animation, setAnimation] = useState("");
  const [color, setColor] = useState({
    PrimaryColor: "",
    SecondaryColor: "",
  });
  const [pinnedMenuItems, setPinnedMenuItems] = useState(null);
  const [sidebarResponsive, setSidebarResponsive] = useState(false);
  const [IsOpen, setIsClose] = useState(false);
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const { StoredUserConfiguration } = useSelector(
    (state) => state?.ThemeCustomizerReducer
  );
  useEffect(() => {
    if (
      StoredUserConfiguration !== null &&
      Object.keys(StoredUserConfiguration).length > 0
    ) {
      setSidebarTypes(StoredUserConfiguration.settings.sidebar.type);
      setSettings(StoredUserConfiguration.settings.sidebar_setting);
      setLayout(StoredUserConfiguration.settings.layout_type);
      setMixBackgroundLayout(
        StoredUserConfiguration.color.mix_background_layout
      );
      setAnimation(StoredUserConfiguration?.router_animation);
      setColor({
        PrimaryColor: StoredUserConfiguration.color.primary_color,
        SecondaryColor: StoredUserConfiguration.color.secondary_color,
      });
      setPinnedMenuItems(StoredUserConfiguration?.pin_menuItem);
    }
  }, [StoredUserConfiguration]);
  useEffect(() => {
    const fetchData = async () => {
      const isToken = await userData?.access_token;
      if (isToken) {
        dispatch(userConfiguration());
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const payload = {
      settings: {
        layout_type: layout,
        sidebar: {
          type: sidebar_types,
        },
        sidebar_setting: settings,
      },
      color: {
        primary_color: color?.PrimaryColor,
        secondary_color: color?.SecondaryColor,
        mix_background_layout: mix_background_layout,
      },
      router_animation: animation,
      pin_menuItem: pinnedMenuItems,
    };
    if (
      layout !== "" ||
      sidebar_types !== "" ||
      settings !== "" ||
      color?.PrimaryColor !== "" ||
      color?.SecondaryColor !== "" ||
      mix_background_layout !== "" ||
      pinnedMenuItems !== null ||
      animation !== ""
    ) {
      dispatch(getStoredTheme({ layoutSetting: JSON.stringify(payload) }));
    }
  }, [
    layout,
    sidebar_types,
    settings,
    color?.PrimaryColor,
    color?.SecondaryColor,
    mix_background_layout,
    pinnedMenuItems,
    animation,
  ]);

  const addSidebarTypes = (type) => {
    if (type) {
      setSidebarTypes(type);
    }
  };

  const addSidebarSettings = (sidebar_Settings) => {
    if (sidebar_Settings) {
      setSettings(sidebar_Settings);
    }
  };

  const addLayout = (layout) => {
    if (layout) {
      setLayout(layout);
    }
  };
  const addPinMenuItem = (pinMenuItem) => {
    if (pinMenuItem) {
      setPinnedMenuItems(pinMenuItem);
    }
  };

  const addMixBackgroundLayout = (mix_background_layout) => {
    if (mix_background_layout !== "light-only") {
      setMixLayout(false);
    } else {
      setMixLayout(true);
    }
    setMixBackgroundLayout(mix_background_layout);
  };

  const addColor = (default_color, secondary_color) => {
    setColor({
      PrimaryColor: default_color,
      SecondaryColor: secondary_color,
    });
  };
  const RouterAnimation = (layout_anim) => {
    if (layout_anim) {
      setAnimation(layout_anim);
    }
  };
  const toggleSidebar = (toggle) => {
    setToggleIcon(toggle);
  };

  const toggleSidebarResponsive = (toggle) => {
    setSidebarResponsive(toggle);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        sidebar_types,
        settings,
        layout,
        mix_background_layout,
        toggleIcon,
        mixLayout,
        sidebarResponsive,
        IsOpen,
        setIsClose,
        toggleSidebarResponsive,
        setMixLayout,
        addSidebarTypes: addSidebarTypes,
        addSidebarSettings: addSidebarSettings,
        addLayout: addLayout,
        addMixBackgroundLayout: addMixBackgroundLayout,
        RouterAnimation: RouterAnimation,
        addColor: addColor,
        toggleSidebar: toggleSidebar,
        addPinMenuItem: addPinMenuItem,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default React.memo(CustomizerProvider);
