import "react-toastify/dist/ReactToastify.css";
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
    getExitSurveyAnswers,
    getExitSurveyMainReason,
    getExitSurveyMartialStatus,
    getExitSurveyGender,
    getExitSurveyData,
    getExitSurveyDataByToken,
    creteExitSurveyApi,
    getExitSurveyAge,
    creteExitSurveyFormTokenApi,
} from "../../_helper/services/exitsurvey";

import {
    EXIT_SURVEY_CREATE,
    GET_EXIT_SURVEY_ANSWER,
    GET_EXIT_SURVEY_MAIN_REASON,
    GET_EXIT_SURVEY_MARTIAL_STATUS,
    GET_EXIT_SURVEY_GENDER,
    GET_EXIT_SURVEY_DATA,
    RESET,
    GET_EXIT_SURVEY_TOKEN_DATA,
    GET_EXIT_SURVEY_AGE,
    GET_EXIT_SURVEY_DEPARTMENTS,
    GET_EXIT_SURVEY_POSITIONS,
    CREATE_FORM_TOKEN_EXIT_SURVEY,
} from "./actionType";

import {
    createExitSurvey,
    createExitSurveySuccess,
    createExitSurveyFail,
    getAnswersSuccess,
    getAnswersFail,
    getMainReasonSuccess,
    getMainReasonFail,
    getMartialStatusSuccess,
    getMartialStatusFail,
    getGenderSuccess,
    getGenderFail,
    getExitSurveyDataSuccess,
    getExitSurveyDataFail,
    reset,
    getExitSurveyTokenDataSuccess,
    getExitSurveyTokenDataFail,
    getAgeSuccess,
    getAgeFail,
    getExitSurveyDepartmentsSuccess,
    getExitSurveyDepartmentsFail,
    getExitSurveyPositionsSuccess,
    getExitSurveyPositionsFail,
    createExitFormTokenFail,
    createExitFormTokenSuccess,
} from "./actions";
import { getDepartments, getExitSurveyDepartments, getExitSurveyPositionsAPI } from "../../_helper/services/department";

function* onCreateExitSurvey({ payload }) {
    try {
        const response = yield call(creteExitSurveyApi, payload);
        yield put(createExitSurveySuccess(response));
        ShowSucess(response?.message, {
            position: "top-right",
        });
    } catch (error) {
        yield put(createExitSurveyFail(error.response));
        ShowError(error?.response?.data?.message);
    } finally {
        // yield put(reset());
    }
}

function* onCreateExitSurveyFormToken({ payload }) {
    try {
        const response = yield call(creteExitSurveyFormTokenApi, payload);
        yield put(createExitFormTokenSuccess(response));
        console.log("response ", response);
        ShowSucess(response?.message, {
            position: "top-right",
        });
    } catch (error) {
        yield put(createExitFormTokenFail(error.response));
        ShowError(error?.response?.data?.message);
    }
}

function* onGetAnswers() {
    try {
        const response = yield call(getExitSurveyAnswers);
        yield put(getAnswersSuccess(response));
    } catch (error) {
        yield put(getAnswersFail(error.response));
    }
}

function* onGetMainReason() {
    try {
        const response = yield call(getExitSurveyMainReason);
        yield put(getMainReasonSuccess(response));
    } catch (error) {
        yield put(getMainReasonFail(error.response));
    }
}

function* onGetMartialStatus() {
    try {
        const response = yield call(getExitSurveyMartialStatus);
        yield put(getMartialStatusSuccess(response));
    } catch (error) {
        yield put(getMartialStatusFail(error.response));
    }
}

function* onGetGender() {
    try {
        const response = yield call(getExitSurveyGender);
        yield put(getGenderSuccess(response));
    } catch (error) {
        yield put(getGenderFail(error.response));
    }
}

function* onGetAgeRange() {
    try {
        const response = yield call(getExitSurveyAge);
        yield put(getAgeSuccess(response));
    } catch (error) {
        yield put(getAgeFail(error.response));
    }
}

function* onGetExitSurveyDepartments({ payload: { page, pageSize } }) {
    try {
        const response = yield call(getExitSurveyDepartments, { page, pageSize });
        yield put(getExitSurveyDepartmentsSuccess(response));
    } catch (error) {
        yield put(getExitSurveyDepartmentsFail(error.response));
    }
}

function* onGetExitSurveyPositions() {
    try {
        const response = yield call(getExitSurveyPositionsAPI);
        yield put(getExitSurveyPositionsSuccess(response));
    } catch (error) {
        yield put(getExitSurveyPositionsFail(error.response));
    }
}

function* onGetExitSurveyData() {
    try {
        const response = yield call(getExitSurveyData);
        yield put(getExitSurveyDataSuccess(response));
    } catch (error) {
        yield put(getExitSurveyDataFail(error.response));
    }
}

function* onGetExitSurveyTokenData({ payload }) {
    try {
        const response = yield call(getExitSurveyDataByToken, payload);
        yield put(getExitSurveyTokenDataSuccess(response));
    } catch (error) {
        yield put(getExitSurveyTokenDataFail(error.response));
    }
}

function* ExitSurveySaga() {
    yield takeLatest(EXIT_SURVEY_CREATE, onCreateExitSurvey);
    yield takeLatest(CREATE_FORM_TOKEN_EXIT_SURVEY, onCreateExitSurveyFormToken);
    yield takeLatest(GET_EXIT_SURVEY_ANSWER, onGetAnswers);
    yield takeLatest(GET_EXIT_SURVEY_MAIN_REASON, onGetMainReason);
    yield takeLatest(GET_EXIT_SURVEY_MARTIAL_STATUS, onGetMartialStatus);
    yield takeLatest(GET_EXIT_SURVEY_GENDER, onGetGender);
    yield takeLatest(GET_EXIT_SURVEY_AGE, onGetAgeRange);
    yield takeLatest(GET_EXIT_SURVEY_DEPARTMENTS, onGetExitSurveyDepartments);
    yield takeLatest(GET_EXIT_SURVEY_POSITIONS, onGetExitSurveyPositions);
    yield takeLatest(GET_EXIT_SURVEY_DATA, onGetExitSurveyData);
    yield takeLatest(GET_EXIT_SURVEY_TOKEN_DATA, onGetExitSurveyTokenData);
    yield takeLatest(RESET, reset);
}

export default ExitSurveySaga;
