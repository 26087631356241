import React, { useEffect, useState } from 'react'
import "./style.css"

const BBMRStaffingPattern = () => {

    const [responseData, setResponseData] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [serverData, setServerData] = useState([]);

    const [totals, setTotals] = useState({});
    console.log("totals", totals);
    console.log("finalData", finalData)
    const handlePrint = () => {
        window.print();
    };
    //  useEffect(() => {
    //       fetch("https://ehrp-hr-webapi.azurewebsites.net/api/StaffingPattern/getEmployees").then(d => {
    //           d.json().then(response => {
    //               setResponseData(response);
    //           })
    //       });
    //   }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_DOTNET_URL}/StaffingPattern/getEmployees`).then(d => {
            d.json().then(response => {
                setResponseData(response);
            })
        });
    }, [])


    useEffect(() => {
        if (responseData && responseData?.results?.length > 0) {
            const departmentTotals = {};


            var groups = ['DEPARTMENT NAME', 'DIVISION NAME'],
                grouped = {};

            responseData?.results.forEach(function (a) {
                groups.reduce(function (o, g, i) {                            // take existing object,
                    o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
                    return o[a[g]];                                           // at last, then an array
                }, grouped).push(a);
            });
            setFinalData(grouped);
            setServerData(grouped);



        }
    }, [responseData]);

    useEffect(() => {
        if (selectedDepartment != "") {

            setFinalData({ [selectedDepartment]: serverData[selectedDepartment] });
        } else {
            setFinalData(serverData);
        }

    }, [selectedDepartment])

    useEffect(() => {
        console.log("he;;", finalData);
    }, [finalData]);
    if (finalData && Object.keys(finalData)?.length > 0) {
        return (
            <>
                {/* <Navbar /> */}
                <div className="container-fluid page-container mt-4">

                    <div className='container print-container'>
                        <div className="row">
                            <div className="col-12 col-md-8"><h3>BBMR Staffing Pattern Report 01</h3></div>
                            <div className="col-md-3 dropdown-deparment">
                                <select value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)} className="form-select" aria-label="Default select example">
                                    <option value="" selected disabled>Select a Department</option>
                                    {Object.keys(serverData).map((department, index) => (
                                        <option key={index} value={department}>{department}</option>
                                    ))}
                                </select>


                            </div>
                            <div className="col-md-1">
                                <button onClick={handlePrint} className='btn btn-primary printVisible'>Print</button>
                            </div>

                        </div>

                    </div>

                    {Object.keys(finalData).map((department, index) => {
                        // {Object.keys(finalData[selectedDepartment]).map((division, index) => {

                        return Object.keys(finalData[department]).map((division, index) => {
                            return <>
                                <div key={index}>
                                    <table
                                        style={{
                                            width: "max-content",
                                            borderCollapse: "collapse", whiteSpace: "wrap"
                                        }}
                                        className="table mt-3 table-striped table-bordered ">
                                        <thead>
                                            <tr >
                                                <td colSpan={22}>
                                                    <span><b>Department</b>: {department}</span>
                                                    <span style={{ width: '100px' }}>&nbsp;</span>
                                                    <span><b>Division</b>: {division}</span>
                                                </td>
                                            </tr>
                                            <tr>

                                                <th> </th>
                                                <th style={{ width: "120px" }} className='text-center'>(A)</th>
                                                <th style={{ width: "300px" }} className='text-center'>(B)</th>
                                                <th style={{ width: "200px" }} className='text-center'>(C)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(D)</th>
                                                <th style={{ width: "120px" }} className='text-center'>(E)</th>
                                                <th style={{ width: "80px" }} className='text-center'>(F)</th>
                                                <th style={{ width: "80px" }} className='text-center'>(G)</th>
                                                <th style={{ width: "200px" }} className='text-center' colSpan="2">(I)<br />Increment</th>
                                                <th style={{ width: "100px" }} className='text-center'>(J)<br />(E+F+G+I)  </th>
                                                {/* <th className='text-center'>Subtotal <br />(E+F+G+I)  </th>                                                 */}
                                                {/* <th className='text-center' colSpan='7'>Benefits</th> */}
                                                <th style={{ width: "100px" }} className='text-center'>(K)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(L)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(M)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(N)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(O)</th>
                                                <th style={{ width: "100px" }} className='text-center'>(P)</th>
                                                <th style={{ width: "80px" }} className='text-center'>(Q)</th>
                                                <th style={{ width: "200px" }} className='text-center'>(K thru Q)</th>
                                                <th style={{ width: "200px" }} className='text-center'>(J+R)</th>

                                            </tr>
                                            <tr>
                                                <th>No.</th>
                                                <th>Pos No.</th>
                                                <th>Position Title</th>
                                                <th>Name of Incumbent</th>
                                                <th>Grade/Step</th>
                                                <th className='text-center'>Annual Salary</th>
                                                <th>O/T</th>
                                                <th>Special</th>


                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th className='text-center'>Subtotal</th>
                                                <th className='text-center'>(J*29.43 %)  <br /> Retire</th>
                                                <th className='text-center'>Retire (DD1)</th>
                                                <th className='text-center'>(6.2% *J)  <br /> Soc.Sec </th>
                                                <th className='text-center'>(1.45% *J) <br />  Medicare</th>
                                                <th className='text-center'>Life (187)</th>
                                                <th className='text-center'>Medical <br /> (Prem)</th>
                                                <th>Dental <br /> (Prem)</th>
                                                <th className='text-center'>Total Benefits</th>
                                                <th className='text-center'>Total <br /> </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {/* {finalData[department][division].map((item, index) => ( */}
                                            {finalData[department][division].map((item, index) => (

                                                <tr>

                                                    <td>{index + 1}</td>
                                                    <td>{item["Pos No."]}</td>
                                                    <td>{item["Position Title"]}</td>
                                                    <td>{item["Name Of Incumbent"]}</td>
                                                    <td>{item["Grade /Step"]}</td>
                                                    {/* <td className="d-flex justify-content-between">
                                                        <span>$</span>
                                                        {item["Salary"] !== null ? parseFloat(item["Salary"]).toFixed(2) : 0}
                                                    </td> */}
                                                    <td  ><span className="d-flex justify-content-between">
                                                        {/* <span>$</span> {item["Salary"].toLocaleString('en-US')} */}
                                                        <span>$</span> {item["Salary"] !== null ? item["Salary"].toLocaleString('en-US') : ''}
                                                    </span>
                                                    </td>

                                                    <td  ><span className="d-flex justify-content-between">
                                                        {/* <span>$</span> {item["O/T"]} */}
                                                        <span>$</span> {item["O/T"] !== null ? item["O/T"].toLocaleString('en-US') : ''}

                                                    </span>

                                                    </td>
                                                    <td  ><span className="d-flex justify-content-between"
                                                    >
                                                         <span>$</span> {item["Special"] !== null ? item["Special"].toLocaleString('en-US') : ''}

                                                        {/* <span>$</span> {item["Special"]} */}
                                                        </span>
                                                    </td>


                                                    <td>{item["Increment Date"] ? new Date(item["Increment Date"]).toLocaleDateString('en-US') : ''}</td>

                                                    <td  ><span className="d-flex justify-content-between">  
                                                    <span>$</span> {item["Increment Amt"] !== null ? item["Increment Amt"].toLocaleString('en-US') : ''}

                                                     {/* {item["Increment Amt"]} */}
                                                     </span>
                                                     </td>
                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Subtotal"]} */}
                                                    <span>$</span> {item["Subtotal"] !== null ? item["Subtotal"].toLocaleString('en-US') : ''}

                                                         </span></td>

                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Retire"]} */}
                                                    <span>$</span> {item["Retire"] !== null ? item["Retire"].toLocaleString('en-US') : ''}

                                                         </span></td>

                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Retire(DDI)"]} */}
                                                    <span>$</span> {item["Retire(DDI)"] !== null ? item["Retire(DDI)"].toLocaleString('en-US') : ''}

                                                         </span></td>


                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Soc.Sec"]} */}
                                                    <span>$</span> {item["Soc.Sec"] !== null ? item["Soc.Sec"].toLocaleString('en-US') : ''}

                                                         </span></td>

                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Medicare"]} */}
                                                    <span>$</span> {item["Medicare"] !== null ? item["Medicare"].toLocaleString('en-US') : ''}

                                                         </span></td>


                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Life"]} */}
                                                    <span>$</span> {item["Life"] !== null ? item["Life"].toLocaleString('en-US') : ''}

                                                         </span></td>
                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Medical"]} */}
                                                    <span>$</span> {item["Medical"] !== null ? item["Medical"].toLocaleString('en-US') : ''}

                                                         </span></td>
                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Dental"]} */}
                                                    <span>$</span> {item["Dental"] !== null ? item["Dental"].toLocaleString('en-US') : ''}

                                                         </span></td>


                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Total Benefits"]} */}
                                                    <span>$</span> {item["Total Benefits"] !== null ? item["Total Benefits"].toLocaleString('en-US') : ''}

                                                         </span></td>

                                                    <td  ><span className="d-flex justify-content-between">
                                                         {/* <span>$</span> {item["Total"]} */}
                                                    <span>$</span> {item["Total"] !== null ? item["Total"].toLocaleString('en-US') : ''}

                                                         </span></td>

                                                </tr>

                                            ))}
                                            <tr>
                                                <td colSpan={5}></td>
                                                <td >
                                                    <span className="d-flex justify-content-between">
                                                        <span>$</span>

                                                        {/* <b>{parseInt(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Salary"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Salary"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>
                                                </td>


                                                <td  >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseInt(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["O/T"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["O/T"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>

                                                    </span>

                                                </td>
                                                <td  >
                                                    <span className="d-flex justify-content-between">
                                                        <span>$</span>

                                                        {/* <b>{parseInt(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Special"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Special"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>


                                                </td>
                                                <td></td>
                                                <td  >
                                                    <span className="d-flex justify-content-between">
                                                        <span>$</span>
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Increment Amt"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                        {/* <b>{parseInt(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Increment Amt"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">
                                                        <span>$</span>
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Subtotal"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>


                                                    </span>

                                                </td>
                                                <td  >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseInt(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Retire"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseInt(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Retire"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Retire(DDI)"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Retire(DDI)"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>

                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Soc.Sec"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Soc.Sec"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Medicare"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Medicare"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td  >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Life"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Life"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Medical"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Medical"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Dental"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Dental"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Total Benefits"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Total Benefits"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>
                                                    </span>

                                                </td>
                                                <td   >
                                                    <span className="d-flex justify-content-between">

                                                        <span>$</span>
                                                        {/* <b>{parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                            return acc + obj["Total"]
                                                        }, 0)).toFixed(0)}</b> */}
                                                        <b>
                                                            {parseFloat(finalData[department][division].reduce((acc, obj) => {
                                                                return acc + obj["Total"]
                                                            }, 0)).toLocaleString('en-US')}
                                                        </b>

                                                    </span>

                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </>
                        })

                    })}


                </div>

            </>
        )
    } else {
        return <div class="text-center">
            <div class="spinner-grow" role="status" style={{ width: '5rem', height: '5rem', marginTop: '200px' }}>
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    }

}


export default BBMRStaffingPattern