import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { ShowError, ShowSucess } from "../../../_helper/helper";

const sliceName = "EmplFrPerformanceEvalSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const fetchEmplFrPerformanceEvalData = createAsyncThunk(
  `${sliceName}/fetchEmployeeData`,
  async (actnNo) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV
      let employeeData = {};
      try {
        const LookUpItems = await axios.get(
          `${process.env.REACT_APP_API_DOTNET_URL}/Employee02/${actnNo}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        if (LookUpItems) {
          employeeData = LookUpItems.data;
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        employeeData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const fetchEmplDataByIDPerformanceEval = createAsyncThunk(
  `${sliceName}/fetchEmployeeDataByID`,
  async (id) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV

      //get nature of transactions

      let employeeDatabyId = {};
      let TransactionProcessStatuses = [];
      try {
        const LookUpItems = await axios.get(
          `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/${id}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );
        if (LookUpItems) {
          employeeDatabyId = LookUpItems.data.performanceEvaliation;
          if (LookUpItems?.data?.transactionProcessStatuses) {
            TransactionProcessStatuses =
              LookUpItems?.data?.transactionProcessStatuses;
          }
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        employeeDatabyId,
        TransactionProcessStatuses: TransactionProcessStatuses
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getUrlToken = createAsyncThunk(
  `${sliceName}/getToken`,
  async (id) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      // Get Token
      let token = "";

      try {
        const getToken = await axios.get(
          `https://hr-webapi.dev.ehrpweb.com/getToken`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                "jwt_access_token"
              )}`,
            },
          }
        );

        token = getToken?.data;
      } catch (error) {
        console.log("error", error);
      }

      return {
        token,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getAuditHistoryData = createAsyncThunk(
  `${sliceName}/getAuditHistoryData`,
  async (data) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      const getAuditHistoryByActionNo = await axios.get(
        `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/AuditTrail/${data.employeeid}/${data.hrControl}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );
      let HistoryData = [];
      if (getAuditHistoryByActionNo.data) {
        HistoryData = getAuditHistoryByActionNo.data;
      }
      return {
        HistoryData,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);


export const getProcessDetailsList = createAsyncThunk(
  `${sliceName}/getProcessDetailsList`,
  async (data) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      const processStaus = await axios.get(
        `${process.env.REACT_APP_API_DOTNET_URL}/LkProcessStatus/ByProcessID?ProcessID=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            // Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
            // "x-access-token":
            //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IkUzM0FERTA3MUU5MzJFRUI3MzMwMkUzMDc2RTRDQ0MzIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODA1MDY4ODQsImV4cCI6MTY4MDU5MzI4NCwiaXNzIjoiaHR0cHM6Ly9zaWduaW4uZWhycHdlYi5jb20iLCJjbGllbnRfaWQiOiJteS1laHJwd2ViIiwic3ViIjoiYmU4ZGFmMGEtZGY2Ny00MzZjLWJkMzgtOWQxODFmNGFiMWYzIiwiYXV0aF90aW1lIjoxNjgwNTA2ODc3LCJpZHAiOiJsb2NhbCIsInJvbGUiOiJzdXBlcnZpc29yIiwiRW1wbG95ZWVJRCI6IjEwMDM2OTQyIiwiRGVwYXJ0bWVudElEIjoiMTYyMiIsImVtYWlsIjoic3VwZXJ2aXNvckBnbWFpbC5jb20iLCJqdGkiOiI3MEE4RUEyODhCMUEwMzI1MDlFQTdBQkMyRDBFODI4NSIsInNpZCI6IjM1MUQzQjM3NkYyRjVERTZDMzYzMzgxRDhGQUY4MUUxIiwiaWF0IjoxNjgwNTA2ODg0LCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJyb2xlcyIsImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.PcMxXiMhikRB81L-PkEKWeZbW3fBdqY1iQosncmY9sIQPp0fE743yXx-c6fDQ6-GO80somA2wTn5zoyy5Z58E9Us_pR6Fw9JXPs1NR_0X_o3AjdMa5ASG7oDiEyZ_miCzQYuC3MOPy-EBlp0ME6zLvspQ04VfKHrBSvj9n6lC96MiLYtPP89v_Q72JgQchJM4UtKeVQmDtNx974YDiSef_PZpw9rjj3oJa1-K7t5LQ4-KFnM7YBNR63A9VohsWQI2UneITS_ukDgZtsVQhA6zG__xg3wew58e72DlwuIhtnr5Ue16Gak8_FUDKBQyDlJam6mQi--tiuXnuLBhuOQzw",
          },
        }
      );


      return {
        statusList: processStaus.data || []
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

export const getEmployeeListOfPE = createAsyncThunk(
  `${sliceName}/getEmployeeListOfPE`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {

      //"Dept_No as DEPT_CODE,DPDV as DeptDiv"
      let infoDept = [
        {
          columnName: "Ndate_Increm",
          operator: "<",
          value: moment().format("YYYY-MM-DD"),
          dataType: "Date",
          logicOperator: "AND",
        },
      ]

      if (payload?.Dept_No && payload?.Dept_No == '99') {
        infoDept = [
          {
            columnName: "DPDV",
            operator: "=",
            value: `${payload?.dpdv}`,
            dataType: "varchar(max)",
            logicOperator: "AND",
          },
        ]
      } else {
        infoDept = [
          {
            columnName: "Dept_No",
            operator: "=",
            value: `${payload?.Dept_No}`,
            dataType: "varchar(max)",
            logicOperator: "AND",
          },
        ]
      }
      if (["SUPER-USER"].includes(sessionStorage?.getItem("currentRole"))) {
        infoDept = []
      }

      const getPEEmployee = await axios.post(
        `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`,
        {
          "tableName": "fn_PE_Employees",
          searchParams: JSON.stringify(infoDept),
          // searchParams: JSON.stringify([]),
          "columns": "*",
          "pageNumber": 1,
          "pageSize": 1000000,
          "sortData": "",
          "qID": 0,
          "location": "global",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,
          },
        }
      );

      return {
        pe_employeeList: getPEEmployee?.data?.results || []
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);


export const getPESupervisorList = createAsyncThunk(
  `${sliceName}/getPESupervisorList`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    let searchTxt = `[
      {\"columnName\":\"Department\",\"operator\":\"=\",\"value\":\"${payload?.department}\",\"dataType\":\"varchar(max)\",\"logicOperator\":\"AND\"}
    ]`

    if (payload.department == "99") {
      searchTxt = `[
        {\"columnName\":\"Departmentdivision\",\"operator\":\"=\",\"value\":\"${payload?.dpdv?.trim()}\",\"dataType\":\"varchar(max)\",\"logicOperator\":\"AND\"}
      ]`
    }
    try {
      const getPEEmployee = await axios.post(
        `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`,
        {
          "tableName": "vw_PE_Timekeepers_Supervisor",
          searchParams: searchTxt,
          "columns": "*",
          "pageNumber": 1,
          "pageSize": 1000000,
          "sortData": "",
          "qID": 0,
          "location": "global",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,
          },
        }
      );
      // {\"columnName\":\"userRole\",\"operator\":\"=\",\"value\":\"S\",\"dataType\":\"varchar(max)\",\"logicOperator\":\"AND\"},
      return {
        supervisorList: getPEEmployee?.data?.results || []
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

// export const getPEReviewerList = createAsyncThunk(
//   `${sliceName}/getPEReviewerList`,
//   async () => {
//     axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
//     try {
//       const getPEReviewer = await axios.post(
//         `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`,
//         {
//           "tableName": "vw_PE_Timekeepers_Supervisor",
//           searchParams: "[{\"columnName\":\"userRole\",\"operator\":\"=\",\"value\":\"S\",\"dataType\":\"varchar(max)\",\"logicOperator\":\"AND\"}]",
//           "columns": "*",
//           "pageNumber": 1,
//           "pageSize": 1000000,
//           "sortData": "",
//           "qID": 0,
//           "location": "global",
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,
//           },
//         }
//       );

//       return {
//         reviewerList: getPEReviewer?.data?.results || []
//       };
//     } catch (e) {
//       return {
//         error: e.message,
//       };
//     }
//   }
// );


export const deletePE = createAsyncThunk(
  `${sliceName}/deletePE`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/Soft_delete?id=${payload.ID}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,
          },
        }
      ).then((response) => {
        ShowSucess(
          `Deleted Successfully`,
        );
        setTimeout(() => {
          window.location.href = ""
        }, 1000)
        return true
      })

    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);


export const getUserDepartment = createAsyncThunk(
  `${sliceName}/getUserDepartment`,
  async (payload) => {
    axios.defaults.headers.common['role'] = sessionStorage.getItem("currentRole");

    let empID = payload.EmployeeID;
    let searchData = {
      "tableName": "EmployeeMaster",
      searchParams: ``,
      columns: "Dept_No,DPDV",
      additionalWhere: `ID=${empID}`,
      "pageNumber": 1,
      "pageSize": 100,
      "sortData": "",
      "qID": 0,
      "location": "global",
    };

    return axios
      .post(`${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`,
        searchData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,
          },
        })
      .then((response) => {
        return { userDepartment: response?.data?.results?.length ? response.data.results[0] : {} }
      })
      .catch((error) => {
        return {
          error: error.message,
        };
      });

  }
);

const EmplFrPerformanceEvalSlice = createSlice({
  name: sliceName,
  initialState: {
    EmployeeArr: {},
    EmployeeDatabyId: {},
    TransactionProcessStatuses: [],
    token: "",
    isEmployeeLoading: false,
    isPELoading: false,
    isUrlToken: false,
    isProcessStatusList: false,
    auditHistory: [],
    processStatusList: [],
    pe_employeeList: [],
    ispe_employeeList: false,
    isDeleted: false,
    isSupervisorLoading: false,
    supervisorList: [],
    isReviewerLoading: false,
    reviewerList: [],
    userDepartment: {},
    isUserDepartmentLoading: false
  },
  reducers: {
    clearPageData: (state) => {
      state.EmployeeArr = {};
      state.EmployeeDatabyId = {};
      state.token = "";
      state.loding = false;
      state.auditHistory = [];
    },
  },

  extraReducers: {
    [fetchEmplFrPerformanceEvalData.fulfilled]: (state, action) => {
      state.EmployeeArr = action.payload.employeeData;
      state.isEmployeeLoading = false;
    },
    [fetchEmplDataByIDPerformanceEval.pending]: (state, action) => {
      state.EmployeeDatabyId = {};
      state.isPELoading = true;
    },
    [fetchEmplDataByIDPerformanceEval.fulfilled]: (state, action) => {
      state.EmployeeDatabyId = action.payload.employeeDatabyId;
      state.TransactionProcessStatuses = action.payload.TransactionProcessStatuses;
      state.isPELoading = false;
    },
    [fetchEmplDataByIDPerformanceEval.rejected]: (state, action) => {
      state.isPELoading = false;
    },
    [fetchEmplFrPerformanceEvalData.pending]: (state, action) => {
      state.EmployeeArr = {};
      state.isEmployeeLoading = true;
    },
    [fetchEmplFrPerformanceEvalData.rejected]: (state, action) => {
      state.isEmployeeLoading = false;
    },
    [getUrlToken.pending]: (state, action) => {
      state.isUrlToken = true;
    },
    [getUrlToken.fulfilled]: (state, action) => {
      state.token = action.payload.token;
      state.isUrlToken = false;
    },
    [getUrlToken.rejected]: (state, action) => {
      state.isUrlToken = false;
    },
    [getAuditHistoryData.fulfilled]: (state, action) => {
      state.auditHistory = action.payload.HistoryData;
      state.loding = false;
    },
    [getAuditHistoryData.pending]: (state, action) => {
      state.auditHistory = [];
      state.loding = true;
    },
    [getAuditHistoryData.rejected]: (state, action) => {
      state.auditHistory = [];
      state.loding = false;
    },

    [getProcessDetailsList.fulfilled]: (state, action) => {
      state.processStatusList = action.payload.statusList;
      state.isProcessStatusList = false;
    },
    [getProcessDetailsList.pending]: (state, action) => {
      state.processStatusList = [];
      state.isProcessStatusList = true;
    },
    [getProcessDetailsList.rejected]: (state, action) => {
      state.processStatusList = [];
      state.isProcessStatusList = false;
    },

    [getEmployeeListOfPE.fulfilled]: (state, action) => {
      state.pe_employeeList = action.payload.pe_employeeList;
      state.ispe_employeeList = false;
    },
    [getEmployeeListOfPE.pending]: (state, action) => {
      state.pe_employeeList = [];
      state.ispe_employeeList = true;
    },
    [getEmployeeListOfPE.rejected]: (state, action) => {
      state.pe_employeeList = [];
      state.ispe_employeeList = false;
    },

    [getPESupervisorList.fulfilled]: (state, action) => {
      state.supervisorList = action.payload.supervisorList;
      state.isSupervisorLoading = false;
    },
    [getPESupervisorList.pending]: (state, action) => {
      state.supervisorList = [];
      state.isSupervisorLoading = true;
    },
    [getPESupervisorList.rejected]: (state, action) => {
      state.supervisorList = [];
      state.isSupervisorLoading = false;
    },

    // [getPEReviewerList.fulfilled]: (state, action) => {
    //   state.reviewerList = action.payload.reviewerList;
    //   state.isReviewerLoading = false;
    // },
    // [getPEReviewerList.pending]: (state, action) => {
    //   state.reviewerList = [];
    //   state.isReviewerLoading = true;
    // },
    // [getPEReviewerList.rejected]: (state, action) => {
    //   state.reviewerList = [];
    //   state.isReviewerLoading = false;
    // },


    [getUserDepartment.fulfilled]: (state, action) => {
      console.log("acttion 123", action?.payload?.userDepartment)
      state.userDepartment = action?.payload?.userDepartment || {};
      state.isUserDepartmentLoading = false;
    },
    [getUserDepartment.pending]: (state, action) => {
      state.userDepartment = {};
      state.isUserDepartmentLoading = true;
    },
    [getUserDepartment.rejected]: (state, action) => {
      console.log("payload rs 3")
      state.userDepartment = {};
      state.isUserDepartmentLoading = false;
    },


    [deletePE.fulfilled]: (state, action) => {
      state.isDeleted = false;
    },
    [deletePE.pending]: (state, action) => {
      state.isDeleted = true;
    },
    [deletePE.rejected]: (state, action) => {
      state.isDeleted = false;
    },
  },
});

export default EmplFrPerformanceEvalSlice.reducer;
export const { clearPageData } = EmplFrPerformanceEvalSlice.actions;
