const HistoryColumns = [
  {
    title: "Change DateTime",
    field: "changeDateTime",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY hh:mm A",
    editor: "date",
    width: "auto",
    show: true,
  },
  {
    title: "Middle Name",
    field: "middleName",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Email",
    field: "email",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Status",
    field: "empStatus",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Work Schedule",
    field: "workSch",
    locked: false,
    filter: "text",

    width: "auto",
    show: true,
  },
];

export default HistoryColumns;
