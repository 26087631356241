import * as React from "react";
import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuItemContent,
  GridColumnMenuItemGroup,
} from "@progress/kendo-react-grid";
import { classNames } from "@progress/kendo-react-common";

export const CustomMenu = (props) => {
  const handleColumnToggle = (state) => {
    props.onColumnLockToggle(props.column.field || "", state);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };
  return (
    <div>
      <GridColumnMenuSort {...props} />
      <GridColumnMenuFilter {...props} />
      {props?.loadQuery ? "" : <GridColumnMenuGroup {...props} />}
      <GridColumnMenuItemGroup>
        <GridColumnMenuItemContent show={true}>
          <div className={"k-column-list-wrapper"}>
            <div className={"k-column-list"}>
              {props.locked !== true ? (
                <div
                  className={classNames("k-column-list-item")}
                  onClick={() => handleColumnToggle(true)}
                >
                  <span className="k-icon k-i-lock" /> Lock Column
                </div>
              ) : (
                <div
                  className={classNames("k-column-list-item")}
                  onClick={() => handleColumnToggle(false)}
                >
                  <span className="k-icon k-i-unlock" /> Unlock Column
                </div>
              )}
            </div>
          </div>
        </GridColumnMenuItemContent>
      </GridColumnMenuItemGroup>
    </div>
  );
};
