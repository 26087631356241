import {
	GET_TRAININGS, GET_TRAININGS_SUCCESS, GET_TRAININGS_FAIL,
	GET_TRAININGS_DETAILS, GET_TRAININGS_DETAILS_SUCCESS, GET_TRAININGS_DETAILS_FAIL,
	TRAINING_CREATE,TRAINING_CREATE_FAIL,TRAINING_CREATE_SUCCESS,
	TRAINING_DELETE,TRAINING_DELETE_FAIL,TRAINING_DELETE_SUCCESS,
	TRAINING_UPDATE,TRAINING_UPDATE_FAIL,TRAINING_UPDATE_SUCCESS,RESET, GET_TRAININGS_SETTING_FAIL, GET_TRAININGS_SETTING_SUCCESS, SAVE_TRAININGS_SETTING_FAIL, SAVE_TRAININGS_SETTING_SUCCESS, SAVE_TRAININGS_SETTING, GET_TRAININGS_SETTING, TRAINING_COPY, TRAINING_COPY_SUCCESS, TRAINING_COPY_FAIL, TRAINING_STATUS_CREATE, TRAINING_STATUS_CREATE_SUCCESS, TRAINING_STATUS_CREATE_FAIL, TRAINING_STATUS_UPDATE, TRAINING_STATUS_UPDATE_SUCCESS, TRAINING_STATUS_UPDATE_FAIL, TRAINING_STATUS_DELETE, TRAINING_STATUS_DELETE_SUCCESS, TRAINING_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getTrainings = (payload) => {
	return {
	  type: GET_TRAININGS,
	  payload: payload
	};
  };
  
  export const getTrainingsSuccess = (posts) => {
	return {
	  type: GET_TRAININGS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getTrainingsFail = (error) => {
	return {
	  type: GET_TRAININGS_FAIL,
	  payload: error,
	};
  };
  
  export const getTrainingsDetails = (id) => {
	return {
	  type: GET_TRAININGS_DETAILS,
	  payload: id,
	};
  };
  
  export const getTrainingsDetailsSuccess = (post) => {
	return {
	  type: GET_TRAININGS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getTrainingsDetailsFail = (error) => {
	return {
	  type: GET_TRAININGS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createTraining =(payload)=>{
	return {
		type: TRAINING_CREATE,
		payload: payload
	}
  }
  export const createTrainingSuccess =(response)=>{
	return {
		type: TRAINING_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createTrainingFail =(error)=>{
	return {
		type: TRAINING_CREATE_FAIL,
		payload: error
	}
  }


  export const updateTraining =(payload)=>{
	
	return {
		type: TRAINING_UPDATE,
		payload: payload
	}
  }
  export const updateTrainingSuccess =(response)=>{
	return {
		type: TRAINING_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updateTrainingFail =(error)=>{
	return {
		type: TRAINING_UPDATE_FAIL,
		payload: error
	}
  }


  export const deleteTraining =(payload)=>{
	return {
		type: TRAINING_DELETE,
		payload: payload
	}
  }
  export const deleteTrainingSuccess =(response)=>{
	return {
		type: TRAINING_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deleteTrainingFail =(error)=>{
	return {
		type: TRAINING_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

