import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
  GET_DETAILS_BY_ADMIN,
  GET_TABLENAMES,
  UPDATE_TABLENAMES,
} from "./actionTypes";

import {
  getDetailsByAdminSuccess,
  getDetailsByAdminFail,
  getTableNamesSuccess,
  getTableNamesFail,
  updateTableNamesSuccess,
  updateTableNamesFail,
} from "./actions";

import {
  getSystemAdmin,
  getTableList,
  updateTableNames,
} from "../../_helper/services/systemAdmin";

function* onGetSystemAdminDetails({ payload }) {
  try {
    const response = yield call(getSystemAdmin, payload);
    yield put(getDetailsByAdminSuccess(response));
    console.log("getsystem",response)
  } catch (error) {
    yield put(getDetailsByAdminFail(error.response));
  }
}
function* onGetTableNamesList() {
  try {
    const response = yield call(getTableList);
    yield put(getTableNamesSuccess(response));
   
  } catch (error) {
    yield put(getTableNamesFail(error.response));
  }
}

function* onUpdateTableNames({ payload }) {
  try {
    const response = yield call(updateTableNames, payload);
    yield put(updateTableNamesSuccess(response));
    ShowSucess("Updated", {
      position: "top-right",
    });
    
  } catch (error) {
    yield put(updateTableNamesFail(error.response));
  }
}
function* CartSaga() {
  yield takeLatest(GET_DETAILS_BY_ADMIN, onGetSystemAdminDetails);
  yield takeLatest(GET_TABLENAMES, onGetTableNamesList);
  yield takeLatest(UPDATE_TABLENAMES, onUpdateTableNames);
}

export default CartSaga;
