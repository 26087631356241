import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { Modal, Typography } from "@mui/material";
import visileColumns from "./visileColumns";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../../ErrorBoundry";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable";
import { useNavigate } from "react-router-dom";
import { faEye, faList } from "@fortawesome/pro-duotone-svg-icons";
import { ShowError, filterAllowedColumn } from "../../../_helper/helper";
import { useAuth } from "oidc-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Icon } from "@progress/kendo-react-common";
import useSortedColumns from "../../../utils/useSortedColumns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Report from "./Report";
import PDFGenerator from "../../../CommonComponents/SendNotice/PDFGenerator";
import { postReturnResponse } from "../../../_helper/api_helper";
import { gg1ReportDownload } from "../../../_helper/services/advanceSearchv2";

function OverdueProbationary({ report }) {
  const {
    columns,
    loadingSearch,
    searchResults,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const navigate = useNavigate();
  const { userData } = useAuth();
  // const [expandedSearchResult, setexpandedSearchResult] = useState(false);

  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [searchParams, setSearchParams] = useState([]);

  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([
    // {field:"Step_Actual",dir:"DESC"}
  ]);
  const [pageSize, setPageSize] = useState(100);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const id = "OverdueProbationary";
  const history = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [departmentModalContent, setDepartmentModalContent] = useState(null);
  const [groupDepartmentLoader,setGroupDepartmentLoader] = useState(false)

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const [groupConfig, setGroupConfig] = useState([{
    field: "Department_name",
    aggregates: [{ field: "Department_name", aggregate: "count" }],
  },]);

  const actions = useMemo(() => {
    return [
      // {
      //   title: "delete",
      //   icon: { iconName: 'k-icon  k-i-delete' },
      //   className: "font-primary",
      //   style: { cursor: "pointer" },
      //   action: (props) => {
      //     if (window.confirm("Are you sure to delete this?")) {
      //       dispatch(deleteEducation(props?.dataItem?.Education));
      //     }
      //   },
      // },
    ];
  });
  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);
  useEffect(() => {
    // console.log(searchResults);
  }, [searchResults]);
  const [sortedColumns,setSortedColumns] = useSortedColumns(
    id,
    ColumnsSorted,
    columns,
    visileColumns,
    userData
  );
  useEffect(() => {
    setTimeout(()=>{
      setGroupConfig([
        {
          field: "Department_name",
          aggregates: [{ field: "Department_name", aggregate: "count" }],
        },
      ]);
    },1000)
    

    return () => {
      // Clear groupConfig when component unmounts
      setGroupConfig([]);
    };
  }, [sortedColumns]);
  useEffect(() => {
    // Trigger a reflow after the component mounts
      if (window) {
        window.dispatchEvent(new Event('resize'));
      }
  }, [results]);

  useEffect(()=>{
      if(
      sessionStorage.getItem("overdueprobationary")==="overdueprobationary"
      ){
        sessionStorage.removeItem("overdueprobationary")
        window.location.reload();
      }
      return()=>{
        sessionStorage.removeItem("overdueprobationary")
      }
  },[])

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    // setPage(1);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);

  const handleModalContent = async(data) => {
    setDepartmentModalContent(data);
    // handleOpenDialog();
    console.log(data,"probationData11")
    const {Dept_No,Department_name} = data[0]
    try{
      const payload = {
        ...searchParameter[id],
        additionalWhere:`Dept_No='${Dept_No}'`,
        "columnAlignments": "[{\"ColumnName\":\"Probationaryenddate\",\"Aliginment\":\"center\"}]",
        "headerAliginment": "[{\"ColumnName\":\"Probationaryenddate\",\"Aliginment\":\"center\"}]",
        columns: sortedColumns
          .filter((i) => i.show && !i.remove)
          .map((d) => d.field)
          .join(","),
        fileExtension: "pdf",
        isOJA: false,
        headername:true,
        headerContent:`<div style=\"text-align: center;\"><h2><b>${Department_name}</b></h2>\n<h3 style=\"font-size: 16px; font-family: Arial, sans-serif;\"><u><b>Overdue Probationary Notice Report</b></u></h3></div>`,
        footerContent:`<table style=\"width:100%; border: 1px solid black;\"><tr><td>Information is based on notices processed to date. Please
        disregard notice if probationary report has been submitted to
        this office.</td></tr><tr><td>If you find any questions regarding the information provided,
        please contact the Department of Administration, HR Division,
        Records Branch at 475-1213.</td></tr></table>`
        // ...(props?.header ? { headername: props.header } : {}),
        // ...(props?.footerContent?{footerContent:props.footerContent}:{}),
        // ...(props?.headerContent?{headerContent:props.headerContent}:{})
      }
      setGroupDepartmentLoader(true)
      const response = await gg1ReportDownload(payload)
      const filename = `report_${new Date().getTime()}`;
    const blob = await response.data;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    }catch(error){
      ShowError(error?.message??"something went wrong")
    } finally{
      setGroupDepartmentLoader(false)
      setDepartmentModalContent(null)
    }
  };
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"VW_OVERDUE_PROBATIONARY_REPORT"}
                    searchParams={searchParams}
                    additionalWhere={additionalWhere}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                  />

                  <Accordion expanded={expandedSearchResult}>
                    <AccordionDetails>
                      {/* {loadingSearch && loadingPanel} */}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            key={report}
                            id={id}
                            pageSize={pageSize}
                            // totalCount={rowCount}
                            groupDepartment={true}
                            groupDepartmentLoader={groupDepartmentLoader}
                            setGroupDepartmentLoader={setGroupDepartmentLoader}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            sortData={sortData}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            // actions={actions}
                            searchParams={searchParams}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                            setDepartmentModalContent={handleModalContent}
                            departmentModalContent={departmentModalContent} //this is row data group by a field
                            group={groupConfig}
                          ></TelerikDataTable>
                        )}
                      </div>
                      <div>
                        <Modal
                          open={dialogOpen}
                          onClose={handleCloseDialog}
                          aria-labelledby="modal-title"
                          aria-describedby="modal-description"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "fit-content",
                              maxWidth: "75%",
                              maxHeight: "98%",
                              overflowY: "auto",
                              backgroundColor: "white",
                              boxShadow: 24,
                              p: 4,
                              borderRadius: 4,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                                padding: "15px",
                                position: "sticky",
                                top: "0",
                                zIndex: "9999",
                                backgroundColor: "white",
                              }}
                            >
                              <Typography variant="h6" id="modal-title">
                                Overdue Probationary Notice
                              </Typography>
                              <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleCloseDialog}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div>
                              {departmentModalContent && (
                                <PDFGenerator>
                                  <Report rowData={departmentModalContent} />
                                </PDFGenerator>
                              )}
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
}
export default OverdueProbationary;
