import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../ErrorBoundry";
import TelerikDataTable from "../../CommonComponents/TelerikDataTable";
import { useAuth } from "oidc-react";
import { filterAllowedColumn } from "../../_helper/helper";
import { Btn } from "../../AbstractElements";
import { faPlusLarge } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDocumentUpload from "../DocumentUpload";
import { Icon } from "@progress/kendo-react-common";
import { ExpandMore } from "@mui/icons-material";
const DataTable = ({
  title,
  tableName,
  location,
  isLoading,
  sort = [],
  actions = [],
  visileColumns,
  deleteData,
  additionalFilter,
  showDocumentUpload,
  isDashboard = true,
  referenceId = null,
  sourceName = "",
  employeeId = null,
  moreGridTool,
  arr,
  defaultSearchParams = [],
  isShowActionCheckBox = false,
  setSelectedCheckBoxed,
  clearSelectedCheckbox,
  checkboxKey,
  isTransferToPRButtonVisible = false,
  selectDocuments,
  handleTransferToPRButton,
  docSelectionON = false,
  approvalBtn = false,

  initialGridParams = {},
  ...props
}) => {
  const { userData } = useAuth();

  const {
    columns,
    loadingSearch,
    searchParameter,
    ColumnsSorted,
    searchResults,
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const id = tableName || "document";
  const { searchResultsV2 } = useSelector(
    (state) => state.advanceSearchv2Reducer
  );
  const { filter: storeFilter, pageSize: storePageSize } = useSelector(
    (state) => state.CommonReducer
  );
  const { results, rowCount } =
    searchResultsV2[id] == undefined ? { results: [] } : searchResultsV2[id];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(storePageSize[id] || 100);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [sortData, setSortData] = useState(sort);
  const [additionalWhere, setAdditionalWhere] = useState(additionalFilter);
  const [chooseFilterMethod, setChooseFilterMethod] = useState(
    props.chooseFilterMethod || "normalSearch"
  );
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);

  const [searchParams, setSearchParams] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);

  useEffect(() => {
    if (additionalFilter != "") {
      setAdditionalWhere(additionalFilter);
    }
  }, [additionalFilter]);

  useEffect(() => {
    if (ColumnsSorted[id] !== undefined) {
      setSortedColumns(ColumnsSorted[id]);
    } else {
      if (columns && visileColumns.length > 0 && columns.length > 0) {
        setSortedColumns(filterAllowedColumn(userData, visileColumns), "id");
      }
    }
  }, [columns]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };

  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    // setPageSize(100);
  };

  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    // setPageSize(100);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);

  // useEffect(() => {
  //   if (searchParameter[id]?.searchParams?.length > 0) {
  //     setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
  //   }
  // }, [searchParameter]);

  useEffect(() => {
    setSearchParams(defaultSearchParams);
  }, [defaultSearchParams]);

  return (
    <ErrorBoundary>
      <Fragment>
        <Container fluid={true}>
          {!!title && (
            <Row className="bg-primary rounded py-3 mb-3">
              <Typography variant="h5">
                <Icon
                  style={{ fontSize: "25px", margin: "10px" }}
                  name="file-txt"
                />
                {/* <i class="fa-duotone fa-file-invoice m-r-10"></i> */}
                {title}
              </Typography>
            </Row>
          )}
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location={location}
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={tableName}
                    searchParams={defaultSearchParams}
                    additionalWhere={additionalWhere}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    deleteData={deleteData}
                    chooseFilterMethod={chooseFilterMethod}
                    setChooseFilterMethod={setChooseFilterMethod}
                    forceParams={searchParams}
                  />

                  <Accordion expanded={expandedSearchResult}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      className="bg-light"
                      onClick={() =>
                        setexpandedSearchResult(!expandedSearchResult)
                      }
                    >
                      <CustomDocumentUpload
                        open={openDailog}
                        handleClose={() => setOpenDailog(false)}
                        modalTitle={"Upload Document"}
                        maxWidth="lg"
                        fullWidth={true}
                        referenceId={referenceId}
                        sourceName={sourceName}
                        employeeId={employeeId}
                        docSelectionON={docSelectionON}
                        approvalBtn={approvalBtn}
                      />
                      <div className={"d-flex gap-2"}>
                        {showDocumentUpload && (
                          <Btn
                            attrBtn={{
                              color: "primary",
                              onClick: (e) => {
                                e.stopPropagation();
                                setOpenDailog(true);
                              },
                            }}
                          >
                            <Icon style={{ fontSize: "25px" }} name="plus" />
                            Upload Document
                          </Btn>
                        )}
                        {isTransferToPRButtonVisible && (
                          <Btn
                            className="ml-2"
                            attrBtn={{
                              color: "primary",
                              onClick: (event) => {
                                event?.stopPropagation();
                                handleTransferToPRButton(event);
                              },
                            }}
                          >
                            Move to PR
                          </Btn>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {sortedColumns.length > 0 &&
                          searchParams[0]?.value !== "" && (
                            <TelerikDataTable
                              id={id}
                              isDashboard={isDashboard}
                              pageSize={pageSize}
                              totalCount={rowCount}
                              skip={(page - 1) * pageSize}
                              take={pageSize}
                              data={results || []}
                              sortData={sortData}
                              onFilterChange={handleFilterChange}
                              onSortChange={handleSortChange}
                              onPageChange={handlePageChange}
                              onPageSizeChange={handlePageSizeChange}
                              selectedColumns={sortedColumns}
                              setSortedColumns={setSortedColumns}
                              loading={loadingSearch || isLoading}
                              actions={actions}
                              handleClick={() => {}}
                              chooseFilterMethod={chooseFilterMethod}
                              moreGridTool={moreGridTool}
                              arr={arr}
                              setChooseFilterMethod={setChooseFilterMethod}
                              isEmployeeManagement={false}
                              isShowActionCheckBox={isShowActionCheckBox}
                              setSelectedCheckBoxed={setSelectedCheckBoxed}
                              clearSelectedCheckbox={clearSelectedCheckbox}
                              checkboxKey={checkboxKey}
                              initialGridParams={initialGridParams}
                              hideAllGridToolBar={true}
                            />
                          )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
};

export default DataTable;
