import { 
    NPAPOPUP,
    NPAPOPUP_SUCCESS,
    NPAPOPUP_FAIL,
    NPA_ACTION_DELETE,
    NPA_ACTION_DELETE_FAIL,
    NPA_ACTION_DELETE_SUCCESS,
} from "./actiontype";

const initialState={
    loading:false,
    popupList:[],
    postingResult:{},
    error: {
     message: "",
    },
}
const NpaPopupReducer = (state=initialState,action)=>{
    if (!action)
     return state;
    switch (action.type){
        case NPAPOPUP:{
            state= {...state,payload:action.payload,loading:true};
            break;
        }
            
        case NPAPOPUP_SUCCESS:
            {
            state={...state,popupList:action.payload,loading:false};
            break;
        }
        case NPAPOPUP_FAIL:
            {
            state={...state,popupList: [],loading:false};
            break;
        }
        case NPA_ACTION_DELETE:
            {
            state = { ...state, loading: true };
            break;
        }
        case NPA_ACTION_DELETE_SUCCESS:
            {
            state = { ...state, loading: false };
            break;
        }
        case NPA_ACTION_DELETE_FAIL:
            {
            state = {
                ...state,
                error: {
                message: "Error",
                },
                loading: false,
            };
            break;
        }
        default:{
            state= {...state};  
            break;  
        }
             
    }
    return state;
}
export default NpaPopupReducer;