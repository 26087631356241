import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { findEmployeeVerification } from "../../../store/employees/actions";
import { formatNumber } from "@telerik/kendo-intl";
import { Btn } from "../../../AbstractElements";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { loadingPanel } from "../../../CommonComponents/CommonFunction";
import { H6, H5 } from "../../../AbstractElements";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useForm, Controller } from "react-hook-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { advanceSearchTotalSVC } from "../../../store/advanceSearchv2/actions";
import Header from "../../Reports/Header";
import index from "./index.css";
import dayjs from "dayjs";
import EmployeeSignature from "../../../CommonComponents/EmployeeSignature";
import { ShowError } from "../../../_helper/helper";
import {
  employeeservicerequest,
  generateDocumentForSignature,
  resetDocumentSignatureData,
} from "../../../store/EmployeeSignature/actions";
import { isEmpty } from "lodash";

const Item = styled("div")(({ theme }) => ({
  textAlign: "center",
}));
const P = styled("p")(({ theme }) => ({
  fontSize: "13px",
  fontWeight: 400,
}));
const B = styled("b")(({ theme }) => ({
  fontSize: "12px",
}));
function EmployeeVerification({ setVisible, visible, empId, hrcontrolnumber }) {
  const pdfExportComponent = React.useRef(null);
  const [signatureData, setSignatureData] = useState({});
  console.log("hrcontrolnumber1", hrcontrolnumber);
  const { control, getValues } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });
  const { totalSVC, loadingSearch } = useSelector(
    (state) => state.advanceSearchv2Reducer
  );
  const { isEmployeeSignatureLoading, documentResponse } = useSelector(
    (state) => state?.EmployeeSignatureReducer
  );

  // const { employeeID } = useParams();
  const { employeeVerification, loadingEmployee } =
    useSelector((state) => state?.employeeReducer) || {};
  const isLoading = loadingEmployee || loadingSearch;
  const dispatch = useDispatch();
  const {
    empl_id_no,
    hourlyRate,
    title,
    departmentName,
    jacketNo,
    dob,
    annualSalary,
    workSchedule,
    totalService: { years, months, days } = { years: 0, months: 0, days: 0 },
    priorService,
    budgetNo,
    fullName,
    employeeName_FML,
    status,
    dateTerm,
    employmentType,
    employeeName_LFM,
    dateEmployment,
    dateOfSeparation,
  } = employeeVerification || {};
  const name = employeeName_FML || "N/A";
  const empType = employmentType ? employmentType : "N/A";
  const department = departmentName ? departmentName.trim() : "N/A";
  const positionTitle = title ? title.trim() : "N/A";

  useEffect(() => {
    if (empId) {
      dispatch(findEmployeeVerification(empId));
    }
  }, [empId]);
  const toggleDialog = () => {
    setVisible(!visible);
    dispatch(resetDocumentSignatureData());
  };
  const { yy, mm, dd } = totalSVC[0] || {};

  useEffect(() => {
    if (employeeVerification && Object.keys(employeeVerification).length > 0) {
      const { dateEmployment, priorService, dateTerm } = employeeVerification;
      const dstart = new Date(dateEmployment);
      const yy = priorService?.years;
      const mm = priorService?.months;
      const dd = priorService?.days;

      dstart.setFullYear(dstart?.getFullYear() - yy);
      dstart.setMonth(dstart?.getMonth() - mm);
      dstart.setDate(dstart?.getDate() - dd);
      // const formattedDate = dstart && dstart?.toISOString().split("T")[0];

      dispatch(
        advanceSearchTotalSVC({
          columns: "*",
          pageNumber: 1,
          pageSize: 9999,
          sortData: "",
          qID: 0,
          location: "global",
          id: "TotalSVC",
          tableName: "fn_getdate_year_month_day",
          searchParams: JSON.stringify([
            {
              columnName: "dstart",
              operator: "=",
              value: dayjs(dstart).format("MM/DD/YYYY"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
            {
              columnName: "dend",
              operator: "=",
              value:
                status === "I"
                  ? dateTerm
                  : dayjs(new Date()).format("MM/DD/YYYY"),
              dataType: "dateTime",
              logicOperator: "AND",
            },
          ]),
        })
      );
    }
  }, [employeeVerification]);

  /*useEffect(() => {
        if(!isEmpty(documentResponse) && documentResponse.status === 200) {
            if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
            }
        }
    }, [documentResponse]);*/
  const handlePrintButton = (e) => {
    try {
      if (!empl_id_no) {
        ShowError("Employee data missing!", {
          position: "top-right",
        });
        return;
      }
      if (!signatureData.signUrl) {
        ShowError("Signature is required for the verification!", {
          position: "top-right",
        });
        return;
      }
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
      /*let gridElement = document.querySelector(".export-PDF-class");
            drawDOM(gridElement, {
              paperSize: ["8.5in", "11in"],
              scale: 0.6
            })
              .then((group) => {
                return exportPDF(group);
              })
              .then((dataUri) => {
                if(dataUri && signatureData.signUrl) {
                  const payload = {
                    documentUrl: dataUri,
                    signReferenceId: signatureData.controlNumber,
                    sourceName: 'VOE',
                    employeeId: empl_id_no
                  }
                  dispatch(generateDocumentForSignature(payload))
                }
              });*/
      e.stopPropagation();
    } catch (e) {
      console.log("Error", e);
    }
  };
  const publicUrl = window.location.origin;
  return (
    <>
      {(isLoading || isEmployeeSignatureLoading) && loadingPanel()}
      {visible && (
        <Dialog
          title={
            <Btn
              attrBtn={{
                color: "primary",
                onClick: handlePrintButton,
                style: {
                  marginBottom: "10px",
                },
              }}
            >
              Print
            </Btn>
          }
          onClose={toggleDialog}
          className="empVerification"
        >
          <PDFExport
            forcePageBreak=".page-break"
            paperSize={["8.5in", "11in"]}
            ref={pdfExportComponent}
            margin={"0"}
            scale={0.6}
            // paperSize="auto"
            fileName={`Report for ${new Date().getFullYear()}`}
          >
            {" "}
            <Card className="container export-PDF-class">
              {/* <CardHeader> */}
              <div style={{ marginTop: "35px" }}>
                {" "}
                <Header />
              </div>
              <Grid container style={{ marginTop: "40px" }}>
                {" "}
                <Grid xs={12} sm={9}></Grid>
                <Grid xs={12} sm={3} style={{ textAlign: "center" }}>
                  {" "}
                  <b style={{ fontSize: "15px" }}>
                    {dayjs(new Date()).format("MM/DD/YYYY")}
                  </b>
                </Grid>
              </Grid>
              {/* </CardHeader> */}
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 0,
                }}
              >
                <H6 attrH5={{ style: { fontWeight: "bold" } }}>
                  {/* <FontAwesomeIcon
                       className="m-r-10 font-primary"
                       icon={faUser}
                       size="lg"
                     /> */}
                  VERIFICATION OF EMPLOYMENT{" "}
                </H6>
              </CardHeader>
              <CardBody>
                <Grid
                  container
                  style={{
                    border: "1.5px solid black",
                    borderBottom: 0,
                  }}
                >
                  <Grid item xs={12} sm={4}>
                    <Item>
                      <B>EMPL ID NO:</B>{" "}
                    </Item>
                    <Item>
                      {" "}
                      <P>{empl_id_no}</P>{" "}
                    </Item>
                  </Grid>
                  <Grid xs={12} sm={4}>
                    <Item>
                      <B>FULL NAME:</B>{" "}
                    </Item>
                    <Item>
                      {" "}
                      <P>{employeeName_LFM ? employeeName_LFM : "N/A"}</P>{" "}
                    </Item>
                  </Grid>
                  <Grid xs={12} sm={4}>
                    <Item>
                      <B>DATE OF BIRTH:</B>{" "}
                    </Item>
                    <Item>
                      {" "}
                      <P>{dayjs(dob).format("MM/DD/YYYY")}</P>{" "}
                    </Item>
                  </Grid>
                </Grid>
                <Grid container style={{ border: "1px  solid black" }}>
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    style={{
                      border: "1px  solid black",
                      borderBottom: 0,
                    }}
                  >
                    <Grid
                      container
                      style={{ height: "40px", alignItems: "center" }}
                    >
                      <Grid xs={12} sm={4}>
                        <Item>
                          <B>Employee Status:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {status !== null &&
                            status !== undefined &&
                            status !== ""
                              ? status === "A"
                                ? "Active"
                                : "InActive"
                              : "N/A"}
                          </P>
                        </Item>
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <Item>
                          <B>Appointment Type:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>{empType}</P>{" "}
                        </Item>
                      </Grid>
                      <Grid xs={12} sm={4}>
                        <Item>
                          <B>Work Schedule:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {workSchedule !== null &&
                            workSchedule !== undefined &&
                            workSchedule !== ""
                              ? workSchedule
                              : "N/A"}
                          </P>{" "}
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid container style={{ alignItems: "center" }}>
                      <Grid xs={12} sm={6}>
                        <Item>
                          <B>Current Employment Date:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {dateEmployment
                              ? dayjs(dateEmployment).format("MM/DD/YYYY")
                              : "N/A"}
                          </P>{" "}
                        </Item>
                      </Grid>
                      <Grid xs={12} sm={6}>
                        <Item>
                          <B>Date of Separation:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {dateOfSeparation !== null
                              ? dayjs(dateOfSeparation).format("MM/DD/YYYY")
                              : null}
                          </P>{" "}
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={3}
                    style={{
                      border: "1px solid black",
                      borderBottom: 0,
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span> FOR OFFICE USE ONLY</span>
                    </div>
                    <Grid
                      container
                      style={{ height: "40px", alignItems: "center" }}
                    >
                      <Grid xs={12} sm={4}>
                        <Item>
                          <B>Jacket No:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {jacketNo !== null &&
                            jacketNo !== undefined &&
                            jacketNo !== ""
                              ? jacketNo
                              : "N/A"}
                          </P>{" "}
                        </Item>
                      </Grid>
                      <Grid xs={12} sm={8}>
                        <Item>
                          <B>Budget No:</B>{" "}
                        </Item>
                        <Item>
                          {" "}
                          <P>
                            {budgetNo !== null &&
                            budgetNo !== undefined &&
                            budgetNo?.trim() !== ""
                              ? budgetNo
                              : "N/A"}
                          </P>{" "}
                        </Item>
                      </Grid>{" "}
                    </Grid>
                    {/* <Grid
                          container
                          style={{ height: "60px", alignItems: "center" }}
                        >
                          <Grid xs={12} sm={12}>
                            <Item>
                              <B>Produced By:</B>{" "}
                            </Item>
                            <Item>
                              {" "}
                              <P>{employeeData?.status}</P>{" "}
                            </Item>
                          </Grid>
                        </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    border: "1px  solid black",
                    borderTop: 0,
                  }}
                >
                  <Grid xs={12} sm={9}>
                    {" "}
                    <Grid
                      container
                      style={{
                        border: "1px  solid black",
                        height: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs={12} sm={12}>
                        <Item>
                          <B>Dept/Division Name:</B>
                        </Item>
                        <Item>
                          <P>{department}</P>
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        border: "1px  solid black",
                        height: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs={12} sm={12}>
                        <Item>
                          <B>Current Position Title:</B>
                        </Item>
                        <Item>
                          <P>{positionTitle}</P>
                        </Item>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        border: "1px  solid black",
                        height: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs={12} sm={12}>
                        <Item>
                          <B>Prior Service: Years/Months/Days</B>
                        </Item>
                        <Item>
                          <P>
                            {priorService === null
                              ? "N/A"
                              : `${
                                  priorService?.years === null
                                    ? 0
                                    : priorService?.years
                                } /${
                                  priorService?.months === null
                                    ? 0
                                    : priorService?.months
                                } /${
                                  priorService?.days === null
                                    ? 0
                                    : priorService?.days
                                } `}
                          </P>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={3}>
                    <Grid
                      container
                      style={{
                        height: "90px",
                        border: "1px  solid black",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs={12} sm={12}>
                        <Grid
                          container
                          style={{ height: "45px", alignItems: "center" }}
                        >
                          <Grid item xs={12}>
                            <Item>
                              <B>Salary RATE:</B>
                            </Item>{" "}
                            {/* <Item>
                              <P>
                                $
                                {hourlyRate !== null &&
                                hourlyRate !== undefined &&
                                hourlyRate !== ""
                                  ? hourlyRate
                                  : "N/A"}
                              </P>
                            </Item> */}
                          </Grid>
                        </Grid>

                        <Grid container style={{ height: "45px" }}>
                          <Grid xs={12} sm={6}>
                            <Item>
                              <B>Hourly Rate</B>
                            </Item>
                            <Item>
                              <P>
                                $
                                {hourlyRate !== null &&
                                hourlyRate !== undefined &&
                                hourlyRate !== ""
                                  ? hourlyRate
                                  : "N/A"}
                              </P>{" "}
                            </Item>
                          </Grid>
                          <Grid xs={12} sm={6}>
                            <Item>
                              <B> Annual Rate</B>
                            </Item>
                            <Item>
                              <P>
                                {annualSalary !== null &&
                                annualSalary !== undefined &&
                                annualSalary !== ""
                                  ? formatNumber(Number(annualSalary), "c2")
                                  : "N/A"}
                              </P>
                            </Item>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        height: "90px",
                        border: "1px  solid black",
                        alignItems: "center",
                      }}
                    >
                      <Grid xs={12} sm={12}>
                        <Item>
                          {" "}
                          <B>TOTAL YEARS OF SERVICE</B>
                        </Item>
                        <Item>
                          {" "}
                          <P>{`${yy} Yrs ${mm} Mons ${dd} Days`}</P>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
              <CardBody>
                {/* <P> */}
                {`This verification of employment certifies that ${name} is currently employed under a/n ${empType} as an ${`${positionTitle}`} with the ${department}, Government of Guam. If you have any questions regarding this verification, please contact the Department of Administration, Human Resources Division at 475-1213 or 475-1186.`}
                {/* This verification of employment certifies that SIAS L. SUAREZ
                  is currently employed under a/n Limited Term Appointment as
                  a/n CLERK III with the GUAM ENV. PROTECTION AGENCY, Government
                  of Guam. If you have any questions regarding this
                  verification, please contact the Department of Administration,
                  Human Resources Division 671 475-1213 671 475-1186 */}
                {/* </P> */}
              </CardBody>
              {/* QR code on the right */}
              <Grid container style={{ margin: "7px 0" }}>
                {/*<Grid container style={{ margin: "7px 0" }}>*/}
                <Grid item xs={12} sm={9}></Grid>
                <Grid item xs={12} sm={3} full>
                  <EmployeeSignature
                    sourceName={"VOE"}
                    signatureData={signatureData}
                    setSignatureData={setSignatureData}
                    employeeId={empl_id_no}
                    printRef={pdfExportComponent}
                    // onDocumentSignComplete={()=>{
                    // // hrcontrol ,documentferfertID, currentprocesstauts =0

                    //   // alert('Completed')
                    //   employeeservicerequest()
                    // }}
                    onDocumentSignComplete={(processedReferenceId) => {
                      debugger;
                      dispatch(
                        employeeservicerequest({
                          hrControl: hrcontrolnumber,
                          processedReferenceId: processedReferenceId,
                          currentprocesstauts: 0,
                        })
                      );
                    }}
                    // hrControl={hrcontrolnumber}
                    // refControlNumber={empl_id_no}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      marginLeft: "10px",
                      borderTop: "1px solid black",
                    }}
                  >
                    <B>For: APPOINTING AUTHORITY</B>
                    <B>Assistant Personnel Services Administrator,</B>
                    <B>HR Division</B>
                  </div>
                </Grid>
              </Grid>
              {/* <div
                style={{]
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "end",
                  borderBottom: "1px  solid black",
                }}
              ></div> */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Grid item xs={12} sm={3}> */}
                {/* <QRCodeCanvas 
             // value={userData?.profile?.EmployeeID} 
             value={`hr.dev.ehrpweb.com/employeeVerification/${employeeID}`}
        
             id={"profile-qr"} width={400} height={135} /> */}
                {/* <QRCodeCanvas
                        value={`${publicUrl}/employeeVerification/${employeeID}`}
                        width={400}
                        height={135}
                      /> */}
                {/* </Grid> */}
                {/*<div*/}
                {/*  style={{*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    textAlign: "center",*/}
                {/*    marginLeft: "10px",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <B>For: SHANE G.L NGATA</B>*/}
                {/*  <B>Personnel Service Administrator,</B>*/}
                {/*  <B>HR Division</B>*/}
                {/*</div>*/}
              </div>
              <div
                style={{
                  border: "1px solid gray",
                  marginTop: "12px",
                  textAlign: "center",
                  paddingRight: "15px",
                }}
              >
                <P>
                  PURSUANT TO 9 GCA SUBSECTION 55.10, IT IS CONSIDERED A FELONY
                  TO KNOWINGLY MAKE A FALSE ALTERATION OF A DOCUMENT RECEIVED BY
                  THE GOVERNMENT IN ORDER TO DEFRAUD OR INJURE SOMEONE.{" "}
                </P>
              </div>
            </Card>{" "}
          </PDFExport>
        </Dialog>
      )}
    </>
  );
}
export default EmployeeVerification;
