import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../_helper/helper';

import {
  GET_LOOKUP_POSITIONS, GET_POSITIONS, GET_POSITIONS_DETAILS, POSITION_CREATE, POSITION_DELETE,
  POSITION_UPDATE
} from "./actionTypes";

import {
  getPositionsSuccess,
  getPositionsFail,
  getPositionsDetailsSuccess,
  getPositionsDetailsFail,
  createPositionSuccess,
  createPositionFail,
  updatePositionSuccess,
  updatePositionFail,
  deletePositionSuccess,
  deletePositionFail,
  getLookupPositionsSuccess,
  getLookupPositionsFail,
} from "./actions";

import {
  createPosition, deletePosition,
  getLookupPositions,
  getPositionDetails, getPositions
  , updatePosition,
} from "../../_helper/services/position";
function* onGetPositions({ payload: { page, pageSize } }) {
  try {
    const response = yield call(getPositions, { page, pageSize });
    yield put(getPositionsSuccess(response));
  } catch (error) {
    yield put(getPositionsFail(error.response));
  }
}

function* onGetLookupPositions() {
  try {
    const response = yield call(getLookupPositions);
    yield put(getLookupPositionsSuccess(response));
  } catch (error) {
    yield put(getLookupPositionsFail(error.response));
  }
}

function* onGetPositionDetails({ payload: id }) {
  try {
    let response = yield call(getPositionDetails, id);
    response.npositionNo = response?.npositionNo?.trim()
    yield put(getPositionsDetailsSuccess(response));
  } catch (error) {
    yield put(getPositionsDetailsFail(error.response));
  }
}
function* onCreatePosition({ payload }) {
  try {
    const response = yield call(createPosition, payload);
    yield put(createPositionSuccess(response));
    ShowSucess("Position Created Successfully", {
      position: "top-right"
    })
  } catch (error) {
    yield put(createPositionFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}
function* onUpdatePosition({ payload: { id }, payload }) {
  try {
    const response = yield call(updatePosition, id, payload);
    yield put(updatePositionSuccess(response));
    ShowSucess("Position Details Updated Successfully", {
      position: "top-right"
    })
  } catch (error) {
    yield put(updatePositionFail(error.response));
    ShowError(error?.response?.data?.message);
    // console.log(error.response)
    // const { status, statusText } = error.response;
    // const errorMessage = `${status} - ${statusText}`;
    //     toast.error(errorMessage, {
    //       position: 'top-right'
    //     });
  }
}
function* onDeletePosition({ payload: ID }) {
  try {
    const response = yield call(deletePosition, ID);
    yield put(deletePositionSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(deletePositionFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* CartSaga() {
  yield takeLatest(GET_POSITIONS, onGetPositions);
  yield takeLatest(GET_LOOKUP_POSITIONS, onGetLookupPositions);
  yield takeLatest(GET_POSITIONS_DETAILS, onGetPositionDetails);
  yield takeLatest(POSITION_CREATE, onCreatePosition);
  yield takeLatest(POSITION_UPDATE, onUpdatePosition);
  yield takeLatest(POSITION_DELETE, onDeletePosition);
}

export default CartSaga;