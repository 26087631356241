import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignaturePad from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import {
  addUpdateSignature, clearAddUpdateSignatureResponse,
  getAllEmployeeSignatures, getEmployeeSignatureByControlNumber, unAssignSignature,
} from "../../store/EmployeeSignature/actions";
import { LoadingPanel } from "../CommonFunction";
import { Btn } from "../../AbstractElements";
import MenuItem from "@mui/material/MenuItem";
import { getDocumentSources } from "../../store/DocumentUpload/actions";

const EmployeeSignatureModal = ({
  signatureData,
  openCanvas,
  setOpenCanvas,
  sourceName,
  refControlNumber,
  isEditMode = false,
  handleSetSignature,
  operation = 'add',
}) => {
  let sigPad = {};
  const [signatureDetails, setSignatureDetails] = useState({});
  const [error, setError] = useState({});
  const fileInputRef = useRef(null);

  const { isEmployeeSignatureLoading, signatures, isSignatureFetching } = useSelector(
    (state) => state?.EmployeeSignatureReducer
  );
  const { loading: isSourceLoading, documentSources } = useSelector(
    (state) => state?.DocumentUploadReducer
  );

  const closeCanvas = () => {
    dispatch(clearAddUpdateSignatureResponse());
    setOpenCanvas(false);
  };

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    signText: Yup.string().required("Title is required"),
    description: Yup.string()
        .required("Description is required")
        .max(255, 'Description max length exceeded!!!'),
    signUrl: Yup.string()
      .required("Sign URL is required")
      .test({
        name: "is-valid-sign-url",
        message: "Sign URL must match the specified value",
        test: (value) =>
          value !==
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC",
      }),
  });

  const {addUpdateSignatureResponse, getSignatureByControlNumberResponse, unAssignSignatureResponse} = useSelector((state) => state?.EmployeeSignatureReducer);

  useEffect(() => {
    if(!isEmpty(addUpdateSignatureResponse)) {
      if(!isEditMode) {
        handleSetSignature({ ...addUpdateSignatureResponse })
      }
      setTimeout(() => closeCanvas(), 10);
    }
  }, [addUpdateSignatureResponse])

  useEffect(() => {
    if(!isEmpty(unAssignSignatureResponse)) {
      if(!isEditMode && unAssignSignatureResponse.status === 200) {
        handleSetSignature({})
      }
      setTimeout(() => closeCanvas(), 10);
    }
  }, [unAssignSignatureResponse])

  useEffect(() => {
    if(!isEmpty(getSignatureByControlNumberResponse)) {
      if(!isEditMode) {
        handleSetSignature({ ...getSignatureByControlNumberResponse })
      }
      setTimeout(() => closeCanvas(), 10);
    }
  }, [getSignatureByControlNumberResponse])

  useEffect(() => {
    if(!isEditMode) {
      !signatures.length && dispatch(getAllEmployeeSignatures());
      !documentSources.length && dispatch(getDocumentSources());
    }
  }, [signatures, documentSources]);

  useEffect(() => {
    if (signatureData?.id) {
      setSignatureDetails({ ...signatureData });
    }
  }, [signatureData]);

  const trimSign = () => sigPad?.getTrimmedCanvas?.()?.toDataURL("image/png");

  const clearCanvas = () => {
    sigPad.clear?.();
    setSignatureDetails(prev => ({ ...prev, signUrl: "" }));
    setError({});
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(acceptedFiles[0]);
      fileReader.onloadend = () => {
        setSignatureDetails((prev) => {
          return {
              ...prev,
              signUrl: fileReader.result || "",
            };
          });
      };
      fileReader.onerror = (error) => () => {
        console.log("Error while uploading image");
      };
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSignatureDetails({ ...signatureDetails, [name]: value });
  };
  const handleOptionChange = (event) => {
    setError({});
    if (event.target.value) {
      const selectedSignature = signatures.find(
        (value) => value.id === event.target.value
      );
      setSignatureDetails({ ...selectedSignature });
    } else {
      setSignatureDetails({});
    }
  };

  const handleRemoveSignature = () => {
    if(window.confirm('Are you sure want to remove signature?')) {
      const { refControlNumber, controlNumber } = signatureDetails;
      dispatch(unAssignSignature({ refControlNumber, refSign: controlNumber }));
    }
  }

  const handleSubmit = async () => {
    try {
      if (!signatureData.id || isEditMode || signatureDetails.id !== signatureData.id) {
        const trimmedSign = trimSign();
        const signDetails = {
          ...signatureDetails,
          signUrl: !isEmpty(signatureDetails.signUrl)
              ? signatureDetails.signUrl
              : trimmedSign || "",
        };
        if(isEditMode) {
          await validationSchema.validate(signDetails, { abortEarly: false });
          setError({});
        }
        let payload = {
          ...signDetails,
          signText: signDetails.signText.trim()
        };
        if(!isEditMode) {
          payload = {
            ...payload,
            sourceId: documentSources.find(
                (item) => item?.sourceName?.toLowerCase() === sourceName?.toLowerCase()
            )?.id || null,
            refControlNumber: refControlNumber || null,
          }
        }
        if(!isEditMode && !payload.refControlNumber) {
          if(window.confirm('Are you sure want to use this signature?')) {
            dispatch(getEmployeeSignatureByControlNumber(signatureDetails.controlNumber))
          }
        } else {
          const addUpdatePayload = { ...payload };
          delete addUpdatePayload.statusTags;
          dispatch(addUpdateSignature(addUpdatePayload));
        }
      } else {
        closeCanvas();
      }
    } catch (error) {
      const validationErrors = {};
      error?.inner?.forEach((fieldError) => {
        validationErrors[fieldError.path] = fieldError.message;
      });
      setError(validationErrors);
      return { validationErrors };
    }
  };

  const customDropDown = useMemo(() => {
    return (
      <FormControl size="small">
        <InputLabel>Select Signature</InputLabel>
        <Select
          id="demo-select-small"
          value={signatureDetails?.id || ""}
          renderValue={(value) => {
            return <span>{signatures.find(option => option.id === value)?.signText || ''}</span>
          }}
          label="Select Signature"
          className="signature-dropdown"
          placeholder="Select Signature"
          onChange={handleOptionChange}
        >
          {(signatures || []).map((option) => {
            return (
              <MenuItem
                key={option.id}
                value={option.id}
                className="d-flex justify-content-between"
              >
                <span>{option.signText}</span>
                <span>{option.seq}</span>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }, [signatureDetails?.id, signatures]);

  return (
    <Dialog
      className="employee-signature-modal"
      open={openCanvas}
      onClose={closeCanvas}
      PaperProps={{
        sx: {
          minWidth: "600px",
          maxWidth: "600px"
        },
      }}
    >
      <DialogTitle className="d-flex justify-content-between">
        <span>{isEditMode ? 'Add/Update your signature': 'Select Signature'}</span>
        <IconButton onClick={closeCanvas}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {(isEmployeeSignatureLoading || isSourceLoading || isSignatureFetching) && <LoadingPanel />}
        <div className="py-1 d-flex flex-column gap-2">
          {signatures.length && !isEditMode ? customDropDown : null}
          <TextField
            margin="dense"
            className="flex w-full"
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            name="signText"
            inputProps={{
              shrink: true,
              style: {
                fontSize: "14px",
                color: "black",
              },
            }}
            placeholder="Title"
            label="Title"
            value={signatureDetails.signText || ""}
            disabled={!isEditMode}
            onChange={(e) => handleChange(e)}
            error={!!error.signText}
          />
          <TextField
            rows={3}
            multiline
            className="flex w-full"
            style={{ width: "100%" }}
            variant="outlined"
            size="small"
            name="description"
            inputProps={{
              shrink: true,
              style: {
                fontSize: "14px",
                color: "black",
              },
            }}
            disabled={!isEditMode}
            placeholder="Notes"
            label="Description"
            value={signatureDetails.description || ""}
            onChange={(e) => handleChange(e)}
            error={!!error.description}
          />
        </div>
        {!isEditMode && !signatureDetails.signUrl
          ? null
          : (
            <div
              {...getRootProps()}
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: isDragActive ? "#eee" : "#fff",
                minHeight: 331,
                minWidth: 530,
                borderColor: error.signUrl ? "red" : "#ccc",
              }}
            >
              <input {...getInputProps()} ref={fileInputRef}/>
              {isEditMode && operation === 'add' && (
                <div className="d-flex align-items-center">
                  <p onClick={() => fileInputRef.current.click()}>
                    Drag & drop some images here, or click to select files, or draw your
                    signature below.
                  </p>
                  <Btn
                    attrBtn={{
                      color: "danger",
                      onClick: clearCanvas,
                    }}
                  >
                    Clear
                  </Btn>
                </div>
              )}
              {signatureDetails?.signUrl ? (
                <img
                  key={signatureDetails.id}
                  src={signatureDetails?.signUrl}
                  alt="Signature"
                  style={{maxWidth: 450, maxHeight: 220, margin: "10px"}}
                />
              ) : isEditMode && (
                <SignaturePad
                  canvasProps={{className: "sigPad", width: 450, height: 220}}
                  ref={(ref) => {
                    sigPad = ref;
                  }}
                  disabled={!isEditMode}
                />
              )}
            </div>
          )}
      </DialogContent>
      <DialogActions>
        {/*{!isEditMode && (signatureDetails.id && signatureDetails.id === signatureData.id) && (
            <Btn
                attrBtn={{
                  color: "danger",
                  onClick: handleRemoveSignature,
                }}
            >
              Remove Signature
            </Btn>
        )}*/}
        <Btn
          attrBtn={{
            color: "primary",
            onClick: handleSubmit,
          }}
        >
          <div>
            <FontAwesomeIcon icon={faSave}/>
            <span>
              {isEditMode ? signatureDetails.id ? " Update" : " Save" : " Use This Signature"}
            </span>
          </div>
        </Btn>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeSignatureModal;
