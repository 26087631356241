import React, { Fragment, useEffect, useState, useContext } from "react";
import SidebarMenu from "./SidebarMenu";
import CustomizerContext from "../../_helper/Customizer";
import { sidebarmenu } from "./Menu";
import Profile from "./Profile";
import { useDispatch, useSelector } from "react-redux";
import { getsidebarmenudata,getsidebarsidebarmenu } from "../../store/sidebarmenu/actions";

const SideBarLayout = (props) => {
  const { toggleIcon } = useContext(CustomizerContext);
  const [currentUrl] = useState(window.location.pathname);
  const id = window.location.pathname.split("/").pop();
  // eslint-disable-next-line
  const [leftArrow, setLeftArrow] = useState(false);
  const layout = id;
  const [width, setWidth] = useState(0);

  const dispatch = useDispatch()
  const { sidebarmenu, loading,  } = useSelector(
    (state) => state.SideBarMenuReducer
  );
  // useEffect(()=>{
  //   dispatch(getsidebarmenudata())
  // },[dispatch])
  // useEffect(() => {
  //   dispatch(getsidebarmenudata());
  // }, [dispatch]);
  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }; // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState([]);
  useEffect(() => {
    setMainMenu(sidebarmenu);
  }, [sidebarmenu]);
  const handleScroll = () => {
    if (window.scrollY > 400) {
      document.querySelector(".main-navbar").className = "main-navbar hovered";
    } else {
      if (document.getElementById("main-navbar"))
        document.querySelector(".main-navbar").className = "main-navbar";
    }
  };

  const setNavActive = (item) => {
    sidebarmenu.map((sidebarmenu) => {
      sidebarmenu.items.filter((items) => {
        if (items !== item) {
          items.active = false;
          document.getElementById("bg-overlay1").classList.remove("active");
        }
        if (items.children && items.children.includes(item)) {
          items.active = false;
        }
        if (items.children) {
          items.children.filter((subsidebarmenu) => {
            if (subsidebarmenu.children && subsidebarmenu.children.includes(item)) {
              items.active = true;
              subsidebarmenu.active = false;
              return false;
            } else {
              return false;
            }
          });
        }
        return items;
      });
      return sidebarmenu;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: sidebarmenu });
  };
  useEffect(() => {
    setLeftArrow(true);
    window.addEventListener("resize", handleResize);
    handleResize();
    // const currentUrl = window.location.pathname;
    sidebarmenu.map((items) => {
      items.items.filter((items) => {
        if (items.path === currentUrl) setNavActive(items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
      return items;
    });
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [layout, currentUrl]);

  const closeOverlay = () => {
    document.getElementById("bg-overlay1").classList.remove("active");
    document.getElementById("nav-link").classList.remove("active");
  };
  // console.log("This is SideBarLayout");

  return (
    <Fragment>
      <div
        id="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}

      ></div>
      <header className={`main-nav ${toggleIcon ? "close_icon" : ""}`}>
        <Profile />
        <SidebarMenu
          setMainMenu={setMainMenu}
          props={props}
          sidebartoogle={true}
          setNavActive={setNavActive}
          width={width}
        />
      </header>
    </Fragment>
  );
};
export default React.memo(SideBarLayout);
