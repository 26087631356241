import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
export const DropdownFilterCell = (props) => {
    const onChange = (event) => {
        props.onChange({
            value: event.target.value?.value !== 'All' ? event.target.value?.value : "",
            operator: event.target.value?.value !== 'All' ? event.target.value?.condition : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    return (
        <div className="k-filtercell">
            <DropDownList
                size={"small"}
                data={props.options}
                onChange={onChange}
                value={props.options.find(e => e['value'] == (props.selectedVal ?? props.defaultItem))}
                textField="option"
                dataItemKey="value"
                popupSettings={{ width: "auto" }}
            />
        </div>
    );
};