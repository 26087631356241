// import * as React from "react";
// import { Input } from "@progress/kendo-react-inputs";
// export const CustomTextFilter = (props) => {
//   let hasValue = (value) => Boolean(value && value !== props.defaultItem);
//   const onChange = (event) => {
//     const newValue = event.target.value;
//     const valueExists = hasValue(newValue);
  
//     props.onChange({
//       value: valueExists ? newValue : "",
//       operator: valueExists ? "eq" : "",
//       syntheticEvent: event.syntheticEvent,
//     });
//   };
  
//   // const onChange = (event) => {
//   //   hasValue = hasValue(event.target.value);
//   //   props.onChange({
//   //     value: hasValue ? event.target.value : "",
//   //     operator: hasValue ? "eq" : "",
//   //     syntheticEvent: event.syntheticEvent,
//   //   });
//   // };

//   return (
//     <div className="k-filtercell">
//       <input
//         style={{
//           paddingleft: "8px",
//           height: "28px",
//           border: "1px solid"
//         }}
//         data={props.data}
//         onChange={onChange}
//         value={props.value || props.defaultItem}
//         placeholder="Exact match required"
//       />
//     </div>
//   );
// };
import React, { useState } from "react";
import { Input } from "@progress/kendo-react-inputs";

export const CustomTextFilter = (props) => {
  const [timerId, setTimerId] = useState(null);

  const hasValue = (value) => Boolean(value && value !== props.defaultItem);

  const handleChange = (event) => {
    const newValue = event.target.value;

    clearTimeout(timerId);

    // Set a new timer to call onChange after 3 seconds of no input
    const newTimerId = setTimeout(() => {
      const valueExists = hasValue(newValue);
      props.onChange({
        value: valueExists ? newValue : "",
        operator: valueExists ? "eq" : "",
        syntheticEvent: event.syntheticEvent,
      });
    }, 990);

    // Save the new timerId in the component state
    setTimerId(newTimerId);
  };

  return (
    <div className="k-filtercell">
      <input
        style={{
          paddingLeft: "8px",
          height: "28px",
          border: "1px solid",
        }}
        data={props.data}
        onChange={handleChange}
        value={props.value || props.defaultItem}
        placeholder="Exact match req."
      />
    </div>
  );
};
