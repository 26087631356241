import React, { useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@mui/material";

const ToggleButtonFilterCell = (props) => {
    const ref = useRef(null);
    const dropdownObjectOption = props?.options ?? [];

    const handleClick = (event) => {
        const val = dropdownObjectOption.findIndex(obj => obj?.value === ref.current?.props?.value) + 1
        const newVal = val === (props?.options ?? []).length ? 0 : val;
        props.onChange({
            value: newVal !== 0 ? dropdownObjectOption[newVal]?.value : "",
            operator: newVal !== 0 ? dropdownObjectOption[newVal]?.condition : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    return (
        props?.options && <Tooltip title={dropdownObjectOption[dropdownObjectOption.findIndex(obj => obj?.value === props?.selectedVal) ?? 0]?.option} placement="left" style={{ cursor: "pointer" }} arrow>
            <div className="k-filtercell">
                <Button style={{ overflow: 'hidden' }} ref={ref} value={props?.selectedVal} size="small"
                    onClick={handleClick}
                >
                    {dropdownObjectOption[dropdownObjectOption.findIndex(obj => obj?.value === props?.selectedVal) ?? 0]?.option}
                </Button>
            </div>
        </Tooltip>
    );
};

export default React.memo(ToggleButtonFilterCell);