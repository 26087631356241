import {
  GET_SIDEBARMENU, GET_SIDEBARMENU_SUCCESS, GET_SIDEBAR_FAILURE,
  GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILURE,
  CREATE_SIDEBARMENU, CREATE_SIDEBAR_SUCCESS, CREATE_SIDEBAR_FAILURE,
  GET_SIDEBARLOOKUP, GET_SIDEBAR_LIKKUP_SUCCESS, GET_SIDEBARLOOKUP_FAILURE,
  GETBY_ID_SIDEBARMENU, GETBY_ID_SIDBARMENU_SUCCESS, GETBY_ID_SIDEBARMENU_FAILURE,
  DELETE_SIDEBARMENU, DELETE_SIDEBARMENU_SUCCESS, DELETE_SIDEBARMENU_FAILURE,
  UPDATE_SIDEBARMENU, UPDATE_SIDEBARMENU_SUCCESS, UPDATE_SIDEBARMENU_FAILURE,
  GET_MENUITEMS, GET_MENUITEMS_SUCCESS, GET_MENUITEMS_FAILURE,
  UPDATE_SIDEBARMENU_SEQUENCE, UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS, UPDATE_SIDEBARMENU_SEQUENCE_FAILURE,
  RESET,RESET_CREATE_MENU,RESET_DELETE, GET_SIDEBARMENU_BY_ROLE
} from "./actionType";

const initialState = {
  sidebarmenu: [],
  menuItems:[],
  role: [],
  lookup: [],
  createsidebar: {},
  updatesidebar: null,
  deleteMenuResponse:  {},
  sidebarId: {},
  loading: false,
  loadingItems:false,
  updateSeq: {},
  // menuitems: [],
  error: {
    message: "",
  },
}

const SideBarMenuReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {

   

    case
      GET_SIDEBARMENU,
      GET_SIDEBARMENU_BY_ROLE:
      state = { ...state, loading: true }
      break;
    case GET_SIDEBARMENU_SUCCESS:
      state = {
        ...state,
        sidebarmenu: action.payload,
        loading: false,
      }
      break;
    case GET_SIDEBAR_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
case GET_MENUITEMS :
  state  = {...state,loadingItems:true,}
  break;
  case GET_MENUITEMS_SUCCESS:
    state = {
      ...state,
      menuItems : action.payload,
      loadingItems:false
    }
    break;
    case GET_MENUITEMS_FAILURE:
      state ={
        ...state,
        error:{
          message : "Error"
        },
        loadingItems:false
      }
      break
    case GET_ROLES:
      state = { ...state, loading: true }
      break;
    case GET_ROLES_SUCCESS:
      state = {
        ...state,
        role: action.payload,
        loading: false
      }
    case GET_ROLES_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    case GET_SIDEBARLOOKUP:
      state = { ...state, loading: true }
      break;
    case GET_SIDEBAR_LIKKUP_SUCCESS:
      state = {
        ...state,
        lookup: action.payload,
        loading: false
      };
      break;
    case GET_SIDEBARLOOKUP_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    // getbyid
    case GETBY_ID_SIDEBARMENU:
      state = { ...state, loading: true, }
      break;
    case GETBY_ID_SIDBARMENU_SUCCESS:
      state = {
        ...state,
        sidebarId: action.payload,
        loading: false,
      }
      break;
    case GETBY_ID_SIDEBARMENU_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    // for update
    case UPDATE_SIDEBARMENU:
      state = { ...state, loading: true, }
      break;
    case UPDATE_SIDEBARMENU_SUCCESS:
      state = {
        ...state,
        updatesidebar: action.payload,
        loading: false
      }
      break;
    case UPDATE_SIDEBARMENU_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    case UPDATE_SIDEBARMENU_SEQUENCE:
      state = { ...state, loading: true, updateSeq: {} }
      break;
    case UPDATE_SIDEBARMENU_SEQUENCE_SUCCESS:
      state = {
        ...state,
        updateSeq: action.payload,
        loading: false
      }
      break;
    case UPDATE_SIDEBARMENU_SEQUENCE_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    case CREATE_SIDEBARMENU:
      state = { ...state, createsidebar: {}, loading: true };
      break;
    case CREATE_SIDEBAR_SUCCESS:
      state = {
        ...state, loading: false,
        createsidebar: action.payload,
      };
      break;
    case CREATE_SIDEBAR_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        createsidebar: {},
        loading: false,
      };
      break;
    // for delete
    case DELETE_SIDEBARMENU:

      state = { ...state, deleteMenuResponse:{},  loading: true };

      break;
    case DELETE_SIDEBARMENU_SUCCESS:
      state = {
        ...state,
        deleteMenuResponse: action.payload,
        loading: false
      }
      // return {
      //   ...state,
      //   loading: false,
      // };
      break;
    case DELETE_SIDEBARMENU_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };

      break;
    case RESET:
      state = { ...state, updatesidebar: {} };
      break;
    case RESET_CREATE_MENU:
      state = { ...state, createsidebar: {} };
      break;
      case RESET_DELETE:
        state={...state,deleteMenuResponse:{}}
    default:
      state = { ...state };
      break;

  }
  return state;
}
export default SideBarMenuReducer;

