import moment from "moment";
const SQL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const SQL_DATE_FORMAT = "DD/MM/YYYY";
const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
const mappings = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  endswith: "{0} LIKE '%{1}'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
};
const formatFilter = (mapping, field, value) => {
  return mapping.replace("{0}", field).replace("{1}", value);
};
const formatDateFilter = (mapping, field, value) => {
  return mapping
    .replace("{0}", `CONVERT(DATE,${field})`)
    .replace("'{1}'", `CONVERT(DATE,'${value}')`);
};

export const toSQLExpression = (filter) => {
  if (!filter) return;

  let filters = (filter && filter?.filters) || [],
    field,
    value,
    operator,
    mapping,
    type,
    logic = filter.logic || "AND",
    result = [];

  for (let i = 0; i < filters.length; i++) {
    filter = filters[i];
    field = filter.field;
    value = filter.value;
    operator = filter.operator;

    if (filter.filters) {
      filter = toSQLExpression(filter);
    } else {
      mapping = mappings[operator];
      type = typeof value; ///$.type

      // if (value instanceof Date) {
      //   value =moment(value).format(SQL_DATE_FORMAT) // toString(value.toISOString().slice(0, 19).replace('T', ' '));
      // }
      if (value instanceof Date) {
        filter = formatDateFilter(
          mapping,
          field,
          moment(value).format(SQL_DATE_FORMAT)
        );
      } else {
        filter = formatFilter(mapping, field, value);
      }
    }

    result.push(filter);
  }

  filter = result.join(" " + logic.toUpperCase() + " ");

  if (result.length > 1) {
    filter = "(" + filter + ")";
  }

  return filter;
};
