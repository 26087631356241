import { Icon } from "@progress/kendo-react-common";
import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { Badge, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "./benefit.css";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FileUploadModel from "./FileUploadModel";
const GenderArray = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female",
    value: "female",
  },
];

const RelationArray = [
  {
    name: "Spouse",
    value: "spouse",
  },
  {
    name: "Domestic Partner",
    value: "domesticpartner",
  },
  {
    name: "Child",
    value: "child",
  },
];
const Depedentinformation = (props) => {
  const {
    dependentInformation,
    setDependentInformation,
    renderCheckbox,
    renderDropDown,
    values,
    deletedUid,
    setDeletedUid,
    setClassValues,
    params,
  } = props;
  const [dependentOption, setDependantOPtions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModel] = useState(false);
  const [hrControl, setHrControl] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [data, setData] = useState([
    {
      name: "",
      part_a: "A",
      part_b: "B",
      part_d: "D",
      medicaid: "",
      other_insurance_carrier: "",
      medical: "",
      dental: "",
      effectiveDate: "",
    },
  ]);

  const handleAdd = (index) => {
    let addData = [...dependentInformation];
    let sampleData = {
      lastName: values?.LastName,
      firstName: "",
      middleName: "",
      relation: "",
      ssn: "",
      gender: "Male",
      dob: "",
      mailingAddress: values?.MailingAddress,
      email: values?.Email,
      subscriberId: params?.id,
    };
    addData.splice(index + 1, 0, sampleData);
    setClassValues(addData);
    setDependentInformation(addData);
  };

  const handleRemove = (index) => {
    let removeData = [...dependentInformation];
    let deletedUids = [...deletedUid];
    if (removeData[index]?.uid) {
      deletedUids.push(removeData[index]?.uid);
      setDeletedUid(deletedUids);
    }
    console.log("dsfsgdsgdsdg", index, dependentInformation);

    removeData.splice(index, 1);
    setClassValues(removeData);
    setDependentInformation(removeData);
  };

  const renderCheckbox1 = (index, size = "large", stateName, value) => {
    return (
      <Checkbox
        size={size}
        // style={{width:"200px"}}
        checked={dependentInformation[index][stateName] == value}
        onChange={(e) => {
          let data = [...dependentInformation];
          data[index][stateName] = value;
          setDependentInformation(data);
        }}
      />
    );
  };

  function renderTextInput(
    index,
    stateName,
    label,
    others = {
      disabled: false,
      multiline: false,
      required: false,
      maxLength: "",
      shrink: true,
    }
  ) {
    let other = {
      disabled: false,
      multiline: false,
      required: false,
      shrink: true,
      ...others,
    };

    let { disabled, multiline, required, shrink, width } = other;
    return (
      <TextField
        fullWidth
        size="small"
        inputProps={{
          style: {
            fontSize: "14px",
            paddingTop: "12px",
            width: width,
          },
          maxLength: other.maxLength,
          readOnly: other.disabled,
        }}
        margin="dense"
        variant="outlined"
        label={label}
        name={stateName}
        id={stateName}
        multiline={other.multiline}
        rows={other.multiline ? 3 : 1}
        value={dependentInformation[index][stateName]}
        InputLabelProps={{ shrink: other.shrink ? other.shrink : false }}
        shrink={other.shrink ? other.shrink : false}
        onChange={(e) => {
          let data = [...dependentInformation];
          data[index][stateName] = e.target.value;
          setDependentInformation(data);
        }}
        // onBlur={handleBlur}
        error={
          !disabled && required && !dependentInformation?.[index]?.[stateName]
        }
        helperText={
          values?.HSAANDPPO !== "I"
            ? !disabled &&
              required &&
              !dependentInformation?.[index]?.[stateName] &&
              `${label} is required`
            : null
        }
        disabled={other.disabled}
        required={other.required}
      />
    );
  }

  function renderPasswordInput(
    index,
    stateName,
    label,
    others = {
      disabled: false,
      multiline: false,
      required: false,
      maxLength: "",
      shrink: true,
    }
  ) {
    let other = {
      disabled: false,
      multiline: false,
      required: false,
      shrink: true,
      ...others,
    };

    let { disabled, multiline, required, shrink, width } = other;
    return (
      <FormControl sx={{ mt: 1, width: "155px" }} variant="outlined">
        <InputLabel shrink={true} htmlFor="outlined-adornment-password">
          {label}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          size="small"
          notched={true}
          name={stateName}
          inputProps={{ maxLength: "9" }}
          value={dependentInformation[index][stateName]}
          InputLabelProps={{ shrink: other.shrink ? other.shrink : false }}
          onChange={(e) => {
            let data = [...dependentInformation];
            data[index][stateName] = e.target.value;
            setDependentInformation(data);
          }}
          disabled={other.disabled}
          required={other.required}
          error={
            !disabled && required && !dependentInformation?.[index]?.[stateName]
          }
          helperText={
            values?.HSAANDPPO !== "I"
              ? !disabled &&
                required &&
                !dependentInformation?.[index]?.[stateName] &&
                `${label} is required`
              : null
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
      </FormControl>
    );
  }
  function renderDateInput(
    index,
    stateName,
    label,
    others = { disabled: false, minDate: null }
    // maxDate
  ) {
    let other = {
      disabled: false,
      multiline: false,
      required: false,
      shrink: true,
      minDate: null,
      ...others,
    };

    let { disabled, multiline, required, minDate } = other;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputVariant={"outlined"}
          margin="dense"
          format="MM/dd/yyyy"
          label={label}
          minDate={minDate}
          // maxDate={maxDate}
          value={dependentInformation[index][stateName]}
          KeyboardButtonProps={{ "aria-label": label }}
          disabled={disabled}
          onChange={(value) => {
            let data = [...dependentInformation];
            const formatDate = value?.$d;
            const date = formatDate
              ? moment(formatDate).format("YYYY-MM-DD")
              : null;
            data[index][stateName] = date;
            setDependentInformation(data);
          }}
          // onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin="dense"
              size="small"
              style={{ width: "150px" }}
              InputLabelProps={{ shrink: true }}
              {...params}
              error={
                !disabled &&
                required &&
                !dependentInformation?.[index]?.[stateName]
              }
              helperText={
                values?.HSAANDPPO !== "I"
                  ? !disabled &&
                    required &&
                    !dependentInformation?.[index]?.[stateName] &&
                    `${label} is required`
                  : null
              }
              disabled={other.disabled}
              required={other.required}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  function newRenderDateInput(
    index,
    stateName,
    label,
    handleChange,
    others = {
      disabled: false,
      minDate: "",
      // maxDate
    }
  ) {
    let other = { disabled: false, required: false, minDate: "", ...others };
    let { disabled, minDate, required } = other;
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputVariant={"outlined"}
          margin="dense"
          format="MM/dd/yyyy"
          label={label}
          // minDate={minDate}
          // maxDate={maxDate}
          value={data[index][stateName]}
          KeyboardButtonProps={{ "aria-label": label }}
          disabled={disabled}
          onChange={handleChange}
          // onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin="dense"
              size="small"
              InputLabelProps={{ shrink: true }}
              {...params}
              error={!disabled}
              helperText={!disabled}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  function renderDropDown1(
    index,
    stateName,
    label,
    value,
    others = {
      disabled: false,
      minDate: "",
      // maxDate
    }
  ) {
    let other = {
      disabled: false,
      required: false,
      ...others,
    };

    let { disabled, required } = other;
    return (
      <div>
        <TextField
          margin="dense"
          className="flex w-full  mt-2"
          variant="outlined"
          select
          SelectProps={{ native: true }}
          // style={{ width: "100%" }}
          size="small"
          disabled={disabled}
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
              width: "140px",
            },
          }}
          label={label}
          name={stateName}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            let data = [...dependentInformation];
            data[index][stateName] = e.target.value;
            setClassValues(data);
            setDependentInformation(data);
          }}
          error={
            !disabled && required && !dependentInformation?.[index]?.[stateName]
          }
          helperText={
            values?.HSAANDPPO !== "I"
              ? !disabled &&
                required &&
                !dependentInformation?.[index]?.[stateName] &&
                `${label} is required`
              : null
          }
          value={dependentInformation[index][stateName]}
        >
          {/* <option value={""} disabled> */}
          {/* value={values[stateName]}> */}
          <option value={""}>Select {label}</option>

          {value.length &&
            value.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
        </TextField>
      </div>
    );
  }

  useEffect(() => {
    if (dependentInformation?.length) {
      let dependentOption = dependentInformation?.map((item) => {
        return {
          name: `${item.firstName} ${item.lastName}`,
          value: `${item.firstName} ${item.lastName}`,
        };
      });
      setDependantOPtions(dependentOption);
    }
  }, [dependentInformation]);
  return (
    <Card style={{ padding: "20px", overflow: "auto" }}>
      <table className="benefitTable">
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
            <th>Relation </th>
            <th>SSN</th>
            <th>Gender</th>
            <th>DOB</th>
            <th>Mailing Address</th>

            <th>Email</th>
          </tr>
        </thead>
        {console.log(dependentInformation)}
        {dependentInformation?.map((item, index) => {
          return (
            <tr>
              {/* <td className="midAlign">
                <div style={{ display: "flex" }}>
                  <div>
                    {dependentInformation.length - 1 == index ? (
                      <Icon
                        onClick={() => handleAdd(index)}
                        style={{
                          marginRight: "10px",
                          marginTop: "10px",
                          color: "green",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          border: `1px solid ${"green"}`,
                          borderRadius: "20px",
                        }}
                        className="m-r-10"
                        name="plus-circle"
                      />
                    ) : (
                      <Icon
                        onClick={() => handleRemove(index)}
                        style={{
                          marginRight: "10px",
                          marginTop: "10px",
                          color: "red",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          border: `1px solid ${"red"}`,
                          borderRadius: "20px",
                        }}
                        className="m-r-10"
                        name="minus-circle"
                      />
                    )}
                  </div>
                  <div>
                    
                    <Badge color="secondary" badgeContent={99}>
                    <Tooltip title="Upload Document" placement="bottom">
                      <IconButton
                        onClick={() => {
                          setHrControl(
                            `${item?.ssn?.slice(-4)}${item.subscriberId}`
                          );
                          setOpenModel(true);
                        }}
                        style={{
                          // marginRight: "10px",
                          marginTop: "10px",
                          margin: "5px",
                          color: "primary",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          // border: `1px solid`,
                          borderRadius: "50%",
                          // padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fa-duotone fa-file-arrow-up font-primary"></i>
                      </IconButton>
                    </Tooltip>
                    </Badge>
                  </div>
                </div>
              </td> */}
              <td style={{ minWidth: "100px", padding: "15px 5px 0px 5px", display: "flex" }}>
                {
                  dependentInformation.length != 1 ? <Icon onClick={() => handleRemove(index, item.uid)}
                    style={{
                      // marginRight: "10px",
                      // marginTop: "10px",
                      margin: "5px",
                      color: "red",
                      height: "20px",
                      width: "20px",
                      justifyContent: "center",
                      border: `1px solid ${"red"
                        }`,
                      borderRadius: "20px",
                    }} className=" " name="minus-circle" /> : null
                }
 
                {
                  (dependentInformation.length == 1 || (dependentInformation.length - 1 == index)) ?
                    <Icon onClick={() => handleAdd(index)}
                      style={{
                        // marginRight: "10px",
                        // marginTop: "10px",
                        margin: "5px",
                        color: "green",
                        height: "20px",
                        width: "20px",
                        justifyContent: "center",
                        border: `1px solid ${"green"
                          }`,
                        borderRadius: "20px",
                      }} className="m-r-10" name="plus-circle" /> : null}
                       <Tooltip title={`Upload Document (${item.totalDocument ?  "Total Doc - "+  item.totalDocument : ""},${item.totalDocument ?  "Verified - "+  item.totalVerifiedDoc : ""},${item.totalDocument ?  "Rejected - "+  item.totalRejectedDoc : ""})`} placement="bottom" >
  <Badge color="secondary" badgeContent={item.totalDocument ? parseInt(item.totalDocument): 0} style={{marginRight:"10px"}}>
                {
                  (item.uid ? true : item?.ssn?.length == 9 && item.subscriberId) ?
                   
                      <IconButton
                        onClick={() => {
                          setHrControl(`${item?.ssn?.slice(-4)}${item.subscriberId}`)
                          setOpenModel(true)
                        }}
 
                        style={{
                          // marginRight: "10px",
                          // marginTop: "10px",
                          margin: "5px",
                          color: "primary",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          // border: `1px solid`,
                          borderRadius: "50%",
                          // padding: "10px",
                          cursor: "pointer",
                          
 
                        }}
                      >
                        <i className="fa-duotone fa-file-arrow-up font-primary"></i>
                      </IconButton>
                   
 
                    : null
                }</Badge>
                 </Tooltip>
 
              </td>
              <td md={3} style={{ width: "165px" }}>
                {renderTextInput(index, "firstName", "First Name", {
                  required: values?.HSAANDPPO !== "I" ? true : false,
                  width: "165px",
                })}
              </td>
              <td md={2} style={{ width: "165px" }}>
                {renderTextInput(index, "middleName", "Middle Name", {
                  required: values?.HSAANDPPO !== "I" ? true : false,
                  width: "165px",
                })}
              </td>
              <td md={3} style={{ width: "165px" }}>
                {renderTextInput(index, "lastName", "Last Name", {
                  required: values?.HSAANDPPO !== "I" ? true : false,
                  width: "165px",
                })}
              </td>

              <td md={2} style={{ width: "165px" }}>
                {renderDropDown1(index, "relation", "Relation", RelationArray, {
                  required: values?.HSAANDPPO !== "I" ? true : false,
                  width: "165px",
                })}
              </td>

              <td md={2} style={{ width: "155px" }}>
                {item?.uid ? (
                  <div className="midAlign" style={{ textAlign: "center" }}>
                    {" "}
                    ----
                  </div>
                ) : (
                  // renderTextInput(index, "ssn", "SSN", {
                  //   maxLength: 9,
                  //   required: values?.HSAANDPPO !== "I" ? true : false,width:"100px"
                  // })
                  renderPasswordInput(index, "ssn", "SSN", {
                    required: values?.HSAANDPPO !== "I" ? true : false,
                    width: "155px",
                  })
                )}
              </td>
              <td md={5} style={{ width: "200px" }} className="midAlign">
                {/* {renderDropDown1(index,'gender',"Gender",GenderArray)} */}
                <div style={{ display: "flex", marginRight: "5px" }}>
                  {/* <span style={{padding:"10px 0px"}}><b>Gender</b></span> */}
                  <span style={{ display: "flex" }}>
                    <span>
                      {renderCheckbox1(index, "medium", "gender", "Male")}{" "}
                    </span>
                    <span style={{ alignSelf: "center" }}>Male</span>
                  </span>
                  <span style={{ display: "flex" }}>
                    <span>
                      {renderCheckbox1(index, "medium", "gender", "Female")}{" "}
                    </span>
                    <span style={{ alignSelf: "center" }}> Female</span>
                  </span>
                  <span style={{ display: "flex" }}>
                    <span>
                      {renderCheckbox1(index, "medium", "gender", "Other")}{" "}
                    </span>
                    <span style={{ alignSelf: "center" }}> Other </span>
                  </span>
                </div>
              </td>

              <td md={2} style={{ width: "200px" }}>
                {renderDateInput(index, "dob", "Date of Birth", {
                  required: values?.HSAANDPPO !== "I" ? true : false,
                })}
              </td>
              <td md={2} style={{ width: "200px" }}>
                {renderTextInput(index, "mailingAddress", "Mailing Address", {
                  width: "200px",
                })}
              </td>
              <td md={3} style={{ width: "200px" }}>
                {renderTextInput(index, "email", "Email Address", {
                  width: "200px",
                })}
              </td>
            </tr>
          );
        })}
      </table>
      {["IIa", "IVa"].includes(values?.retireeClass) ? (
        <>
          <Row className="mt-3">
            <Col md={"auto"} style={{ display: "flex", alignItems: "center" }}>
              <h3>Other Insurance</h3>
            </Col>
            <Col>
              Do you or will you or any of your covered dependents have other
              health coverage? <br />
              If “Yes”, please indicate which other coverage will apply and the
              effective date of such coverage.
            </Col>
          </Row>
          <table
            style={{ border: "1px solid black" }}
            className="text-center mt-3"
          >
            <tr style={{ border: "1px solid black" }}>
              <td
                style={{
                  width: "40%",
                  border: "1px solid black",
                  verticalAlign: "bottom",
                }}
                rowSpan={2}
              >
                Person with Dual Health Insurance Coverage
              </td>
              <td
                style={{ width: "9%", border: "1px solid black" }}
                colSpan={3}
              >
                Medicare
              </td>
              <td
                style={{ width: "3%", border: "1px solid black" }}
                rowSpan={2}
              >
                Medicaid
              </td>
              <td
                style={{
                  width: "25%",
                  border: "1px solid black",
                  verticalAlign: "bottom",
                }}
                rowSpan={2}
              >
                Other Insurance Carrier
              </td>
              <td
                style={{ width: "3%", border: "1px solid black" }}
                rowSpan={2}
              >
                Medical
              </td>
              <td
                style={{ width: "3%", border: "1px solid black" }}
                rowSpan={2}
              >
                {" "}
                Dental
              </td>
              <td
                style={{ width: "13%", border: "1px solid black" }}
                rowSpan={2}
              >
                Effective Date
              </td>
              <td
                style={{ width: "13%", border: "1px solid black" }}
                rowSpan={2}
              >
                Action
              </td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black" }}>Part A</td>
              <td style={{ border: "1px solid black" }}>Part B</td>
              <td style={{ border: "1px solid black" }}>Part D </td>
            </tr>
            {data.map((item, index) => {
              return (
                <tr style={{ border: "1px solid black" }}>
                  <td style={{ border: "1px solid black" }}>
                    <div style={{ padding: "5px 10px" }}>
                      {renderDropDown(
                        "Dependent",
                        "Dependent",
                        dependentOption
                      )}
                    </div>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {renderCheckbox("medium")}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {" "}
                    {renderCheckbox("medium")}{" "}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {" "}
                    {renderCheckbox("medium")}{" "}
                  </td>
                  <td style={{ border: "1px solid black" }}>5 </td>
                  <td style={{ border: "1px solid black" }}>6 </td>
                  <td style={{ border: "1px solid black" }}>7 </td>
                  <td style={{ border: "1px solid black" }}>8 </td>
                  <td style={{ border: "1px solid black" }}>
                    <div style={{ padding: "5px 10px" }}>
                      {newRenderDateInput(
                        index,
                        "effective_date",
                        "Effective Date",
                        (value) => {
                          const formatDate = value?.$d;
                          const date = formatDate
                            ? moment(formatDate).format("YYYY-MM-DD")
                            : null;
                          let newData = [...data];

                          newData[index]["effective_date"] = date;
                          console.log("Dasdasdadada", date, newData);

                          setData(newData);
                        }
                      )}{" "}
                    </div>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <Icon
                        style={{
                          marginRight: "10px",
                          marginTop: "10px",
                          color: "green",
                          height: "20px",
                          width: "20px",
                          justifyContent: "center",
                          border: `1px solid ${"green"}`,
                          borderRadius: "20px",
                        }}
                        className="m-r-10"
                        name="plus-circle"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </>
      ) : null}
      {openModal ? (
        <FileUploadModel
          isOpen={openModal}
          handleClose={() => {
            setOpenModel(!openModal);
          }}
          hrControl={hrControl}
          approvalBtn={true}
          docSelectionON={true}
        />
      ) : null}
    </Card>
  );
};

export default Depedentinformation;
