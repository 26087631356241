import { all, fork } from "redux-saga/effects";

import PostSaga from "./posts/saga";
import UserConfigurationSaga from "./themeCustomizer/saga";
import LeaveShareSaga from "./leave/saga";
import EmployeeSaga from "./employees/saga";
import EmployeeSignatureSaga from "./EmployeeSignature/saga"
// import AdvanceSearchSaga from "./advanceSearch/saga";
import AdvanceSearchv2Saga from "./advanceSearchv2/saga";
import WorkflowSaga from "./workflows/saga";
import ProcessSaga from "./process/saga";
import OutSideEmployementSaga from "./outsideEmployment/saga";
import PayrollStagingSaga from "./PayrollStaging/saga";
import PositionSaga from "./position/saga";
import DepartmentSaga from "./Department/saga";
import EducationSaga from "./Education/saga";
import EmployeeTypeSaga from "./EmployeeType/saga";
import PaygradeSaga from "./Paygrade/saga";
import PaystepSaga from "./Paystep/saga";
import PmrcNatureSaga from "./PmrcNature/saga";
import EthicGroupSaga from "./EthicGroup/saga";
// import NewPositionSaga from "./addorupdate/saga";
import DeductionPlanSaga from "./DeducionPlans/saga";
import VendorSaga from "./Vendor/saga";
import DeductiontypeSaga from "./DeductionTypes/saga";
import PaytimecodeSaga from "./PaytimeCodes/saga";
import PmrcsalariesSaga from "./PmrcSalaries/saga";
import CertificationSaga from "./Certifications/saga";
import TagmasterSaga from "./TagMaster/saga";
import HistorySaga from "./PrHistory/saga";
import OnlineJobApplicationSaga from "./Online-job-application/saga";
import BillingInvoiceSaga from "./DrugTesting/BillingInvoice/saga";
import CarmensOwnDTLogSaga from "./DrugTesting/CarmensOwnDTLog/saga";
import DTPMDataTLogSaga from "./DrugTesting/DTPMDataLog/saga";
import TestDesignatedPositionLogSaga from "./DrugTesting/TestDesignatedPositionLog/saga";
import DepartmentRandomDateLogSaga from "./DrugTesting/DepartmentRandomDateLog/saga";
import DepartmentJVControlNumberSaga from "./DrugTesting/DepartmentJVControlNumber/saga";
import RandomDTLogSaga from "./DrugTesting/RandomDTLog/saga";
import TDPEmployeeMasterListLogSaga from "./DrugTesting/TDPEmployeeMasterListLog/saga";
import DOAHRJVInternalLogSaga from "./DrugTesting/DOA-HRJVInternalLog/saga";
import MISDataLogSaga from "./DrugTesting/MISDataLog/saga";
import DFWPContactListSaga from "./DrugTesting/DFWPContactList/saga";
import VendorLogSaga from "./DrugTesting/VendorLog/saga";
import DT from "./DT/saga";
import AdverseActionSaga from "./EMR/AdverseAction/saga";
import CBARLogSaga from "./EMR/CBARLog/saga";
import CBAStatusSaga from "./EMR/Lookups/CBAStatus/saga";
import MediaSourceSaga from "./EMR/Lookups/MediaSource/saga";
import WaitingPeriodSaga from "./EMR/Lookups/WaitingPeriod/saga";
import TrainingLogSaga from "./EMR/TrainingLog/saga";
import TypeOfChargesSaga from "./EMR/Lookups/TypeOfCharges/saga";
import GrievanceAppealStatusSaga from "./EMR/Lookups/GrievanceAppealStatus/saga";
import NatureOfGrievanceSaga from "./EMR/Lookups/NatureOfGrievance/saga";
import GrievanceRequestSaga from "./EMR/GrievanceRequest/saga";
import LTDSaga from "./EMR/LTD/saga";
import TypesOfWorkHoursSaga from "./EMR/Lookups/TypesOfWorkHours/saga";
import RWHRSaga from "./EMR/RWHR/saga";
import TypeOfTrainingSaga from "./EMR/Lookups/TypeOfTraining/saga";
import SpecMedicalEvalSaga from "./EMR/SpecMedicalEval/saga";
import StatusOfAPPTSaga from "./EMR/Lookups/StatusOfAPPT/saga";
import TypesOfSME from "./EMR/Lookups/TypesOfSME/saga";
import EvalAppealSaga from "./EMR/EvalAppeal/saga";
import PERatingSaga from "./EMR/Lookups/PERating/saga";
import EvalAppealReasonSaga from "./EMR/Lookups/EvalAppealReason/saga";
import DocumentUploadSaga from "./DocumentUpload/saga";
import ChartSage from "./chart/saga";
import SystemAdminSaga from "./systemAdmin/saga";
import AuditLog from "./AuditLogs/saga";
import Gg1Saga from "./GG1-Crud/saga";

import GetLeaveshareFormSaga from "./leaveshare/saga";
import MainMenuSaga from "./mainMenu/saga";
// import onGetTableNameColumn from "./systemAdmin/saga";
import SidearMenuSaga from "./sidebarmenu/saga";
import ExitSurvey from "./ExitSurvey/saga";
import NpaSaga from "./npa/saga";
import TrainingRegistrySaga from "./TrainingRegistry/saga";
import JobAnnouncementSaga from "./jobAnnouncement/saga";
import PositionClassSaga from "./PositionClass/saga";
import SalaryIncrementSaga from "./salaryIncrement/saga";
import EmployeeDeductionPlanSaga from "./EmployeeDeductionPlan/saga"
import DivisionSaga from "./UserDivisionAssignment/saga"

export default function* rootSaga() {
  yield all([
    fork(PostSaga),
    fork(LeaveShareSaga),
    fork(EmployeeSaga),
    // fork(AdvanceSearchSaga),
    fork(WorkflowSaga),
    fork(ProcessSaga),
    fork(AdvanceSearchv2Saga),
    fork(OutSideEmployementSaga),
    fork(PayrollStagingSaga),
    fork(PositionSaga),
    fork(EthicGroupSaga),
    fork(UserConfigurationSaga),
    // fork(NewPositionSaga),
    fork(DepartmentSaga),
    fork(EducationSaga),
    fork(EmployeeTypeSaga),
    fork(PaygradeSaga),
    fork(PaystepSaga),
    fork(PmrcNatureSaga),
    fork(DeductionPlanSaga),
    fork(VendorSaga),
    fork(DeductiontypeSaga),
    fork(PaytimecodeSaga),
    fork(PmrcsalariesSaga),
    fork(CertificationSaga),
    fork(TagmasterSaga),
    fork(HistorySaga),
    fork(OnlineJobApplicationSaga),
    fork(BillingInvoiceSaga),
    fork(DT),
    fork(CarmensOwnDTLogSaga),
    fork(DTPMDataTLogSaga),
    fork(TestDesignatedPositionLogSaga),
    fork(DepartmentRandomDateLogSaga),
    fork(DepartmentJVControlNumberSaga),
    fork(RandomDTLogSaga),
    fork(TDPEmployeeMasterListLogSaga),
    fork(DOAHRJVInternalLogSaga),
    fork(MISDataLogSaga),
    fork(DFWPContactListSaga),
    fork(VendorLogSaga),
    fork(ExitSurvey),
    fork(AdverseActionSaga),
    fork(CBARLogSaga),
    fork(CBAStatusSaga),
    fork(MediaSourceSaga),
    fork(WaitingPeriodSaga),
    fork(TrainingLogSaga),
    fork(TypeOfChargesSaga),
    fork(GrievanceAppealStatusSaga),
    fork(NatureOfGrievanceSaga),
    fork(GrievanceRequestSaga),
    fork(LTDSaga),
    fork(TypesOfWorkHoursSaga),
    fork(RWHRSaga),
    fork(TypeOfTrainingSaga),
    fork(SpecMedicalEvalSaga),
    fork(StatusOfAPPTSaga),
    fork(TypesOfSME),
    fork(EvalAppealSaga),
    fork(PERatingSaga),
    fork(EvalAppealReasonSaga),
    fork(DocumentUploadSaga),
    fork(GetLeaveshareFormSaga),
    fork(ChartSage),
    fork(SystemAdminSaga),
    fork(AuditLog),
    fork(MainMenuSaga),
    // fork(onGetTableNameColumn),
    fork(SidearMenuSaga),
    fork(Gg1Saga),
    fork(NpaSaga),
    fork(TrainingRegistrySaga),
    fork(JobAnnouncementSaga),
    fork(PositionClassSaga),
    fork(SalaryIncrementSaga),
    fork(EmployeeDeductionPlanSaga),
    fork(EmployeeSignatureSaga),
    fork(DivisionSaga),
  ]);
}
