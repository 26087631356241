import { useCallback, useEffect, useRef } from "react";
import { DropdownFilterCell } from "./dropdownFIlterCell";
import ToggleButtonFilterCell from "./toggleButtonFilterCell";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import { setChangedField } from "../../store/Common/actions";

export const CustomFilterCell = (props) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { changedField } = useSelector((state) => state?.CommonReducer);

  useEffect(() => {
    if (
      props.value &&
      changedField === props.field &&
      props.customFilterType === "CustomNumericText"
    ) {
      inputRef.current.focus();
    }
  }, [props?.value]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, 1000);
    };
  };

  const optimizedVersion = useCallback(
    debounce((value, syntheticEvent) => {
      if (props?.customFilterType === "CustomNumericText") {
        dispatch(setChangedField(props.field));
        props.onChange({
          value: value || "",
          operator: value ? "eq" : "",
          syntheticEvent: syntheticEvent,
        });
      }
    }),
    [props.value]
  );

  switch (props?.customFilterType) {
    case "Dropdown":
      return (
        <DropdownFilterCell
          {...props}
          options={props?.dropdownOptions || []}
          defaultItem={
            props?.defaultDropdownOption ? props?.defaultDropdownOption : "All"
          }
          selectedVal={props?.value || props?.defaultItem}
        />
      );
    case "Toggle":
      return (
        <ToggleButtonFilterCell
          options={props?.dropdownOptions || []}
          selectedVal={props?.value || props?.defaultDropdownOption}
          onChange={props.onChange}
        />
      );
    case "CustomNumericText":
      return (
        <div className="k-filtercell-wrapper">
          <NumericTextBox
            format="##"
            value={props.value}
            ref={inputRef}
            onChange={(event) => {
              const { value, syntheticEvent } = event.target;
              optimizedVersion(value, syntheticEvent);
            }}
          />
        </div>
      );
    default:
      return null;
  }
};
