import {
	CREATE_UPDATE_EMPLOYEE_DEDUCTION, CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL, CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS, GET_ALL_DEDUCTION_METHOD_LIST, GET_ALL_DEDUCTION_METHOD_LIST_FAIL, GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS, GET_ALL_DEDUCTION_PLAN_MASTER, GET_ALL_DEDUCTION_PLAN_MASTER_FAIL, GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS, GET_ALL_PAYMENT_TYPE, GET_ALL_PAYMENT_TYPE_FAIL, GET_ALL_PAYMENT_TYPE_SUCCESS, GET_DEDUCTION_PLAN_DOCUMENTS, GET_DEDUCTION_PLAN_DOCUMENTS_FAIL, GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS, GET_DEDUCTION_TYPE, GET_DEDUCTION_TYPE_FAIL, GET_DEDUCTION_TYPE_SUCCESS, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL, GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS, RESET, RESET_DEDUCTION_CREATE, RESET_EMPLOYEE_DEDUCTION_PLAN,
} from "./actionTypes";

export const createOrUpdateEmployeeDeductionPlanMaster = (payload) => {
	return {
		type: CREATE_UPDATE_EMPLOYEE_DEDUCTION,
		payload: payload
	};
};

export const createOrUpdateEmployeeDeductionPlanMasterSuccess = (payload) => {
	return {
		type: CREATE_UPDATE_EMPLOYEE_DEDUCTION_SUCCESS,
		payload: payload,
	};
};

export const createOrUpdateEmployeeDeductionPlanMasterFail = (error) => {
	return {
		type: CREATE_UPDATE_EMPLOYEE_DEDUCTION_FAIL,
		payload: error,
	};
};

export const getEmployeeDeductionPlanById = (payload) => {
	return {
		type: GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID,
		payload: payload
	};
};

export const getEmployeeDeductionPlanByIdSuccess = (payload) => {
	return {
		type: GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_SUCCESS,
		payload: payload,
	};
};

export const getEmployeeDeductionPlanByIdFail = (error) => {
	return {
		type: GET_EMPLOYEE_DEDUCTION_PLAN_BY_ID_FAIL,
		payload: error,
	};
};

export const getDeductionPlanDocuments = (payload) => {
	return {
		type: GET_DEDUCTION_PLAN_DOCUMENTS,
		payload: payload
	};
};

export const getDeductionPlanDocumentsSuccess = (payload) => {
	return {
		type: GET_DEDUCTION_PLAN_DOCUMENTS_SUCCESS,
		payload: payload,
	};
};

export const getDeductionPlanDocumentsFail = (error) => {
	return {
		type: GET_DEDUCTION_PLAN_DOCUMENTS_FAIL,
		payload: error,
	};
};

export const getAllDeductionMethodList = (payload) => {
	return {
		type: GET_ALL_DEDUCTION_METHOD_LIST,
		payload: payload
	};
};

export const getAllDeductionMethodListSuccess = (payload) => {
	return {
		type: GET_ALL_DEDUCTION_METHOD_LIST_SUCCESS,
		payload: payload,
	};
};

export const getAllDeductionMethodListFail = (error) => {
	return {
		type: GET_ALL_DEDUCTION_METHOD_LIST_FAIL,
		payload: error,
	};
};

export const getAllDeductionPlanMaster = (payload) => {
	return {
		type: GET_ALL_DEDUCTION_PLAN_MASTER,
		payload: payload
	};
};

export const getAllDeductionPlanMasterSuccess = (payload) => {
	return {
		type: GET_ALL_DEDUCTION_PLAN_MASTER_SUCCESS,
		payload: payload,
	};
};

export const getAllDeductionPlanMasterFail = (error) => {
	return {
		type: GET_ALL_DEDUCTION_PLAN_MASTER_FAIL,
		payload: error,
	};
};

export const getAllPaymentType = (payload) => {
	return {
		type: GET_ALL_PAYMENT_TYPE,
		payload: payload
	};
};

export const getAllPaymentTypeSuccess = (payload) => {
	return {
		type: GET_ALL_PAYMENT_TYPE_SUCCESS,
		payload: payload,
	};
};

export const getAllPaymentTypeFail = (error) => {
	return {
		type: GET_ALL_PAYMENT_TYPE_FAIL,
		payload: error,
	};
};

export const getDeductionType = (payload) => {
	return {
		type: GET_DEDUCTION_TYPE,
		payload: payload
	};
};

export const getDeductionTypeSuccess = (payload) => {
	return {
		type: GET_DEDUCTION_TYPE_SUCCESS,
		payload: payload,
	};
};

export const getDeductionTypeFail = (error) => {
	return {
		type: GET_DEDUCTION_TYPE_FAIL,
		payload: error,
	};
};

export const resetDeductionCreate = () => {
	return {
		type: RESET_DEDUCTION_CREATE
	};
};

export const resetEmployeeDeduction = () => {
	return {
		type: RESET_EMPLOYEE_DEDUCTION_PLAN
	};
};

export const reset = () => {
	return {
		type: RESET,
	};
};
