import {
	ADVANCE_ADDITIONALWHERE,
	CHANGED_FIELD,
	GRID_DATASTATE,
	GRID_FILTER,
	GRID_PAGE_SIZE,
	GRID_SKIP,
	GRID_SORT,
	ROLE
  } from "./actionTypes";

  export const SetGridFilter = (payload) => {
	return {
	  type: GRID_FILTER,
	  payload: payload
	};
  };

  export const SetGridDataState = (payload) => {
	return {
	  type: GRID_DATASTATE,
	  payload: payload
	};
  };
  
  export const SetGridSort = (payload) => {
	return {
	  type: GRID_SORT,
	  payload: payload
	};
  };
  
  export const setGridPageSize = (payload) => {
	return {
	  type: GRID_PAGE_SIZE,
	  payload: payload,
	};
  };
  export const setAdditionalWhere = (payload) => {
	return {
	  type: ADVANCE_ADDITIONALWHERE,
	  payload: payload,
	};
  };
  
  export const setGridSkip = (payload) => {
	return {
	  type: GRID_SKIP,
	  payload: payload,
	};
  };
  
export const setSelectedRoles = (payload) => {
	return {
		type: ROLE,
		payload: payload,
	};
};

export const setChangedField = (payload) => {
	return {
		type: CHANGED_FIELD,
		payload: payload,
	};
};