import { Autocomplete, TextField, styled } from "@mui/material";
import React, { forwardRef, useState } from "react";
import { Col, Row } from "reactstrap";
import CustomTextField from "../../../Layout/Components/TextField";
import { fontWeight } from "@mui/system";
import moment from "moment";
import { TextArea } from "@progress/kendo-react-inputs";
import { dataCsvIcon } from "@progress/kendo-svg-icons";
import EmployeeSignature from "../../../CommonComponents/EmployeeSignature";

const B = styled("b")(({ theme }) => ({
  fontSize: "14px",
}));
const NPADirectPrintSalaryIncrement = React.forwardRef((props, ref) => {
  const { data, printState } = props;
  console.log("directNpa", data);
  const CheckValue = (temp) => {
    if (temp !== null && temp !== undefined && temp !== "") {
      return true;
    }
    return false;
  };
  let UserName = `${
    CheckValue(data?.enterByLastName) ? data?.enterByLastName : ""
  }, ${CheckValue(data?.enterByFirstName) ? data?.enterByFirstName : ""} ${
    CheckValue(data?.enterByMiddleName) ? data?.enterByMiddleName : ""
  }`;
  let UserContactNo = CheckValue(data?.Contact_Number)
    ? data?.Contact_Number
    : "";
  console.log("123123 asas::: ", printState, props?.showPrintForm);
  // const [signatureData, setSignatureData] = useState({})

  return (
    <>
      {props?.showPrintForm && (
        <div ref={ref}>
          <style type="text/css" media="print">
            {/* {" @page {size : landscape}"} */}
            {`
              @page {
                size: letter;
                margin: 0;
              }
              body {
                margin: 0;
              }
            `}
          </style>
          <div
            style={{
              width: "8.5in", // Letter size width
              height: "11in", // Letter size height
              // backgroundColor: "#ccfff9",
              padding: "35px",
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "1.5",
            }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 !important",
                fontWeight: "bold",
                lineHeight: "1.3",
              }}
            >
              <Col
                sm={2}
                className="p-1"
                style={{
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "end",
                }}
              >
                {/* <Row>
              <Col sm={12}>
                FCN 2-0-7 <br />
                Revised 7/62 <br />
                (old GG-1)
              </Col>
            </Row> */}
              </Col>
              <Col sm={8} className="text-center" style={{ fontSize: "14px" }}>
                GOVERNMENT OF GUAM <br />
                AGANA, GUAM <br />
                NOTIFICATION OF PERSONNEL ACTION
              </Col>
              <Col sm={2}></Col>
            </Row>
            {/* <Row
          style={{ borderTop: "1px solid black", marginBottom: "5px" }}
        ></Row> */}
            <Row style={{ borderTop: "1px solid black" }}>
              <Col sm={6} className="p-1 border-dark border-end">
                1. Fullname
                <br />
                <span className="p-4">{data?.fullName}</span>
              </Col>

              <Col sm={2} className="p-1 border-dark border-end">
                2. Date of Birth <br />
                {printState == "Print NPA Redacted" ? (
                  <span
                    className="m-1"
                    style={{ height: "25px", backgroundColor: "#000" }}
                  >
                    XX XXX XXXX
                  </span>
                ) : (
                  <span className="p-4">
                    {/* {data?.DOB && moment(data.DOB).format("DD MMM YYYY")} */}
                    {data?.dateOfBirth &&
                      moment(data.dateOfBirth).format("DD MMM YYYY")}
                  </span>
                )}
              </Col>
              <Col sm={2} className="p-1 border-dark border-end">
                3.Employee ID
                <br />
                {/* <span className="p-4">{ data?.employeeId }</span> */}
                <span className="p-4">
                  {data && data.employeeId
                    ? data.employeeId
                    : data.employeeID
                    ? data.employeeID
                    : ""}
                </span>
              </Col>

              <Col sm={2} className="p-1 border-dark">
                4.Date Requested <br />
                <span className="p-4">
                  {" "}
                  {/* {data?.DOR && moment(data.DOR).format("DD MMM YYYY")} */}
                  {/* {data?.dateOfRequest && moment(data.dateOfRequest).format("DD MMM YYYY")} */}
                  {moment().format("DD MMM YYYY")}
                </span>
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid black" }}>
              <Col sm={12} className="p-1 border-dark text-center">
                THIS IS TO NOTIFY YOU OF THE FOLLOWING ACTION AFFECTING YOUR
                EMPLOYMENT
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid black" }}>
              <Col sm={5} className="p-1 border-dark border-end">
                5.Nature of Action <br />
                {/* <span className="p-4">{data?.RequestID}</span> */}
                <span className="p-4">{data?.natureOfActionTitle} </span>
              </Col>
              <Col sm={3} className="p-1 border-dark border-end">
                6.Effective Date <br />
                <span className="p-4">
                  {" "}
                  {/* {data?.ActionDate && moment(data.ActionDate).format("DD MMM YYYY")} */}
                  {data?.actionEffectiveDate &&
                    moment(data.actionEffectiveDate).format("DD MMM YYYY")}
                </span>
              </Col>
              <Col sm={4} className="p-1 border-dark">
                7.Legal Authority <br />
                <span className="p-4">{data?.legalAuthority} </span>
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid black" }}>
              <Col sm={5} className="p-1 border-dark border-end text-center">
                FROM
              </Col>
              <Col sm={2} className="p-1 border-dark border-end text-center">
                Description
              </Col>
              <Col sm={5} className="p-1 text-center">
                TO
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid black" }}>
              <Col
                sm={5}
                className="p-1 border-dark border-end text-center p-3"
              >
                <Row>
                  <Col sm={12} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      // style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="FR_Title"
                      label="Position"
                      // value={data?.FR_PositionTitle?.label}
                      value={data?.fromPositionTitle?.trim()}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={4} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="fromPayGrade"
                      label="Pay Grade"
                      // value={data?.FR_Grade}
                      value={data?.fromPayGrade?.trim()}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={8} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="fromSalary"
                      label="Salary Per Annum"
                      // value={data?.FR_Salary}
                      value={data?.fromSalary}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={4} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="fromStep"
                      label="Step"
                      // value={data?.FR_Step}
                      value={data?.fromStep}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={8} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="fromRate"
                      label="Rate per Hour"
                      // value={data?.FR_Rate}
                      value={data?.fromRate}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="department"
                      label="Department"
                      // value={data?.FR_Department?.label}
                      value={data?.fromDepartment}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="section_divison"
                      label="Section/Division"
                      // value={data?.FR_Division?.label}
                      value={data?.fromDivisionName}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12} className="bg-white">
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      //   style={{ backgroundColor: "#cdd3d6" }}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="FR_FTE_Number"
                      label="FTE"
                      // value={data?.FR_FTE_Number}
                      value={data?.fromFteNo}
                      shrink={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={2} className="p-1 border-dark border-end pt-3">
                {/* <h6 className="p-1 m-2"> */}
                <Row>
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    8. Position Title
                  </Col>
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    9. Pay Grade/Step Salary Rate
                  </Col>
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  ></Col>
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    10. Department
                  </Col>
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    11. Div./Section
                  </Col>
                  {/* <Col
                sm={12}
                style={{
                  minHeight: "46.25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                {" "}
                12. Section
              </Col> */}
                  <Col
                    sm={12}
                    style={{
                      minHeight: "46.25px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    12.Position Number
                  </Col>
                </Row>
                {/* 8. Position Title */}
                {/* <br />
            <br /> */}
                {/* </h6> */}
                {/* <h6 className="p-1 m-2"> */}
                {/* 9. Pay Range.Step Salary or Rate */}
                {/* <br />
            <br /> */}
                {/* </h6>{" "} */}
                {/* <h6 className="p-1 m-2"> */}
                {/* 10. Dept. or Agency
            <br />
            <br /> */}
                {/* </h6>{" "} */}
                {/* <h6 className="p-1 m-2"> */}
                {/* 11. Division */}
                {/* <br />
            <br /> */}
                {/* </h6> */}
                {/* <h6 className="p-1 m-2"> */}
                {/* 12. Section
            <br />
            <br /> */}
                {/* </h6> */}
                {/* <h6 className="p-1 m-2"> */}
                {/* 13.Position Number */}
                {/* </h6> */}
              </Col>
              <Col sm={5} className="p-1  text-center p-3">
                <Row>
                  <Col sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="to_title"
                      label="Position"
                      // value={data?.TO_PositionTitle?.label }
                      value={data?.toPositionTitle?.trim()}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="to_pay_grade"
                      label="Pay Grade"
                      // value={data?.TO_Grade}
                      value={data?.toPayGrade?.trim()}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={8}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="toSalary"
                      label="Salary"
                      // value={data?.TO_Salary}
                      value={data?.toSalary}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="to_strp"
                      label="Step"
                      // value={data?.TO_Step}
                      value={data?.toStep}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={8}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="toRate"
                      label="Rate"
                      // value={data?.TO_Rate}
                      value={data?.toRate}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="toDepartment"
                      label="Department"
                      // value={data?.TO_Department?.label}
                      value={data?.toDepartment}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="to_section_divison"
                      label="Section/Division"
                      // value={data?.TO_Division?.label }
                      value={data?.toDivisionName}
                      shrink={true}
                    />
                  </Col>
                  <Col sm={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className="bg-white"
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black",
                          fontSize: "12px",
                        },
                        "& .MuiFormLabel-root.Mui-disabled": {
                          WebkitTextFillColor: "#13346b",
                          bgcolor: "white",
                          fontSize: "14px",
                          fontWeight: "bolder",
                        },
                      }}
                      size="small"
                      margin="dense"
                      fullWidth
                      disabled
                      name="TO_FTE_Number"
                      label="FTE"
                      // value={data?.TO_FTE_Number}
                      value={data?.toFteNo}
                      shrink={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ borderTop: "1px solid black" }}>
              {console.log(
                "123123 ::: ",
                data?.nextIncrementDate,
                data?.worK_SCH,
                typeof data?.worK_SCH,
                data
              )}
              <Col sm={4} className="p-1 border-dark border-end">
                Next Increment:
                <span className="p-4">
                  {" "}
                  {data?.nextIncrementDate
                    ? moment(data?.nextIncrementDate).format("MM-DD-YYYY")
                    : ""}{" "}
                </span>
                <br />
                Action No.:
                <span className="p-4"> {data?.actnNo} </span>
                <br />
                Work Schedule:
                <span className="p-4">
                  {" "}
                  {data?.worK_SCH && typeof data?.worK_SCH == "object"
                    ? data?.worK_SCH?.value
                    : data?.worK_SCH || ""}{" "}
                </span>
                <br />
              </Col>
              <Col sm={4} className="p-1 border-dark border-end">
                Exp/Separation Date:
                <span className="p-4">
                  {" "}
                  {data?.expirationDate
                    ? moment(data?.expirationDate).format("MM-DD-YYYY")
                    : ""}{" "}
                </span>
                <br />
                PMIS Action Date:
                <span className="p-4">
                  {" "}
                  {data?.actionDate
                    ? moment(data?.actionDate).format("MM-DD-YYYY")
                    : ""}{" "}
                </span>
                <br />
                Request ID:
                <span className="p-4"> {data?.requestId} </span>
                <br />
              </Col>
              <Col sm={4} className="p-1 border-dark">
                Bdgt Accn No.:
                <span className="p-4"> {data?.budgetAcctNo} </span>
                <br />
                JON:
                <span className="p-4"> {data?.jobOrderNumber} </span>
                <br />
              </Col>
            </Row>
            <Row style={{ borderTop: "1px solid black" }}>
              <Col sm={8} className="p-1 border-dark border-end">
                Retirement Type:
                <span className="p-4"> {data?.retirementCoverage || ""} </span>
                {/* <br />
            Action No.: 
            <span className="p-4"> {data?.ActionNo} </span>
            <br />
            Work Schedule: 
            <span className="p-4"> {data?.worK_SCH?.value} </span> */}
                <br />
              </Col>
              <Col sm={4} className="p-1 border-dark">
                Personnel Jacket No.:
                <span className="p-4"> {data?.jacket_No} </span>
                <br />
              </Col>
            </Row>

            <Row
              style={{
                borderTop: "1px solid black",
                minHeight: "22vh",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "space-between",
              }}
            >
              <Col sm={12} className="p-1">
                14.REMARKS (continue in item 21,if necessary) <br />
                {/* <span className="p-4">{data?.Remarks}</span> */}
                <span className="p-4">{data?.remarks}</span>
              </Col>
              {/* <Col sm={12} className="p-1">
            EMPLOYMENT IS SUBJECT TO SATISFACTORY COMPLETION OF MEDICAL EXAMINATION AND MANDATORY DRUG TEST.
            <br/>
          </Col>
          <Col sm={12} className="p-1">
            CONTINUE ALL BENEFITS AND DEDUCTIONS FROM LAST APPOINTMENT.
            <br/>
          </Col>
          <Col sm={12} className="p-1">
            PRIOR SERVICE WILL BE CREDITED UPON RECEIPT OF OFFICIAL PERSONNEL FILE FROM {data?.fromDepartment}.
            <br/>
          </Col> */}

              <Col
                md={12}
                lg={12}
                sm={12}
                className="p-1"
                //  style={{ display: "flex", justifyContent: "end", width: "50%" }}
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "50%",
                    float: "right",
                    marginTop: "40px",
                  }}
                >
                  {/* {console.log("123123 props... ",props?.approvedData)} */}
                  <Col sm={12} md={12} lg={12}>
                    <EmployeeSignature
                      signatureData={props?.mySignature}
                      setSignatureData={props?.setMySignature}
                      npaSignature={props?.mySignature}
                      label={""}
                      sourceName={"GG1"}
                      employeeId={
                        data && data.employeeId
                          ? data.employeeId
                          : data.employeeID
                          ? data.employeeID
                          : ""
                      }
                    />
                    <hr />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <B>For: APPOINTING AUTHORITY</B>
                      <B>Assistant Personnel Services Administrator,</B>
                      <B>HR Division</B>
                    </div>
                  </Col>
                  {/* <Col sm={12} md={12} lg={12}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="bg-white"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                      fontSize: "12px",
                    },
                    "& .MuiFormLabel-root.Mui-disabled": {
                      WebkitTextFillColor: "#13346b",
                      bgcolor: "white",
                      fontSize: "14px",
                      fontWeight: "bolder",
                    },
                  }}
                  fullWidth
                  minRows={4}
                  rows={4}
                  margin="dense"
                  disabled
                  name="Approved"
                  label="HUMAN RESOURCES MANAGER"
                  // value={
                  //   props?.approvedData?.find((i) => i.statusId == 7)
                  //     ? `Digitally approved by ${props?.approvedData.length
                  //       ? props?.approvedData?.find((i) => i.statusId == 7)
                  //         ?.performedBy !== undefined
                  //         ? props?.approvedData?.find(
                  //           (i) => i.statusId == 7
                  //         )?.performedBy
                  //         : ""
                  //       : ""
                  //     } on ${props?.approvedData.length
                  //       ? props?.approvedData?.find((i) => i.statusId == 7)
                  //         ?.performedOn !== undefined
                  //         ? props?.approvedData?.find(
                  //           (i) => i.statusId == 7
                  //         )?.performedOn
                  //         : ""
                  //       : ""
                  //     }`
                  //     : ""
                  // }
                />
              </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
});
export default NPADirectPrintSalaryIncrement;
