import {
  GET_TRAININGS,
  GET_TRAININGS_SUCCESS,
  GET_TRAININGS_FAIL,
  GET_TRAININGS_DETAILS,
  GET_TRAININGS_DETAILS_SUCCESS,
  GET_TRAININGS_DETAILS_FAIL,
  TRAINING_CREATE,
  TRAINING_CREATE_FAIL,
  TRAINING_CREATE_SUCCESS,
  TRAINING_DELETE,
  TRAINING_DELETE_FAIL,
  TRAINING_DELETE_SUCCESS,
  TRAINING_UPDATE,
  TRAINING_UPDATE_FAIL,
  TRAINING_UPDATE_SUCCESS,
  RESET,
} from "./actionTypes";

const initialState = {
  trainingList: [],
  training: {},
  postingResult: {},
  loading: false,
  trainingSetting: {},
  error: {
    message: "",
  },
};

const TrainingReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case GET_TRAININGS:
      state = { ...state, loading: true };
      break;
    case GET_TRAININGS_SUCCESS:
      state = { ...state, trainingList: action.payload, loading: false };
      break;
    case GET_TRAININGS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case GET_TRAININGS_DETAILS:
      state = { ...state, loading: true };
      break;
    case GET_TRAININGS_DETAILS_SUCCESS:
      state = { ...state, training: action.payload, loading: false };
      break;
    case GET_TRAININGS_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case TRAINING_CREATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case TRAINING_CREATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case TRAINING_CREATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        postingResult: {},
        loading: false,
      };
      break;
    case TRAINING_UPDATE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case TRAINING_UPDATE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case TRAINING_UPDATE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case TRAINING_DELETE:
      state = { ...state, postingResult: {}, loading: true };
      break;
    case TRAINING_DELETE_SUCCESS:
      state = { ...state, postingResult: action.payload, loading: false };
      break;
    case TRAINING_DELETE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
    case RESET:
      state = { ...state, training: {} };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TrainingReducer;
