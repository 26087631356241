import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_PAYSTEPS, GET_PAYSTEPS_DETAILS,  PAYSTEP_CREATE, PAYSTEP_DELETE,
   PAYSTEP_UPDATE} from "./actionTypes";

import {
  getPaystepsSuccess,
  getPaystepsFail,
  getPaystepsDetailsSuccess,
  getPaystepsDetailsFail,
  createPaystepSuccess,
  createPaystepFail,
  updatePaystepSuccess,
  updatePaystepFail,
  deletePaystepSuccess,
  deletePaystepFail,
} from "./actions";

import {  createPaystep,deletePaystep,
	getPaystepDetails,getPaysteps
	,updatePaystep,
 } from "../../_helper/services/paystep";
function* onGetPaysteps({payload: {page,pageSize}}) {
  try {
    const response = yield call(getPaysteps,{page,pageSize});
    yield put(getPaystepsSuccess(response));
  } catch (error) {
    yield put(getPaystepsFail(error.response));
  }
}

function* onGetPaystepDetails({ payload: {paY_GRADE ,paY_STEP} }) {
  try {
    const response = yield call(getPaystepDetails, {paY_GRADE, paY_STEP});
    yield put(getPaystepsDetailsSuccess(response));
  } catch (error) {
    yield put(getPaystepsDetailsFail(error.response));
  }
}
function* onCreatePaystep({ payload }) {
	try {
	  const response = yield call(createPaystep, payload);
	  yield put(createPaystepSuccess(response));
    ShowSucess("Paystep Created Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(createPaystepFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  function* onUpdatePaystep({ payload: { paY_GRADE, paY_STEP },payload }) {
	try {
	  const response = yield call(updatePaystep, { paY_GRADE, paY_STEP }, payload);
	  yield put(updatePaystepSuccess(response));
    ShowSucess("Paystep Details Updated Successfully",{
      position:"top-right"
    })
	} catch (error) {
	  yield put(updatePaystepFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }
  // function* onDeletePaystep({ payload: ID }) {
	// try {
	//   const response = yield call(deletePaystep, ID);
	//   yield put(deletePaystepSuccess(response));
  //   toast.success('Deleted successfully', {
  //     position: 'top-right'
  //   });
	// } catch (error) {
	//   yield put(deletePaystepFail(error.response));
	// }
  // }
  // function* onDeletePaystep({ payload: { id: paY_GRADE, id: paY_STEP } }) {
  //   try {
  //     const response = yield call(deletePaystep, { id: paY_GRADE, id: paY_STEP });
  //     yield put(deletePaystepSuccess(response));
  //     toast.success('Deleted successfully', {
  //       position: 'top-right'
  //     });
  //   } catch (error) {
  //     yield put(deletePaystepFail(error.response));
  //   }
  // }
  
  function* onDeletePaystep({payload: PAY_GRADE,PAY_STEP} ) {
    console.log("Pay_grade:", PAY_GRADE);
    console.log("Pay_step:", PAY_STEP);
    // console.log("payload:", payload)
    try {
      const response = yield call(deletePaystep, PAY_GRADE, PAY_STEP );
      yield put(deletePaystepSuccess(response));
      ShowSucess('Deleted successfully', {
        position: 'top-right'
      });
    } catch (error) {
      yield put(deletePaystepFail(error.response));
      ShowError(error?.response?.data?.message);
    }
  }
  

function* CartSaga() {
  yield takeLatest(GET_PAYSTEPS, onGetPaysteps);
  yield takeLatest(GET_PAYSTEPS_DETAILS, onGetPaystepDetails);
  yield takeLatest(PAYSTEP_CREATE, onCreatePaystep);
  yield takeLatest(PAYSTEP_UPDATE, onUpdatePaystep);
  yield takeLatest(PAYSTEP_DELETE, onDeletePaystep);
}

export default CartSaga;