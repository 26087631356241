export const GET_CHART_DATA_YEAR_SERVICE = "GET_CHART_DATA_YEAR_SERVICE";
export const GET_CHART_DATA_YEAR_SERVICE_SUCCESS = "GET_CHART_DATA_YEAR_SERVICE_SUCCESS";
export const GET_CHART_DATA_YEAR_SERVICE_FAIL = "GET_CHART_DATA_YEAR_SERVICE_FAIL";

export const GET_CHART_DATA_AGE_GROUP = "GET_CHART_DATA_AGE_GROUP";
export const GET_CHART_DATA_AGE_GROUP_SUCCESS = "GET_CHART_DATA_AGE_GROUP_SUCCESS";
export const GET_CHART_DATA_AGE_GROUP_FAIL = "GET_CHART_DATA_AGE_GROUP_FAIL";

export const GET_CHART_DATA_AGE_Salary_Group = "GET_CHART_DATA_AGE_Salary_Group";
export const GET_CHART_DATA_AGE_Salary_Group_SUCCESS = "GET_CHART_DATA_AGE_Salary_Group_SUCCESS";
export const GET_CHART_DATA_AGE_Salary_Group_FAIL = "GET_CHART_DATA_AGE_Salary_Group_FAIL";

export const GET_CGI_BOTTLENET = "GET_CGI_BOTTLENET";
export const GET_CGI_BOTTLENET_SUCCESS="GET_CGI_BOTTLENET_SUCCESS";
export const GET_CGI_BOTTLENET_FAILURE="GET_CGI_BOTTLENET_FAILURE";

export const GET_SOLIDGAUGE = "GET_SOLIDGAUGE";
export const GET_SOLID_GAUGE_SUCCESS = "GET_SOLID_GAUGE_SUCCESS";
export const GET_SOLID_GAUGE_FAILURE = "GET_SOLID_GAUGE_FAILURE";

export const GET_BBMRGG1ANALYST = "GET_BBMRGG1ANALYST";
export const GET_BBMRGG1ANALYST_SUCCESS = "GET_BBMRGG1ANALYST_SUCCESS";
export const GET_BBMRGG1ANALYST_FAILURE = "GET_BBMRGG1ANALYST_FAILURE";

export const GET_BBMRGG1ASSIGNANALYST = "GET_BBMRGG1ASSIGNANALYST";
export const GET_BBMRGG1ASSIGNANALYST_SUCCESS = "GET_BBMRGG1ASSIGNANALYST_SUCCESS";
export const GET_BBMRGG1ASSIGNANALYST_FAILURE = "GET_BBMRGG1ASSIGNANALYST_FAILURE";
