import { Breadcrumbs, H5, P } from '../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Fragment } from 'react';

function NotMatch(props) {
	return <Fragment>
		<Breadcrumbs mainTitle='Missing page' parent="" title="Not Found" />
		<Container fluid={true}>
			<Row>
				<Col sm="12">
					<Card>
						<CardHeader>
							<H5>Oops</H5><span>Something wrong here!</span>
						</CardHeader>
						<CardBody>
							<P><b>We are sorry, the page you requested cannot be found.</b>
                            <P>The URL may be misspelled or the page you're looking for is no longer available.</P></P>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	</Fragment>
}
export default NotMatch