import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from '../../_helper/helper';
import {
  GET_DIVISION,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAIL,
  GET_DIVISION_DETAILS,
  GET_DIVISION_DETAILS_SUCCESS,
  GET_DIVISION_DETAILS_FAIL,
  DIVISION_UPDATE,
  DIVISION_UPDATE_SUCCESS,
  DIVISION_UPDATE_FAIL,
  DIVISION_CREATE,
  DIVISION_CREATE_SUCCESS,
  DIVISION_CREATE_FAIL,
  DIVISION_DELETE,
  DIVISION_DELETE_SUCCESS,
  DIVISION_DELETE_FAIL,
} from "./actionTypes";

import {
  getDivisionSuccess,
  getDivisionFail,
  getDivisionDetailsSuccess,
  getDivisionDetailsFail,
  divisionUpdateSuccess,
  divisionUpdateFail,
  divisionCreateSuccess,
  divisionCreateFail,
  divisionDeleteSuccess,
  divisionDeleteFail,
} from "./action";


import { 
    createUserDivision,
    deleteUserDivision,
    getUserDivision,
    getUserDivisionDetails,
    updateUserDivision } from "../../_helper/services/UserDivisionAssignment";


function* onGetDivision( payload ) {
  try {
    const response = yield call(getUserDivision, payload);
    yield put(getDivisionSuccess(response));
  } catch (error) {
    yield put(getDivisionFail(error.response));
  }
}

function* onGetDivisionDetails({ payload: id }) {
  try {
    const response = yield call(getUserDivisionDetails, id);
    yield put(getDivisionDetailsSuccess(response));
  } catch (error) {
    yield put(getDivisionDetailsFail(error.response));
  }
}

function* onUpdateDivision({ payload }) {
  try {
    const response = yield call(updateUserDivision, payload);
    yield put(divisionUpdateSuccess(response));
    ShowSucess("Division updated successfully");
  } catch (error) {
    yield put(divisionUpdateFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* onCreateDivision({ payload }) {
  try {
    const response = yield call(createUserDivision, payload);
    yield put(divisionCreateSuccess(response));
    ShowSucess("Division created successfully");
  } catch (error) {
    yield put(divisionCreateFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* onDeleteDivision({ payload: employeeId, dpdv  }) {
  try {
    const response = yield call(deleteUserDivision, employeeId, dpdv);
    yield put(divisionDeleteSuccess(response));
    ShowSucess("Division deleted successfully");
  } catch (error) {
    yield put(divisionDeleteFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

// function* onDeleteDivision({ payload }) {
//   try {
//     const response = yield call(deleteUserDivision, payload);
//     yield put(divisionDeleteSuccess(response));
//     ShowSucess("Division deleted successfully");
//   } catch (error) {
//     yield put(divisionDeleteFail(error.response));
//     ShowError(error?.response?.data?.message);
//   }
// }

function* CartSaga() {
  yield takeLatest(GET_DIVISION, onGetDivision);
  yield takeLatest(GET_DIVISION_DETAILS, onGetDivisionDetails);
  yield takeLatest(DIVISION_UPDATE, onUpdateDivision);
  yield takeLatest(DIVISION_CREATE, onCreateDivision);
  yield takeLatest(DIVISION_DELETE, onDeleteDivision);
}

export default CartSaga;
