import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ShowError, ShowSucess } from "../../_helper/helper";
import {
  GET_LEAVESSHAREFORM_DETAILS,
  LEAVESHAREFORM_DELETE,
  LEAVESHAREFORM_UPDATE,
  LEAVESHAREFORM_CREATE,
  LEAVESHAREFORM_GETINFO,
} from "./actiontype";
import {
  getleavesformsuccess,
  getleavesformfailure,
  deleteShareFormSuccess,
  deleteShareFormFail,
  updateLeavesFormSuccess,
  updateLeavesFormFail,
  createLeavesFormSuccess,
  createLeavesFormFailure,
  getLeaveDonarInfoSuccess,
  getLeaveDonarInfoFail,
} from "./action";
import {
  getLeaveShareForm,
  deleteleaveshareform,
  createleaveshareform,
  updateleaveshareform,
  getleaveshareInfo,
} from "../../_helper/services/EMR/leaveshare";

function* GetLeavesData({ payload: { leaveAppID } }) {
  try {
    const response = yield call(getLeaveShareForm, leaveAppID);

    yield put(getleavesformsuccess(response));
    // console.log("processstatus",response)
  } catch (error) {
    yield put(getleavesformfailure(error));
  }
}
function* onGetLeaveShareFormInfo({ payload }) {
  const { employeeId, rowIndex } = payload;
  try {
    const response = yield call(getleaveshareInfo, employeeId);
    yield put(getLeaveDonarInfoSuccess(response, rowIndex));
    console.log("employee response", response);
  } catch (error) {
    yield put(getLeaveDonarInfoFail(error));
  }
}

function* onCreateLeaveShare({ payload }) {
  try {
    const response = yield call(createleaveshareform, payload);
    yield put(createLeavesFormSuccess(response));
    ShowSucess(
      response?.message.length > 25
        ? `${response?.message.slice(0, 25)}....`
        : response?.message,
      {
        position: "top-right",
      }
    );
  } catch (error) {
    yield put(createLeavesFormFailure(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error);
  }
}
function* onUpdateLeaveShare({ payload }) {
  try {
    const response = yield call(updateleaveshareform, payload);
    yield put(updateLeavesFormSuccess(response));
    ShowSucess(
      response?.message.length > 25
        ? `${response?.message.slice(0, 25)}....`
        : response?.message,
      {
        position: "top-right",
      }
    );
  } catch (error) {
    yield put(updateLeavesFormFail(error.response));
    ShowError(error?.response?.data?.message, {
      position: "top-right",
    });
  }
}

function* onDeleteLeaveshare({ payload: dID }) {
  try {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      const response = yield call(deleteleaveshareform, dID);
      // debugger;
      yield put(deleteShareFormSuccess(response));
      // debugger;
      ShowSucess("Deleted successfully", {
        position: "top-right",
      });
    }
  } catch (error) {
    yield put(deleteShareFormFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* GetLeaveshareFormSaga() {
  yield takeLatest(GET_LEAVESSHAREFORM_DETAILS, GetLeavesData);
  yield takeLatest(LEAVESHAREFORM_GETINFO, onGetLeaveShareFormInfo);
  yield takeLatest(LEAVESHAREFORM_CREATE, onCreateLeaveShare);
  yield takeLatest(LEAVESHAREFORM_UPDATE, onUpdateLeaveShare);
  yield takeLatest(LEAVESHAREFORM_DELETE, onDeleteLeaveshare);
}

export default GetLeaveshareFormSaga;
