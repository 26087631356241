import {
  GET_DIVISION,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAIL,
  GET_DIVISION_DETAILS,
  GET_DIVISION_DETAILS_SUCCESS,
  GET_DIVISION_DETAILS_FAIL,
  DIVISION_UPDATE,
  DIVISION_UPDATE_SUCCESS,
  DIVISION_UPDATE_FAIL,
  DIVISION_CREATE,
  DIVISION_CREATE_SUCCESS,
  DIVISION_CREATE_FAIL,
  DIVISION_DELETE,
  DIVISION_DELETE_SUCCESS,
  DIVISION_DELETE_FAIL,
  RESET,
} from "./actionTypes";

export const getDivision = (payload) => {
  return {
    type: GET_DIVISION,
    payload: payload,
  };
};

export const getDivisionSuccess = (data) => {
  return {
    type: GET_DIVISION_SUCCESS,
    payload: data,
  };
};

export const getDivisionFail = (error) => {
  return {
    type: GET_DIVISION_FAIL,
    payload: error,
  };
};

export const getDivisionDetails = (id) => {
  return {
    type: GET_DIVISION_DETAILS,
    payload: id,
  };
};

export const getDivisionDetailsSuccess = (data) => {
  return {
    type: GET_DIVISION_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getDivisionDetailsFail = (error) => {
  return {
    type: GET_DIVISION_DETAILS_FAIL,
    payload: error,
  };
};

export const divisionUpdate = (payload) => {
  return {
    type: DIVISION_UPDATE,
    payload: payload,
  };
};

export const divisionUpdateSuccess = (response) => {
  return {
    type: DIVISION_UPDATE_SUCCESS,
    payload: response,
  };
};

export const divisionUpdateFail = (error) => {
  return {
    type: DIVISION_UPDATE_FAIL,
    payload: error,
  };
};

export const divisionCreate = (payload) => {
  return {
    type: DIVISION_CREATE,
    payload: payload,
  };
};

export const divisionCreateSuccess = (response) => {
  return {
    type: DIVISION_CREATE_SUCCESS,
    payload: response,
  };
};

export const divisionCreateFail = (error) => {
  return {
    type: DIVISION_CREATE_FAIL,
    payload: error,
  };
};

export const divisionDelete = (payload) => {
  return {
    type: DIVISION_DELETE,
    payload: payload,
  };
};

export const divisionDeleteSuccess = (response) => {
  return {
    type: DIVISION_DELETE_SUCCESS,
    payload: response,
  };
};

export const divisionDeleteFail = (error) => {
  return {
    type: DIVISION_DELETE_FAIL,
    payload: error,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};
