import { Grid, Paper, IconButton } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { post } from "../../_helper/api_helper";
import moment from "moment";
import { LoadingPanel } from "../../CommonComponents/CommonFunction";
import "./AttritionContent.css";
import SelectMultipleCheckBox from "./SelectMultipleCheckBox";
import { ShowError } from "../../_helper/helper";

const AttrtionTable = () => {
  const [rowData, setRowData] = useState();
  const [colorBackground1, setColorBackground1] = useState("#fff44c");
  const [colorStrip, setColorStrip] = useState("#fdf2f2");
  const [isPrinting, setIsPrinting] = useState(false);

  const printRef = useRef(null);
  const promiseResolveRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const [rowHeader, setRowHeader] = useState([]);
  const [FTEDate, setFTEDate] = useState(moment().format("YYYY-MM-DD"));
  const [StartDate, setStartDate] = useState("2023-10-01");
  const [departmentOption, setDepartmentOption] = useState([]);

  const [selectedDept, setSelectedDept] = useState([]);

  const GetDepartmentData = async () => {
    if (selectedDept.length == 0) {
      ShowError("Please select a department");
      return;
    }
    setLoading(true);
    try {
      const promises = [
        // get nature of actions

        post(`${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`, {
          tableName: "vw_naturecodes",
          searchParams: "[]",
          columns: "*",
          pageNumber: 1,
          pageSize: 100000,
          sortData: "",
          additionalWhere: "",
          qID: 0,
          role: "",
          location: "global",
          id: "vw_naturecodes",
        }),

        // get attrition FTE-on-board and Current-on-board
        post(`${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`, {
          tableName: "sp_attrition_FTP",
          // searchParams: `[{"columnName":"ftedate","operator":"=","value":'${StartDate}',"dataType":"DATETIME"}]`,
          searchParams: `[{"columnName":"startDate","operator":">=","value":'${StartDate}',"dataType":"DATETIME"},{"columnName":"fteDate","operator":"<=","value":'${FTEDate}',"dataType":"DATETIME"}]`,
          columns: "*",
          pageNumber: 1,
          pageSize: 100000000,
          sortData: "",
          additionalWhere: "",
          qID: 0,
          location: "global",
          id: "sp_attrition_FTP",
        }),

        // get Appoiment and Sepration Data

        post(`${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`, {
          tableName: "sp_attrition_report",
          // searchParams: `[{"columnName":"ftedate","operator":"=","value":'${StartDate}',"dataType":"DATETIME"}]`,
          searchParams: `[{"columnName":"startDate","operator":">=","value":'${StartDate}',"dataType":"DATETIME"},{"columnName":"ftedate","operator":"<=","value":'${FTEDate}',"dataType":"DATETIME"}]`,
          columns: "*",
          pageNumber: 1,
          pageSize: 1000000000,
          sortData: "",
          additionalWhere: "",
          qID: 0,
          location: "global",
          id: "sp_attrition_report",
        }),
      ];

      // Use Promise.all to wait for all promises to resolve
      const [
        { results: getNatureCode },
        { results: getFTE },
        { results: getNOAValue },
      ] = await Promise.all(promises);

      // filter Appointment NOA
      let AppNOAs = getNatureCode
        .filter((item) => item?.type == "Appointment")
        .map((i) => {
          return {
            label: i?.NATURE_TITLE?.trim(),
            key: i?.NATURE_TITLE?.trim(),
            width: "25px",
            bg_color: "white",
          };
        });

      // Filter Seperation NOA
      let SepNOAs = getNatureCode
        .filter(
          (item) =>
            item?.type == "Seperation" &&
            !["SUSPENSION"].includes(item?.NATURE_TITLE?.trim())
        )
        .map((i) => {
          return {
            label: i?.NATURE_TITLE?.trim(),
            key: i?.NATURE_TITLE?.trim(),
            width: "25px",
            bg_color: "white",
          };
        });

      // setAppointmentNOAs(AppNOAs);
      // setSeparationNOAs(SepNOAs);
      let headers = [
        {
          label: "DEPARTMENT",
          key: "DEPARTMENT",
          width: "150px",
          bg_color: "white",
          writingMode: true,
          bg_row_color: "#d9d9d9",
          fontSize: "10px",
          align: "center",
        },
        {
          label: `FTE ON-BOARD ${moment(StartDate).format("MM-DD-YY")}`,
          key: "FTE",
          width: "55px",
          bg_color: colorBackground1 ? colorBackground1 : "#fff44c",
          writingMode: true,
          bg_row_color: colorBackground1 ? colorBackground1 : "#fff44c",
          align: "center",
        },
        ...AppNOAs,
        {
          label: "Appointments",
          key: "Appointments",
          width: "40px",
          bg_color: colorBackground1 ? colorBackground1 : "#fff44c",
          bg_row_color: colorBackground1 ? colorBackground1 : "#fff44c",
        },
        ...SepNOAs,
        {
          label: "Separations",
          key: "Separations",
          width: "40px",
          bg_color: colorBackground1 ? colorBackground1 : "#fff44c",
          bg_row_color: colorBackground1 ? colorBackground1 : "#fff44c",
        },
        {
          label: `Current On-Board`,
          key: "Current_FTE",
          width: "55px",
          bg_color: colorBackground1 ? colorBackground1 : "#fff44c",
          writingMode: true,
          bg_row_color: colorBackground1 ? colorBackground1 : "#fff44c",
          align: "center",
        },
        {
          label: "Attrition Rate",
          key: "Attrition Rate",
          width: "60px",
          writingMode: true,
          bg_row_color: "#d9d9d9",
          color: "red",
          align: "center",
        },
      ];

      setRowHeader(headers);

      const getDept = departmentOption.filter((item) =>
        selectedDept.includes(item.Attriation_Name)
      );

      let tableData = getDept?.map((item) => {
        let fainalData = {
          DEPARTMENT: item.Attriation_Name,
          FTE: item.FTE,
          // Current_FTE: getFTE.find(
          //   (i) => i.DepartmentName == item.Attriation_Name
          // )?.Current_FTE,
        };

        let filterDepartmentNOA = getNOAValue.filter(
          (i) =>
            i.dept == item.Attriation_Name &&
            !["SUSPENSION"].includes(i?.NATURE_TITLE?.trim())
        );

        if (filterDepartmentNOA.length) {
          filterDepartmentNOA.map((i) => {
            fainalData = {
              ...fainalData,
              [i.NATURE_TITLE?.trim()]: i.total,
            };
          });
        }

        fainalData.Separations = 0;
        if (filterDepartmentNOA.length) {
          fainalData.Appointments = filterDepartmentNOA
            .filter((ite) => ite.type == "Appointment")
            .reduce((acc, datas) => {
              return acc + parseInt(datas.total);
            }, 0);
          fainalData.Separations = filterDepartmentNOA
            .filter((ite) => ite.type == "Separation")
            .reduce((acc, datas) => {
              return acc + parseInt(datas.total);
            }, 0);
        }

        fainalData.Current_FTE =
          (fainalData?.FTE || 0) +
          (fainalData?.Appointments || 0) -
          (fainalData?.Separations || 0);

        // fainalData.FTE = fainalData?.FTE
        // (fainalData?.Current_FTE || 0) -
        // (fainalData?.Appointments || 0) +
        // (fainalData?.Separations || 0);

        // fainalData["Attrition Rate"] =
        //   fainalData?.FTE + fainalData?.Current_FTE > 0
        //     ? parseFloat(
        //         (parseInt(fainalData?.Separations) /
        //           ((parseInt(fainalData?.FTE || 0) +
        //             parseInt(fainalData?.Current_FTE || 0)) /
        //             2) || 0) * 100
        //       ).toFixed(2)
        //     : (0.0).toFixed(2);

        fainalData["Attrition Rate"] = parseFloat(
          (fainalData?.Separations /
            (((fainalData?.FTE || 0) + (fainalData?.Current_FTE || 0)) / 2)) *
            100
        ).toFixed(2);
        // fainalData?.Separations + fainalData?.Current_FTE != 0
        // ?
        // parseFloat(
        //   ((parseFloat(fainalData?.Current_FTE || 0) -
        //     parseFloat(fainalData?.FTE || 0)) /
        //     parseFloat(fainalData?.FTE)) *
        //     100
        // ).toFixed(2);
        // : (0.0).toFixed(2);

        // calcualte FTE
        return fainalData;
      });

      setRowData(tableData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetDept();
  }, []);

  const GetDept = async () => {
    const { results: departmentOptionList } = await post(
      `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global`,
      {
        tableName: "vw_dpdv_attriation",
        searchParams: "[]",
        columns: "*",
        pageNumber: 1,
        pageSize: 1000,
        sortData: "Attriation_Name asc",
        additionalWhere: "",
        qID: 0,
        role: "",
        location: "global",
        id: "vw_dpdv_attriation",
      }
    );
    if (departmentOptionList.length) {
      setDepartmentOption(departmentOptionList);
      setSelectedDept(departmentOptionList.map((i) => i.Attriation_Name));
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const handleChange = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      let AllID =
        selectedDept.length === departmentOption.length
          ? []
          : departmentOption.map((i) => i.Attriation_Name);
      setSelectedDept(AllID);
      return;
    }
    setSelectedDept(event.target.value);
  };

  return (
    <div>
      <Container fluid className="rounded bg-white mt-3">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            padding: "10px",
          }}
        >
          {/* <Input
          type="color"
          id="Column Color"
          name="Color-Background1"
          style={{ width: "40px", height: "25px" }}
          value={colorBackground1}
          onChange={(e) => handleUnlimatedColor1Change(e)}
        />
        <Label for="Column Color" style={{ margin: "5px 5px 5px 0px" }}>
          Column Color
        </Label>
        <Input
          type="color"
          id="Strip Color"
          name="Strip Color"
          style={{ width: "40px", height: "25px" }}
          value={colorStrip}
          onChange={(e) => handleColorStripChange(e)}
        />
        <Label for="Strip Color" style={{ margin: "5px" }}>
          Strip Color
        </Label> */}

          <div
            style={{
              display: "flex",
              width: "100%",
              // justifyContent: "space-between",
            }}
          >
            <div style={{ width: "100%" }}>
              <Row>
                {/* <Col>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      size="small"
                      inputVariant={"outlined"}
                      margin="dense"
                      format="MM/dd/yyyy"
                      label={"Start Date"}
                      value={StartDate}
                      KeyboardButtonProps={{ "aria-label": "Start Date" }}
                      onChange={(value) => {
                        const formatDate = value?.$d;
                        const date = formatDate
                          ? moment(formatDate).format("YYYY-MM-DD")
                          : null;
                        setStartDate(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          margin="dense"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col> */}
                {/* <Col md={3} style={{ paddingTop: "20px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      size="small"
                      inputVariant={"outlined"}
                      margin="dense"
                      format="MM/dd/yyyy"
                      label={"FTE Date"}
                      value={FTEDate}
                      KeyboardButtonProps={{ "aria-label": "FTE Date" }}
                      onChange={(value) => {
                        const formatDate = value?.$d;
                        const date = formatDate
                          ? moment(formatDate).format("YYYY-MM-DD")
                          : null;
                        setFTEDate(date);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          margin="dense"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col> */}
                <Col md={4}>
                  <SelectMultipleCheckBox
                    labelName="Departments"
                    name="department"
                    handleChange={handleChange}
                    setSelected={setSelectedDept}
                    selectUpdate={selectedDept}
                    selected={selectedDept}
                    options={departmentOption.map((item) => {
                      return {
                        label: item.Attriation_Name,
                        value: item.Attriation_Name,
                      };
                    })}
                  ></SelectMultipleCheckBox>{" "}
                </Col>
                <Col md={3} style={{ paddingTop: "20px" }}>
                  <Button
                    variant="contained"
                    style={{ color: "white" }}
                    onClick={() => GetDepartmentData()}
                    type="button"
                    className="bg-primary mt-2"
                  >
                    Generate Report
                  </Button>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    paddingLeft: "20px",
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "end",
                    }}
                  > */}
                  <IconButton
                    onClick={() => {
                      handlePrint();
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                  {/* </div> */}
                </Col>
              </Row>
            </div>
            {/* <div></div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                size="small"
                inputVariant={"outlined"}
                margin="dense"
                format="MM/dd/yyyy"
                label={"FTE Date"}
                value={FTEDate}
                KeyboardButtonProps={{ "aria-label": "FTE Date" }}
                onChange={(value) => {
                  const formatDate = value?.$d;
                  const date = formatDate
                    ? moment(formatDate).format("YYYY-MM-DD")
                    : null;
                  setFTEDate(date);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    margin="dense"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  handlePrint();
                }}
              >
                <PrintIcon />
              </IconButton>
            </div> */}
          </div>
        </div>
        <hr />
        {loading ? (
          <LoadingPanel />
        ) : (
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid xs={12} md={12}>
              <div
                ref={printRef}
                component={Paper}
                style={{ marginBottom: "50px" }}
              >
                <style type="text/css" media="print">
                  {/* {"@page {scale:50}"} */}
                </style>
                <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
                  <center>
                    <h5>
                      <b>
                        DEPARTMENT OF ADMINISTRATION (DOA)<br></br>
                        Line Agency - Attrition Report
                      </b>
                    </h5>
                  </center>
                </div>
                <table
                  style={{
                    width: "100%",
                    marginLeft: "1px",
                    marginRight: "5px",
                    borderCollapse: "collapse",
                    // borderLeft: "1px solid darkgrey",
                    // borderTop: "1px solid darkgrey",
                    borderRadius: "25px",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderCollapse: "collapse",
                        // border: "1px solid darkgrey",
                      }}
                    >
                      {rowHeader.map((item) => (
                        <th
                          style={{
                            lineHeight: "1",
                            // borderRight: "1px solid darkgrey",
                            // borderBottom: "1px solid darkgrey",
                            writingMode: item.writingMode ? "" : "vertical-rl",
                            width: item.width ? item.width : "25px",
                            height: "200px",
                            wordWrap: "break-word",
                            textAlign: item.align ? item.align : "right",
                            backgroundColor: item.bg_color
                              ? item.bg_color
                              : "white",
                            fontSize: "10px",
                            padding: "3px",
                            border: "1px solid darkgrey",
                            borderCollapse: "collapse",
                          }}
                        >
                          {item.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData && rowData.length > 0 ? (
                      rowData.map((item, i) => (
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {rowHeader.map((j) => (
                            <td
                              style={{
                                backgroundColor: j.bg_row_color
                                  ? j.bg_row_color
                                  : (i + 1) % 2 == 0
                                  ? colorStrip
                                    ? colorStrip
                                    : "#ddb7b7"
                                  : "white",
                                lineHeight: "1.2",
                                width: j.width ? j.width : "25px",
                                textAlign:
                                  j?.key == "DEPARTMENT" ? "right" : "center",
                                textWrap: "wrap",
                                fontSize: j.fontSize ? j.fontSize : "12px",
                                color: j.color ? j.color : `black`,
                                border: "1px solid darkgrey",
                                borderCollapse: "collapse",
                              }}
                            >
                              {item[j.key]}
                              {j.key == "Attrition Rate" ? " %" : ""}
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          style={{
                            display: "flex",
                            borderBottom: "1px solid darkgrey",
                            justifyContent: "center",
                          }}
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                    {rowData && rowData.length > 0 ? (
                      <tr
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {rowHeader.map((j) => (
                          <td
                            style={{
                              backgroundColor: j.bg_row_color
                                ? j.bg_row_color
                                : colorStrip
                                ? colorStrip
                                : "#ddb7b7",
                              // borderRight: "1px solid darkgrey",
                              // borderBottom: "1px solid darkgrey",
                              lineHeight: "1.2",
                              width: j.width ? j.width : "25px",
                              textAlign: "center",
                              textWrap: "wrap",
                              fontSize: j.fontSize ? j.fontSize : "12px",
                              color: j.color ? j.color : `black`,
                              border: "1px solid darkgrey",
                              borderCollapse: "collapse",
                            }}
                          >
                            {j.key == "DEPARTMENT"
                              ? "Total"
                              : j.key == "Attrition Rate"
                              ? (
                                  (rowData.reduce(
                                    (acc, sumData) =>
                                      acc +
                                      parseFloat(sumData["Separations"] || 0),
                                    0
                                  ) /
                                    ((rowData.reduce(
                                      (acc, sumData) =>
                                        acc + parseFloat(sumData["FTE"] || 0),
                                      0
                                    ) +
                                      rowData.reduce(
                                        (acc, sumData) =>
                                          acc +
                                          parseFloat(
                                            sumData["Current_FTE"] || 0
                                          ),
                                        0
                                      )) /
                                      2)) *
                                  100
                                ).toFixed(2) + " % "
                              : rowData.reduce(
                                  (acc, sumData) =>
                                    acc + parseFloat(sumData[j.key] || 0),
                                  0
                                )}{" "}
                          </td>
                        ))}
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default AttrtionTable;
