import {
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_SUCCESS,
  ADVANCE_SEARCH_FAIL,
  ADVANCE_SEARCH_POSITION,
  ADVANCE_SEARCH_POSITION_SUCCESS,
  ADVANCE_SEARCH_POSITION_FAIL,
  ADVANCE_SEARCH_STATUS,
  ADVANCE_SEARCH_CALCULATE_SVC,
  ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS,
  ADVANCE_SEARCH_CALCULATE_SVC_FAIL,
  ADVANCE_SEARCH_TOTAL_SVC,
  ADVANCE_SEARCH_TOTAL_SVC_SUCCESS,
  ADVANCE_SEARCH_TOTAL_SVC_FAIL,
  ADVANCE_SEARCH_STATUS_SUCCESS,
  ADVANCE_SEARCH_STATUS_FAIL,
  ADVANCE_SEARCH_EMPL_TYPE,
  ADVANCE_SEARCH_EMPL_TYPE_SUCCESS,
  ADVANCE_SEARCH_EMPL_TYPE_FAIL,
  ADVANCE_SEARCH_ETHINIC,
  ADVANCE_SEARCH_ETHINIC_SUCCESS,
  ADVANCE_SEARCH_ETHINIC_FAIL,
  ADVANCE_SEARCH_EDUCATION,
  ADVANCE_SEARCH_EDUCATION_SUCCESS,
  ADVANCE_SEARCH_EDUCATION_FAIL,
  ADVANCE_SEARCH_CITIZEN,
  ADVANCE_SEARCH_CITIZEN_SUCCESS,
  ADVANCE_SEARCH_CITIZEN_FAIL,
  ADVANCE_SEARCH_MILITARY,
  ADVANCE_SEARCH_MILITARY_SUCCESS,
  ADVANCE_SEARCH_MILITARY_FAIL,
  ADVANCE_SEARCH_DEPARTMENT,
  ADVANCE_SEARCH_DEPARTMENT_SUCCESS,
  ADVANCE_SEARCH_DEPARTMENT_FAIL,
  ADVANCE_SEARCH_RETIREMENT,
  ADVANCE_SEARCH_RETIREMENT_SUCCESS,
  ADVANCE_SEARCH_RETIREMENT_FAIL,
  ADVANCE_SEARCH_APPROPRIATION,
  ADVANCE_SEARCH_APPROPRIATION_SUCCESS,
  ADVANCE_SEARCH_APPROPRIATION_FAIL,
  LOAD_COLUMNS,
  LOAD_COLUMNS_SUCCESS,
  LOAD_COLUMNS_FAIL,
  FINDASYOUTYPE,
  FINDASYOUTYPE_SUCCESS,
  FINDASYOUTYPE_FAIL,
  SAVE_ADVANCE_SEARCH,
  SAVE_ADVANCE_SEARCH_SUCCESS,
  SAVE_ADVANCE_SEARCH_FAIL,
  GET_ADVANCE_SEARCH_BYID,
  GET_ADVANCE_SEARCH_BYID_SUCCESS,
  GET_ADVANCE_SEARCH_BYID_FAIL,
  SHOWN_TOASTER,
  GET_ALL_ADVANCE_SEARCH,
  GET_ALL_ADVANCE_SEARCH_SUCCESS,
  GET_ALL_ADVANCE_SEARCH_FAIL,
  SAVE_QUERY,
  SAVE_LOCATION,
  DELETE_SAVED_QUERY,
  DELETE_SAVED_QUERY_SUCCESS,
  DELETE_SAVED_QUERY_FAIL,
  GG1_EXCEL_REPORT,
  GG1_EXCEL_REPORT_SUCCESS,
  GG1_EXCEL_REPORT_FAIL,
  SALARY_INCREMENT,
  SALARY_INCREMENT_SUCCESS,
  SALARY_INCREMENT_FAIL,
  PAYGRADE_CALCULATE,
  PAYGRADE_CALCULATE_SUCCESS,
  PAYGRADE_CALCULATE_FAIL,
} from "./actionTypes";
export const storeActiveData = (payload) => {
  return {
    type: "STORED_ACTIVE_DATA",
    payload: payload,
  };
};
export const storeColumns = (payload) => {
  return {
    type: "STORED_COLUMNS",
    payload: payload,
  };
};
export const paygradeCalc = (parameters, currentID) => {
  return {
    type: PAYGRADE_CALCULATE,
    payload: parameters,
    currentID: currentID,
  };
};

export const paygradeCalcSuccess = (results) => {
  return {
    type: PAYGRADE_CALCULATE_SUCCESS,
    payload: results,
  };
};

export const paygradeCalcFail = (error) => {
  return {
    type: PAYGRADE_CALCULATE_FAIL,
    payload: error,
  };
};
export const advanceSearch = (parameters, currentID) => {
  return {
    type: ADVANCE_SEARCH,
    payload: parameters,
    currentID: currentID,
  };
};

export const advanceSearchSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_SUCCESS,
    payload: results,
  };
};

export const advanceSearchFail = (error) => {
  return {
    type: ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};
export const salaryincrement = (parameters, currentID) => {
  return {
    type: SALARY_INCREMENT,
    payload: parameters,
    currentID: currentID,
  };
};

export const salaryincrementSuccess = (results) => {
  return {
    type: SALARY_INCREMENT_SUCCESS,
    payload: results,
  };
};

export const salaryincrementFail = (error) => {
  return {
    type: SALARY_INCREMENT_FAIL,
    payload: error,
  };
};

export const advanceSearchMilitary = (parameters) => {
  return {
    type: ADVANCE_SEARCH_MILITARY,
    payload: parameters,
  };
};
export const advanceSearchMilitarySuccess = (parameters) => {
  return {
    type: ADVANCE_SEARCH_MILITARY_SUCCESS,
    payload: parameters,
  };
};
export const advanceSearchMilitaryFail = (parameters) => {
  return {
    type: ADVANCE_SEARCH_MILITARY_FAIL,
    payload: parameters,
  };
};
export const advanceSearchDepartment = (parameters) => {
  return {
    type: ADVANCE_SEARCH_DEPARTMENT,
    payload: parameters,
  };
};
export const advanceSearchDepartmentSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_DEPARTMENT_SUCCESS,
    payload: results,
  };
};
export const advanceSearchDepartmentFail = (error) => {
  return {
    type: ADVANCE_SEARCH_DEPARTMENT_FAIL,
    payload: error,
  };
};
export const advanceSearchCalculateSVC = (parameters) => {
  return {
    type: ADVANCE_SEARCH_CALCULATE_SVC,
    payload: parameters,
  };
};
export const advanceSearchCalculateSVCSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS,
    payload: results,
  };
};
export const advanceSearchCalculateSVCFail = (error) => {
  return {
    type: ADVANCE_SEARCH_CALCULATE_SVC_FAIL,
    payload: error,
  };
};
export const advanceSearchTotalSVC = (parameters) => {
  return {
    type: ADVANCE_SEARCH_TOTAL_SVC,
    payload: parameters,
  };
};
export const advanceSearchTotalSVCSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_TOTAL_SVC_SUCCESS,
    payload: results,
  };
};
export const advanceSearchTotalSVCFail = (error) => {
  return {
    type: ADVANCE_SEARCH_TOTAL_SVC_FAIL,
    payload: error,
  };
};
export const advanceSearchRetirement = (parameters) => {
  return {
    type: ADVANCE_SEARCH_RETIREMENT,
    payload: parameters,
  };
};
export const advanceSearchRetirementSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_RETIREMENT_SUCCESS,
    payload: results,
  };
};
export const advanceSearchRetirementFail = (error) => {
  return {
    type: ADVANCE_SEARCH_RETIREMENT_FAIL,
    payload: error,
  };
};

export const advanceSearchAppropriation = (parameters) => {
  return {
    type: ADVANCE_SEARCH_APPROPRIATION,
    payload: parameters,
  };
};
export const advanceSearchAppropriationSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_APPROPRIATION_SUCCESS,
    payload: results,
  };
};
export const advanceSearchAppropriationFail = (error) => {
  return {
    type: ADVANCE_SEARCH_APPROPRIATION_FAIL,
    payload: error,
  };
};
export const advanceSearchPosition = (parameters) => {
  return {
    type: ADVANCE_SEARCH_POSITION,
    payload: parameters,
  };
};

export const advanceSearchPositionSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_POSITION_SUCCESS,
    payload: results,
  };
};

export const advanceSearchPositionFail = (error) => {
  return {
    type: ADVANCE_SEARCH_POSITION_FAIL,
    payload: error,
  };
};
export const advanceSearchStatus = (parameters) => {
  return {
    type: ADVANCE_SEARCH_STATUS,
    payload: parameters,
  };
};

export const advanceSearchStatusSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_STATUS_SUCCESS,
    payload: results,
  };
};

export const advanceSearchStatusFail = (error) => {
  return {
    type: ADVANCE_SEARCH_STATUS_FAIL,
    payload: error,
  };
};
export const advanceSearchEmplType = (parameters) => {
  return {
    type: ADVANCE_SEARCH_EMPL_TYPE,
    payload: parameters,
  };
};

export const advanceSearchEmplTypeSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_EMPL_TYPE_SUCCESS,
    payload: results,
  };
};

export const advanceSearchEmplTypeFail = (error) => {
  return {
    type: ADVANCE_SEARCH_EMPL_TYPE_FAIL,
    payload: error,
  };
};
export const advanceSearchEducation = (parameters) => {
  return {
    type: ADVANCE_SEARCH_EDUCATION,
    payload: parameters,
  };
};

export const advanceSearchEducationSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_EDUCATION_SUCCESS,
    payload: results,
  };
};

export const advanceSearchEducationFail = (error) => {
  return {
    type: ADVANCE_SEARCH_EDUCATION_FAIL,
    payload: error,
  };
};
export const advanceSearchCitizen = (parameters) => {
  return {
    type: ADVANCE_SEARCH_CITIZEN,
    payload: parameters,
  };
};

export const advanceSearchCitizenSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_CITIZEN_SUCCESS,
    payload: results,
  };
};

export const advanceSearchCitizenFail = (error) => {
  return {
    type: ADVANCE_SEARCH_CITIZEN_FAIL,
    payload: error,
  };
};
export const advanceSearchEthinic = (parameters) => {
  return {
    type: ADVANCE_SEARCH_ETHINIC,
    payload: parameters,
  };
};

export const advanceSearchEthinicSuccess = (results) => {
  return {
    type: ADVANCE_SEARCH_ETHINIC_SUCCESS,
    payload: results,
  };
};

export const advanceSearchEthinicFail = (error) => {
  return {
    type: ADVANCE_SEARCH_ETHINIC_FAIL,
    payload: error,
  };
};
export const SaveQuery = (parameters) => {
  return {
    type: SAVE_QUERY,
    payload: parameters,
  };
};
export const SaveLocation = (parameters) => {
  return {
    type: SAVE_LOCATION,
    payload: parameters,
  };
};

export const gg1ExcelReport = (payload) => {
  return {
    type: GG1_EXCEL_REPORT,
    payload,
  };
};
export const gg1ExcelReportSuccess = (results) => {
  return {
    type: GG1_EXCEL_REPORT_SUCCESS,
    payload: results,
  };
};
export const gg1ExcelReportFail = (error) => {
  return {
    type: GG1_EXCEL_REPORT_FAIL,
    payload: error,
  };
};

export const saveAdvanceSearch = (parameters, currentID) => {
  return {
    type: SAVE_ADVANCE_SEARCH,
    payload: parameters,
    currentID: currentID,
  };
};

export const saveAdvanceSearchSuccess = (results, currentID) => {
  return {
    type: SAVE_ADVANCE_SEARCH_SUCCESS,
    payload: results,
    currentID: currentID,
  };
};

export const saveAdvanceSearchFail = (error) => {
  return {
    type: SAVE_ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};
export const savedIsToasterShown = (payload) => {
  return {
    type: SHOWN_TOASTER,
    payload: payload,
  };
};

export const deleteSearchSaveQuery = (payload) => {
  return {
    type: DELETE_SAVED_QUERY,
    payload: payload,
  };
};

export const deleteSearchSaveQuerySuccess = (results) => {
  return {
    type: DELETE_SAVED_QUERY_SUCCESS,
    payload: results,
  };
};

export const deleteSearchSaveQueryFail = (error) => {
  return {
    type: DELETE_SAVED_QUERY_FAIL,
    payload: error,
  };
};
export const getAllAdvanceSearch = (parameters) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH,
    payload: parameters,
  };
};

export const getAllAdvanceSearchSuccess = (results) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH_SUCCESS,
    payload: results,
  };
};

export const getAllAdvanceSearchFail = (error) => {
  return {
    type: GET_ALL_ADVANCE_SEARCH_FAIL,
    payload: error,
  };
};

export const getAdvanceSearchById = (parameters, params) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID,
    payload: parameters,
    params: params,
  };
};

export const getAdvanceSearchByIdSuccess = (results) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID_SUCCESS,
    payload: results,
  };
};

export const getAdvanceSearchByIdFail = (error) => {
  return {
    type: GET_ADVANCE_SEARCH_BYID_FAIL,
    payload: error,
  };
};

export const loadColumns = (tableName) => {
  return {
    type: LOAD_COLUMNS,
    payload: { tableName },
  };
};

export const loadColumnsSuccess = (results) => {
  return {
    type: LOAD_COLUMNS_SUCCESS,
    payload: results,
  };
};

export const loadColumnsFail = (error) => {
  return {
    type: LOAD_COLUMNS_FAIL,
    payload: error,
  };
};

export const findAsyouType = (payload) => {
  return {
    type: FINDASYOUTYPE,
    payload,
  };
};
export const findAsyouTypeSuccess = (results) => {
  return {
    type: FINDASYOUTYPE_SUCCESS,
    payload: results,
  };
};
export const findAsYouTypeFail = (error) => {
  return {
    type: FINDASYOUTYPE_FAIL,
    payload: error,
  };
};
