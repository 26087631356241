import { Grid, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ParamsForm from "./paramsForm";
import DynamicTemplate from "./dynamicTemplate";

const ViewTemplate = ({ currentTemplate, onSave, onCancel, addParams }) => {
  const [content, setContent] = useState("");

  const [leftPaperHeight, setLeftPaperHeight] = useState("600px");
  const [rightPaperHeight, setRightPaperHeight] = useState("600px");
  useEffect(() => {
    if (currentTemplate.templateBody) setContent(currentTemplate.templateBody);
  }, []);

  useEffect(() => {
    const leftPaper = document.getElementById("left-paper");
    const rightPaper = document.getElementById("right-paper");

    if (leftPaper && rightPaper) {
      const newLeftPaperHeight = `${leftPaper.offsetHeight}px`;
      const newRightPaperHeight = `${rightPaper.offsetHeight}px`;

      if (newLeftPaperHeight !== leftPaperHeight) {
        setLeftPaperHeight(newLeftPaperHeight);
      }

      if (newRightPaperHeight !== rightPaperHeight) {
        setRightPaperHeight(newRightPaperHeight);
      }
    }
  }, [content, currentTemplate]);

  const templateStringChangeHandler = useCallback((toReplaceObj) => {
    const tempReplcaeObj = { ...toReplaceObj };
    let tempContent = currentTemplate.templateBody.toString();
    for (const singleParam in tempReplcaeObj) {
      const escapedKey = singleParam.replace(
        /([.*+?^=!:${}()|\[\]\/\\])/g,
        "\\$1"
      );
      const regex = new RegExp(`\\[@${escapedKey}\\]`, "g");
      tempContent = tempContent.replace(
        regex,
        tempReplcaeObj[singleParam].toString()
      );
    }
    setContent(tempContent);
  }, []);

  const getHtmlandFields = (data) => {
    onSave(data, content);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={6} style={{ borderRight: "4px solid #ccc" }}>
          <Paper
            id="left-paper"
            style={{
              minHeight: leftPaperHeight,
              padding: 10,
              overflow: "auto",
            }}
          >
            {content && <DynamicTemplate templateString={content} />}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            id="right-paper"
            style={{
              minHeight: rightPaperHeight,
              padding: 20,
              overflow: "auto",
            }}
          >
            {currentTemplate.params && (
              <ParamsForm
                parameters={currentTemplate.params}
                templateStringChangeHandler={templateStringChangeHandler}
                onSave={onSave ? getHtmlandFields : undefined}
                onCancel={onCancel}
                addParams={addParams}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewTemplate;
