import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import index from "./index.css";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { useAuth } from "oidc-react";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { ShowError } from "../../_helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { faAdd, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Btn, H5 } from "../../AbstractElements";
import dayjs from "dayjs";
import { getDocumentAttached } from "../../store/employees/actions";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  listHeight: {
    height: "calc(100vh - 100px - 317px)",
    maxHeight: "calc(100vh - 100px - 317px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 180px)",
      maxHeight: "calc(100vh - 100px - 180px)",
    },
  },
  listHeightError: {
    height: "calc(100vh - 100px - 256px)",
    maxHeight: "calc(100vh - 100px - 256px)",
    overflow: "auto",
    "@media screen and (max-width: 767px)": {
      height: "calc(100vh - 100px - 239px)",
      maxHeight: "calc(100vh - 100px - 239px)",
    },
  },
}));

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 10px;
  margin: 25px;
  align-items: center;
`;

const Label = styled.label`
  grid-column: 1;
`;
function PositionForm(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    onCreateCertificate,
    onUpdateCertificate,
    handleChange,
    positionList,
    isTag,
    title,
  } = props;
  const [filterText, setFilterText] = useState("");
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [loadAttachFiles, setLoadAttachFiles] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const { employeeID } = useParams();
  const { userData } = useAuth();

  const handleReset = () => {
    setLoadAttachFiles([]);
    setValidFrom(null);
    setValidTo(null);
  };
  const toggleDialog = () => {
    setOpenModal(!openModal);
    handleReset();
  };
  const { document } = useSelector((state) => state.employeeReducer);
  useEffect(() => {
    if (data?.certificationID || data?.tagID) {
      setValidFrom(data?.validFrom);
      setValidTo(data?.validTo);
    }
  }, [data]);
  useEffect(() => {
    if (!isTag && data !== null && data?.certificationID !== null) {
      dispatch(getDocumentAttached(data?.certificationID));
    }
  }, [data]);
  useEffect(() => {
    if (document !== null && Object.keys(document).length > 0) {
      setLoadAttachFiles(document);
    }
  }, [document]);
  const handleDelete = (id) => {
    let filteredDocument = [...loadAttachFiles];
    filteredDocument.splice(id, 1);
    setLoadAttachFiles(filteredDocument);
  };
  const handleConfirm = () => {
    if (!validFrom || !validTo) {
      return;
    } else if (dayjs(validFrom).isBefore(validTo, "day")) {
    } else {
      ShowError(
        "ValidTo date should not be less than or equal to ValidFrom date"
      );
    }
    let payload = {
      id: data?.tagID ? data.tagID : 0,
      employeeId: Number(employeeID || userData?.profile?.EmployeeID),
      tagCode: data?.code,

      validFrom: dayjs(validFrom).format(`YYYY-MM-DD HH:mm:ssZ`),
      validTo: dayjs(validTo).format(`YYYY-MM-DD HH:mm:ssZ`),
      isActive:
        data?.isActive === null || data?.isActive === false ? true : false,
      remarks: "string",
    };
    let form = new FormData();
    form.append("ID", data?.certificationID ? data.certificationID : 0);
    form.append(
      "EmployeeId",
      Number(employeeID || userData?.profile?.EmployeeID)
    );
    form.append("CertificationCode", data?.code);
    form.append("ValidFrom", dayjs(validFrom).format(`YYYY-MM-DD HH:mm:ssZ`));
    form.append("ValidTo", dayjs(validTo).format(`YYYY-MM-DD HH:mm:ssZ`));
    form.append(
      "IsActive",
      data?.isActive === null || data?.isActive === false ? true : false
    );
    form.append("Remarks", "string");
    form.append("File", file);

    if (data?.certificationID || data?.tagID) {
      dispatch(
        onUpdateCertificate(
          isTag ? payload : form,
          data?.certificationID || data?.tagID
        )
      );
      setApiCalled(true);
    } else {
      dispatch(onCreateCertificate(isTag ? payload : form));
      setApiCalled(true);
    }
    toggleDialog();
  };
  useEffect(() => {
    let items = [];
    // apiCalled &&
    positionList.forEach((item) => {
      if (item?.isActive === true) {
        items.push(item?.certificateName || item?.tagName);
      }
    });
    setSelectedPositions(items);
  }, [positionList]);
  const handleUpload = (e) => {
    const files = e.target.files[0];
    setFile(files);
  };
  console.log("selectedPositions", selectedPositions);
  return (
    <div className="flex w-full flex-col">
      <Typography className="flex w-full justify-center py-8 bg-gray-200 text-gray-600 text-18">
        {`${isTag ? "Tags" : "Certificates"} in which you are interested`}
      </Typography>
      <TextField
        fullWidth
        size="small"
        className="m-b-10"
        label={`Find ${isTag ? "tags" : "certificates"}`}
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />

      <div className="d-flex align-content-start flex-wrap">
        {openModal && (
          <Dialog
            title={
              <H5
                attrH5={{
                  style: {
                    display: isTag ? "none" : null,
                  },
                }}
              >
                Upload Certificate
              </H5>
            }
            onClose={toggleDialog}
          >
            <GridContainer>
              <Label htmlFor="validFrom">Valid From:</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  id="validFrom"
                  value={validFrom}
                  onChange={(date) => setValidFrom(date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </LocalizationProvider>
              <Label htmlFor="validTo">Valid To:</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  id="validTo"
                  value={validTo}
                  onChange={(date) => setValidTo(date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </LocalizationProvider>
              {isTag ? null : (
                <>
                  <Label htmlFor="my-file">Attach Certificate:</Label>
                  <input
                    type="file"
                    name="file"
                    id="my-file"
                    onChange={handleUpload}
                  />
                  {document && document.length > 0 ? (
                    <>
                      {" "}
                      <Label for="list" />
                      <ul>
                        {loadAttachFiles && loadAttachFiles.length
                          ? loadAttachFiles.map((item, index) => {
                              return (
                                <li key={IndeterminateCheckBoxRounded}>
                                  <a
                                    target="_blank"
                                    href={`https://api.hr.ehrpweb.com/uploads/${item?.documentName}`}
                                  >
                                    {item?.documentName}
                                  </a>
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => handleDelete(index)}
                                    className="font-primary"
                                    style={{
                                      width: "1em",
                                      height: "1em",
                                      marginTop: "6px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </li>
                              );
                            })
                          : null}{" "}
                      </ul>{" "}
                    </>
                  ) : null}
                </>
              )}
            </GridContainer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    handleConfirm();
                  },
                  style: {
                    margin: "6px",
                  },
                }}
              >
                <FontAwesomeIcon icon={faAdd} style={{ paddingRight: "6px" }} />
                Save
              </Btn>
              {document && document.length > 0 ? (
                <Btn
                  attrBtn={{
                    color: "primary",
                    onClick: () => {
                      handleReset();
                    },
                    style: {
                      margin: "6px",
                    },
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ paddingRight: "6px" }}
                  />
                  Delete
                </Btn>
              ) : null}
            </div>
          </Dialog>
        )}
        {positionList
          .filter((item) => {
            return (
              selectedPositions &&
              selectedPositions.find(
                (d) => d === (item.tagName || item?.certificateName)
              ) != null
            );
          })
          .map((i, index) => {
            return (
              <Tooltip
                title={
                  i?.isActive
                    ? `${dayjs(i?.validFrom).format(
                        "DD/MM/YYYY hh:mm A"
                      )}-${dayjs(i?.validTo).format("DD/MM/YYYY hh:mm A")}`
                    : null
                }
              >
                <div className="p-1">
                  <input
                    type="checkbox"
                    className="btn-check font-primary"
                    value={i?.certificateName || i?.tagName}
                    tabIndex={-1}
                    name={title}
                    key={title + index}
                    id={title + (i?.certificateName || i?.tagName)}
                    onClick={() => {
                      setOpenModal(true);
                      setData(i);

                      if (apiCalled) {
                        let selectedItems = [...selectedPositions];
                        if (
                          selectedItems.find(
                            (d) => d === i.certificateName || i?.tagName
                          ) != null
                        )
                          selectedItems = selectedItems.filter(
                            (d) => d !== (i?.certificateName || i?.tagName)
                          );
                        else
                          selectedItems.push(i?.certificateName || i?.tagName);
                        setSelectedPositions([...selectedItems]);
                      }
                      handleChange({
                        target: {
                          value: i?.certificateName || i?.tagName,
                          name: title,
                        },
                      });
                    }}
                    checked={
                      i?.isActive === true ||
                      selectedPositions.find(
                        (d) => d === i?.certificateName || i?.tagName
                      ) != null
                    }
                    autocomplete="off"
                  />
                  <label
                    className={`btn btn-outline-primary ${
                      i?.isActive === false ||
                      (i?.validTo !== null
                        ? dayjs(i?.validTo).isBefore(dayjs())
                        : null)
                        ? "isUnActive"
                        : "isActive"
                    }`}
                    htmlFor={title + (i?.certificateName || i?.tagName)}
                  >
                    {i?.certificateName || i?.tagName}
                  </label>
                </div>
              </Tooltip>
            );
          })}
        {positionList &&
          positionList.length &&
          positionList
            .filter((item) => {
              return (
                selectedPositions.find(
                  (d) => d === (item?.tagName || item?.certificateName)
                ) == null &&
                (item?.tagName || item?.certificateName)
                  ?.toLowerCase()
                  .indexOf(filterText.toLocaleLowerCase()) > -1
              );
            })
            .map((i, index) => {
              return (
                <Tooltip
                  title={
                    i?.isActive
                      ? `${dayjs(i?.validFrom).format(
                          "DD/MM/YYYY hh:mm A"
                        )}-${dayjs(i?.validTo).format("DD/MM/YYYY hh:mm A")}`
                      : null
                  }
                >
                  <div className="p-1">
                    <input
                      type="checkbox"
                      className="btn-check font-primary"
                      value={i?.certificateName || i?.tagName}
                      tabIndex={-1}
                      name={title}
                      key={title + index}
                      id={title + (i?.certificateName || i?.tagName)}
                      onClick={() => {
                        setOpenModal(true);
                        setData(i);
                        if (apiCalled === true) {
                          let selectedItems = [...selectedPositions];
                          if (
                            selectedItems.find(
                              (d) => d === i?.certificateName || i?.tagName
                            ) != null
                          )
                            selectedItems = selectedItems.filter(
                              (d) => d !== i?.certificateName || i?.tagName
                            );
                          else
                            selectedItems.push(
                              i?.certificateName || i?.tagName
                            );
                          setSelectedPositions([...selectedItems]);
                        }

                        handleChange({
                          target: {
                            value: i?.certificateName || i?.tagName,
                            name: title,
                          },
                        });
                      }}
                      checked={
                        i?.isActive === true ||
                        selectedPositions.includes(i?.certificateName) ||
                        selectedPositions.includes(i?.tagName)
                      }
                      autocomplete="off"
                    />
                    <label
                      className={`btn btn-outline-primary ${
                        i?.isActive === false ||
                        (i?.validTo
                          ? dayjs(i?.validTo).isBefore(dayjs())
                          : null)
                          ? "isUnActive"
                          : i?.isActive === true
                          ? "isActive"
                          : ""
                      }`}
                      htmlFor={title + (i?.certificateName || i?.tagName)}
                    >
                      {i?.certificateName || i?.tagName}
                    </label>
                  </div>
                </Tooltip>
              );
            })}
      </div>
    </div>
  );
}

export default PositionForm;
