import { get, post, put } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;
// export const updateMaster = ( payload) => put(`${baseURL}${url.MASTER_BASE}`, payload, {
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });
export const npagetTransactionApi = async ({ hrcontrol, employeeID }) => {
  return await get(
    `${baseURL}${url?.NPAGETTRANSACTION}/${hrcontrol}?employeeID=${employeeID}`
  );
};
export const updateMaster = async (payload) => {
  return await put(`${baseURL}${url?.MASTER_BASE}`, payload);
};
export const updateGgMaster = async (payload) => {
  return await put(`${baseURL}${url?.GG1MASTER_BASE}`, payload);
};
export const npapayroll = async (payload) => {
  return await post(`${baseURL}${url?.NPAPAYROLL}`, payload);
};
export const getEmployee = (id) => get(`${baseURL}${url.EMPLOYEE}/${id}`);
export const Employee = (id) => get(`${baseURL}${url.GET_EMPLOYEE}/${id}`);
export const findEmployee = (id) =>
  get(`${baseURL}${url.FIND_EMPLOYEE}?employeeId=${id}`);
  export const findGg1Employee = (id) =>
  get(`${baseURL}${url.FINDGG_EMPLOYEE}/${id}`);
export const employeeverification = (employeeID) =>
  get(`${baseURL}${url.EMPLOYEE_VERIFICATION}/${employeeID}`);
export const getEmployeeTypes = () => get(baseURL + url.EMPLOYEE_TYPES);
//Post
export const documentAttached = (id) =>
  get(`${baseURL}${url.DOCUMENT_ATTACHMENT_CERTIFICATE}/${id}`);
export const searchEmployee = (payload) => post(url.EMPLOYEE_SEARCH, payload);
export const verifyEmployee = (EmpID) =>
  get(url.REQ_LEAVE_APPLICATION + `/VerifyEmployee?empid=${EmpID}`);

export const advanceSearchLoadColumns = (tableName) =>
  get(url.ADVANCE_SEARCH_LOAD_COLUMNS + "/" + tableName);
export const CreateCertifications = (payload) =>
  post(baseURL + "/EmployeeCertifications", payload);
export const UpdateCertifications = async (payload, id) => {
  return await put(`${baseURL}${url?.EMPLOYEE_CERTIFICATION}/${id}`, payload);
};
export const CreateTag = (payload) =>
  post(baseURL + url?.EMPLOYEE_TAG, payload);
export const UpdateTag = async (payload, id) =>
  await put(`${baseURL}${url?.EMPLOYEE_TAG}/${id}`, payload);
export const performNewSubscription = (payload) =>
  // post(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION + "/subscribe", payload);
  post(baseURL + url.GET_EMPLOYEE + "/SubscribeDeduction", payload);
export const deductionUpdate = (payload) =>
  post(baseURL + url.GET_EMPLOYEE + "/UpdateDeduction", payload);
export const performActivateSubscription = (DeductionCode) =>
  put(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION + "/activate/" + DeductionCode);
export const performInActivateSubscription = (DeductionCode) =>
  put(url.EMPLOYEE_DEDUCTION_SUBSCRIPTION + "/deactivate/" + DeductionCode);
export const getTransactionLogs = (employeeID) =>
  get(baseURL + url.EMPLOYEE_TRANSACTIONS_LOGS + "/" + employeeID);
export const getTransactionInfo = (employeeID, logID) =>
  get(
    baseURL + url.EMPLOYEE_TRANSACTIONS_DETAILS + "/" + employeeID + "/" + logID
  );
//Post
export const getDeductionPlan = (id) =>
  get(url.EMPLOYEE_DEDUCTION, {
    params: {
      id: id,
    },
  });
export const AuditTrailHistory = async (id) =>
  await get(`${baseURL}${url.EMPLOYEEAUDIT_HISTORY}/${Number(id)}`);

export const getEmployeeList = (id) => get(`${baseURL}${url.EMPLOYEE_LIST}`);
