import {
    GET_AUDIT_LOGS,
    GET_AUDIT_LOGS_SUCCESS,
    GET_AUDIT_LOGS_FAIL,
    RESET,
    GET_AUDIT_LOG_EXPORTS,
    GET_AUDIT_LOG_EXPORTS_SUCCESS,
    GET_AUDIT_LOG_EXPORTS_FAIL,
    GET_AUDIT_LOG_DIFF,
    GET_AUDIT_LOG_DIFF_SUCCESS,
    GET_AUDIT_LOG_DIFF_FAIL,
    RESET_AUDIT_LOG_DIFF,
} from "./actionsTypes";

const initialState = {
    auditLogs: [],
    auditLogsLoading: false,
    error: {
        message: "",
    },
    exportAuditLogsLoading: false,
    exportAuditLogs: [],
    auditLogDiff: [],
    auditLogDiffLoading: false,
};

const AuditLogsReducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case GET_AUDIT_LOGS:
            state = { ...state, auditLogsLoading: true, auditLogs: [] };
            break;
        case GET_AUDIT_LOGS_SUCCESS:
            state = { ...state, auditLogs: action.payload, auditLogsLoading: false };
            break;
        case GET_AUDIT_LOGS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                auditLogsLoading: false,
            };
            break;

        case GET_AUDIT_LOG_EXPORTS:
            state = { ...state, exportAuditLogsLoading: true, exportAuditLogs: [] };
            break;
        case GET_AUDIT_LOG_EXPORTS_SUCCESS:
            state = { ...state, exportAuditLogs: action.payload, exportAuditLogsLoading: false };
            break;
        case GET_AUDIT_LOG_EXPORTS_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                exportAuditLogsLoading: false,
                exportAuditLogs: []
            };
            break;

        case GET_AUDIT_LOG_DIFF:
            state = { ...state, auditLogDiffLoading: true, auditLogDiff: [] };
            break;
        case GET_AUDIT_LOG_DIFF_SUCCESS:
            state = { ...state, auditLogDiff: action.payload, auditLogDiffLoading: false };
            break;
        case GET_AUDIT_LOG_DIFF_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                auditLogDiffLoading: false,
            };
            break;

        case RESET:
            state = { ...initialState };
            break;
        case RESET_AUDIT_LOG_DIFF:
            state = { ...state, auditLogDiff: [], auditLogDiffLoading: false, };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default AuditLogsReducer;