import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./index.css";
import * as Yup from "yup";
import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { makeStyles, MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { Col } from "reactstrap";
import { Button } from "@mui/material";

import { Row } from "reactstrap";
const validationSchema = Yup.object().shape({
  queryName: Yup.string().required("Query Name is required"),
});

const SaveNewQuery = (props) => {
  const { queryId, isDefaultQuery, userGroup, selected } = props;
  const [UserGroupData, setUserGroupData] = useState([]);
  const [value, setValue] = useState(null);
  useEffect(() => {
    const filterUserGroup = userGroup.filter(
      (d) => d.userGroupName === selected
    );
    setValue(filterUserGroup[0]);
  }, [userGroup, selected]);
  const formik = useFormik({
    initialValues: {
      queryName: props.queryName || "",
      selected: props.selected || "",
    },
    validationSchema,
    onSubmit: (values) => {
      const { queryName, selected } = values;
    },
  });
  const filterData = (filter) => {
    const filterData = userGroup;
    const data = (filterData && filterData?.slice()) || [];

    return filterBy(data, filter);
  };
  useEffect(() => {
    if (userGroup && userGroup.length > 0) {
      setUserGroupData(userGroup);
    }
  }, [userGroup]);
  const filterChange = (event) => {
    setUserGroupData(filterData(event.filter));
  };

  return (
    <Card>
      <CardContent>
        <Row>
          <Typography
            variant="h5"
            component="h5"
            style={{
              marginBottom: "16px",
              marginLeft: "2px",
              fontWeight: "bold",
              color: "#24695c",
            }}
          >
            Enter Query Details
          </Typography>
        </Row>

        <div className="mt-2 mb-5">
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <TextField
                className="mb-3"
                fullWidth
                label="Query Name"
                variant="outlined"
                name="queryName"
                value={formik.values.queryName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.queryName && Boolean(formik.errors.queryName)
                }
                helperText={formik.touched.queryName && formik.errors.queryName}
              />
            </Col>
          </Row>

          <>
            <ComboBox
              data={UserGroupData}
              textField="userGroupName"
              value={value}
              filterable={true}
              onFilterChange={filterChange}
              allowCustom={true}
              placeholder="Select Users"
              onChange={(e) => {
                if (e.value) {
                  setValue(e.value);
                } else {
                  setUserGroupData(userGroup);
                  setValue(null);
                }
              }}
            />
          </>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "4rem",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Button
              variant="contained"
              className="bg-primary"
              style={{ color: "white" }}
              onClick={() => {
                formik.handleSubmit();
                if (formik.isValid) {
                  props.onSubmit(
                    {
                      queryName: formik.values.queryName,
                      selected: value?.userGroupName,
                    },
                    { update: false }
                  );
                }
              }}
              disabled={!formik.isValid}
            >
              {queryId ? "Save as New Query" : "Save Query"}
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              className="bg-primary"
              style={{ color: "white" }}
              onClick={() => {
                formik.handleSubmit();

                if (formik.isValid) {
                  props.onSubmit(
                    {
                      queryName: formik.values.queryName,
                      selected: value?.userGroupName,
                    },
                    { update: true }
                  );
                }
              }}
              disabled={!formik.isValid}
            >
              Update
            </Button>
          </div>

          <div>
            <Button
              variant="contained"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <FormGroup>
                <FormControlLabel
                  // required
                  control={
                    <Checkbox
                      checked={isDefaultQuery}
                      onChange={props.handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Set as Default"
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </CardContent>
    </Card>
  );
};

export default SaveNewQuery;
