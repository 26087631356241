export const ADVANCE_SEARCH = "ADVANCE_SEARCHV2";
export const ADVANCE_SEARCH_SUCCESS = "ADVANCE_SEARCHV2_SUCCESS";
export const ADVANCE_SEARCH_FAIL = "ADVANCE_SEARCHV2_FAIL";
export const ADVANCE_SEARCH_RETIREMENT = "ADVANCE_SEARCH_RETIREMENT";
export const ADVANCE_SEARCH_RETIREMENT_SUCCESS =
  "ADVANCE_SEARCH_RETIREMENT_SUCCESS";
export const ADVANCE_SEARCH_RETIREMENT_FAIL = "ADVANCE_SEARCH_RETIREMENT_FAIL";
export const ADVANCE_SEARCH_CALCULATE_SVC = "ADVANCE_SEARCH_CALCULATE_SVC";
export const ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS =
  "ADVANCE_SEARCH_CALCULATE_SVC_SUCCESS";
export const ADVANCE_SEARCH_CALCULATE_SVC_FAIL =
  "ADVANCE_SEARCH_CALCULATE_SVC_FAIL";
export const ADVANCE_SEARCH_TOTAL_SVC = "ADVANCE_SEARCH_TOTAL_SVC";
export const ADVANCE_SEARCH_TOTAL_SVC_SUCCESS =
  "ADVANCE_SEARCH_TOTAL_SVC_SUCCESS";
export const ADVANCE_SEARCH_TOTAL_SVC_FAIL = "ADVANCE_SEARCH_TOTAL_SVC_FAIL";

export const ADVANCE_SEARCH_APPROPRIATION = "ADVANCE_SEARCH_APPROPRIATION";
export const ADVANCE_SEARCH_APPROPRIATION_SUCCESS =
  "ADVANCE_SEARCH_APPROPRIATION_SUCCESS";
export const ADVANCE_SEARCH_APPROPRIATION_FAIL =
  "ADVANCE_SEARCH_APPROPRIATION_FAIL";
export const ADVANCE_SEARCH_STATUS = "ADVANCE_SEARCH_STATUS";
export const ADVANCE_SEARCH_STATUS_SUCCESS = "ADVANCE_SEARCH_STATUS_SUCCESS";
export const ADVANCE_SEARCH_STATUS_FAIL = "ADVANCE_SEARCH_STATUS_FAIL";

export const SALARY_INCREMENT = "SALARY_INCREMENT";
export const SALARY_INCREMENT_SUCCESS = "SALARY_INCREMENT_SUCCESS";
export const SALARY_INCREMENT_FAIL = "SALARY_INCREMENT_FAIL";

export const PAYGRADE_CALCULATE = "PAYGRADE_CALCULATE";
export const PAYGRADE_CALCULATE_SUCCESS = "PAYGRADE_CALCULATE_SUCCESS";
export const PAYGRADE_CALCULATE_FAIL = "PAYGRADE_CALCULATE_FAIL";

export const ADVANCE_SEARCH_DEPARTMENT = "ADVANCE_SEARCH_DEPARTMENT";
export const ADVANCE_SEARCH_DEPARTMENT_SUCCESS =
  "ADVANCE_SEARCH_DEPARTMENT_SUCCESS";
export const ADVANCE_SEARCH_DEPARTMENT_FAIL = "ADVANCE_SEARCH_DEPARTMENT_FAIL";
export const ADVANCE_SEARCH_POSITION = "ADVANCE_SEARCH_POSITION";
export const ADVANCE_SEARCH_POSITION_SUCCESS =
  "ADVANCE_SEARCH_POSITION_SUCCESS";
export const ADVANCE_SEARCH_POSITION_FAIL = "ADVANCE_SEARCH_POSITION_FAIL";
export const ADVANCE_SEARCH_MILITARY = "ADVANCE_SEARCH_MILITARY";
export const ADVANCE_SEARCH_MILITARY_SUCCESS =
  "ADVANCE_SEARCH_MILITARY_SUCCESS";
export const ADVANCE_SEARCH_MILITARY_FAIL = "ADVANCE_SEARCH_MILITARY_FAIL";
export const ADVANCE_SEARCH_EMPL_TYPE = "ADVANCE_SEARCH_EMPL_TYPE";
export const ADVANCE_SEARCH_EMPL_TYPE_SUCCESS =
  "ADVANCE_SEARCH_EMPL_TYPE_SUCCESS";
export const ADVANCE_SEARCH_EMPL_TYPE_FAIL = "ADVANCE_SEARCH_EMPL_TYPE_FAIL";
export const ADVANCE_SEARCH_EDUCATION = "ADVANCE_SEARCH_EDUCATION";
export const ADVANCE_SEARCH_EDUCATION_SUCCESS =
  "ADVANCE_SEARCH_EDUCATION_SUCCESS";
export const ADVANCE_SEARCH_EDUCATION_FAIL = "ADVANCE_SEARCH_EDUCATION_FAIL";
export const ADVANCE_SEARCH_ETHINIC = "ADVANCE_SEARCH_ETHINIC";
export const ADVANCE_SEARCH_ETHINIC_SUCCESS = "ADVANCE_SEARCH_ETHINIC_SUCCESS";
export const ADVANCE_SEARCH_ETHINIC_FAIL = "ADVANCE_SEARCH_ETHINIC_FAIL";
export const ADVANCE_SEARCH_CITIZEN = "ADVANCE_SEARCH_CITIZEN";
export const ADVANCE_SEARCH_CITIZEN_SUCCESS = "ADVANCE_SEARCH_CITIZEN_SUCCESS";
export const ADVANCE_SEARCH_CITIZEN_FAIL = "ADVANCE_SEARCH_CITIZEN_FAIL";

export const SAVE_QUERY = "SAVE_QUERY";
export const LOAD_COLUMNS = "ADVANCE_SEARCHV2_LOAD_COLUMNS";
export const LOAD_COLUMNS_SUCCESS = "ADVANCE_SEARCHV2_LOAD_COLUMNS_SUCCESS";
export const LOAD_COLUMNS_FAIL = "ADVANCE_SEARCHV2_LOAD_COLUMNS_FAIL";
export const GG1_EXCEL_REPORT = "GG1_EXCEL_REPORT";
export const GG1_EXCEL_REPORT_SUCCESS = "GG1_EXCEL_REPORT_SUCCESS";
export const GG1_EXCEL_REPORT_FAIL = "GG1_EXCEL_REPORT_FAIL";
export const FINDASYOUTYPE = "FINDASYOUTYPEV2";
export const FINDASYOUTYPE_SUCCESS = "FINDASYOUTYPEV2_SUCCESS";
export const FINDASYOUTYPE_FAIL = "FINDASYOUTYPEV2_FAIL";

export const SAVE_ADVANCE_SEARCH = "SAVE_ADVANCE_SEARCH";
export const SAVE_ADVANCE_SEARCH_SUCCESS = "SAVE_ADVANCE_SEARCH_SUCCESS";
export const SAVE_ADVANCE_SEARCH_FAIL = "SAVE_ADVANCE_SEARCH_FAIL";
export const SHOWN_TOASTER = "SHOWN_TOASTER";
export const DELETE_SAVED_QUERY = "DELETE_SAVED_QUERY";
export const DELETE_SAVED_QUERY_SUCCESS = "DELETE_SAVED_QUERY_SUCCESS";
export const DELETE_SAVED_QUERY_FAIL = "DELETE_SAVED_QUERY_FAIL";
export const GET_ADVANCE_SEARCH_BYID = "GET_ADVANCE_SEARCH_BYID";
export const GET_ADVANCE_SEARCH_BYID_SUCCESS =
  "GET_ADVANCE_SEARCH_BYID_SUCCESS";
export const GET_ADVANCE_SEARCH_BYID_FAIL = "GET_ADVANCE_SEARCH_BYID_FAIL";

export const GET_ALL_ADVANCE_SEARCH = "GET_ALL_ADVANCE_SEARCH";
export const GET_ALL_ADVANCE_SEARCH_SUCCESS = "GET_ALL_ADVANCE_SEARCH_SUCCESS";
export const GET_ALL_ADVANCE_SEARCH_FAIL = "GET_ALL_ADVANCE_SEARCH_FAIL";

export const SAVE_LOCATION = "SAVE_LOCATION";
