import React, { useEffect, useState } from "react";
// import AdvanceSearch from "../../../../CommonComponents/AdvanceSearch";
import { useSelector } from "react-redux";
import AttrtionTable from "./AttrtionTable.jsx";
// import { Typography } from "antd";
// import { isAllowedURL } from '../../../../_helper/helper';
// import { useAuth } from 'oidc-react';
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../AbstractElements";

const AttritionReport = () => {
  const id = "DeductionReportV2";
  // const { userData } = useAuth();
  const {
    columns,
    loadingSearch,
    searchResults,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([
    { field: "EmployeeID", dir: "asc" },
  ]);
  const [searchParams, setSearchParams] = useState([
    {
      columnName: "DeptDiv",
      operator: "=",
      value: "4210",
      dataType: "varchar(max)",
      logicOperator: "AND",
    },
  ]);

  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);

  let rowData = [
    {
      DEPARTMENT: "B.B.M.R",
      "FTE ON-BOARD 10-1-18": "1",
      "Elected App": "1",
      Contract: "9",
      Exempt: "1",
      LTA: "1",
      Probation: "1",
      Unclass: "1",
      "Transfer - Promotion": "100",
      Temp: "1",
      "Acting Appt": "1",
      "2 % Employment Program": "1",
      Reemploymen: "1",
      " Transfer - Chg of Position Title": "1",
      "Transfer - Demotion": "1",
      " Transfer - Latera": "1",
      "Appoint-ments": "1",
      "Transfer Demotion - Sep": "1",
      "Transfer - LateraSep": "1",
      "Transfer - ChgPosn Title": "1",
      "Retire - DC": "1",
      "Transfer - Promotion Sep": "1",
      "Term - Exp of Appt / Contract": "1",
      Terminate: "1",
      Death: "1",
      Dismissal: "1",
      "Term of Acting Appt": "1",
      "Retire - Service": "1",
      "Retire - Age": "1",
      Resign: "1",
      "Separation - Auto": "1",
      "Completion  of Service": "1",
      "Retire - Disability": "1",
      "Retire - Optional": "1",
      "Separa-tions": "1",
      "Current On-Board": "1",
      "Attrition Rate": "1",
    },
    {
      DEPARTMENT: "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
      "FTE ON-BOARD 10-1-18": "1",
      "Elected App": "1",
      Contract: "1",
      Exempt: "1",
      LTA: "1",
      Probation: "1",
      Unclass: "1",
      "Transfer - Promotion": "100",
      Temp: "1",
      "Acting Appt": "1",
      "2 % Employment Program": "1",
      Reemploymen: "1",
      " Transfer - Chg of Position Title": "1",
      "Transfer - Demotion": "1",
      " Transfer - Latera": "1",
      "Appoint-ments": "1",
      "Transfer Demotion - Sep": "1",
      "Transfer - LateraSep": "1",
      "Transfer - ChgPosn Title": "1",
      "Retire - DC": "1",
      "Transfer - Promotion Sep": "1",
      "Term - Exp of Appt / Contract": "1",
      Terminate: "1",
      Death: "1",
      Dismissal: "1",
      "Term of Acting Appt": "1",
      "Retire - Service": "1",
      "Retire - Age": "1",
      Resign: "1",
      "Separation - Auto": "1",
      "Completion  of Service": "1",
      "Retire - Disability": "1",
      "Retire - Optional": "1",
      "Separa-tions": "1",
      "Current On-Board": "1",
      "Attrition Rate": "1",
    },
    {
      DEPARTMENT: "B.B.M.R",
      "FTE ON-BOARD 10-1-18": "1",
      "Elected App": "1",
      Contract: "1",
      Exempt: "1",
      LTA: "1",
      Probation: "1",
      Unclass: "1",
      "Transfer - Promotion": "100",
      Temp: "1",
      "Acting Appt": "1",
      "2 % Employment Program": "1",
      Reemploymen: "1",
      " Transfer - Chg of Position Title": "1",
      "Transfer - Demotion": "1",
      " Transfer - Latera": "1",
      "Appoint-ments": "1",
      "Transfer Demotion - Sep": "1",
      "Transfer - LateraSep": "1",
      "Transfer - ChgPosn Title": "1",
      "Retire - DC": "1",
      "Transfer - Promotion Sep": "1",
      "Term - Exp of Appt / Contract": "1",
      Terminate: "1",
      Death: "1",
      Dismissal: "1",
      "Term of Acting Appt": "1",
      "Retire - Service": "1",
      "Retire - Age": "1",
      Resign: "1",
      "Separation - Auto": "1",
      "Completion  of Service": "1",
      "Retire - Disability": "1",
      "Retire - Optional": "1",
      "Separa-tions": "1",
      "Current On-Board": "1",
      "Attrition Rate": "1",
    },
    {
      DEPARTMENT: "B.B.M.R",
      "FTE ON-BOARD 10-1-18": "1",
      "Elected App": "1",
      Contract: "1",
      Exempt: "1",
      LTA: "1",
      Probation: "1",
      Unclass: "1",
      "Transfer - Promotion": "100",
      Temp: "1",
      "Acting Appt": "1",
      "2 % Employment Program": "1",
      Reemploymen: "1",
      " Transfer - Chg of Position Title": "1",
      "Transfer - Demotion": "1",
      " Transfer - Latera": "1",
      "Appoint-ments": "1",
      "Transfer Demotion - Sep": "1",
      "Transfer - LateraSep": "1",
      "Transfer - ChgPosn Title": "1",
      "Retire - DC": "1",
      "Transfer - Promotion Sep": "1",
      "Term - Exp of Appt / Contract": "1",
      Terminate: "1",
      Death: "1",
      Dismissal: "1",
      "Term of Acting Appt": "1",
      "Retire - Service": "1",
      "Retire - Age": "1",
      Resign: "1",
      "Separation - Auto": "1",
      "Completion  of Service": "1",
      "Retire - Disability": "1",
      "Retire - Optional": "1",
      "Separa-tions": "1",
      "Current On-Board": "1",
      "Attrition Rate": "1",
    },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "9",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "B.B.M.R",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // },
    // {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "1"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100 %"
    // }, {
    //     "DEPARTMENT": "GUAM DEVELOPMENTAL DISABILITIES COUNCIL",
    //     "FTE ON-BOARD 10-1-18": "1",
    //     "Elected App": "1",
    //     "Contract": "1",
    //     "Exempt": "1",
    //     "LTA": "1",
    //     "Probation": "1",
    //     "Unclass": "1",
    //     "Transfer - Promotion": "100",
    //     "Temp": "1",
    //     "Acting Appt": "1",
    //     "2 % Employment Program": "1",
    //     "Reemploymen": "1",
    //     " Transfer - Chg of Position Title": "1",
    //     "Transfer - Demotion": "1",
    //     " Transfer - Latera": "1",
    //     "Appoint-ments": "1",
    //     "Transfer Demotion - Sep": "1",
    //     "Transfer - LateraSep": "1",
    //     "Transfer - ChgPosn Title": "1",
    //     "Retire - DC": "1",
    //     "Transfer - Promotion Sep": "1",
    //     "Term - Exp of Appt / Contract": "1",
    //     "Terminate": "1",
    //     "Death": "1",
    //     "Dismissal": "1",
    //     "Term of Acting Appt": "1",
    //     "Retire - Service": "1",
    //     "Retire - Age": "1",
    //     "Resign": "1",
    //     "Separation - Auto": "1",
    //     "Completion  of Service": "1",
    //     "Retire - Disability": "1",
    //     "Retire - Optional": "1",
    //     "Separa-tions": "1",
    //     "Current On-Board": "1",
    //     "Attrition Rate": "100.00 %"
    // }
  ];

  return (
    <div>
      <Breadcrumbs
        mainTitle={
          <Typography variant="h5" className="text-white">
            <i class="fa-duotone fa-list-ol m-r-10"></i>
            Attrition Report
          </Typography>
        }
        title="Reports"
        parent={
          // isAllowedURL("/DepartmentTable", userData) ? (
          <Link
            to={`${process.env.PUBLIC_URL}/DepartmentTable`}
            className="font-light"
          >
            Attrition Report
          </Link>
          //     // ) : undefined
        }
      />
      {/* <AdvanceSearch
        id={0}
        gridId={id}
        location="global"
        columnLocation="global"
        columnList={"*"}
        // columnRefernces={[
        //   {
        //     columnName: "RunYear_ID",

        //     keyField: "RunYear_ID",
        //     valueField: "RunYear_ID",
        //     table: "PayrollProcess"
        //   },
        // ]}
        tableName={"fn_Reports_MasterControllListing_prerun"}
        searchParams={searchParams}
        additionalWhere={additionalWhere}
        expanded={true}
        sortData={sortData}
        sortBy={sortData[0].dir}
        orderBy={sortData[0].field}
        pageNumber={page}
        pageSize={pageSize}
        chooseFilterMethod={"advancesearch"}
      /> */}
      <AttrtionTable Data={rowData} />
    </div>
  );
};

export default AttritionReport;
