import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import DocumentListing from "../../CommonComponents/DocumentListing";

const FileUploadModel = ({
  isOpen,
  handleClose,
  hrControl = null,
  docSelectionON=false,
  approvalBtn=false
}) => {

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={"lg"} >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0",
            }}
          >
            <h5 className="mt-2"></h5>
            <div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DocumentListing
            title={"Dependent Document"}
            isDocumentManagement={false}
            isShowActions={true}
            isDashboard={false}
            referenceId={hrControl}
            sourceName="Benefit"
            isShowUplaodBtn={true}
            docSelectionON={docSelectionON}
            approvalBtn={approvalBtn}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default FileUploadModel;
