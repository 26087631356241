import React, { Fragment, useEffect, useMemo, useState } from "react";
// import {
//   Grid,
//   GridColumn,
//   GridColumnMenuWrapper,
//   GridToolbar,
// } from "@progress/kendo-react-grid";
// import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { ShowError, ShowSucess, getUserRole } from "../../_helper/helper";
// import { GridPDFExport } from "@progress/kendo-react-pdf";
// import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { visileColumns } from "./visileColumnsBBMR";
// import { orderBy } from "@progress/kendo-data-query";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../ErrorBoundry";
import TelerikDataTable from "../../CommonComponents/TelerikDataTable";
import { useNavigate } from "react-router-dom";
import {
  faDeleteLeft,
  faDiagramProject,
  faEye,
  faList,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { filterAllowedColumn } from "../../_helper/helper";
import { useAuth } from "oidc-react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import { deleteGG1Action } from "../../store/GG1-Crud/actions";
import { Icon } from "@progress/kendo-react-common";

import ConfirmationDialog from "./ConfirmationDialog";
import { advanceSearch } from "../../store/advanceSearchv2/actions";
import AnalystReview from "../Charts/BBMR/AnalystReview";
import { toSQLExpression } from "../../utils/common";
import { setAdditionalWhere } from "../../store/Common/actions";

function BBMRGG1List() {
  const {
    columns,
    loadingSearch,
    searchResults,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const { sort, additionalWhere: storedAdditionalWhere, filter:storeFilter } =
    useSelector((state) => state?.CommonReducer) || {};
  const id = "BBMRGG1TRACKING";
  const [openDialog, setOpenDialog] = useState(false); // State variable to manage dialog visibility
  const [radioWithBBMR, setRadioWithBBMR] = useState("withBBMR");
  const [tableName, setTableName] = useState("vw_TransactionLogsV3BBMR");

  const [HrControl, setHrControl] = useState(null); // State variable to store hrControl
  const handleOpenModal = (HrControl) => {
    setOpenDialog(true); // Open the dialog
    setHrControl(HrControl); // Set HrControl received from the action
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAssign = () => {
    // Call your API here
    // For demonstration, let's just log a message
    console.log("Assigning...");
    // Close the dialog
    handleCloseDialog();
  };

  const history = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState([]);
  const { userData } = useAuth();
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [expandedPieChart, setexpandedPieChart] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [page, setPage] = useState(1);
  // const [additionalWhere, setAdditionalWhere] = useState("");
  const [additionalWhereGG1, setAdditionalWhereGG1] = useState("");

  const [sortData, setSortData] = useState(
    sort && sort[id]
      ? sort[id]
      : [
          { field: "EmployeeName_LFM", dir: "asc" },
          { field: "SSN", dir: "asc" },
          // { field: "EmployeeID", dir: "desc" },
          // { field: "HrControl", dir: "desc" },
          { field: "ActnEffDate", dir: "desc" },
          { field: "ActionDate", dir: "desc" },
        ]
  );
  const [pageSize, setPageSize] = useState(100);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  // console.log("121212 ShowDelete;;;; ", showDelete);
  console.log("sort", sort);
  const GG1NPAStatuses = ["Final Post NPA", "Payroll Staging", "Payroll"]; // For redirecting to NPA with GG1 HRControl


  const role = getUserRole(userData);
  // const currentRole = getUserRole(userData);
  // const selectedRole = useSelector((state) => state?.CommonReducer?.role);
  // const role1 = Array.isArray(currentRole) ? selectedRole : currentRole;
  // const [role,setRole] = useState(role1);
  console.log("123123 ::: ", role);

  const [defaultAdditionalWhere, setDefaultAdditionalWhere] = useState("");

  // useEffect(() => {
  //   if (role == "BBMR-ANALYST") {
  //     setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%BBMR%'");
  //     setAdditionalWhere("CurrentWorkflowStatus LIKE '%BBMR%'");
  //   } else if (role == "BBMR-DIRECTOR") {
  //     setDefaultAdditionalWhere(
  //       "CurrentWorkflowStatus LIKE '%BBMR%' OR CurrentWorkflowStatus LIKE 'Classification'"
  //     );
  //     setAdditionalWhere(
  //       "CurrentWorkflowStatus LIKE '%BBMR%' OR CurrentWorkflowStatus LIKE 'Classification'"
  //     );
  //   }
  //   // else if(role == "DEPT-ADMIN"){
  //   //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Dept Admin%'");
  //   //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Dept Admin%'");
  //   // }
  //   // else if(role == "CERTIFYING-OFFICER"){
  //   //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Cert Officer%'");
  //   //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Cert Officer%'");
  //   // }
  //   // else if(role == "AGENCY-DIRECTOR" || role == "DOA-DIRECTOR"){
  //   //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
  //   //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
  //   // }
  //   else if (role == "SUPER-USER") {
  //     // setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
  //     // setAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
  //     setSortData(
  //       sort && sort[id] && sort[id]?.length > 0
  //         ? sort[id]
  //         : [
  //             { field: "ActnEffDate", dir: "desc" },
  //             { field: "ActionDate", dir: "desc" },
  //             { field: "HrControl", dir: "desc" },
  //             { field: "EmployeeName_LFM", dir: "asc" },
  //           ]
  //     );
  //   }
  // }, [role, sort]);

  useEffect(() => {
    // if (role == "BBMR-ANALYST") {
    //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%BBMR%'");
    //   if(storedAdditionalWhere[id] != "" && storedAdditionalWhere[id] != null && storedAdditionalWhere[id] != undefined){
    //     setAdditionalWhere(
    //       `${storedAdditionalWhere[id]} AND CurrentWorkflowStatus LIKE '%BBMR%'`
    //     );
    //   }else{
    //     setAdditionalWhere("CurrentWorkflowStatus LIKE '%BBMR%'");
    //   }
    // } else if (role == "BBMR-DIRECTOR") {
    //   setDefaultAdditionalWhere(
    //     "CurrentWorkflowStatus LIKE '%BBMR%' OR CurrentWorkflowStatus LIKE 'Classification'"
    //   );
    //   if(storedAdditionalWhere[id] != "" && storedAdditionalWhere[id] != null && storedAdditionalWhere[id] != undefined){
    //     setAdditionalWhere(
    //       `${storedAdditionalWhere[id]} AND CurrentWorkflowStatus LIKE '%BBMR%' OR CurrentWorkflowStatus LIKE 'Classification'`
    //     );
    //   }else{
    //     setAdditionalWhere(
    //       "CurrentWorkflowStatus LIKE '%BBMR%' OR CurrentWorkflowStatus LIKE 'Classification'"
    //     );
    //   }
    // }
    // else if(role == "DEPT-ADMIN"){
    //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Dept Admin%'");
    //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Dept Admin%'");
    // }
    // else if(role == "CERTIFYING-OFFICER"){
    //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Cert Officer%'");
    //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Cert Officer%'");
    // }
    // else if(role == "AGENCY-DIRECTOR" || role == "DOA-DIRECTOR"){
    //   setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
    //   setAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
    // }
    // else if (role == "SUPER-USER") {
    if (role == "SUPER-USER") {
      // setDefaultAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
      // setAdditionalWhere("CurrentWorkflowStatus LIKE '%Agency Director%'");
      setSortData(
        sort && sort[id] && sort[id]?.length > 0
          ? sort[id]
          : [
              { field: "ActnEffDate", dir: "desc" },
              { field: "ActionDate", dir: "desc" },
              { field: "HrControl", dir: "desc" },
              { field: "EmployeeName_LFM", dir: "asc" },
            ]
      );
      if (
        storedAdditionalWhere[id] != "" &&
        storedAdditionalWhere[id] != null &&
        storedAdditionalWhere[id] != undefined
      ) {
        // setAdditionalWhere(storedAdditionalWhere[id]);
      }
    } else {
      if (
        storedAdditionalWhere[id] != "" &&
        storedAdditionalWhere[id] != null &&
        storedAdditionalWhere[id] != undefined
      ) {
        // setAdditionalWhere(storedAdditionalWhere[id]);
      }
    }
  }, [role, sort]);
  useEffect(() => {
    const d = {};
    let gridWhere = "";
    if (storeFilter[id] != null) {
      gridWhere = toSQLExpression(storeFilter[id]);
    }
    let cond =
    role == "BBMR-DIRECTOR" && radioWithBBMR == "withBBMR"
    ? `CurrentWorkflowStatusID in (8)`
    : "";
    
    if (gridWhere !== "") {
      d[id] = gridWhere + (cond ? `and ${cond}` : "");
    } else {
      d[id] = cond;
    }
    
    console.log("11111 ::: d : ",d,cond)
    dispatch(setAdditionalWhere(d));
    setAdditionalWhereGG1(d[id]);
    // setAdditionalWhere(d[id]);
  }, [storeFilter[id], radioWithBBMR, dispatch,role]);

  const actions = useMemo(() => {
    // if (CurrentWorkflowStatus == "Cert Officer")
    return [
      {
        title: "Assign Analyst",
        action: (props) => handleOpenModal(props.dataItem.HrControl), // Pass hrControlNo to handleOpenModal

        icon: { iconName: "k-icon  k-i-share" },
        className: "font-primary",
        style: { cursor: "pointer" },
        isVisible: (dataItem) => {
          return role == "SUPER-USER" || role == "BBMR-ADMIN";
        },
      },
      {
        title: "Show Details",
        action: (props) => {
          console.log("121212 ::: ", props, props?.dataItem?.CurrentProcessID);
          // if (GG1NPAStatuses.includes(props?.dataItem?.CurrentWorkflowStatus)) {
          //   if (props?.dataItem?.Nature_of_Action_Code == 30) {
          //     return history(
          //       // `/transaction/${props.dataItem.EmployeeID}/${props.dataItem.ActnNo}`,
          //       `/npa/Salary-Increment/${props.dataItem.EmployeeID}/${props.dataItem.HrControl}`,
          //       { state: columns }
          //     );
          //   } else {
          //     return history(
          //       // `/transaction/${props.dataItem.EmployeeID}/${props.dataItem.ActnNo}`,
          //       `/npa/transaction/${props.dataItem.EmployeeID}/${props.dataItem.HrControl}`,
          //       { state: columns }
          //     );
          //   }
          // } else 
          {
            return history(
              // `/transaction/${props.dataItem.EmployeeID}/${props.dataItem.ActnNo}`,
              `/transaction/${
                props.dataItem.EmployeeID
                  ? props.dataItem.EmployeeID
                  : "recruitment"
              }/${props.dataItem.HrControl}`,
              { state: columns }
            );
          }
        },
        icon: { iconName: "k-icon  k-i-eye" },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
      {
        title: "List All Employee Transaction",
        action: (props) => {
          return history(`/transaction-log/${props.dataItem.EmployeeID}`);
        },
        icon: { iconName: "k-icon  k-i-list-ordered" },
        className: "font-primary",
        style: { cursor: "pointer" },
      },
      // {
      //   title: "Workflow",
      //   actionType: "workflow",
      //   className: "font-primary",
      //   icon: faDiagramProject,
      //   style: { cursor: "pointer" },
      //   onlyVisible: "workflowID",
      // },

      {
        title: "delete",
        icon: { iconName: "k-icon  k-i-delete" },
        className: "font-primary",
        style: { cursor: "pointer" },
        action: async (props) => {
          // console.log(
          //   "121212 Props ::: ",
          //   props,
          //   props?.dataItem?.CurrentWorkflowStatus,
          //   props?.dataItem?.ActionNo
          // );
          if (props?.dataItem?.CurrentWorkflowStatus == "Dept Admin") {
            setShowDelete(true);
          }
          // if (window.confirm("Are you sure to delete this?")) {
          //   const res = await axios.delete(
          //     `${process.env.REACT_APP_API_DOTNET_URL}/GG1Common/${props?.dataItem?.ActnNo}?IsCancel=false`,
          //     {
          //       headers: {
          //         "Content-Type": "application/json",
          //         Authorization: `Bearer ${localStorage.getItem(
          //           "jwt_access_token"
          //         )}`,
          //       },
          //     }
          //   );
          //   if (res?.data?.message) {
          //     ShowSucess(res?.data?.message, { position: "top-right" });
          //   }
          //   return res;
          // }
          if (window.confirm("Are you sure to delete this?")) {
            let payload = {
              ActionNo: props?.dataItem?.ActnNo,
              isCancel: false,
            };
            dispatch(deleteGG1Action(payload));
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, [500]);
          }
        },
        visible: "CurrentWorkflowStatus",
        arrayVisible: ["Dept Admin", "Submitted", "Cert Officer"],
        // onlyVisible: async (props) => {
        //   return props?.dataItem?.CurrentWorkflowStatus == "Dept Admin" ? 1 : null;
        // },
        // onlyVisible: 0,
        // onlyVisible: (props) => {
        //   const { dataItem } = props;

        //   if (dataItem.CurrentWorkflowStatus == "Dept Admin") {
        //     return <button className="delete-button">Delete</button>;
        //   } else {
        //     return null; // Hide the button if canDelete is false
        //   }
        // },
      },
    ];
  });

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);
  useEffect(() => {
    console.log(searchResults, "123123");
  }, [searchResults]);
  useEffect(() => {
    console.log(
      "123123 Parth searchParams::: ",
      searchParameter,
      id
    );
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);
  useEffect(() => {
    if (ColumnsSorted[id] !== undefined) {
      setSortedColumns(ColumnsSorted[id]);
    } else {
      if (columns && visileColumns.length > 0 && columns.length > 0) {
        const clms = visileColumns.map((d) => d.field.toLowerCase());
        const data = columns
          .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
          .map((p, index) => {
            return {
              field: p.column_name,
              show: false,
              locked: false,
              width: 200,
              title: p.column_title,
              filter: "text",
              key: index,
            };
          });
        setSortedColumns(
          filterAllowedColumn(userData, visileColumns.concat(data)),
          "id"
        );
      }
    }
  }, [columns]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
  };
  const handleFilterChange = (data) => {
    console.log("123123 Parth ::: ", data, searchParams );
    // if (defaultAdditionalWhere != "") {
    //   if (data != "" && data != null && data != undefined) {
    //     setAdditionalWhere(`(${defaultAdditionalWhere}) AND ${data}`);
    //   } else {
    //     setAdditionalWhere(defaultAdditionalWhere);
    //   }
    // } else 
    {
      setAdditionalWhereGG1(data);
    }
    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const payload = {
    id: "bbmr_analyst_lookup",
    gridId: "bbmr_analyst_lookup",
    location: "global",
    columnLocation: "global",
    tableName: "vw_bbmr_analyst_lookup",
    searchParams: "[]",
    columns: "*",
    columnRefernces: "",
    pageNumber: page,
    pageSize: pageSize,
    sortData: "",
    // additionalWhere: additionalWhere,
    additionalWhere: "",
  };

  useEffect(() => {
    // Call advanceSearch action with payload
    dispatch(
      advanceSearch({ bbmr_analyst_lookup: payload }, "bbmr_analyst_lookup")
    );
  }, []);

  const handleALLGG1 = (value) => {};

  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Breadcrumbs
            mainTitle={
              <Typography variant="h5">
                <Icon
                  style={{ fontSize: "25px", margin: "10px" }}
                  name="file-ascx"
                />
                BBMR GG1 Tracking
              </Typography>
            }
            parent={null}
            title="GG1"
          />
          {openDialog && results?.length && (
            <ConfirmationDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onAssign={handleAssign}
              results={results}
              HrControl={HrControl}
            />
          )}
          <Accordion expanded={expandedPieChart}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="bg-light"
              onClick={() => setexpandedPieChart(!expandedPieChart)}
            >
              <Typography variant="h6">Work Load Distribution</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col sm={12} md={12} xs={12}>
                  <AnalystReview />
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>

          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    // tableName={role == "BBMR-ANALYST" ? "vw_TransactionLogsOnlyBBMR" : role == "BBMR-DIRECTOR" ? "vw_TransactionLogsOnlyBBMRWithClassification" : "vw_TransactionLogsV2"}
                    tableName={tableName}
                    searchParams={searchParams}
                    additionalWhere={additionalWhereGG1}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                    role={role}
                  />

                  <Accordion expanded={expandedSearchResult}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      className="bg-light"
                      onClick={() =>
                        setexpandedSearchResult(!expandedSearchResult)
                      }
                    >
                      <Typography variant="h6">Search Results</Typography>
                      {role != "AGENCY-DIRECTOR" &&
                        role != "BBMR-ANALYST" &&
                        role != "BBMR-ADMIN" &&
                        role != "BBMR-SUPERVISOR" &&
                        role != "BBMR-DIRECTOR" &&
                        role != "DOA-DIRECTOR" && (
                          <Btn
                            attrBtn={{
                              color: "primary",
                              style: {
                                marginRight: "4px",
                                marginBottom: "5px",
                                marginLeft: "1rem",
                              },
                              onClick: () => {
                                // handleValidateSSN();
                                history("/gg1-tracking/new-request");
                              },
                            }}
                          >
                            Create New GG1
                          </Btn>
                        )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="m-2 ">
                        <Row>
                          <RadioGroup
                            row
                            style={{ display: "flex", justifyContent: "end" }}
                            // aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="withBBMR"
                              control={
                                <Radio
                                  onChange={(e, v) => {
                                    setTableName("vw_TransactionLogsV3BBMR");
                                    setRadioWithBBMR(e.target.value);
                                    handleALLGG1(e.target.value);
                                  }}
                                  checked={
                                    radioWithBBMR == "withBBMR" ? true : false
                                  }
                                />
                              }
                              label={"GG1's with BBMR"} //"Approve"
                            />

                            <FormControlLabel
                              value="ALLGG1"
                              control={
                                <Radio
                                  onChange={(e, v) => {
                                    setTableName("vw_TransactionLogsV3");
                                    setRadioWithBBMR(e.target.value);
                                    handleALLGG1(e.target.value);
                                  }}
                                  checked={
                                    radioWithBBMR == "ALLGG1" ? true : false
                                  }
                                />
                              }
                              label={"ALL GG1's"}
                            />
                          </RadioGroup>
                        </Row>
                        {/* <Btn
                          attrBtn={{
                            color: "primary",
                            type: "submit",
                            style: {
                              marginRight: "4px",
                              marginBottom: "5px",
                            },
                          }}

                          // onClick={() => handleSubmit()}
                        >
                          {employeeID && actionNo ? (
                            <>
                              {" "}
                              <FontAwesomeIcon
                                icon={faFilePen}
                                style={{
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              />
                              Update Transaction
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={faFloppyDiskPen}
                                style={{
                                  color: "white",
                                  marginRight: "5px",
                                }}
                              />
                              Save Transaction
                            </>
                          )}
                        </Btn> */}
                        {/* <Btn
                          attrBtn={{
                            color: "primary",
                            style: { marginRight: "3px" },
                            style: {
                              marginRight: "4px",
                              marginBottom: "5px",
                            },
                            onClick: () => {
                              // handleValidateSSN();
                              history("/gg1-tracking/new-request");
                            },
                          }}
                        >
                          Create New GG1
                        </Btn> */}

                        {/* </div> */}
                      </div>

                      {/* {loadingSearch && loadingPanel} */}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            id={id}
                            pageSize={pageSize}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            // key={results}
                            sortData={sortData}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            actions={actions}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                            searchParams={searchParams}
                          ></TelerikDataTable>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
}
export default BBMRGG1List;
