import "react-toastify/dist/ReactToastify.css";
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
  GET_MASETR_DATA,
  GG1_ACTION_DELETE,
  ADD_GG1_EMPLOYEE,
  GET_POSITION_SALARY_BY_GRADE,
  RESET,
  GG1ANLYSTUPDATE,
} from "./actionTypes";

import {
  deleteGG1ActionSucess,
  deleteGG1ActionFail,
  checkEmployeeMasterSucess,
  checkEmployeeMasterFail,
  GetPositionSalaryByGradeSucess,
  GetPositionSalaryByGradeFail,
  reset,
  AddGG1EmployeeMasterDataFail,
  AddGG1EmployeeMasterDataSucess,
  updateGg1AnalystSuccess,
  updateGg1AnalystFail,
} from "./actions";

import {
  deleteActionGg1,
  checkEmployeeMasterApiCall,
  GetPositionSalaryByGradeApiCall,
  AddGG1EmployeeMasterApiCall,
  updateGG1Analyst,
} from "../../_helper/services/GG1";

function* onDeleteActionGg1({ payload }) {
  try {
    const response = yield call(deleteActionGg1, payload);
    console.log("paylaod in saga", payload, response);
    if (response?.status == 200 || 204) {
      yield put(deleteGG1ActionSucess(response));
      ShowSucess(
        `${payload?.isCancel ? "Cancelled " : "Deleted "}Successfully`,
        {
          position: "top-right",
        }
      );
    }
  } catch (error) {
    yield put(deleteGG1ActionFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}

function* checkEmployeeMasterRequest(props) {
  let Id = props?.payload;
  console.log("123123 props daata ", Id, props);
  if (Id !== "" && Id !== undefined && Id !== null) {
    try {
      const response = yield call(() => checkEmployeeMasterApiCall(Id));
      if (response) {
        console.log("response in saga", response);
        let msg = response?.response?.data?.errors?.employeeId[0];
        if (response?.response?.status == 404) {
          yield put(checkEmployeeMasterFail(msg || response?.response?.data));
          props.cbFailure(response);
        } else if (response?.response?.status == 401) {
          // yield put(checkEmployeeMasterFail(msg || response?.response?.data));
          // props.cbFailure(response);
          ShowError('You are not authorized to create GG1 for this Employee.', {
            position: "top-right",
          });
        } 
        else {
          props.cbSuccess(response);
          yield put(checkEmployeeMasterSucess(response));
        }
      }
    } catch (error) {
      console.log("123123 ::: ",error)
      if(error?.message == "Request failed with status code 401" || error?.response?.status == 401){
        ShowError('You are not authorized to create GG1 for this Employee.', {
          position: "top-right",
        });
      }
      yield put(checkEmployeeMasterFail(error));
    }
  }
}
function* onUpdateGg1Analyst({ payload: payload }) {
  try {
    const response = yield call(updateGG1Analyst, payload);
    yield put(updateGg1AnalystSuccess(response));
    console.log("response",response)
    ShowSucess(response, {
      position: "top-right",
    });
  } catch (error) {
    yield put(updateGg1AnalystFail(error.response));
    ShowError(error?.response?.request?.statusText);
    // ShowError(error?.response?.data?.message);

    console.log("ShowError",error)
  }
}
// GetPositionSalaryByGrade
function* GetPositionSalaryByGradeRequest(props) {
  console.log("Props insga", props?.payload);
  try {
    const response = yield call(() =>
      GetPositionSalaryByGradeApiCall(props?.payload)
    );
    if (response) {
      console.log("resonse", response);
      // let msg = response?.response?.data?.errors?.employeeId[0];
      // if (response?.response?.status == 404) {
      //   yield put(GetPositionSalaryByGradeFail(msg || response?.response?.data));
      //   props.cbFailure(response);
      // } else {
      props.cbSuccess(response);
      //   yield put(checkEmployeeMasterSucess(response));
      // }
    }
  } catch (error) {
    yield put(GetPositionSalaryByGradeFail(error));
    props.cbFailure(error);
  }
}

function* AddGG1EmployeeMasterRequest(props) {
  try {
    const response = yield call(() =>
      AddGG1EmployeeMasterApiCall(props?.payload)
    );
    if (response) {
      console.log("resonse in saga ifff:-", response, response?.status);
      let msg = "Something went wrong!";
      // // let msg = response?.response?.data?.errors?.employeeId[0];
      // // if (response?.response?.status == 404) {
      // //   yield put(AddGG1EmployeeMasterDataFail(msg || response?.response?.data));
      // //   props.cbFailure(response);
      // // } else {
      //   yield put(AddGG1EmployeeMasterDataSucess(response));
      //   props.cbSuccess(response);
      // // }
      if (response?.success || (response?.response?.status >= 200 && response?.response?.status <= 299)) {
        yield put(AddGG1EmployeeMasterDataSucess(response));
        props?.cbSuccess(response);
      }else{
        let errMsg = response?.response?.data?.errors ? Object.keys(response?.response?.data?.errors).map(item=>{return response?.response?.data?.errors[item]}).join("  ") : response?.response?.data || msg;
        // yield put(AddGG1EmployeeMasterDataFail(response?.response?.data || msg));
        yield put(AddGG1EmployeeMasterDataFail(errMsg));
        props?.cbFailure(errMsg);
      }
    }
  } catch (error) {
    console.log("resonse in saga ifff catch:-", error?.message?.response?.data);
    yield put(AddGG1EmployeeMasterDataFail(error?.response?.data));
    props?.cbFailure(error?.response?.data);
  }
}

function* Gg1Saga() {
  yield takeLatest(ADD_GG1_EMPLOYEE, AddGG1EmployeeMasterRequest);
  yield takeLatest(
    GET_POSITION_SALARY_BY_GRADE,
    GetPositionSalaryByGradeRequest
  );
  yield takeLatest(GET_MASETR_DATA, checkEmployeeMasterRequest);
  yield takeLatest(GG1_ACTION_DELETE, onDeleteActionGg1);
  yield takeLatest(RESET, reset);
  yield takeLatest(GG1ANLYSTUPDATE, onUpdateGg1Analyst);
}

export default Gg1Saga;
