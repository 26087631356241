import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faList } from "@fortawesome/pro-duotone-svg-icons";
// import ModalCustom from "../../AdvanceSearch/component/Modal";
// import ChipsSelection from "./ChipsSelection";
import { Box, Button } from "@mui/material";
import { faXmark } from "@fortawesome/pro-duotone-svg-icons";
import ModalCustom from "../AdvanceSearch/component/Modal";
import ChipsSelection from "./ChipSelectionChart";
// import ChipsSelection from "../DashboardChart/Default/ChipsSelection";
const style = {
    border: "2px solid #000",
    boxShadow: 24,
    //p: 4,
};

const classes = makeStyles((theme) => ({
    // formControl: {
    //     margin: theme.spacing(1),
    //     width: "100%",
    // },
    indeterminateColor: {
        color: "#f50057",
    },
    selectAllText: {
        fontWeight: 500,
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
}));
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "500",
        },
    },
};
function SelectMultipleCheckBoxCharts(props) {
    let {
        options,
        selected,
        handleChange,
        setSelected,
        selectUpdate,
        labelName,
        variant,
        label,
        size,
        name,
    } = props;
    console.log("options", options, selected);

    const isAllSelected =
        options.length > 0 && selected.length === options.length;
    const [open, setOpen] = useState(false);
    return (
        <div>
            <ModalCustom open={open} fullWidth>
                <Box sx={style}>
                    <Button
                        variant="outlined"
                        className=" mb-3"
                        style={{
                            float: "right",
                        }}
                        onClick={() => setOpen(false)}
                    >
                        <FontAwesomeIcon icon={faXmark} size="2xl" />
                    </Button>
                    <ChipsSelection
                        options={options}
                        setOpen={setOpen}
                        selected={selected}
                        setSelected={setSelected}
                        selectUpdate={selectUpdate}
                        labelName={labelName}
                        name={name}
                    />
                </Box>
            </ModalCustom>
            {/* {JSON.stringify(options.filter(i => selected.includes(i.value)).map(i=>i.label).join(", "))} */}
            <div class="d-flex justify-content-between align-items-center ">
            <FormControl size="small" fullWidth className={classes.formControl} style={{ width: "100%", display:'flex' }}>
                <InputLabel    >
                    {`${labelName}  `}
                    {` ${selected.length ? `-  ${selected.length} Items` : ""}`}
                    {/* {label} */}
                    
                </InputLabel>
                <Select
                    label={labelName}
                    size= {size}
                    multiple
                    name={name}
                    variant={variant}
                    value={selected}
                    onChange={handleChange}
                    renderValue={() => {
                        return options
                            .filter((i) => selected.includes(i.value))
                            .map((i) => i.label)
                            .toString();
                    }}
                    // MenuProps={MenuProps}
                >
                    <MenuItem
                        value="all"
                        classes={{
                            root: isAllSelected ? classes.selectedAll : "",
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                classes={{ indeterminate: classes.indeterminateColor }}
                                checked={isAllSelected}
                                indeterminate={
                                    selected.length > 0 && selected.length < options.length
                                }
                            />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary="Select All"
                        />
                    </MenuItem>
                    {options.map((option, index) => (
                        <MenuItem key={option.value} value={option.value}>
                            <ListItemIcon>
                                <Checkbox    checked={selected.includes(option.value)} />
                            </ListItemIcon>
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
                </Select>

               

            </FormControl>
            <FormControl className={classes.formControl}  >
            <FontAwesomeIcon
            className="ms-2"
                    icon={faList}
                    onClick={() => {
                        setOpen(true);
                    }}
                    style={{fontSize:'28px'}}
                />

            </FormControl>


            </div> 
          
        </div>
    );
}

export default SelectMultipleCheckBoxCharts;
// src\Components\Charts\SelectMultipleCheckBoxCharts.js
