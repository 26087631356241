// import React from "react";

// import { Typography } from "@mui/material";

// import { makeStyles } from "@material-ui/core/styles";
// import Divider from "@material-ui/core/Divider";
// import Header from "../Header";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin: theme.spacing(2),
//     textAlign: "center",
//   },
//   divider: {
//     margin: theme.spacing(2),
//   },
//   paragraph: {
//     margin: theme.spacing(2),
//     textAlign: "left",
//   },
//   table: {
//     width: "100%",
//     textAlign: "center",
//   },
//   cellLeft: {
//     textAlign: "left",
//   },
//   cellRight: {
//     textAlign: "right",
//   },
//   date: {
//     textAlign: "left",
//     marginTop: theme.spacing(2),
//   },
//   probEnd: {
//     textAlign: "right", // Align to the right
//   },
//   title: {
//     textAlign: "left", // Align to the left
//   },
// }));
// const Report = ({ rowData }) => {
//   const classes = useStyles();
//   const formatDate = (date) => {
//     const month = date.getMonth() + 1;
//     const day = date.getDate();
//     const year = date.getFullYear();
//     return `${month}/${day}/${year}`;
//   };
//   const DateNew = (date) => {
//     if (!date || typeof date !== "string") {
//       return "N/A";
//     }
//     const formattedDate = new Date(date.slice(0, 10));
//     const month = formattedDate.getMonth() + 1;
//     const day = formattedDate.getDate();
//     const year = formattedDate.getFullYear();
//     return `${month}/${day}/${year}`;
//   };
  
//   // const DateNew = (date) => {
//   //   if (!date || typeof date !== "string") {
//   //     return "N/A";
//   //   }
//   //   const formattedDate = date.slice(0, 10);
//   //   return formattedDate;
//   // };

//   const currentDate = formatDate(new Date());
//   // const { EmployeeName, ProbationaryEndDate, TITLE } = rowData.dataItem;

//   console.log("rowData", rowData);
//   return (
//     <div className={classes.root}>
//       <Header />
//       <div style={{ marginTop: '20px' }}>
//         <Typography variant="h5" gutterBottom>
//             <b>{rowData?.[0]?.Department_name ?? ""}</b>
//         </Typography>
//     </div>
//       <Typography variant="h6" gutterBottom style={{ textDecoration: 'underline' }}>
//         Overdue Probationary Notice Report
//       </Typography>
//       <Divider className={classes.divider}  />
//       <table className={classes.table}>
//         <thead>
//           <th className={`{classes.cellLeft} ${classes.probEnd}`} >
//             <b>Prob End:</b>
//           </th>
//           <th>
//             <b>Employee Name:</b>
//           </th>
//           <th className={`{classes.cellRight} ${classes.title}`}>
//             <b>Title:</b>
//           </th>
//         </thead>
//         <tbody>
//           {rowData.map(({ ProbationaryEndDate, TITLE, EmployeeName }) => {
//             return (
//               <tr>
//                 <td className={`{classes.cellLeft} ${classes.probEnd}`}>
//                   {DateNew(ProbationaryEndDate) || "N/A"}
//                 </td>

//                 {/* <td className={`{classes.cellLeft} ${classes.probEnd}`}>{Date(ProbationaryEndDate) || "N/A"}</td> */}

//                 <td>{EmployeeName || "N/A"}</td>
//                 <td className={`{classes.cellRight} ${classes.title} `}>
//                   {TITLE || "N/A"}
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//       <Divider className={classes.divider} />
//       <Typography variant="body1" className={classes.paragraph}>
//         Information is based on notices processed to date. Please disregard
//         notice if probationary report has been submitted to this office. If you
//         find any questions regarding the information provided, please contact
//         the Department of Administration, HR Division, Records Branch at
//         475-1213.
//       </Typography>
//       <Typography variant="body2" className={classes.date}>
//         <b> Date: {currentDate} </b>
//       </Typography>
//     </div>
//   );
// };

// export default Report;
import React from "react";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Header from "../Header";
import { constant } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    margin: theme.spacing(2),
    borderBottom: "4px solid black",
    fontWeight: "bold",
  },
  tableHeader: {
    borderBottom: "4px solid black",
    borderTop: "4px solid black",
  },
  paragraph: {
    margin: theme.spacing(2),
    textAlign: "left",
  },
  table: {
    width: "90%",
    textAlign: "center",
  },
  cellLeft: {
    textAlign: "left",
  },
  cellRight: {
    textAlign: "right",
  },
  date: {
    textAlign: "left",
    marginTop: theme.spacing(2),
  },
  probEnd: {
    textAlign: "right",
  },
  title: {
    textAlign: "left",
  },
}));

const Report = ({ rowData }) => {
  const classes = useStyles();

  const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };



  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const DateNew = (date) => {
    if (!date || typeof date !== "string") {
      return "N/A";
    }
    const targetDate = new Date(date.slice(0, 10));
    const dayOfWeekIndex = targetDate.getDay();
    const weekday = weekdays[dayOfWeekIndex];
    const month = months[targetDate.getMonth()];
    const dayOfMonth = targetDate.getDate();
    const year = targetDate.getFullYear();
    const formattedDate = dayOfMonth +"-" + month.substring(0, 3) + "-" + year%100
      // weekday + ", " + month + " " + dayOfMonth + ", " + year;
    return formattedDate;
  };

  const getTodayDateFormat = () => {
    const targetDate = new Date();
    const dayOfWeekIndex = targetDate.getDay();

    const weekday = weekdays[dayOfWeekIndex];

    const month = months[targetDate.getMonth()];

    const dayOfMonth = targetDate.getDate();

    const year = targetDate.getFullYear();

    const formattedDate =
      weekday + ", " + month + " " + dayOfMonth + ", " + year;
    return formattedDate;
  };

  const currentDate = formatDate(new Date());

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        minHeight:
          "1123px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.root} style={{ flex: 1 }}>
        <Header />
        <div style={{ padding: "0 40px" }}>
          <div style={{ marginTop: "20px" }}>
            <Typography variant="h5" gutterBottom>
              <b>{rowData?.[0]?.Department_name ?? ""}</b>
            </Typography>
          </div>
          <Typography
            variant="h6"
            gutterBottom
            style={{ textDecoration: "underline" }}
          >
            Overdue Probationary Notice Report
          </Typography>
          {/* <Divider className={classes.tableHeader} /> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <table className={classes.table}>
              <thead>
                <tr className={classes.tableHeader}>
                  <th className={`${classes.cellLeft} ${classes.probEnd}`}>
                    PROB END DATE
                  </th>
                  <th style={{ width: "45%" }}>EMPLOYEE NAME</th>
                  <th className={`${classes.cellRight} ${classes.title}`}>
                    TITLE
                  </th>
                </tr>
              </thead>
              <tbody>
                {rowData.map(({ ProbationaryEndDate, TITLE, EmployeeName }) => {
                  return (
                    <tr>
                      <td className={`${classes.cellLeft} ${classes.probEnd}`}>
                        {DateNew(ProbationaryEndDate) || "N/A"}
                      </td>
                      <td>{EmployeeName || "N/A"}</td>
                      <td className={`${classes.cellRight} ${classes.title}`}>
                        {TITLE || "N/A"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "92%", textAlign: "center" }}>
              <hr
                style={{
                  borderBottom: "2px solid black",
                  borderTop: "2px solid black",
                  margin: 0,
                  clear: "both",
                  height: "5px",
                }}
              />
              <Typography variant="body1" className={classes.paragraph}>
                Information is based on notices processed to date. Please
                disregard notice if probationary report has been submitted to
                this office.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                If you find any questions regarding the information provided,
                please contact the Department of Administration, HR Division,
                Records Branch at 475-1213.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "#333",
          // color: "#fff",
          padding: "20px",
          textAlign: "center",
          // width: 100%,
          position: "fixed",
          bottom: 0,
          width: "94%",
          display:"flex",
          justifyContent:"center" 
        }}
      >
        {/* <AppBar position="static" color="primary">
        <Toolbar> */}
        <div style={{ padding: "10px 40px",width:"100%"}}>

        <Typography variant="body2" className={classes.date}>
          <hr
            style={{
              // borderBottom: "2px solid black",
              borderTop: "2px solid black",
              margin: 0,
              clear: "both",
              height: "2px",
              width: "77%",
            }}
          />
          <Typography variant="body2" style={{paddingLeft:"10px"}}> {getTodayDateFormat()} </Typography>
        </Typography>
        {/* </Toolbar>
      </AppBar> */}
      </div>
      </div>
    </div>
  );
};

export default Report;
