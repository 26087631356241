import { Typography } from '@mui/material'
import { Icon } from '@progress/kendo-react-common'
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Container } from 'reactstrap'
import { Accordion, Alert, Card, Tooltip } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AccordionSummary } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from '@mui/material/Checkbox';
import {
  faRectangleList,
} from "@fortawesome/pro-duotone-svg-icons";
import EmployeeDetails, { EmpDepartMent } from './Components/EmployeeDetails';
import {
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from 'moment';
import EnrollDetails from './Components/EnrollDetails';
import HealthAndDeductionPlan from './Components/HealthAndDeductionPlan';
import ActiveHealthAndDeductionPlan from './Components/ActiveHealthAndDeductionPlan';
import Depedentinformation from './Components/Depedentinformation';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Button } from "@mui/material";
import axios from "axios";
import { useParams } from 'react-router-dom';
import RetireePlan from './Components/RetireePlan';
import { LoadingPanel } from '../CommonComponents/CommonFunction';
import { KeyGenV2, ShowError, ShowSucess, d2Time, dateFormat } from '../_helper/helper';
import { findOutEmployee } from '../store/employees/actions';
import { useDispatch, useSelector } from 'react-redux';



const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".&.MuiExpansionPanelSummary-content": {
      padding: 0,


    },
    ".css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "32px", 
    },


    "& .MuiPaper-root": {
      background: "none",
      display: "block",
    },
    margin: "0 !important",
    borderTopWidth: 1,
    borderTopColor: "#ccc", 
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "70%",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
}));


const EnrollMentBenifitsMain = () => {
  const classes = useStyles();
  const params = useParams()
  const dispatch = useDispatch()
  const { employeeMasterDetails } = useSelector(
    (state) => state?.employeeReducer.findEmployee
  );

  const paramsId = useParams()
  let sampleDependant =[{
    lastName:"",
    firstName:"",
    middleName:"",
    relation:"",
    ssn:"",
    gender:"Male",
    dob:"",
    mailingAddress:"",
    email:""
  }]
  const [dependentInformation, setDependentInformation] = useState(sampleDependant)
  const [deletedUid, setDeletedUid] = useState([])
  const [oldData, setOldData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpadate] = useState(false)
  const [expanded, setExpanded] = React.useState({
    empDetail: true,
    enrollDetail: true,
    healthandDeduction: true,
    dependent: true,
    retireePlan:true
  });

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded((prevState) => ({
      ...prevState,
      [panel]: isExpanded,
    }));
  };

  const [schema, setPerformanceSchema] = useState({
    
    FirstName: Yup.string().required("First Name is required"),
    MiddleName: Yup.string().required("Middle Name is required"),
    LastName: Yup.string().required("Last Name is required"),
    
    // SSN: oldData?.ssn ? '' : Yup.string()
    // .min(9, "must be 9 digit")
    // .max(9, "must be 9 digit").required("SSN is Required"),
    
    // MailingAddress: Yup.string().required("MailingAddress is required"),
    // City: Yup.string().required("City is required"),
    // State: Yup.string().required("State is required"),
    // HomePhone:Yup.string().nullable()
    // .max(12,"Phone number should not large 12 digit"),
    // WorkPhone:Yup.string().nullable()
    // .max(12, "Phone number should not large 12 digit"),
    // CellPhone:Yup.string().nullable()
    // .max(12, "Phone number should not large 12 digit"),
    // Zip: Yup.string().required("Zip is required"),
    // Department:Yup.string().required("Depaerment is required"),
    Gender:Yup.string().required("Gender is required"),
    MaritalStatus:Yup.string().nullable(),
    // Email:Yup.string().required("Email is required"),
     DateOfEmployment:Yup.string().required("Date of Employeement is required")
      .typeError("Enter a valid date format."),
    DOB:Yup.string().required("DOB is required")
    .typeError("Enter a valid date format."),
   
  });

  const initialItem ={
    // Employee Detils

    actEmployee:false,
    retiree:false,
    survivorRetiree:false,
    dbRetireeplan:false,
    dcRetire:false,

    empType:"actEmployee",
    FirstName:"",
    MiddleName:"",
    LastName:"",
    Department:"",
    DateOfEmployment:"",
    SSN:"",
    MailingAddress:"",
    City:"",
    State:"",
    Zip:"",
    HomePhone:"",
    WorkPhone:"",
    CellPhone:"",
    DOB:"",
    Gender:"Male",
    MaritalStatus:"",
    Email:"",
    healthPlan:"",
    HSAANDPPO:"",
    HealthVendor:""


  }
  const getMessage = (uniqueValues) => {
    if (uniqueValues.every(i => i == "")) {
      return "Please select class I"
    }
    if (uniqueValues.length == 1 ? uniqueValues.every(i => ["spouse", "domesticpartner"].includes(i)) : uniqueValues.every(i => ["spouse", "domesticpartner"].includes(i) || i == "")) {
      return "Please select class II"
    }
    if (uniqueValues.length == 1 ? uniqueValues.every(i => ["child"].includes(i)) : uniqueValues.every(i => i == "child" || i == "")) {
      return "Please select class III"
    }
    if (uniqueValues.length == 1 ? uniqueValues.some(i => ["spouse", "domesticpartner"]) && uniqueValues.some(i => ["child"]) : uniqueValues.some(i => ["spouse", "domesticpartner"].includes(i) || i == "") && uniqueValues.some(i => ["child"].includes(i))) {
      return "Please select class IV"
    }
  }

  const isValidHealthPlan = (dataSet, currentValue, uniqueValues) => {
    console.log('Div bhaskar',dataSet,'===',currentValue,'---',uniqueValues);
    if (currentValue == "I") {
      // ShowError(getMessage(uniqueValues))
      return dataSet.every(i => i.relation == "")
    }
    if (currentValue == "II") {
      // ShowError(getMessage(uniqueValues))
      return uniqueValues.length == 1 ?
        dataSet.every(obj => ["spouse", "domesticpartner"].includes(obj.relation)) : dataSet.every(obj => ["spouse", "domesticpartner"].includes(obj.relation) || obj.relation == "")
    }
    if (currentValue == "III") {
      // ShowError(getMessage(uniqueValues))
      return uniqueValues.length == 1 ?
        dataSet.every(i => i.relation == "child") : dataSet.every(i => i.relation == "child" || i.relation == "")
    }
    if (currentValue == "IV") {
      // ShowError(getMessage(uniqueValues))
      return dataSet.some(obj => ["spouse", "domesticpartner"].includes(obj.relation)) && dataSet.some(i => i.relation == "child")
    }
    return false
  }

  const setClassValues = (dependentArray) => {
      
    const uniqueValues = [...new Set(dependentArray.map(obj => obj.relation))]
    console.log("asdasdadasds",uniqueValues);
    if (uniqueValues.every((i) => i == "")) {
      setFieldValue("HSAANDPPO", "I");
      values?.HSAANDPPO !== "I" && ShowSucess(`Class changed ${values?.HSAANDPPO} to I `)
      return true
    }
    if (
      uniqueValues.length == 1
        ? uniqueValues.every((i) => ["spouse", "domesticpartner"].includes(i))
        : uniqueValues.every(
          (i) => ["spouse", "domesticpartner"].includes(i) || i == ""
        )
    ) {
      setFieldValue("HSAANDPPO", "II");
      values?.HSAANDPPO !== "II" && ShowSucess(`Class changed ${values?.HSAANDPPO} to II `)
      return true
    }
    if (
      uniqueValues.length == 1
        ? uniqueValues.every((i) => ["child"].includes(i))
        : uniqueValues.every((i) => i == "child" || i == "")
    ) {
      setFieldValue("HSAANDPPO", "III");
      values?.HSAANDPPO !== "III" && ShowSucess(`Class changed ${values?.HSAANDPPO} to III `)

      return true
    }
    if (
      uniqueValues.length == 1
        ? uniqueValues.some((i) => ["spouse", "domesticpartner"].includes(i)) &&
        uniqueValues.some((i) => ["child"].includes(i))
        : uniqueValues.some((i) => ["spouse", "domesticpartner"].includes(i) || i == "") &&
        uniqueValues.some((i) => ["child"].includes(i))
    ) {
      setFieldValue("HSAANDPPO", "IV");
      values?.HSAANDPPO !== "IV" && ShowSucess(`Class changed ${values?.HSAANDPPO} to IV `)
      
      return true
    }
  };

 

  const onSubmit = (values,{resetForm}) => {
   
    console.log('Div bhaskar');
    if(!values?.empType){
      ShowError("Please select employee type")
      return false
    }
    console.log("Dasdaadada");

    if(!values?.healthPlan && values?.empType == 'actEmployee'){
      ShowError("Please select health plan")
      return false
    }
    console.log("Dasdaadada");

    if(!values?.retireePlan && ["retiree","survivorRetiree","dbRetireeplan","dcRetire"].includes(values?.empType)){
      ShowError("Please select retiree plan")
      return false
    }

    console.log("Dasdaadada");
    
    if (values?.HSAANDPPO !== "I" && dependentInformation.some((i) => !i.firstName || !i.lastName || !i.middleName || !i.ssn || !i.relation || !i.gender || !i.dob)) {
      ShowError("Please Check Dependent Information")
      return false
    }
    console.log("Dasdaadada");

    const uniqueValuesRelation = [...new Set(dependentInformation.map(obj => obj.relation))];
    if (!isValidHealthPlan(dependentInformation, values?.HSAANDPPO, uniqueValuesRelation)) {
     ShowError(getMessage(uniqueValuesRelation))
      return false
    }  
    console.log("Dasdaadada");
    let newId = params?.id || KeyGenV2(9);
    let filterDependant = dependentInformation?.filter((item) => item.firstName &&  item?.middleName && item.lastName)
    let dependentArray = filterDependant?.map((item) => {
      return {
        uid:item?.uid ? item?.uid : null,
        "employeeID": null,
        "employeeType": '',
        "branchAuto": "",
        "subscriberID": newId,
        "relation": item.relation,
        "retireType": "",
        "firstName": item.firstName,
        "middleName": item.middleName,
        "lastName": item.lastName,
        "department": "",
        "deptCode": "",
        "dateOfEmployment": null,
        "ssn": item.ssn,
        "mailingAddress": item.mailingAddress,
        "city": "",
        "state": "",
        "zip": "",
        "homePhone": "",
        "workPhone": "",
        "cellPhone": "",
        "dob": item.dob,
        "gender": item.gender,
        "maritalStatus": "",
        "email": item.email,
        "isNewEnrollee": "",
        "enrolleeDate": null,
        "terminateCoverage": "",
        "changeOfStatus": "",
        "retireVendor": "",
        "retirePlan": "",
        "retireClass": "",
        "healthVendor": "",
        "healthPlan": "",
        "healthDeductionClass": "",
        "healthStatus": "",
        "dentalVendor": "",
        "dentalPlan": "",
        "dentalDeductionClass": "",
        "dentalStatus": "",
        "lifeVendor": "",
        "lifePlan": "",
        "lifeDeductionClass": "",
        "lifeBeneficiary": '',
        "lifeStatus": ""
      }
    })
   let deptDev = EmpDepartMent?.find((item) => item.value == values?.Department)
      console.log("sadaddaddsad", deptDev);
    let AllPayload = [
      {
        uid:values?.uid ? values?.uid : null,
        "employeeID":values?.empID ? values?.empID : newId ,
        "employeeType": values?.empType == "actEmployee" ? "A" : "R",
        "retireType": values?.empType == "actEmployee" ? "" : values?.empType,
        "branchAuto": "",
        "subscriberID": newId,
        "relation": "",
        "firstName": values?.FirstName,
        "middleName": values?.MiddleName,
        "lastName": values?.LastName,
        "department": deptDev?.name,
        "deptCode": values?.Department ,
        "dateOfEmployment": moment(values?.DateOfEmployment).format("YYYY-MM-DD"),
        "ssn": values?.SSN,
        "mailingAddress": values?.MailingAddress,
        "city": values?.City,
        "state": values?.State,
        "zip": values?.Zip,
        "homePhone": values?.HomePhone,
        "workPhone": values?.WorkPhone,
        "cellPhone": values?.CellPhone,
        "dob": values?.DOB,
        "gender": values?.Gender,
        "maritalStatus": values?.MaritalStatus,
        "email": values?.Email,
        "isNewEnrollee": "",
        "enrolleeDate": "",
        "terminateCoverage": "",
        "changeOfStatus": "",
        "retireVendor": values?.retireeVendor,
        "retirePlan":values?.retireePlan,
        "retireClass": values?.retireeClass,
        "healthVendor":  values?.HealthVendor,
        "healthPlan": values?.healthPlan,
        "healthDeductionClass": values?.HSAANDPPO,
        "healthStatus": "",
        "dentalVendor": "",
        "dentalPlan": "",
        "dentalDeductionClass": "",
        "dentalStatus": "",
        "lifeVendor": "",
        "lifePlan": "",
        "lifeDeductionClass": "",
        "lifeBeneficiary": 0,
        "lifeStatus": ""
      }
    ]
    
    console.log("sadaddaddsad", AllPayload);
     
    let finalPayload = [...AllPayload,...dependentArray]
 
        axios
          .post(
            `${process.env.REACT_APP_API_DOTNET_URL}/BenefitsMASTER/CreateOrUpdateDeteteBenefits`,{createnupdate : finalPayload,deletedList:deletedUid},
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
              },
            }
          )
          .then(async (response) => {
            ShowSucess("Create Successfull")
            resetForm({values:""})
            setDeletedUid([])
            setDependentInformation(sampleDependant)
            GetDataUseingSubId(params?.id)
          })
          .catch((error) => {
            console.warn("Cannot retrieve user data", error);
          });
      };
    

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    validateForm,
    setValues,
  } = useFormik({
    initialValues: initialItem,
    validationSchema: Yup.object().shape(schema),
    onSubmit,
  });
  function renderTextInput(
    stateName,
    label,
    others = {disabled : false,
    multiline : false,
    required : false,
    shrink : true}
  ) {
    let other = {disabled : false,
      multiline: false,
      maxLength:'',
      required : false,
      shrink: true, ...others
    }
    
    
    return (
      <TextField
        fullWidth
        size="small"
        autoComplete='off'
        inputProps={{
          style: {
            fontSize: "14px",
            paddingTop: "12px",
            width: "100%",
          },
          maxLength:other.maxLength,
          readOnly: other.disabled,
        }}
        margin="dense"
        variant="outlined"
        label={label}
        name={stateName}
        id={stateName}
        multiline={other.multiline}
        rows={other.multiline ? 3 : 1}
        value={values[stateName]}
        InputLabelProps={{ shrink: other.shrink ? other.shrink : false }}
        shrink={other.shrink ? other.shrink : false}
        onChange={!other.disabled && handleChange}
        onBlur={handleBlur}
        error={!other.disabled && touched[stateName] && errors[stateName]}
        helperText={
          !other.required && !other.disabled && touched[stateName] && errors[stateName]
        }
        disabled={other.disabled}
        required={other.required}
      />
    );
  }

  function renderDateInput(
    stateName,
    label,
    disabled = false,
    minDate = null
    // maxDate
  ) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputVariant={"outlined"}
          margin="dense"
          format="MM/dd/yyyy"
          label={label}
          minDate={minDate}
          // maxDate={maxDate}
          value={values[stateName]}
          KeyboardButtonProps={{ "aria-label": label }}
          disabled={disabled}
          onChange={(value) => {
            const formatDate = value?.$d;
            const date = formatDate
              ? moment(formatDate).format("YYYY-MM-DD hh:mm:ss")
              : null;
            setFieldValue(stateName, date);
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin="dense"
              size="small"
              InputLabelProps={{ shrink: true }}
              {...params}
              error={!disabled && errors[stateName]}
              helperText={!disabled && errors[stateName]}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  function renderDropDown(stateName, label, value, disabled) {
    return (
      <div>
        <TextField
          margin="dense"
          className="flex w-full  mt-2"
          variant="outlined"
          select
          SelectProps={{ native: true }}
          style={{ width: "100%" }}
          size="small"
          disabled={disabled}
          inputProps={{
            style: {
              fontSize: "14px",
              color: "black",
              paddingTop: "12px",
            },
          }}
          label={label}
          name={stateName}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
          error={!disabled && errors[stateName]}
          helperText={!disabled && errors[stateName]}
          value={values[stateName]}
        >
          {/* <option value={""} disabled> */}
          {/* value={values[stateName]}> */}
          <option value={""}>
            Select {label}
          </option>

          {value.length &&
            value.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
        </TextField>
      </div>
    );
  }

  const renderCheckbox = (size="large",stateName, value) => {
  
   return <Checkbox size={size} checked={values[stateName] == value} 
      onChange={(e) => {
        if(stateName == 'empType'){
          if(value == 'actEmployee'){ 
            setValues({...values,retireeVendor:"",retireePlan:"",retireeClass:""})
          }else{
            setValues({...values,HealthVendor:"",healthPlan:"",healthDeductionClass:""})
          }
          setFieldValue(stateName,value)
        }else{
        setFieldValue(stateName,value)
        }
      }}
   />
  }

  const RenderCheckbox2= (size="large",stateName, value) => {
  
    return <Checkbox size={size} checked={values[stateName] == value} 
       onChange={(e) => {
         setFieldValue(stateName,value)
       }}
    />
   }
  
  const renderGroupCheckbox = (size = "large", stateName, value, other = null) => {
    // console.log('bbb 00',stateName,value,'---',other);
    
    let defaultChk = <Checkbox size={ size } checked={ values[ stateName ] == value } 
      onChange={ (e) => {
        setFieldValue(stateName,value)
      } }  
      
 />
    if (other && other.matchkey && other.matchkey1 ) {
      defaultChk =  <Checkbox size={ size } checked={ values[ stateName ] == value && values[ other.matchkey ] == other.matchvalue  && values[ other.matchkey1 ] == other.matchvalue1 } 
        onChange={ (e) => {

          if (stateName == 'HSAANDPPO' && other?.changeTarget1 && other?.changeTarget1=='HealthVendor' && other?.changeTarget2=='healthPlan') {
              setFieldValue(other?.changeTarget1, other?.changeTargetValue1)
              setFieldValue(other?.changeTarget2,other?.changeTargetValue2)
          }
          if (stateName == 'retireeClass' && other?.changeTarget1 && other?.changeTarget1=="retireeVendor" && other?.changeTarget2=="retireePlan") {
            setFieldValue(other?.changeTarget1, other?.changeTargetValue1)
            setFieldValue(other?.changeTarget2,other?.changeTargetValue2)
        }
        
          
            setFieldValue(stateName,value)
          }}  
      />
    } else if (other && other.matchkey) {
      defaultChk = <Checkbox size={ size } checked={ values[ stateName ] == value && values[ other.matchkey ] == other.matchvalue } 
        onChange={ (e) => {
          if (stateName == 'HSAANDPPO' && other?.changeTarget1 && other?.changeTarget1=='HealthVendor' && other?.changeTarget2=='healthPlan') {
              setFieldValue(other?.changeTarget1, other?.changeTargetValue1)
              setFieldValue(other?.changeTarget2,other?.changeTargetValue2)
          }
          if (stateName == 'healthPlan' && other.matchkey) {
            setFieldValue('HSAANDPPO',values.HSAANDPPO)
          }
            setFieldValue(stateName,value)
          }}  
      />
    } else {
      defaultChk =<Checkbox size={ size } checked={ values[ stateName ] == value } 
       onChange={(e) => {
         setFieldValue(stateName,value)
       }}  
    />
    }
    return  defaultChk
   }
  const loadPreData = async (type) => {
    handleSubmit()
    // return false
  }


  const GetDataUseingSubId = (subId) => {
    setIsLoading(true)
    axios
    .get(
      `${process.env.REACT_APP_API_DOTNET_URL}/BenefitsMASTER/GetBySubscriberId?SubscriberId=${subId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("jwt_access_token")}`,
        },
      }
    )
    .then(async (response) => {
      console.log("Dsfsdfssdg", response);
      let data = response?.data
      let empData = data?.find((i) => i.employeeId == i.subscriberId)
      setOldData(empData)
      if(!empData){
        setPerformanceSchema({...schema, SSN: oldData?.ssn ? '' : Yup.string()
        .min(9, "must be 9 digit")
        .max(9, "must be 9 digit").required("SSN is Required")})
      }
        
        
      let dependentData = data?.filter((i) => i.employeeId !== i.subscriberId)
      console.log("empDatadsdds", empData,dependentData);
      if(empData){
        setFieldValue("empType",empData.employeeType == "A" ? "actEmployee" : empData.retireType )
        setFieldValue("empID",empData.employeeId)
        setFieldValue("FirstName",empData.firstName)
    setFieldValue("MiddleName",empData.middleName)
    setFieldValue("LastName",empData.lastName)
    setFieldValue("Department",empData.department)
    setFieldValue("DateOfEmployment",empData.dateOfEmployment)
    setFieldValue("SSN",empData.ssn)
    setFieldValue("MailingAddress",empData.mailingAddress)
    setFieldValue("City",empData.city)
    setFieldValue("State",empData.state)
    setFieldValue("Zip",empData.zip)
    setFieldValue("HomePhone",empData.homePhone)
    setFieldValue("WorkPhone",empData.workPhone)
    setFieldValue("CellPhone",empData.cellPhone)
    setFieldValue("DOB",empData.dob)
    setFieldValue("Gender",empData.gender)
    setFieldValue("MaritalStatus",empData.maritalStatus)
    setFieldValue("Email",empData.email)
    setFieldValue("healthPlan",empData.healthPlan)
    setFieldValue("HealthVendor",empData.healthVendor)
    setFieldValue("HSAANDPPO",empData.healthDeductionClass)
    setFieldValue("retireeVendor",empData.retireVendor)
    setFieldValue("retireePlan",empData.retirePlan)
    setFieldValue("retireeClass",empData.retireClass) 
    setFieldValue("uid",empData.uid) 
      }
      if(dependentData?.length){
       let dependent =  dependentData?.map((item) => {
          return {
            lastName:item.lastName,
            firstName:item.firstName,
            middleName:item.middleName,
            relation:item.relation,
            ssn:item.ssn,
            gender:item.gender,
            dob:item.dob,
            mailingAddress:item.mailingAddress,
            email:item.email,
            uid:item.uid ? item.uid : null,
            subscriberId:item?.subscriberId || null,
            totalDocument:item?.totalDocument || 0,
            totalRejectedDoc:item?.totalRejectedDoc || 0,
            totalVerifiedDoc:item?.totalVerifiedDoc || 0,

          }
        })

        setDependentInformation(dependent)
       
      }
      setIsLoading(false)
    })
    .catch((error) => {
      console.warn("Cannot retrieve user data", error);
      setIsLoading(false)
    });
  }
  useEffect(() => {
    if(params?.id && params?.type == "update"){
      setIsLoading(true)
      GetDataUseingSubId(params?.id)
    }
      if(params?.type == "update"){
      setIsUpadate(true)
    }
    if(params?.id && params?.type == "add"){

      dispatch(
        findOutEmployee(Number(params?.id))
      );

      if(params?.empType){
        setFieldValue("empType",params.empType?.trim() == "9" ? "actEmployee" : ["A","B","O","S","Y"].includes(params.empType?.trim()) ? "retiree" : "" )
      }

    }
  },[params?.id])

  useEffect(() => {
    if(params?.type == "add" && employeeMasterDetails){
      let data = employeeMasterDetails;
      setValues({...values,DateOfEmployment:dateFormat(data?.dateEmployment), FirstName : data?.firstName, MiddleName:data?.middleName,LastName:data?.lastName ,SSN:data.ssn,MailingAddress:data?.hmAddLn1,
        City:data?.hmAddCity,
        State:data?.hmAddState,
        Zip:data?.hmAddZip,
        HomePhone:data?.hmPhone, 
        DOB:data?.ndateOfBirth,
        Gender:data?.sex == "M" ? "Male" :data?.sex== "F" ? "Female" : "",
        MaritalStatus :(data?.maritalStatus)?.trim() ,Email:data?.email })
    }
  },[params])

  return (
    <Fragment>
    
      <Container fluid={true}>
     
        <Row className="bg-primary rounded m-0 p-0 py-3 mb-3 ">
          <div style={{display:"flex", justifyContent:"space-between"}}>
          <Typography variant="h5">
            {/* <div> */}
            {/* <Icon style={{ fontSize: '25px', margin:"10px"}} name="file-ascx" /> */}
            <Icon style={{ fontSize: '25px', margin: "10px" }} name="graph" />
            Enrollment Benefits
            {/* </div> */}
          </Typography>
          <Button
              variant="contained"
              // className="bg-secondary"
              style={{ color: "#5F7DC4" , backgroundColor:"white"}}
              onClick={() => {loadPreData()}}
            >
             {isUpdate ? "Update" : "Save"} 
            </Button>
            </div>

        </Row>
        <Card style={{marginTop:"10px"}}>
        
          <Accordion
            className={classes.root}
            expanded={expanded.empDetail}
            onChange={handleChangeExpanded("empDetail")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="sub-header w-100 p-1 bg-primary text-white"
              style={{ width:"100%" }}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  // color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography
                className={classes.heading}
                style={{ height: "auto" }}
              >
                Employee Details

              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
            {isLoading && <LoadingPanel />}
              <EmployeeDetails 
              renderTextInput={renderTextInput}
              renderCheckbox={renderCheckbox}
              renderDropDown={renderDropDown}
              renderDateInput={renderDateInput}
              values={values}
              oldData={oldData}
              params={params}
              />
            </AccordionDetails>
          </Accordion>
        </Card>
        
        {/* <Card style={{marginTop:"10px"}}>
          <Accordion
            className={classes.root}
            expanded={expanded.enrollDetail}
            onChange={handleChangeExpanded("enrollDetail")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="sub-header w-100 p-1 bg-primary text-white"
              style={{ width:"100%" }}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  // color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography
                className={classes.heading}
                style={{ height: "auto" }}
              >
                Enroll Details

              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
              <EnrollDetails 
              renderTextInput={renderTextInput}
              renderCheckbox={renderCheckbox}
              renderDropDown={renderDropDown}
              renderDateInput={renderDateInput}
              />
            </AccordionDetails>
          </Accordion>
        </Card> */}
        {values?.empType == "actEmployee" ? 
        <Card style={{marginTop:"10px"}}>
          <Accordion
            className={classes.root}
            expanded={expanded.healthandDeduction}
            onChange={handleChangeExpanded("healthandDeduction")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="sub-header w-100 p-1 bg-primary text-white"
              style={{ width:"100%" }}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  // color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography
                className={classes.heading}
                style={{ height: "auto" }}
              >
                Health and Deduction Plan

              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
              {/* <HealthAndDeductionPlan 
              renderTextInput={renderTextInput}
              renderCheckbox={renderCheckbox}
              renderDropDown={renderDropDown}
              renderDateInput={renderDateInput}
              /> */}
              
               
              <ActiveHealthAndDeductionPlan 
                renderTextInput={renderTextInput}
                renderCheckbox={renderCheckbox}
                renderDropDown={ renderDropDown }
                renderGroupCheckbox={renderGroupCheckbox}
                renderDateInput={ renderDateInput }
                setFieldValue={ setFieldValue }
                
              />
              
            </AccordionDetails>
          </Accordion>
        </Card> : null}
        {["retiree","survivorRetiree","dbRetireeplan","dcRetire"].includes(values?.empType) ? 
        <Card style={{marginTop:"10px"}}>
          <Accordion
            className={classes.root}
            expanded={expanded.retireePlan}
            onChange={handleChangeExpanded("retireePlan")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="sub-header w-100 p-1 bg-primary text-white"
              style={{ width:"100%" }}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  // color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography
                className={classes.heading}
                style={{ height: "auto" }}
              >
                Retiree Plan

              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
              {/* <HealthAndDeductionPlan 
              renderTextInput={renderTextInput}
              renderCheckbox={renderCheckbox}
              renderDropDown={renderDropDown}
              renderDateInput={renderDateInput}
              />  */}
              <RetireePlan
                renderTextInput={renderTextInput}
                renderCheckbox={renderCheckbox}
                renderDropDown={ renderDropDown }
                renderGroupCheckbox={renderGroupCheckbox}
                renderDateInput={ renderDateInput }
                setFieldValue={ setFieldValue }
                
              />
              
            </AccordionDetails>
          </Accordion>
        </Card> : null}
        <Card style={{marginTop:"10px"}}>
          <Accordion
            className={classes.root}
            expanded={expanded.dependent}
            onChange={handleChangeExpanded("dependent")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
              className="sub-header w-100 p-1 bg-primary text-white"
              style={{ width:"100%" }}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                className="sub-header"
                style={{
                  // color: "#1569C7",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              <Typography
                className={classes.heading}
                style={{ height: "auto" }}
              >
                Dependent Information

              </Typography>
            </AccordionSummary>
            <AccordionDetails className="p-0 page-body-wrapper">
              <Depedentinformation
              renderTextInput={renderTextInput}
              renderCheckbox={renderCheckbox}
              renderDropDown={renderDropDown}
              renderDateInput={renderDateInput}
              dependentInformation={dependentInformation}
              setDependentInformation={setDependentInformation}
              values={values}
              deletedUid={deletedUid}
              setDeletedUid={setDeletedUid}
              setClassValues={setClassValues}
              params={params}
              />
            </AccordionDetails>
          </Accordion>
        </Card>
      </Container>
    </Fragment>
  )
}

export default EnrollMentBenifitsMain