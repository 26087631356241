const visibleColumns = [
  // {
  //   title: "AS400 AccountNo",
  //   field: "AS400_AccountNo",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },{
  //   title: "D365AccountNo",
  //   field: "D365AccountNo",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },{
  //   title: "D365 Description",
  //   field: "D365_Description",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Account No",
  //   field: "Account_Number",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  {
    title: "Dept Div",
    field: "DEPTDIV",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },
  
  {
    title: "Employee ID",
    field: "EMPLOYEE_ID",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "PPE Ending Date",
    field: "Pay_Period_Ending_date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Check Date",
    field: "Check_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
},

// {
//   title: "Check Seq",
//   field: "Check_Sequence",
//   locked: false,
//   filter: "text",
//   width: "160",
//   show: true,
// },



  {
    title: "NAME",
    field: "NAME",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  // {
  //   title: "RATE",
  //   field: "RATE",
  //   locked: false,
  //   filter: "numeric",
  //   editor: "numeric",
  //   currencyType: "USD",
  //   className: 'text-end',
	// 	headerClassName: 'number-align',
  //   width: "200",
  //   show: true,
  // },
  {
    title: "GROSS",
    field: "GROSS",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  // {
  //   title: "Total Deduction",
  //   field: "Ppe_Total_Deductions",
  //   locked: false,
  //   filter: "numeric",
  //   editor: "numeric",
  //   currencyType: "USD",
  //   className: 'text-end',
	// 	headerClassName: 'number-align',
  //   width: "200",
  //   show: true,
  // },
  {
    title: "HEALTH GOVT CONTR",
    field: "HEALTH_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "230",
    show: true,
  },
  {
    title: "DENTAL GOVT CONTR",
    field: "DENTAL_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "GOVT LIFE CONTR",
    field: "GOVT_LIFE_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DC GOVT CONTR",
    field: "DC_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "GOVT NON-BASE CONTR",
    field: "GOVT_NON_BASE_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "GOC DC & UNF",
    field: "GOC_DC_AND_UNF",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "MEDICARE GOVT CONTR",
    field: "MEDICARE_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "HEALTH EMP CONTR",
    field: "HEALTH_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DENT EMP CONTR",
    field: "DENT_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "LIFE EMP CONTR",
    field: "LIFE_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DC EMP CONTR",
    field: "DC_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "MEDICARE EMP CONTR",
    field: "MEDICARE_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
		headerClassName: 'number-align',
    width: "200",
    show: true,
  },




];

export default visibleColumns;
