import * as React from "react";
import { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import dayjs from "dayjs";

const DifferencesModal = ({
  visible,
  setVisible,
  data,
  loading,
  loadingPanel,
}) => {
  const [dataStatus, setDataStatus] = useState("changed");
  const [isLoading, setIsLoading] = useState(false);
  const [diffData, setDiffData] = useState([]);
  useEffect(() => {
    if (visible) {
      setDataStatus("changed");
    }
  }, [visible]);
  const toggleDialog = () => {
    setVisible(!visible);
  };
  const handleChange = (event, newAlignment) => {
    setDataStatus(newAlignment);
    setIsLoading(true);
  };

  let GG1Colums = [
    "changeDateTime",
    "requestedOn",
    "desiredStartDate",
    "createdOn",
    "dateOfRequest",
    "socialSecurityNumber",
    "dateOfBirth",
    "currentProcessStatus",
    "fromLastName",
    "fromFirstName",
    "fromMiddleName",
    "toLastName",
    "toFirstName",
    "toMiddleName",
    "employeeId",
    "requestId",
    "actionEffectiveDate",
    "natureOfActionTitle",
    "fromPositionTitle",
    "fromPayGrade",
    "fromStep",
    "fromRate",
    "fromSalary",
    "fromDepartment",
    "toPositionTitle",
    "toPayGrade",
    "toStep",
    "toRate",
    "toSalary",
    "toDepartment",
    "fteNo",
    "remarks",
    "budgetAcctNo",
    "contactFullName",
    "contactDepartment",
    "contactNumber",
    "contactEmail",
  ];

  useEffect(() => {
    let newData = data.map((item) => {
      let newObj = {};
      Object.keys(item).forEach((key) => {
        if (GG1Colums.includes(key)) {
          newObj[key] = item[key];
        }
      });
      return newObj;
    });

    const formattedData =
      newData &&
      newData.map((item) => {
        const keysToCheck = [
          "changeDateTime",
          "requestedOn",
          "desiredStartDate",
          "createdOn",
        ];
        const searchedVal = keysToCheck.find((key) => key in item);
        if (searchedVal) {
          return {
            ...item,
            [searchedVal]: dayjs(item[searchedVal]).format(
              "DD/MM/YYYY hh:mm A"
            ),
          };
        }

        return item;
      });

    setTimeout(async () => {
      if (dataStatus === "changed") {
        for (let i = 0; i < formattedData.length; i++) {
          for (let key in formattedData[i]) {
            if (i + 1 < formattedData.length) {
              if (formattedData[i][key] == formattedData[i + 1][key]) {
                delete formattedData[i][key];
                delete formattedData[i + 1][key];
              }
            }
          }
        }
        const changedData = formattedData.map((item) => {
          let updatedItem = {};

          for (let key in item) {
            if (Array.isArray(item[key]) && item[key].length === 0) {
              continue; // Skip keys with empty arrays
            }

            updatedItem[key] = item[key];
          }
          return updatedItem;
        });
        await setDiffData(changedData);
        setIsLoading(false);
      } else {
        await setDiffData(formattedData);
        setIsLoading(false);
      }
    }, 500);
  }, [dataStatus, data, isLoading]);

  const [obj1, obj2] = diffData;

 
  return (
    <div>
      {isLoading && loadingPanel()}

      {visible ? (
        diffData && diffData.length > 0 ? (
          <Dialog
            title={
              <Grid>
                <h3>{"Differences"}</h3>
                <ToggleButtonGroup
                  color="primary"
                  value={dataStatus}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="all">All</ToggleButton>
                  <ToggleButton value="changed">Changed</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            }
            onClose={toggleDialog}
          >
            <TableContainer
              style={{
                height: "600px",
                width: "800px",
                marginBottom: "10px",
              }}
              component={Paper}
            >
              {isLoading ? (
                loadingPanel()
              ) : !diffData || (diffData && diffData.length === 0) ? (
                <p
                  style={{
                    textAlign: "center",
                    marginTop: "15%",
                  }}
                >
                  Data not available!
                </p>
              ) : (
                <Table sx={{ minWidth: 800 }} aria-label="simple table">
                  {diffData && diffData?.length ? (
                    <TableHead>
                      <TableRow>
                        <TableCell size="small">Field Name</TableCell>
                        <TableCell size="small">Selected Record</TableCell>
                        <TableCell size="small">Next Record </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : null}
                  {diffData && diffData?.length ? (
                    <>
                      {obj1 &&
                        obj2 &&
                        Object.keys(obj1).map((item, i) => {
                          return (
                            <TableBody key={i}>
                              <TableRow
                                style={{
                                  backgroundColor:
                                    Array.isArray(obj1[item]) &&
                                    obj1[item].length === 0
                                      ? ""
                                      : obj1[item] === obj2[item]
                                      ? ""
                                      : "#59bd59", //"#4cd964",
                                }}
                              >
                                <TableCell>{item}</TableCell>
                                <TableCell>
                                  {obj1[item] === null ||
                                  (typeof obj1[item] === "string" &&
                                    obj1[item].trim() === "") ||
                                  (Array.isArray(obj1[item]) &&
                                    obj1[item].length === 0)
                                    ? "N/A"
                                    : obj1[item]}
                                </TableCell>
                                <TableCell>
                                  {obj2[item] === null ||
                                  (typeof obj2[item] === "string" &&
                                    obj2[item].trim() === "") ||
                                  (Array.isArray(obj2[item]) &&
                                    obj2[item].length === 0)
                                    ? "N/A"
                                    : obj2[item]}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          );
                        })}
                    </>
                  ) : null}
                </Table>
              )}
            </TableContainer>
          </Dialog>
        ) : !diffData || (diffData && diffData.length === 0) ? (
          <p style={{ textAlign: "center" }}>Data not available!</p>
        ) : (
          loadingPanel()
        )
      ) : null}
    </div>
  );
};
export default DifferencesModal;
