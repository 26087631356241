export const GET_ALL_EMPLOYEE_SIGNATURES = "GET_ALL_EMPLOYEE_SIGNATURES";
export const GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS = "GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS";
export const GET_ALL_EMPLOYEE_SIGNATURES_FAIL = "GET_ALL_EMPLOYEE_SIGNATURES_FAIL";
export const ADD_UPDATE_EMPLOYEE_SIGNATURES = "ADD_UPDATE_EMPLOYEE_SIGNATURES";
export const ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS = "ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS";
export const ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL = "ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL";
export const DELETE_SIGNATURE = "DELETE_SIGNATURE`";
export const DELETE_SIGNATURE_SUCCESS = "DELETE_SIGNATURE_SUCCESS";
export const DELETE_SIGNATURE_FAIL = "DELETE_SIGNATURE_FAIL";
export const CLEAR_ADD_UPDATE_SIGNATURE = "CLEAR_ADD_UPDATE_SIGNATURE";
export const RESET_DOCUMENT_DATA = "RESET_DOCUMENT_DATA";
export const GET_SIGNATURE_DATA = "GET_SIGNATURE_DATA";
export const GET_SIGNATURE_DATA_SUCCESS = "GET_SIGNATURE_DATA_SUCCESS";
export const GET_SIGNATURE_DATA_FAIL = "GET_SIGNATURE_DATA_FAIL";
export const UNASSIGN_SIGNATURE = "UNASSIGN_SIGNATURE";
export const UNASSIGN_SIGNATURE_SUCCESS = "UNASSIGN_SIGNATURE_SUCCESS";
export const UNASSIGN_SIGNATURE_FAIL = "UNASSIGN_SIGNATURE_FAIL";
export const GET_SIGNATURE_BY_CONTROL_NUMBER = "GET_SIGNATURE_BY_CONTROL_NUMBER";
export const GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS = "GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS";
export const GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL = "GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL";
export const GENERATE_SIGNATURE_DOCUMENT = "GENERATE_SIGNATURE_DOCUMENT";
export const GENERATE_SIGNATURE_DOCUMENT_SUCCESS = "GENERATE_SIGNATURE_DOCUMENT_SUCCESS";
export const GENERATE_SIGNATURE_DOCUMENT_FAIL = "GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL";
export const UPDATE_SIGNATURE_STATUS = "UPDATE_SIGNATURE_STATUS";
export const UPDATE_SIGNATURE_STATUS_SUCCESS = "UPDATE_SIGNATURE_STATUS_SUCCESS";
export const UPDATE_SIGNATURE_STATUS_FAIL = "GENERATE_SIGNATURE_DOCUMENT_FAIL";

export const APPROVE_REJECT_EMPLOYEE_SIGNATURE = "APPROVE_REJECT_EMPLOYEE_SIGNATURE";
export const APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS = "APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS";
export const APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL = "APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL";

export const EMPLOYEESERVICE_REQUEST = "EMPLOYEESERVICE_REQUEST";
export const EMPLOYEESERVICE_REQUEST_SUCCESS = "EMPLOYEESERVICE_REQUEST_SUCCESS";
export const EMPLOYEESERVICE_REQUEST_FAIL="EMPLOYEESERVICE_REQUEST_FAIL"