const visibleColumns = [
  {
    title: "Department Name",
    field: "DepartmentName",
    locked: true,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Action Date",
    field: "ActionDate",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  {
    title: "No Of Days",
    field: "NoOfDays",
    locked: false,
    filter: "text",
    width: 180,
    show: true,
  },
  {
    title: "Notification Status",
    field: "notificationStatus",
    locked: false,
    filter: "text",
    width: 240,
    show: true,
  },
  {
    title: "EmployeeID",
    field: "EmployeeID",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Name",
    field: "Name",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },

  {
    title: "DPDV",
    field: "DPDV",
    locked: false,
    filter: "text",
    width: 100,
    show: true,
  },

  {
    title: "Description",
    field: "Description",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Position Number",
    field: "NPOSITION_NO",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Status",
    field: "Status",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Notification Status",
    field: "notificationStatus",
    locked: false,
    filter: "text",
    width: 100,
    show: true,
  },
];

export default visibleColumns;
