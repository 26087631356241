import React from 'react';
import Modal from '@mui/material/Modal';
import FileViewer from 'react-file-viewer';
import { Box } from '@mui/material';

const FileModal = ({ isOpen, handleClose, fileType, selectedFile }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            width: 1200,
            height: 700,
            overflowY: "auto",
            textAlign:"center"
          }}
        >
            
        {fileType && (
          <FileViewer
            fileType={fileType}
            filePath={selectedFile}
            onError={(e) => console.log('Error:', e)}
          />
        )}
      </Box>
    </Modal>
  );
};

export default FileModal;
