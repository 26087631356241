import { takeLatest, put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SEND_QUICK_ACTION_REQUEST, GET_MAIN_MENU } from "./actiionType";
import {
  sendQuickActionSuccess,
  sendQuickActionFailure,
  getmainmenusuccess,
  getmainmenufailure,
} from "./action";
import { ShowError, ShowSucess } from "../../_helper/helper";
import {
  createmainmenu,
  getmainmenu,
} from "../../_helper/services/EMR/MainMenu";

function* onCreatemainmenu({ payload }) {
  try {
    const response = yield call(createmainmenu, payload);
    yield put(sendQuickActionSuccess(response));
  } catch (error) {
    yield put(sendQuickActionFailure(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error);
  }
}
function* onGetMainmenu({ payload }) {
  console.log("GET_MAIN_MENU action dispatched", payload);
  try {
    const response = yield call(getmainmenu, payload);
    yield put(getmainmenusuccess(response));
  } catch (error) {
    yield put(getmainmenufailure(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error);
  }
}

function* MainMenuSaga() {
  yield takeLatest(SEND_QUICK_ACTION_REQUEST, onCreatemainmenu);
  yield takeLatest(GET_MAIN_MENU, onGetMainmenu);
}

export default MainMenuSaga;
