import {
	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
	GET_CHART_DATA_YEAR_SERVICE_FAIL,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_GROUP_SUCCESS,
	GET_CHART_DATA_AGE_GROUP_FAIL,
	GET_CHART_DATA_AGE_Salary_Group,
	GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
	GET_CHART_DATA_AGE_Salary_Group_FAIL,
	GET_CGI_BOTTLENET,
	GET_CGI_BOTTLENET_SUCCESS,
	GET_CGI_BOTTLENET_FAILURE,
	GET_SOLIDGAUGE,GET_SOLID_GAUGE_SUCCESS,GET_SOLID_GAUGE_FAILURE, GET_BBMRGG1ANALYST, GET_BBMRGG1ANALYST_SUCCESS, GET_BBMRGG1ANALYST_FAILURE, GET_BBMRGG1ASSIGNANALYST, GET_BBMRGG1ASSIGNANALYST_SUCCESS, GET_BBMRGG1ASSIGNANALYST_FAILURE
} from "./actionTypes";

export const getChartDataYearOfService = (queryName) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE,
		payload: queryName
	};
};


export const getChartDataYearOfServiceSuccess = (data) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
		payload: data
	};
};

export const getChartDataYearOfServiceFail = (error) => {
	return {
		type: GET_CHART_DATA_YEAR_SERVICE_FAIL,
		payload: error
	};
};

export const getChartDataAgeGroup = (queryName) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP,
		payload: queryName
	};
};



export const getChartDataAgeGroupSuccess = (data) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP_SUCCESS,
		payload: data
	};
};


export const getChartDataAgeGroupFail = (error) => {
	return {
		type: GET_CHART_DATA_AGE_GROUP_FAIL,
		payload: error
	};
};


export const BBMRGG1Analyst = (queryName) => {
	return {
		type: GET_BBMRGG1ANALYST,
		payload: queryName
	};
};

export const BBMRGG1AnalystSuccess = (data) => {
	return {
		type: GET_BBMRGG1ANALYST_SUCCESS,
		payload: data
	};
};


export const BBMRGG1AnalystFail = (error) => {
	return {
		type: GET_BBMRGG1ANALYST_FAILURE,
		payload: error
	};
};

export const BBMRGG1AssignAnalyst = (queryName) => {
	return {
		type: GET_BBMRGG1ASSIGNANALYST,
		payload: queryName
	};
};

export const BBMRGG1AssignAnalystSuccess = (data) => {
	return {
		type: GET_BBMRGG1ASSIGNANALYST_SUCCESS,
		payload: data
	};
};


export const BBMRGG1AssignAnalystFail = (error) => {
	return {
		type: GET_BBMRGG1ASSIGNANALYST_FAILURE,
		payload: error
	};
};

export const getChartDataSalaryGroup = (queryName) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group,
		payload: queryName
	};
};


export const getChartDataSalaryGroupSuccess = (data) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
		payload: data
	};
};

export const getChartDataSalaryGroupFail = (error) => {
	return {
		type: GET_CHART_DATA_AGE_Salary_Group_FAIL,
		payload: error
	};
};
export const getcglBottleNet=( payload )=>{
	 
	return{
		type:GET_CGI_BOTTLENET,
		 payload:payload
		 
	}
}
export const getcglBottleNetSuccess=(cglBottleNetData)=>{
	 
	return{
		type:GET_CGI_BOTTLENET_SUCCESS,
		payload:cglBottleNetData,
	}
}
export const getcglBottleNetFailure=(error)=>{
	console.log("dontenterror",error)
	return{
		type:GET_CGI_BOTTLENET_FAILURE,
		payload:error,
	}
}

export const getsolidGauge = (PPE)=>{
	return{
		type:GET_SOLIDGAUGE,
		payload:PPE
	}
}
export const getsolidGaugeSuccess = (data)=>{
	return{
		type:GET_SOLID_GAUGE_SUCCESS,
		payload:data
	}
}
export const getsolidGaugeFailure = (error)=>{
	return{
		type:GET_SOLID_GAUGE_FAILURE,
		payload:error,
	}
}