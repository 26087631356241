import React, { useEffect, useState, useCallback } from "react";
import tab from "../../Components/Leave/LeaveApplication/components/tab.css";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import CloseIcon from "@mui/icons-material/Close";
import {
  advanceSearch,
  loadColumns,
} from "../../store/advanceSearchv2/actions";
import { Chip } from "@progress/kendo-react-buttons";
import dayjs from "dayjs";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  gg1ExcelReport,
  storeActiveData,
  storeColumns,
} from "../../store/advanceSearchv2/actions";
import {
  getStoredTheme,
  userConfiguration,
  gridDataTable,
} from "../../store/themeCustomizer/action";
import { ShowSucess } from "../../_helper/helper";
import {
  Tooltip,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // Chip,
  Select,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  setGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFile, faFilePdf } from "@fortawesome/pro-duotone-svg-icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { CustomMenu } from "./customMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { filterBy } from "@progress/kendo-data-query";
import ColumnFilter from "./columnFilter";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { faDiagramProject } from "@fortawesome/pro-duotone-svg-icons";
import FullScreenWorkflowDialog from "../../Components/Workflow/WorkflowReadOnly";
import QRCodeModal from "../../Components/AdvanceSearch/QRCodeModal";
import AdvanceSearch from "../../CommonComponents/AdvanceSearch/index";
import { Check, CropSharp, Watch } from "@material-ui/icons";
import { findMaxDigitsAmongFields } from "../CommonFunction/index";
import { renderNumberFormat } from "../../utils/rendernumber";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  SetGridDataState,
  SetGridFilter,
  SetGridSort,
  setAdditionalWhere,
  setGridPageSize,
  setGridSkip,
} from "../../store/Common/actions";

import {
  BOOLEAN_FIELDS,
  BUTTON_TYPE_FIELDS,
  CUSTOME_BUTTON_STATUS_FIELDS,
  STATUS_FIELDS,
} from "./Constants";
import { AlternateEmail } from "@mui/icons-material";

import { useAuth } from "oidc-react";
import { Btn, H6 } from "../../AbstractElements";
import { Icon, getter } from "@progress/kendo-react-common";
import { CustomTextFilter } from "../Customtextfilter";
import { CustomFilterCell } from "./customFilterCell";
import { getUserRole } from "../../_helper/helper";
import { isEqual } from "lodash";
import { makeStyles } from "@material-ui/core";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const noFooterCellFields = [
  "ID",
  "EmployeeId",
  "EMPLOYEE_ID",
  "Employee_No",
  "Check_Sequence",
  "Check_Seq",
  "FundID",
];
const useCheckboxStyles = makeStyles({
  root: {
    padding: "5px 0px !important",
  },
});

const initialDataState = {
  sort: [
    // {
    //   field: "code",
    //   dir: "asc",
    // },
  ],
  //   take: 10,
  //   skip: 0,
};
const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
const SQL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const SQL_DATE_FORMAT = "DD/MM/YYYY";
const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;
const mappings = {
  eq: "{0} = '{1}'",
  neq: "{0} != '{1}'",
  isnull: "{0} IS NULL",
  isnotnull: "{0} IS NOT NULL",
  lt: "{0} < '{1}'",
  lte: "{0} <= '{1}'",
  gt: "{0} > '{1}'",
  gte: "{0} >= '{1}'",
  startswith: "{0} LIKE '{1}%'",
  endswith: "{0} LIKE '%{1}'",
  doesnotstartwith: "{0} NOT LIKE '{1}%'",
  contains: "{0} LIKE '%{1}%'",
  doesnotcontain: "{0} NOT LIKE '%{1}%'",
  isempty: "{0} = ''",
  isnotempty: "{0} != ''",
};
const formatFilter = (mapping, field, value) => {
  return mapping.replace("{0}", field).replace("{1}", value);
};
const formatDateFilter = (mapping, field, value) => {
  return mapping
    .replace("{0}", `CONVERT(DATE,${field})`)
    .replace("'{1}'", `CONVERT(DATE,'${value}')`);
};
const toSQLExpression = (filter) => {
  if (!filter) return;

  let filters = filter.filters,
    field,
    value,
    operator,
    mapping,
    type,
    logic = filter.logic || "AND",
    result = [];

  for (let i = 0; i < filters.length; i++) {
    filter = filters[i];
    field = filter.field;
    value = filter.value;
    operator = filter.operator;

    if (filter.filters) {
      filter = toSQLExpression(filter);
    } else {
      mapping = mappings[operator];
      type = typeof value; ///$.type

      // if (value instanceof Date) {
      //   value =moment(value).format(SQL_DATE_FORMAT) // toString(value.toISOString().slice(0, 19).replace('T', ' '));
      // }
      if (value instanceof Date) {
        filter = formatDateFilter(
          mapping,
          field,
          moment(value).format(SQL_DATE_FORMAT)
        );
      } else {
        filter = formatFilter(mapping, field, value);
      }
    }

    result.push(filter);
  }

  filter = result.join(" " + logic.toUpperCase() + " ");

  if (result.length > 1) {
    filter = "(" + filter + ")";
  }

  return filter;
};
let newData = [];
const TelerikLightGrid = (props) => {
  let {
    id,
    setSortedColumns,
    selectedColumns,
    pageSize,
    onPageChange,
    onPageSizeChange,
    tableName,
    additionalWhere,
    onFilterChange,
    onSortChange,
    totalCount,
    skip,
    loading,
    sortData,
    actions,
    handleRadioChange,
    chooseFilterMethod,
    setChooseFilterMethod,
    searchParams,
    isDashboard,
    expandField,
    moreGridTool,
    onExpandChange,
    handleClick,
    isEmployeeManagement,
    aggregates = [],
    // searchParameter,
    actionsWidth,
    setIsActive,
    isActive,
    pageNumber,
    isPmValidation,
    showCheckboxes,
    onSelectionChange,
    onHeaderSelectionChange,
    selectedState,
    groupDepartment,
    setDepartmentModalContent,
    departmentModalContent,
    groupDepartmentLoader,
    setGroupDepartmentLoader,
    checkboxAction = false,
    handleSelectCheckbox = () => {},
    checkboxKey = null,
    clearSelectedCheckbox = null,
    handleSelectAll = () => {},
    checkSelectedCheckbox = null,
    setSelectedCheckBoxed = () => {},
    showActionCheckBoxTooltipText,
    deleteState = false,
    setUnCheckEmpno = () => {},
    unCheckedEmpNo,
    defaultEmpID,
    isShowActionCheckBox = false,
    onGroupChange,
    data,
    enableFooterCell,
    initialGridParams,
    defaultAdditionalWhere,
    hideSaveIcon,
  } = props;
  const {
    filter: storeFilter,
    dataState: storeDataState,
    sort: storeSort,
    skip: storeSkip,
    pageSize: storePageSize,
    additionalWhere: storeAdditionalWhere,
  } = useSelector((state) => state.CommonReducer);
  const {
    savedLocation,
    searchParameter,
    GG1ExcelReport,
    loadingExcelReport,
    activeResp,
    fileExtension,
    ColumnsSorted,
    searchResultsV2,
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const [enableCheckboxes, setEnableCheckboxes] = useState(false);
  const {
    gridSetting = {},
    GridData = {},
    loadingSearch,
    message,
  } = useSelector((state) => state?.ThemeCustomizerReducer || {});
  // const handleExcelExport = async () => {
  //   try {
  //     setIsExporting(true);

  //     // Simulate export delay (replace with your actual export logic)
  //     await new Promise((resolve) => setTimeout(resolve, 3000));

  //     // Perform your export logic here

  //   } finally {
  //     setIsExporting(false);
  //   }
  // };
  const { results, rowCount } =
    searchResultsV2[id] == undefined ? { results: [] } : searchResultsV2[id];
  //   const data = results;
  const dispatch = useDispatch();
  const URL = useLocation();
  const [group, setGroup] = React.useState(props.group || []);
  const minGridWidth = React.useRef(0);
  const grid = React.useRef(null);
  const [applyMinWidth, setApplyMinWidth] = React.useState(false);
  const [gridCurrent, setGridCurrent] = React.useState(0);
  let gridPDFExport;
  const pdfExportComponent = React.useRef(null);
  const _export = React.useRef(null);
  const handleExcelExport = () => {
    const exportParams = {
      ...payload,
      columns: selectedColumns
        .filter((i) => i.show)
        .map((d) => d.field)
        .join(","),
      fileExtension: "xls",
      isOJA: id == "JA_Announcement",
    };

    dispatch(gg1ExcelReport(exportParams));
  };

  useEffect(() => {
    if (typeof GG1ExcelReport === "object" && startDownload === true) {
      downloadExcel();
    }
  }, [GG1ExcelReport]);
  useEffect(() => {
    dispatch(loadColumns(tableName));
  }, []);
  async function downloadExcel() {
    // const contentDisposition = GG1ExcelReport?.headers?.get(
    //   "content-disposition"
    // );
    //const filenameMatch =
    // contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const filename = `report_${new Date().getTime()}`;
    // Create a Blob from the response data
    const blob = await GG1ExcelReport.data;
    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  const exportPDF = () => {
    try {
      setIsExportingPDF(true);

      const exportParams = {
        ...payload,
        columns: selectedColumns
          .filter((i) => i.show)
          .map((d) => d.field)
          .join(","),
        fileExtension: "pdf",
        isOJA: id == "JA_Announcement",
        ...(props?.header ? { headername: props.header } : {}),
        ...(props?.footerContent ? { footerContent: props.footerContent } : {}),
        ...(props?.headerContent ? { headerContent: props.headerContent } : {}),
        ...(props?.additionalExportPdfPayload
          ? props.additionalExportPdfPayload
          : {}),
      };

      dispatch(gg1ExcelReport(exportParams));
    } finally {
      setIsExportingPDF(false);
    }
  };

  const navigate = useNavigate();
  const { userData } = useAuth();
  const currentRole = getUserRole(userData);

  const [filter, setFilter] = React.useState({ logic: "and", filters: [] });
  const [startDownload, setStartDownload] = useState(false);
  const [gridParams, setGridParams] = useState({
    sort: [],
    skip: 0,
    pageSize: 100,
  });
  const [workflowInfo, setWorkflowInfo] = useState({
    workflowID: 0,
    CurrentStatus: "",
    employeeName: "",
    hrControlNo: "",
  });
  const [dataState, setDataState] = React.useState(initialDataState);

  const [openModal, setOpenModal] = useState(false);
  const [result, setResult] = React.useState({ data: [] });
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectAll, setSelectAll] = useState();
  const DATA_ITEM_KEY = "ID";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);
  const checkboxProps = showCheckboxes
    ? {
        dataItemKey: DATA_ITEM_KEY,
        selectedField: SELECTED_FIELD,
        selectable: {
          enabled: true,
          drag: false,
          cell: false,
          mode: "multiple",
        },
        onSelectionChange: onSelectionChange,
        onHeaderSelectionChange: onHeaderSelectionChange,
      }
    : {};
  const checkBoxClasses = useCheckboxStyles();
  const storedColumns = {};
  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;
    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    setResult({ data: newDataState.data });
    return newDataState;
  };
  useEffect(() => {
    if (
      searchParams &&
      searchParams !== null &&
      Object.keys(searchParams).length > 0
    ) {
      setChooseFilterMethod("advanceSearch");
    }
  }, [searchParams]);
  const [digitVars, setDigitVars] = useState(0);
  const [collapsedState, setCollapsedState] = React.useState([]);
  const expandChange = (event) => {
    const item = event.dataItem;
    if (item && item?.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };
  // useEffect(() => {
  //   if (savedLocation !== "") {
  //     setChooseFilterMethod(
  //       savedLocation === URL?.pathname ? "advanceSearch" : chooseFilterMethod
  //     );
  //   }
  // }, [savedLocation]);

  const handleSelectAllCheckbox = (event) => {
    if (event?.target?.checked) {
      let selectedData = props.data.map((obj) =>
        checkboxKey ? obj[checkboxKey] : JSON.stringify(obj)
      );
      setSelectedCheckbox(selectedData);
      setSelectAll(true);
      handleSelectAll(true);
      setSelectedCheckBoxed(selectedData);
    } else {
      setSelectedCheckbox([]);
      setSelectAll(false);
      handleSelectAll(false);
      setSelectedCheckBoxed([]);
    }
  };
  const handleCheck = (clickProps, e) => {
    const updatedSelectedEmployees = new Set(selectedCheckbox);

    if (e.target.checked) {
      !!checkboxKey &&
        updatedSelectedEmployees.add(clickProps?.dataItem[checkboxKey]);
      if (deleteState) {
        let removeEmp = unCheckedEmpNo.filter(
          (item) => item !== clickProps?.dataItem[checkboxKey]
        );
        setUnCheckEmpno(removeEmp);
      }
    } else {
      !!checkboxKey &&
        updatedSelectedEmployees.delete(clickProps?.dataItem[checkboxKey]);
      if (deleteState) {
        let isExistEmpID = defaultEmpID?.find(
          (i) => i == clickProps?.dataItem[checkboxKey]
        );
        if (isExistEmpID) {
          unCheckedEmpNo.push(isExistEmpID);
        }
      }
    }
    let data = JSON.parse(JSON.stringify(clickProps?.dataItem));
    delete data["expanded"];

    if (e.target.checked) {
      updatedSelectedEmployees.add(
        checkboxKey ? clickProps?.dataItem[checkboxKey] : JSON.stringify(data)
      );
    } else {
      updatedSelectedEmployees.delete(
        checkboxKey ? clickProps?.dataItem[checkboxKey] : JSON.stringify(data)
      );
    }
    if (updatedSelectedEmployees.size == props?.data?.length) {
      setSelectAll(true);
      handleSelectAll(true);
    } else {
      setSelectAll(false);
      handleSelectAll(false);
    }
    setSelectedCheckbox(Array.from(updatedSelectedEmployees));
    setSelectedCheckBoxed(Array.from(updatedSelectedEmployees));
  };

  useEffect(() => {
    if (selectAll && !!(props?.data || []).length) {
      setSelectedCheckbox(
        props?.data.map((obj) =>
          checkboxKey ? obj[checkboxKey] : JSON.stringify(obj)
        )
      );
    }
  }, [props?.data, checkboxKey]);

  useEffect(() => {
    handleSelectCheckbox(selectedCheckbox);
  }, [selectedCheckbox]);

  useEffect(() => {
    if (
      checkSelectedCheckbox &&
      !isEqual(selectedCheckbox, checkSelectedCheckbox)
    )
      setSelectedCheckbox(checkSelectedCheckbox);
  }, [checkSelectedCheckbox]);

  useEffect(() => {
    if (clearSelectedCheckbox != null || clearSelectedCheckbox != undefined) {
      setSelectedCheckbox([]);
    }
    setSelectAll(false);
    handleSelectAll(false);
  }, [clearSelectedCheckbox]);

  React.useEffect(() => {
    setGridParams({ pageSize: pageSize, skip: skip, sort: sortData });
  }, [pageSize, skip, sortData]);
  React.useEffect(() => {
    let gridState = storeDataState[id];
    if (gridState) {
      setDataState(gridState);
    }
  }, [id]);
  React.useEffect(() => {
    let gridState = storeDataState[id] || { sort: sortData, group: group };
    if (gridState) {
      setDataState(gridState);
    }
  }, [storeDataState, id]);

  useEffect(() => {
    setDataState({ sort: gridParams?.sort, group: group });
  }, [id, storeDataState]);
  const payload = {
    tableName: tableName,
    searchParams: JSON.stringify(searchParams),
    columns: "*",
    location: "global",
    pageNumber: pageNumber,
    pageSize: pageSize,
    sortData:
      sortData && sortData?.length > 0
        ? sortData.reduce(
            (accumulator, item) =>
              (accumulator === "" ? " " : accumulator + ", ") +
              `[${item.field}]` +
              " " +
              item.dir,
            ""
          )
        : "",
    additionalWhere: additionalWhere ?? "",
    qID: 0,
  };
  useEffect(() => {
    dispatch(advanceSearch(payload, id));
  }, [additionalWhere, sortData, pageSize, pageNumber, dispatch]);
  useEffect(() => {
    const initialGroupFiled = Array.isArray(storeDataState[id]?.group)
      ? []
      : []; // { field: sortData[0]?.field }
    const allGroupFiled = initialGroupFiled.concat(
      Array.isArray(storeDataState[id]?.group)
        ? storeDataState[id]?.group
        : group
    );
    setGroup(allGroupFiled);
  }, [storeDataState[id]?.group]);

  useEffect(() => {
    let data = {};
    data[id] = dataState;
    dispatch(SetGridDataState(data));
  }, [dataState]);
  const [employeeInfo, setEmployeeInfo] = useState({
    ID: null,
    NAME: "",
  });
  React.useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    selectedColumns.map((item) =>
      item.minWidth !== undefined
        ? (minGridWidth.current += item.minWidth)
        : minGridWidth.current
    );
    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, []);
  const handleResize = () => {
    if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };
  const customTextFilterSearch = (props) => (
    <CustomTextFilter {...props} data={props.field} />
  );

  const handleModal = (id, name) => {
    setEmployeeInfo({ ...employeeInfo, ID: id, NAME: name });
    setOpenModal(!openModal);
  };
  handleRadioChange = (e) => {
    setChooseFilterMethod(e.target.value);
  };
  const handleActiveChange = (e) => {
    const val = e.target.value;
    setIsActive(val);
  };

  const updateLockColumn = async (col, status) => {
    let copyArray = [...selectedColumns];
    let objectAtIndex = copyArray.findIndex((i) => {
      return i.field === col;
    });
    let copyObject =
      objectAtIndex !== -1 ? { ...copyArray[objectAtIndex] } : {};
    // debugger;
    if (copyObject) {
      copyObject["locked"] = status;
      copyArray[objectAtIndex] = copyObject;
      setSortedColumns(copyArray);
    }
  };
  useEffect(() => {
    processWithGroups(
      props.data,
      { sort: gridParams.sort, group: props.group || [] } //|| storeDataState[id]
    );
  }, [id, props.data]);

  const dataStateChange = (event) => {
    const newDataState = processWithGroups(props.data, event.dataState);
    setResult({ data: newDataState.data });
    setDataState(event.dataState);
  };
  newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState,
  });
  useEffect(() => {
    if (selectedColumns && selectedColumns.length) {
      const newDigitVars = {};
      selectedColumns
        .filter((i) => i.show)
        .forEach((i) => {
          const field = i.field;
          if (field !== null && field !== undefined) {
            newDigitVars[field] = findMaxDigitsAmongFields(props.data, [field]);
          }
        });
      setDigitVars(newDigitVars);
    }
  }, [selectedColumns, props.data]);

  const loaderStyle = {
    border: "2px solid #f3f3f3",
    borderTop: "2px solid #3498db",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupHeader") {
      if (cellProps.dataItem.field === "Department_name" && groupDepartment) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "100%",
              marginLeft: "2rem",
            }}
          >
            <h6 style={{ whiteSpace: "nowrap", margin: "0", padding: "0" }}>
              {cellProps.dataItem.value}
            </h6>
            {groupDepartmentLoader &&
            departmentModalContent?.[0]?.["Department_name"] ==
              cellProps?.dataItem?.value ? (
              <div>
                <style>{keyframesStyle}</style>
                <div style={loaderStyle}></div>
              </div>
            ) : (
              <Icon
                style={{ cursor: "pointer", fontSize: "20px" }}
                name="file-report"
                onClick={() => {
                  console.log(cellProps, "cellPropsGropu");
                  //this is group data(icon) trigger function
                  setDepartmentModalContent(cellProps?.dataItem?.items ?? []);
                }}
              />
            )}
          </div>
        );
      }
    }
    return tdElement;
  };

  const removeExpandedKey = (props) => {
    let tempData = JSON.parse(JSON.stringify(props?.dataItem));
    delete tempData["expanded"];
    return tempData;
  };
  const debounce = (func, delay = 500) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  // const debouncedFilterChange = useCallback(
  //   debounce((newWhere) => {
  //     onFilterChange(newWhere);
  //   }, 500),
  //   []
  // );

  let GridComponent = (
    <>
      {loading && loadingPanel}
      <Grid
        // style={{
        //   maxHeight: "calc(100vh - 420px)",
        //   minHeight: "450px",
        // }}
        key={id}
        style={{ height: `calc(100vh - 240px)` }}
        data={newData}
        {...dataState}
        {...checkboxProps}
        reorderable={true}
        // onColumnReorder={(e) => {
        //   let arr = [];
        //   e.target.columnResize?.columns.forEach((column) => {
        //     arr.push(column?.title);
        //   });
        //   const rearrangedArray = selectedColumns.sort((a, b) => {
        //     const indexA = arr.indexOf(a.title);
        //     const indexB = arr.indexOf(b?.title);
        //     if (indexA !== -1 && indexB !== -1) {
        //       return indexA - indexB;
        //     }
        //     if (indexA !== -1) {
        //       return -1;
        //     } else if (indexB !== -1) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        //   //   setSelectedColumnsV2(rearrangedArray);
        //   // storedColumns[id] = rearrangedArray;
        //   //   dispatch(storeColumns({ ...ColumnsSorted, [id]: rearrangedArray }));
        // }}
        group={group}
        // onGroupChange={(evt) => {
        //   const formattedGroup =
        //     Object.values(
        //       evt?.group.reduce((a, b) => {
        //         if (!a[b.field]) a[b.field] = b;
        //         return a;
        //       }, {})
        //     ).map((obj) => {
        //       obj.aggregates = [];
        //       return obj;
        //     }) || [];
        //   let ungroupData = group.filter(
        //     (d) => !evt.group || !evt.group.find((c) => c?.field === d?.field)
        //   );

        //   if (typeof onGroupChange === "function") {
        //     onGroupChange(formattedGroup);
        //   }

        //   const GroupForSort =
        //     formattedGroup.map((obj) => {
        //       obj["dir"] = "asc";
        //       delete obj?.aggregates;
        //       return obj;
        //     }) || [];
        //   let storedGridPaSort = gridParams?.sort || [];
        //   storedGridPaSort = storedGridPaSort
        //     .filter(
        //       (d) =>
        //         !ungroupData || !ungroupData.find((c) => c?.field === d?.field)
        //     )
        //     .filter((d) => evt?.group.map((c) => c.field).indexOf(d.field) < 0);
        //   const concateGroupSort = GroupForSort.concat(storedGridPaSort);
        //   // setGridParams({ ...gridParams, sort: concateGroupSort });
        // }}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 20, 50, 100, 200, 500, 1000, 1500],
          pageSizeValue: gridParams.pageSize,
          // style: { width: '200px' }
        }}
        expandField={expandField || "expanded"}
        onExpandChange={onExpandChange || expandChange}
        sortable={{
          allowUnsort: true,
          mode: "multiple",
        }}
        groupable={{
          footer: "visible",
        }}
        pageSize={gridParams.pageSize}
        total={totalCount}
        skip={gridParams.skip}
        take={gridParams.pageSize}
        filter={filter}
        lockGroups={true}
        // columnVirtualization={true}
        onFilterChange={(props) => {
          setResult({ data: [] });
          const filterArr = props?.filter?.filters?.find((d) => d?.filters);
          const nonFiltersKeyObj = props?.filter?.filters?.find(
            (d) => !d?.filters
          );
          if (nonFiltersKeyObj && filterArr && filterArr?.filters) {
            filterArr?.filters.unshift(nonFiltersKeyObj);
          }
          const updatedFilter = filterArr ?? props?.filter;
          const formattedFilter = updatedFilter?.filters?.map((d) =>
            id === "employeemaster" && d?.field === "Emp_Status_Title"
              ? {
                  ...d,
                  operator: "eq",
                }
              : d
          );

          const updatedFilterArr = {
            filters: formattedFilter || [],
            logic: "and",
          };
          setFilter(updatedFilterArr);
          let additionalWhere = "";
          const params = toSQLExpression(updatedFilterArr);
          if (
            defaultAdditionalWhere &&
            defaultAdditionalWhere !== "" &&
            params &&
            params !== ""
          ) {
            additionalWhere = `${params}  AND  ${defaultAdditionalWhere}`;
          } else {
            additionalWhere = params;
          }
          //   debounce(onFilterChange(additionalWhere), 500);
          onFilterChange(additionalWhere);
          return false;
        }}
        onSortChange={(props) => {
          setTimeout(() => {
            onSortChange(props.sort);
          }, 500);
        }}
        sort={gridParams.sort}
        onPageChange={(evt) => {
          onPageChange(evt);

          // window.scrollY(0, 0);
        }}
        filterable={
          // isDashboard === true || chooseFilterMethod === "normalSearch"
          //   ? true
          //   : false
          true
        }
        resizable={true}
        onDataStateChange={dataStateChange}
        // cellRender={cellRender}

        // onDataStateChange={(e) => {
        //   setDataState(e.dataState);
        // }}
      >
        {filter && filter?.filters?.length > 0 && (
          <GridToolbar>
            <label>Filter By</label>

            {filter &&
              filter?.filters?.map((d, i) => {
                if (d?.field) {
                  let title = "";
                  const matchingColumn = JSON.parse(
                    JSON.stringify(selectedColumns)
                  ).find((c) => c.field === d?.field);
                  if (matchingColumn && matchingColumn?.title) {
                    title = matchingColumn?.title;
                  }
                  return (
                    <Chip
                      key={`${i + 1}xy`}
                      style={{ margin: "8px" }}
                      text={` ${title || d?.field}   ${d?.operator}   ${
                        typeof d?.value === "object"
                          ? dayjs(d?.value).format("MM/DD/YYYY")
                          : d?.value
                      }`}
                      onRemove={(e) => {
                        const selectFilter = e.target?.props?.value;
                        const filteredFilterArr = filter?.filters.filter(
                          (d) => d?.field !== selectFilter
                        );
                        let additionalWhere = "";
                        const payloadFilterArr = {
                          logic: "and",
                          filters: filteredFilterArr,
                        };
                        const params = toSQLExpression(payloadFilterArr);
                        if (
                          defaultAdditionalWhere &&
                          defaultAdditionalWhere !== "" &&
                          params &&
                          params !== ""
                        ) {
                          additionalWhere = `${params}  AND  ${defaultAdditionalWhere}`;
                        } else {
                          additionalWhere = params;
                        }
                        onFilterChange(additionalWhere);

                        setFilter(payloadFilterArr);
                      }}
                      removable={true}
                      value={d?.field}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </GridToolbar>
        )}
        {gridParams && gridParams?.sort && gridParams?.sort.length > 0 && (
          <GridToolbar>
            <label>Sort By</label>
            {gridParams &&
              gridParams?.sort &&
              gridParams?.sort.map((d, i) => {
                if (d?.field) {
                  let title = "";
                  const matchingColumn = JSON.parse(
                    JSON.stringify(selectedColumns)
                  ).find((c) => c.field === d?.field);
                  if (matchingColumn && matchingColumn?.title) {
                    title = matchingColumn?.title;
                  }
                  return (
                    <Chip
                      key={`${i + 1}chip`}
                      style={{ margin: "8px" }}
                      text={` ${title || d?.field}     ${d?.dir}`}
                      onRemove={(e) => {
                        const selectTag = e.target?.props?.value;
                        const filteredSort =
                          gridParams &&
                          gridParams?.sort &&
                          gridParams?.sort?.filter(
                            (d) => d?.field !== selectTag
                          );
                        const filteredGroup =
                          group && group?.length > 0
                            ? group?.filter((d) => d?.field !== selectTag)
                            : [];
                        setGroup(filteredGroup);

                        dataState.group = filteredGroup;

                        onSortChange(filteredSort);
                      }}
                      value={d?.field}
                      removable={true}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </GridToolbar>
        )}
        <GridToolbar>
          <div style={{ float: "left", width: "50%" }}>
            <Tooltip title="Export Excel" placement="bottom">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                // onClick={excelExport}
                onClick={() => {
                  setStartDownload(true);
                  handleExcelExport();
                }}
              >
                {loadingExcelReport && fileExtension === "xls" ? (
                  <Spinner size={24} />
                ) : (
                  // <i className="fa-duotone fa-file font-primary"></i>
                  <Icon style={{ fontSize: "25px" }} name="file-excel" />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Export PDF" placement="bottom">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => {
                  setStartDownload(true);
                  exportPDF();
                }}
              >
                {loadingExcelReport && fileExtension === "pdf" ? (
                  <Spinner size={24} />
                ) : (
                  // <i class="fa-duotone fa-file-pdf font-primary"></i>
                  <Icon style={{ fontSize: "25px" }} name="file-pdf" />
                )}
              </IconButton>
            </Tooltip>

            <ColumnFilter
              id={id}
              columns={selectedColumns || []}
              setSortedColumns={setSortedColumns}
            />
            {typeof moreGridTool === "function" &&
              moreGridTool(enableCheckboxes)}
          </div>
        </GridToolbar>
        {showCheckboxes && (
          <GridColumn
            filterable={false}
            field={"selected"}
            width={70}
            headerSelectionValue={
              newData.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
        )}
        {actions && !!actions.length && (
          <GridColumn
            // field="ID"
            title="Actions"
            sortable={false}
            width={
              actionsWidth
                ? actionsWidth
                : isEmployeeManagement
                ? "200px"
                : actions?.length <= 3
                ? `${actions?.length * 30 + 70}px`
                : `${actions?.length * 30}px`
            }
            filterCell={() => {
              return (
                !!isShowActionCheckBox && (
                  <Typography className="text-center">
                    <Tooltip
                      style={{ cursor: "pointer" }}
                      title={showActionCheckBoxTooltipText ?? "Select All"}
                      placement="bottom"
                    >
                      <Checkbox
                        size="small"
                        checked={
                          props?.data?.length > 0 &&
                          selectedCheckbox?.length == props?.data?.length
                        }
                        onChange={handleSelectAllCheckbox}
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Typography>
                )
              );
            }}
            footerCell={() => {
              if (enableFooterCell) {
                return (
                  <td>
                    <H6>Total:</H6>
                  </td>
                );
              } else {
                return null;
              }
            }}
            // filterable={false}
            // locked={true}
            cell={(props) => {
              if (props.rowType === "groupHeader") {
                return null;
              }
              if (props.rowType === "groupFooter") {
                return null;
              }
              return (
                <td
                  {...props}
                  style={{
                    ...props.style, // Used for showing red color in Action Column when delete flag true
                  }}
                >
                  {
                    <div
                      style={{
                        display: "flex",
                        columnGap: "6px",
                        alignItems: "center",
                      }}
                    >
                      {actions &&
                        actions
                          .filter(
                            (d) =>
                              d.isVisible ? d.isVisible(props.dataItem) : true
                            // (d) =>
                            //   d.onlyVisible === undefined ||
                            //   (d.onlyVisible &&
                            //     props.dataItem[d.onlyVisible] > 0)
                          )
                          .map((item) =>
                            item?.render && enableCheckboxes ? (
                              item?.render(props, enableCheckboxes)
                            ) : item?.showAsButton ? (
                              <Btn
                                attrBtn={{
                                  color: "primary",
                                  onClick: () => {
                                    item?.action(props);
                                  },
                                  disabled: item?.disabled
                                    ? item?.disabled(props)
                                    : false,
                                }}
                              >
                                {item?.title}
                                <span
                                  className={`${
                                    ["delete", "Show Details"].includes(
                                      item?.title
                                    ) && props?.dataItem?.IS_DELETED === true
                                      ? null
                                      : item.icon?.iconName
                                  } font-primary`}
                                ></span>
                              </Btn>
                            ) : item?.isCheckbox ? (
                              <Checkbox
                                size="small"
                                checked={selectedCheckbox?.includes(
                                  checkboxKey
                                    ? props?.dataItem[checkboxKey]
                                    : JSON.stringify(removeExpandedKey(props))
                                )}
                                onChange={(e) => handleCheck(props, e)}
                                className={checkBoxClasses.root}
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <Tooltip
                                style={{ cursor: "pointer" }}
                                title={item.title}
                                placement="bottom"
                              >
                                {item?.visible == undefined ||
                                (item?.arrayVisible &&
                                  item?.arrayVisible.includes(
                                    props.dataItem[item?.visible]
                                  )) ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (!item.action) {
                                        switch (item.actionType) {
                                          case "workflow":
                                            setWorkflowInfo({
                                              workflowID:
                                                props.dataItem.WorkflowID,
                                              CurrentStatus:
                                                props.dataItem
                                                  .CurrentWorkflowStatus,
                                              employeeName:
                                                props.dataItem.EmployeeID,
                                              hrControlNo:
                                                props.dataItem.HrControlNo,
                                            });
                                            break;
                                          case "qrcode": {
                                            // debugger;
                                            handleModal(
                                              props.dataItem.id,
                                              props.dataItem.employeeName_FML
                                            );
                                            break;
                                          }
                                          default:
                                            break;
                                        }
                                      } else {
                                        item.action(props);
                                      }
                                    }}
                                    className={`${
                                      (["delete", "Show Details"].includes(
                                        item?.title
                                      ) &&
                                        props?.dataItem?.IS_DELETED) ||
                                      props?.dataItem?.IsDeleted === true
                                        ? null // used to hide Action columns Actions when isdeleted is true
                                        : item.icon?.iconName
                                    } 
                                      font-primary
                                      // ${
                                        props.dataItem?.donated_Status_Cde ==
                                        "A"
                                          ? "text-success"
                                          : "font-primary"
                                      }
                                        `}
                                    // className={item.className}
                                    // icon={

                                    // }
                                  ></span>
                                ) : (
                                  <span></span>
                                )}
                              </Tooltip>
                            )
                          )}
                    </div>
                  }
                </td>
              );
            }}
          />
        )}

        {selectedColumns && selectedColumns.length
          ? selectedColumns
              .filter((i) => i.show)
              .map((i, index) => {
                const isShowColumnMenu = i.isShowColumnMenu ?? true;

                return (
                  <GridColumn
                    key={`col${index + 1}`}
                    headerClassName={i.headerClassName}
                    className={i.className}
                    field={i.field}
                    filter={i.filter}
                    locked={i.locked || false}
                    title={i.title}
                    width={i.minWidth || i.width}
                    minResizableWidth={100}
                    format={i.format}
                    editor={i.editor}
                    filterable={i?.filterable ?? true}
                    filterCell={
                      i?.customFilterType
                        ? (filterProps) =>
                            CustomFilterCell({ ...filterProps, ...i })
                        : undefined
                    }
                    footerCell={(props) => {
                      const { editor, format, className, title, field } = i;
                      if (enableFooterCell) {
                        if (
                          noFooterCellFields.find(
                            (d) =>
                              d.toLowerCase() === field.toLowerCase() ||
                              d.toLowerCase() === title.toLowerCase()
                          )
                        ) {
                          return <td></td>;
                        }
                        const sum = data.reduce((acc, current) => {
                          const fieldValue = current[field];
                          return typeof fieldValue === "number"
                            ? acc + fieldValue
                            : acc;
                        }, 0);

                        return (
                          <td
                            colSpan={props.colSpan}
                            style={{ paddingInline: "20px" }}
                            className={className || ""}
                          >
                            {!actions && props?.ariaColumnIndex === 1 ? (
                              <H6>Total:{sum === 0 ? null : sum}</H6>
                            ) : sum === 0 ? null : (
                              <H6
                                attrH6={{ style: { display: "inline-flex" } }}
                              >
                                {editor === "numeric" && format !== null
                                  ? renderNumberFormat(" ", sum)
                                  : sum % 1 !== 0
                                  ? sum.toFixed(2)
                                  : sum}
                              </H6>
                            )}
                          </td>
                        );
                      } else {
                        return null;
                      }
                    }}
                    // filterCell={customTextFilterSearch}
                    // filterCell={
                    // i.field === "ID" ||
                    // i.field === "EmployeeID" ||
                    // i.field === "employeeId" ||
                    // i.field === "EmpId" ||
                    // i.field === "empId" ||
                    // i.field === "emplId" ||
                    // i.field === "SSN" || i.field === "ssn" ? customTextFilterSearch : undefined}

                    cell={(props) => {
                      const field = props.field || "";
                      const editor = props.editor || "";
                      const format = props.format || "";
                      const value = props.dataItem[field] || "";
                      if (props.rowType === "groupHeader") {
                        return null;
                      }
                      if (props.rowType === "groupFooter") {
                        const obj = aggregates.find(
                          (agg) => agg.field === props.field
                        );
                        if (!!Object.keys(obj || {}).length) {
                          return (
                            <td
                              key={i.title}
                              style={{
                                ...props.style,
                                whiteSpace: "nowrap",
                              }}
                              className={props.className}
                            >
                              Total {obj?.field}: {obj?.prefixIcon}{" "}
                              {props.dataItem.aggregates[obj?.field][
                                obj?.aggregate
                              ].toFixed(obj?.precision || 0)}
                            </td>
                          );
                        } else {
                          return <td></td>;
                        }
                      }
                      return (
                        <td key={i.title} className={props?.className || ""}>
                          {editor === "numeric" &&
                          format !== null &&
                          value !== null &&
                          value !== undefined
                            ? renderNumberFormat(" ", value, digitVars[field])
                            : editor === "date"
                            ? value
                              ? moment(value).format(format)
                              : "N/A"
                            : value}
                        </td>
                      );
                    }}
                    columnMenu={
                      isShowColumnMenu
                        ? (props) => (
                            <CustomMenu
                              locked={i.locked}
                              {...props}
                              onColumnLockToggle={(col, status) => {
                                updateLockColumn(col, status);
                              }}
                            />
                          )
                        : false
                    }
                  />
                );
              })
          : null}
      </Grid>
    </>
  );

  return (
    <>
      <ExcelExport data={props.data} ref={_export}>
        {selectedColumns && selectedColumns.length
          ? selectedColumns
              .filter((i) => i.show)
              .map((i) => (
                <ExcelExportColumn
                  key={i.field}
                  field={i.field}
                  title={i.title}
                />
              ))
          : null}
      </ExcelExport>
      <div>
        {/* {GridComponent} */}
        <PDFExport ref={pdfExportComponent}> {GridComponent} </PDFExport>
      </div>

      {openModal && (
        <div>
          <Modal
            open={openModal}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0",
                }}
              >
                <h6 className="mt-2">
                  {employeeInfo?.NAME ? employeeInfo.NAME + " 'S QR Code" : ""}
                </h6>
                <div>
                  <IconButton onClick={handleModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <QRCodeModal employeeInfo={employeeInfo} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TelerikLightGrid;
