export const SALARY_INCREMENT_UPDATE = "SALARY_INCREMENT_UPDATE";
export const SALARY_INCREMENT_UPDATE_SUCCESS = "SALARY_INCREMENT_UPDATE_SUCCESS";
export const SALARY_INCREMENT_UPDATE_FAIL = "SALARY_INCREMENT_UPDATE_FAIL";

export const GET_AUDIT_TRAIL = "GET_AUDIT_TRAIL";
export const GET_AUDIT_TRAIL_SUCCESS = "GET_AUDIT_TRAIL_SUCCESS";
export const GET_AUDIT_TRAIL_FAIL = "GET_AUDIT_TRAIL_FAIL";

export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTIONL_FAIL = "GET_TRANSACTIONL_FAIL";

export const SALARY_INCREMENT_GETINFO = "SALARY_INCREMENT_GETINFO";
export const SALARY_INCREMENT_GETINFO_SUCCESS = "SALARY_INCREMENT_GETINFO_SUCCESS";
export const SALARY_INCREMENT_GETINFO_FAIL = "SALARY_INCREMENT_GETINFO_FAIL";

export const RESET = "RESET";