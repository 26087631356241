import { Headphones, Home } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Data Visualization",
        icon: "gauge",
        type: "sub",
        id: 23,
        active: false,
        roles: ["Analyst"],
        children: [
          {
            title: "Base Chart",
            icon: "gears",
            type: "link",
            active: false,
            path: `dashboard/chart`,
            roles: ["Analyst"],
          },
          {
            title: "Sanky Chart",
            icon: "gauge",
            roles: ["Analyst"],
            type: "link",
            path: `dashboard/sanky`,
            active: false,
          },
          {
            title: "Rapid G-Track",
            icon: "gauge",
            roles: ["Analyst"],
            type: "link",
            path: `dashboard/rapid-g-track`,
            active: false,
          },
        ],
      },
   
      {
        title: "Administrationtestsdvsdf",
        icon: "gauge",
        type: "sub",
        id: 0,
        active: false,
        roles: ["SuperUser", "Developer", "HRAdmin", "supervisor", "user"],

        children: [
          {
            title: "GG1 Tracking",
            icon: "gears",
            type: "link",
            active: false,
            path: `gg1-tracking`,
            roles: ["HRAdmin", "SuperUser", "Developer", "user"],
          },
          {
            title: "Dashboards",
            icon: "gauge",
            roles: ["SuperUser", "Developer", "user"],
            type: "link",
            path: `dashboard/default`,
            active: false,
          },
          {
            title: "Employee Master",
            icon: "address-card",
            type: "link",
            active: false,
            path: `employee-master`,
            roles: ["HRAdmin", "SuperUser", "Developer", "supervisor"],
          },
          // {
          //   title: "Document Management",
          //   icon: "address-card",
          //   type: "link",
          //   active: false,
          //   path: `document-management`,
          //   roles: ["Developer"],
          // },
          {
            title: "Audit Master Log",
            icon: "address-card",
            type: "link",
            active: false,
            path: `audit-master-log`,
            roles: ["Developer"],
          },
        ],
      },
      {
        title: "performance-evaluation",
        icon: "gauge",
        type: "link",
        path: `performance-evaluation`,
        active: false,
        roles: ["reviewer", "appointingAuthority"],
      },
      {
        title: "Systems Admin dsfsdfsdf tetest",
        icon: "gauge",
        type: "sub",
        id: 1,
        roles: ["SuperUser", "Developer"],

        active: false,
        children: [
          {
            title: "eHRP Lookup Table",
            icon: "address-card",
            type: "sub",
            active: false,
            // path : `master-detail`,
            // roles: ["HRAdmin"],
            children: [
              {
                path: `position`,
                title: "Position",
                type: "link",
                active: false,
              },
              {
                path: `departmentdivision`,
                title: "Department Division",
                type: "link",
                active: false,
              },
              {
                path: `education`,
                title: "Education",
                type: "link",
                active: false,
              },
              {
                path: `ethicgroup`,
                title: `Ethnic Groups`,
                type: "link",
                active: false,
              },
              {
                path: `employee-type`,
                // path:`employeetypes`,
                title: "Employee Type",
                type: "link",
                active: false,
              },
              {
                path: `jobannouncement`,

                title: "Job Announcement",
                type: "link",
                active: false,
              },
              {
                path: `paygrade`,
                title: "Pay Grade",
                type: "link",
                active: false,
              },
              {
                path: `paystep`,
                title: "Pay Step",
                type: "link",
                active: false,
              },
              {
                path: `pmrc-nature`,
                title: "Pmrc Nature",
                type: "link",
                active: false,
              },
              {
                path: `deductionplans`,
                title: "Deduction Plans",
                type: "link",
              },
              {
                path: `vendors`,
                title: "Vendors",
                type: "link",
              },
              {
                path: `deductiontypes`,
                title: "Deduction Types",
                type: "link",
              },
              {
                path: `paytimecodes`,
                title: "Paytime Code",
                type: "link",
              },
              {
                path: `pmrc-salaries`,
                title: "Pmrc Salaries",
                type: "link",
                active: false,
              },
              {
                path: `certifications`,
                title: "Certificate Management",
                type: "link",
                active: false,
              },
              // {
              //   path: `tagmaster`,
              //   title: "Tag Management",
              //   type: "link",
              // },
            ],
          },
          {
            title: "Column Lookup",
            icon: "gauge",
            type: "link",
            roles: ["Developer"],
            path: "column-lookup",
            active: false,
          },
          {
            title: "Menu Management",
            icon: "gauge",
            type: "link",
            roles: ["Developer"],
            path: "sidebar",
            active: false,
          },
          {
            title: "Workflow",
            icon: "hospitals",
            type: "sub",
            active: false,
            path: `workflow`,
            roles: ["Developer"],

            // roles: ["HRAdmin"],
            children: [
              { path: `process`, title: "Process Management", type: "link" },
              {
                path: `workflow/configuration`,
                title: "Configuration",
                type: "link",
              },
              { path: `workflow`, title: "Workflow List", type: "link" },
            ],
          },
        ],
      },
      // {
      //   title: "Employee Benefits",
      //   icon: "gauge",
      //   type: "sub",
      //   id: 2,
      //   roles: ["SuperUser", "Developer"],

      //   active: false,
      //   children: [
      //     {
      //       title: "RFP Processing",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "Retiree Portal",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "Data Reporting",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "Death claims",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "Dept Benefit Support",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: " Emp Benefit Support",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "New Health Enrollment",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //     {
      //       title: "Life Ins Enrollmt",
      //       path: "inprogress",
      //       type: "link",
      //     },
      //   ],
      // },
      {
        title: "Class and Comp",
        icon: "gauge",
        type: "sub",
        id: 3,
        active: false,
        roles: ["SuperUser", "Developer"],

        children: [
          {
            title: "Position Class",
            path: "PositionClass",
            type: "link",
          },
          // {
          //   title: "Salary Registory",
          //   path: "inprogress",
          //   type: "link",
          // },
        ],
      },
      {
        title: "Drug-Free Workplace",
        icon: "gauge",
        type: "sub",
        roles: ["HRAdmin", "SuperUser", "Developer"],
        id: 4,
        active: false,
        children: [
          {
            path: `billing-invoice-log`,
            title: "Billing Invoice Log",
            type: "link",
          },
          {
            path: `archival-dt-log`,
            title: "Archival DT Log",
            type: "link",
          },
          {
            path: `dtpm-data-log`,
            title: "DTPM Data Log",
            type: "link",
          },
          {
            path: `test-designated-position-log`,
            title: "Test Designated Position Log",
            type: "link",
          },
          {
            path: `department-random-date-log`,
            title: "Department Random Date Log",
            type: "link",
          },
          {
            path: `tdp-employee-master-list-log`,
            title: "TDP Employee Master List Log",
            type: "link",
          },
          {
            path: `random-dt-log`,
            title: "Random DT Log",
            type: "link",
          },
          {
            path: `department-jv-control-number`,
            title: "Department's JV Control Number",
            type: "link",
          },
          {
            path: `dfwp-contact-list`,
            title: "DFWP Contact List",
            type: "link",
          },
          {
            path: `vendor-log`,
            title: "Vendor Log",
            type: "link",
          },
          {
            path: `doa-hr-jv-internal-log`,
            title: "DOA-HR JV Internal Log",
            type: "link",
          },
          {
            path: `mis-data-log`,
            title: "MIS data Log",
            type: "link",
          },
        ],
      },

      // {
      //   title: "Dashboard",
      //   icon: "gauge",
      //   type: "sub",
      //   active: false,
      //   children: [
      //     { path: `dashboard/default`, title: "Dashboard", type: "link" },
      //   ],
      // },

      // {
      //   title: "Transaction",
      //   icon: "address-card",
      //   type: "link",
      //   active: false,
      //   path: `transactionlist`,
      //   roles: ["HRAdmin"],
      // },
      // {
      // 	title: "Advance Search",
      // 	icon: "telescope",
      // 	type: "link",
      // 	active: false,
      // 	path: `advance-search`,
      //   },
      // {
      //   title: "Master Detail",
      //   icon: "address-card",
      //   type: "link",
      //   active: false,
      //   path: `master-detail`,
      // },
      //

      // {
      //     title: 'Employee', icon: 'address-card', type: 'sub', active: false, children: [
      //         { path: `dashboard/employee`, title: 'Employee', type: 'link' },
      //     ]
      // },
      {
        title: "Transaction Log",
        icon: "square-list",
        type: "link",
        active: false,
        path: `transaction-log`,
        roles: ["SuperUser", "Developer", "HRAdmin"],
      },
      {
        title: "Recruitment to On-Line Application",
        icon: "address-card",
        type: "sub",
        roles: ["SuperUser", "Developer", "HRAdmin", "user"],
        id: 6,
        active: false,
        children: [
          {
            title: "On-Line Application",
            icon: "telescope",
            type: "link",
            active: false,
            roles: ["SuperUser", "Developer", "HRAdmin", "user"],
            path: `online-recruitment`,
          },
        ],
      },
      // {
      //   title: "Test Dev & Valid",
      //   icon: "address-card",
      //   roles: ["SuperUser", "Developer"],
      //   type: "sub",
      //   id: 7,
      //   active: false,
      //   children: [
      //     {
      //       title: "Testing On-line",
      //       type: "link",
      //       path: "inprogress",
      //     },
      //   ],
      // },
      {
        title: "Training & Dev",
        icon: "address-card",
        type: "sub",
        roles: ["SuperUser", "Developer"],
        id: 8,
        active: false,
        children: [
          {
            title: "Training Registry",
            type: "link",
            path: "training-registry",
          },
        ],
      },
      { 
      title: "Reports",
      icon: "address-card",
      type: "sub",
      id: 12,
      roles: ["SuperUser", "Developer", "user"],
      active: false,
      children: [
        {
          title: "Reports",
          type: "link",
          path: "reports",
          roles: ["HRAdmin", "SuperUser", "Developer"],
        },
      ]},
      {
        title: "EMR",
        icon: "address-card",
        type: "sub",
        active: false,
        roles: ["HRAdmin", "SuperUser", "Developer", "supervisor", "user"],
        id: 9,
        children: [
          {
            path: `leaveapplication`,
            title: "Leave Application",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer", "user"],
          },
          {
            // path: `employee-leave-share`,
            path: "share",
            title: "Leave Share",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            // path: `employee-performance-evaluation`,
            path: `performance-evaluation`,
            title: "Performance Evaluation",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer", "supervisor", "user"],
          },
          {
            path: `performance-evaluation-appeal`,
            title: "Performance Appeal",
            roles: ["HRAdmin", "SuperUser", "Developer"],
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            // path: `outside-employment-record`,
            path: `outside-employment`,
            title: "Outside Employment",
            type: "link",
            //
            roles: ["HRAdmin", "SuperUser", "Developer", "user"],
          },
          {
            path: `training-log`,
            title: "Training Log",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            path: `adverse-action`,
            title: "Adv Act Lg",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            path: `grievance-request`,
            title: "Grievance Appeal",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            path: `special-medical-evaluation-log`,
            title: "Spec Medical Eval",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            path: `collective-bargaining-agreement-request`,
            title: "CBAR Log",
            type: "link",
            roles: ["SuperUser", "Developer"],
          },
          {
            path: `long-term-disability`,
            title: "LTD Log",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            path: `reduce-work-hour-record`,
            title: "Reduce Work Hour",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },

          {
            path: "inprogress",
            title: "Defect Report",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          // {
          //   path: "inprogress",
          //   title: "PEMRA Petition",
          //   type: "link",
          // },

          {
            path: "inprogress",
            title: "DPEM Log",
            type: "link",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
        ],
      },
     
      {
        title: "Record",
        icon: "address-card",
        type: "sub",
        id: 10,
        roles: ["SuperUser", "Developer", "user"],
        active: false,
        children: [
          {
            title: "Jackets",
            type: "link",
            path: "document-management",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            title: "Exit Survey",
            type: "link",
            path: "exit-survey-email",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
          {
            title: "NPA",
            type: "link",
            path: `npa-records`,
            roles: ["HRAdmin", "SuperUser", "Developer", "user"],
          },
          // {
          //   title: "Payroll Staging",
          //   type: "link",
          //   path: "payrollstaging",
          //   roles: ["Developer"]
          // },
          {
            title: "Retention Register",
            type: "link",
            path: "inprogress",
            roles: ["HRAdmin", "SuperUser", "Developer"],
          },
        ],
      },
      {
        title: "Employee-Deductions",
        icon: "address-card",
        type: "sub",
        roles: ["user"],
        id: 111,
        active: false,
        children: [
          {
            title: "Deductions",
            type: "link",
            path: "employee-deduction-plan",
            roles: ["user"],
            active: false,
          },
        ],
      },
      {
        title: "Generate Paystub",
        type: "link",
        path: "pay-stub",
        roles: ["user","devloper"]
      },
      
    ],
  },
];
