import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";
import { OJA } from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getJobAnnouncementsApi = ({ page, pageSize }) => get(baseURL + url.JOB_ANNOUNCEMENT + `/GetJobAnnouncements?pageNumber=${page}&pageSize=${pageSize}`);
export const getJobApplicationLeadsApi = () => get(baseURL + url.OJA + `/JobApplicationLeads`);
export const getEthnicitiesApi = () => get(baseURL + url.OJA + `/Ethnicities`);
export const getPersonRacesApi = () => get(baseURL + url.OJA + `/PersonsRaces`);
export const getJobTypesApi = () => get(baseURL + url.OJA + `/JobTypes`);
export const getPositionTypesApi = () => get(baseURL + url.OJA + `/PositionTypes`);
export const getAgeRangesApi = () => get(baseURL + url.OJA + `/AgeRanges`);
export const getCitizenshipApi = () => get(baseURL + url.Citizens);
export const geTerminationReasonsApi = () => get(baseURL + url.OJA + `/TerminationReasons`);
export const getQuestionByPositionsApi = ({ pos1, pos2 }) => get(baseURL + url.OJA + `/QuestionsByPos/${pos1}/${pos2}`);
export const getApplicationByEmpAndAnnApi = ({ empId, annId }) => get(baseURL + url.OJA + `/GetApplication/${empId}/${annId}`);
export const createJobApplicationApi = (payload) => post(baseURL + url.OJA, payload);
export const closeJobApplicationApi = (payload) => post(baseURL + '/JobAnnouncement' + '/CloseAnnouncement/' + payload?.announcementId, {});
export const getJobAnnouncementByIdApi = (id) => get(baseURL + url.JOB_ANNOUNCEMENT + '/' + id);
export const uploadFile = (payload) => post(baseURL + url.OJA + '/AddOrUpdateDocument', payload);
export const getFiles = ({ announcementId, employeeId }) => get(baseURL + url.OJA + `/GetDocumentAnnouncementAndEmployeeById?announcementId=${announcementId}&employeeId=${employeeId}`);
export const deleteFile = (id) => remove(baseURL + url.OJA + '/DeleteOjaDocument?documentId=' + id);