import { takeLatest, put, call } from "redux-saga/effects";
import { GET_AUDIT_LOGS, GET_AUDIT_LOG_DIFF, GET_AUDIT_LOG_EXPORTS, RESET, RESET_AUDIT_LOG_DIFF } from "./actionsTypes";

import {
    getAuditLogDiffFail,
    getAuditLogDiffSuccess,
    getAuditLogExportsFail,
    getAuditLogExportsSuccess,
    getAuditLogsFail,
    getAuditLogsSuccess,
    reset,
    resetAuditLogDiff,
} from "./action";

import {
    getAuditLogDiff,
    getAuditLogs
} from "../../_helper/services/AuditLogs";
import { gg1ReportDownload } from "../../_helper/services/advanceSearchv2";

function* onGetAuditLogs({ payload }) {
    try {
        const response = yield call(getAuditLogs, payload, payload?.location);
        yield put(getAuditLogsSuccess(response?.results));
    } catch (error) {
        yield put(getAuditLogsFail(error.response));
    }
}

function* onGetAuditLogExports({ payload }) {
    try {
        const response = yield call(gg1ReportDownload, payload);
        yield put(getAuditLogExportsSuccess(response));
    } catch (error) {
        yield put(getAuditLogExportsFail(error.response));
    }
}

function* onGetAuditLogDiff({ payload }) {
    try {
        const response = yield call(getAuditLogDiff, payload);
        yield put(getAuditLogDiffSuccess(response));
    } catch (error) {
        yield put(getAuditLogDiffFail(error.response));
    }
}
function* AuditLogSaga() {
    yield takeLatest(GET_AUDIT_LOGS, onGetAuditLogs);
    yield takeLatest(GET_AUDIT_LOG_EXPORTS, onGetAuditLogExports);
    yield takeLatest(GET_AUDIT_LOG_DIFF, onGetAuditLogDiff);

    yield takeLatest(RESET, reset);
    yield takeLatest(RESET_AUDIT_LOG_DIFF, resetAuditLogDiff);
}

export default AuditLogSaga;