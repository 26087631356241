import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import { useAuth } from 'oidc-react';
import NotMatch from '../Components/NotMatch';
import { getsidebarmenudata } from '../store/sidebarmenu/actions';
import { useDispatch, useSelector } from 'react-redux';
const LayoutRoutes = () => {

	const { sidebarmenu } = useSelector((state) => state.SideBarMenuReducer);
	const dispatch = useDispatch();
	const { userData } = useAuth();


	// useEffect(() => {
	// 	dispatch(getsidebarmenudata());
	// }, [dispatch]);



	const apiPaths = sidebarmenu
		? sidebarmenu.flatMap((item) => {
			console.log("item", item);
			const itemPaths = item.path ? [item.path] : [];
			const nestedItemPaths = item.items
				? item.items
					.flatMap((nestedItem) => [nestedItem.path, ...(nestedItem.children ? nestedItem.children.map((child) => child.path) : [])])
					.filter((path) => path !== undefined && path !== "")
				: [];
			return [...itemPaths, ...nestedItemPaths];
		})
		: [];

	console.log("apiPaths", apiPaths)





	return (
		<>
			<Routes>
				{routes.filter(d => {

					return (d.roles && userData?.profile?.role && d.roles.indexOf(userData?.profile?.role) > -1) || d.roles === undefined
				})
				//  .filter((d)=>apiPaths.includes(d.group))
				// .filter(d => Array.isArray(d.group) && d.group.some(group => apiPaths.includes(group)))
					// .filter((d) => apiPaths.some((apipath) => d.group.includes(apipath)))
					.map(({ path, layout, Component }, i) => {

						if (layout === 'none') {
							return <Route key={i}>
								<Route path={path} element={Component} />
							</Route>
						} else {
							// console.log(path,layout);
							return <Route element={<AppLayout />} key={i}>
								<Route path={path} element={Component} />
							</Route>;
						}

					})}
				<Route element={<AppLayout />} key="notmatch">
					<Route path={"*"} element={<NotMatch />} />
				</Route>
			</Routes>
		</>
	);
};

export default LayoutRoutes;