import { SALARY_INCREMENT_UPDATE,SALARY_INCREMENT_GETINFO,RESET ,
    GET_AUDIT_TRAIL,GET_TRANSACTION
} from "./actionTypes";
import { put, takeLatest, call } from "redux-saga/effects";

import {
    updatesalaryIncrementSuccess,
    updatesalaryIncrementFail,reset,
    getAuditTrailSuccess,
    getAuditTrailFail,
    getinfosalaryIncrementSuccess,
    getinfosalaryIncrementFail,
    getTransactionSuccess,getTransactionFail
} from "./action";

import { updatesalaryapiUrl,getSalaryInrement,getAuditTrail,getTransaction } from "../../_helper/services/salaryincrement";
import { ShowError, ShowSucess } from "../../_helper/helper";


function* onGetInfoSalaryIncrement({ payload: HrControl, employeeID }) {
    try {
      const response = yield call(getSalaryInrement,HrControl, employeeID);
      yield put(getinfosalaryIncrementSuccess(response));
      console.log("employee response",response)
    } catch (error) {
      yield put(getinfosalaryIncrementFail(error));
    }
  }
function* onGetAuditTrail({ payload:  employeeID }) {
    try {
      const response = yield call(getAuditTrail, employeeID);
      yield put(getAuditTrailSuccess(response));
      console.log("employee response",response)
    } catch (error) {
      yield put(getAuditTrailFail(error));
    }
  }
function* onTransaction({ payload:  employeeID }) {
    try {
      const response = yield call(getTransaction, employeeID);
      yield put(getTransactionSuccess(response));
      console.log("employee response",response)
    } catch (error) {
      yield put(getTransactionFail(error));
    }
  }

function* onSalaryIncrement( {payload} ) {
    
    try {
        const response = yield call(updatesalaryapiUrl, payload);
        yield put(updatesalaryIncrementSuccess(response));
        console.log("resonseData",response)
        // ShowSucess('Salary Increment Completed', {
        //     position: 'top-right'
        // });
    } catch (error) {
        yield put(updatesalaryIncrementFail(error.response));
        // console.log("errormessgae",error.response)
        ShowError(error?.response?.data?.detail??error?.message??"something went wrong",{
            position: 'top-right'
        });
    }finally {
        yield put(reset()); // Dispatch RESET action after handling createDepartment
      }
}
function* SalaryIncrementSaga() {

    yield takeLatest(SALARY_INCREMENT_UPDATE, onSalaryIncrement);
    yield takeLatest(SALARY_INCREMENT_GETINFO, onGetInfoSalaryIncrement);
    yield takeLatest(GET_AUDIT_TRAIL, onGetAuditTrail);
    yield takeLatest(GET_TRANSACTION, onTransaction);
    yield takeLatest(RESET,reset);


}

export default SalaryIncrementSaga;
