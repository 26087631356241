
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getDeductionPlanById = (id) => get(`${baseURL + url.EMPLOYEE_DEDUCTION_BASE}/GetById?Id=${id}`);
export const getDeductionDocuments = (payload) => post(`${baseURL}/AdvanceSearch/CommonSearch`, payload);
export const CreateOrUpdateDeduction = (payload) => post(baseURL + url.EMPLOYEE_DEDUCTION_BASE + '/CreateOrUpdate', payload);

export const getDeductionPlanMaster = () => get(`${baseURL + url.DEDUCTION_BASE}/GetAll`);
export const getDeductionMethodList = () => get(`${baseURL}/Enum/GetDeductionMethodList`);
export const getAllPaymentTypeAPI = () => get(`${baseURL}/PaymentType/GetAll`);
export const getDeductionTypeAPI = () => get(`${baseURL}/deductionTypes/GetAll`);
