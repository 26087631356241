import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  Autocomplete,
  FormControl,
  TextField,
  Typography
} from "@mui/material";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import {
  faClock,
  faUserCheck
} from "@fortawesome/pro-duotone-svg-icons";
import { Icon } from "@progress/kendo-react-common";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
import visileColumns from "./visibleColumns";
import TelerikDataTable from "../CommonComponents/TelerikDataTable";
import ErrorBoundary from "../ErrorBoundry";
import { filterAllowedColumn } from "../_helper/helper";
import AdvanceSearch from "../CommonComponents/AdvanceSearch";
import { isArray } from "lodash";
function BenefitsMaster() {
  let {
    columns,
    loadingSearch,
    searchParameter,
    ColumnsSorted,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const history = useNavigate();
  const { userData } = useAuth();
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  const [sortData, setSortData] = useState([{ field: "", dir: "" }]);
  const [pageSize, setPageSize] = useState(10);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [searchParams, setSearchParams] = useState([]);
  const [sortedColumns, setSortedColumns] = useState([]);
  const [DeparementCode,setDepartmentCode] = useState(userData?.profile?.DEPT_CODE ? userData.profile.DEPT_CODE[0] : '')
  const listName = "Benefits_MASTER_1";
    
  useEffect(() => {
	 
		//  let searchParam= []
		// 	if (DeparementCode) {
		// 		searchParam.push({
		// 			columnName: "DeptCode",
		// 			operator: "=",
		// 			value: DeparementCode,
		// 			dataType: "varchar(max)",
		// 			logicOperator: "AND",
		// 		});
		// 	}else{
		// 		searchParam = searchParams.filter((i) => i.columnName !== "DeptCode" )
		// 	}
    if(DeparementCode){
      setAdditionalWhere(`Department = '${DeparementCode}'`)
    }
      
			// setSearchParams(searchParam);
	 
	}, [DeparementCode])


  const actions = useMemo(() => {
    return [
      {
        title: "Show Details",
        action: (props) => {
          return history(
            `/enrollment-benefits/${props.dataItem.EmployeeID}/update`,
            { state: columns }
          );
        },
        icon: { iconName: 'k-icon  k-i-eye' },
        className: "font-primary",
        style: { cursor: "pointer" },
      }
    ];
  });
  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);

  useEffect(() => {
    if (ColumnsSorted[listName] !== undefined) {
      setSortedColumns(ColumnsSorted[listName]);
    } else {
      if (columns && visileColumns.length > 0 && columns.length > 0) {
        const clms = visileColumns.map((d) => d.field.toLowerCase());
        const data = columns
          .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
          .map((p) => {
            return {
              field: p.column_name,
              show: false,
              locked: false,
              width: 200,
              title: p.column_title,
              filter: "text",
            };
          });
        setSortedColumns(
          filterAllowedColumn(userData, visileColumns.concat(data)),
          "employeeID"
        );
      }
    }
  }, [columns]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  const getWhere = () => {
    // if (userData.profile.role === "user") {
    //   return `employeeID = ${userData?.profile?.EmployeeID}  ${additionalWhere ? `and ${additionalWhere}` : ``
    //     }`;
    // } else {
    return additionalWhere;
    // }
  };
  // useEffect(() => {
  //   if (searchParameter[listName]?.searchParams?.length > 0) {
  //     setSearchParams(JSON.parse(searchParameter[listName]?.searchParams));
  //   }
  // }, [searchParameter]);
console.log("asdasddsdsa", searchParams);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row className="bg-primary rounded py-3 mb-3">
          <Col md={6}>
            <Typography variant="h5">
              <Icon style={{ fontSize: '25px', margin: "10px" }} name="graph" />
              Benefits Master
            </Typography>
            </Col>
            {isArray(userData?.profile?.DEPT_CODE) ? 
            <Col md={6} className="d-flex justify-content-end">
            <FormControl className="payperiodRange" style={{ marginRight: "10px" }}>
				<span>
				  <Autocomplete
					size="small"
					name="dept_Code"
					id="payperiod_range"
					className="muirmexpand min-w-128 text-16 "
					style={{ margin: 0 }}
					disableClearable={true}
					value={DeparementCode}
					onChange={(event, value) => {
					  setDepartmentCode(value)
					  // dispatch(setDeptCode(value))
					}}
					options={userData?.profile?.DEPT_CODE?.length && userData?.profile?.DEPT_CODE || []}
					getOptionLabel={(option) => option || ""}
					renderInput={(params) => (
					  <TextField
						defaultValue={userData?.profile?.DEPT_CODE?.length && userData?.profile?.DEPT_CODE ? userData?.profile?.DEPT_CODE[0] : null}
						size='small'
						{...params}
						margin="dense"
						variant="outlined"
						style={{ minWidth: "100px", marginRight: "10px" }}
						inputProps={{
						  ...params.inputProps,
						  style: { color: "white" },
						}}
					  />
					)}
				  />
				</span>
			  </FormControl>
							</Col> : null }
          </Row>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={listName}
                    gridId={listName}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={userData.profile.role == "BenefitsDepartmentManagement" ? "sp_BenefitsDeptUserList" : "sp_BenefitsGovtUserList" }
                    searchParams={searchParams}
                    additionalWhere={getWhere()}
                    expanded={true}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                  />

                  <div>
                    {sortedColumns.length > 0 && (
                      <TelerikDataTable
                        id={listName}
                        pageSize={pageSize}
                        totalCount={rowCount}
                        skip={(page - 1) * pageSize}
                        take={pageSize}
                        data={results || []}
                        sortData={sortData}
                        onFilterChange={handleFilterChange}
                        onSortChange={handleSortChange}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        selectedColumns={sortedColumns}
                        setSortedColumns={setSortedColumns}
                        loading={loadingSearch}
                        actions={actions}
                        isDashboard={true}
                        setChooseFilterMethod={setChooseFilterMethod}
                        chooseFilterMethod={chooseFilterMethod}
                      ></TelerikDataTable>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );
}
export default BenefitsMaster;
