const Columns =  [
    {
        "column_name": "CreatedOn",
        "column_title": "created At",
        "status": 1,
        "data_type": "text"
    },
    {
        "column_name": "Id",
        "column_title": "id",
        "status": 1,
        "data_type": "numeric"
    },
]


export default Columns;