import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError,ShowSucess } from '../../_helper/helper';

import { GET_TRAININGS, GET_TRAININGS_DETAILS,  TRAINING_CREATE, TRAINING_DELETE,
   TRAINING_UPDATE,RESET} from "./actionTypes";

import {
  getTrainingsSuccess,
  getTrainingsFail,
  getTrainingsDetailsSuccess,
  getTrainingsDetailsFail,
  createTrainingSuccess,
  createTrainingFail,
  updateTrainingSuccess,
  updateTrainingFail,
  deleteTrainingSuccess,
  deleteTrainingFail,
} from "./actions";

import {  createTraining,deleteTraining,
	getTrainingDetails,getTrainings
	,updateTraining,
 } from "../../_helper/services/training";
function* onGetTrainings({payload: {page,pageSize}}) {
  try {
    const response = yield call(getTrainings,{page,pageSize});
    yield put(getTrainingsSuccess(response));
  } catch (error) {
    yield put(getTrainingsFail(error.response));
  }
}

function* onGetTrainingDetails({ payload: id }) {
  try {
    const response = yield call(getTrainingDetails, id);
    yield put(getTrainingsDetailsSuccess(response));
  } catch (error) {
    yield put(getTrainingsDetailsFail(error.response));
  }
}
function* onCreateTraining({ payload }) {
	try {
	  const response = yield call(createTraining, payload);
	  yield put(createTrainingSuccess(response));
    ShowSucess('Training Created successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(createTrainingFail(error.response));
    ShowError(error?.response?.data?.message);
    console.log(error)
    // const { status, statusText } = error.response;
    // const errorMessage = `${status} - ${statusText}`;
    //     toast.error(errorMessage, {
    //       position: 'top-right'
    //     });
	}
  }
  function* onUpdateTraining({ payload: { id},payload }) {
	try {
	  const response = yield call(updateTraining, id, payload);
	  yield put(updateTrainingSuccess(response));
    ShowSucess('Training updated successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(updateTrainingFail(error.response));
    console.log(error?.response?.data?.message);
    ShowError(error?.response?.data?.message);
    console.log(error?.response?.data?.message);
	}
  }
  function* onDeleteTraining({ payload: ID }) {
	try {
	  const response = yield call(deleteTraining, ID);
	  yield put(deleteTrainingSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
	} catch (error) {
	  yield put(deleteTrainingFail(error.response));
    ShowError(error?.response?.data?.message);
	}
  }

function* CartSaga() {
  yield takeLatest(GET_TRAININGS, onGetTrainings);
  yield takeLatest(GET_TRAININGS_DETAILS, onGetTrainingDetails);
  yield takeLatest(TRAINING_CREATE, onCreateTraining);
  yield takeLatest(TRAINING_UPDATE, onUpdateTraining);
  yield takeLatest(TRAINING_DELETE, onDeleteTraining);
}

export default CartSaga;