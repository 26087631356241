import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const DynamicTemplate = ({ templateString, pdf }) => {
  const [content, setContent] = useState("");
  useEffect(() => {
    if (templateString) setContent(templateString);
  }, []);
  return (
    <Paper style={{ border: "2px solid #ccc", padding: 10 }}>
      {!pdf && (
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2, mb: 2 }}>
          Template Preview
        </Typography>
      )}

      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{ __html: templateString }}
      />
    </Paper>
  );
};

export default DynamicTemplate;
