import React, { Fragment, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Box,
  Pagination,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonIcon from "@mui/icons-material/Person";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
 

import { formatDistanceToNow } from "date-fns";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
 

import { Icon } from "@progress/kendo-react-common";
 
 
import { useEffect } from "react";
 
import {
  AccessTime,
  Assessment,
  AssignmentLate,
  Circle,
  Event,
  Info,
  PhonelinkLockOutlined,
  PriorityHigh,
  ReportProblem,
  Schedule,
} from "@mui/icons-material";
import { PersonPinCircleOutlined } from "@material-ui/icons";
import ErrorBoundary from "../../ErrorBoundry";
import { advanceSearchDashboard } from "../../_helper/services/advanceSearchv2";
import { getUserId } from "../../_helper/helper";

const BenefitsDashboardMain = () => {
    const navigate = useNavigate();
    const { userData } = useAuth();
  
    const [data, setData] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [rowCount, setRowCount] = useState(null);
    const [priority, setPriority] = useState("all");
    const [transactionType, setTransactionType] = useState("Benefits");
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(50);
  
    const getNoticeData = async () => {
      setLoading(true);
      let additionalWhere = "";
  
      if (priority !== "all") {
        additionalWhere += `priority = '${priority}'`;
      }
  
      if (transactionType?.trim()) {
        additionalWhere += additionalWhere
          ? ` AND TransactionType = '${transactionType}' AND EmployeeID != '${getUserId(userData)}'`
          : `TransactionType = '${transactionType}' AND EmployeeID != '${getUserId(userData)}'`;
      }
      // if (transactionType?.trim()) {
      //   additionalWhere += additionalWhere
      //     ? ` AND TransactionType = '${transactionType}' AND (EmployeeID = '${getUserId(userData)}' or EmployeeID = 0 or EmployeeID is null)`
      //     : `TransactionType = '${transactionType}' AND (EmployeeID = '${getUserId(userData)}' or EmployeeID = 0 or EmployeeID is null)`;
      // }
  
      const payload = {
        tableName: "vw_AllTransaction_emp_portal",
        searchParams: "[]",
        columns: "*",
        pageNumber: currPage,
        pageSize: pageSize,
        sortData: " [CreatedDate] desc",
        additionalWhere: additionalWhere,
      };
  
      try {
        const response = await advanceSearchDashboard(payload, "All-Transaction");
        const { results, rowCount } = response;
        setData(results);
        setRowCount(rowCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      getNoticeData();
    }, [currPage, priority, transactionType]);
  
    // useEffect(() => {
    //   setData(defaultData);
    //   setRowCount(20);
    //   setLoading(false);
    //   setPageSize(5);
    // }, [defaultData]);
    const getBackgroundColor = (priority) => {
      switch (priority) {
        case "Urgent":
          return "red";
        case "Non-Urgent":
          return "whitesmoke";
        case "FYI":
          return "lightyellow";
        case "Notification":
          return "yellow";
        case "Past Due":
          return "orange";
        default:
          return "white";
      }
    };
  
    const pallets = {
      All: {
        bg: "#eceff1",
        text: "#607d8b",
      },
      Urgent: {
        bg: "#ffebee",
        text: "#f44336",
        icon: <PriorityHigh />,
      },
      "Non-Urgent": {
        bg: "#e3f2fd",
        text: "#2196f3",
        icon: <Event />,
      },
      FYI: {
        bg: "#e0f7fa",
        text: "#00bcd4",
        icon: <Info />,
      },
      Notification: {
        bg: "#e8f5e9",
        text: "#4caf50",
        icon: <NotificationsIcon />,
      },
      "Past Due": {
        bg: "#fff3e0",
        text: "#ff9800",
        icon: <AssignmentLate />,
      },
    };
  
    const getColor = (pt) => {
      return pallets[pt] || null;
    };
  
    const getTextColor = (priority) => {
      return priority === "Urgent" ? "black" : "black";
    };
  
    const getIcon = (transactionType) => {
      switch (transactionType) {
        case "Notification":
          return <NotificationsIcon />;
        case "Information":
          return <InfoIcon />;
        case "Personal Action":
          return <PersonIcon />;
        case "Leave Application":
          return <BeachAccessIcon />;
        default:
          return <EventNoteIcon />;
      }
    };
  
    const handlePageChange = (event, value) => {
      setCurrPage(value);
    };
  
    const handlePriorityChange = (event, newPriority) => {
      if (newPriority) {
        setPriority(newPriority);
        setCurrPage(1); // Reset to the first page whenever the priority changes
      }
    };
  
    const handleTransactionTypeChange = (event) => {
      setTransactionType(event.target.value);
      setCurrPage(1); // Reset to the first page whenever the transaction type changes
    };
  
    const formatDate = (date) => {
      const d = new Date(date);
      return d.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    };
  
    return (
      <ErrorBoundary>
        {" "}
        <Fragment>
          <Container fluid={true}>
            {/* <NotificationPage
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              notificationId={notificationId}
              hideButton={true}
            /> */}
            <Row className="bg-primary rounded py-3 mb-3">
              <Typography variant="h5">
                <Icon
                  style={{ fontSize: "25px", margin: "10px" }}
                  name="file-data"
                />
                Dashboard
              </Typography>
            </Row>
            <Row>
              <Col sm="12" className="page-content">
                <Card>
                  <CardBody className="p-3">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <ToggleButtonGroup
                        value={priority}
                        exclusive
                        onChange={handlePriorityChange}
                        aria-label="priority"
                        size="small"
                      >
                        <ToggleButton
                          value="all"
                          aria-label="all"
                          sx={{
                            // backgroundColor: "grey",
                            "&.Mui-selected": {
                              backgroundColor: getColor("All")?.bg,
                              color: getColor("All")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("All")?.text,
                            }}
                          >
                            {/* <Circle
                              sx={{ fontSize: "15px", marginRight: 1 }}
                            ></Circle> */}
                            All
                            {/* {priority === "all" && (
                              <CheckIcon
                                sx={{ marginLeft: 1, fontSize: "15px" }}
                              />
                            )} */}
                          </Box>
                        </ToggleButton>
                        <ToggleButton
                          value="Urgent"
                          aria-label="urgent"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: getColor("Urgent")?.bg,
                              color: getColor("Urgent")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("Urgent")?.text,
                            }}
                          >
                            <Circle
                              sx={{
                                fontSize: "15px",
                                margin: "5px",
                                color: "red",
                              }}
                            ></Circle>
                            Urgent
                            {/* {priority === "Urgent" && (
                              <CheckIcon sx={{ margin: "5px" }} />
                            )} */}
                          </Box>
                        </ToggleButton>
                        <ToggleButton
                          value="Non-Urgent"
                          aria-label="non-urgent"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: getColor("Non-Urgent")?.bg,
                              color: getColor("Non-Urgent")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("Non-Urgent")?.text,
                            }}
                          >
                            <Circle
                              sx={{
                                fontSize: "15px",
                                margin: "5px",
                              }}
                            ></Circle>
                            Non-Urgent
                            {/* {priority === "Non-Urgent" && (
                              <CheckIcon sx={{ margin: "5px" }} />
                            )} */}
                          </Box>
                        </ToggleButton>
                        <ToggleButton
                          value="FYI"
                          aria-label="fyi"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: getColor("FYI")?.bg,
                              color: getColor("FYI")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("FYI")?.text,
                            }}
                          >
                            <Circle
                              sx={{
                                fontSize: "15px",
                                margin: "5px",
                              }}
                            ></Circle>
                            FYI
                            {/* {priority === "FYI" && (
                              <CheckIcon sx={{ margin: "5px" }} />
                            )} */}
                          </Box>
                        </ToggleButton>
                        <ToggleButton
                          value="Notification"
                          aria-label="notification"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: getColor("Notification")?.bg,
                              color: getColor("Notification")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("Notification")?.text,
                            }}
                          >
                            <Circle
                              sx={{
                                fontSize: "15px",
                                margin: "5px",
                              }}
                            ></Circle>
                            Notification
                            {/* {priority === "Notification" && (
                              <CheckIcon sx={{ margin: "5px" }} />
                            )} */}
                          </Box>
                        </ToggleButton>
                        <ToggleButton
                          value="Past Due"
                          aria-label="past due"
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: getColor("Past Due")?.bg,
                              color: getColor("Past Due")?.text,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100px",
                              color: getColor("Past Due")?.text,
                            }}
                          >
                            <Circle
                              sx={{
                                fontSize: "15px",
                                margin: "5px",
                              }}
                            ></Circle>
                            Past Due
                            {/* {priority === "Past Due" && (
                              <CheckIcon sx={{ margin: "5px" }} />
                            )} */}
                          </Box>
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <FormControl
                          size="small"
                          sx={{ minWidth: 200, marginRight: 2 }}
                        >
                          <InputLabel shrink={true}> Transaction Type</InputLabel>
                          <Select
                            value={transactionType}
                            onChange={handleTransactionTypeChange}
                            label="Transaction Type"
                            notched={true}
                          >
                            <MenuItem value=" ">
                              <em>--select--</em>
                            </MenuItem>
                            <MenuItem value="Notification">Notification</MenuItem>
                            <MenuItem value="Personal Action">
                              Personal Action
                            </MenuItem>
                            <MenuItem value="Leave Application">
                              Leave Application
                            </MenuItem>
                          </Select>
                        </FormControl> */}
                        <Pagination
                          count={Math.ceil(rowCount / pageSize)}
                          page={currPage}
                          onChange={handlePageChange}
                          color="primary"
                        />
                      </Box>
                    </Box>
                    {loading ? (
                      <List sx={{ padding: 2 }}>
                        {[...Array(5)].map((_, index) => (
                          <ListItem key={index} sx={{ marginBottom: "10px" }}>
                            <ListItemAvatar>
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Skeleton variant="text" />}
                              secondary={<Skeleton variant="text" width="60%" />}
                            />
                          </ListItem>
                        ))}
                      </List>
                    ) : data.length === 0 ? (
                      <Typography variant="h6" align="center">
                        No Transactions
                      </Typography>
                    ) : (
                      <List sx={{ padding: 2 }}>
                        {data.map((item) => (
                          <ListItem
                            key={item.ID}
                            style={{
                            //   backgroundColor: getColor(item.Priority)?.bg,
                              color: getTextColor(item.Priority),
                              borderBottom: "1px solid gray",
                              marginBottom: "10px",
                              position: "relative",
                              borderRadius: "5px",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{
                                  background: getColor(item.Priority)?.bg,
                                  border: `1px solid ${
                                    getColor(item.Priority)?.text
                                  }`,
                                  color: getColor(item.Priority)?.text,
                                }}
                              >
                                {getColor(item.Priority)?.icon}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                            style={{cursor:"pointer"}}
                            onClick={() => {
                              navigate(`/enrollment-benefits/${item.EmployeeID}/update`);
                            }}
                              primary={
                                <>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: getTextColor(item.Priority) }}
                                  
                                  >
                                    {item.Action_Description}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{ color: getTextColor(item.Priority) }}
                                  >
                                    {`Create Date: ${formatDate(
                                      item.CreatedDate
                                    )}
                                    `
                                    }
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: getTextColor(item.Priority) }}
                                  >{`ID: ${item.HrControl}`}</Typography>
                                </>
                              }
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                textAlign: "right",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: getTextColor(item.Priority) }}
                              >{`TransactionType: ${item.TransactionType}`}</Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: getTextColor(item.Priority) }}
                              >{`Status: ${item.Status}`}</Typography>
                            </Box>
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 8,
                                right: 8,
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <EventNoteIcon
                                sx={{
                                  marginRight: 1,
                                  color: getTextColor(item.Priority),
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{ color: getTextColor(item.Priority) }}
                              >
                                {formatDistanceToNow(
                                  new Date(item.CreatedDate),
                                  { addSuffix: true }
                                )}
                              </Typography>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Pagination
                        count={Math.ceil(rowCount / pageSize)}
                        page={currPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Box>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
      </ErrorBoundary>
    );
  }
  export default BenefitsDashboardMain;


