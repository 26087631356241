import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ShowSucess, ShowError } from "../../../_helper/helper";
import { retry } from "redux-saga/effects";
const sliceName = "SavePerfomanceEvalution";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const SavePerfomanceEvalutionData = createAsyncThunk(
  `${sliceName}/Submit`,
  async (payload, dispatch) => {
    let performanceEval = {};
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      if (payload.payload !== undefined) {
        try {
          let submitTransactionData;
          if (payload.status === false) {
            submitTransactionData = await axios.put(
              `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations/${payload.id}`,
              payload.payload,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "jwt_access_token"
                  )}`,
                },
              }
            );
          } else {
            submitTransactionData = await axios.post(
              `${process.env.REACT_APP_API_DOTNET_URL}/PerformanceEvaluations`,
              payload.payload,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(
                    "jwt_access_token"
                  )}`,
                },
              }
            );
          }

          if (submitTransactionData.data) {
            if (
              submitTransactionData.status === 200 ||
              submitTransactionData.status === 201
            ) {
              performanceEval = submitTransactionData?.data;
              ShowSucess(payload.id ? "Updated Successfully!!" : "Submitted Successfully!!");
            }
            if (performanceEval !== undefined) {
              return {
                PerformanceEval: performanceEval,
              };
            }
          }
        } catch (error) {
          ShowError("something went wrong!!");
          if (error) {
            return { error: error.message };
          }
        }
      }
    } catch (e) {
      console.log("error", e);
      return {
        error: e.message,
      };
    }
  }
);

const SavePerfomanceEvalutionSlice = createSlice({
  name: sliceName,
  initialState: {
    PerformanceEval: {},
    isLoading: false,
    error: null,
    isDataSaved: false,
  },
  // reducers: {
  //   saveDataSuccess(state, action) {
  //     state.isDataSaved = true;
  //   },
  // },
  extraReducers: {
    [SavePerfomanceEvalutionData.pending]: (state, action) => {
      // console.log("action 1212121 ::::: ==> ", action, create);
      state.PerformanceEval = {};
      state.isLoading = true;
      state.isSuccess = false;
    },
    [SavePerfomanceEvalutionData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.PerformanceEval = action.payload?.PerformanceEval;
    },
    [SavePerfomanceEvalutionData.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
  },
});

export default SavePerfomanceEvalutionSlice.reducer;
