import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import H3 from "../Headings/H3Element";

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true} className="bg-primary rounded py-3 mb-1" style={{marginBottom:'5px'}}>
        <div className="page-header p-1">
          <Row>
            <Col sm="6">{props.mainTitle}</Col>
            <Col sm="6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item font-light">
                  <Link
                    to={`${process.env.PUBLIC_URL}/dashboard/default`}
                    className="text-light"
                  >
                    Home
                  </Link>
                </li>
                {props.parent && (
                  <li className="breadcrumb-item font-light">{props.parent}</li>
                )}
                {props.subParent ? (
                  <li className="breadcrumb-item font-light">
                    {props.subParent}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active font-light">
                  {props.title}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
