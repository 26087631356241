import { get, post, remove ,postReturnResponse, put} from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getDeleteDocument = (id) =>
  remove(baseURL + url.DELETE_DOCUMENT + "/" + id);

export const getDocumentRequiredFields = (id) =>
  get(`${baseURL + url.LkDocumentSource}/${id}`);
export const getDocumentSources = (id) =>
  get(`${baseURL + url.LkDocumentSource}`);
export const postDocumentsAPI = (payload) =>
  post(baseURL + url.postDocuments, payload);

export const verifyDocumentAPI = (payload) =>
  put(baseURL + url.verifyDocument, payload?.payload);
// export const onCombineDocumentsAPI=(payload) =>
// post(baseURL + url.combinedDocument, payload);
export const onCombineDocumentsAPI = (payload) => {
  const { fileExtension,headername, ...rest } = payload;
  return postReturnResponse(
    baseURL +
    url?.combinedDocument ,
    // +
    // `/${payload.isOJA ? 'OJAExport' : 'CommonSearchExport'}?fileExtension=pdf${headername?'&headername=header':""}`,
    payload,
    { responseType: "blob" }
  );
};

export const moveDocumentsToPRAPI = (payload) =>
    post(baseURL + url.moveDocumentToPR, payload);