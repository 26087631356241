import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const sliceName = "GGTrackSlice";
axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
export const fetchGGTrackData = createAsyncThunk(
  `${sliceName}/fetchGGTrackData`,
  async (actnNo) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV

      //get nature of transactions
      let NatureList = [];

      //get nature of transactions
      let PositionTitleList = [];
      let salaryItemsList = [];
      let dpdvItems = [];
      let processStatusItems = [];
      let evalTypeItems = [];
      let maritalStatusItems = [];
      let genderItems = [];
      let jobOrderItems = [];
      axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
      try {
        const LookUpItems = await axios.get(
          // `${process.env.REACT_APP_API_DOTNET_URL}/GG1/lookupitems?ActnNo=${actnNo}`,
          `${process.env.REACT_APP_API_DOTNET_URL}/GG1/lookupitems?HrControl=${actnNo}`,

          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${localStorage.getItem(
              //   "jwt_access_token"
              // )}`,
              Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            },
          }
        );

        if (LookUpItems) {
          console.log("lookup", LookUpItems.data);
          LookUpItems.data.dpdvItems.map((item) => {
            dpdvItems.push({
              // value: item.key,
              value: item?.deptNo?.startsWith("99") ? item?.deptno_lk : item?.key, //item.deptno_lk,
              label: item.value,
              // deptNo: item.deptNo,
              deptNo: item.deptno_lk,
              deptDiv: item.deptDiv,
            });
            // vestingList.push({'value':item.ownership_vesting_id,'label':item.ownership_vesting});
          });

          LookUpItems.data.natureItems.map((item) => {
            NatureList.push({
              value: item.key,
              label: item.value,
              authority: item.authority,
            });
          });
          LookUpItems.data.evalTypeItems.map((item) => {
            evalTypeItems.push({
              value: item.key,
              label: item.value,
            });
          });
          LookUpItems.data.salaryItems.map((item) => {
            salaryItemsList.push({
              grade: item.grade,
              step: item.step,
              nrate: item.nrate,
              nSalary: item.nSalary,
            });
          });
          LookUpItems.data.positionItems.map((item) => {
            PositionTitleList.push({
              value: item.key,
              label: item.value,
              name: item.value,
              range_next: item.range_next,
              titleWithGrade: item.titleWithGrade,
              tdp: item.tdp,
              isLawEnforcementPosition: item.isLawEnforcementPosition
            });
          });
          LookUpItems.data.processStatusItems.map((item) => {
            processStatusItems.push({
              value: item.key,
              label: item.value,
            });
          });
          LookUpItems.data.maritalStatus.map((item) => {
            maritalStatusItems.push({
              value: item.key,
              label: item.description,
              name: item.description,
            });
          });
          LookUpItems.data.genderItems.map((item) => {
            genderItems.push({
              value: item.key,
              label: item.sex,
              name: item.sex,
            });
          });
          LookUpItems.data.jobOrder.map((item) => {
            jobOrderItems.push({
              key: item.key,
              value: item.value,
            });
          });
        }
      } catch (error) {
        console.log("action error", error);
      }

      let ggTrackArray = {
        nature: NatureList,
        positionTitle: PositionTitleList,
        dpdvItems: dpdvItems,
        salaryItemsList: salaryItemsList,
        processStatusItems: processStatusItems,
        evalTypeItems: evalTypeItems,
        maritalStatusItems: maritalStatusItems,
        genderItems: genderItems,
        jobOrderItems: jobOrderItems,
      };
      return {
        GGTrackData: ggTrackArray,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);
export const fetchEthanicity = createAsyncThunk(
  `${sliceName}/fetchEthanicityData`,
  async (actnNo) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      //get DPDV

      //get nature of transactions

      let EthanicityItems = [];
      try {
        const fetchEthanicityItems = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "LK_Ethnicity",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: " ID desc",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${localStorage.getItem(
              //   "jwt_access_token"
              // )}`,
              Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            },
          }
        );

        if (fetchEthanicityItems) {
          console.log("lookup", fetchEthanicityItems.data);

          // LookUpItems.data.genderItems.map((item) => {
          //   genderItems.push({
          //     value: item.key,
          //     label: item.sex,
          //     name: item.sex,
          //   });
          // });
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        EthanicityItems: EthanicityItems,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);


export const getBBMRAnalyst = createAsyncThunk(
  `${sliceName}/getBBMRAnalyst`,
  async () => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      let getBBMRList = [];
      try {
        const fetchEthanicityItems = await axios.post(
          `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
          {
            tableName: "vw_bbmr_analyst_lookup",
            searchParams: "[]",
            columns: "*",
            pageNumber: 1,
            pageSize: 1000000,
            sortData: "",
            additionalWhere: "",
            qID: 0,
            location: "global",
          },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${localStorage.getItem(
              //   "jwt_access_token"
              // )}`,
              Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

            },
          }
        );

        if (fetchEthanicityItems) {
          getBBMRList = fetchEthanicityItems?.data?.results || []
          // LookUpItems.data.genderItems.map((item) => {
          //   genderItems.push({
          //     value: item.key,
          //     label: item.sex,
          //     name: item.sex,
          //   });
          // });
        }
      } catch (error) {
        console.log("error", error);
      }

      return {
        BBMRList: getBBMRList,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

// export const fetchPreviousTransaction = createAsyncThunk(
//   `${sliceName}/fetchPreviousTransaction`,
//   async (props) => {
//     console.log("ASync Prop 121212 : ", props)
//     try {
//       const fetchPreviousTransactionItems = await axios.post(
//         `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
//         {
//           tableName: "vw_GetAllDataByEmployee",
//           searchParams: "[]",
//           columns: "*",
//           pageNumber: props?.page,
//           pageSize: props?.pageSize,
//           // sortData: " CreateDate DESC",
//           // sortData: " ID desc",
//           additionalWhere: props?.NOA == "73" ? 
//             `(EmployeeID LIKE ${props?.id ? props?.id : ""} AND NatureTitle LIKE 'SALARY INCREMENT')` 
//             : props?.NOA == "80" ? `(EmployeeID LIKE '${props?.id ? props?.id : ""}' AND NatureTitle LIKE 'SALARY INCREMENT' AND EvalType LIKE 'O')`
//               : props?.NOA == "51" ? `(EmployeeID LIKE '${props?.id ? props?.id : ""}' AND (NatureTitle LIKE 'DETAIL APPOINTMENT' OR NatureTitle LIKE 'ACTING APPOINTMENT'))`
//                 : `EmployeeID LIKE ${props?.id ? props?.id : ""}`,
//           qID: 0,
//           location: "global",
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${localStorage.getItem(
//               "jwt_access_token"
//             )}`,
//           },
//         }
//       );
//       return {
//         PreviousTransactionItems: fetchPreviousTransactionItems?.data,
//       };
//     } catch (e) {
//       return {
//         error: e.message,
//       };
//     }
//   }
// );

export const fetchPreviousTransaction = createAsyncThunk(
  `${sliceName}/fetchPreviousTransaction`,
  async (props) => {
    axios.defaults.headers.common['role'] = sessionStorage?.getItem("currentRole");
    try {
      // NATURE_TITLE LIKE '%salary%' AND EmployeeID LIKE '%60611%'
      const fetchPreviousTransactionItems = await axios.post(
        `${process.env.REACT_APP_API_DOTNET_URL}/AdvanceSearch/global?pageNumber=1&pageSize=100000`,
        {
          tableName: props?.view || "vw_GetAllDataByEmployee",
          searchParams: "[]",
          columns: "*",
          pageNumber: props?.page,
          pageSize: props?.pageSize,
          additionalWhere: props?.additionalWhere,
          sortData: props?.sortData,
          qID: 0,
          location: "global",
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem(
            //   "jwt_access_token"
            // )}`,
            Authorization: `Bearer ${sessionStorage.getItem("jwt_access_token")}`,

          },
        }
      );
      return {
        PreviousTransactionItems: fetchPreviousTransactionItems?.data,
      };
    } catch (e) {
      return {
        error: e.message,
      };
    }
  }
);

const GGTrackSlice = createSlice({
  name: sliceName,
  initialState: {
    GGTrackData: {
      nature: [],
      positionTitle: [],
      dpdvItems: [],
      salaryItemsList: [],
      evalTypeItems: [],
      processStatusItems: [],
      maritalStatusItems: [],
      genderItems: [],
      jobOrderItems: [],
      error:null
    },
    // PreviousItem: {
    //   previousData: [],
    //   currentPage: 1,
    //   pageSize: 0,
    //   rowCount: 0
    // },
    PreviousItem: {
      previousData: [],
      previousDataItem: {},
      currentPage: 1,
      pageSize: 0,
      rowCount: 0,
      Loading: false,
    },
    BBMRList: [],
    isBBMRLoading: false
  },
  reducers: {
    setLetestData: (state, action) => {
      console.log("action Letest::::: ==> ", action);
      state.PreviousItem.previousDataItem = action?.payload;
    }
  },
  extraReducers: {
    [fetchGGTrackData.fulfilled]: (state, action) => {
      console.log("action ::::: ==> ", action);
      if(!action.payload?.GGTrackData?.dpdvItems?.length){
        state.GGTrackData.error = 'reloadLookup';
      }else if(action.payload?.error){
        state.GGTrackData.error = 'reloadLookup';
      }else{
        state.GGTrackData = action.payload.GGTrackData;
        state.GGTrackData.error = null;
      }
      
    },
    [fetchPreviousTransaction.pending]: (state, action) => {
      state.PreviousItem = {
        ...state.PreviousItem,
        Loading: true,
      };
    },
    [fetchPreviousTransaction.fulfilled]: (state, action) => {
      let item = action?.payload?.PreviousTransactionItems;
      state.PreviousItem = {
        previousData: item?.results,
        currentPage: item?.currentPage,
        pageSize: item?.pageSize,
        rowCount: item?.rowCount,
        Loading: false,
      };
    },

    // get BBMR List

    [getBBMRAnalyst.fulfilled]: (state, action) => {
      console.log("khfjhdsjhfjdhf".action?.payload?.BBMRList)
      state.BBMRList = action?.payload?.BBMRList || [];
      state.isBBMRLoading = false
    },
    [getBBMRAnalyst.pending]: (state, action) => {
      console.log("khfjhdsjhfjdhf".action?.payload?.BBMRList)

      state.BBMRList = [];
      state.isBBMRLoading = true
    },

    [getBBMRAnalyst.rejected]: (state, action) => {
      state.BBMRList = [];
      state.isBBMRLoading = false
    },

    // [fetchPreviousTransaction.fulfilled]: (state, action) => {
    //   console.log("action ::::: 123==> ", action);
    //   state.PreviousItem = {
    //     previousData: action?.payload?.PreviousTransactionItems?.results,
    //     currentPage: action?.payload?.PreviousTransactionItems?.currentPage,
    //     pagesize: action?.payload?.PreviousTransactionItems?.pageSize,
    //     rowCount: action?.payload?.PreviousTransactionItems?.rowCount,
    //     Loading: false,
    //   };
    // }
    // [fetchPreviousTransaction.fulfilled]: (state, action) => {
    //   let item = action?.payload?.PreviousTransactionItems;
    //   state.PreviousItem = {
    //     previousData: item?.results,
    //     currentPage: item?.currentPage,
    //     pageSize: item?.pageSize,
    //     rowCount: item?.rowCount,
    //     Loading: false,
    //   };
    // }
  },
});
export const { setLetestData } = GGTrackSlice.actions;
export default GGTrackSlice.reducer;
