import {
  ADD_UPDATE_EMPLOYEE_SIGNATURES,
  ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL,
  ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS,
  CLEAR_ADD_UPDATE_SIGNATURE,
  DELETE_SIGNATURE,
  DELETE_SIGNATURE_FAIL,
  DELETE_SIGNATURE_SUCCESS,
  GET_ALL_EMPLOYEE_SIGNATURES,
  GET_ALL_EMPLOYEE_SIGNATURES_FAIL,
  GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS,
  GET_SIGNATURE_DATA,
  GET_SIGNATURE_DATA_FAIL,
  GET_SIGNATURE_DATA_SUCCESS,
  UNASSIGN_SIGNATURE,
  UNASSIGN_SIGNATURE_SUCCESS,
  UNASSIGN_SIGNATURE_FAIL,
  GET_SIGNATURE_BY_CONTROL_NUMBER,
  GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS,
  GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL,
  GENERATE_SIGNATURE_DOCUMENT,
  GENERATE_SIGNATURE_DOCUMENT_SUCCESS,
  GENERATE_SIGNATURE_DOCUMENT_FAIL, RESET_DOCUMENT_DATA,
  UPDATE_SIGNATURE_STATUS,
  UPDATE_SIGNATURE_STATUS_SUCCESS,
  UPDATE_SIGNATURE_STATUS_FAIL,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE,
  EMPLOYEESERVICE_REQUEST,
  EMPLOYEESERVICE_REQUEST_SUCCESS,
  EMPLOYEESERVICE_REQUEST_FAIL,
} from "./actionTypes";

export const getAllEmployeeSignatures = () => {
  return {
    type: GET_ALL_EMPLOYEE_SIGNATURES
  };
};

export const getAllEmployeeSignaturesSuccess = (result) => {
  return {
    type: GET_ALL_EMPLOYEE_SIGNATURES_SUCCESS,
    payload: result,
  };
};

export const getAllEmployeeSignaturesFail = (error) => {
  return {
    type: GET_ALL_EMPLOYEE_SIGNATURES_FAIL,
    payload: error,
  };
};

export const addUpdateSignature = (payload) => {
  return {
    type: ADD_UPDATE_EMPLOYEE_SIGNATURES,
    payload: payload,
  };
}

export const clearAddUpdateSignatureResponse = () => {
  return {
    type: CLEAR_ADD_UPDATE_SIGNATURE
  };
}

export const addUpdateSignatureSuccess = (result) => {
  return {
    type: ADD_UPDATE_EMPLOYEE_SIGNATURES_SUCCESS,
    payload: result,
  };
};

export const addUpdateSignatureFail = (error) => {
  return {
    type: ADD_UPDATE_EMPLOYEE_SIGNATURES_FAIL,
    payload: error,
  };
};

export const deleteSignature = (id) => {
  return {
    type: DELETE_SIGNATURE,
    payload: id,
  };
}

export const deleteSignatureSuccess = (result) => {
  return {
    type: DELETE_SIGNATURE_SUCCESS,
    payload: result,
  };
};

export const deleteSignatureFail = (error) => {
  return {
    type: DELETE_SIGNATURE_FAIL,
    payload: error,
  };
};

export const getSignatureData = (sourceName, refControlNumber) => {
  return {
    type: GET_SIGNATURE_DATA,
    payload: { sourceName, refControlNumber }
  };
};

export const getSignatureDataSuccess = (result) => {
  return {
    type: GET_SIGNATURE_DATA_SUCCESS,
    payload: result,
  };
};

export const getSignatureDataFail = (error) => {
  return {
    type: GET_SIGNATURE_DATA_FAIL,
    payload: error,
  };
};

export const unAssignSignature = (payload) => {
  return {
    type: UNASSIGN_SIGNATURE,
    payload: payload
  };
};

export const unAssignSignatureSuccess = (result) => {
  return {
    type: UNASSIGN_SIGNATURE_SUCCESS,
    payload: result,
  };
};

export const unAssignSignatureFail = (error) => {
  return {
    type: UNASSIGN_SIGNATURE_FAIL,
    payload: error,
  };
};

export const getEmployeeSignatureByControlNumber = (controlNumber) => {
  return {
    type: GET_SIGNATURE_BY_CONTROL_NUMBER,
    payload: controlNumber
  };
};

export const getEmployeeSignatureByControlNumberSuccess = (result) => {
  return {
    type: GET_SIGNATURE_BY_CONTROL_NUMBER_SUCCESS,
    payload: result,
  };
};

export const getEmployeeSignatureByControlNumberFail = (error) => {
  return {
    type: GET_SIGNATURE_BY_CONTROL_NUMBER_FAIL,
    payload: error,
  };
};

export const generateDocumentForSignature = (payload) => {
  return {
    type: GENERATE_SIGNATURE_DOCUMENT,
    payload: payload
  };
};

export const generateDocumentForSignatureSuccess = (result) => {
  return {
    type: GENERATE_SIGNATURE_DOCUMENT_SUCCESS,
    payload: result,
  };
};

export const generateDocumentForSignatureFail = (error) => {
  return {
    type: GENERATE_SIGNATURE_DOCUMENT_FAIL,
    payload: error,
  };
};

export const resetDocumentSignatureData = () => {
  return {
    type: RESET_DOCUMENT_DATA
  };
};

export const updateSignatureStatus = (payload) => {
  return {
    type: UPDATE_SIGNATURE_STATUS,
    payload: payload
  };
};

export const updateSignatureStatusSuccess = (result) => {
  return {
    type: UPDATE_SIGNATURE_STATUS_SUCCESS,
    payload: result,
  };
};

export const updateSignatureStatusFail = (error) => {
  return {
    type: UPDATE_SIGNATURE_STATUS_FAIL,
    payload: error,
  };
};

export const approveRejectEmployeeSignature = (payload) => {
  return {
    type: APPROVE_REJECT_EMPLOYEE_SIGNATURE,
    payload: payload
  };
};

export const approveRejectEmployeeSignatureSuccess = (result) => {
  return {
    type: APPROVE_REJECT_EMPLOYEE_SIGNATURE_SUCCESS,
    payload: result,
  };
};

export const approveRejectEmployeeSignatureFail = (error) => {
  return {
    type: APPROVE_REJECT_EMPLOYEE_SIGNATURE_FAIL,
    payload: error,
  };
};

export const employeeservicerequest = (payload) => {
  return {
    type: EMPLOYEESERVICE_REQUEST,
    payload: payload
  };
};

export const employeeservicerequestSuccess = (result) => {
  return {
    type: EMPLOYEESERVICE_REQUEST_SUCCESS,
    payload: result,
  };
};

export const employeeservicerequestFail = (error) => {
  return {
    type: EMPLOYEESERVICE_REQUEST_FAIL,
    payload: error,
  };
};