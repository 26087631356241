import React, { useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";
import { getUserRole } from "../../../_helper/helper";

const LoadingDashboard = () => {
  const { userData } = useAuth();
  const role = getUserRole(userData);
  const navigate = useNavigate();
  const [toggleState,setToggleState] = useState(false)
  const BBMRRoles = ["BBMR-ADMIN","BBMR-ANALYST","BBMR-SUPERVISOR","BBMR-DIRECTOR"]
  const benefitsRoles = ["BenefitsDepartmentManagement","BenefitsGovernmentWideManagement"]

  //   useEffect(() => {

  //     setTimeout(()=>{

  //       signOutRedirect();
  //     },3000)

  //   }, []);
  useEffect(() => {
    console.log("11111 ::: ",localStorage.getItem("redirect-to"), role)
    if(localStorage.getItem("redirect-to")){
      console.log(localStorage.getItem("redirect-to"),'redirect-to')
      const redirectTo = localStorage.getItem("redirect-to");
      localStorage.removeItem("redirect-to");
      setToggleState(!toggleState)
      navigate(redirectTo);
    } else{
      console.log("11111 ::: ", role)

      // if(Array.isArray(role) && role.find((item)=>BBMRRoles.includes(item))){
      if(Array.isArray(role) && role?.length && BBMRRoles.includes(role[0])){
        navigate("bbmr-gg1-tracking");
      }
      else{
        // if(role == "BBMR-ANALYST" || role == "BBMR-DIRECTOR" || role == "BBMR-ADMIN" || role == "BBMR-SUPERVISOR"){
          if(BBMRRoles.includes(role)){
          // navigate("gg1-tracking");
          navigate("bbmr-gg1-tracking");
        }else if (benefitsRoles.includes(role)){
          navigate("dashboard/benefit");
        }else{
          navigate("dashboard/default");
        }
      }
    }
  }, [toggleState]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Loading</h1>
    </div>
  );
};

export default LoadingDashboard;
