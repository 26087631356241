import React from "react";
import { makeStyles } from "@material-ui/core";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";

const useStyles = makeStyles({
    root: {
        "& fieldset": {
            borderColor: "#0000003b !important",
            color: "#0000003b !important",
            "&:focus": {
                borderColor: "#1976d2 !important", // Add focus styles for input focus
            },
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '2px solid #1976d2 !important'
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            color: '2px solid #1976d2 !important'
        }
    }
});

const DatePicker = ({ name, value, className, errorMessage, dateFormat = "MM/DD/YYYY", placeholder,size, ...props }) => {
    const classes = useStyles();

    return <FormControl
        fullWidth
        size="small"
        margin="dense"
        error={!!errorMessage}
        variant="outlined"
    >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                key={name}
                fullWidth
                inputFormat={dateFormat}
                value={dayjs(value || '').format(dateFormat)}
                renderInput={(params) => {
                    return <TextField
                        fullWidth
                        variant="outlined"
                        size={size || "small"}
                        {...params}
                        classes={!errorMessage ? classes : ''}
                        inputProps={{
                            ...params.inputProps,
                            placeholder,
                            value: value ? dayjs(value || '').format(dateFormat) : ''
                        }}
                        error={!!errorMessage}
                        className={`${className}`}
                    />
                }}
                {...props}
            />
        </LocalizationProvider>
        <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
};

export const DatePickerWithController = ({ name, control, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
                return <DatePicker onChange={onChange} onBlur={onBlur} value={value} name={name} {...props} />
            }}
        />
    );
};

export default DatePicker;
