import {get,post,remove,put} from "../api_helper";
import * as url from "../url_helper"

const baseURL = process.env.REACT_APP_API_DOTNET_URL;

export const getUserDivision =(payload)=>get(baseURL+url.USERDIVISION_BASE1,payload)
export const getUserDivisionDetails=(id)=> get(`${baseURL+url.USERDIVISION_BASE}/${id}`);
export const updateUserDivision = (payload) => put(`${baseURL}${url.USERDIVISION_UPDATE}`, payload,);
export const createUserDivision =(payload) => post(baseURL+url.USERDIVISION_CREATE,payload)
export const deleteUserDivision =({employeeId, dpdv}) => remove (`${baseURL}${url.USERDIVISION_BASE}/${employeeId}/${dpdv}`)
// export const deleteUserDivision = ({employeeId, dpdv}) => {
//     const url = `${baseURL+url.USERDIVISION_BASE}/${employeeId}/${dpdv}`;
//     return remove(url);
//   };
  