const visibleColumns = [
  // {
  //   title: "Department Name",
  //   field: "DepartmentName",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Employee Name",
    field: "NAME2",
    locked: false,
    filter: "text",
    width: 190,
    show: true,
  },
  {
    title: "Effective Date",
    field: "Action_Effective_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  // {
  //   title: "Type of Appointment",
  //   field: "Definition",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  // {
  //   title: "Social Security Number",
  //   field: "SSN",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Date of Birth",
    field: "NDATE_OF_BIRTH",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: 190,
    show: true,
  },
  // {
  //   title: "Mailing Address",
  //   field: "Mailing Add",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  // {
  //   title: "Home Phone",
  //   field: "HM_PHONE",
  //   locked: false,
  //   filter: "text",
  //   width: "auto",
  //   show: true,
  // },
  {
    title: "Work Phone",
    field: "ML_WK_PHONE",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "EIN",
    field: "EIN",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Address",
    field: "Address",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Position Title",
    field: "TITLE",
    locked: false,
    filter: "text",
    width: "auto",
    show: true,
  },
  {
    title: "Annual Salary",
    field: "ANNUAL_SALARY",
    locked: false,
    filter: "numeric",
    width: "auto",
    show: true,
  },
];

export default visibleColumns;
