import { takeLatest, put, call } from "redux-saga/effects";
import 'react-toastify/dist/ReactToastify.css';
import {
  ADD_UPDATE_EMPLOYEE_SIGNATURES,
  APPROVE_REJECT_EMPLOYEE_SIGNATURE,
  CLEAR_ADD_UPDATE_SIGNATURE,
  DELETE_SIGNATURE,
  GENERATE_SIGNATURE_DOCUMENT,
  GET_ALL_EMPLOYEE_SIGNATURES,
  GET_SIGNATURE_BY_CONTROL_NUMBER,
  GET_SIGNATURE_DATA,
  RESET_DOCUMENT_DATA,
  UNASSIGN_SIGNATURE, UPDATE_SIGNATURE_STATUS,
  EMPLOYEESERVICE_REQUEST_SUCCESS,
  EMPLOYEESERVICE_REQUEST_FAIL,
  EMPLOYEESERVICE_REQUEST,
} from "./actionTypes";

import {
  getAllEmployeeSignaturesSuccess,
  getAllEmployeeSignaturesFail,
  addUpdateSignatureSuccess,
  addUpdateSignatureFail,
  clearAddUpdateSignatureResponse,
  deleteSignatureSuccess,
  deleteSignatureFail,
  getSignatureDataSuccess,
  getSignatureDataFail,
  unAssignSignatureSuccess,
  unAssignSignatureFail,
  getEmployeeSignatureByControlNumberSuccess,
  getEmployeeSignatureByControlNumberFail,
  generateDocumentForSignatureSuccess,
  generateDocumentForSignatureFail,
  resetDocumentSignatureData, updateSignatureStatusSuccess, updateSignatureStatusFail,
  approveRejectEmployeeSignatureSuccess, approveRejectEmployeeSignatureFail, employeeservicerequestSuccess, employeeservicerequestFail
} from "./actions";
import {
  addUpdateSignatureAPI,
  approveRejectEmployeeSignatureAPI,
  deleteSignatureAPI,
  employeeservicerequest,
  generateSignatureDocumentAPI,
  getAllEmployeeSignaturesAPI,
  getEmployeeSignatureByControlNumberAPI,
  getSignatureDataAPI,
  unassignedSignatureAPI,
  updateSignatureStatusAPI
} from "../../_helper/services/employeeSignatures";
import { ShowError, ShowSucess } from "../../_helper/helper";
function* getAllEmployeeSignatures() {
  try {
    const response = yield call(getAllEmployeeSignaturesAPI);
    yield put(getAllEmployeeSignaturesSuccess(response));
  } catch (error) {
    yield put(getAllEmployeeSignaturesFail(error.response));
  }
}

function* addUpdateSignature({ payload }) {
  try {
    const response = yield call(addUpdateSignatureAPI, payload);
    yield put(addUpdateSignatureSuccess(response));
    if (response?.status === 200) {
      ShowSucess('Signature saved successfully!', {
        position: 'top-right'
      });
    } else if (response?.status === 401) {
      ShowError('Signature already exist with same name!')
    } else {
      ShowError(response.message, {
        position: 'top-right'
      })
    }
  } catch (error) {
    yield put(addUpdateSignatureFail(error.response));
  }
}

function* deleteSignature({ payload }) {
  try {
    const response = yield call(deleteSignatureAPI, payload);
    yield put(deleteSignatureSuccess(response));
    if (response.status === 200) {
      ShowSucess('Signature deleted successfully!', {
        position: 'top-right'
      });
    } else {
      ShowError(response.message, {
        position: 'top-right'
      })
    }
  } catch (error) {
    yield put(deleteSignatureFail(error.response));
    ShowError('Error while delete signature!', {
      position: 'top-right'
    });
  }
}

function* getSignatureData({ payload }) {
  try {
    const response = yield call(getSignatureDataAPI, payload);
    yield put(getSignatureDataSuccess(response));
  } catch (error) {
    yield put(getSignatureDataFail(error.response));
  }
}

function* unAssignSignature({ payload }) {
  try {
    const response = yield call(unassignedSignatureAPI, payload);
    yield put(unAssignSignatureSuccess(response));
    if (response.status === 200) {
      ShowSucess('Signature unassigned successfully!', {
        position: 'top-right'
      });
    }
  } catch (error) {
    yield put(unAssignSignatureFail(error.response));
    ShowError('Error while delete signature!', {
      position: 'top-right'
    });
  }
}

function* getEmployeeSignatureByControlNumberSaga({ payload }) {
  try {
    const response = yield call(getEmployeeSignatureByControlNumberAPI, payload);
    yield put(getEmployeeSignatureByControlNumberSuccess(response));
  } catch (error) {
    yield put(getEmployeeSignatureByControlNumberFail(error.response));
  }
}
function* generateSignatureDocument({ payload }) {
  try {
    const response = yield call(generateSignatureDocumentAPI, payload);
    yield put(generateDocumentForSignatureSuccess(response));
    if (response.status === 200) {
      ShowSucess('Document saved successfully!', {
        position: 'top-right'
      });
    } else {
      ShowError(response.message, {
        position: 'top-right'
      })
    }
  } catch (error) {
    yield put(generateDocumentForSignatureFail(error.response));
    ShowError('Error while saving document!', {
      position: 'top-right'
    });
  }
}

function* updateSignatureStatus({ payload }) {
  try {
    const response = yield call(updateSignatureStatusAPI, payload);
    yield put(updateSignatureStatusSuccess(response));
    if (response.status === 200) {
      ShowSucess("Signature status updated successfully!", {
        position: 'top-right'
      })
    } else {
      ShowError(response.message, {
        position: 'top-right'
      })
    }
  } catch (error) {
    yield put(updateSignatureStatusFail(error.response));
  }
}
function* ongetemployeerequest({ payload }) {
  try {
    const response = yield call(employeeservicerequest, payload);
    yield put(employeeservicerequestSuccess(response));
    if (response.status === 200) {
      ShowSucess("Signature status updated successfully!", {
        position: 'top-right'
      })
    } else {
      ShowError(response.message, {
        position: 'top-right'
      })
    }
  } catch (error) {
    yield put(employeeservicerequestFail(error.response));
  }
}
function* approveRejectEmployeeSignatures({ payload }) {
  try {
    const response = yield call(approveRejectEmployeeSignatureAPI, payload);
    yield put(approveRejectEmployeeSignatureSuccess({
      ...response,
      data: { dt: new Date() }
    }));
    if (response.status === 200) {
      ShowSucess("Signature updated successfully!", { position: 'top-right' })
    } else {
      ShowError(response.message, { position: 'top-right' })
    }
  } catch (error) {
    yield put(approveRejectEmployeeSignatureFail(error.response));
  }
}

function* EmployeeSignatureSaga() {
  yield takeLatest(GET_ALL_EMPLOYEE_SIGNATURES, getAllEmployeeSignatures);
  yield takeLatest(ADD_UPDATE_EMPLOYEE_SIGNATURES, addUpdateSignature);
  yield takeLatest(CLEAR_ADD_UPDATE_SIGNATURE, clearAddUpdateSignatureResponse);
  yield takeLatest(DELETE_SIGNATURE, deleteSignature);
  yield takeLatest(GET_SIGNATURE_DATA, getSignatureData);
  yield takeLatest(UNASSIGN_SIGNATURE, unAssignSignature);
  yield takeLatest(GET_SIGNATURE_BY_CONTROL_NUMBER, getEmployeeSignatureByControlNumberSaga);
  yield takeLatest(GENERATE_SIGNATURE_DOCUMENT, generateSignatureDocument);
  yield takeLatest(RESET_DOCUMENT_DATA, resetDocumentSignatureData);
  yield takeLatest(UPDATE_SIGNATURE_STATUS, updateSignatureStatus);
  yield takeLatest(APPROVE_REJECT_EMPLOYEE_SIGNATURE, approveRejectEmployeeSignatures);
  yield takeLatest(EMPLOYEESERVICE_REQUEST,ongetemployeerequest)
}

export default EmployeeSignatureSaga;
