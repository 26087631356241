import {
	GET_POSITIONCLASS, GET_POSITIONCLASS_SUCCESS, GET_POSITIONCLASS_FAIL,
	GET_POSITIONCLASS_DETAILS, GET_POSITIONCLASS_DETAILS_SUCCESS, GET_POSITIONCLASS_DETAILS_FAIL,
	POSITIONCLAS_CREATE,POSITIONCLAS_CREATE_FAIL,POSITIONCLAS_CREATE_SUCCESS,
	POSITIONCLAS_DELETE,POSITIONCLAS_DELETE_FAIL,POSITIONCLAS_DELETE_SUCCESS,
	POSITIONCLAS_UPDATE,POSITIONCLAS_UPDATE_FAIL,POSITIONCLAS_UPDATE_SUCCESS,RESET, GET_POSITIONCLASS_SETTING_FAIL, GET_POSITIONCLASS_SETTING_SUCCESS, SAVE_POSITIONCLASS_SETTING_FAIL, SAVE_POSITIONCLASS_SETTING_SUCCESS, SAVE_POSITIONCLASS_SETTING, GET_POSITIONCLASS_SETTING, POSITIONCLAS_COPY, POSITIONCLAS_COPY_SUCCESS, POSITIONCLAS_COPY_FAIL, POSITIONCLAS_STATUS_CREATE, POSITIONCLAS_STATUS_CREATE_SUCCESS, POSITIONCLAS_STATUS_CREATE_FAIL, POSITIONCLAS_STATUS_UPDATE, POSITIONCLAS_STATUS_UPDATE_SUCCESS, POSITIONCLAS_STATUS_UPDATE_FAIL, POSITIONCLAS_STATUS_DELETE, POSITIONCLAS_STATUS_DELETE_SUCCESS, POSITIONCLAS_STATUS_DELETE_FAIL
  } from "./actionTypes";

  export const getPositionClass = (payload) => {
	return {
	  type: GET_POSITIONCLASS,
	  payload: payload
	};
  };
  
  export const getPositionClassSuccess = (posts) => {
	return {
	  type: GET_POSITIONCLASS_SUCCESS,
	  payload: posts,
	};
  };
  
  export const getPositionClassFail = (error) => {
	return {
	  type: GET_POSITIONCLASS_FAIL,
	  payload: error,
	};
  };
  
  export const getPositionClassDetails = (id) => {
	return {
	  type: GET_POSITIONCLASS_DETAILS,
	  payload: id,
	};
  };
  
  export const getPositionClassDetailsSuccess = (post) => {
	return {
	  type: GET_POSITIONCLASS_DETAILS_SUCCESS,
	  payload: post,
	};
  };
  
  export const getPositionClassDetailsFail = (error) => {
	return {
	  type: GET_POSITIONCLASS_DETAILS_FAIL,
	  payload: error,
	};
  };

  export const createPositionClas =(payload)=>{
	return {
		type: POSITIONCLAS_CREATE,
		payload: payload
	}
  }
  export const createPositionClasSuccess =(response)=>{
	return {
		type: POSITIONCLAS_CREATE_SUCCESS,
		payload: response
	}
  }
  export const createPositionClasFail =(error)=>{
	return {
		type: POSITIONCLAS_CREATE_FAIL,
		payload: error
	}
  }


  export const updatePositionClas =(payload)=>{
	
	return {
		type: POSITIONCLAS_UPDATE,
		payload: payload
	}
  }
  export const updatePositionClasSuccess =(response)=>{
	return {
		type: POSITIONCLAS_UPDATE_SUCCESS,
		payload: response
	}
  }
  export const updatePositionClasFail =(error)=>{
	return {
		type: POSITIONCLAS_UPDATE_FAIL,
		payload: error
	}
  }


  export const deletePositionClas =(payload)=>{
	return {
		type: POSITIONCLAS_DELETE,
		payload: payload
	}
  }
  export const deletePositionClasSuccess =(response)=>{
	return {
		type: POSITIONCLAS_DELETE_SUCCESS,
		payload: response
	}
  }
  export const deletePositionClasFail =(error)=>{
	return {
		type: POSITIONCLAS_DELETE_FAIL,
		payload: error
	}
  }
  export const reset = () => {
	return {
	  type: RESET,
	  
	};
  };

