//Yes or No
export const BOOLEAN_FIELDS = [
  "TDPNotice",
  "DFWPRelease",
  "noShowStatus",
  "DataTransfer",
  "TestCompleted",
  "MembershipReceived",
  "Majority",
  "GovernerApproved",
  "ElectionRequired",
  "GroundRulesEstablished",
  "Taxable",
  "IsEmployeeModifiable",
  "Applys_To_OT",
  "isApproved",
  "signStatus",
  "AllowedOnlyAssociatedEmployee",
];

//Active, Inactive or N/A
export const STATUS_FIELDS = [
  "TDPPosition",
  "DTResultAppealed",
  "TDPPositionStatus",
  "ProcessEligibility",
  "empStatus",
  "DepartmentStatus",
  "IsActive",
];

//Show As Button
export const BUTTON_TYPE_FIELDS = ["Operation", "operation"];

//Show As Button
export const CUSTOME_BUTTON_STATUS_FIELDS = [
  "DeductionPlanStatus",
  "Deduction_plan_Status",
  "ActiveStatus",
];
