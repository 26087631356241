import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADVANCE_SEARCH,
  ADVANCE_SEARCH_EMPL_TYPE,
  ADVANCE_SEARCH_STATUS,
  LOAD_COLUMNS,
  FINDASYOUTYPE,
  SAVE_ADVANCE_SEARCH,
  GET_ADVANCE_SEARCH_BYID,
  GET_ALL_ADVANCE_SEARCH,
  DELETE_SAVED_QUERY,
  GG1_EXCEL_REPORT,
  ADVANCE_SEARCH_CITIZEN,
  ADVANCE_SEARCH_EDUCATION,
  ADVANCE_SEARCH_POSITION,
  ADVANCE_SEARCH_ETHINIC,
  ADVANCE_SEARCH_MILITARY,
  ADVANCE_SEARCH_DEPARTMENT,
  SALARY_INCREMENT,
  PAYGRADE_CALCULATE,
  ADVANCE_SEARCH_RETIREMENT,
  ADVANCE_SEARCH_APPROPRIATION,
  ADVANCE_SEARCH_CALCULATE_SVC,
  ADVANCE_SEARCH_TOTAL_SVC,
} from "./actionTypes";

import {
  advanceSearchSuccess,
  advanceSearchEmplTypeSuccess,
  advanceSearchStatusSuccess,
  advanceSearchStatusFail,
  advanceSearchRetirementSuccess,
  advanceSearchRetirementFail,
  advanceSearchAppropriationSuccess,
  advanceSearchAppropriationFail,
  advanceSearchEmplTypeFail,
  advanceSearchPositionSuccess,
  advanceSearchPositionFail,
  advanceSearchFail,
  loadColumnsSuccess,
  loadColumnsFail,
  findAsyouTypeSuccess,
  findAsYouTypeFail,
  saveAdvanceSearchSuccess,
  saveAdvanceSearchFail,
  getAdvanceSearchByIdSuccess,
  getAdvanceSearchByIdFail,
  getAllAdvanceSearchSuccess,
  getAllAdvanceSearchFail,
  deleteSearchSaveQuerySuccess,
  deleteSearchSaveQueryFail,
  gg1ExcelReportSuccess,
  gg1ExcelReportFail,
  advanceSearchCitizenSuccess,
  advanceSearchCitizenFail,
  advanceSearchEducationSuccess,
  advanceSearchCalculateSVCSuccess,
  advanceSearchCalculateSVCFail,
  advanceSearchEducationFail,
  advanceSearchEthinicSuccess,
  advanceSearchEthinicFail,
  advanceSearchMilitarySuccess,
  advanceSearchMilitaryFail,
  advanceSearchDepartmentSuccess,
  advanceSearchDepartmentFail,
  salaryincrementSuccess,
  salaryincrementFail,
  paygradeCalcSuccess,
  paygradeCalcFail,
  advanceSearchTotalSVCSuccess,
  advanceSearchTotalSVCFail,
} from "./actions";

import {
  advanceSearch,
  loadColumns,
  findAsyouType,
  saveAdvanceSearch,
  deleteSavedQuery,
  getAdvanceSearchById,
  getAllAdvanceSearchDAta,
  gg1ReportDownload,
} from "../../_helper/services/advanceSearchv2";

function* onAdvanceSearch({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchSuccess(response));
  } catch (error) {
    yield put(advanceSearchFail(error.response));
  }
}
function* onSalaryIncrementSearch({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(salaryincrementSuccess(response));
  } catch (error) {
    yield put(salaryincrementFail(error.response));
  }
}
function* onPaygradeSearch({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(paygradeCalcSuccess(response));
  } catch (error) {
    yield put(paygradeCalcFail(error.response));
  }
}
function* onAdvanceSearchEthinic({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchEthinicSuccess(response));
  } catch (error) {
    yield put(advanceSearchEthinicFail(error.response));
  }
}
function* onAdvanceSearchDepartment({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchDepartmentSuccess(response));
  } catch (error) {
    yield put(advanceSearchDepartmentFail(error.response));
  }
}
function* onAdvanceSearchRetirement({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchRetirementSuccess(response));
  } catch (error) {
    yield put(advanceSearchRetirementFail(error.response));
  }
}
function* onAdvanceSearchAppropriation({ payload }) {
  try {
    const params = payload;
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchAppropriationSuccess(response));
  } catch (error) {
    yield put(advanceSearchAppropriationFail(error.response));
  }
}
function* onAdvanceSearchCitizen({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchCitizenSuccess(response));
  } catch (error) {
    yield put(advanceSearchCitizenFail(error.response));
  }
}
function* onAdvanceSearchEducation({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchEducationSuccess(response));
  } catch (error) {
    yield put(advanceSearchEducationFail(error.response));
  }
}
function* onAdvanceSearchCalculateSVC({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchCalculateSVCSuccess(response));
  } catch (error) {
    yield put(advanceSearchCalculateSVCFail(error.response));
  }
}
function* onAdvanceSearchTotalSVC({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchTotalSVCSuccess(response));
  } catch (error) {
    yield put(advanceSearchTotalSVCFail(error.response));
  }
}
function* onAdvanceSearchEmpType({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchEmplTypeSuccess(response));
  } catch (error) {
    yield put(advanceSearchEmplTypeFail(error.response));
  }
}
function* onAdvanceSearchStatus({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchStatusSuccess(response));
  } catch (error) {
    yield put(advanceSearchStatusFail(error.response));
  }
}
function* onAdvanceSearchMilitary({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchMilitarySuccess(response));
  } catch (error) {
    yield put(advanceSearchMilitaryFail(error.response));
  }
}
function* onAdvanceSearchPosition({ payload, currentID }) {
  try {
    const params = payload[currentID];
    const response = yield call(advanceSearch, params || payload);
    yield put(advanceSearchPositionSuccess(response));
  } catch (error) {
    yield put(advanceSearchPositionFail(error.response));
  }
}
function* onGG1ExcelReport({ payload }) {
  try {
    // debugger;
    const response = yield call(gg1ReportDownload, payload);
    yield put(gg1ExcelReportSuccess(response));
  } catch (error) {
    yield put(gg1ExcelReportFail(error.response));
  }
}
function* onSaveAdvanceSearch({ payload, currentID }) {
  try {
    const response = yield call(saveAdvanceSearch, payload);
    yield put(saveAdvanceSearchSuccess(response, currentID));
  } catch (error) {
    yield put(saveAdvanceSearchFail(error.response, currentID));
  }
}
function* onDeleteSavedQuery({ payload }) {
  try {
    const response = yield call(deleteSavedQuery, payload);
    yield put(deleteSearchSaveQuerySuccess(response));
  } catch (error) {
    yield put(deleteSearchSaveQueryFail(error.response));
  }
}

function* onGetAdvanceSearchById({ payload, params }) {
  try {
    const response = yield call(getAdvanceSearchById, payload, params);
    yield put(getAdvanceSearchByIdSuccess(response));
  } catch (error) {
    yield put(getAdvanceSearchByIdFail(error.response));
  }
}

function* onGetAllAdvanceSearchData({ payload }) {
  try {
    console.log("parameters CALLLING FIRSt");
    const response = yield call(getAllAdvanceSearchDAta, payload);
    console.log("parameters CALLLING SEC");

    yield put(getAllAdvanceSearchSuccess(response));
  } catch (error) {
    console.log("parameters CALLLING FIRSt");

    yield put(getAllAdvanceSearchFail(error.response));
  }
}

function* onLoadColumns({ payload: { tableName } }) {
  try {
    const response = yield call(loadColumns, tableName);
    yield put(loadColumnsSuccess(response));
  } catch (error) {
    yield put(loadColumnsFail(error.response));
  }
}

function* onFindAsYouType({
  payload: { table, keyField, valueField, search },
}) {
  try {
    const response = yield call(
      findAsyouType,
      table,
      keyField,
      valueField,
      search
    );
    yield put(findAsyouTypeSuccess(response));
  } catch (error) {
    yield put(findAsYouTypeFail(error.response));
  }
}
function* CartSaga() {
  yield takeLatest(ADVANCE_SEARCH, onAdvanceSearch);
  yield takeLatest(SALARY_INCREMENT, onSalaryIncrementSearch);
  yield takeLatest(PAYGRADE_CALCULATE, onPaygradeSearch);
  yield takeLatest(ADVANCE_SEARCH_POSITION, onAdvanceSearchPosition);
  yield takeLatest(ADVANCE_SEARCH_MILITARY, onAdvanceSearchMilitary);
  yield takeLatest(ADVANCE_SEARCH_EMPL_TYPE, onAdvanceSearchEmpType);
  yield takeLatest(ADVANCE_SEARCH_STATUS, onAdvanceSearchStatus);
  yield takeLatest(ADVANCE_SEARCH_CITIZEN, onAdvanceSearchCitizen);
  yield takeLatest(ADVANCE_SEARCH_ETHINIC, onAdvanceSearchEthinic);
  yield takeLatest(ADVANCE_SEARCH_EDUCATION, onAdvanceSearchEducation);
  yield takeLatest(ADVANCE_SEARCH_CALCULATE_SVC, onAdvanceSearchCalculateSVC);
  yield takeLatest(ADVANCE_SEARCH_TOTAL_SVC, onAdvanceSearchTotalSVC);
  yield takeLatest(ADVANCE_SEARCH_DEPARTMENT, onAdvanceSearchDepartment);
  yield takeLatest(ADVANCE_SEARCH_RETIREMENT, onAdvanceSearchRetirement);
  yield takeLatest(ADVANCE_SEARCH_APPROPRIATION, onAdvanceSearchAppropriation);
  yield takeLatest(LOAD_COLUMNS, onLoadColumns);
  yield takeLatest(FINDASYOUTYPE, onFindAsYouType);
  yield takeLatest(SAVE_ADVANCE_SEARCH, onSaveAdvanceSearch);
  yield takeLatest(GET_ADVANCE_SEARCH_BYID, onGetAdvanceSearchById);
  yield takeLatest(GET_ALL_ADVANCE_SEARCH, onGetAllAdvanceSearchData);
  yield takeLatest(DELETE_SAVED_QUERY, onDeleteSavedQuery);
  yield takeLatest(GG1_EXCEL_REPORT, onGG1ExcelReport);
}

export default CartSaga;
