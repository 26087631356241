const visibleColumns = [
  {
    title: "Job Order",
    field: "Default_Job_Order",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Dept Div",
    field: "DEPTDIV",
    locked: false,
    filter: "text",
    width: "140",
    show: true,
  },
  {
    title: "Employee ID",
    field: "EMPLOYEE_ID",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  {
    title: "Name",
    field: "NAME",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Status",
    field: "Status",
    locked: false,
    filter: "text",
    width: "220",
    show: true,
  },
  {
    title: "Employee Begin Date",
    field: "Employee_Begin_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Job Title",
    field: "Job_Classification_Title",
    locked: false,
    filter: "text",
    width: "160",
    show: true,
  },
  {
    title: "Exempt",
    field: "Exempt",
    locked: false,
    filter: "text",
    width: "160",
    show: true,
  },
  {
    title: "Annual Salary",
    field: "Annual_Salary",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Acting Begin Date",
    field: "Acting_Begin_date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Acting End Date",
    field: "Acting_End_date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Detailacting Hourly Rate",
    field: "Detailacting_Hourly_Rate",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Years of Service",
    field: "Years_of_Service",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Retirement Type",
    field: "Retirement_Type",
    locked: false,
    filter: "text",
    width: "150",
    show: true,
  },
  {
    title: "Prior Govt Service Years",
    field: "Prior_Govt_Service_Years",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Annual Leave Balance",
    field: "Annual_Leave_Balance",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Al Hours To Accrue",
    field: "Al_Hours_To_Accrue",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Ppe Al Hours",
    field: "Ppe_Al_Hours",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Sick Leave Balance",
    field: "Sick_Leave_Balance",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Sl Hours To Accrue",
    field: "Sl_Hours_To_Accrue",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "Ppe Sl Hours",
    field: "Ppe_Sl_Hours",
    locked: false,
    filter: "numeric",
    width: "200",
    show: true,
  },
  {
    title: "RATE",
    field: "RATE",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "GROSS",
    field: "GROSS",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Total Deduction",
    field: "Ppe_Total_Deductions",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Health Govt Contr",
    field: "HEALTH_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "currency",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "230",
    show: true,
  },
  {
    title: "Dental Govt Contr",
    field: "DENTAL_GOVT_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Govt Life Contr",
    field: "GOVT_LIFE_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "DC Govt Contr",
    field: "DC_GOVT_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "HP Govt Contr",
    field: "HP_GOVT_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Govt Non-Base Contr",
    field: "GOVT_NON_BASE_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "GOC DC & UNF",
    field: "GOC_DC_AND_UNF",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Medicare Govt Contr",
    field: "MEDICARE_GOVT_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Health Emp Contr",
    field: "HEALTH_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Dent Emp Contr",
    field: "DENT_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Life Emp Contr",
    field: "LIFE_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "HP Emp Contr",
    field: "HP_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "DC Emp Contr",
    field: "DC_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "DB Emp Contr",
    field: "DB_GOVT_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Medicare Emp Contr",
    field: "MEDICARE_EMP_CONTR",
    className: "text-end",
    headerClassName: "number-align",
    format: "c2",
    locked: false,
    filter: "numeric",
    editor: "numeric",
   width: "200",
    show: true,
  },
  {
    title: "Check Date",
    field: "Check_Date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
  {
    title: "Check Seq",
    field: "Check_Sequence",
    locked: false,
    filter: "text",
    width: "160",
    show: true,
  },
  {
    title: "PPE Ending Date",
    field: "Pay_Period_Ending_date",
    locked: false,
    filter: "date",
    format: "MM/DD/yyyy",
    editor: "date",
    width: 180,
    show: true,
  },
];

export default visibleColumns;
