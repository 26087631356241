import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationEditor from "./NotificationEditor";
import CloseIcon from "@material-ui/icons/Close";

const SendNotificationModal = ({
  isModalOpen,
  setIsModalOpen,
  // notificationId,
  // hideButton,
  // addParams,
  // additinalStringToReplace,
  // type,
  notificationDetail,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("EMAIL");

  useEffect(() => {
    if (isModalOpen !== undefined) setOpen(isModalOpen);
  }, [isModalOpen]);

  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
    // setNotificationContent({});
  };
  const handleDeliveryOptionChange = (event) => {
    setSelectedDeliveryOption(event.target.value);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            width: 1200,
            height: "600px",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            style={{
              marginBottom: "16px",
            }}
          >
            Send Notification
          </Typography>
          <Button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Button>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <Typography variant="subtitle1">
                Choose Delivery Option:
              </Typography>
              <RadioGroup
                value={selectedDeliveryOption}
                onChange={handleDeliveryOptionChange}
                row
              >
                <FormControlLabel
                  value="EMAIL"
                  control={<Radio />}
                  label="Email"
                />
                <FormControlLabel
                  value="DASHBOARD"
                  control={<Radio />}
                  label="Dashboard Notification"
                />
                <FormControlLabel
                  value="Whatsapp_Notification"
                  control={<Radio />}
                  label="Whatsapp Notification"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <NotificationEditor 
          selectedDeliveryOption={selectedDeliveryOption}
          notificationDetail = {notificationDetail}
          // selectedDeliveryOption={selectedDeliveryOption}
          />
          {/* <Button>Send Notification</Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default SendNotificationModal;
