import { takeLatest, put, call } from "redux-saga/effects";
import {
  getDeleteDocument,
  getDocumentRequiredFields,
  getDocumentSources,
  moveDocumentsToPRAPI,
  postDocumentsAPI,
  verifyDocumentAPI,
} from "../../_helper/services/documentupload";
import {
  DELETE_DOCUMENT,
  GET_DOCUMENT_FIELDS,
  GET_DOCUMENT_SOURCES,
  MOVE_DOCUMENTS_TO_PR,
  POST_ALL_DOCUMENTS,
  SET_SOURCE_IN_POST_DOCUMENTS,
  VERIFY_DOCUMENT,
} from "./actionTypes";

import {
  getDocumentFieldsSuccess,
  getDocumentSourcesSuccess,
  postDocumentsSuccess,
  deleteDocumentSuccess,
  deleteDocumentFail,
  combinedDocumentSuccess,
  combinedDocumentFail,
  getDocumentFieldsFail,
  getDocumentSourcesFail,
  postDocumentsFail,
  moveDocumentsToPRSuccess,
  moveDocumentsToPRFail,
  setReferenceId,
  verifyDocumentSuccess,
  verifyDocumentFail
} from "./actions";
import { ShowError, ShowSucess } from "../../_helper/helper";

function* onGetDocumentFields({ payload: { sourceID } }) {
  try {
    const response = yield call(getDocumentRequiredFields, sourceID);
    yield put(getDocumentFieldsSuccess(response));
  } catch (error) {
    yield put(getDocumentFieldsFail(error.response));
  }
}

function* onGetDocumentSources() {
  try {
    const response = yield call(getDocumentSources);
    yield put(getDocumentSourcesSuccess(response));
  } catch (error) {
    yield put(getDocumentSourcesFail(error.response));
  }
}

function* onPostDocuments({ payload: { isExisting = false, ...otherPayload } }) {
  try {
    const response = yield call(postDocumentsAPI, otherPayload);
    yield put(postDocumentsSuccess(response));
    ShowSucess("Document uploaded successfully", { position: "top-right" });
  } catch (error) {
    yield put(postDocumentsFail(error.response));
    ShowError("Something went wrong", { position: "top-right" });
  }
}

function* onVerifyDocument(payload) {
  try {
    const response = yield call(verifyDocumentAPI, payload);
    yield put(verifyDocumentSuccess(response));
    ShowSucess("Document Verify successfully", { position: "top-right" });
  } catch (error) {
    yield put(verifyDocumentFail(error.response));
    ShowError("Something went wrong", { position: "top-right" });
  }
}


function* onDeleteDocument({ payload: ID }) {
  try {
    const response = yield call(getDeleteDocument, ID);
    yield put(deleteDocumentSuccess(response));
    if (response?.status === 200) {
      ShowSucess("Document Deleted successfully", { position: "top-right" });
    } else {
      ShowError("Something went wrong", { position: "top-right" });
    }
  } catch (error) {
    yield put(deleteDocumentFail(error.response));
  }
}

function* moveDocumentsToPRSaga({ payload }) {
  try {
    const response = yield call(moveDocumentsToPRAPI, payload);
    yield put(moveDocumentsToPRSuccess(response));
    if (response?.status === 200) {
      ShowSucess("Document moved to PR successfully", { position: "top-right" });
    } else {
      ShowError("Something went wrong", { position: "top-right" });
    }
  } catch (error) {
    yield put(moveDocumentsToPRFail(error.response));
  }
}

function* DocumentUploadSaga() {
  yield takeLatest(GET_DOCUMENT_FIELDS, onGetDocumentFields);
  yield takeLatest(GET_DOCUMENT_SOURCES, onGetDocumentSources);
  yield takeLatest(POST_ALL_DOCUMENTS, onPostDocuments);
  yield takeLatest(VERIFY_DOCUMENT, onVerifyDocument);
  yield takeLatest(DELETE_DOCUMENT, onDeleteDocument);
  yield takeLatest(MOVE_DOCUMENTS_TO_PR, moveDocumentsToPRSaga);
}

export default DocumentUploadSaga;
