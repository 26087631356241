import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldow from "highcharts/modules/drilldown";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Chip,
  Box,
  OutlinedInput,
} from "@mui/material";
import { Col, Row, Spinner } from "reactstrap";
import { BBMRGG1Analyst, BBMRGG1AssignAnalyst, getChartDataAgeGroup } from "../../../store/chart/actions";
import SelectMultipleCheckBoxCharts from "../SelectMultipleCheckBoxCharts";
import exportingModule from "highcharts/modules/exporting";
exportingModule(Highcharts);
drilldow(Highcharts);

const AnalystReview = () => {
  let dispatch = useDispatch();
  let { chartDataAgeGroup, loadingChartsAgeGroup, agegroup_error, bbmrGG1, bbmrGG1Loading, bbmrGG1Assign } =
    useSelector((state) => state.ChartReducer);
  console.log("Dasdsaasdadasdasda", bbmrGG1);
  const [chartType, setChartType] = useState("pie");
  const [showSelectMultiple, setShowSelectMultiple] = useState(false);
  const [formData, setFormData] = useState("Overall");
  const [selected, setSelected] = useState([]);

  const [dpdvItems, setDpdvItems] = useState([]);
  const [seriesYData, setSeriesYData] = useState([]);
  const [drilledData, setDrilledData] = useState([]);
  const [radio, setRadio] = useState("Pie");

  const [chartDataItems, setChartdataItems] = useState([]);
  const [barChartData, setBarChartData] = useState({})
  const [analystUserBar, setAnalystUserBar] = useState({})


  let defaultOptions = {

    chart: {
      type: 'pie'
    },
    title: {
      text: 'BBMR Work Load',
      align: 'left'
    },
    showInLegend: true,
    // subtitle: {
    //   text: 'Click to Analyst Review to see reviews list',
    //   align: 'left'
    // },

    accessibility: {
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: '%'
      }
    },

    plotOptions: {
      pie: {
        showInLegend: true
      },
      series: {
        borderRadius: 5,
        dataLabels: [{
          enabled: true,
          distance: 15,
          format: '{point.name}'
        }, {
          enabled: true,
          distance: '-30%',
          filter: {
            property: 'percentage',
            operator: '>',
            value: 5
          },
          format: '{point.y}',
          style: {
            fontSize: '0.9em',
            textOutline: 'none'
          }
        }]
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Work load<br/>'
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: 'BBMR User',
        colorByPoint: true,
        data: seriesYData
      }
    ],
    drilldown: {
      series: [
        {
          name: 'Analyst Review',
          id: 'Analyst Review',
          data: drilledData
        },

      ]
    }
  }

  let analystUserPie = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'BBMR Analyst Work Load',
      align: 'left'
    },
    // subtitle: {
    //   text: 'Click to Analyst Review to see reviews list',
    //   align: 'left'
    // },

    accessibility: {
      announceNewData: {
        enabled: true
      },
      point: {
        valueSuffix: '%'
      }
    },

    plotOptions: {
      series: {
        borderRadius: 5,
        dataLabels: [{
          enabled: true,
          distance: 15,
          format: '{point.name}'
        }, {
          enabled: true,
          distance: '-30%',
          filter: {
            property: 'percentage',
            operator: '>',
            value: 5
          },
          format: '{point.y}',
          style: {
            fontSize: '0.9em',
            textOutline: 'none'
          }
        }]
      },
      pie: {
        showInLegend: true
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Work Load<br/>'
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: 'BBMR User',
        colorByPoint: true,
        data: analystUserBar
      }
    ],
    drilldown: {
      series: [
        {
          name: 'Analyst Review',
          id: 'Analyst Review',
          data: drilledData
        },

      ]
    }
  }

  let defaultBaChatOptions = {
    chart: {
      type: 'column'
    },
    title: {
      align: 'left',
      text: 'BBMR Work Load'
    },
    subtitle: {
      align: 'left',
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category',
      title: {
        text: "BBMR Admin"
      }
    },
    yAxis: {
      title: {
        text: 'Total GG1 Work Load'
      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}'
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Work load<br/>'
    },

    series: {
      name: 'BBMR User',
      colorByPoint: true,
      data: barChartData
    }
  }


  let defaultanalystUser = {
    chart: {
      type: radio == 'pie' ? 'pie' : 'column'
    },
    title: {
      align: 'left',
      text: 'BBMR Analyst Work Load'
    },
    subtitle: {
      align: 'left',
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    xAxis: {
      type: 'category',
      title: {
        text: "BBMR Analyst Users"
      },

    },
    yAxis: {
      title: {
        text: 'Total GG1 Anaylst Work Load',

      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}'
        }
      },

    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Work load<br/>'
    },

    series: {
      name: 'BBMR Analyst',
      colorByPoint: true,
      data: analystUserBar
    }
  }




  useEffect(() => {
    if (bbmrGG1?.length) {
      let seriesY = [];
      bbmrGG1?.map((item) => {
        seriesY.push({
          name: item.StatusName,
          y: item.workload,
          // drilldown: item.StatusName
        })
      })
      setSeriesYData(seriesY)
      setBarChartData(seriesY)
    }
  }, [bbmrGG1, radio])

  useEffect(() => {
    if (bbmrGG1Assign?.length) {
      let drilled = [];
      bbmrGG1Assign?.map((item) => {
        drilled.push([item.Name, item.count])
      })

      console.log("drilled", drilled)
      setDrilledData(drilled)
      setAnalystUserBar(bbmrGG1Assign?.map((i) => {
        return {
          name: i.Name,
          y: i.count,
          // drilldown: item.StatusName
        }
      }))
    }
  }, [bbmrGG1Assign, radio])
  const initialOptionsAnalyticReview = {
    ...defaultOptions,
    chart: {
      type: chartType,
    },
    xAxis: {
      categories: chartDataItems?.[0]?.chartData?.map((item) => item?.xLabel),
    },
    yAxis: {
      title: {
        text: "Employee",
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
  };

  const [finalChartData, setFinalChartData] = useState([]);
  const [options, setOptions] = useState({ ...initialOptionsAnalyticReview });
  useEffect(() => {
    if (chartDataAgeGroup) {
      setDpdvItems(chartDataAgeGroup);
      setChartdataItems(chartDataAgeGroup);
    }
  }, [chartDataAgeGroup]);

  useEffect(() => {
    dispatch(BBMRGG1Analyst({
      tableName: "vw_BBMR_Workload_State",
      searchParams: "[]",
      columns: "*",
      pageNumber: 1,
      pageSize: 1000000,
      sortData: "",
      additionalWhere: "",
      qID: 0,
      location: "global",
    },));
  }, []);

  useEffect(() => {
    dispatch(BBMRGG1AssignAnalyst({
      tableName: "vw_BBMR_Assigned_Analyst",
      searchParams: "[]",
      columns: "*",
      pageNumber: 1,
      pageSize: 1000000,
      sortData: "",
      additionalWhere: "",
      qID: 0,
      location: "global",
    },));
  }, []);

  const handleSelectChange = (event) => {
    const { value } = event.target;

    setSelected(value);
  };
  useEffect(() => {
    const finalItems = [...chartDataAgeGroup].filter((item) =>
      selected.includes(item.seriesName)
    );
    setChartdataItems(finalItems);
  }, [selected]);
  const handleChange = (event) => {
    setChartType(event.target.value);
  };
  const handleChangeDropDown = (event) => {
    setFormData(event.target.value);

    if (event.target.value === "Department") {
      setShowSelectMultiple(true);
    } else {
      setShowSelectMultiple(false);
    }
  };
  useEffect(() => {
    if (chartType === "column-stack") {
      const data = chartDataItems?.map((series) => ({
        name: series.seriesName,
        data: series.chartData.map((item) => item.employeeCount),
      }));
      setFinalChartData(data);
    } else if (chartType === "pie") {
      const data = chartDataItems?.map((series) => ({
        name: series.seriesName,
        data: series.chartData.map((item) => {
          return { name: item.xLabel, y: item.employeeCount };
        }),
      }));
      setFinalChartData(data);
    } else {
      setFinalChartData(
        chartDataItems?.map((series) => ({
          name: series.seriesName,
          data: series.chartData.map((item) => item.employeeCount),
        }))
      );
    }
  }, [chartType, chartDataItems]);

  //   useEffect(() => {
  //     let chart = chartType.split("-");
  //     let localOptions = {
  //       ...initialOptionsAnalyticReview,
  //       chart: {
  //         type: chart[0],
  //       },
  //       series: finalChartData,
  //     };
  //     if (chart.length > 1) {
  //       if (chart[0] === "column") {
  //         if (chart[1] === "stack") {
  //           localOptions.yAxis.stackLabels = { enabled: true };
  //           localOptions.plotOptions = {
  //             column: {
  //               stacking: "normal",
  //               dataLabels: {
  //                 enabled: true,
  //               },
  //             },
  //           };
  //         } else {
  //           localOptions.yAxis.stackLabels = { enabled: false };
  //           localOptions.plotOptions = {
  //             column: {
  //               stacking: "none",
  //               dataLabels: {
  //                 enabled: false,
  //               },
  //             },
  //           };
  //         }
  //       }
  //     }
  //     setOptions(localOptions);
  //   }, [finalChartData, chartType]);

  return (
    <div style={{ overflow: "hidden", background: "white" }}>
      {/* <Box
        style={{
          width: "100%",
          marginTop: "14px",
          marginBottom: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 34px",
        }}
      > */}
      {/* <FormControl fullwidth size="small" sx={{ minWidth: 250 }}>
          <InputLabel>Preference</InputLabel>
          <Select
            variant="outlined"
            value={formData}
            label="Preference"
            onChange={handleChangeDropDown}
          >
            <MenuItem value={"Overall"}>Overall</MenuItem>
            <MenuItem value={"Department"}>By Department</MenuItem>
          </Select>
        </FormControl> */}
      {/* SelectMultiple Box */}
      {/* <Col sm="3">
          {showSelectMultiple && dpdvItems && dpdvItems.length > 0 && (
            <SelectMultipleCheckBoxCharts
              size="small"
              labelName="Department"
              name="AgeGroupChartType"
              handleChange={handleSelectChange}
              setSelected={setSelected}
              selectUpdate={selected}
              selected={selected}
              options={dpdvItems.map((data, index) => ({
                value: data.seriesName,
                label: data.seriesName,
              }))}
            />
          )}
        </Col> */}
      {/* <FormControl margin="dense" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Chart Type</InputLabel>
          <Select
            size="small"
            variant="outlined"
            name="AgeGroupChartType"
            label="Chart Type"
            value={chartType}
            onChange={handleChange}
          >
            <MenuItem value={"line"}>Line</MenuItem>
            <MenuItem value={"column"}>Column</MenuItem>
            <MenuItem value={"column-stack"}>Staked Column</MenuItem>
            <MenuItem value={"spline"}>Spline</MenuItem>
            <MenuItem value={"area"}>Area</MenuItem>

            <MenuItem value={"pie"}>Pie</MenuItem>
          </Select>
        </FormControl> */}
      {/* </Box> */}
      <Row >
        <RadioGroup
          row
          style={{ display: "flex", justifyContent: "end" }}
          // aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Pie"
            control={
              <Radio
                onChange={(e, v) => {
                  setRadio(e.target.value);
                }}
                checked={radio == "Pie" ? true : false}
              />
            }
            label={"Pie Chart"} //"Approve"
          />

          <FormControlLabel
            value="Bar"
            control={
              <Radio
                onChange={(e, v) => {
                  setRadio(e.target.value);
                }}
                checked={radio == "Bar" ? true : false}
              />
            }
            label={"Bar Chart"}
          />
        </RadioGroup>
      </Row>

      {loadingChartsAgeGroup ? (
        <Row>
          <Col className="text-center">
            <Spinner />
          </Col>
        </Row>
      ) : (
        radio == "Pie" ?
          <Row>
            <Col md={6}> <HighchartsReact highcharts={Highcharts} options={defaultOptions} /> </Col>
            <Col md={6}> <HighchartsReact highcharts={Highcharts} options={analystUserPie} /> </Col>
          </Row>
          :
          <Row>
            <Col md={6}><HighchartsReact highcharts={Highcharts} options={defaultBaChatOptions} /></Col>
            <Col md={6}><HighchartsReact highcharts={Highcharts} options={defaultanalystUser} /></Col>
          </Row>
      )}
    </div>
  );
};

export default AnalystReview;
