import {
  GET_LEAVES,
  GET_LEAVES_SUCCESS,
  GET_LEAVES_FAIL,
  GET_LEAVE_DETAILS,
  GET_LEAVE_DETAILS_SUCCESS,
  GET_LEAVE_DETAILS_FAIL,
  REQ_LEAVE_APPLICATION,
  REQ_LEAVE_APPLICATION_SUCCESS,
  REQ_LEAVE_APPLICATION_FAIL,
  EDIT_LEAVE_APPLICATION,
  EDIT_LEAVE_APPLICATION_SUCCESS,
  EDIT_LEAVE_APPLICATION_FAIL,
  LEAVE_APPLICATION_DETAILS,
  LEAVE_APPLICATION_DETAILS_SUCCESS,
  LEAVE_APPLICATION_DETAILS_FAIL,
  FIND_LEAVE_APPLICATION,
  FIND_LEAVE_APPLICATION_SUCCESS,
  FIND_LEAVE_APPLICATION_FAIL,
  DELETE_LEAVE_APPLICATION,
  DELETE_LEAVE_APPLICATION_SUCCESS,
  DELETE_LEAVE_APPLICATION_FAIL,
  CALCULATE_HOURS,
  CALCULATE_HOURS_SUCCESS,
  CALCULATE_HOURS_FAIL,
  LEAVE_APPLICATION_HISTORY,
  LEAVE_APPLICATION_HISTORY_SUCCESS,
  LEAVE_APPLICATION_HISTORY_FAIL,
  UPDATE_LEAVE_STATUS,
  UPDATE_LEAVE_STATUS_SUCCESS,
  UPDATE_LEAVE_STATUS_FAIL,
} from "./actionTypes";

export const getLeaves = () => {
  return {
    type: GET_LEAVES,
  };
};

export const getLeavesSuccess = (leaves) => {
  return {
    type: GET_LEAVES_SUCCESS,
    payload: leaves,
  };
};

export const getLeavesFail = (error) => {
  return {
    type: GET_LEAVES_FAIL,
    payload: error,
  };
};

export const getLeaveDetails = (id) => {
  return {
    type: GET_LEAVE_DETAILS,
    payload: id,
  };
};

export const getLeaveDetailsSuccess = (leave) => {
  return {
    type: GET_LEAVE_DETAILS_SUCCESS,
    payload: leave,
  };
};

export const getLeaveDetailsFail = (error) => {
  return {
    type: GET_LEAVE_DETAILS_FAIL,
    payload: error,
  };
};

// import { showMessage } from "app/store/actions/fuse";
export const SET_HEADER_FILTER = "SET HEADER FILTER";
export const CHANGE_HEADER_FILTER = "CHANGE HEADER FILTER";
export const TOGGLE_DIALOG = "TOGGLE DIALOG";
export const UPDATE_LEAVE_SHARE_DETAIL = "UPDATE LEAVE SHARE DETAIL";
export const RESET_FILTER = "RESET_FILTER";

export function setHeaderFilter() {
  return (dispatch) => {
    dispatch({ type: SET_HEADER_FILTER });
  };
}

export function changeHeaderFilter(data) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_HEADER_FILTER,
      payload: {
        data: data,
      },
    });
  };
}

export const toggleRemarksDialog = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_DIALOG });
  };
};
export const resetFilter = () => {
  return (dispatch) => {
    dispatch({ type: RESET_FILTER });
  };
};

export const updateLeaveShareDetail = (data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_LEAVE_SHARE_DETAIL, payload: data });
    // dispatch(
    //   showMessage({
    //     message: "Saved",
    //     variant: "success",
    //   })
    // );
  };
};
export const editLeaveApplicationDetails = (payload, id) => {
  return {
    type: EDIT_LEAVE_APPLICATION,
    payload: payload,
    id: id,
  };
};

export const editLeaveApplicationDetailsSuccess = (results) => {
  return {
    type: EDIT_LEAVE_APPLICATION_SUCCESS,
    payload: results,
  };
};
export const editLeaveApplicationDetailsFail = (error) => {
  return {
    type: EDIT_LEAVE_APPLICATION_FAIL,
    payload: error,
  };
};
export const leaveApplicationDetails = (id) => {
  return {
    type: LEAVE_APPLICATION_DETAILS,
    payload: id,
  };
};
export const leaveApplicationDetailsSuccess = (results) => {
  return {
    type: LEAVE_APPLICATION_DETAILS_SUCCESS,
    payload: results,
  };
};

export const leaveApplicationDetailsFail = (error) => {
  return {
    type: LEAVE_APPLICATION_DETAILS_FAIL,
    payload: error,
  };
};
// leaveApplicationHistory
export const findLeaveApplication = (id) => {
  return {
    type: FIND_LEAVE_APPLICATION,
    payload: id,
  };
};

export const findLeaveApplicationSuccess = (results) => {
  return {
    type: FIND_LEAVE_APPLICATION_SUCCESS,
    payload: results,
  };
};

export const findLeaveApplicationFail = (error) => {
  return {
    type: FIND_LEAVE_APPLICATION_FAIL,
    payload: error,
  };
};

export const leaveApplicationHistory = (id) => {
  return {
    type: LEAVE_APPLICATION_HISTORY,
    payload: id,
  };
};

export const leaveApplicationHistorySuccess = (results) => {
  console.log("result", results);
  return {
    type: LEAVE_APPLICATION_HISTORY_SUCCESS,
    payload: results,
  };
};

export const leaveApplicationHistoryFail = (error) => {
  return {
    type: LEAVE_APPLICATION_HISTORY_FAIL,
    payload: error,
  };
};

export const reqLeaveApplication = (payload) => {
  return {
    type: REQ_LEAVE_APPLICATION,
    payload: payload,
  };
};

export const reqLeaveApplicationSuccess = (payload) => {
  return {
    type: REQ_LEAVE_APPLICATION_SUCCESS,
    payload: payload,
  };
};
export const reqLeaveApplicationFail = (payload) => {
  return {
    type: REQ_LEAVE_APPLICATION_FAIL,
    payload: payload,
  };
};

export const deleteLeaveApplication = (id) => {
  return {
    type: DELETE_LEAVE_APPLICATION,
    payload: id,
  };
};

export const deleteLeaveApplicationSuccess = (results) => {
  return {
    type: DELETE_LEAVE_APPLICATION_SUCCESS,
    payload: results,
  };
};

export const deleteLeaveApplicationFail = (error) => {
  return {
    type: DELETE_LEAVE_APPLICATION_FAIL,
    payload: error,
  };
};
export const UpdateLeaveStatus = (payload) => {
  return {
    type: UPDATE_LEAVE_STATUS,
    payload: payload,
  };
};

export const UpdateLeaveStatusSuccess = (payload) => {
  return {
    type: UPDATE_LEAVE_STATUS_SUCCESS,
    payload: payload,
  };
};

export const UpdateLeaveStatusFail = (error) => {
  return {
    type: UPDATE_LEAVE_STATUS_FAIL,
    payload: error,
  };
};
export const calculateHours = (startDate, endDate) => {
  return {
    type: CALCULATE_HOURS,
    startDate: startDate,
    endDate: endDate,
  };
};

export const calculateHoursSuccess = (results) => {
  return {
    type: CALCULATE_HOURS_SUCCESS,
    payload: results,
  };
};
export const calculateHoursFail = (error) => {
  return {
    type: CALCULATE_HOURS_FAIL,
    payload: error,
  };
};
