import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import {
  GET_PROCESSES,
  GET_PROCESSES_DETAILS,
  PROCESS_COPY,
  PROCESS_CREATE,
  PROCESS_DELETE,
  PROCESS_UPDATE,
  PROCESS_STATUS_CREATE,
  PROCESS_STATUS_DELETE,
  PROCESS_STATUS_UPDATE,
  GET_PROCESSES_STATUSLIST,
  GET_PROCESSES_STATUSLIST_BY_NOA,
  BOTTLENECK_UPDATE,
  GET_NatureKeyValue,
  GET_NatureKeyValue_SUCCESS,
  GET_NatureKeyValue_FAIL,
} from "./actionTypes";

import {
  getProcessesSuccess,
  getProcessesFail,
  getProcessesDetailsSuccess,
  getProcessesDetailsFail,
  createProcessSuccess,
  createProcessFail,
  updateProcessSuccess,
  updateProcessFail,
  deleteProcessSuccess,
  deleteProcessFail,
  copyProcessSuccess,
  copyProcessFail,
  createProcessStatusSuccess,
  createProcessStatusFail,
  updateProcessStatusSuccess,
  updateProcessStatusFail,
  deleteProcessStatusSuccess,
  deleteProcessStatusFail,
  getProcessesStatusListSuccess,
  getProcessesStatusListFail,
  updatebottleneckSuccess,
  updatebottleneckFailure,
  getNatureKeyValueSuccess,
  getNatureKeyValueFail,
} from "./actions";

import {
  copyProcess,
  createProcess,
  deleteProcess,
  getProcessDetails,
  getProcesses,
  updateProcess,
  updateProcessStatus,
  deleteProcessStatus,
  createProcessStatus,
  updateProcessStatusList,
  getProcessStatusList,
  updateBottleneckData,
  getNatureKeyValue, 
  getProcessStatusListByNOA
} from "../../_helper/services/process";
function* onGetProcesses({ payload: { page, pageSize } }) {
  try {
    const response = yield call(getProcesses, { page, pageSize });
    yield put(getProcessesSuccess(response));
  } catch (error) {
    yield put(getProcessesFail(error.response));
  }
}

function* onGetProcessDetails({ payload: id }) {
  try {
    const response = yield call(getProcessDetails, id);
    yield put(getProcessesDetailsSuccess(response));
  } catch (error) {
    yield put(getProcessesDetailsFail(error.response));
  }
}
function* onGetProcessStatusList({ payload: id }) {
  try {
    const response = yield call(getProcessStatusList, id);
    yield put(getProcessesStatusListSuccess(response));
  } catch (error) {
    yield put(getProcessesStatusListFail(error.response));
  }
}
function* onGetProcessStatusListByNOA({ payload }) {
  console.log("121212 saga payload ::: ",payload)
  try {
    const response = yield call(getProcessStatusListByNOA, payload);
    yield put(getProcessesStatusListSuccess(response));
  } catch (error) {
    yield put(getProcessesStatusListFail(error.response));
  }
}
function* onCreateProcess({ payload }) {
  try {
    const response = yield call(createProcess, payload);
    yield put(createProcessSuccess(response));
    ShowSucess('Process Created successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(createProcessFail(error.response));
  }
}
function* onCreateProcessStatus({ payload }) {
  try {
    if(payload.statusName===""){
      ShowError("Status Name is Required")
    } else {
    const response = yield call(createProcessStatus, payload);
    yield put(createProcessStatusSuccess(response));
    ShowSucess("Process Status Created successfully", {
      position: "top-right",
    })};
  } catch (error) {
    yield put(createProcessStatusFail(error.response));
  }
}
function* onUpdateProcess({ payload: { ID }, payload }) {
  try {
    const response = yield call(updateProcess, ID, payload);
    yield put(updateProcessSuccess(response));
    ShowSucess("Process Updated successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(updateProcessFail(error.response));
  }
}
function* onUpdateProcessStatus({ payload }) {
  try {
    const response = payload.statusList
      ? yield call(updateProcessStatusList, payload.statusList)
      : yield call(updateProcessStatus, payload.id, payload);
    yield put(updateProcessStatusSuccess(response));
    ShowSucess("Process Status Updated successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(updateProcessStatusFail(error.response));
  }
}
function* onCopyProcess({ payload: payload }) {
  try {
    const response = yield call(copyProcess, payload);
    yield put(copyProcessSuccess(response));
    ShowSucess("Process Copied successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(copyProcessFail(error.response));
  }
}
function* onDeleteProcess({ payload: id }) {
  try {
    const response = yield call(deleteProcess, id);
    yield put(deleteProcessSuccess(response));
    ShowSucess('Deleted successfully', {
      position: 'top-right'
    });
  } catch (error) {
    yield put(deleteProcessFail(error.response));
  }
}

function* onDeleteProcessStatus({ payload: id }) {
  try {
    const response = yield call(deleteProcessStatus, id);
    yield put(deleteProcessStatusSuccess(response));
    ShowSucess("Deleted successfully", {
      position: "top-right",
    });
  } catch (error) {
    yield put(deleteProcessStatusFail(error.response));
  }
}
function* onUpdateBottleNeck({ payload }) {

  try {

    const response = yield call(updateBottleneckData, payload);
    yield put(updatebottleneckSuccess(response));
 


  } catch (error) {
    yield put(updatebottleneckFailure(error.response));
  }
}
function* onGetNatureKeyValue({ payload }) {
  try {
 const response = yield call(getNatureKeyValue , payload);
   yield put(getNatureKeyValueSuccess(response));
   } catch (error) {
     yield put(getNatureKeyValueFail(error.response));
   }
 }
// function* onGetNatureKeyValue({ payload }) {
//  try {
// const response = yield call(getNatureKeyValue , payload);
//   yield put(getNatureKeyValueSuccess(response));
//   } catch (error) {
//     yield put(getNatureKeyValueFail(error.response));
//   }
// }

function* CartSaga() {
  yield takeLatest(GET_PROCESSES, onGetProcesses);
  yield takeLatest(GET_PROCESSES_STATUSLIST, onGetProcessStatusList);
  yield takeLatest(GET_PROCESSES_STATUSLIST_BY_NOA, onGetProcessStatusListByNOA);
  yield takeLatest(GET_PROCESSES_DETAILS, onGetProcessDetails);
  yield takeLatest(PROCESS_CREATE, onCreateProcess);
  yield takeLatest(PROCESS_STATUS_CREATE, onCreateProcessStatus);
  yield takeLatest(PROCESS_UPDATE, onUpdateProcess);
  yield takeLatest(PROCESS_COPY, onCopyProcess);
  yield takeLatest(PROCESS_STATUS_UPDATE, onUpdateProcessStatus);
  yield takeLatest(PROCESS_DELETE, onDeleteProcess);
  yield takeLatest(PROCESS_STATUS_DELETE, onDeleteProcessStatus);
  yield takeLatest(BOTTLENECK_UPDATE, onUpdateBottleNeck);
  yield takeLatest(GET_NatureKeyValue, onGetNatureKeyValue);
}

export default CartSaga;
