const visibleColumns = [
  // {
  //   title: "Account Description",
  //   field: "Main_Account_Description",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Fund Description",
  //   field: "FundDescription",
  //   locked: false,
  //   filter: "text",
  //   width: "300",
  //   show: true,
  // },
  // {
  //   title: "Job Number",
  //   field: "JobNumber",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Job Order Number",
  //   field: "JobOrderNumber",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Account Number",
  //   field: "Lg_Account_Number",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "D365 Account",
  //   field: "D365Account",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },

  // {
  //   title: "Main Account",
  //   field: "Main_Account",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Fund Dimension",
  //   field: "Fund_Dimension",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Year Dimension",
  //   field: "Year_Dimension",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "Account Type",
  //   field: "AccountType",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  {
    title: "DEPTDIV",
    field: "DEPTDIV",
    locked: false,
    filter: "text",
    width: "200",
    show: true,
  },
  // {
  //   title: "Employee ID",
  //   field: "EMPLOYEE_ID",
  //   locked: false,
  //   filter: "numeric",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "NAME",
  //   field: "NAME",
  //   locked: false,
  //   filter: "text",
  //   width: "200",
  //   show: true,
  // },
  // {
  //   title: "RATE",
  //   field: "RATE",
  //   locked: false,
  //   filter: "numeric",
  //   editor: "numeric",
  //   currencyType: "USD",
  //   className: 'text-end',
  //   headerClassName: 'number-align',
  //   width: "200",
  //   show: true,
  // },
  {
    title: "Pay Period Ending Date",
    field: "Pay_Period_Ending_date",
    locked: false,
    filter: "date",
    format: "MM/DD/YYYY",
    editor: "date",
    width: "180",
    show: true,
  },
  {
    title: "GROSS",
    field: "GROSS",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },

  // {
  //   title: "Total Deduction",
  //   field: "Ppe_Total_Deductions",
  //   locked: false,
  //   filter: "numeric",
  //   editor: "numeric",
  //   currencyType: "USD",
  //   className: 'text-end',
  //   headerClassName: 'number-align',
  //   width: "200",
  //   show: true,
  // },
  {
    title: "Health Govt Contr",
    field: "HEALTH_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Dental Govt Contr",
    field: "DENTAL_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Govt Life Contr",
    field: "GOVT_LIFE_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DC Govt Contr",
    field: "DC_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "HP Govt Contr",
    field: "HP_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Govt Non-Base Contr",
    field: "GOVT_NON_BASE_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "GOC DC & UNF",
    field: "GOC_DC_AND_UNF",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Medicare Govt Contr",
    field: "MEDICARE_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Health Emp Contr",
    field: "HEALTH_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Dent Emp Contr",
    field: "DENT_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Life Emp Contr",
    field: "LIFE_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "HP Emp Contr",
    field: "HP_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DC Emp Contr",
    field: "DC_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "DB Govt Contr",
    field: "DB_GOVT_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  {
    title: "Medicare Emp Contr",
    field: "MEDICARE_EMP_CONTR",
    locked: false,
    filter: "numeric",
    editor: "numeric",
    currencyType: "USD",
    className: 'text-end',
    headerClassName: 'number-align',
    width: "200",
    show: true,
  },
  // {
  //   title: "Check Date",
  //   field: "Check_Date",
  //   locked: false,
  //   filter: "date",
  //   format: "MM/DD/YYYY",
  //   editor: "date",
  //   width: "180",
  //   show: true,
  // },
  // {
  //   title: "Check Sequence",
  //   field: "Check_Sequence",
  //   locked: false,
  //   filter: "numeric",
  //   width: "160",
  //   show: true,
  // },

];

export default visibleColumns;
