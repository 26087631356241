import { useParams } from "react-router-dom";
import {
  GET_LEAVESSHAREFORM_DETAILS,
  GET_LEAVESSHAREFORM_SUCCESS,
  GET_LEAVESHAREFORM_FAILURE,
  LEAVESHAREFORM_DELETE,
  LEAVESHAREFORM_DELETE_SUCCESS,
  LEAVESHREFORM_DELETE_FAIL,
  LEAVESHAREFORM_UPDATE,
  LEAVESHAREFORM_UPDATE_SUCCESS,
  LEAVESHAREFORM_UPDATE_FAIL,
  LEAVESHAREFORM_CREATE,
  LEAVESHAREFORM_CREATE_SUCCESS,
  LEAVESHAREFORM_CREATE_FAIL,
  LEAVESHAREFORM_GETINFO,
  LEAVESHAREFORM_GETINFO_SUCCESS,
  LEAVESHAREFORM_GETINFO_FAIL,
} from "./actiontype";

export const getleavesformDetails = (leaveAppID) => {
  return {
    type: GET_LEAVESSHAREFORM_DETAILS,
    payload: { leaveAppID },
  };
};
export const getleavesformsuccess = (results) => {
  return {
    type: GET_LEAVESSHAREFORM_SUCCESS,
    payload: results,
  };
};

export const getleavesformfailure = (error) => {
  return {
    type: GET_LEAVESHAREFORM_FAILURE,
    payload: error,
  };
};

// get information

export const getleaveDonarInfo = (employeeId, rowIndex) => ({
  type: LEAVESHAREFORM_GETINFO,
  payload: { employeeId, rowIndex },
});

export const getLeaveDonarInfoSuccess = (data, rowIndex) => ({
  type: LEAVESHAREFORM_GETINFO_SUCCESS,
  payload: data,
  index: rowIndex,
});

export const getLeaveDonarInfoFail = (error) => ({
  type: LEAVESHAREFORM_GETINFO_FAIL,
  payload: error,
});

// for using update
export const updateLeavesForm = (payload) => {
  return {
    type: LEAVESHAREFORM_UPDATE,
    payload: payload,
  };
};
export const updateLeavesFormSuccess = (response) => {
  return {
    type: LEAVESHAREFORM_UPDATE_SUCCESS,
    payload: response,
  };
};
export const updateLeavesFormFail = (error) => {
  return {
    type: LEAVESHAREFORM_UPDATE_FAIL,
    payload: error,
  };
};
export const createLeavesForm = (payload) => {
  return {
    type: LEAVESHAREFORM_CREATE,
    payload: payload,
  };
};
export const createLeavesFormSuccess = (response) => {
  return {
    type: LEAVESHAREFORM_CREATE_SUCCESS,
    payload: response,
  };
};
export const createLeavesFormFailure = (error) => {
  return {
    type: LEAVESHAREFORM_CREATE_FAIL,
    payload: error,
  };
};

export const deleteShareform = (EmpID) => {
  return {
    type: LEAVESHAREFORM_DELETE,
    payload: EmpID,
  };
};
export const deleteShareFormSuccess = (EmpID) => {
  return {
    type: LEAVESHAREFORM_DELETE_SUCCESS,
    payload: EmpID,
  };
};
export const deleteShareFormFail = (error) => {
  return {
    type: LEAVESHREFORM_DELETE_FAIL,
    payload: error,
  };
};
