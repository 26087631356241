import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useAuth } from "oidc-react";

import visileColumns from "./visibleColumns";
import { useNavigate } from "react-router-dom";
 

import {
  faCalendar,
  faDisplayChartUp,
  faExternalLink,
  faEye,
  faList,
  faPlusCircle,
  faQrcode,
  faUsers,
  faBookOpen,
  faHousePersonLeave,
  faPoll,
  faPollH,
  faSquarePollHorizontal,
  faPollPeople,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@progress/kendo-react-common";
import TelerikDataTable from "../CommonComponents/TelerikDataTable";
import AdvanceSearch from "../CommonComponents/AdvanceSearch";
import NotificationPage from "../CommonComponents/SendNotice/CreateNotice";
import ErrorBoundary from "../ErrorBoundry";
import HistoryDataTable from "../CommonComponents/HistoryDataTable";
import useSortedColumns from "../utils/useSortedColumns";
import EmployeeVerification from "../Components/EmployeeVarificationV2/component/EmployeeVerification";
import { loadingPanel } from "../CommonComponents/CommonFunction";

function EmpBenefitMaster() {
  const {
    columns,
    loadingSearch,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);
  const dispatch = useDispatch();
  const { userData } = useAuth();
  const id = "employeemaster";
  const { employeeAuditHistory, isValidEmply, loadingEmployeeSearch } =
    useSelector((state) => state.employeeReducer);
  const { sort } = useSelector((state) => state?.CommonReducer) || {};
  const { filter: storeFilter, pageSize: storePageSize } = useSelector(
    (state) => state.CommonReducer
  );

  const history = useNavigate();
  const [expandedSearchResult, setexpandedSearchResult] = useState(true);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const [searchParams, setSearchParams] = useState([]);
  const [page, setPage] = useState(1);
  const [additionalWhere, setAdditionalWhere] = useState("");
  // const [sortData, setSortData] = useState(
  //   sort && sort[id] ? sort[id] : []
  // );
  const [sortData, setSortData] = useState([
    { field: "EmployeeName_LFM", dir: "asc" },
  ]);
  const initialGridParams = {
    sort: [{ field: "EmployeeName_LFM", dir: "asc" }],
    filter: "",
  };
  const [pageSize, setPageSize] = useState(storePageSize[id] || 100);
  // const [sortedColumns, setSortedColumns] = useState([]);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);
  const [isActive, setIsActive] = useState("A%");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [empId, setEmpId] = useState(null);
  const [visible, setVisible] = useState(false);

  console.log("userData", userData);
  const actions = useMemo((props) => {
    
    return [
      
      {
        title: "Employee Details",
        action: (props) => {
          const url = `/enrollment-benefits/${props.dataItem.ID}/add/${props.dataItem.Inactive_Status}`;
          history(url);
        },
        icon: { iconName: "k-icon  k-i-eye" },
        className: "font-primary",
        style: { cursor: "pointer" },
        _role: ["Developer", "GG1-TRAINER", "EmployeeSuperUser", "SUPER-USER"],
        isVisible: (dataProps) => {
         
          return dataProps?.Exist ?  false : true
        }
      },
      {
        title: "Update",
        action: (props) => {
          const url = `/enrollment-benefits/${props.dataItem.ID}/update`;
          history(url);
        },
        icon: { iconName: "k-icon  k-i-pencil" },
        className: "font-primary",
        style: { cursor: "pointer" },
        _role: ["Developer", "GG1-TRAINER", "EmployeeSuperUser", "SUPER-USER"],
        isVisible: (dataProps) => {
          
          return dataProps?.Exist ?  true : false
        }
      },
     
      
    ];
  });

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);
  useEffect(() => {
    if (isValidEmply === true && empId) {
      history(`/leaveShare/${empId}`);
    }
  }, [isValidEmply, empId]);
  const [sortedColumns, setSortedColumns] = useSortedColumns(
    id,
    ColumnsSorted,
    columns,
    visileColumns,
    userData
  );
  // useEffect(() => {
  //   if (ColumnsSorted[id] !== undefined) {
  //     setSortedColumns(ColumnsSorted[id]);
  //   } else {
  //     if (
  //       columns &&
  //       openHistory === true &&
  //       HistoryColumns.length > 0 &&
  //       columns.length > 0
  //     ) {
  //       const clms = HistoryColumns.map((d) => d.field.toLowerCase());
  //       const data = columns
  //         .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
  //         .map((p) => {
  //           return {
  //             field: p.column_name,
  //             //   p.column_name.indexOf("_") > -1
  //             //     ? p.column_name
  //             //         .slice(0, p.column_name.indexOf("_") - 1)
  //             //         .toLowerCase() +
  //             //       p.column_name.slice(p.column_name.indexOf("_") - 1)
  //             //     : p.column_name.charAt(0).toLowerCase() +
  //             //       p.column_name.slice(1),
  //             show: false,
  //             locked: false,
  //             width: 200,
  //             title: p.column_title,
  //             filter: "text",
  //           };
  //         });

  //       const sortedData = filterAllowedColumn(
  //         userData,
  //         HistoryColumns.concat(data),
  //         "id"
  //       );

  //       setSortedColumns(sortedData);
  //     } else if (columns && visileColumns.length > 0 && columns.length > 0) {
  //       const clms = visileColumns.map((d) => d.field.toLowerCase());
  //       const data = columns
  //         .filter((p) => clms.indexOf(p.column_name.toLowerCase()) < 0)
  //         .map((p) => {
  //           return {
  //             field: p.column_name,
  //             show: false,
  //             locked: false,
  //             width: 200,
  //             title: p.column_title,
  //             filter: "text",
  //           };
  //         });
  //       setSortedColumns(visileColumns.concat(data));
  //     } else {
  //       return;
  //     }
  //   }
  // }, [columns]);
  //, openHistory === true, sortData
  // console.log("userData", userData);
  // useEffect(() => {}, [openHistory === true, sortData]);
  useEffect(() => {
    const data = historyData.sort((a, b) => {
      return sortData
        .map((sortItem) => {
          const { field, dir } = sortItem;
          if (a[field] < b[field]) return dir === "desc" ? 1 : -1;
          if (a[field] > b[field]) return dir === "desc" ? -1 : 1;
          return 0;
        })
        .reduce((acc, result) => {
          return acc !== 0 ? acc : result;
        }, 0);
    });

    // console.log("useEffect", data);
    setHistoryData(data);
  }, [sortData]);

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    //setPageSize(10);
  };
  // const handleFilterChange = (data) => {
  //   const additionalWhereCondition =
  //     isActive === "A%"
  //       ? `Emp_Status LIKE 'A%' `
  //       : isActive === "I%"
  //       ? `Emp_Status LIKE 'I%' `
  //       : '';

  //   const filterCondition = data ? `${data} AND ` : '';

  //   setAdditionalWhere(`${filterCondition}${additionalWhereCondition}`);
  //   setPage(1);
  //   // setPageSize(10);
  // };

  // const handleFilterChange = (data) => {
  //   const additionalWhereCondition =
  //     isActive === "A%"
  //       ? `Emp_Status LIKE 'A%' `
  //       : isActive === "I%"
  //       ? `Emp_Status LIKE 'I%' `
  //       : '';

  //   setAdditionalWhere(`${data} AND ${additionalWhereCondition}`);
  //   setPage(1);
  //   // setPageSize(10);
  // };

  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    //setPageSize(10);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        {visible && (
          <EmployeeVerification
            setVisible={setVisible}
            visible={visible}
            empId={empId}
          />
        )}
        <Container fluid={true}>
          <NotificationPage
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            notificationId={notificationId}
            hideButton={true}
          />
          <Row className="bg-primary rounded py-3 mb-3">
            <Typography variant="h5">
              {/* <FontAwesomeIcon icon={faUsers} className="m-r-10" /> */}
              <Icon style={{ fontSize: "25px", margin: "10px" }} name="user" />
              Benefit Master
            </Typography>
          </Row>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <AdvanceSearch
                    id={id}
                    gridId={id}
                    location="global"
                    columnList={"*"}
                    columnRefernces={[]}
                    tableName={"vw_EmployeeMaster_Benefit"}
                    searchParams={searchParams}
                    additionalWhere={additionalWhere}
                    expanded={false}
                    sortData={sortData}
                    pageNumber={page}
                    pageSize={pageSize}
                    chooseFilterMethod={chooseFilterMethod}
                    isActive={isActive}
                  />
                  <Accordion
                    style={{ margin: "0px" }}
                    expanded={expandedSearchResult}
                  >
                    {/* <AccordionSummary
                      expandIcon={<ExpandMore />}
                      className="bg-light"
                      onClick={() =>
                        setexpandedSearchResult(!expandedSearchResult)
                      }
                    >
                      <Typography variant="h6">Search Results</Typography>
                    </AccordionSummary> */}
                    <AccordionDetails style={{ padding: "0px" }}>
                      {loadingEmployeeSearch && loadingPanel()}
                      <div>
                        {sortedColumns.length > 0 && (
                          <TelerikDataTable
                            id={id}
                            // pageNumber={page}
                            pageSize={pageSize}
                            totalCount={rowCount}
                            skip={(page - 1) * pageSize}
                            take={pageSize}
                            data={results || []}
                            sortData={sortData}
                            // tableName={"vw_employeeMaster"}
                            searchParams={searchParams}
                            // additionalWhere={additionalWhere}
                            onFilterChange={handleFilterChange}
                            onSortChange={handleSortChange}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            selectedColumns={sortedColumns}
                            setSortedColumns={setSortedColumns}
                            loading={loadingSearch}
                            actions={actions}
                            setChooseFilterMethod={setChooseFilterMethod}
                            chooseFilterMethod={chooseFilterMethod}
                            isEmployeeManagement={false}
                            setIsActive={setIsActive}
                            initialGridParams={initialGridParams}
                            isActive={isActive}
                            isPmValidation={false}
                          ></TelerikDataTable>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <HistoryDataTable
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
          data={employeeAuditHistory || []}
          sortData={sortData}
          selectedColumns={sortedColumns}
          setSortedColumns={setSortedColumns}
          onSortChange={handleSortChange}
          onFilterChange={handleFilterChange}
        />
      </Fragment>
    </ErrorBoundary>
  );
}
export default EmpBenefitMaster;
