import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Grid,
  GridColumn,
  GridColumnMenuWrapper,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { useForm } from "react-hook-form";
// import { useNavigate } from 'react-router-dom';
import { Btn } from "../../../AbstractElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@progress/kendo-react-common";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  Accordion,
  Button,
  AccordionDetails,
  AccordionSummary,
  CardActionArea,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import visileColumns from "./visibleColumns";
import { orderBy } from "@progress/kendo-data-query";
import AdvanceSearch from "../../../CommonComponents/AdvanceSearch";
import ErrorBoundary from "../../../ErrorBoundry";
import TelerikDataTable from "../../../CommonComponents/TelerikDataTable/indexV2";
import { useNavigate } from "react-router-dom";
import { useAuth } from "oidc-react";
import { filterAllowedColumn } from "../../../_helper/helper";
import {
  faDiagramProject,
  faEye,
  faList,
  faPlusLarge,
  faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { toast } from "react-toastify";

import useSortedColumns from "../../../utils/useSortedColumns";

function BBMR() {
  const dispatch = useDispatch();
  const {
    columns,
    loadingSearch,
    searchResults,
    ColumnsSorted,
    searchParameter,
    searchResults: { results, rowCount },
  } = useSelector((state) => state.advanceSearchv2Reducer);

  const history = useNavigate();
  const [expandedSearchResult, setexpandedSearchResult] = useState(false);
  const [chooseFilterMethod, setChooseFilterMethod] = useState("normalSearch");
  const id = "BbmrReport210";
  const [searchParams, setSearchParams] = useState([]);
  const [page, setPage] = useState(1);
  const { userData } = useAuth();
  const [additionalWhere, setAdditionalWhere] = useState("");
  const { sort, pageSize: storePageSize } =
    useSelector((state) => state?.CommonReducer) || {};
  const [sortData, setSortData] = useState([]);

  const [pageSize, setPageSize] = useState( 1000);

  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    if (loadingSearch) setexpandedSearchResult(true);
  }, [loadingSearch]);

  useEffect(() => {
    // console.log(searchResults);
  }, [searchResults]);
  const [sortedColumns, setSortedColumns] = useSortedColumns(
    id,
    ColumnsSorted,
    columns,
    visileColumns,
    userData
  );

  const handlePageChange = (event) => {
    setPage(event.page.skip / event.page.take + 1);
    setPageSize(event.page.take);
  };
  const handleSortChange = (sort) => {
    setSortData(sort);
    setPage(1);
    // setPageSize(10);
  };
  const handleFilterChange = (data) => {
    setAdditionalWhere(data);
    setPage(1);
    setPageSize(1000)
  };
  // const [groupConfig, setGroupConfig] = useState([{
  //   field: "TotalRevisedBudget",
  //   aggregates: [{ field: "TotalRevisedBudget", aggregate: "count" }],
  // },]);
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
    
  };
  useEffect(() => {
    if (searchParameter[id]?.searchParams?.length > 0) {
      setSearchParams(JSON.parse(searchParameter[id]?.searchParams));
    }
  }, [searchParameter]);
  return (
    <ErrorBoundary>
      {" "}
      <Fragment>
        <Container fluid={true}>
          <Row className="bg-primary rounded py-3 mb-3">
            <Typography variant="h5">
              <Icon
                style={{ fontSize: "25px", margin: "10px" }}
                name="file-ascx"
              />
              BBMR Report 210: BBMR Labor Cost Distribution by Job, DeptDiv, PPE
               with D365 Info
            </Typography>
          </Row>
          <Row>
            <Col sm="12" className="page-content">
              <Card>
                <CardBody>
                  <div>
                        
                          
                          <TelerikDataTable
                          id={id}
                          pageNumber={page}
                          pageSize={pageSize}
                          totalCount={rowCount}
                          skip={(page - 1) * pageSize}
                          take={pageSize}
                          data={results || []}
                          sortData={sortData}
                          tableName={"vw_BBMR_Report210_JobOrder_Summary_DPDV_PPE"}
                          searchParams={searchParams}
                          additionalWhere={additionalWhere}
                          onFilterChange={handleFilterChange}
                          onSortChange={handleSortChange}
                          onPageChange={handlePageChange}
                          onPageSizeChange={handlePageSizeChange}
                          selectedColumns={sortedColumns}
                          setSortedColumns={setSortedColumns}
                          loading={loadingSearch}
                          enableFooterCell={true}
                          chooseFilterMethod={chooseFilterMethod}
                          // aggregates={[
                          //   {
                          //     field: "TotalPreEncumbrances",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "TotalBudgetFundsAvailable",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "TotalRevisedBudget",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "TotalEncumbrances",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "TotalApportionment",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "TotalActualExpenditures",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          //   {
                          //     field: "GrossPay",
                          //     aggregate: "sum",
                          //     format: "c2",
                          //     prefixIcon: "$",
                          //     precision: 2,
                          //   },
                          // ]}
                        ></TelerikDataTable>
                      </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </ErrorBoundary>
  );

}
export default BBMR;
