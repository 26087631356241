import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_DEDUCTION,
  GET_EMPLOYEE_TRANSACTIONINFO,
  GET_EMPLOYEE_TRANSACTIONLOGS,
  GET_EMPLOYEE_TYPES,
  SEARCH_EMPLOYEE,
  VERIFY_EMPLOYEE,
  SUBSCRIBE_DEDUCTION,
  UNSUBSCRIBE_DEDUCTION,
  EMPLOYEE,
  AUDIT_TRAIL_HISTORY,
  AUDIT_TRAIL_HISTORY_SUCCESS,
  AUDIT_TRAIL_HISTORY_FAIL,
  FIND_EMPLOYEE,
  UPDATE_DEDUCTION,
  CREATE_CERTIFICATIONS,
  UPDATE_CERTIFICATIONS,
  CREATE_TAG,
  UPDATE_TAG,
  GET_DOCUMENT_ATTACHED,
  EMPLOYEE_LIST,
  EMPLOYEE_VERIFICATION,
  MASTER_UPDATE,
  MASTER_UPDATE_FAIL,
  MASTER_UPDATE_SUCCESS,
  NPAPAYROLL,
  NPAGG1TRANSACTION,
  FINDGG1_EMPLOYEE,
  MASTERGG1_UPDATE,
} from "./actionTypes";

import {
  searchEmployeeSuccess,
  searchEmployeeFail,
  getEmployeeDeductionSuccess,
  getEmployeeDeductionFail,
  getEmployeeTypesSuccess,
  getEmployeeTypesFails,
  getEmployeeSuccess,
  getEmployeeFails,
  findOutEmployeeSuccess,
  findOutEmployeeFail,
  verifyEmployeeSuccess,
  verifyEmployeeFail,
  perfomDeductionSubscriptionSuccess,
  perfomDeductionSubscriptionFails,
  perfomDeductionUnSubscriptionSuccess,
  perfomDeductionUnSubscriptionFails,
  getEmployeeTransactionLogsSuccess,
  getEmployeeTransactionLogsFails,
  getEmployeeTransactionInfoSuccess,
  getEmployeeTransactionInfoFails,
  EmployeeSuccess,
  EmployeeFails,
  updateDeductionSuccess,
  updateDeductionFails,
  onCertificationCreationSuccess,
  onCertificationCreationFails,
  updateCertificationsSuccess,
  updateCertificationsFails,
  createTagSuccess,
  createTagFails,
  updateTagSuccess,
  updateTagFails,
  getDocumentAttachedSuccess,
  getDocumentAttachedFail,
  getEmployeeListSuccess,
  getEmployeeListFails,
  findEmployeeVerification,
  findEmployeeVerificationFail,
  findEmployeeVerificationSuccess,
  updateMasterFail,
  updateMasterSuccess,
  createnpapayrollSuccess,
  createnpapayrollFails,
  npagetTransactionSuccess,
  npagetTransactionFails,
  findOutGg1EmployeeSuccess,
  findOutGg1EmployeeFail,
  updateGg1MasterFail,
  updateGg1MasterSuccess,
} from "./actions";
import { useNavigate } from "react-router-dom";
import {
  searchEmployee,
  verifyEmployee,
  getDeductionPlan,
  getEmployeeTypes,
  getEmployee,
  performNewSubscription,
  performActivateSubscription,
  performInActivateSubscription,
  getTransactionLogs,
  getTransactionInfo,
  Employee,
  findEmployee,
  AuditTrailHistory,
  deductionUpdate,
  CreateCertifications,
  UpdateCertifications,
  CreateTag,
  UpdateTag,
  documentAttached,
  getEmployeeList,
  employeeverification,
  updateMaster,
  npapayroll,
  npagetTransactionApi,
  findGg1Employee,
  updateGgMaster,
} from "../../_helper/services/employee";
import { ShowError, ShowSucess } from "../../_helper/helper";

function* onNPAGetTransaction({ payload: { hrcontrol, employeeID } }) {
  try {
    const response = yield call(npagetTransactionApi, {
      hrcontrol,
      employeeID,
    });
    yield put(npagetTransactionSuccess(response));
  } catch (error) {
    yield put(npagetTransactionFails(error.response));
  }
}
function* onUpdateMaster({ payload: payload }) {
  try {
    const response = yield call(updateMaster, payload);
    yield put(updateMasterSuccess(response));
    ShowSucess(response?.message, {
      position: "top-right",
    });
  } catch (error) {
    yield put(updateMasterFail(error.response));
    ShowError(error?.response?.data?.message);
  }
}
function* onUpdateGg1Master({ payload: payload }) {
  try {
    const response = yield call(updateGgMaster, payload);
    yield put(updateGg1MasterSuccess(response));
    ShowSucess(response?.message, {
      position: "top-right",
    });
  } catch (error) {
    yield put(updateGg1MasterFail(error.response));
    ShowError(error?.response?.request?.statusText);
    // ShowError(error?.response?.data?.message);

    console.log("ShowError",error)
  }
}
function* onCreateNPAPayroll({ payload }) {
  try {
    const response = yield call(npapayroll, payload);
    yield put(createnpapayrollSuccess(response));
    ShowSucess("Successfully created", {
      position: "top-right",
    });
  } catch (error) {
    yield put(createnpapayrollFails(error.response));
    ShowError(error?.response?.data?.message);
  }
}
function* onGetEmployee({ payload: id }) {
  try {
    const response = yield call(getEmployee, id);
    yield put(getEmployeeSuccess(response));
  } catch (error) {
    yield put(getEmployeeFails(error.response));
  }
}
function* GetEmployee({ payload: id }) {
  try {
    const response = yield call(Employee, id);
    yield put(EmployeeSuccess(response));
  } catch (error) {
    yield put(EmployeeFails(error.response));
  }
}
function* onfindEmployee({ payload: id }) {
  try {
    const response = yield call(findEmployee, id);
    yield put(findOutEmployeeSuccess(response));
  } catch (error) {
    yield put(findOutEmployeeFail(error.response));
  }
}
function* onfindGg1Employee({ payload: id }) {
  try {
    const response = yield call(findGg1Employee, id);
    console.log("findGg1Employee",response)
    yield put(findOutGg1EmployeeSuccess(response));
  } catch (error) {
    yield put(findOutGg1EmployeeFail(error.response));
  }
}
function* getAuditHistory({ payload }) {
  try {
    const response = yield call(AuditTrailHistory, payload);
    yield put({ type: AUDIT_TRAIL_HISTORY_SUCCESS, response });
  } catch (error) {
    yield put({
      type: AUDIT_TRAIL_HISTORY_FAIL,
      message: error.message,
    });
  }
}
function* onGetEmployeeTransactionLogs({ payload: employeeID }) {
  try {
    const response = yield call(getTransactionLogs, employeeID);
    yield put(getEmployeeTransactionLogsSuccess(response));
  } catch (error) {
    yield put(getEmployeeTransactionLogsFails(error.response));
  }
}

function* onGetEmployeeVerification({ payload: employeeID }) {
  try {
    const response = yield call(employeeverification, employeeID);
    yield put(findEmployeeVerificationSuccess(response));
  } catch (error) {
    yield put(findEmployeeVerificationFail(error.response));
  }
}

function* onGetEmployeeTransactionInfo({ payload: { employeeID, logID } }) {
  try {
    const response = yield call(getTransactionInfo, employeeID, logID);
    yield put(getEmployeeTransactionInfoSuccess(response));
  } catch (error) {
    yield put(getEmployeeTransactionInfoFails(error.response));
  }
}

function* onGetEmployeeTypes() {
  try {
    const response = yield call(getEmployeeTypes);
    yield put(getEmployeeTypesSuccess(response));
  } catch (error) {
    yield put(getEmployeeTypesFails(error.response));
  }
}
function* onSearchEmployee({ payload }) {
  try {
    const response = yield call(searchEmployee, payload);
    yield put(searchEmployeeSuccess(response));
  } catch (error) {
    yield put(searchEmployeeFail(error.response));
  }
}
function* onVerifyEmployee({ payload }) {
  try {
    const response = yield call(verifyEmployee, payload);
    yield put(verifyEmployeeSuccess(response));
    if (response === false) {
      ShowError("The Employee Id does not exist in Payroll System", {
        position: "top-right",
      });
    }
  } catch (error) {
    yield put(verifyEmployeeFail(error.response));
    ShowError(error.response?.data?.errors?.empid, {
      position: "top-right",
    });
  }
}
function* onGetDeductionPlan() {
  try {
    const response = yield call(getDeductionPlan);
    yield put(getEmployeeDeductionSuccess(response));
  } catch (error) {
    yield put(getEmployeeDeductionFail(error.response));
  }
}
function* onSubscribeDeduction({ payload }) {
  try {
    const response = yield call(performNewSubscription, payload);
    yield put(perfomDeductionSubscriptionSuccess(response));
  } catch (error) {
    yield put(perfomDeductionSubscriptionFails(error.response));
  }
}
function* onUpdateDeduction({ payload }) {
  try {
    const response = yield call(deductionUpdate, payload);
    yield put(updateDeductionSuccess(response));
  } catch (error) {
    yield put(updateDeductionFails(error.response));
  }
}
function* onUpdateCertifications({ payload, id }) {
  try {
    const response = yield call(UpdateCertifications, payload, id);
    yield put(updateCertificationsSuccess(response));
  } catch (error) {
    yield put(updateCertificationsFails(error.response));
  }
}
function* onCreateCertifications({ payload }) {
  try {
    const response = yield call(CreateCertifications, payload);
    yield put(onCertificationCreationSuccess(response));
  } catch (error) {
    yield put(onCertificationCreationFails(error.response));
  }
}
function* onUpdateTag({ payload, id }) {
  try {
    const response = yield call(UpdateTag, payload, id);
    yield put(updateTagSuccess(response));
  } catch (error) {
    yield put(updateTagFails(error.response));
  }
}
function* onGetDocumentAttached({ payload }) {
  try {
    const response = yield call(documentAttached, payload);
    yield put(getDocumentAttachedSuccess(response));
  } catch (error) {
    yield put(getDocumentAttachedFail(error.response));
  }
}
function* onCreateTag({ payload }) {
  try {
    const response = yield call(CreateTag, payload);
    yield put(createTagSuccess(response));
  } catch (error) {
    yield put(createTagFails(error.response));
  }
}
function* onSubscribeChangeDeduction({
  payload: { subscribe, DeductionCode },
}) {
  try {
    const response = yield call(
      subscribe === "active"
        ? performActivateSubscription
        : performInActivateSubscription,
      DeductionCode
    );
    yield put(perfomDeductionUnSubscriptionSuccess(response));
  } catch (error) {
    yield put(perfomDeductionUnSubscriptionFails(error.response));
  }
}

function* onGetEmployeeLists() {
  try {
    const response = yield call(getEmployeeList);
    yield put(getEmployeeListSuccess(response));
  } catch (error) {
    yield put(getEmployeeListFails(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(NPAGG1TRANSACTION, onNPAGetTransaction);
  yield takeLatest(SEARCH_EMPLOYEE, onSearchEmployee);
  yield takeLatest(VERIFY_EMPLOYEE, onVerifyEmployee);
  yield takeLatest(NPAPAYROLL, onCreateNPAPayroll);
  yield takeLatest(FIND_EMPLOYEE, onfindEmployee);
  yield takeLatest(FINDGG1_EMPLOYEE,onfindGg1Employee)
  yield takeLatest(GET_EMPLOYEE_DEDUCTION, onGetDeductionPlan);
  yield takeLatest(GET_EMPLOYEE_TYPES, onGetEmployeeTypes);
  yield takeLatest(GET_EMPLOYEE, onGetEmployee);
  yield takeLatest(EMPLOYEE, GetEmployee);
  yield takeLatest(UPDATE_DEDUCTION, onUpdateDeduction);
  yield takeLatest(SUBSCRIBE_DEDUCTION, onSubscribeDeduction);
  yield takeLatest(UNSUBSCRIBE_DEDUCTION, onSubscribeChangeDeduction);
  yield takeLatest(GET_EMPLOYEE_TRANSACTIONLOGS, onGetEmployeeTransactionLogs);
  yield takeLatest(GET_EMPLOYEE_TRANSACTIONINFO, onGetEmployeeTransactionInfo);
  yield takeLatest(AUDIT_TRAIL_HISTORY, getAuditHistory);
  yield takeLatest(UPDATE_CERTIFICATIONS, onUpdateCertifications);
  yield takeLatest(CREATE_CERTIFICATIONS, onCreateCertifications);
  yield takeLatest(CREATE_TAG, onCreateTag);
  yield takeLatest(UPDATE_TAG, onUpdateTag);
  yield takeLatest(GET_DOCUMENT_ATTACHED, onGetDocumentAttached);
  yield takeLatest(EMPLOYEE_LIST, onGetEmployeeLists);
  yield takeLatest(EMPLOYEE_VERIFICATION, onGetEmployeeVerification);
  yield takeLatest(MASTER_UPDATE, onUpdateMaster);
  yield takeLatest(MASTERGG1_UPDATE, onUpdateGg1Master);
}

export default CartSaga;
