import {
	GET_CHART_DATA_YEAR_SERVICE,
	GET_CHART_DATA_YEAR_SERVICE_SUCCESS,
	GET_CHART_DATA_YEAR_SERVICE_FAIL,
	GET_CHART_DATA_AGE_GROUP,
	GET_CHART_DATA_AGE_GROUP_SUCCESS,
	GET_CHART_DATA_AGE_GROUP_FAIL,
	GET_CHART_DATA_AGE_Salary_Group,
	GET_CHART_DATA_AGE_Salary_Group_SUCCESS,
	GET_CHART_DATA_AGE_Salary_Group_FAIL,
	GET_CGI_BOTTLENET,
	GET_CGI_BOTTLENET_SUCCESS,
	GET_CGI_BOTTLENET_FAILURE,
	GET_SOLIDGAUGE, GET_SOLID_GAUGE_SUCCESS, GET_SOLID_GAUGE_FAILURE, GET_BBMRGG1ANALYST, GET_BBMRGG1ANALYST_SUCCESS, GET_BBMRGG1ANALYST_FAILURE, GET_BBMRGG1ASSIGNANALYST, GET_BBMRGG1ASSIGNANALYST_SUCCESS, GET_BBMRGG1ASSIGNANALYST_FAILURE
} from "./actionTypes";

const initialState = {
	chartDataYearOfService: [],
	chartDataAgeGroup: [],
	chartDataSalaryGroup: [],
	loadingChartsYearOfservice: false,
	loadingChartsAgeGroup: false,
	loadingChartsSalaryGroup: false,
	cglBottleNetData: [],
	gaugesolidData: [],
	bbmrGG1: [],
	bbmrGG1Assign: [],
	bbmrGG1AssignLoading:false,
	loading: false,
	gaugesolid_error: {
		message: "",
	},
	bbmrGG1_error: {
		message: "",
	},
	bbmrGG1Assign_error: {
		message: "",
	},
	yearofservice_error: {
		message: "",
	},
	agegroup_error: {
		message: "",
	},
	salarygroup_error: {
		message: "",
	},
	bottlenet_error: {
		message: "",
	},
};


const GetChartData = (state = initialState, action) => {
	if (!action)
		return state;
	switch (action.type) {
		case GET_SOLIDGAUGE:
			return {
				...state,
				loading: true,
			};
		case GET_SOLID_GAUGE_SUCCESS:
			return {
				...state,
				gaugesolidData: action.payload,
				loading: false,

			}
		case GET_SOLID_GAUGE_FAILURE:
			state = {
				...state,
				gaugesolid_error: {
					message: "Error",
				},
				loading: false,
			};
			break;
			

			

			case GET_BBMRGG1ANALYST:
			return {
				...state,
				bbmrGG1Loading: true,
			};
		case GET_BBMRGG1ANALYST_SUCCESS:
			return {
				...state,
				bbmrGG1: action.payload.results,
				bbmrGG1Loading: false,

			}
		case GET_BBMRGG1ANALYST_FAILURE:
			state = {
				...state,
				bbmrGG1_error: {
					message: "Error",
				},
				bbmrGG1Loading: false,
			};
			break;  
			
			

			case GET_BBMRGG1ASSIGNANALYST:
			return {
				...state,
				bbmrGG1AssignLoading: true,
			};
		case GET_BBMRGG1ASSIGNANALYST_SUCCESS:
			return {
				...state,
				bbmrGG1Assign: action.payload.results,
				bbmrGG1AssignLoading: false,

			}
		case GET_BBMRGG1ASSIGNANALYST_FAILURE:
			state = {
				...state,
				bbmrGG1Assign_error: {
					message: "Error",
				},
				bbmrGG1AssignLoading: false,
			};
			break;  






		case GET_CGI_BOTTLENET:
			return {
				...state,
				loading: true,
			};
		case GET_CGI_BOTTLENET_SUCCESS:
			return {
				...state,
				cglBottleNetData: action.payload,
				loading: false,
			};
		case GET_CGI_BOTTLENET_FAILURE:
			state = {
				...state,
				bottlenet_error: {
					message: "Error",
				},
				loading: false,
			};
			break;
		case GET_CHART_DATA_YEAR_SERVICE:
			state = { ...state, loadingChartsYearOfservice: true };
			break;
		case GET_CHART_DATA_YEAR_SERVICE_SUCCESS:
			state = { ...state, chartDataYearOfService: action.payload, loadingChartsYearOfservice: false };
			break;
		case GET_CHART_DATA_YEAR_SERVICE_FAIL:
			state = {
				...state,
				yearofservice_error: {
					message: "Error",
				},
				loadingChartsYearOfservice: false,
			};
			break;
		case GET_CHART_DATA_AGE_GROUP:
			state = { ...state, loadingChartsAgeGroup: true };
			break;
		case GET_CHART_DATA_AGE_GROUP_SUCCESS:
			state = { ...state, chartDataAgeGroup: action.payload, loadingChartsAgeGroup: false };
			break;
		case GET_CHART_DATA_AGE_GROUP_FAIL:
			state = {
				...state,
				agegroup_error: {
					message: "Error",
				},
				loadingCharts: false,
			};
			break;

		case GET_CHART_DATA_AGE_Salary_Group:
			state = { ...state, loadingChartsSalaryGroup: true };
			break;
		case GET_CHART_DATA_AGE_Salary_Group_SUCCESS:
			state = { ...state, chartDataSalaryGroup: action.payload, loadingChartsSalaryGroup: false };
			break;
		case GET_CHART_DATA_AGE_Salary_Group_FAIL:
			state = {
				...state,
				salarygroup_error: {
					message: "Error",
				},
				loadingChartsSalaryGroup: false,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};
export default GetChartData;