import {
  GET_MASETR_DATA,
  GET_MASETR_DATA_FAIL,
  GET_MASETR_DATA_SUCCESS,
  GG1_ACTION_DELETE,
  GG1_ACTION_DELETE_FAIL,
  GG1_ACTION_DELETE_SUCCESS,
  GET_POSITION_SALARY_BY_GRADE,
  GET_POSITION_SALARY_BY_GRADE_FAIL,
  GET_POSITION_SALARY_BY_GRADE_SUCCESS,
  ADD_GG1_EMPLOYEE,
  ADD_GG1_EMPLOYEE_SUCCESS,
  ADD_GG1_EMPLOYEE_FAIL,
  RESET,
  GG1ANLYSTUPDATE,
  GG1ANLYSTUPDATE_SUCCESS,
  GG1ANLYSTUPDATE_FAIL,
} from "./actionTypes";

//   export const getDepartments = (payload) => {
// 	return {
// 	  type: GET_DEPARTMENTS,
// 	  payload: payload
// 	};
//   };

//   export const getDepartmentsSuccess = (posts) => {
// 	return {
// 	  type: GET_DEPARTMENTS_SUCCESS,
// 	  payload: posts,
// 	};
//   };

//   export const getDepartmentsFail = (error) => {
// 	return {
// 	  type: GET_DEPARTMENTS_FAIL,
// 	  payload: error,
// 	};
//   };

//   export const getDepartmentsDetails = (id) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS,
// 	  payload: id,
// 	};
//   };

//   export const getDepartmentsDetailsSuccess = (post) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS_SUCCESS,
// 	  payload: post,
// 	};
//   };

//   export const getDepartmentsDetailsFail = (error) => {
// 	return {
// 	  type: GET_DEPARTMENTS_DETAILS_FAIL,
// 	  payload: error,
// 	};
//   };

//   export const createDepartment =(payload)=>{
// 	return {
// 		type: DEPARTMENT_CREATE,
// 		payload: payload
// 	}
//   }
//   export const createDepartmentSuccess =(response)=>{
// 	return {
// 		type: DEPARTMENT_CREATE_SUCCESS,
// 		payload: response
// 	}
//   }
//   export const createDepartmentFail =(error)=>{
// 	return {
// 		type: DEPARTMENT_CREATE_FAIL,
// 		payload: error
// 	}
//   }

//   export const updateDepartment =(payload)=>{

// 	return {
// 		type: DEPARTMENT_UPDATE,
// 		payload: payload
// 	}
//   }
//   export const updateDepartmentSuccess =(response)=>{
// 	return {
// 		type: DEPARTMENT_UPDATE_SUCCESS,
// 		payload: response
// 	}
//   }
//   export const updateDepartmentFail =(error)=>{
// 	return {
// 		type: DEPARTMENT_UPDATE_FAIL,
// 		payload: error
// 	}
//   }

export const deleteGG1Action = (payload) => {
  console.log("payload in acton", payload);
  return {
    type: GG1_ACTION_DELETE,
    payload: payload,
  };
};
export const deleteGG1ActionSucess = (response) => {
  return {
    type: GG1_ACTION_DELETE_SUCCESS,
    payload: response,
  };
};
export const deleteGG1ActionFail = (error) => {
  return {
    type: GG1_ACTION_DELETE_FAIL,
    payload: error,
  };
};

export const checkEmployeeMaster = (payload, cbSuccess, cbFailure) => {
  return {
    type: GET_MASETR_DATA,
    payload,
    cbSuccess,
    cbFailure,
  };
};
export const checkEmployeeMasterSucess = (response) => {
  return {
    type: GET_MASETR_DATA_SUCCESS,
    payload: response,
  };
};
export const checkEmployeeMasterFail = (error) => {
  return {
    type: GET_MASETR_DATA_FAIL,
    payload: error,
  };
};

export const GetPositionSalaryByGrade = (payload, cbSuccess) => {
  return {
    type: GET_POSITION_SALARY_BY_GRADE,
    payload,
    cbSuccess,
  };
};
export const GetPositionSalaryByGradeSucess = (response) => {
  return {
    type: GET_POSITION_SALARY_BY_GRADE_SUCCESS,
    payload: response,
  };
};
export const GetPositionSalaryByGradeFail = (error) => {
  return {
    type: GET_POSITION_SALARY_BY_GRADE_FAIL,
    payload: error,
  };
};

export const updateGg1Analyst = (payload) => {
  return {
    type: GG1ANLYSTUPDATE,
    payload: payload,
  };
};
export const updateGg1AnalystSuccess = (response) => {
  return {
    type: GG1ANLYSTUPDATE_SUCCESS,
    payload: response,
  };
};
export const updateGg1AnalystFail = (error) => {
  return {
    type: GG1ANLYSTUPDATE_FAIL,
    payload: error,
  };
};
// AddGG1EmployeeMaster
export const AddGG1EmployeeMasterData = (payload, cbSuccess, cbFailure) => {
  return {
    type: ADD_GG1_EMPLOYEE,
    payload,
    cbSuccess,
    cbFailure
  };
};
export const AddGG1EmployeeMasterDataSucess = (response) => {
  return {
    type: ADD_GG1_EMPLOYEE_SUCCESS,
    payload: response,
  };
};
export const AddGG1EmployeeMasterDataFail = (error) => {
  return {
    type: ADD_GG1_EMPLOYEE_FAIL,
    payload: error,
  };
};
export const reset = () => {
  return {
    type: RESET,
  };
};
