import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";
const baseURL = process.env.REACT_APP_API_DOTNET_URL;
// const token = localStorage.getItem("jwt_access_token");
const token = sessionStorage.getItem("jwt_access_token");

//Post
export const deleteActionGg1 = async (ID) =>
  await remove(`${baseURL}/GG1Common/${ID?.ActionNo}?IsCancel=${ID?.isCancel}`
  // , {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // }
  );
// export const updateGG1Analyst = (payload) => put(`${baseURL + url.GG1ANALYST_BASE}`, payload,{
// headers: {
//   'Content-Type': 'application/json',
// }
// })
export const updateGG1Analyst = (payload) => {
  return put(`${baseURL + url.GG1ANALYST_BASE}`, JSON.stringify(payload), {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}


export const checkEmployeeMasterApiCall = async (payload) =>
  // await get(baseURL + "/Employee/CheckForEmployeeMasterByEmployeeId/" + id, {
    await get(baseURL + "/Employee/CheckForEmployeeMasterByEmployeeIdOrSSN/" + payload?.SSN + `?Noa=${payload?.NOA}`,
  //    {
  //   headers: {
  //     "Content-type": "application/json",
  //     "x-access-token": token,
  //   },
  // }
  )
    .then((res) => res)
    .catch((error) => error);

export const GetPositionSalaryByGradeApiCall = async (id) =>
  await get(
    baseURL +
      "/GG1Common/GetPositionSalaryByGrade/" +
      id?.range_next?.trim() +
      "/" +
      id?.label,

    // {
    //   headers: {
    //     "Content-type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  )
    .then((res) => res)
    .catch((error) => error);

// AddGG1EmployeeMaster

export const AddGG1EmployeeMasterApiCall = async (id) =>
  await post(baseURL + "/Employee/AddGG1EmployeeMaster", id
  // , {
  //   headers: {
  //     "Content-type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   },
  // }
  )
    .then((res) => res)
    .catch((error) => error);
