import { takeLatest, put, call } from "redux-saga/effects";

import {
	CREATE_JOB_APPLICATION,
	GET_AGE_RANGES,
	GET_ANNOUNCEMENT,
	GET_ANNOUNCEMENTS,
	GET_APPLICATION_EMP_AND_ANN,
	GET_CITIZENSHIP,
	GET_ETHNICITIES,
	GET_JOB_APPLICATION_LEAD,
	GET_JOB_TYPES,
	GET_PERSON_RACES,
	GET_POSITION_TYPES,
	GET_QUESTION_POSITIONS,
	GET_TERMINATION_REASONS,
	RESET,
	SAVE_JOB_APPLICATION,
	SET_ERROR_STATE,
	UPLOAD_FILE,
	DELETE_UPLOAD_FILE, GET_FILES, CLOSE_JOB_APPLICATION
} from "./actionTypes";

import {
	getAppointmentsSuccess,
	getAppointmentsFail,
	getJobApplicationLeadsSuccess,
	getJobApplicationLeadsFail,
	getEthnicitiesFail,
	getEthnicitiesSuccess,
	getPersonRacesSuccess,
	getPersonRacesFail,
	getJobTypesSuccess,
	getJobTypesFail,
	getPositionTypesSuccess,
	getPositionTypesFail,
	getQuestionsPositionsSuccess,
	getQuestionsPositionsFail,
	getTerminationReasonsSuccess,
	getTerminationReasonsFail,
	createJobApplicationSuccess,
	createJobApplicationFail,
	getAgeRangesSuccess,
	getAgeRangesFail,
	getCitizenshipSuccess,
	getCitizenshipFail,
	getApplicationByEmployeeAnnouncementSuccess,
	getApplicationByEmployeeAnnouncementFail,
	getAnnouncementSuccess,
	getAnnouncementFail,
	reset,
	setOnlineJobApplicationError,
	saveJobApplicationSuccess,
	saveJobApplicationFail,
	closeJobApplicationSuccess,
	closeJobApplicationFail,
	uploadFileSuccess,
	uploadFileFail,
	deleteUploadFileSuccess,
	deleteUploadFileFail, getFilesSuccess, getFilesFail
} from "./actions";
import {
	getJobAnnouncementsApi,
	getJobApplicationLeadsApi,
	getEthnicitiesApi,
	getPersonRacesApi,
	getPositionTypesApi,
	getQuestionByPositionsApi,
	geTerminationReasonsApi,
	createJobApplicationApi,
	getAgeRangesApi,
	getCitizenshipApi,
	getJobTypesApi,
	getApplicationByEmpAndAnnApi,
	getJobAnnouncementByIdApi,
	uploadFile,
	getFiles,
	deleteFile,
	closeJobApplicationApi
} from "../../_helper/services/onlineJobApplication";
import { ShowError, ShowSucess } from "../../_helper/helper";

function* onGetAnnouncements({ payload: { page, pageSize } }) {
	try {
		const response = yield call(getJobAnnouncementsApi, { page, pageSize });
		yield put(getAppointmentsSuccess(response));
	} catch (error) {
		yield put(getAppointmentsFail(error.response));
	}
}

function* onGetJobApplicationLeads() {
	try {
		const response = yield call(getJobApplicationLeadsApi);
		yield put(getJobApplicationLeadsSuccess(response));
	} catch (error) {
		yield put(getJobApplicationLeadsFail(error.response));
	}
}

function* onGetEthnicities() {
	try {
		const response = yield call(getEthnicitiesApi);
		yield put(getEthnicitiesSuccess(response));
	} catch (error) {
		yield put(getEthnicitiesFail(error.response));
	}
}

function* onGetPersonRaces() {
	try {
		const response = yield call(getPersonRacesApi);
		yield put(getPersonRacesSuccess(response));
	} catch (error) {
		yield put(getPersonRacesFail(error.response));
	}
}

function* onGetJobTypes() {
	try {
		const response = yield call(getJobTypesApi);
		yield put(getJobTypesSuccess(response));
	} catch (error) {
		yield put(getJobTypesFail(error.response));
	}
}

function* onGetPositionTypes() {
	try {
		const response = yield call(getPositionTypesApi);
		yield put(getPositionTypesSuccess(response));
	} catch (error) {
		yield put(getPositionTypesFail(error.response));
	}
}

function* onGetQuestionsByPositions({ payload: { pos1, pos2 } }) {
	try {
		const response = yield call(getQuestionByPositionsApi, { pos1, pos2 });
		yield put(getQuestionsPositionsSuccess(response));
	} catch (error) {
		yield put(getQuestionsPositionsFail(error.response));
	}
}

function* onGetTerminationReasons() {
	try {
		const response = yield call(geTerminationReasonsApi);
		yield put(getTerminationReasonsSuccess(response));
	} catch (error) {
		yield put(getTerminationReasonsFail(error.response));
	}
}

function* onGetAgeRanges() {
	try {
		const response = yield call(getAgeRangesApi);
		yield put(getAgeRangesSuccess(response));
	} catch (error) {
		yield put(getAgeRangesFail(error.response));
	}
}

function* onGetCitizenship() {
	try {
		const response = yield call(getCitizenshipApi);
		yield put(getCitizenshipSuccess(response));
	} catch (error) {
		yield put(getCitizenshipFail(error.response));
	}
}

function* onGetJobApplication({ payload: { empId, annId } }) {
	try {
		const response = yield call(getApplicationByEmpAndAnnApi, { empId, annId });
		yield put(getApplicationByEmployeeAnnouncementSuccess(response));
	} catch (error) {
		yield put(getApplicationByEmployeeAnnouncementFail(error.response));
	}
}

function* onCreateJobApplication({ payload }) {
	try {
		const response = yield call(createJobApplicationApi, payload);
		yield put(createJobApplicationSuccess(response));
	} catch (error) {
		yield put(createJobApplicationFail(error.response));
		ShowError(error?.response?.data?.message);
	}
}

function* onSaveJobApplication({ payload }) {
	try {
		const response = yield call(createJobApplicationApi, payload);
		yield put(saveJobApplicationSuccess(response));
		ShowSucess("Job Application Saved successfully", { position: "top-right" });
	} catch (error) {
		yield put(saveJobApplicationFail(error.response));
		ShowError(error?.response?.data?.message);
	}
}


function* onCloseJobApplication({ payload }) {
	try {
		const response = yield call(closeJobApplicationApi, payload);
		yield put(closeJobApplicationSuccess(response));
		ShowSucess("Job Application Saved successfully", { position: "top-right" });
	} catch (error) {
		yield put(closeJobApplicationFail(error.response));
		ShowError(error?.response?.data?.message);
	}
}

function* onGetJobAnnouncementById({ payload: { id } }) {
	try {
		const response = yield call(getJobAnnouncementByIdApi, id);
		yield put(getAnnouncementSuccess(response));
	} catch (error) {
		yield put(getAnnouncementFail(error.response));
	}
}

function* onUploadFile({ payload }) {
	try {
		const response = yield call(uploadFile, payload);
		yield put(uploadFileSuccess(response));
		ShowSucess(`${(payload?.ContentType || "Attachment")} uploaded successfully`, { position: "top-right" });
	} catch (error) {
		yield put(uploadFileFail(error.response));
		ShowError(error?.response?.data?.message);
	}
}

function* ongetFiles({ payload }) {
	try {
		const response = yield call(getFiles, payload);
		yield put(getFilesSuccess(response));
	} catch (error) {
		yield put(getFilesFail(error.response));
		ShowError(error?.response?.data?.message);
	}
}

function* onDeleteFile({ payload: ID }) {
	try {
		const response = yield call(deleteFile, ID);
		yield put(deleteUploadFileSuccess(response));
		ShowSucess("Attachment deleted successfully", { position: "top-right" });
	} catch (error) {
		yield put(deleteUploadFileFail(error.response));
		ShowError("Something went wrong!", { position: "top-right" });
	}
}

function* CartSaga() {
	yield takeLatest(GET_ANNOUNCEMENTS, onGetAnnouncements);
	yield takeLatest(GET_JOB_APPLICATION_LEAD, onGetJobApplicationLeads);
	yield takeLatest(GET_ETHNICITIES, onGetEthnicities);
	yield takeLatest(GET_PERSON_RACES, onGetPersonRaces);
	yield takeLatest(GET_JOB_TYPES, onGetJobTypes);
	yield takeLatest(GET_POSITION_TYPES, onGetPositionTypes);
	yield takeLatest(GET_QUESTION_POSITIONS, onGetQuestionsByPositions);
	yield takeLatest(GET_TERMINATION_REASONS, onGetTerminationReasons);
	yield takeLatest(GET_AGE_RANGES, onGetAgeRanges);
	yield takeLatest(CREATE_JOB_APPLICATION, onCreateJobApplication);
	yield takeLatest(SAVE_JOB_APPLICATION, onSaveJobApplication);
	yield takeLatest(CLOSE_JOB_APPLICATION, onCloseJobApplication);
	yield takeLatest(GET_CITIZENSHIP, onGetCitizenship);
	yield takeLatest(GET_ANNOUNCEMENT, onGetJobAnnouncementById);
	yield takeLatest(GET_APPLICATION_EMP_AND_ANN, onGetJobApplication);
	yield takeLatest(RESET, reset);
	yield takeLatest(SET_ERROR_STATE, setOnlineJobApplicationError);
	yield takeLatest(UPLOAD_FILE, onUploadFile);
	yield takeLatest(GET_FILES, ongetFiles);
	yield takeLatest(DELETE_UPLOAD_FILE, onDeleteFile);
}

export default CartSaga;
