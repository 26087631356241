import React from 'react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';


import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import { useAuth } from 'oidc-react';
import { getUserRole } from '../_helper/helper';


const Routers = () => {
        const { userData } = useAuth();
        const role = getUserRole(userData);
        const BBMRRoles = ["BBMR-ADMIN","BBMR-ANALYST","BBMR-SUPERVISOR","BBMR-DIRECTOR"]
        const benefitsRoles = ["BenefitsDepartmentManagement","BenefitsGovernmentWideManagement"]


        useEffect(() => {
                let abortController = new AbortController();
                // console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
                // console.disableYellowBox = true;
                return () => {
                        abortController.abort();
                };

        }, []);
        console.log("123123 ::: ",role,benefitsRoles.includes(role))
        return (
                <BrowserRouter basename={'/'}>
                        <>
                                <Suspense fallback={<Loader />}>
                                        <Routes>
                                                <Route path={"/default"} element={<Navigate to={BBMRRoles.includes(role) ?`/bbmr-gg1-tracking`: benefitsRoles.includes(role) ? `/dashboard/benefit` :`/dashboard/default`} />} />
                                                {/* <Route path={"/default"} element={<Navigate to={`dashboard/default`} />} /> */}

                                                {/* <Route exact path={'/'} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />     */}
                                                <Route path={`/*`} element={<LayoutRoutes />} />
                                        </Routes>
                                </Suspense>
                        </>
                </BrowserRouter>
        );
};

export default Routers;